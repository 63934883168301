import React, { ReactElement } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import "./style.scss";
import { Card } from "../../components";
import { WidgetProps } from "../../models"
import { BootstrapHelper } from "../../helpers";

export interface GaugeProps extends ABB.GaugeProps {
	key?: string;
	showAsPercentage: boolean;
}

export interface GaugesProps extends WidgetProps {
	gauges: GaugeProps[];
}

export function Gauges({
	title,
	desktopCols,
	tabletCols,
	mobileCols,
	gauges,
	lastUpdate,
	lastUpdateType,
	installed,
	disconnected
}: GaugesProps): ReactElement {
	// TODO: manage showAsPercentage if needed
	return (

		<Card title={title} comment={{ lastUpdate, lastUpdateType }}
			mobileCols={BootstrapHelper.getColValue(12, mobileCols)}
			tabletCols={BootstrapHelper.getColValue(4, tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, desktopCols)}
			installed={installed}
			disconnected={disconnected}
			contentClassName="d-flex flew-column justify-content-around">

			{gauges.map((props, i) => <ABB.Gauge {...props} style={{ height: "150px" }} />)}


		</Card>
	);
}
