import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as ABB from '@abb/abb-common-ux-react'
import { WidgetProps } from '../../models'
import { HealthIndexStatusHelper, BootstrapHelper, SectionsHelper } from '../../helpers'
import { HealthIndexStatus, AppSections } from '../../enums'
import { Card, InfoPopup } from '../../components'
import './style.scss'


export interface BaseSensorStatusProps extends WidgetProps {
    status: HealthIndexStatus;
    info?: string;
}

export interface SensorStatusProps extends BaseSensorStatusProps {
    embedded?: boolean;
}

export function SensorStatus(props: SensorStatusProps): ReactElement {
    let { t } = useTranslation();
    let hist = useHistory();

    let color = HealthIndexStatusHelper.getColor(props.status);
    let href = SectionsHelper.createHref(AppSections.Settings, hist);

    return (

        <Card hideTitle={true}
						installed={props.installed}
						disconnected={props.disconnected}
            mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
            tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)}
            desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}
            className={"sensor-status-card " + props.status + (props.embedded ? " embedded" : "")}
            contentClassName="d-flex justify-content-between">
            <div className="d-flex justify-content-start">
                <ABB.Icon sizeClass="medium" className="mx-2 align-middle" name="abb/broadcast" color={color} />
                <span className="font-weight-bolder align-middle" style={{ fontSize: "16px" }}>{t('SensorStatus')}</span>
                <InfoPopup info={props.info} />
            </div>
            <div className="d-flex justify-content-center">
                <a href={href}>
                    <ABB.Icon sizeClass="medium" name="abb/right-arrow" color="color-status-blue" />
                </a>
            </div>
        </Card>
    )
}
