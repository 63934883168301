export enum WaveformOperation {
    opening = "opening",
    closing = "closing",
    last = "last"
}

export interface WaveformDataItem {
    id: number;
    timestamp_us: number;
    operation: WaveformOperation;
    sampling_rate_us: number;
    samples: string[];
		group_id?: number;
}

export type WaveformDataListOnlyItem = Omit<WaveformDataItem, 'samples'>;

export interface WaveformsResponse {
    waveforms: WaveformDataItem[];
    total: number;
}

export interface WaveformsListOnlyResponse {
	waveforms: WaveformDataListOnlyItem[];
	total: number;
}
