import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import './style.scss';
import { useTranslation } from 'react-i18next';

export interface ModalWithStepsProps {
	dialog: ABB.DialogProps,
	children: ReactElement[]|ReactElement,
	title: string,
	steps: {
		title: string,
		isUnlocked: boolean,
		cardTitle?: string,
		topRightContent?: () => ReactElement,
	}[],
	onClose: () => unknown,
	onFinish: () => Promise<void>,
	onSuccess: () => Promise<void>,
	onError: (err: Error) => void,
	nextGuard: (currentStep: number) => Promise<void>,
}

export function ModalWithSteps({ dialog, children, title, steps, onClose, onFinish, onSuccess, onError, nextGuard }: ModalWithStepsProps): ReactElement {
	const { t } = useTranslation();
	const [activeStep, setActiveStep] = useState(0);
	const [maxUnlockedStep, setMaxUnlockedStep] = useState(0);
	const [maxHeightWasSet, setMaxHeightWasSet] = useState<boolean|undefined>(undefined);
	const modalContentRef = useRef<HTMLDivElement>(null);
	const [contentMaxHeight, setContentMaxHeight] = useState<string>('unset'); 
	const [isLoading, setIsLoading] = useState(false);
	
	const updateMaxHeight = useCallback(() => {
		if (modalContentRef.current) {
			const modal = modalContentRef.current.closest('.modal-with-steps-content') as HTMLDivElement|null;
			const modalOffsets = modal ? (window.innerHeight - modal.offsetHeight - modal.offsetTop) : 0;
			setContentMaxHeight(`${(window.innerHeight - modalOffsets - modalContentRef.current.offsetTop)}px`);
			setMaxHeightWasSet(true);
		} else {
			setMaxHeightWasSet(false);
		}
	}, [modalContentRef]);

	useEffect(() => {
		if (!maxHeightWasSet) {
			updateMaxHeight();
		}
	}, [updateMaxHeight, maxHeightWasSet]);

	useEffect(() => {
		const listener = () => {
			updateMaxHeight();
		}
		window.addEventListener('resize', listener);
		return () => {
			window.removeEventListener('resize', listener);
		}
	}, [updateMaxHeight]);
	return <ABB.Dialog {...dialog}
		dimBackground={true}
		contentClassName="modal-with-steps-content"
		containerClassName="modal-with-steps-wrapper">
		<ABB.AbbBar productName={title} className='px-1'>
			{dialog.showCloseButton !== false &&
			<ABB.CloseIcon sizeClass='large' onClick={() => {
				onClose();
			}} />}
		</ABB.AbbBar>
		<div className='step-wrapper py-2' ref={modalContentRef} style={{ maxHeight: contentMaxHeight }}>
			<div className='step-selector-wrapper'>
				{steps.map((step, i) => <button
					key={i}
					className={`step-selector-button${i === activeStep ? ' active' : ''}`}
					disabled={maxUnlockedStep < i || (i !== activeStep && !steps[activeStep].isUnlocked)}
					onClick={async () => {
						try {
							await nextGuard(activeStep);
							if (i > maxUnlockedStep) {
								setMaxUnlockedStep(i);
							}
							setActiveStep(i);
						} catch (guardFailed) {
							// do nothing
						}
					}}>
					<ABB.Icon name='abb/check-mark' sizeClass='large' color={i <= maxUnlockedStep ? 'color-status-green' : '#dbdbdb'} />
					<span>{step.title}</span>
				</button>)}
			</div>
			<div className='step-content-wrapper mr-2'>
				{steps.map((step, i) => <div
					key={i}
					className={`step-content p-4 mt-0${i === activeStep ? ' active' : ''}`}>
						<div className='d-flex justify-content-between align-items-baseline'>
							<ABB.Heading level={3} text={step.cardTitle ?? step.title} className="font-weight-bold" />
							<div>{step.topRightContent && <step.topRightContent />}</div>
						</div>
						{('length' in children) ? children[i] : children}
				</div>)}
				<div className='step-content-navigator my-3 pr-4'>
					{activeStep > 0 && <ABB.Button
						icon='abb/left-arrow'
						type='discreet-blue'
						sizeClass='small'
						className='mr-3'
						onClick={async () => {
							try {
								await nextGuard(activeStep);
								setActiveStep(activeStep - 1);
							} catch (guardFailed) {
								// do nothing
							}
						}}
						text={t("Back")} />}

					{activeStep < steps.length - 1 && <ABB.Button
						icon='abb/right-arrow'
						type='primary-blue'
						sizeClass='small'
						disabled={!steps[activeStep].isUnlocked}
						onClick={async () => {
							try {
								await nextGuard(activeStep);
								if (activeStep + 1 > maxUnlockedStep) {
									setMaxUnlockedStep(activeStep + 1);
								}
								setActiveStep(activeStep + 1);
							} catch (guardFailed) {
								// do nothing
							}
						}}
						text={t("Next")} />}

					{activeStep === steps.length - 1 && <ABB.Button
						type='primary-blue'
						sizeClass='small'
						isLoading={isLoading}
						disabled={steps.some((s) => !s.isUnlocked)}
						onClick={async () => { 
							setIsLoading(true);
							let wasSuccessful = false;
							try {
								await onFinish();
								wasSuccessful = true;
							} catch (err) {
								console.error(err);
								onError(err);
							} finally {
								setIsLoading(false);
							}
							if (wasSuccessful) {
								await onSuccess();
							}
						}}
						text={t("Finish")} />}
				</div>
			</div>
		</div>
	</ABB.Dialog>;
}
