import React, { ReactElement } from "react";
import { HealthIndexStatus, DashboardSections } from "../../enums";
import * as ABB from "@abb/abb-common-ux-react";
import { Card, InfoPopup, TrimmedText } from "../../components";
import { SectionsHelper, HealthIndexStatusHelper, BootstrapHelper } from "../../helpers";
import { Link, useHistory } from "react-router-dom";
import { WidgetProps } from "../../models"
import './style.scss';
import { useTranslation } from "react-i18next";

export interface FamilyStatusProps extends WidgetProps {
	status: HealthIndexStatus;
	description: string;

	section?: DashboardSections;
	linkUrl?: string;
	linkLabel?: string;

	disableText?: string;
	info?: string;

	error?: string;
}

export function FamilyStatus(props: FamilyStatusProps): ReactElement {
	let icon = HealthIndexStatusHelper.getIcon(props.status);
	let color = HealthIndexStatusHelper.getColor(props.status);

	let hist = useHistory();
	const { t } = useTranslation();

	let href: string | undefined = undefined;

	if (!props.disabled && props.section) {
		href = SectionsHelper.createHref(props.section, hist);
	} else if (props.linkUrl) {
		href = props.linkUrl;
	}

	return (

		<Card title={props.title} comment={!props.disabled ? { lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType } : ""}
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(4, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}
			installed={props.installed}
			disconnected={props.disconnected}
			className={"family-card text-center" + (href ? " browsable" : "") + BootstrapHelper.getElementByStatus(props.disabled)}
			contentClassName="d-flex flex-column justify-content-between family-status">
			<div className="status-container d-flex flex-column justify-content-center">

				{props.exception ?
					<div className="mx-auto">
						<ABB.WithTooltip>
							<ABB.Icon sizeClass="large" color="color-status-red" name="abb/error-circle-1" />
							<ABB.Tooltip style={{ maxWidth: "250px" }} children={props.exception.toString()} />
						</ABB.WithTooltip>
					</div>
					: !props.disabled ?
						<>
							<ABB.Icon className="mx-auto" sizeClass="medium" name={icon} color={color} />
							<div className="d-flex justify-content-center" style={{ height: "30px" }}>
								<span className="my-auto align-middle">{props.description}</span>
								<InfoPopup info={props.info} />
							</div>
						</>
						:
						<span>
							{props.disableText}
						</span>
				}
			</div>

			{href &&
				<>
					{props.exception || props.status === HealthIndexStatus.Ok ?
						<div className="more-container">
							<Link to={href}>{props.linkLabel}</Link>
						</div>
						:
						<div className="alert-message d-flex justify-content-between">
							<ABB.Icon className="my-auto" sizeClass="small" name={icon} color={color} />
							<TrimmedText text={props.error ? props.error : t("GenericErrorMessage")} tag="span" maxLength={20} />
							<Link className="my-auto d-flex" to={href}><ABB.Icon sizeClass="small" name="abb/right-arrow" color="blue" /></Link>
						</div>
					}
				</>
			}

			{href === undefined && (props.status !== HealthIndexStatus.Ok) &&
				<div className="alert-message d-flex ">
					<ABB.Icon className="my-auto" sizeClass="small" name={icon} color={color} />
					<div className="my-auto w-100">
						<TrimmedText text={props.error ? props.error : t("GenericErrorMessage")} tag="span" maxLength={20} />
					</div>
				</div>
			}
		</Card>
	);
}
