import React, { FunctionComponent, useState, useEffect } from 'react'
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from "..";
import { useTranslation } from 'react-i18next';


export interface dropdownElement {
  value: number,
  label: string,
  isNew?: boolean,
  disabled?: boolean
}

export interface DropDownMultiselectProps {
  labelText?: string,
  description?: string,
  size: "small" | "medium" | "large" | undefined,
  allowAdd?: boolean,
  clearable?: boolean,
  searchable?: boolean,
  clearOnEscape?: boolean,
  dataSeries: dropdownElement[],
  fetching?: boolean,
  disabled?: boolean,
  //TMP*****
  maxSelectedElements?: number,
  //TMP*****
  onSelectedItemsChange?: (value: dropdownElement[]) => void;
	selectedMulti?: dropdownElement[],
}

export const DropDownMultiselect: FunctionComponent<DropDownMultiselectProps> = (props) => {

  const { t } = useTranslation();

  const [selectedMulti, setSelectedMulti] = useState<dropdownElement[]>(props.selectedMulti ?? []);
  const [options, setOptions] = useState(props.dataSeries);
  /* const getMenuItemTemplate = (o: dropdownElement) => (
    <span
      style={{
        flex: 1,
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ textDecoration: 'underline' }}>{o.label}</span>
        <span style={{ fontSize: '80%', opacity: '.7' }}>The value is: {o.label}</span>
      </div>
      <ABB.Icon style={{ marginLeft: '32px', color: 'red' }} name="abb/settings" />
    </span>
  ); */

  /* const getSelectedItemTemplate = (o: dropdownElement) => (
    <span style={{ color: 'red', padding: '16px', textDecoration: 'underline', display: 'flex', alignItems: 'center' }}>
      {o.label}
      <ABB.Icon style={{ marginLeft: '32px' }} name="abb/settings" />
    </span>
  ); */

  function handleMultiselection(val: dropdownElement[]) {
    
    if (props.maxSelectedElements) 
    {
      if (val.length <= props.maxSelectedElements) 
      {
        setSelectedMulti(val)
      }
    }
    else 
    {
      setSelectedMulti(val)
    }
  }
	const onSelectedItemsChange = props.onSelectedItemsChange;
	
  useEffect(() => {
    if (onSelectedItemsChange)
      onSelectedItemsChange(selectedMulti);
  }, [selectedMulti, onSelectedItemsChange]);

  useEffect(() => {
    if (props.dataSeries && props.dataSeries.length > 0)
      setOptions(props.dataSeries);
  }, [props.dataSeries]);

  return (
    <Card mobileCols={6} tabletCols={6} desktopCols={6} hideTitleStraightBar={true} hideTitle={true}
      progressBar={"bottom"}
      showProgressBar={props.fetching}>
      <br />
      <div className="as-row">
        <div className="dashed-borders" style={{ flex: 1, margin: '16px', minHeight: '155px' }}>
          {/*<p>Currently selected multi: {selectedMulti.map((e) => e.label).join(', ')}</p>*/}
          <br />
          {(
            <>
              <ABB.Dropdown
                label={props.labelText}
                placeholder={t("Pick many")}
                multiselect={true}
                value={selectedMulti}
                onChange={(val) => {
                  handleMultiselection(val);
                }}
                allowAdd={props.allowAdd ? props.allowAdd : false}
                clearable={props.clearable ? props.clearable : true}
                searchable={props.searchable ? props.searchable : true}
                clearOnEscape={props.clearOnEscape ? props.clearOnEscape : true}
                description={props.description}
                sizeClass={props.size}
                disabled={props.disabled ? props.disabled : false}
              >

                {options.map((o) => (
                  <ABB.DropdownOption
                    key={o.value}
                    label={o.label}
                    value={o.value}
                    disabled={o.disabled}
                  />
                ))}
              </ABB.Dropdown>
            </>
          )}
        </div>
      </div>
      <br />
    </Card>
  )
}
