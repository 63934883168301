import React from 'react'
import * as Widgets from '../../widgets'
import { SectionProps } from "../../models";
import * as ABB from "@abb/abb-common-ux-react";
import { useTranslation } from 'react-i18next';
import './style.scss'
import { useReadable } from '../../observables/hooks';
import { phaseChartTimeInterval$ } from '../../services/widget';



interface TemperatureProps extends SectionProps {

}

export const Temperature = (props: TemperatureProps) => {
    const { t } = useTranslation();

    const temperatureWidgets = props.widgetConfigs.filter((wc) => wc.tag === 'widgets');
    const temperatureSensors = props.widgetConfigs.filter((wc) => wc.tag === 'sensors');
    const phaseChartTimeInterval = useReadable(phaseChartTimeInterval$);

    return (
        <>
            <div id="widgets" className="row">
                {temperatureWidgets.map((pr, i) => <Widgets.WidgetSelector widgetConfig={pr} key={i} />)}
            </div>


            <div className="d-flex row justify-content-xl-between justify-content-lg-between justify-content-md-between justify-content-sm-between justify-content-around flex-wrap" style={{ margin: "5px -10px 5px -10px" }}>
                <div className="d-flex flex-row">
                    <h3>{t('LoadAndTemperature')}</h3>
                </div>
                <div className="d-flex justify-content-center" style={{ paddingRight: "5px", alignItems: "center" }} >
                    <ABB.ToggleButtonGroup id="PhaseChartTimeInterval" className={"m-sm-auto m-md-0"} style={{ padding: 0, margin: 0 }}
                        sizeClass={"small"} multiselect={false} selected={[phaseChartTimeInterval as number]}
                        >
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1H")} />
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1D")} />
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1W")} />
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1M")} />
                    </ABB.ToggleButtonGroup>
                </div>
            </div>
            <div id="sensors" className="temperature-sensors row">
                {temperatureSensors.map((pr, i) => <Widgets.WidgetSelector widgetConfig={pr} key={i} />)}
            </div>
        </>
    )
}
