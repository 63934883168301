import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ABB from '@abb/abb-common-ux-react';
import { AvailableSensor, getSensorDescription, getSensorName, InstalledSensor, sensors$ } from '../../services/sensor';
import { useReadable } from '../../observables/hooks';
import { delay } from '../../helpers';
import { Role } from '../../enums';
import { fireNotification } from '../../toasts';
import { SettingsSensor } from '../../widgets';
import { user$ } from '../../services/user';
import { useEffect } from 'react';

export function Sensors(): ReactElement {
	const { t } = useTranslation();
	const allSensors = useReadable(sensors$);
	const [autoTune, setAutoTune] = useState(true);
	const [channel, setChannel] = useState(null);
	const [isTuning, setIsTuning] = useState(false);
	const userRole = useReadable(user$)?.role ?? Role.None;
	const [sensors, setSensors] = useState<(InstalledSensor|AvailableSensor)[]>([]);

	useEffect(() => {
		setSensors(
			allSensors
				.filter((s) => s.exported)
				.sort((s1, s2) => {
					if (s1.installed && !s2.installed) {
						return -1;
					} else if (!s1.installed && s2.installed) {
						return 1;
					} else {
						const sensor1Name = getSensorName(s1.model, s1.roles);
						const sensor2Name = getSensorName(s2.model, s2.roles);
						if (sensor1Name < sensor2Name) {
							return -1;
						} else if (sensor1Name > sensor2Name) {
							return 1;
						}
						const sensor1Description = getSensorDescription(s1.model, s1.roles);
						const sensor2Description = getSensorDescription(s2.model, s2.roles);
						if (sensor1Description < sensor2Description) {
							return -1;
						} else if (sensor1Description > sensor2Description) {
							return 1;
						}
					}
					return 0;
				})
		);
	}, [allSensors]);

	const triggerAutoTune = useCallback(async () => {
		setIsTuning(true);
		await delay(5);
		setIsTuning(false);
		fireNotification({
			severity: 'success',
			text: t("TunedToChannel", { channel: 3 }),
			type: 'banner',
		});
	}, [t]);

	const cancelTuning = useCallback(() => {
		setIsTuning(false);
	}, []);
	return <>
		<p>{t("SensorsInfo")}</p>
		<h6 className='text-uppercase font-weight-bold pb-2 sensors-subtitle'>
			{t("Sensors")}
		</h6>
		<div className='sensors-auto-tune-wrapper'>
			<div className="d-flex flex-wrap">
				{isTuning ? <div className='d-flex align-items-center' style={{ width: '100%' }}>
					<div style={{ flexGrow: 1 }}>
						<ABB.LoadingIndicator
							determinate={false}
							color='blue'
							sizeClass='medium'
							text={t("TryingToTuneToChannel", { channel: Math.floor(Math.random() * 100) })}
						/>
					</div>
					<div>
						<ABB.Button
							type='discreet-blue'
							className='ml-2'
							onClick={() => cancelTuning()}
							text={t("CancelTuning")}
							sizeClass="small"
						/>
					</div>
				</div> : <>
					<div className='d-flex align-items-center flex-wrap'>
						<ABB.Checkbox label={t('Auto-tune')}
							value={autoTune}
							className="my-auto"
							sizeClass="small"
							onChange={() => setAutoTune(!autoTune)}
							allowIndeterminate={false}
						/>
						<ABB.Button
							className='ml-2'
							type='discreet-blue'
							sizeClass='small'
							text={t("TriggerAutoTune")}
							onClick={() => triggerAutoTune()}
						/>
					</div>
					<div className='d-flex align-items-center justify-content-end flex-wrap'>
						<ABB.Dropdown clearable={true}
							label={t('CommunicationChannel')}
							placeholder={t('Channel')}
							className="my-auto ml-4 sensors-horizontal-dropdown"
							disabled={autoTune}
							multiselect={false}
							value={channel === null ? [] : [{ value: channel }]}
							onChange={(current) => {
								if (current[0]?.value) {
									setChannel(current[0].value);
								} else {
									setChannel(null);
								}
							}}>
							{new Array(64).fill(0).map((_, ch) => (
								<ABB.DropdownOption key={'option-' + ch} label={ch.toString()} value={ch.toString()} />
							))}
						</ABB.Dropdown>
						<small className='ml-2 text-muted'>{t("AppliesToAll")}</small>
					</div></>}
			</div>
		</div>
		<div className='px-2'>
			<div className='row'>
				{sensors.map((sensor, i) => <div key={i} className='col-md-6 col-12 mt-3 px-2'>
					<SettingsSensor
						fireNotification={fireNotification}
						description={getSensorDescription(sensor.model, sensor.roles)}
						label={getSensorName(sensor.model, sensor.roles)}
						sensor={sensor}
						userRole={userRole}
					/>
				</div>)}
			</div>
		</div>
	</>;
}
