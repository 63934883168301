import * as ABB from '@abb/abb-common-ux-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components';
import { SaveThermalConfiguration, ThermalConfiguration as IThermalConfiguration } from '../../models/api/ThermalConfiguration';
import { apiService } from '../../services/api';
import { fireErrorNotification, fireNotification } from '../../toasts';
import { useMountedRef } from '../../utils/hooks';

const SaveThermalConfigurationDialog: React.FC<{
	data: SaveThermalConfiguration | null,
	isOpen: boolean,
	onClose: (wasConfirmed: boolean) => void,
}> = ({
	onClose,
	isOpen,
	data,
}) => {
		const { t } = useTranslation();
		const [loading, setLoading] = useState(false);
		const mountedRef = useMountedRef();
		const submitData = useCallback(async () => {
			if (!data) { return; }
			setLoading(true);
			try {
				await apiService.updateThermalConfiguration(data);
				fireNotification({
					severity: 'success',
					text: t("ThermalConfigurationUpdated"),
					type: 'banner',
				});
				onClose(true);
			} catch (err) {
				fireErrorNotification({
					text: t('ThermalConfigurationUpdateError'),
					type: 'banner',
					severity: 'alarm',
				}, err, t);
			} finally {
				if (mountedRef.current) {
					setLoading(false);
				}
			}
		}, [data, onClose, t, mountedRef]);

		return <ABB.Dialog
			isOpen={isOpen}
			dimBackground
			title={t("UpdateThermalConfiguration")}
		>
			<p>{t("UpdateThermalConfigurationMessage")}</p>
			<div className='text-right'>
				<ABB.Button disabled={loading} className='mr-2' text={t('Cancel')} onClick={() => onClose(false)} sizeClass='small' />
				<ABB.Button isLoading={loading} text={t('Confirm')} sizeClass='small' onClick={submitData} type='primary-blue' />
			</div>
		</ABB.Dialog>
	}


const ResetThermalConfigurationDialog: React.FC<{
	isOpen: boolean,
	onClose: (wasConfirmed: boolean) => void,
}> = ({
	onClose,
	isOpen,
}) => {
		const { t } = useTranslation();
		const [loading, setLoading] = useState(false);
		const mountedRef = useMountedRef();
		const submitData = useCallback(async () => {
			setLoading(true);
			try {
				await apiService.resetThermalConfiguration();
				fireNotification({
					severity: 'success',
					text: t("ThermalConfigurationReset"),
					type: 'banner',
				});
				onClose(true);
			} catch (err) {
				fireErrorNotification({
					text: t('ThermalConfigurationResetError'),
					type: 'banner',
					severity: 'alarm',
				}, err, t);
			} finally {
				if (mountedRef.current) {
					setLoading(false);
				}
			}
		}, [onClose, t, mountedRef]);

		return <ABB.Dialog
			isOpen={isOpen}
			dimBackground
			title={t("ResetThermalConfiguration")}
		>
			<p>{t("ResetThermalConfigurationMessage")}</p>
			<div className='text-right'>
				<ABB.Button disabled={loading} className='mr-2' text={t('Cancel')} onClick={() => onClose(false)} sizeClass='small' />
				<ABB.Button isLoading={loading} text={t('Confirm')} sizeClass='small' onClick={submitData} type='primary-blue' />
			</div>
		</ABB.Dialog>
	}

type BusbarCableConfigurationProps = {
	connectionTypeSelection: { label: string, value: string }[],
	onConnectionTypeSelectionChange: (newSelection: { label: string, value: string }[]) => void,
	thermalConfigurationData: IThermalConfiguration
};

const BusbarCableConfiguration: React.FC<BusbarCableConfigurationProps> = ({
	connectionTypeSelection,
	onConnectionTypeSelectionChange,
	thermalConfigurationData,
}) => {
	const { t } = useTranslation();
	return <><div className='col-12'>
		<ABB.Dropdown
			placeholder={t('Select a connection type')}
			value={connectionTypeSelection}
			label={t('Connection type')}
			onChange={(selection) => onConnectionTypeSelectionChange(selection)}>
			{Object.entries(thermalConfigurationData.DTE.default).map(([key, value], i) => <ABB.DropdownOption
				label={t(`thermalConfiguration:${value.name}`)}
				value={key}
				key={i}
			/>)}
		</ABB.Dropdown>
	</div>
		<div className='col-12'>
			<h6 className='mt-3 font-weight-bold'>{t(`thermalConfiguration:${thermalConfigurationData.DTE.description}`)}</h6>
		</div>
		<div className='col-6'>
			<ABB.Input
				dataType='number'
				disabled
				label={t('ThermalConfigurationConstantsInputLabelWarning', { type: 'DTE' })}
				value={thermalConfigurationData.DTE.default[connectionTypeSelection[0].value].warning} />
		</div>
		<div className='col-6'>
			<ABB.Input
				dataType='number'
				disabled
				label={t('ThermalConfigurationConstantsInputLabelAlarm', { type: 'DTE' })}
				value={thermalConfigurationData.DTE.default[connectionTypeSelection[0].value].alarm} />
		</div>
		<div className='col-12'>
			<h6 className='mt-3 font-weight-bold'>{t(`thermalConfiguration:${thermalConfigurationData.HTE.description}`)}</h6>
		</div>
		<div className='col-6'>
			<ABB.Input
				dataType='number'
				disabled
				label={t('ThermalConfigurationConstantsInputLabelWarning', { type: 'HTE' })}
				value={thermalConfigurationData.HTE.default[connectionTypeSelection[0].value].warning} />
		</div>
		<div className='col-6'>
			<ABB.Input
				dataType='number'
				disabled
				label={t('ThermalConfigurationConstantsInputLabelAlarm', { type: 'HTE' })}
				value={thermalConfigurationData.HTE.default[connectionTypeSelection[0].value].alarm} />
		</div>
		<div className='col-12'>
			<h6 className='mt-3 font-weight-bold'>{t(`thermalConfiguration:${thermalConfigurationData.TRE.description}`)}</h6>
		</div>
		<div className='col-6'>
			<ABB.Input
				dataType='number'
				disabled
				label={t('ThermalConfigurationConstantsInputLabelWarning', { type: 'TRE' })}
				value={thermalConfigurationData.TRE.default[connectionTypeSelection[0].value].warning} />
		</div>
		<div className='col-6'>
			<ABB.Input
				dataType='number'
				disabled
				label={t('ThermalConfigurationConstantsInputLabelAlarm', { type: 'TRE' })}
				value={thermalConfigurationData.TRE.default[connectionTypeSelection[0].value].alarm} />
		</div></>
}

export const ThermalConfiguration: React.FC = () => {
	const { t } = useTranslation();
	const [thermalConfigurationData, setThermalConfigurationData] = useState<IThermalConfiguration | null>(null);
	const [saveThermalData, setSaveThermalData] = useState<SaveThermalConfiguration | null>(null);
	const [busbarConnectionTypeSelection, setBusbarConnectionTypeSelection] = useState<{ label: string, value: string }[]>([]);
	const [cableConnectionTypeSelection, setCableConnectionTypeSelection] = useState<{ label: string, value: string }[]>([]);
	const [showSaveDialog, setShowSaveDialog] = useState(false);
	const [showResetDialog, setShowResetDialog] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const mountedRef = useMountedRef();
	const fetchData = useCallback(async () => {
		try {
			setIsFetching(true);
			const data = await apiService.getThermalConfiguration();
			if (mountedRef.current) {
				setThermalConfigurationData(data);
				setSaveThermalData({
					constants: {
						DTE: data.DTE.value,
						HTE: data.HTE.value,
						TRE: data.TRE.value,
					},
					applications: {
						DTE_Tau_DecrCur_BusBar: data.DTE_Tau_DecrCur_BusBar.value,
						DTE_Tau_DecrCur_Cable: data.DTE_Tau_DecrCur_Cable.value,
						DTE_Tau_DecrCur_Lower: data.DTE_Tau_DecrCur_Lower.value,
						DTE_Tau_DecrCur_Upper: data.DTE_Tau_DecrCur_Upper.value,
						DTE_Tau_IncrCur_Upper: data.DTE_Tau_IncrCur_Upper.value,
						DTE_Tau_IncrCurr_BusBar: data.DTE_Tau_IncrCurr_BusBar.value,
						DTE_Tau_IncrCurr_Cable: data.DTE_Tau_IncrCurr_Cable.value,
						DTE_Tau_IncrCurr_Lower: data.DTE_Tau_IncrCurr_Lower.value,
						K_BusBars: data.K_BusBars.value,
						K_Cables: data.K_Cables.value,
						K_Contacts: data.K_Contacts.value,
					}
				});
				const options = Object.entries(data.DTE.default);
				const selectedBusbarOption = options.find(([_value, optData]) => optData.warning === data.DTE.value.busbar.warning && optData.alarm === data.DTE.value.busbar.alarm);
				setBusbarConnectionTypeSelection(selectedBusbarOption ? [{ label: t(`thermalConfiguration:${selectedBusbarOption[1].name}`), value: selectedBusbarOption[0] }] : [{ value: options[0][0], label: t(`thermalConfiguration:${options[0][1].name}`) }]);
				const selectedCableOption = options.find(([_value, optData]) => optData.warning === data.DTE.value.cable.warning && optData.alarm === data.DTE.value.cable.alarm);
				setCableConnectionTypeSelection(selectedCableOption ? [{ label: t(`thermalConfiguration:${selectedCableOption[1].name}`), value: selectedCableOption[0] }] : [{ value: options[0][0], label: t(`thermalConfiguration:${options[0][1].name}`) }]);
			}
		} catch (err) {
			console.error(err);
			fireErrorNotification({ text: t('ThermalConfigurationFetchError'), severity: 'alarm', type: 'banner' }, err, t);
		} finally {
			if (mountedRef.current) {
				setIsFetching(false);
			}
		}
	}, [t, mountedRef]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (!thermalConfigurationData || busbarConnectionTypeSelection.length === 0 || cableConnectionTypeSelection.length === 0) { return; }
		setSaveThermalData((previous) => {
			if (previous === null) { return null; }
			const getConstantsSelection = (key: 'DTE' | 'HTE' | 'TRE') => {
				return {
					busbar: {
						alarm: thermalConfigurationData[key].default[busbarConnectionTypeSelection[0].value].alarm,
						warning: thermalConfigurationData[key].default[busbarConnectionTypeSelection[0].value].warning,
					},
					cable: {
						alarm: thermalConfigurationData[key].default[cableConnectionTypeSelection[0].value].alarm,
						warning: thermalConfigurationData[key].default[cableConnectionTypeSelection[0].value].warning,
					},
				}
			};
			return {
				...previous,
				constants: {
					DTE: getConstantsSelection('DTE'),
					HTE: getConstantsSelection('HTE'),
					TRE: getConstantsSelection('TRE'),
				}
			};
		})
	}, [busbarConnectionTypeSelection, cableConnectionTypeSelection, thermalConfigurationData]);


	const validators = useMemo(
		() => {
			const numberValidation = (v: string | null | undefined) => {
				if (v === null || v === undefined) {
					return {
						valid: false,
						text: t("Required"),
					}
				}
				if (Number.isNaN(Number(v))) {
					return {
						valid: false,
						text: t("Invalid number"),
					}
				}
				return { valid: true };
			}
			return {
				DTE_Tau_DecrCur_BusBar: numberValidation,
				DTE_Tau_DecrCur_Cable: numberValidation,
				DTE_Tau_DecrCur_Lower: numberValidation,
				DTE_Tau_DecrCur_Upper: numberValidation,
				DTE_Tau_IncrCur_Upper: numberValidation,
				DTE_Tau_IncrCurr_BusBar: numberValidation,
				DTE_Tau_IncrCurr_Cable: numberValidation,
				DTE_Tau_IncrCurr_Lower: numberValidation,
				K_BusBars: numberValidation,
				K_Cables: numberValidation,
				K_Contacts: numberValidation,
			};
		},
		[t]
	);

	const [valid, setValid] = useState(false);

	const updateValidity = useCallback(() => {
		if (!saveThermalData) {
			setValid(false);
		} else {
			const applicationsValid = !Object.entries(validators)
				.some(([key, validator]) => !validator(saveThermalData.applications[key as keyof typeof saveThermalData.applications]).valid);
			setValid(applicationsValid);
		}
	}, [saveThermalData, setValid, validators]);

	useEffect(() => {
		updateValidity();
	}, [updateValidity]);

	return <>
		{thermalConfigurationData && saveThermalData && !isFetching ? <>
			<div className='row'>
				<Card desktopCols={12} mobileCols={12} tabletCols={12} title={t("Busbar")}>
					<div className='row'>
						<BusbarCableConfiguration
							connectionTypeSelection={busbarConnectionTypeSelection}
							onConnectionTypeSelectionChange={(newSelection) => setBusbarConnectionTypeSelection(newSelection)}
							thermalConfigurationData={thermalConfigurationData} />
					</div>
				</Card>
				<Card desktopCols={12} mobileCols={12} tabletCols={12} title={t("Cable")}>
					<div className='row'>
						<BusbarCableConfiguration
							connectionTypeSelection={cableConnectionTypeSelection}
							onConnectionTypeSelectionChange={(newSelection) => setCableConnectionTypeSelection(newSelection)}
							thermalConfigurationData={thermalConfigurationData}
						/>
					</div>
				</Card>
				<Card desktopCols={12} tabletCols={12} mobileCols={12} title={t("Applications")}>
					<div className='row'>
						{[
							'DTE_Tau_DecrCur_BusBar',
							'DTE_Tau_IncrCurr_BusBar',
							'DTE_Tau_DecrCur_Cable',
							'DTE_Tau_IncrCurr_Cable',
							'DTE_Tau_DecrCur_Lower',
							'DTE_Tau_IncrCurr_Lower',
							'DTE_Tau_DecrCur_Upper',
							'DTE_Tau_IncrCur_Upper',
							'K_BusBars',
							'K_Cables',
							'K_Contacts',
						].map((key) => ({ key, value: saveThermalData.applications[key as keyof typeof saveThermalData.applications] })).map(({ key, value }, i) => <div key={i} className='mt-2 col-12 col-sm-6'>
							<ABB.Input
								dataType='number'
								step={0.1}
								onValueChange={(v) => {
									saveThermalData.applications[key as keyof typeof saveThermalData.applications] = v;
									setSaveThermalData({ ...saveThermalData });
								}}
								required
								value={value}
								label={t(`thermalConfiguration:${thermalConfigurationData[key as keyof typeof thermalConfigurationData].description}`)}
							/>
						</div>)}
					</div>
				</Card>
				<div className='col-12 mt-4 text-center'>
					<ABB.Button
						disabled={!valid}
						text={t("SaveThermalConfiguration")}
						className="mr-2"
						sizeClass='small'
						icon='abb/save'
						type='primary-blue'
						onClick={() => setShowSaveDialog(true)} />
					<ABB.Button
						text={t("ResetThermalConfiguration")}
						sizeClass="small"
						icon="abb/turn-counter-clockwise"
						onClick={() => setShowResetDialog(true)}
					/>
				</div>
			</div>
		</> : <div className='d-flex justify-content-center my-5'>
			<ABB.Spinner color='blue' sizeClass='large' />
		</div>}
		<SaveThermalConfigurationDialog
			isOpen={showSaveDialog}
			onClose={(wasConfirmed) => {
				setShowSaveDialog(false);
				if (wasConfirmed) {
					fetchData();
				}
			}}
			data={saveThermalData}
		/>
		<ResetThermalConfigurationDialog
			isOpen={showResetDialog}
			onClose={(wasConfirmed) => {
				setShowResetDialog(false);
				if (wasConfirmed) {
					fetchData();
				}
			}}
		/>
	</>;
}
