import React from "react";
import { Card } from "../../components";
import { WidgetProps } from "../../models"
import { BootstrapHelper } from "../../helpers";

export interface ImageWidgetProps extends WidgetProps {
  src: string;
}

export function ImageWidget(props: ImageWidgetProps) {
  return (
    <Card hideTitle={true}
			installed={props.installed}
			disconnected={props.disconnected}
      mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)} 
      tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)} 
      desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}
      contentClassName="d-flex align-items-center justify-content-center">
      <img src={props.src} style={{ width: "100%", maxWidth:"300px"}} alt={props.src}></img>
    </Card>
  );
}
