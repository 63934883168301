import { useEffect, useCallback, useState } from 'react';
import './style.scss';

export interface AspectRatioProps {
	ratio: number,
	minWidth?: number,
	children: React.ReactElement|React.ReactElement[];
}

export function AspectRatio({ ratio, children, minWidth }: AspectRatioProps) {
	const [wrapperRef, setWrapperRef] = useState<HTMLDivElement|null>(null);

	const wrapperRefCallback = useCallback((node) => {
		setWrapperRef(node);
	}, [setWrapperRef]);

	useEffect(() => {
		if (wrapperRef) {
			wrapperRef.style.paddingBottom = 100 / ratio + '%';
		}
	}, [wrapperRef, ratio]);

	return (
		<div style={{
			minWidth
		}}>
			<div className="aspect-ratio-wrapper" ref={wrapperRefCallback}>
				<div className="aspect-ratio-content">
					{children}
				</div>
			</div>
		</div>
	);
}
