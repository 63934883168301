import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ABB from '@abb/abb-common-ux-react';
import { delay } from '../../helpers';
import { fireErrorNotification, fireNotification } from '../../toasts';

export function RoutineTest(): ReactElement {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const routineAcquisition = useCallback(async () => {
		setIsLoading(true);
		console.debug('Running routine acquisition...');
		try {
			await delay(1);
			if (Math.random() > 0.8) {
				throw new Error('test error');
			}
			fireNotification({
				severity: 'success',
				text: t('RoutineAcquisitionSuccessful'),
				type: 'banner',
			});
		} catch (err) {
			console.error('Routine acquisition error', err);
			fireErrorNotification({
				severity: 'alarm',
				text: t('AnErrorOccurred'),
				type: 'banner',
			}, err, t);
		}
		setIsLoading(false);
	}, [t]);
	return <>
		<p>{t("RoutineTestIntro")}</p>
		<ABB.Button 
			text={t("RunRoutineAcquisition")} 
			type="primary-blue" 
			sizeClass='small' 
			className='mt-3' 
			isLoading={isLoading}
			onClick={() => routineAcquisition()}/>
	</>;
}
