import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface DurationProps {
	durationMs: number|null,
	displayUnit: string,
	text: string,
}

export function Duration({ durationMs, displayUnit, text }: DurationProps): ReactElement {
	const { t } = useTranslation();
	const divideBy = displayUnit === 's' ? 1000 : 1;
	return <span>
		{t(text)}: {durationMs !== null ? `${(durationMs / divideBy).toFixed(1)}${displayUnit}` : t("N/A")}
	</span>
}
