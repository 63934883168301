import React from "react";
import { WidgetProps } from "../../models"

export interface ImageWidgetWithOverlaysProps extends WidgetProps {
	src: string;
	layers: string[],
	visibleLayerIndices: number[],
}

export function ImageWidgetWithOverlays({ mobileCols, tabletCols, desktopCols, src, layers, visibleLayerIndices }: ImageWidgetWithOverlaysProps) {
	return (
		<div style={{
			position: 'relative',
			width: '100%'
		}}>
			<img src={src} style={{ width: "100%" }} alt={src}></img>
			{layers
				.map((url, index) => <img key={url} src={url} style={{
					width: "100%",
					position: 'absolute',
					top: 0,
					left: 0,
					opacity: visibleLayerIndices.includes(index) ? '1' : '0',
					transition: 'opacity 500ms ease'
				}} alt={url}></img>)}
		</div>
	);
}
