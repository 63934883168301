import { chartFilters$, ChartPropsConfig, getChartDataList, readThresholds } from '.';
import { ChartLastOperation } from '../../../enums';
import { DateTimeFormatHelper } from '../../../helpers';
import { LastUpdateType } from '../../../models';
import { NamedSource, PathSource, WidgetConfig } from '../../../pages/DynamicPage';
import { roundNumber } from '../../../utils/number';
import { ChartDotStyle, ChartLineStyle, SingleLineChartProps } from '../../../widgets';

export async function loadSingleLineChartData(config: WidgetConfig, props: Partial<SingleLineChartProps>): Promise<SingleLineChartProps> {
	console.info("loadSingleLineChartData");
	const singleLineChart = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as SingleLineChartPropsConfig;
		readThresholds(singleLineChart, propsConfig, propsConfig.dataConversion?.value);
		let boundaries = undefined;
		if (singleLineChart.alarmHigh !== undefined) {
			boundaries = { max: singleLineChart.alarmHigh * (1 + (singleLineChart.alarmHigh - (singleLineChart.alarmLow ?? 0)) * 0.05) };
		}
		if (singleLineChart.alarmLow !== undefined) {
			boundaries = { ...(boundaries ?? {}), min: singleLineChart.alarmLow * (1 - ((singleLineChart.alarmHigh ?? 100) - singleLineChart.alarmLow) * 0.05) };
		}
		const scale = (propsConfig.filterRef && chartFilters$.getValue()[propsConfig.filterRef!.value]) ?? ChartLastOperation.MIN;
		if (Object.values(ChartLastOperation).includes(scale as any)) {
			singleLineChart.xAxisType = 'category';
		} else {
			singleLineChart.xAxisType = propsConfig.xAxisType?.value ?? 'date';
		}
		const data = await getChartDataList(scale, boundaries, propsConfig.data.source);
		if (propsConfig.dataConversion?.value) {
			data.data = data.data.map((sample) => {
				return {
					...sample,
					...(Object.keys(sample) as (keyof typeof sample)[])
						.filter((k) => k.startsWith('value'))
						// eslint-disable-next-line eqeqeq
						.filter((k) => sample[k] != undefined)
						.reduce((mapped, k) => {
							mapped[k] = roundNumber((sample[k] as any) / propsConfig.dataConversion!.value);
							return mapped;
						}, {} as Partial<typeof sample>)
				};
			})
		}
		if (singleLineChart.alarmHighTh !== undefined && singleLineChart.alarmHigh !== undefined) {
			const avgValue = singleLineChart.showAsPercentage ? (singleLineChart.alarmHigh / singleLineChart.alarmHighTh) : (singleLineChart.alarmHigh - singleLineChart.alarmHighTh);
			data.data = data.data.map((sample) => {
				return {
					...sample,
					...(Object.keys(sample) as (keyof typeof sample)[])
						.filter((k) => k.startsWith('value'))
						// eslint-disable-next-line eqeqeq
						.filter((k) => sample[k] != undefined)
						.reduce((mapped, k) => {
							if (singleLineChart.showAsPercentage) {
								mapped[k] = (sample[k] as any) / avgValue;
							} else {
								mapped[k] = (sample[k] as any) - avgValue;
							}
							return mapped;
						}, {} as Partial<typeof sample>)
				};
			});
		}
		if (singleLineChart.xAxisType === 'category') {
			data.data = data.data.map((sample, i) => ({
				...sample,
				category: (i + 1).toString(),
			}))
		}
		singleLineChart.data = data.data;
		singleLineChart.lastUpdate = DateTimeFormatHelper.toDate(data.lastValue.date_us);
		singleLineChart.lastUpdateType = LastUpdateType.Sample;

		singleLineChart.fetching = false;

	} catch (e) {
		console.error("loadSingleLineChartData", e);
		singleLineChart.exception = e;
	}
	return singleLineChart as SingleLineChartProps;
}

export interface SingleLineChartPropsConfig extends ChartPropsConfig {
	data: {
		source: NamedSource,
	},
	title: {
		i18n: string,
	},
	color: {
		value: string,
	},
	lineStyle: {
		value: ChartLineStyle,
	},
	dotStyle: {
		value: ChartDotStyle,
	},
	highWarnLabel?: {
		i18n: string,
	},
	highAlarmLabel?: {
		i18n: string,
	},
	alarmHighTh?: {
		source: PathSource
	},
	alarmLowTh?: {
		source: PathSource
	},
	alarmHigh?: {
		source: PathSource
	},
	alarmLow?: {
		source: PathSource
	},
	warningHighTh?: {
		source: PathSource
	},
	warningLowTh?: {
		source: PathSource
	},
	warningHigh?: {
		source: PathSource
	},
	warningLow?: {
		source: PathSource
	},
	xAxisType?: {
		value: 'date' | 'value' | 'category'
	},
	disableYCursor?: {
		value: boolean
	},
	fill?: {
		value: boolean
	},
	hideAxes?: {
		value: boolean
	},
	showHorizontalGrid?: {
		value: boolean
	},
	xAxisLabel?: {
		i18n: string,
	},
	yAxisLabel?: {
		i18n: string,
	},
	showVariation?: {
		value: boolean,
	},
	showDateInTooltip?: {
		value: boolean,
	},
	infoText?: {
		i18n: string,
	},
	showZero?: {
		value: boolean,
	},
	hideXLabel?: {
		value: boolean,
	},
	dataConversion?: {
		value: number,
	},
	showAsPercentage?: {
		value: boolean,
	},
	showUnitInTooltip?: {
		value: boolean,
	},
	showThresholdValue?: {
		value: boolean,
	},
	unit?: {
		value: string,
	},
}
