import { LocalizationHelper } from '../helpers';
import { ChartScaleType, ChartValueProperty } from '../models';
import { WidgetConfig } from '../pages/DynamicPage';
import { PageComponents, PageConfig } from '../pages/PageComponentSelector';
import { DocumentsProps, TrendsProps } from '../sections';
import { FireNotificationParams } from '../toasts';
import { DataTableProps, DataTablePropsConfig, PhaseSensorProps, TableColumn, TableSort } from '../widgets';
import { getUnit } from './polling';
import { PhaseSensorPropsConfig } from './polling/phase-sensor';

export function getPageProps(w: PageConfig): PageProps {

	console.info("loading page " + w.component + " props");

	switch (w.component) {
		case PageComponents.PhaseSensorSection:
			{
				const propsConfig = w.propsConfig as unknown as PhaseSensorSectionPropsConfig;
				const armMappings = propsConfig.temperatureSamples.map((ts, i) => ({
					color: ts.color.value,
					key: `value${i ? (i + 1) : ''}` as ChartValueProperty,
					name: LocalizationHelper.getTranslation(ts.name.i18n),
				}));
				let mappingIndex = armMappings!.length + 1;
				const highWarnKey: ChartValueProperty|undefined = propsConfig.highWarn?.source ? (`value${mappingIndex++}` as ChartValueProperty) : undefined;
				const highAlarmKey: ChartValueProperty|undefined = propsConfig.highAlarm?.source ? (`value${mappingIndex++}` as ChartValueProperty) : undefined;
				const lowWarnKey: ChartValueProperty|undefined = propsConfig.lowWarn?.source ? (`value${mappingIndex++}` as ChartValueProperty) : undefined;
				const lowAlarmKey: ChartValueProperty|undefined = propsConfig.lowAlarm?.source ? (`value${mappingIndex++}` as ChartValueProperty) : undefined;
				const loadKey: ChartValueProperty = `value${mappingIndex}` as ChartValueProperty;
				const props: PhaseSensorSectionProps = {
					component: w.component,
					fireNotification: w.fireNotification,
					widgetConfigs: w.widgetConfigs,
					enableExport: propsConfig.enableExport?.value ?? false,
					dataTable: {
						columns: propsConfig.columns.map((c) => new TableColumn(c.displayField.value, LocalizationHelper.getTranslation(c.text.i18n), c.sortable.value, c.className?.value, c.sortField?.value)),
						rows: [],
						defaultSort: [ new TableSort(propsConfig.defaultSortIndex.value, propsConfig.defaultSortOrder.value) ],
						title: LocalizationHelper.getTranslation(propsConfig.dataTableTitle.i18n),
					},
					phaseSensor: {
						data: [],
						title: LocalizationHelper.getTranslation(propsConfig.phaseSensorTitle.i18n),
						linkUrl: propsConfig.linkUrl?.value,
						fetching: true,
						browsable: propsConfig.browsable.value,
						hideYAxis: propsConfig.hideYAxis?.value ?? true,
						showHorizontalGrid: propsConfig.showHorizontalGrid?.value ?? false,
						showUnitInYLabel: propsConfig.showUnitInYLabel?.value ?? false,
						unit: getUnit(propsConfig.temperatureSamples[0].source) ?? "",
						armMappings,
						highAlarmKey,
						highWarnKey,
						lowAlarmKey,
						lowWarnKey,
						loadKey,
						yAxisLabel: propsConfig.yAxisLabel ? LocalizationHelper.getTranslation(propsConfig.yAxisLabel.i18n) : undefined,
					},
					filter: {
						filterRef: propsConfig.filterRef.value,
						defaultValue: propsConfig.defaultFilter.value,
					}
				}
				return props;
			}
		case PageComponents.Trends:
			{
				const propsConfig = w.propsConfig as unknown as TrendsPropsConfig|undefined;
				const props: TrendsProps = {
					component: w.component,
					fireNotification: w.fireNotification,
					widgetConfigs: w.widgetConfigs,
					tableSelector: propsConfig?.tableSelector?.value ?? false,
				};
				return props;
			}
		case PageComponents.Documents:
			{
				const propsConfig = w.propsConfig as unknown as DocumentsPropsConfig;
				const props: DocumentsProps = {
					component: w.component,
					fireNotification: w.fireNotification,
					widgetConfigs: w.widgetConfigs,
					displayMode: propsConfig.displayMode?.value ?? 'list',
					acceptedFileExtensions: propsConfig.acceptedFileExtensions,
				};
				return props;
			}
		default:
			{
				return {
					component: w.component,
					fireNotification: w.fireNotification,
					widgetConfigs: w.widgetConfigs,
				};
			}
	}
}

export interface PageProps {
	fireNotification: (data: FireNotificationParams) => void,
	component: PageComponents,
	widgetConfigs: WidgetConfig[],
}

export type PhaseSensorSectionProps = PageProps 
	& { phaseSensor: PhaseSensorProps, dataTable: DataTableProps, filter: { filterRef: string, defaultValue?: ChartScaleType }, enableExport: boolean };

export type PhaseSensorSectionPropsConfig = Omit<PhaseSensorPropsConfig, "title"> 
& Omit<DataTablePropsConfig, "title">
& {
	phaseSensorTitle: { i18n: string },
	dataTableTitle: { i18n: string },
	filterRef: { value: string },
	defaultFilter: { value: ChartScaleType },
	browsable: { value: boolean },
	enableExport?: { value: boolean },
};

export interface TrendsPropsConfig {
	tableSelector?: {
		value: boolean,
	}
}

export interface DocumentsPropsConfig {
	displayMode?: {
		value: 'table'|'list'
	},
	acceptedFileExtensions: string[]
}
