import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { HashRouter } from "react-router-dom";
import { ScrollToTop } from './components';
import { Simulator } from './simulator/simulator';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.options.minPolylineStep = 2;
am4core.useTheme(am4themes_animated);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// import * as serviceWorker from './serviceWorker';
// serviceWorker.unregister();

const element = window.location.search !== "?simulator" ?
    <HashRouter>
        <ScrollToTop />
        <App />
    </HashRouter>
    :
    <Simulator />;

ReactDOM.render(element, document.getElementById('root'));
