import { DateTimeFormatHelper } from "../../helpers";
import { NamedSource, PathSource, WidgetConfig } from "../../pages/DynamicPage";
import { SystemInfoProps } from "../../widgets";
import { loadGauge } from "./gauges";

export async function loadSystemInfoData(
	config: WidgetConfig,
	props: Partial<SystemInfoProps>
): Promise<SystemInfoProps> {
	console.info("loadSystemInfoData");
	const systemInfo = { ...props };
	systemInfo.gauge = { ...systemInfo.gauge! };
	try {
		const propsConfig = config.propsConfig as unknown as SystemInfoPropsConfig;
		await loadGauge("loadSystemInfoData", systemInfo, systemInfo.gauge!, {
			value: propsConfig.gauge.source,
		});

		systemInfo.fetching = false;
	} catch (e) {
		console.error("loadSystemInfoData", e);
		systemInfo.exception = e;
	}
	return systemInfo as SystemInfoProps;
}

export function getNameplateData(values: Record<string, any> | undefined) {
	const nameplate: Record<string, any> = values?.nameplate ?? {};
	return {
		assetLabel: nameplate.assetLabel ?? "",
		modelRating: nameplate.modelAndRatings ?? "",
		serialNumber: nameplate.serialNumber ?? "",
		manufacturingDate:
			(nameplate.manufacturingDateUs &&
				DateTimeFormatHelper.toDate(nameplate.manufacturingDateUs)) ||
			"",
		installationDate:
			(nameplate.installationDateUs &&
				DateTimeFormatHelper.toDate(nameplate.installationDateUs)) ||
			"",
		warrantyEnd: nameplate.warrantyStatus ?? "",
	};
}

export interface SystemInfoPropsConfig {
	gauge: {
		source: NamedSource;
	};
	lowWarn: {
		source: PathSource;
	};
	lowAlarm: {
		source: PathSource;
	};
	min: {
		source: PathSource;
	};
	max: {
		source: PathSource;
	};
	deviceName: {
		source: PathSource;
	};
	displayImage: {
		value: boolean;
	};
	displayInfo: {
		value: boolean;
	};
	showAsPercentage?: {
		value: boolean;
	};
}
