import React, { ReactElement, useCallback, useEffect, useState } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { Card, InfoPopup } from "../../components";
import { WidgetProps } from "../../models"
import { GaugeProps } from "../../widgets"
import { SectionsHelper, BootstrapHelper } from "../../helpers"
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface GaugeInfoProps extends WidgetProps {
	gauge: GaugeProps;
	description: string;
	info?: string;
	discoverMoreLink?: string;
	size: 's' | 'm' | 'l';
	orientation: 'row' | 'column';
}

export function GaugeInfo(props: GaugeInfoProps): ReactElement {

	let hist = useHistory();
	let { t } = useTranslation();

	const getGauge = useCallback((g) => ({
		...g,
		min: g.showAsPercentage ? 0 : g.min,
		max: g.showAsPercentage ? 100 : g.max,
		value: g.showAsPercentage ? Math.ceil(g.value / g.max * 100) : g.value,
	}), []);

	const [gaugeData, setGaugeData] = useState(getGauge(props.gauge));
	const [size, setSize] = useState({ height: '150px', width: '190px' });

	useEffect(() => {
		if (props.size === 's') {
			setSize({ height: '150px', width: '190px' });
		} else if (props.size === 'm') {
			setSize({ height: '190px', width: '240px' });
		} else if (props.size === 'l') {
			setSize({ height: '230px', width: '290px' });
		}
	}, [props.size]);

	useEffect(() => {
		setGaugeData(getGauge(props.gauge));
	}, [props.gauge, getGauge]);

	return (
		<Card title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
			className={props.discoverMoreLink ? "browsable" : ""}
			installed={props.installed}
			disconnected={props.disconnected}
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(4, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}>
			<div className={`d-flex flex-${props.orientation} justify-content-around align-items-center`}>
				{(props.description || props.info) && <div className="d-flex">
					<span className="">{props.description}</span>
					<InfoPopup info={props.info} />
				</div>}
				<ABB.Gauge
					{...gaugeData}
					style={{ ...size, marginTop: "-25px" }} />
			</div>

			{props.discoverMoreLink && <div className="text-center"><a href={SectionsHelper.createHrefByString(props.discoverMoreLink, hist)}>{t('DiscoverMore')}</a></div>}
		</Card>
	);
}
