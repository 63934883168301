import { ReactElement, useMemo } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { Card } from '../../components';
import { HealthIndexStatus } from '../../enums';
import { WidgetProps } from '../../models';
import { HealthIndexStatusHelper } from '../../helpers';
import { getColor } from '../../helpers/css';
import { useTranslation } from 'react-i18next';
import './style.scss';

export interface StatusDetailsWithIconProps extends WidgetProps {
	icon: string,
	status: HealthIndexStatus|null,
	lastErrorDuration: number,
	description: string,
	lowAlarm: number,
	lowWarn: number,
}

export function StatusDetailsWithIcon({
	desktopCols,
	mobileCols,
	tabletCols,
	title,
	lastUpdate,
	lastUpdateType,
	icon,
	status,
	description,
	lastErrorDuration,
	installed,
	disconnected
}: StatusDetailsWithIconProps): ReactElement {
	const color = useMemo(() => {
		return status !== null ? getColor(HealthIndexStatusHelper.getColor(status)) : getColor('muted-color');
	}, [status]);
	const { t } = useTranslation();

	const lastErrorHours = Math.floor(lastErrorDuration / 1000 / 60 / 60);
	const lastErrorMinutes = Math.round((lastErrorDuration - lastErrorHours * 1000 * 60 * 60) / 1000 / 60);
	return <Card
		desktopCols={desktopCols ?? 4}
		tabletCols={tabletCols ?? 6}
		mobileCols={mobileCols ?? 12}
		title={title}
		disconnected={disconnected}
		installed={installed}
		contentClassName='d-flex align-items-center justify-content-between flex-column m-0'
		comment={{ lastUpdateType: lastUpdateType, lastUpdate: lastUpdate }}
	>
		<div />
		<div>
			<h6 className='font-weight-bold text-center'>{description}</h6>
			<div className='d-flex justify-content-center'>
				<ABB.Icon sizeClass='large' name={icon} />
				<svg viewBox="0 0 32 32" height={32}>
					<circle cx="16" cy="16" r="16" fill={color} />
				</svg>
			</div>
		</div>
		<div className="mt-2 status-details-with-icon-alert">
			{(lastErrorHours || lastErrorMinutes) ? <span>
				{t("Last error lasted")}
				<span className='font-weight-bold'> {lastErrorHours}:{lastErrorMinutes.toString().padStart(2, '0')} {t("hrs")}</span>
			</span> :
				<span>
					{t("NoPreviousErrorsFound")}
				</span>
			}
		</div>
	</Card>
}
