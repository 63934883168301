export enum ChartScaleOptions {
  TimeRange = "TimeRange",
  DateRange = "DateRange",
  LastOperation = "LastOperation",
	Date = "Date",
}

export enum ChartTimeRange {
  Hour = 0,
  Day = 1,
  Week = 2,
  Month = 3
}

export enum ChartLastOperation {
  MIN = 10,
  MID = 20,
  ALL = 30
}
