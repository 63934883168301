import React, { ReactElement } from 'react';
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from '../../components';
import { WidgetProps } from "../../models"
import { BootstrapHelper } from '../../helpers';

export interface SliderProps extends ABB.SliderProps, WidgetProps {
}

export function Slider(props: SliderProps): ReactElement {
    
    return (
        <Card title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
            mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)} 
            tabletCols={BootstrapHelper.getColValue(4, props.tabletCols)} 
            desktopCols={BootstrapHelper.getColValue(3, props.desktopCols)}>
            <ABB.Slider label={props.label}
                type={props.type}
                min={props.min}
                max={props.max}
                step={props.step}
                value={props.value}
                highlight={props.highlight}
                showTooltip={props.showTooltip} />
        </Card>
    )
}
