import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from '../../components';
import { WidgetProps } from '../../models';
import './style.scss';
import { getColor } from '../../helpers/css';
import { useTranslation } from 'react-i18next';
import { WaveformChart, WaveformChartProps } from '..';
import { getPollerFetchingStatus, refreshPollerById } from '../../services/polling/startup';
import { useReadable } from '../../observables/hooks';
import { chartFilters$ } from '../../services/polling/chart';
import { BehaviorSubject } from 'rxjs';
import { DateTimeFormatHelper } from '../../helpers';
import { SectionTitle } from '../SectionTitle/SectionTitle';

export interface ActuationEventsProps extends WidgetProps {
	eventType$: BehaviorSubject<'open' | 'close'>,
	selectedEvent$: BehaviorSubject<{ timestamp: number, groupId: number }|null>,
	defaultDate?: Date,
	events: { timestamp: number, groupId: number }[],
	charts: WaveformChartProps[],
	pollerId: string,
	filterRef: string,
	separators: string[],
}

export function ActuationEvents(props: ActuationEventsProps): ReactElement {
	const { t } = useTranslation();
	const svgRef = useRef<SVGSVGElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const chartFilters = useReadable(chartFilters$);
	const selectedFilter = chartFilters[props.filterRef];
	const eventType = useReadable(props.eventType$);
	const [eventLines, setEventLines] = useState<SVGLineElement[]>([]);
	const eventLinesColor = useMemo(() => eventType === "close" ? getColor("color-status-red") : getColor("color-status-green"), [eventType]);
	const selectedEvent = useReadable(props.selectedEvent$);
	const loading = useReadable(getPollerFetchingStatus(props.pollerId));
	const [showEvents, setShowEvents] = useState(true);
	const [scale, setScale] = useState(1);
	const [translateX, setTranslateX] = useState(0);
	const [isDragging, setIsDragging] = useState(false);
	const [previousDragX, setPreviousDragX] = useState(0);
	const [lineTooltipData, setLineTooltipData] = useState<{ x: number, scale: number, translateY: number, opacity: number, text: string, y: number }|null>(null);
	const svgWidth = 2400;
	useEffect(() => {
		const allEvents = props.events.filter((e) => e);
		const eventsDistance = (allEvents.length > 0 ? Math.abs(allEvents[0].timestamp - allEvents[allEvents.length - 1].timestamp) : 1) || 1;
		const min = allEvents.length > 0 ? Math.min(allEvents[0].timestamp, allEvents[allEvents.length - 1].timestamp) : 0;
		const adapter = (svgWidth * scale) / eventsDistance;
		setEventLines(
			allEvents.map((e, i) => {
				const event = DateTimeFormatHelper.toDate(e.timestamp)!;
				const isSelected = e.groupId === selectedEvent?.groupId && e.timestamp === selectedEvent?.timestamp;
				const strokeWidth = !isSelected ? 10 : 14;
				const xPos = Math.min(Math.max((e.timestamp - min) * adapter, strokeWidth), svgWidth * scale - strokeWidth);
				const y1 = !isSelected ? 15 : 5;
				const y2 = !isSelected ? 60 : 70;
				return <line
					onClick={() => {
						props.selectedEvent$.next(e);
						refreshPollerById(props.pollerId);
					}}
					key={`event-${i}`}
					x1={xPos}
					x2={xPos}
					y1={y1}
					y2={y2}
					strokeWidth={strokeWidth}
					stroke={eventLinesColor}
					cursor="pointer"
					style={{ transform: `scaleX(${1 / scale})` }}
					onMouseEnter={(e) => {
						const svgRefRect = svgRef.current!.getBoundingClientRect();
						setLineTooltipData({
							x: e.clientX,
							opacity: 0,
							text: event.toLocaleString(undefined, { hourCycle: 'h12' }),
							translateY: -50,
							scale: 0.5,
							y: svgRefRect.y + svgRefRect.height,
						});
						setTimeout(() => {
							setLineTooltipData({
								x: e.clientX,
								opacity: 1,
								text: event.toLocaleString(undefined, { hourCycle: 'h12' }),
								translateY: 0,
								scale: 1,
								y: svgRefRect.y + svgRefRect.height,
							});
						});
					}}
					onMouseLeave={(e) => {
						setLineTooltipData({ ...lineTooltipData!, opacity: 0, translateY: -50, scale: 0.5 });
						setTimeout(() => {
							setLineTooltipData(null);
						}, 200);
					}}
				/>
			}) as unknown as SVGLineElement[]
		);
	}, [props.events, eventLinesColor, props.selectedEvent$, selectedEvent, props.pollerId, scale, lineTooltipData]);

	useEffect(() => {
		if (svgRef.current && wrapperRef.current) {
			const svg = svgRef.current;
			const wrapper = wrapperRef.current;
			const listener = (e: WheelEvent) => {
				e.preventDefault();
				e.stopPropagation();
				let newScale = scale;
				const scaleDelta = 0.2;
				const wrapperClientRect = wrapper.getBoundingClientRect();
				if (e.deltaY > 0) { 
					// decrease zoom
					newScale = Math.max(scale - scaleDelta, 1);
				} else {
					// increase zoom
					newScale = Math.min(scale + scaleDelta, 30);
				}
				const normalScalePointInVisibleArea = e.clientX - wrapperClientRect.x;
				const newTranslate = normalScalePointInVisibleArea / newScale - normalScalePointInVisibleArea / scale + translateX;
				const minTranslate = wrapperClientRect.width * (1 - newScale) / newScale;
				setTranslateX(Math.max(Math.min(newTranslate, 0), minTranslate));
				setScale(newScale);
			}
			svg.addEventListener('wheel', listener, { passive: false });
			return () => {
				svg.removeEventListener('wheel', listener);
			}
		}
	}, [svgRef, scale, translateX, wrapperRef]);
	useEffect(() => {
		setShowEvents(false);
		refreshPollerById(props.pollerId)?.then(() => {
			setShowEvents(true);
		});
	}, [props.pollerId, eventType, selectedFilter]);

	useEffect(() => {
		if (isDragging) {
			const mouseUpListener = (e: MouseEvent) => {
				setIsDragging(false);
				setTranslateX(
					Math.max(
						Math.min(
							translateX,
							0,
						),
						wrapperRef.current!.getBoundingClientRect().width * (1 - scale) / scale,
					)
				);
				svgRef.current!.style.transition = 'transform 0.2s ease-out';
				setTimeout(() => {
					svgRef.current!.style.transition = 'none';
				}, 200);
			}
			window.addEventListener('mouseup', mouseUpListener);
			return () => {
				window.removeEventListener('mouseup', mouseUpListener);
			}
		}
	}, [isDragging, translateX, scale]);

	// TODO: add another parameter if it's necessary to show the widget as disabled when installed is false
	if (props.installed === false) {
		return <></>;
	}
	return <><Card
		desktopCols={props.desktopCols ?? 12}
		tabletCols={props.tabletCols ?? 12}
		mobileCols={props.mobileCols ?? 12}
		hideTitleStraightBar={true}
		installed={props.installed}
		disconnected={props.disconnected}
		className="actuation-events-selector-wrapper"
	>
		<div className="d-flex flex-md-row flex-column">
			<div className="actuation-events-bar-wrapper">
				<div className="d-flex justify-content-between align-items-center">
					<div className="actuation-events-label">{t("Actuation events")}</div>
					<div className="d-flex align-items-center mb-1">
						{scale !== 1 && <ABB.Button className='mr-4' sizeClass='small' type='primary-blue' text={t("ResetZoom")} onClick={() => {
							setScale(1);
							setTranslateX(0);
						}} />}
						<div className="mr-2 font-weight-bold actuation-events-toggle-label">{t("Open")}</div>
						<ABB.ToggleSwitch
							className="actuation-events-toggle"
							showIcons={false}
							value={eventType === 'close'}
							onChange={(close) => {
								props.eventType$.next(close ? 'close' : 'open');
							}}
						/>
						<div className="font-weight-bold actuation-events-toggle-label">{t("Close")}</div>
					</div>
				</div>
				<div className='actuation-events-visible' style={{ overflowX: 'hidden' }} ref={wrapperRef}>
					<div
						style={{ transformOrigin: 'left center', transform: `scaleX(${scale})`}}>
						<svg
							onMouseDown={(e) => {
								e.preventDefault();
								setIsDragging(true);
								setPreviousDragX(e.clientX);
							}}
							onMouseMove={(e) => {
								if (isDragging) {
									setTranslateX((e.clientX - previousDragX) / scale + translateX);
									setPreviousDragX(e.clientX);
								}
							}}
							ref={svgRef} 
							style={{ 
								transformOrigin: 'left center', 
								transform: `translateX(${translateX}px)`,
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
							preserveAspectRatio="none" className="actuation-events-bar" viewBox={`0 0 ${svgWidth} 75`}>
							{showEvents && eventLines}
						</svg>
					</div>
				</div>
				{lineTooltipData && <span
					style={{ 
						position: 'fixed', 
						transform: `translateX(-50%) translateY(${lineTooltipData.translateY}%) scale(${lineTooltipData.scale})`, 
						left: `${lineTooltipData.x}px`, 
						top: `${lineTooltipData.y}px`, 
						pointerEvents: 'none',
						backgroundColor: 'white',
						borderColor: getColor('muted-color'),
						borderWidth: '1px',
						borderStyle: 'solid',
						zIndex: 2,
						opacity: lineTooltipData.opacity,
						transition: 'all .2s ease-out',
					}}
					className='mt-2 px-2 py-1'
				>{lineTooltipData.text}</span>}
			</div>
		</div>
	</Card>
	{props.charts.map((chart, i) => 
		<React.Fragment key={i}>
		<WaveformChart
			{...chart}
			title={chart.title + (selectedEvent ? ` - ${DateTimeFormatHelper.toDate(selectedEvent.timestamp)!.toLocaleString(undefined, { hourCycle: 'h12' })}` : '')}
			fetching={props.fetching !== false || loading}
		/>
		{props.separators && props.separators[i] && <SectionTitle desktopCols={12} title={props.separators[i]} />}
		</React.Fragment>
	)}
	</>
}
