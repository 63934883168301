import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from '../../components';
import { WidgetProps } from '../../models';
import { useTranslation } from 'react-i18next';
import { toCsv } from '../../utils/csv-converter';

export interface EventLogsProps extends WidgetProps {
	logs: {
		analysis: string,
		time: string,
		name: string,
		description: string,
		unit: string,
		exported: boolean,
	}[],
	units: string[],
}
export function EventLogs(props: EventLogsProps): ReactElement {
	const [selectedUnit, setSelectedUnit] = useState([0]);
	const { t } = useTranslation();
	const [sortOrder, setSortOrder] = useState<{ field: string, desc: boolean }|undefined>(undefined);
	const columns = useMemo<ABB.DatagridColumnDef[]>(() => {
		return [
			{
				fieldKey: "time",
				title: t("Time")
			},
			{
				fieldKey: "name",
				title: t("Name")
			},
			{
				fieldKey: "description",
				title: t("Description")
			},
			{
				fieldKey: "analysis",
				title: t("Analysis")
			}
		];
	}, [t]);
	const [rows, setRows] = useState<ABB.DatagridRow[]>([]);
	
	useEffect(() => {
		if (props.logs.length && props.units.length && selectedUnit.length) {
			setRows(props.logs
				.filter((log) => props.units[selectedUnit[0]] === log.unit && log.exported)
				.map((log) => {
					return {
						fields: {
							analysis: log.analysis,
							time: log.time,
							description: log.description,
							name: log.name,
						},
					}
				})
			);
		}
	}, [props.logs, selectedUnit, props.units]);

	const downloadLogs = useCallback(() => {
		const unit = props.units[selectedUnit[0]];
		toCsv(
			`${new Date().getTime()}_logs_unit_${unit}`,
			props.logs.filter((l) => l.unit === unit),
			['time', 'name', 'description', 'analysis'],
		);
	}, [props.logs, props.units, selectedUnit]);

	return <Card
		desktopCols={props.desktopCols ?? 8}
		mobileCols={props.mobileCols ?? 12}
		tabletCols={props.tabletCols ?? 8}
		title={props.title}
		contentClassName="mt-0"
		installed={props.installed}
		disconnected={props.disconnected}
		comment={t("Last24HoursEvents", { count: props.logs.length })}
	>
		<div className="d-flex justify-content-between">
			<ABB.ToggleButtonGroup sizeClass="small" selected={selectedUnit} onChange={(selected) => setSelectedUnit(selected)}>
				{props.units.map((u, i) => <ABB.ToggleButton key={i} text={t("Unit") + ' ' + u} onClick={() => setSelectedUnit([i])} />)}
			</ABB.ToggleButtonGroup>
			<ABB.Button
				text={t("Download log")}
				icon="abb/download"
				type="discreet-blue"
				sizeClass="small"
				onClick={() => downloadLogs()}
			/>
		</div>
		<ABB.Datagrid 
			columns={columns}
			data={rows}
			enableSorting={true}
			sortOrder={sortOrder}
			onSort={(newSort) => {
				setSortOrder(newSort)
			}}
			rowHeight="small"
			style={{ maxHeight: "300px", overflowY: "auto" }}
		/>
	</Card>
}
