import React, { ReactElement, useState } from 'react'
import { DateTimeFormatHelper } from '../helpers/DateTimeFormatHelper'

interface SimulatorSettings {
    active?: boolean;
    simulation?: number;
    closeOpen?: number;
    failure?: number;
    overtemperature?: number;
    gpio?: number;
}

export function Simulator(props: {}): ReactElement {

    const [currentConfig, setCurrentConfig] = useState<SimulatorSettings>({});

    const [url, setUrl] = useState("./api/v1");
    const [developmentMode, setDevelopmentMode] = useState(0);
    const [active, setActive] = useState(false);
    const [simulation, setSimulation] = useState(0);
    const [closeOpen, setCloseOpen] = useState(0);
    const [failure, setFailure] = useState(0);
    const [overtemperature, setOvertemperature] = useState(0);
    const [gpio, setGpio] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [developmentModeRequired, setDevelopmentModeRequired] = useState(false);
    const [time, setTime] = useState(0);

    function setDefault() {
        setActive(false);
        setDevelopmentMode(0);
        setSimulation(0);
        setCloseOpen(0);
        setFailure(0);
        setOvertemperature(0);
        setGpio(0);
        setDevelopmentModeRequired(false);
        setTime(0);
    }

    function loadConfig() {

        if (!url || url.trim().length === 0) {

            setDefault();
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        Promise.all(
            [
                fetch(url + "/simulator/state").then(p => p.status === 200 ? p.json() : { fail: true }),
                fetch(url + "/value/applications/cmu_simulator/").then(p => p.status === 200 ? p.json() : {}),
                fetch(url + "/value/developmentMode/").then(p => p.status === 200 ? p.json() : {}),
                fetch(url + "/board/date").then(p => p.status === 200 ? p.json() : {}),
            ]).then(data => {
                let state: any = data[0];
                let values: any = data[1];
                let developmentMode: any = data[2];
                let date: any = data[3];

                setDefault();

                if (!developmentMode || String(developmentMode.developmentMode) === "0") {
                    setDevelopmentModeRequired(true);
                    setIsLoading(false);
                    return;
                }

                if (date && date.dateUs) {
                    setTime(date.dateUs);
                }

                setDevelopmentMode(developmentMode.developmentMode);

                if (state && state.fail) {
                    throw new Error("unable to read simulator state");
                }

                let config: SimulatorSettings = {}

                if (values && values) {

                    config.simulation = values.simulation;
                    setSimulation(values.simulation);

                    config.closeOpen = values.closeOpen;
                    setCloseOpen(values.closeOpen);

                    config.failure = values.failure;
                    setFailure(values.failure);

                    config.overtemperature = values.overtemperature;
                    setOvertemperature(values.overtemperature);

                    config.gpio = values.gpio;
                    setGpio(values.gpio);
                }

                if (state) {
                    config.active = state.active;
                    setActive(state.active);
                }

                setDataLoaded(true);

                setCurrentConfig(config);

                setIsLoading(false);
            }).catch(e => {

                alert("Unable to read current configuration: " + e);
                setDefault();
                setIsLoading(false);
            })
    }

    function post(name: string, value: number) {
        return fetch(`${url}/value/applications/cmu_simulator/${name}?value=${value}`, { method: 'POST' });
    }

    function postActiveState() {
        return fetch(url + "/simulator/state", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ active })
        });
    }

    async function saveConfig() {

        setIsLoading(true);

        if (currentConfig.active !== active)
            await postActiveState()

        let promises: Promise<Response>[] = [];

        if (currentConfig.simulation !== simulation)
            promises.push(post("simulation", simulation));

        if (currentConfig.closeOpen !== closeOpen)
            promises.push(post("closeOpen", closeOpen));

        if (currentConfig.failure !== failure)
            promises.push(post("failure", failure));

        if (currentConfig.overtemperature !== overtemperature)
            promises.push(post("overtemperature", overtemperature));

        if (currentConfig.gpio !== gpio)
            promises.push(post("gpio", gpio));


        if (promises.length > 0) {

            Promise.all(promises)
                .then(() => loadConfig())
                .catch(e => {
                    alert("Unable to save configuration: " + e);
                    loadConfig();
                });
        }
        else if (currentConfig.active !== active) {
            loadConfig();
        }
        else {
            setIsLoading(false);
        }
    }


    function checkAndSetGpio(checked: boolean) {
        setGpio(checked ? 1 : 0);
        setActive(true);

        if (checked) {
            setSimulation(0);
            setCloseOpen(0);
            setFailure(0);
            setOvertemperature(0);
        }
    }

    function checkAndSetSimulation(checked: boolean) {
        setSimulation(checked ? 1 : 0);
        setActive(true);
        if (checked) {
            setGpio(0);
        } else {
            setCloseOpen(0);
            setFailure(0);
            setOvertemperature(0);
        }
    }

    function ensureSimulationFunction(action?: () => void) {
        setActive(true);
        setGpio(0);
        setSimulation(1);
        if (action)
            action();
    }

    function checkAndSetActive(checked: boolean) {
        setActive(checked);
        if (!checked) {
            setGpio(0);
            setSimulation(0);
            setCloseOpen(0);
            setFailure(0);
            setOvertemperature(0);
        }
    }

    return (

        <div style={{ display: "flex", flexDirection: "column", margin: "60px", maxWidth: "800px" }}>

            {isLoading ?
                <span>loading...</span>
                :
                <>
                    <label htmlFor="url" ><b>api base url</b></label>
                    <input id="url" value={url} type="url" onChange={(e) => setUrl(e.target.value)} onKeyPress={e => e.key === "Enter" && loadConfig()} />
                    <br />
                    <button onClick={() => loadConfig()}>Load</button>

                    <br />

                    {dataLoaded &&
                        <>

                            <label><b>board date</b> {time > 0 ? DateTimeFormatHelper.toDate(time)!.toLocaleString() : '-'}</label>
                            <br />
                            <label><input id="developmentMode" checked={developmentMode > 0} disabled={true} type="checkbox" /> <b>developmentMode</b></label>

                            <br />
                            <label><input id="active" checked={active} type="checkbox" onChange={(e) => checkAndSetActive(e.target.checked)} /> <b>active</b></label>
                            <br />

                            <label><input id="gpio" checked={gpio > 0} type="checkbox" onChange={(e) => checkAndSetGpio(e.target.checked)} /> <b>gpio</b>. If checked the breaker is close, otherwise the breaker is open</label>

                            <label><input id="simulation" checked={simulation > 0} type="checkbox" onChange={(e) => checkAndSetSimulation(e.target.checked)} /> <b>simulation</b>. If checked simulation is enabled</label>

                            <label><input id="closeOpen" checked={closeOpen > 0} type="checkbox" onChange={(e) => ensureSimulationFunction(() => setCloseOpen(e.target.checked ? 1 : 0))} /> <b>closeOpen</b>. If checked the breaker is close, otherwise the breaker is open</label>

                            <label><input id="failure" checked={failure > 0} type="checkbox" onChange={(e) => ensureSimulationFunction(() => setFailure(e.target.checked ? 1 : 0))} /> <b>failure</b>. If checked a mechanical failure of the breaker is simulated with alarm on mechanical</label>

                            <label><input id="overtemperature" checked={overtemperature > 0} type="checkbox" onChange={(e) => ensureSimulationFunction(() => setOvertemperature(e.target.checked ? 1 : 0))} /> <b>overtemperature</b>. If checked an over temperature behaviour is simulated with alarm on temperature</label>

                            <br />
                            <button onClick={() => saveConfig()}>Save</button>
                        </>
                    }

                    {developmentModeRequired &&
                        <>
                            DevelopmentMode is turned off
                            <br />
                            SSH command:
                            <code>
                                cfg set developmentMode 1
                            </code>
                        </>
                    }
                </>
            }
        </div>
    )
}
