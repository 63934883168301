import React, { useEffect, useMemo, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { Card } from '../../components';
import { useTranslation } from 'react-i18next';
import { ReferenceMetaDataInfo } from '..';
import { sampleLastUpdates$ } from '../../services/polling';
import { useReadable } from '../../observables/hooks';
import { DateTimeFormatHelper } from '../../helpers';

export interface TableSelectorProps {
	colors: string[],
	onSelectionChange: (newSelection: { label: string, value: number }[]) => void,
	selectedItems: { label: string, value: number }[],
	referenceMetadata: ReferenceMetaDataInfo[],
	maxElementsReached: boolean,
}
export const TableSelector = ({ colors, onSelectionChange, selectedItems, referenceMetadata, maxElementsReached }: TableSelectorProps) => {
	const { t } = useTranslation();
	const columns = useMemo<ABB.DatagridColumnDef[]>(() => {
		return [
			{
				fieldKey: "name",
				title: t("Name")
			},
			{
				fieldKey: "sensor",
				title: t("Sensor"),
			},
			{
				fieldKey: "unit",
				title: t("Unit")
			},
			{
				fieldKey: "lastUpdate",
				title: t("LastUpdate")
			}
		];
	}, [t]);
	const selectionColumns = useMemo<ABB.DatagridColumnDef[]>(() => {
		return [
			{
				fieldKey: "name",
			},
			{
				fieldKey: "delete",
				width: 50,
			}
		];
	}, []);
	const [sortOrder, setSortOrder] = useState<{ field: string, desc: boolean } | undefined>(undefined);
	const [rowSelection, setRowSelection] = useState<{ [rowId: string]: boolean }>(selectedItems.reduce((selected, item) => {
		selected[item.label] = true;
		return selected;
	}, {} as {[rowId: string]: boolean}));
	const [rows, setRows] = useState<ABB.DatagridRow[]>([]);
	const [visibleRows, setVisibleRows] = useState<ABB.DatagridRow[]>([]);
	const [selectedRows, setSelectedRows] = useState<ABB.DatagridRow[]>([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [columnFilters, setColumnFilters] = useState({
		name: {
			value: "",
			placeholder: t("SampleName")
		},
		sensor: {
			value: "",
			placeholder: t("SensorName"),
		},
	});
	const sampleLastUpdates = useReadable(sampleLastUpdates$);

	useEffect(() => {
		sampleLastUpdates$.startPolling();
		return () => {
			sampleLastUpdates$.stopPolling();
		}
	}, []);

	useEffect(() => {
		setRows(referenceMetadata
			.map((el) => ({
				fields: {
					name: el.sample,
					value: el.index.toString(),
					unit: el.unit ?? '-',
					lastUpdate: sampleLastUpdates.devices?.[el.model]?.[el.id]?.samples?.[el.sample]?.[0] ? DateTimeFormatHelper.toDate(sampleLastUpdates.devices[el.model][el.id].samples[el.sample][0].timestamp_us)?.toLocaleString(undefined, { hourCycle: 'h12' }) : t("N/A"),
					id: el.sample + " [" + el.model + "-" + el.id + "]",
					sensor: `${el.model}-${el.id}`,
				},
				isSelected: rowSelection[el.sample + " [" + el.model + "-" + el.id + "]"] ?? false,
				disableSelect: maxElementsReached && !rowSelection[el.sample + " [" + el.model + "-" + el.id + "]"]
			}))
		);
	}, [referenceMetadata, rowSelection, maxElementsReached, sampleLastUpdates, t]);

	useEffect(() => {
		setVisibleRows(
			rows.filter((el) => {
				let include = true;
				if (columnFilters.name.value) {
					include = include && (el.fields.name! as string).toLowerCase().includes(columnFilters.name.value.toLowerCase());
				}
				if (columnFilters.sensor.value && include) {
					include = include && (el.fields.sensor as string).toLowerCase().includes(columnFilters.sensor.value.toLowerCase());
				}
				return include;
			})
		);
	}, [rows, columnFilters]);

	useEffect(() => {
		if (currentPage > Math.floor(visibleRows.length / pageSize)) {
			setCurrentPage(Math.floor(visibleRows.length / pageSize));
		}
	}, [visibleRows, currentPage, pageSize]);

	useEffect(() => {
		const selectedItems = rows
			.filter((r) => rowSelection[r.fields.id as string])
			.map((r) => r.fields);
		onSelectionChange(selectedItems.map((i) => ({
			label: i.id as string,
			value: Number(i.value),
		})));
	}, [rowSelection, rows, onSelectionChange]);

	useEffect(() => {
		setSelectedRows([...selectedItems.map((item, index) => ({
				fields: {
					name: item.label,
					delete: <ABB.CloseIcon sizeClass="medium" onClick={() => {
						setRowSelection({ ...rowSelection, [item.label]: false })
					}} />
				},
				rowStatus: index as keyof ABB.StatusColorMappings
			})
		), {
			fields: {
				name: t("Add from list"),
				delete: ""
			},
			disableSelect: true,
			rowStatus: colors.length as keyof ABB.StatusColorMappings
		}]);
	}, [selectedItems, colors, rowSelection, t]);
	return <div className="row">
		<Card title={t("Available parameters")} mobileCols={12} tabletCols={8} desktopCols={8}>
			<ABB.Datagrid
				columns={columns}
				data={visibleRows}
				style={{ maxHeight: "300px", overflowY: "scroll" }}
				enableRowSelection={true}
				onToggleRowSelect={(index, oldValue) => {
					const numericIndex = Number(index);
					const nthRow = visibleRows[numericIndex];
					setRowSelection({ ...rowSelection, [nthRow.fields.id as string]: !oldValue });
				}}
				enableSorting={true}
				sortOrder={sortOrder}
				onSort={(newSort) => {
					setSortOrder(newSort as { field: string, desc: boolean } | undefined)
				}}
				page={currentPage}
				pageSize={pageSize}
				columnFilters={columnFilters}
				onColumnFiltersChange={(updatedFilters) => {
					setColumnFilters({
						...columnFilters,
						...updatedFilters,
					});
				}}
			/>
			<ABB.Pagination
				currentPageSize={pageSize}
				currentPage={currentPage}
				showPrevNext={true}
				pageSizeOptions={[10, 20, 50]}
				onGotoPage={(newPage) => setCurrentPage(newPage)}
				onSetPageSize={(newPageSize) => setPageSize(newPageSize)}
				rowCount={visibleRows.length}
			/>
		</Card>
		<Card title={t("Selected rows")} mobileCols={12} tabletCols={4} desktopCols={4} contentClassName="mt-0">
			<ABB.Datagrid
				className="hidden-header"
				statusColors={colors.reduce((mapping, color, index) => ({
					...mapping,
					[index]: color
				}), { [colors.length]: '#dadada' })}
				enableRowStatusIndicators={true}
				columns={selectionColumns}
				sidePaddings={false}
				data={selectedRows}
			/>
		</Card>
	</div>
}
