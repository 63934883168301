import { BehaviorSubject } from 'rxjs';
import { checkEvent, checkSample, getDataAggregation, getMappedEventState, getValue } from '..';
import { ChartLastOperation, ChartTimeRange } from '../../../enums';
import { DateTimeFormatHelper } from '../../../helpers';
import { AggregationDevice, ApiIdentityItem, ApiIdentityItemType, ChartDataItem, ChartDataList, ChartDateRange, ChartScaleType } from '../../../models';
import { PathSource } from '../../../pages/DynamicPage';
import { roundNumber } from '../../../utils/number';
import { apiService } from '../../api';

export const chartFilters$ = new BehaviorSubject<{[filterRef: string]: ChartScaleType}>({});

export async function getChartDataList(scaleType: ChartScaleType, boundaries: { min?: number, max?: number}|undefined, ...api: ApiIdentityItem[]): Promise<ChartDataList> {

    let dateTo: Date = new Date();
    let dateFrom: Date = new Date();
    let numberOfSamples: number = 0;

    if ((scaleType as ChartDateRange).startDate) {
        const dateRange = scaleType as ChartDateRange;
        dateFrom = dateRange.startDate;
        dateTo = dateRange.endDate;
        if (dateRange.noSubsampling) {
            numberOfSamples = 100; // in realtà non dovrei passare il numero di punti in questo caso
            let data = await getChartDataListByDataAggregation(numberOfSamples, api, dateFrom, dateTo, boundaries);
            return data;
        } else {
            numberOfSamples = 50;
            let data = await getDataAggregationIntervals(dateFrom, dateTo, numberOfSamples, api, boundaries);
            return data;
        }

    } else {

        switch (scaleType) {
            case ChartTimeRange.Hour:
            case ChartTimeRange.Day:
            case ChartTimeRange.Month:
            case ChartTimeRange.Week:
                {
                    dateFrom = DateTimeFormatHelper.getTimeIntervalBeginningFromNow(scaleType as ChartTimeRange);
                    numberOfSamples = getNumberOfSamples(scaleType as ChartTimeRange);
                    return getDataAggregationIntervals(dateFrom, dateTo, numberOfSamples, api, boundaries);
                }
            case ChartLastOperation.ALL:
            case ChartLastOperation.MIN:
            case ChartLastOperation.MID:
                {
                    return getChartDataListByDataAggregation(scaleType, api, undefined, undefined, boundaries);
                }
            default:
                throw new Error(`unhandled ChartScaleType: ${scaleType}`);
        }

    }
}

async function getChartDataListByDataAggregation(size: number, apis: ApiIdentityItem[], dateFrom?: Date, dateTo?: Date, boundaries?: { min?: number, max?: number }): Promise<ChartDataList> {

    const res: Map<number, ChartDataItem> = new Map<number, ChartDataItem>();

    function getMapItem(timestamp_us: number) {
        let item = res.get(timestamp_us);
        if (!item) {
            item = { date: timestamp_us / 1000, date_us: timestamp_us };
            res.set(timestamp_us, item);
        }
        return item;
    }

    const data = await getDataAggregation(apis, size, dateFrom, dateTo, boundaries);


    let lastValue: ChartDataItem = { date: 0, date_us: 0 };

		function updateLastValue(i: ChartDataItem, v: number, key: 'value'|'value2'|'value3'|'value4'|'value5'|'value6'|'value7') {
			if (i.date_us >= lastValue.date_us) {
				lastValue[key] = v;
				lastValue.date_us = i.date_us;
				lastValue.date = i.date;
			} else if (lastValue[key] === undefined) {
				lastValue[key] = v;
			}
		}
    const funcs = [
        (i: ChartDataItem, v: number) => { i.value = v; updateLastValue(i, v, 'value'); },
        (i: ChartDataItem, v: number) => { i.value2 = v; updateLastValue(i, v, 'value2'); },
        (i: ChartDataItem, v: number) => { i.value3 = v; updateLastValue(i, v, 'value3'); },
        (i: ChartDataItem, v: number) => { i.value4 = v; updateLastValue(i, v, 'value4'); },
        (i: ChartDataItem, v: number) => { i.value5 = v; updateLastValue(i, v, 'value5'); },
				(i: ChartDataItem, v: number) => { i.value6 = v; updateLastValue(i, v, 'value6'); },
				(i: ChartDataItem, v: number) => { i.value7 = v; updateLastValue(i, v, 'value7'); },
        (i: ChartDataItem, v: number) => console.error("getChartDataListByDataAggregation", "invalid apis length")
    ];

    for (let index = 0; index < apis.length; index++) {
        const api = apis[index];
        const funcSetValue = funcs[index < funcs.length ? index : funcs.length - 1];

        const item = data.devices[api.device!.model][api.device!.id];

        if (api.type === ApiIdentityItemType.event) {
            const event = item.events[api.name!];
            for (const eventValue of event) {
                const mapItem = getMapItem(eventValue.timestamp_us);
                const mapped = getMappedEventState(api.name!, eventValue.state, api.device!.model, api.states);
                if (mapped.chartValue !== undefined) {
                    funcSetValue(mapItem, mapped.chartValue);
                }
                mapItem.color = mapped.chartColor;
            }
        } else if (api.type === ApiIdentityItemType.sample) {
            const sample = item.samples[api.name!];

            for (const sampleValue of sample) {
							if (sampleValue !== undefined) {
                	const mapItem = getMapItem(sampleValue.timestamp_us);
									funcSetValue(mapItem, roundNumber(sampleValue.value));
							}
            }

        } else {
            throw new Error(`getChartDataListByDataAggregation | invalid api type | ${api.type}`);
        }
    }

    const sortedData = [...res.values()].sort((a, b) => a.date - b.date);

    return {
        data: sortedData,
        lastValue: lastValue
    };
}

async function getDataAggregationIntervals(dateFrom: Date, dateTo: Date, numberOfSamples: number, apis: ApiIdentityItem[], boundaries?: { max?: number, min?: number }): Promise<ChartDataList> {
    const devices = apiItemsToAggregationRequestDevices(apis);
    const data = await apiService.getDataAggregationIntervals(devices, dateFrom, dateTo, numberOfSamples, boundaries);
    let res: ChartDataItem[] = []
    let lastValue: ChartDataItem = { date: 0, date_us: 0 };
		function updateLastValue(i: ChartDataItem, v: number, key: 'value'|'value2'|'value3'|'value4'|'value5'|'value6'|'value7') {
			if (i.date_us >= lastValue.date_us) {
				lastValue[key] = v;
				lastValue.date_us = i.date_us;
				lastValue.date = i.date;
			} else if (lastValue[key] === undefined) {
				lastValue[key] = v;
			}
		}
    const funcs = [
			(i: ChartDataItem, v: number) => { i.value = v; updateLastValue(i, v, 'value'); },
			(i: ChartDataItem, v: number) => { i.value2 = v; updateLastValue(i, v, 'value2'); },
			(i: ChartDataItem, v: number) => { i.value3 = v; updateLastValue(i, v, 'value3'); },
			(i: ChartDataItem, v: number) => { i.value4 = v; updateLastValue(i, v, 'value4'); },
			(i: ChartDataItem, v: number) => { i.value5 = v; updateLastValue(i, v, 'value5'); },
			(i: ChartDataItem, v: number) => { i.value6 = v; updateLastValue(i, v, 'value6'); },
			(i: ChartDataItem, v: number) => { i.value7 = v; updateLastValue(i, v, 'value7'); },
			(i: ChartDataItem, v: number) => console.error("getDataAggregationIntervals", "invalid apis length")
    ];

    for (let index = 0; index < apis.length; index++) {
        const api = apis[index];
        const funcSetValue = funcs[index < funcs.length ? index : funcs.length - 1];
        const item = data.devices[api.device!.model][api.device!.id];

        if (api.type === ApiIdentityItemType.event) {
            const event = item.events[api.name!];
            for (let intervalIndex = 0; intervalIndex < data.intervals.length; intervalIndex++) {
                const interval = data.intervals[intervalIndex];
                const eventState = event[intervalIndex];
                const intervalItem = res[intervalIndex] = (res[intervalIndex] || { date: Math.round(interval / 1000), date_us: interval });
                if (eventState !== null && eventState !== undefined) {
                    const mapped = getMappedEventState(api.name!, eventState, api.device!.model, api.states);
                    if (mapped.chartValue !== undefined) {
                        funcSetValue(intervalItem, mapped.chartValue);
                    }
                    intervalItem.color = mapped.chartColor;
                } else {
									// undefined values get ignored by amcharts, so when displaying a chart with
									// connect = true no gap was being shown. This is no longer the desired behaviour in
									// phase charts, so the value is being set to 0. If a different behaviour is required
									// for other charts, make this line conditional. 
									funcSetValue(intervalItem, 0);
								}
            }

        } else if (api.type === ApiIdentityItemType.sample) {
            const sample = item.samples[api.name!];

            for (let intervalIndex = 0; intervalIndex < data.intervals.length; intervalIndex++) {
                const interval = data.intervals[intervalIndex];
                const sampleValue = sample[intervalIndex];

                const intervalItem = res[intervalIndex] = (res[intervalIndex] || { date: Math.round(interval / 1000), date_us: interval });

                if (sampleValue !== undefined && sampleValue !== null) {
                    funcSetValue(intervalItem, roundNumber(sampleValue));
                }
            }

        } else {
            throw new Error(`getDataAggregationIntervals | invalid api type | ${api.type}`);
        }
    }

    return {
        data: res,
        lastValue: lastValue
    };
}

function apiItemsToAggregationRequestDevices(apis: ApiIdentityItem[]): Record<string, Record<string, AggregationDevice>> {

    return apis.filter((api) => api).reduce((devices, api) => {
        function setId() {
            const model = devices[api.device!.model] = (devices[api.device!.model] || {});
            const id = model[api.device!.id] = (model[api.device!.id] || {
                events: [],
                samples: []
            });
            return id;
        }
        if (api.type === ApiIdentityItemType.event) {
            checkEvent("apiItemsToAggregationRequestDevices", api);
            setId().events.push(api.name!);
        } else if (api.type === ApiIdentityItemType.sample) {
            checkSample("apiItemsToAggregationRequestDevices", api);
            setId().samples.push(api.name!);
        } else {
            throw new Error(`apiItemsToAggregationRequestDevices | invalid api type | ${api.type}`);
        }
        return devices;
    }, {} as Record<string, Record<string, AggregationDevice>>);
}

function getNumberOfSamples(timeInterval: ChartTimeRange): number {

    switch (timeInterval) {
        case ChartTimeRange.Hour:
            return 60 / 3;  // 20 - every 3 minutes

        case ChartTimeRange.Day:
            return 24; // 24 - every hour

        case ChartTimeRange.Week:
            return 7 * 24 / 8; // 21 - every 8 hour

        case ChartTimeRange.Month:
            return 31; // 31 - every day

        default:
            throw new Error("getNumberOfSamples - unhandled ChartTimeInterval: " + timeInterval);
    }
}

export function readThresholds<T extends Partial<ChartThresholds<number>>, U extends Partial<ChartThresholds<{ source: PathSource }>>>(
	props: T, 
	propsConfig: U,
	dataConversion = 1
) {
	if (propsConfig.alarmHighTh) {
		props.alarmHighTh = props.alarmHighTh ?? (Number(getValue<number>(propsConfig.alarmHighTh.source)) / dataConversion);
	}
	if (propsConfig.alarmHigh) {
		props.alarmHigh = props.alarmHigh ?? (Number(getValue<number>(propsConfig.alarmHigh.source)) / dataConversion);
	}
	if (propsConfig.alarmLowTh) {
		props.alarmLowTh = props.alarmLowTh ?? (Number(getValue<number>(propsConfig.alarmLowTh.source)! * -1) / dataConversion);
	}
	if (propsConfig.alarmLow) {
		props.alarmLow = props.alarmLow ?? (Number(getValue<number>(propsConfig.alarmLow.source)) / dataConversion);
	}

	if (propsConfig.warningHighTh) {
		props.warningHighTh = props.warningHighTh ?? (Number(getValue<number>(propsConfig.warningHighTh.source)) / dataConversion);
	}
	if (propsConfig.warningHigh) {
		props.warningHigh = props.warningHigh ?? (Number(getValue<number>(propsConfig.warningHigh.source)) / dataConversion);
	}
	if (propsConfig.warningLowTh) {
		props.warningLowTh = props.warningLowTh ?? (Number(getValue<number>(propsConfig.warningLowTh.source)! * -1) / dataConversion);
	}
	if (propsConfig.warningLow) {
		props.warningLow = props.warningLow ?? (Number(getValue<number>(propsConfig.warningLow.source)) / dataConversion);
	}
	// The general rule is that the low thresholds are negative, but in some cases they are positive
	// Since the value we receive is always positive, we can tell them apart by determining which
	// of the two is greater: if the low alarm is greater than the low warning, we need to change back
	// the signs and make them positive.
	if (props.alarmLowTh !== undefined && props.warningLowTh !== undefined && props.alarmLowTh > props.warningLowTh) {
		props.warningLowTh = props.warningLowTh * -1;
		props.alarmLowTh = props.alarmLowTh * -1;
	}
}

export interface ChartThresholds<T> {
	alarmHighTh: T, 
	alarmHigh: T, 
	alarmLowTh: T, 
	alarmLow: T,
	warningHighTh: T,
	warningHigh: T,
	warningLowTh: T,
	warningLow: T,
	dataConversion: { value: number }|undefined
}
export interface ChartFilterPropsConfig {
	filterRef: {
		value: string;
	},
	default: {
		value: ChartScaleType;
	},
	enableLastOperations?: {
		value: boolean
	},
	defaultTimeRange?: {
		value: ChartTimeRange
	},
	enableDate?: {
		value: boolean,
	},
	enableDateRange?: {
		value: boolean,
	},
	enableTimeRange?: {
		value: boolean,
	},
	installedDevices?: { role: string, position: string }[],
}

export interface ChartPropsConfig {
	filterRef?: {
		value: string;
	},
	xAxisLabelAlignment?: {
		value: 'center'|'left'|'right'|'none',
	},
	yAxisLabelAlignment?: {
		value: 'top'|'middle'|'bottom'|'none',
	},
	showUnitInYLabel?: {
		value: boolean,
	},
}
