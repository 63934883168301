import React, { useCallback, useEffect, useState } from 'react';
import { ReferenceMetaDataInfo } from '..';
import { dropdownElement, DropDownMultiselect } from '../../components';
import { ChartScaleType } from '../../models';
import { ExternalChartScaleSelector } from '../../widgets';

function getValueIndex(dev: ReferenceMetaDataInfo) {
	return dev.index;
	//return parseInt(''+dev.id+dev.index, 10)//v1
}

export interface DropodownSelectorProps {
	onSelectionChange: (newSelection: { label: string, value: number }[]) => void,
	selectedItems: { label: string, value: number }[],
	referenceMetadata: ReferenceMetaDataInfo[],
	onScaleTypeChange: (scaleType: ChartScaleType) => void,
	maxAllowedSelectedItems: number,
	maxElementsReached: boolean,
	fetchingDates: boolean,
	fetchingSelections: boolean,
	defaultScaleType?: ChartScaleType,
}
export const DropdownSelector = ({ 
	onSelectionChange, 
	selectedItems,
	referenceMetadata,
	maxElementsReached,
	maxAllowedSelectedItems,
	onScaleTypeChange,
	fetchingDates,
	fetchingSelections,
	defaultScaleType,
}: DropodownSelectorProps) => {
	//Elementi selezionabili dal dropdown
	const [dropdownEl, setDropdownEl] = useState<dropdownElement[]>([]);

	useEffect(() => {
		const dropdown: dropdownElement[] = referenceMetadata.map((res) => ({
			label: res.description + " [" + res.model + "-" + res.id + "]",
			//inserire id | index
			value: getValueIndex(res),
			disabled: false,
			isNew: false
		}));
		setDropdownEl(dropdown);
	}, [referenceMetadata]);

	const disableDropdownElements = useCallback(() => {
		if (dropdownEl.length > 0 && selectedItems.length > 0) {
			let allElements = dropdownEl;
			allElements.forEach((el) => {
				el.disabled = true;
			});
			setDropdownEl(allElements);
		}
	}, [dropdownEl, selectedItems]);

	const enableDropdownElements = useCallback(() => {
		if (dropdownEl.length > 0) {
			let allElements = dropdownEl;
			allElements.forEach((el) => {
				el.disabled = false;
			});
			setDropdownEl(allElements);
		}
	}, [dropdownEl]);
	//disable selection when max limit is reached
	useEffect(() => {
		if (maxElementsReached && selectedItems.length > 0) {
			disableDropdownElements();
		}
		else {
			enableDropdownElements();
		}
	}, [maxElementsReached, disableDropdownElements, enableDropdownElements, selectedItems]);

	return <div className="row">
		<ExternalChartScaleSelector
			key={"ExternalChartScaleSelector"}
			onScaleTypeChange={(scaleType) => onScaleTypeChange(scaleType)}
			enableLastOperations={false}
			fetching={fetchingDates}
			enableDateRange={true}
			enableTimeRange={true}
			enableDate={false}
			defaultValue={defaultScaleType}
			layout={{
				desktop: 6,
				tablet: 6,
				mobile: 12
			}} />
		<DropDownMultiselect
			fetching={fetchingSelections}
			size={'small'}
			labelText={'MultiSelection dropdown, select up to ' + maxAllowedSelectedItems + ' data input for the chart below.'}
			description={'Remaining selectable items: ' + (maxAllowedSelectedItems - selectedItems.length)}
			dataSeries={dropdownEl}
			maxSelectedElements={maxAllowedSelectedItems}
			onSelectedItemsChange={onSelectionChange}
			selectedMulti={selectedItems}
		//disabled={maxElementsReached}
		/>

	</div>
}
