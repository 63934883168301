
import React, { ReactElement } from "react";
import { LoadHelper, ConnectionHelper } from "../../helpers";
import * as Enums from "../../enums";
import * as ABB from "@abb/abb-common-ux-react";
import './style.scss'
import { useTranslation } from "react-i18next";
import { LastUpdate } from '../../components';

export interface SensorsOverviewItemProps {
	group?: string,
  connected: boolean;
  lastConnectionDate?: Date;
  loadStatus: Enums.Load|null;
  value: number|null;
  unit: string;

	title: string;
  label: string;
}


//container + connection icon and titled
export function SensorsOverviewItem(props: SensorsOverviewItemProps): ReactElement {
  let connectionStatus = props.connected ? Enums.ConnectionStatus.Connected : Enums.ConnectionStatus.Disconnected;

  return (
    <div className="SensorOverview">
      <div className={"SensorOverviewSurrounded"}>
         <h6 className={"font-weight-bolder "} style={{ textAlign: "left" }}>
            <ABB.Icon className="align-middle" sizeClass="medium" name={ConnectionHelper.getIcon(connectionStatus)} color={ConnectionHelper.getColor(connectionStatus)} />
            &ensp;<span className="align-middle">{props.title}</span></h6>

          <SensorLowerPart {...props}/>
      </div>
    </div>);
}


//temperature and load
function SensorLowerPart(props: SensorsOverviewItemProps): ReactElement {

  const { t } = useTranslation();

  let advice: string;
  let temperature: string;
  let disconnectedMessage: string;

  if (props.connected) {
    advice = "";
    temperature = props.label;
    disconnectedMessage = "advice-normal";
  }
  else {
    advice = " SensorOverviewAdvice";
    if (props.lastConnectionDate)
      temperature = t('LastUpdate') + " ";
    else
      temperature = t('NoDataAvailable');

    disconnectedMessage = "advice-normal";
  }


  return (
    <div className={"d-flex flex-row flex-wrap justify-content-between SensorOverviewContentCentered " + advice}>
      <div className={"text-left " + disconnectedMessage}>
        {temperature}{!props.connected && props.lastConnectionDate && <LastUpdate lastUpdate={props.lastConnectionDate} />}
      </div>
      <div className={'font-weight-bolder text-center'}>
        {props.value ?? t("N/a")}{props.unit}
      </div>
      <div className={'text-right d-flex flex-row justify-content-around'} style={{fontSize: "12px"}}>
          <span>{t('Load')}</span>
					{props.loadStatus !== null ?
						<><div className={"my-auto mx-1 SensorOverviewSquareState SensorOverviewBackground" + LoadHelper.getNameKey(props.loadStatus)} />
						<span>{LoadHelper.getNameKey(props.loadStatus)}</span></>
					: <div className='mx-1'>{t('N/A')}</div>
					}
      </div>
    </div >
  );
}
