import i18n, { TFunction } from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { EnvHelper } from "./EnvHelper";

export class LocalizationHelper {
	private static initPromise: Promise<TFunction> | undefined = undefined;

	public static initAsync(loadPath: string) {
		if (!LocalizationHelper.initPromise) {
			LocalizationHelper.initPromise = i18n
				.use(detector)
				.use(backend)
				.use(initReactI18next) // passes i18n down to react-i18next
				.init(
					{
						lng: "en",
						fallbackLng: "en",
						debug: EnvHelper.isDev,
						ns: [
							"translations",
							"notificationTitles",
							"notificationDescriptions",
							"notificationPopup",
							"deviceNames",
							"deviceDescriptions",
							"thermalConfiguration"
						],
						defaultNS: "translations",
						keySeparator: false, // we use content as keys
						interpolation: {
							escapeValue: false, // not needed for react!!
							formatSeparator: ",",
						},
						react: {
							useSuspense: true,
						},
						backend: {
							loadPath: loadPath,
						},
					},
					() => {}
				);
		}

		return LocalizationHelper.initPromise;
	}

	public static getTranslation(key: string, returnObjects: true): string | object | Array<string | object>;
	public static getTranslation(key?: string, returnObjects?: false|undefined): string;
	public static getTranslation(key?: string, returnObjects?: boolean) {
		return key ? i18n.t(key, { returnObjects }) : "";
	}

	public static changeLanguage(newLang: string) {
		i18n.changeLanguage(newLang);
	}

	public static getLanguage() {
		return i18n.language;
	}

	public static keyExists(key: string) {
		return i18n.exists(key);
	}
}
