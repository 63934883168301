import * as ABB from "@abb/abb-common-ux-react";
// tslint:disable:no-submodule-imports
import "@abb/abb-common-ux-react/styles.css";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// tslint:enable:no-submodule-imports
// NOTE: keep this below the component library style bundle import, so that
// overriding styles in application level works as expected.
import "./App.scss";
//* Custom components */
import * as WHMI from "../components";
import * as Enums from "../enums";
import * as Helpers from "../helpers";
/* Sections */
import * as Components from "../components";
import { user$ } from "../services/user";
import { useReadable } from "../observables/hooks";
import { useEffect } from "react";
import {
	appConfig$,
	isPublicConfigAvailable$,
	loadPublicConfig,
	productName,
} from "../services/config";
import { PageComponentSelector } from "../pages/PageComponentSelector";
import { fireNotification } from "../toasts";
import { AppDialogs } from "./AppDialogs";
import { handleLogin } from "./login";
import { AppHead } from "./AppHead";
import { AppToasts } from "./AppToasts";
import { AppNotificationsTabs } from "./AppNotificationsTab";
import { init as initUserModule } from "../services/access";
import { init as initPollerModule } from "../services/polling/startup";
import { isCommissioningPhase$, isStartupPhase$ } from "./layout-state";
import { StartupAndCommissioningPage } from "../pages/StartupAndCommissioningPage/StartupAndCommissioningPage";
import { initDemoModule, isDemo, selectedScenario$ } from '../services/demo';
import { ScenarioSelection } from '../pages/Demo/ScenarioSelection';

/* ------------------------------- */

const App: React.FC = () => {
	const isPublicConfigAvailable = useReadable(isPublicConfigAvailable$);
	const [theme] = useState("light");
	const [initializationFailure, setInitializationFailure] = useState(false);
	const user = useReadable(user$);
	const selectedScenario = useReadable(selectedScenario$);
	const authenticated = Boolean(user);
	const userRole = user?.role ?? Enums.Role.None;
	const appJson = useReadable(appConfig$);
	const isStartupPhase = useReadable(isStartupPhase$);
	const isCommissioningPhase = useReadable(isCommissioningPhase$);

	useEffect(() => {
		let elements = document.getElementsByClassName("nav-item");

		for (let i = 0; i < elements.length; i++) {
			elements[i].setAttribute("data-toggle", "collapse");
			elements[i].setAttribute("data-target", "#navbarNav");
		}
		loadPublicConfig()
			.then(() => {
				if (isDemo) {
					initDemoModule();
				}
				initUserModule();
				initPollerModule();
			})
			.catch((err) => {
				console.error(err);
				setInitializationFailure(true);
			});
	}, []);

	if (!isPublicConfigAvailable || !appJson) {
		const splitProductName = productName.split('-');
		const formattedProductName = `${splitProductName[0]?.toUpperCase()} ${splitProductName[1] ?? ''}`
		return (
			// NON UTILIZZARE LA LOCALIZZAZIONE in questo blocco
			<ABB.AppContainer>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
					}}
				>
					{initializationFailure ? (
						<p>Initialization error, please reload</p>
					) : (
						<ABB.SplashScreen productName={formattedProductName} />
					)}
				</div>
			</ABB.AppContainer>
		);
	}

	return (
		<ABB.AppContainer theme={theme}>
			{!(isDemo && selectedScenario === null) && <div
				className={
					isStartupPhase || isCommissioningPhase ? "disabled-header" : ""
				}
			>
				<AppHead />
			</div>}

			{!authenticated ? (
				(isDemo && selectedScenario === null) ? <ScenarioSelection /> : <Components.Login onLogin={handleLogin} />
			) : (
				<ABB.AppContent>
					<ABB.AppMainContent id="appcontent" style={{ overflow: "auto" }}>
						<div className="fullHeight">
							{!isStartupPhase && !isCommissioningPhase && (
								<WHMI.Breadcrumbs mobile={false} />
							)}

							<div id="section-container" className="container-fluid">
								{isStartupPhase || isCommissioningPhase ? (
									<StartupAndCommissioningPage />
								) : (
									<Switch>
										{Object.keys(appJson.pages).map((route) => (
											<Route exact path={route} key={route}>
												<PageComponentSelector
													fireNotification={fireNotification}
													component={appJson.pages[route]!.component}
													widgetConfigs={appJson.pages[route]!.widgets || []}
													propsConfig={appJson.pages[route]!.propsConfig}
												/>
											</Route>
										))}

										<Route>
											{userRole === Enums.Role.None ? (
												<Redirect
													to={Helpers.SectionsHelper.getPath(
														Enums.AppSections.AssetInformation
													)}
												/>
											) : (
												<Redirect
													to={Helpers.SectionsHelper.getPath(
														Enums.AppSections.Dashboard
													)}
												/>
											)}
										</Route>
									</Switch>
								)}

								<br />

								{/* <ABB.AppFooter showCopyright={true} showLogo={false} /> */}
							</div>
						</div>
					</ABB.AppMainContent>

					<AppNotificationsTabs />
				</ABB.AppContent>
			)}

			<AppToasts />

			{/* <ABB.NotificationContainer
                       notifications={dataService.Notifications.all.filter((value) => {
                        return value.status === Enums.HealthIndexStatus.Alarm || value.status === Enums.HealthIndexStatus.Warning;
                      }).map((value, index) => {
        
                        let data: ABB.NotificationData = {
                          id: value.id,
                          text: value.text,
                          severity: 'warn',
                          type: "banner",
                          discreet: false,
                          timeout: 1000000
                        };
        
                        return data;
                      })}
                      actionHandler={this.notificationManager}
                       className="notification-container"
                    /> */}
			<AppDialogs />
		</ABB.AppContainer>
	);
};

/*********************************************************/
/* Use either one of the two below. Also, use the right  */
/* command to start the app, defined in package.json     */
/*********************************************************/

// Standard create-react-app way:
export default App;

// Hot-Module-Replacement in use:
//export default hot(module)(App);
