import { LocalizationHelper } from '../helpers';
import { ProductInfoNameplate } from '../wizards/StartupWizard/ProductInfo';

export function getProductInfoData(values: Record<string, any>|undefined): ProductInfoNameplate {
	const nameplate: Record<string, any> = values?.nameplate ?? {};
	return {
		manufacturingDateUs: nameplate.manufacturingDateUs,
		modelAndRatings: nameplate.modelAndRatings ?? '',
		classification: nameplate.classification ?? '',
		mass: nameplate.mass ?? '',
		shortCircuitBreakingCurrent: nameplate.shortCircuitBreakingCurrent ?? '',
		voltage: nameplate.voltage ?? '',
		DCComponentOfRatedShortCircuitBreakingCurrent: nameplate.DCComponentOfRatedShortCircuitBreakingCurrent ?? '',
		lightningImpulseWithstandVoltage: nameplate.lightningImpulseWithstandVoltage ?? '',
		powerFrequencyWithstandVoltage: nameplate.powerFrequencyWithstandVoltage ?? '',
		operatingSequence: nameplate.operatingSequence ?? '',
		frequency: nameplate.frequency ?? '',
		normalCurrent: nameplate.normalCurrent ?? '',
		shortTimeWithstandCurrent: nameplate.shortTimeWithstandCurrent ?? '',
		shortCircuitDuration: nameplate.shortCircuitDuration ?? '',
		serialNumber: nameplate.serialNumber ?? '',
		orderNumber: nameplate.orderNumber ?? '',
		auxiliaryVoltage: nameplate.auxiliaryVoltage ?? '',
		standard: nameplate.standard ?? '',
	};
}

export function getAssetIdentificationData(values: Record<string, any>|undefined) {
	const nameplate: Record<string, any> = values?.nameplate ?? {};
	return {
		serialNumber: nameplate.serialNumber ?? '',
		orderNumber: nameplate.orderNumber ?? '',
	};
}

export function getStartupData(values: Record<string, any>|undefined) {
	const nameplate: Record<string, any> = values?.nameplate ?? {};
	return {
		deviceType: nameplate.deviceType ?? '',
		modelAndRatings: nameplate.modelAndRatings ?? '',
	}
}

export function getCommissioningData(values: Record<string, any>|undefined) {
	const nameplate: Record<string, any> = values?.nameplate ?? {};
	return {
		manufacturingDate: nameplate.manufacturingDateUs ? new Date(nameplate.manufacturingDateUs / 1000) : new Date(),
		installationDate: nameplate.installationDateUs ? new Date(nameplate.installationDateUs / 1000) : new Date(), 
		modelAndRatings: nameplate.modelAndRatings ?? '',
		mass: nameplate.mass ?? '',
		shortCircuitBreakingCurrent: nameplate.shortCircuitBreakingCurrent ?? '',
		voltage: nameplate.voltage ?? '',
		DCComponentOfTheRatedShortCircuitBC: nameplate.DCComponentOfRatedShortCircuitBreakingCurrent ?? '',
		lightningImpulseWithstandVoltage: nameplate.lightningImpulseWithstandVoltage ?? '',
		cableChargingBreakingCurrent: nameplate.cableChargingBreakingCurrent ?? '',
		powerFrequencyWithstandVoltage: nameplate.powerFrequencyWithstandVoltage ?? '',
		operatingSequence: nameplate.operatingSequence ?? '',
		frequency: nameplate.frequency ?? '',
		temperatureClass: nameplate.temperatureClass ?? '',
		normalCurrent: nameplate.normalCurrent ?? '',
		shuntReleaseOnVoltage: nameplate.shuntReleaseOnVoltage ?? '',
		shortTimeWithstandCurrent: nameplate.shortTimeWithstandCurrent ?? '',
		shuntReleaseOffVoltage: nameplate.shuntReleaseOffVoltage ?? '',
		durationOfShortCircuit: nameplate.shortCircuitDuration ?? '',
		chargingMotorVoltage: nameplate.chargingMotorVoltage ?? '',
		deviceType: nameplate.deviceType ?? '',
		assetLabel: nameplate.assetLabel ?? '',
		serialNumber: nameplate.serialNumber ?? '',
		contractType: nameplate.warrantyStatus ? `${LocalizationHelper.getTranslation('Warranty')}, ${nameplate.warrantyStatus}` : '',
	};
}
