export class AbbColorHelper {

    static getRgbHexColor(color: string): string {
        switch (color) {
            case "color-status-yellow": return "#FFD800";
            case "color-status-orange": return "#FF7300";
            case "color-status-red": return "#F03040";
            case "color-status-magenta": return "#CB2BD5";
            case "color-status-blue": return "#0052FF";
            case "color-status-green": return "#0CA919";
            default:
                throw new RangeError("Invalid color name: " + color);
        }
    }
}