import { BehaviorSubject } from 'rxjs';

export const loginDialogIsShown$ = new BehaviorSubject(false);
export const aboutDialogIsShown$ = new BehaviorSubject(false);
export const changePasswordDialogIsShown$ = new BehaviorSubject(false);
export const userMenuOpen$ = new BehaviorSubject(false);
export const alertBellToggled$ = new BehaviorSubject(false);
export const topBarDropdownsOpen$ = new BehaviorSubject<{ [dropdownId: string]: boolean }>({ });
export const startupWizardIsShown$ = new BehaviorSubject(false);
export const commissioningWizardIsShown$ = new BehaviorSubject(false);
export const isStartupPhase$ = new BehaviorSubject(false);
export const isCommissioningPhase$ = new BehaviorSubject(false);
export const sessionAboutToExpire$ = new BehaviorSubject(false);
