import React, { ReactElement } from 'react';
import { Card } from '../../components';
import { SectionLink } from '../../components/SectionLink/SectionLink';
import { WidgetProps } from '../../models';
import { InputStatusItem, InputStatusItemProps } from './InputStatusItem';

export interface InputStatusProps extends WidgetProps {
	inputs: InputStatusItemProps[],
	link: string,
	linkName: string,
}

export function InputStatus(props: InputStatusProps): ReactElement {
	return <Card
		mobileCols={props.mobileCols ?? 12}
		tabletCols={props.tabletCols ?? 6}
		desktopCols={props.desktopCols ?? 6}
		title={props.title}
		installed={props.installed}
		disconnected={props.disconnected}
		comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
	>	
		<div className="row">
			{props.inputs.map((itemProps, i) => <InputStatusItem key={i} {...itemProps} />)}
		</div>
		<SectionLink 
			className="mt-2"
			link={props.link}
			linkName={props.linkName}
			showName={true}
			showIcon={true}
		/>
	</Card>
}
