import { IApiService } from './IApiService';
import * as real from './real';
import * as mock from './mock';
import { EnvHelper } from '../../helpers';

export const apiService: IApiService = (
		(process.env.REACT_APP_USE_MOCK || (EnvHelper.isDev && !process.env.REACT_APP_API_URL))
			? mock
			: real
);

