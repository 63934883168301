import { BehaviorSubject } from 'rxjs';
import * as ABB from "@abb/abb-common-ux-react";
import { updateBehaviorSubject } from './observables/utils';
import { TFunction } from 'react-i18next';
import { isDemo } from './services/demo';
import { FetchError } from './utils/fetch';

export const toasts$ = new BehaviorSubject<ABB.NotificationData[]>([]);
let notificationCounter = 0;

export type FireNotificationParams = Omit<ABB.NotificationData, 'discreet' | 'id' | 'timeout' | 'buttons'> & {
	timeout?: number,
	buttons?: Array<{
		text: string;
		primary?: boolean;
		handler: (notificationId: string) => void;
	}>,
};

export function fireNotification(data: FireNotificationParams) {
	const newNotification: ABB.NotificationData = {
		id: "notification-" + notificationCounter++,
		buttons: data.buttons as unknown as ABB.NotificationData['buttons'],
		type: data.type,
		severity: data.severity,
		text: data.text,
		timeout: data.timeout ?? 5000,
		discreet: false,
	};
	updateBehaviorSubject(toasts$, (toasts) => [...toasts, newNotification]);
};
export function closeToast(id: string) {
	updateBehaviorSubject(toasts$, (toasts) => toasts.filter((toast) => toast.id !== id));
}

export function fireErrorNotification(data: FireNotificationParams, error: Error, t: TFunction) {
	if (isDemo && error instanceof FetchError && error.status === 406) {
		fireNotification({ severity: 'alarm', text: t('DemoActionNotAvailable'), type: 'banner' });
	} else {
		fireNotification(data);
	}
}
