import React, { ReactElement } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export interface CBStatusItemProps {
	title: string;
	ready: boolean;
	closed: boolean;
	alarm: boolean;
	connected: boolean;
	open: boolean;
}

export function CBStatusItem(props: CBStatusItemProps): ReactElement {
	const { t } = useTranslation();
	return <div className={`cb-status-item-wrapper d-flex justify-content-between align-items-center`}>
		<div className="d-flex align-items-center title-wrapper">
			{props.closed && !props.open && <ABB.Icon sizeClass="medium" name="abb/minus-in-circle" color="color-status-red" className="mr-3" style={{transform: "rotate(90deg)"}} />}
			{props.open && !props.closed && <ABB.Icon sizeClass="medium" name="abb/minus-in-circle" color="color-status-green" className="mr-3"/>}
			{((props.open && props.closed) || (!props.open && !props.closed)) && <ABB.Icon sizeClass="medium" name="abb/close" color="color-status-red" className="mr-3"/>}
			{props.connected
				? <ABB.Icon sizeClass="medium" name="abb/drive-connected" className="mr-3" />
				: <ABB.Icon sizeClass="medium" name="abb/drives-error" className="mr-3" />
			}
			<h6 className="mb-0 font-weight-bold">{props.title}</h6>
		</div>
		<span className="font-weight-bold ready-wrapper">{props.ready ? t("Ready") : t("Not ready")}</span>
		<span className="font-weight-bold alarm-text alarm-wrapper">
			{props.alarm ? <><ABB.Icon sizeClass="medium" color="color-status-red" name="abb/warning-triangle" className="alarm-icon"/> {t("Alarm")}</> : t("No alarm")}
		</span>
		
	</div>
}
