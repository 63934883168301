import React, { useCallback, useState } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { useReadable } from "../observables/hooks";
import { aboutDialogIsShown$, changePasswordDialogIsShown$, loginDialogIsShown$, sessionAboutToExpire$ } from "./layout-state";
import { useTranslation } from "react-i18next";
import { Login } from "../components";
import { useEffect } from "react";
import { apiService } from "../services/api";
import { handleLogin } from "./login";
import { fireErrorNotification, fireNotification } from "../toasts";
import { boardDate$, productName } from "../services/config";
import { UserChangePassword } from '../widgets';

export function AppDialogs(): React.ReactElement {
	const { t } = useTranslation();
	const [syncDateBoard, setSyncDateBoard] = useState<Date | undefined>();
	const [syncDateClient, setSyncDateClient] = useState<Date | undefined>();
	const [refreshingSession, setRefreshingSession] = useState(false);
	const boardDate = useReadable(boardDate$);

	useEffect(() => {
		if (boardDate) {
			const clientDate = new Date();
			const diff = Math.abs(boardDate.getTime() - clientDate.getTime());
			if (diff > 5 * 60 * 1000) {
				//5 minutes
				setSyncDateBoard(boardDate);
				setSyncDateClient(clientDate);
			}
		}
	}, [boardDate]);

	const syncDate = useCallback(async () => {
		setSyncDateBoard(undefined);
		try {
			await apiService.setDate();
			fireNotification({
				type: "banner",
				severity: "success",
				text: t("SyncDateSuccess"),
			});
		} catch (error) {
			console.error("syncDate", error);
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("SyncDateError"),
			}, error, t);
		}
	}, [t, setSyncDateBoard]);

	const aboutDialogIsShown = useReadable(aboutDialogIsShown$);
	const loginDialogIsShown = useReadable(loginDialogIsShown$);
	const changePasswordDialogIsShown = useReadable(changePasswordDialogIsShown$);
	const sessionAboutToExpire = useReadable(sessionAboutToExpire$);

	const refreshSession = useCallback(async () => {
		setRefreshingSession(true);
		try {
			await apiService.refreshSession();
			sessionAboutToExpire$.next(false);
			fireNotification({
				severity: 'success',
				text: t("SessionRefreshSuccess"),
				type: 'banner',
			});
		} catch (err) {
			fireErrorNotification({
				severity: 'alarm',
				text: t("SessionRefreshError"),
				type: 'banner',
			}, err, t);
		} finally {
			setRefreshingSession(false);
		}
	}, [t]);

	return (
		<>
			<ABB.Dialog
				isOpen={aboutDialogIsShown}
				onClose={() => aboutDialogIsShown$.next(false)}
				showCloseButton={true}
				closeOnEscape={true}
				closeOnLostFocus={true}
				dimBackground={true}
				disableDefaultStyle={false}
			>
				<ABB.AboutBox
					productName={t(productName)}
					tabs={[
						(
							<ABB.TabItem title={t("BasicInfo")} key="basic">
								<div>{t(productName)}</div>
								<div>{t("Basic information can be written here")}</div>
								<div>R.220906 - Beta</div>
							</ABB.TabItem>
						) as unknown as ABB.TabItem,
						(
							<ABB.TabItem title={t("Licenses")} key="licenses">
								<div>
									<ul>
										<li>React</li>
										<li>D3</li>
										<li>Am Charts</li>
										<li>ZXing.Net</li>
										<li>
											ABB and the logo are trademarks of ABB Asea Brown Boveri
											Ltd
										</li>
									</ul>
								</div>
							</ABB.TabItem>
						) as unknown as ABB.TabItem,
					]}
				></ABB.AboutBox>
			</ABB.Dialog>

			<ABB.Dialog
				isOpen={loginDialogIsShown}
				onClose={() => loginDialogIsShown$.next(false)}
				showCloseButton={false}
				closeOnEscape={true}
				closeOnLostFocus={true}
				dimBackground={true}
				disableDefaultStyle={true}
			>
				<Login onLogin={handleLogin} />
			</ABB.Dialog>

			<ABB.Dialog
				isOpen={syncDateBoard !== undefined}
				showCloseButton={false}
				closeOnEscape={true}
				closeOnLostFocus={false}
				dimBackground={true}
				title={t("SyncDateDialogTitle")}
				standardButtonsOnBottom={[
					{
						text: t("Cancel"),
						type: "discreet-blue",
						handler: (_dlg) => setSyncDateBoard(undefined),
					},
					{
						text: t("Synchronize"),
						type: "primary-blue",
						handler: (_dlg) => syncDate(),
					},
				]}
			>
				{syncDateBoard && (
					<p>
						{t("SyncDateBoardDate")}{" "}
						{syncDateBoard.toLocaleString(undefined, { hourCycle: "h12" })}{" "}
					</p>
				)}
				{syncDateClient && (
					<p>
						{t("SyncDateClientDate")}{" "}
						{syncDateClient.toLocaleString(undefined, { hourCycle: "h12" })}{" "}
					</p>
				)}
				<p>{t("SyncDateDialogMessage")}</p>
			</ABB.Dialog>

			<ABB.Dialog
				dimBackground
				title={t("PasswordChangeRequiredTitle")}
				isOpen={changePasswordDialogIsShown}>
				<p>{t("PasswordChangeRequiredMessage")}</p>
				<UserChangePassword />
			</ABB.Dialog>

			<ABB.Dialog
				dimBackground
				title={t("SessionAboutToExpire")}
				isOpen={sessionAboutToExpire}
			>
				<p>{t("SessionAboutToExpireMessage")}</p>
				<div className='text-center'>
					<ABB.Button
						isLoading={refreshingSession}
						sizeClass='small'
						type='primary-blue'
						text={t("Ok")}
						onClick={() => refreshSession()} />
				</div>
			</ABB.Dialog>
		</>
	);
}
