import { ConnectionStatus } from '../enums'

export class ConnectionHelper {

  static getIcon(hi: ConnectionStatus): string {

    if (hi === ConnectionStatus.Connected)
      return "abb/drive-connected";

    if (hi === ConnectionStatus.Disconnected)
      return "abb/drives-error";

    throw new RangeError();
  }

  static getSensorIcon(hi: ConnectionStatus, wireless: boolean): string {

    if (hi === ConnectionStatus.Connected)
      return wireless? "abb/drive-connected" : "abb/drive";

    if (hi === ConnectionStatus.Disconnected)
      return "abb/drives-error";

    throw new RangeError();
  }


  static getColor(hi: ConnectionStatus) {

    if (hi === ConnectionStatus.Connected)
      return "color-status-green";

    if (hi === ConnectionStatus.Disconnected)
      return "color-status-red";

    throw new RangeError();
  }
}