import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ABB from '@abb/abb-common-ux-react';
import { useReadable } from '../../observables/hooks';
import { AvailableSensor, getSensorDescription, getSensorName, InstalledSensor, sensors$ } from '../../services/sensor';
import { user$ } from '../../services/user';
import { Role } from '../../enums';
import { SettingsSensor } from '../../widgets';
import { fireNotification } from '../../toasts';

interface SensorProps {
	name: string,
	description: string,
	onSerialNumberChange: (serialNumber: string) => unknown,
	userRole: Role,
	sensor: InstalledSensor|AvailableSensor,
}
function Sensor({ name, onSerialNumberChange, description, userRole, sensor }: SensorProps): ReactElement {
	const { t } = useTranslation();
	const [serialNumber, setSerialNumber] = useState('');

	useEffect(() => {
		onSerialNumberChange(serialNumber);
	}, [onSerialNumberChange, serialNumber]);

	return <SettingsSensor
		fireNotification={fireNotification}
		label={name}
		description={description}
		userRole={userRole}
		sensor={sensor}
	>
		<ABB.Input
			dataType='text'
			value={serialNumber}
			onValueChange={(value) => setSerialNumber(value)}
			label={t('SerialNumber')}
		/>
	</SettingsSensor>
}

export interface AdditionalSensorsData {
	serialNumbers: { [model: string ]: { [id: string]: string } };
}
interface AdditionalSensorsProps {
	setData: (data: AdditionalSensorsData|null) => unknown,
	onLock: () => unknown,
	onUnlock: () => unknown,
}

export function AdditionalSensors({ setData }: AdditionalSensorsProps): ReactElement {
	const { t } = useTranslation();
	const allSensors = useReadable(sensors$);
	const serialNumbers = useRef<{ [model: string ]: { [id: string]: string } }>({});
	const userRole =  useReadable(user$)?.role ?? Role.None;
	const [sensors, setSensors] = useState<(InstalledSensor|AvailableSensor)[]>([]);

	useEffect(() => {
		setSensors(
			allSensors
				.filter((s) => s.exported)
				.sort((s1, s2) => {
					if (s1.installed && !s2.installed) {
						return -1;
					} else if (!s1.installed && s2.installed) {
						return 1;
					} else {
						const sensor1Name = getSensorName(s1.model, s1.roles);
						const sensor2Name = getSensorName(s2.model, s2.roles);
						if (sensor1Name < sensor2Name) {
							return -1;
						} else if (sensor1Name > sensor2Name) {
							return 1;
						}
						const sensor1Description = getSensorDescription(s1.model, s1.roles);
						const sensor2Description = getSensorDescription(s2.model, s2.roles);
						if (sensor1Description < sensor2Description) {
							return -1;
						} else if (sensor1Description > sensor2Description) {
							return 1;
						}
					}
					return 0;
				})
		);
	}, [allSensors]);
	return <>
		<p>{t("AdditionalSensorsInfo")}</p>
		<div className='px-2'>
			<div className='row'>
				{sensors.map((sensor, i) => <div key={i} className='col-md-6 col-12 mt-3 px-2'>
					<Sensor 
						name={getSensorName(sensor.model, sensor.roles)} 
						onSerialNumberChange={(serialNumber) => {
							serialNumbers.current[sensor.model] = serialNumbers.current[sensor.model] || {};
							serialNumbers.current[sensor.model][sensor.id] = serialNumber;
							setData({
								serialNumbers: serialNumbers.current,
							});
						}} 
						description={getSensorDescription(sensor.model, sensor.roles)}
						sensor={sensor}
						userRole={userRole}
					/>
				</div>)}
				{sensors.length === 0 && <div className='col-12 mt-3 px-2'>
					<span className='text-muted'>{t("No additional sensors available")}</span>
				</div>}
			</div>
		</div>
	</>;
}
