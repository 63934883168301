export class BootstrapHelper {

    static getColValue(defaultValue: number, value?: number): number {
        return value ?? defaultValue;
    }

    static getClassName(mobile: number, tablet: number, desktop: number, disabled: boolean = false): string {

        let mobileClass = mobile === 0 ? 'd-sm-none' : `d-sm-block col-sm-${mobile}`;
        let tabletClass = tablet === 0 ? 'd-md-none' : `d-md-block col-md-${tablet}`;
        let desktopClass = desktop === 0 ? 'd-lg-none' : `d-lg-block col-lg-${desktop}`;

        return ` ${mobileClass} ${tabletClass} ${desktopClass} ${this.getElementByStatus(disabled)}`;
    }

    static getElementByStatus(disabled: boolean = false): string {

        if (disabled)
            return " disabledWidget ";
        else
            return "";
    }
}