import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/fr';
import * as Enums from '../enums';

export enum LocaleFormat {
    LT = 'LT',  // 11:48 AM
    LTS = 'LTS',   // 11:48:23 AM
    L = 'L',     // 02/05/2020
    l = 'l',     // 2/5/2020
    LL = 'LL',    // February 5, 2020
    ll = 'll',    // Feb 5, 2020
    LLL = 'LLL',   // February 5, 2020 11:48 AM
    lll = 'lll',   // Feb 5, 2020 11:48 AM
    LLLL = 'LLLL',  // Wednesday, February 5, 2020 11:48 AM
    llll = 'llll',  // Wed, Feb 5, 2020 11:50 AM
}


export class DateTimeFormatHelper {

    static humanDiff(to: Date, from: Date, lang: string): string {
        moment.updateLocale(lang, {
            relativeTime: {
                future: '%s',
                past: '-%s',
                s: '%d s',
                ss: '%d s',
                m: '1 m',
                mm: '%d m',
                h: '1 h',
                hh: '%d h',
                d: '1 d',
                dd: '%d d',
                M: '1 M',
                MM: '%d M',
                y: '1 y',
                yy: '%d y'
            }
        });

        return moment.duration(moment(to).diff(from)).humanize(false);
    }
 
    static toDate(timestamp_us: number): Date|null {
				if (timestamp_us === 0) {
					return null;
				}
        let utcMilliSeconds = timestamp_us / 1000;
        let convertedDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
        convertedDate.setUTCMilliseconds(utcMilliSeconds);
        return convertedDate;
    }

    static convertUtCDate(date: Date, format?: string | LocaleFormat): string {

        let result: string = "";
        moment.locale('en');

        if (format) {
            result = moment(date).format(format.toString())
        }
        else {
            result = moment(date).format();
        }

        return result;

    }

    //restituisce la data di inizio dell'intervallo di tempo partendo da adesso
    static getTimeIntervalBeginningFromNow(timeInterval: Enums.ChartTimeRange): Date {

        let dateFrom: Date = new Date();
        switch (timeInterval) {
            case Enums.ChartTimeRange.Hour:
                dateFrom.setHours(dateFrom.getHours() - 1);
                return dateFrom;

            case Enums.ChartTimeRange.Day:
                dateFrom.setDate(dateFrom.getDate() - 1);
                return dateFrom;

            case Enums.ChartTimeRange.Week:
                dateFrom.setDate(dateFrom.getDate() - 7);
                return dateFrom;

            case Enums.ChartTimeRange.Month:
                dateFrom.setMonth(dateFrom.getMonth() - 1);
                return dateFrom;

            default:
                throw new Error("getTimeIntervalBeginningFromNow - unhandled ChartTimeInterval: " + timeInterval);
        }
    }

    static getDaysBetween(biggerDate: Date, smallerDate: Date): number {
        let a = moment(biggerDate);
        let b = moment(smallerDate);
        return a.diff(b, 'days');
    }

    static getHoursBetween(biggerDate: Date, smallerDate: Date): number {
        let a = moment(biggerDate);
        let b = moment(smallerDate);
        return a.diff(b, 'hours')
    }
}
