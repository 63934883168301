export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Il max è escluso e il min è incluso
}

export function roundNumber(n: number | string, precision = 0.01): number {
		if (n === null || n === undefined) {
			console.warn('roundNumber | received null parameter');
			return 0;
		}
    if (typeof n === "string")
        n = parseFloat(n);
    return Number(n.toFixed(Math.abs(Math.log10(precision))));
}
