import { getConnection, getLastByDataAggregation, getLastValueFromAggregration, getLoad } from '.';
import { ConnectionStatus } from '../../enums';
import { AggregationResponse, LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { SensorsOverviewItemProps, SensorsOverviewProps } from '../../widgets';
import { areInstalled, findDisconnected } from '../sensor';

export async function loadSensorsOverviewData(config: WidgetConfig, props: Partial<SensorsOverviewProps>): Promise<SensorsOverviewProps> {
	console.info("loadSensorsOverviewData");
	const sensorsOverview: Partial<SensorsOverviewProps> = { ...props };
	try {
		if (!config.propsConfig) {
			throw new Error("loadSensorsOverviewData | api configuration not found");
		}
		const propsConfig = config.propsConfig as unknown as SensorsOverviewPropsConfig;
		const sources = propsConfig.sensors
			.flatMap((sensor) => [
				sensor.connectionEvent.source,
				sensor.loadEvent.source,
				sensor.tempSample.source
			]);
		if (propsConfig.installedDevices) {
			sensorsOverview.installed = areInstalled(propsConfig.installedDevices);
			if (sensorsOverview.installed) {
				sensorsOverview.disconnected = findDisconnected(propsConfig.installedDevices);
			} else {
				sensorsOverview.disconnected = undefined;
			}
		}
		const apiResp = await getLastByDataAggregation(...sources);

		sensorsOverview.sensors!.forEach((sensor, index) => {
			const apiItem = propsConfig.sensors[index];
			loadSensorsOverviewDataElement(apiResp, sensor, apiItem);
			sensorsOverview.lastUpdate = getGreaterDate(sensor.lastConnectionDate, sensorsOverview.lastUpdate) ?? null;
			sensorsOverview.lastUpdateType = LastUpdateType.Event;
		});

		sensorsOverview.fetching = false;
	} catch (e) {
		console.error("loadSensorsOverviewData", e);
		sensorsOverview.exception = e;
	}
	return sensorsOverview as SensorsOverviewProps;
}


function loadSensorsOverviewDataElement(apiResp: AggregationResponse, s: SensorsOverviewItemProps, config: SensorsOverviewItemPropsConfig) {
	try {
		const connectionEventValue = getLastValueFromAggregration(apiResp, config.connectionEvent.source);
		if (connectionEventValue) {
			s.connected = getConnection(connectionEventValue) === ConnectionStatus.Connected;
			s.lastConnectionDate = getGreaterDate(s.lastConnectionDate, connectionEventValue.date);
		} else {
			s.connected = false;
		}
	} catch (err) {
		// if no event is found, default value is not connected
		console.warn(err);
		s.connected = false;
	} 
	try {
		const loadEventValue = getLastValueFromAggregration(apiResp, config.loadEvent.source);
		if (loadEventValue) {
			s.loadStatus = getLoad(loadEventValue);
			s.lastConnectionDate = getGreaterDate(s.lastConnectionDate, loadEventValue.date);
		}
	} catch (err) {
		// no event found
		console.warn(err);
		s.loadStatus = null;
	}

	try {
		const tempSampleValue = getLastValueFromAggregration(apiResp, config.tempSample.source);
		if (tempSampleValue) {
			s.value = tempSampleValue.rawValue ?? null;
			s.lastConnectionDate = getGreaterDate(s.lastConnectionDate, tempSampleValue.date);
		}
	} catch (err) {
		// no sample found
		console.warn(err);
		s.value = null;
	}
}

export interface SensorsOverviewPropsConfig {
	title: { i18n: string },
	sensors: SensorsOverviewItemPropsConfig[],
	disabled?: {
		value: boolean,
	},
	installedDevices?: { role: string, position: string }[]
};


export interface SensorsOverviewItemPropsConfig {
	group?: { value: string },
	title: { i18n: string },
	label: { i18n: string },
	tempSample: {
		source: NamedSource,
	},
	loadEvent: {
		source: NamedSource,
	},
	connectionEvent: {
		source: NamedSource,
	}
}
