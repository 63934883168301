import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { BarChart, BarChartProps, WaveformChart, WaveformChartProps } from '..';
import { SpritePointerTypeEvent } from '@amcharts/amcharts4/.internal/core/SpriteEvents';
import './style.scss';
import { PollingWritable } from '../../utils/polling-subject';
import { BehaviorSubject } from 'rxjs';
import { useReadable } from '../../observables/hooks';
import { DateTimeFormatHelper } from '../../helpers';

export interface BarChartWithChartTooltipProps extends BarChartProps {
	tooltipChartType: 'WaveformChart',
	tooltipChartProps$: BehaviorSubject<WaveformChartProps>,
	tooltipChartPoller$: PollingWritable<WaveformChartProps>|undefined,
	selectedTimestamp$: BehaviorSubject<number>,
}

export function BarChartWithChartTooltip(props: BarChartWithChartTooltipProps): ReactElement {
	const [showChartTooltip, setShowChartTooltip] = useState(false);
	const [chartStyle, setChartStyle] = useState<any>({ top: "-100%", left: 0, bottom: 0 });
	const [arrowStyle, setArrowStyle] = useState<any>({ left: 0 });
	const chartAreaRef = useRef<HTMLDivElement>(null);
	const [tooltipChartTitle, setTooltipChartTitle] = useState("");
	const chartProps = useReadable(props.tooltipChartProps$);
	const selectedTimestamp = useReadable(props.selectedTimestamp$);
	const onBarClick = useCallback((dataItem: am4core.Optional<am4core.DataItem>, ev: {
		type: "hit";
		target: am4charts.Column;
	} & SpritePointerTypeEvent & am4core.SpritePointEvent & am4core.SpriteMouseTouchEvent) => {
		const newTimestamp = (dataItem!.dataContext as any).date_us;
		if (selectedTimestamp === newTimestamp) { return; }
		setShowChartTooltip(true);
		props.selectedTimestamp$!.next(newTimestamp);
		setTooltipChartTitle(DateTimeFormatHelper.toDate(newTimestamp)!.toLocaleString(undefined, { 
			minute: '2-digit', 
			hour: '2-digit', 
			hourCycle: 'h12', 
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}));
		const offsetWidth = chartAreaRef.current?.offsetWidth ?? 0;
		const offsetLeft = (chartAreaRef.current?.offsetParent as HTMLElement)?.offsetLeft ?? 0;
		let left = 0;
		if ((ev.point.x - offsetLeft) > offsetWidth * 0.3) {
			left = Math.min(offsetWidth * 0.4, (ev.point.x - offsetLeft) - offsetWidth * 0.3);
		}
		setChartStyle({
			left: `${left}px`,
			bottom: 0,
			top: `${(chartAreaRef.current?.offsetHeight ?? 0) * -1 + ev.svgPoint.y}px`,
		});
		setArrowStyle({
			left: `${(ev.point.x - offsetLeft - 7 - left)}px`
		});
		props.tooltipChartPoller$?.fetch();
	}, [props.tooltipChartPoller$, props.selectedTimestamp$, selectedTimestamp]);

	useEffect(() => {
		if (!showChartTooltip) { return; }
		const hideTooltipChart = () => setShowChartTooltip(false);
		document.body.addEventListener('pointerup', hideTooltipChart);
		return () => {
			document.body.removeEventListener('pointerup', hideTooltipChart);
		}
	}, [showChartTooltip]);
	
	return <div style={{ position: "relative", width: "100%" }} ref={chartAreaRef} ><BarChart
		{...props}
		onBarClick={onBarClick}
	/>
		{showChartTooltip && <><div
			style={{ position: "absolute", width: "60%", ...chartStyle }}>
			<div className='shadow border bg-white' style={{ position: "relative"}} onPointerUp={(e) => e.stopPropagation()}>
				<div className='tooltip-arrow shadow border-bottom border-right bg-white' style={{...arrowStyle}} />
				<WaveformChart
					{...chartProps}
					title={tooltipChartTitle}
					desktopCols={12}
					tabletCols={12}
					mobileCols={12}/>
			</div>
		</div></>}
	</div>
}
