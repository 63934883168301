import { getEvents } from '.';
import { DateTimeFormatHelper } from '../../helpers';
import { ApiIdentityItemType } from '../../models';
import { WidgetConfig } from '../../pages/DynamicPage';
import { EventLogsProps } from '../../widgets';
import { devicesMetadata$ } from '../device';

export async function loadEventLogs(config: WidgetConfig, props: Partial<EventLogsProps>): Promise<EventLogsProps> {
	const eventLogs = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as EventLogsPropsConfig;
		const dateFrom = new Date();
		dateFrom.setHours(dateFrom.getHours() - 24);
		const allEvents = await Promise.all(propsConfig.devices.map((d) => {
			return getEvents({
				type: ApiIdentityItemType.event,
				device: { id: d.id.value, model: d.model.value },
				name: 'ALL',
			}, undefined, dateFrom);
		}));
		const metadata = devicesMetadata$.getValue();
		eventLogs.logs = allEvents.flatMap((eventsByDevice) => eventsByDevice
			.map((e) => {
				return {
					analysis: metadata.models[e.model]?.events?.[e.name].analysis?.[e.state] ?? '-',
					description: metadata.models[e.model]?.events?.[e.name].description ?? '-',
					name: e.name, // ACU events => do not use formatted names
					time: DateTimeFormatHelper.toDate(e.timestamp_us)!.toLocaleTimeString(),
					unit: e.device_id,
					exported: metadata.models[e.model]?.events?.[e.name].exportedStates?.[e.state] === '1',
				}
			})
		);
	} catch (e) {
		console.error("loadEventLogs", e);
		eventLogs.exception = e;
	} finally {
		eventLogs.fetching = false;
	}
	return eventLogs as EventLogsProps;
}

export interface EventLogsPropsConfig {
	title: {
		i18n: string,
	},
	devices: {
		model: {
			value: string,
		},
		id: {
			value: string,
		}
	}[],
}
