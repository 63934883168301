import { WidgetLayoutConfig } from '..';
import { ChartTimeRange, ChartLastOperation } from "../../enums"

export enum LastUpdateType {
	Sample = "Sample",
	Event = "Event",
}

export interface WidgetProps {
    mobileCols?: number;
    tabletCols?: number;
    desktopCols?: number;
    title?: string;
    lastUpdate?: Date|null;
		lastUpdateType?: LastUpdateType,
    fetching?: boolean;
    externalFetching?: boolean;
    disabled?: boolean;
    key?: string;
    exception?: Error;
		installed?: boolean;
		disconnected?: { role: string, position?: string }[];
}


export interface ChartDateRange {
    startDate: Date;
    endDate: Date;
    noSubsampling?: boolean;
}

export type ChartScaleType = ChartTimeRange | ChartLastOperation | ChartDateRange | undefined;

export interface ChartWidgetProps extends WidgetProps {
    scaleType?: ChartScaleType;
		filterRef?: string;
		xAxisLabelAlignment?: 'center'|'left'|'right'|'none';
		yAxisLabelAlignment?: 'top'|'middle'|'bottom'|'none';
		showUnitInYLabel: boolean;
}

export interface ChartFilterWidgetProps extends WidgetProps {
	filterRef?: string;
	defaultValue?: ChartScaleType;
	enableLastOperations?: boolean,
	defaultTimeRange?: ChartTimeRange,
	enableDateRange: boolean,
	enableTimeRange: boolean,
	enableDate: boolean,
	onScaleTypeChange?: (scaleType: ChartScaleType) => void,
	layout?: WidgetLayoutConfig
}
