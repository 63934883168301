import React, { ReactElement } from "react";
import { useHistory } from 'react-router';
import * as ABB from "@abb/abb-common-ux-react";
import { SectionsHelper } from '../../helpers';

export interface SectionLinkProps {
	link: string,
	linkName?: string,
	showName: boolean,
	showIcon: boolean,
	className?: string
}
export function SectionLink({ link, linkName, showIcon, showName, className }: SectionLinkProps): ReactElement {
	const hist = useHistory();
	const href = SectionsHelper.createHrefByString(link, hist);
	return <a href={href} className={`d-flex align-items-center justify-content-center ${className || ''}`}>
		{showName && <div className="mr-1 align-middle">{linkName}</div>}
		{showIcon && <ABB.Icon sizeClass="small" name="abb/right-arrow" color="color-status-blue" />}
	</a>
}
