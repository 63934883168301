import React, { ReactElement, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as ABB from "@abb/abb-common-ux-react";
import { Card, LastUpdate } from "../../components";
import { ChartDataItem, ChartWidgetProps, ChartLegendPosition } from "../../models"
import { AbbColorHelper, HealthIndexStatusHelper, BootstrapHelper, ChartHelper } from "../../helpers"
import "./style.scss";
import { HealthIndexStatus, ChartTimeRange, ChartLastOperation } from "../../enums";
import { useTranslation } from 'react-i18next';
import { useReadable } from '../../observables/hooks';
import { multiLineChartTimeScale$ } from '../../services/polling/chart/multiline';
import { chartFilters$ } from '../../services/polling/chart';
import { useRef } from 'react';


export interface MultiLineChartItemProps {
	unit: string;
	name: string;
	color: string;
	lastValue: number;
	lastUpdate: Date;

	dashed?: boolean;
	
	highAlarm?: number;
	highWarn?: number;
	lowWarn?: number;
	lowAlarm?: number;

	highAlarmLabel?: string;
	highWarnLabel?: string;
	lowWarnLabel?: string;
	lowAlarmLabel?: string;

	showSerieMissingDataGap?: boolean;//NEW PROP
	connect?: boolean;
}

export type SeriesType = "line" | "step";

export interface MultiLineChartProps extends ChartWidgetProps {
	dataInfo: MultiLineChartItemProps[];
	data: ChartDataItem[];
	legend?: ChartLegendPosition;
	showFilter?: boolean;
	yAxisLabel?: string;
	xAxisLabel?: string;
	seriesType?: SeriesType;
	multipleYAxis?: boolean;
	hideCard?: boolean;//NEW PROP
	showGrid?: boolean;
	image?: string;
	cardTopRightContent?: () => ReactElement;
	fillOnHover: boolean;
}

export function MultiLineChart(props: MultiLineChartProps): ReactElement {
	const { t } = useTranslation();
	const scaleTypeReadable = useReadable(multiLineChartTimeScale$);
	const chartFilters = useReadable(chartFilters$);
	const selectedFilter = props.filterRef ? chartFilters[props.filterRef] : null;
	const scaleType = selectedFilter ?? props.scaleType ?? scaleTypeReadable;
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [chart, setChart] = useState<am4charts.XYChart | null>(null);
	const [xDateAxis, setXDateAxis] = useState<am4charts.DateAxis | null>(null);
	const [defaultXAxisName, setDefaultXAxisName] = useState('');

	useEffect(() => {
		if (Object.values(ChartLastOperation).includes(scaleType as any)) {
			setDefaultXAxisName(t("OperationExecuted"));
		} else {
			setDefaultXAxisName(t("Time"));
		}
	}, [scaleType, t]);
	//************************************************************ LEGEND FUNCTIONS ************************************************************
	function showHideElement(i: number) {

		if (chart && !chart.isDisposed()) {
			chart.series.each(function (singleSerie) {
				if (singleSerie.dummyData.seriesid === i)
					if (singleSerie.isHidden)
						singleSerie.show();
					else
						singleSerie.hide();
			})
		}
	}

	function processOutOver() {

		if (chart && !chart.isDisposed()) {
			chart.series.each(function (series) {
				let workingOnSeries = series as am4charts.LineSeries;
				workingOnSeries.segments.each(function (segment: { setState: (arg0: string) => void; }) {
					segment.setState("default");
				})
				series.bulletsContainer.setState("default");
			});
		}
	}

	function processOver(i: number) {
		if (chart && !chart.isDisposed()) {
			chart.series.each(function (singleSerie) {
				if (singleSerie.dummyData.seriesid === i) {
					let typedHoveredSeries = singleSerie as am4charts.LineSeries;
					if (typedHoveredSeries) {
						typedHoveredSeries.toFront();
					}
					typedHoveredSeries.segments.each(function (segment: { setState: (arg0: string) => void; }) {
						segment.setState("hover");
					})

					chart.series.each(function (series) {
						let workingOnSeries = series as am4charts.LineSeries;
						if (workingOnSeries !== typedHoveredSeries) {
							workingOnSeries.segments.each(function (segment: { setState: (arg0: string) => void; }) {
								segment.setState("dimmed");
							})
							series.bulletsContainer.setState("dimmed");
						}
					});
				}
			});
		}
	}

	function createSeriesLabel(text: string | undefined, target: am4core.Label, m: MultiLineChartItemProps) {
		let val = target.dataItem as am4charts.LineSeriesDataItem;
		let status = HealthIndexStatus.Ok;

		if (val) {
			if (m.highAlarm && val.valueY > m.highAlarm) {
				status = HealthIndexStatus.Alarm;
			}
			else if (m.highWarn && val.valueY > m.highWarn) {
				status = HealthIndexStatus.Warning;
			}
			else if (m.lowAlarm && val.valueY < m.lowAlarm) {
				status = HealthIndexStatus.Alarm;
			}
			else if (m.lowWarn && val.valueY < m.lowWarn) {
				status = HealthIndexStatus.Warning;
			}
		}

		if (status === HealthIndexStatus.Ok) {
			return m.name + ": <b>" + val.valueY + m.unit + '</b>';
			//return "{name}: <b>{valueY}" + m.unit + '</b>';
		} else {
			let icon = HealthIndexStatusHelper.getIcon(status).replace('/', '_');
			let color = AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(status));

			//ToDo: trovare un modo di usare il componente Icon di ABB?
			return '{name}: <i class="ABB_CommonUX_Icon__root mx-auto ABB_CommonUX_style__icon_abb_16 ABB_CommonUX_style__icon-' + icon + '_16" style="color:' + color + '"></i> <b>{valueY}' + m.unit + '</b>';
		}
	}
	//************************************************************ LEGEND FUNCTIONS ************************************************************

	useEffect(() => {
		if (!containerRef.current) {
			return;
		}
		const _chart = am4core.create(containerRef.current, am4charts.XYChart);
		_chart.zoomOutButton.disabled = true;
		_chart.dateFormatter.inputDateFormat = "x";
		_chart.cursor = new am4charts.XYCursor();
		_chart.cursor.behavior = "none";
		_chart.cursor.lineY.disabled = true;
		setChart(_chart);
		const _xDateAxis = _chart.xAxes.push(new am4charts.DateAxis());
		_xDateAxis.cursorTooltipEnabled = false;
		_xDateAxis.renderer.grid.template.disabled = true;
		_xDateAxis.baseInterval = { timeUnit: "minute", count: 1 };
		setXDateAxis(_xDateAxis);
	}, [containerRef]);

	const firstRender = useRef(true);
	useEffect(() => {
		if (chart && !chart.isDisposed()) {
			if (!firstRender.current) {
				chart.series.each((series) => {
					series.interpolationDuration = 0;
				});
			}
			firstRender.current = false;
			const lastValidIndex = [...props.data].reverse().findIndex((item) => 
				(item.value || item.value2 || item.value3 || item.value4 || item.value5) !== undefined
			);
			chart.data = props.data.slice(0, props.data.length - lastValidIndex);
		}
	}, [chart, props.data, firstRender]);

	useEffect(() => {
		if (xDateAxis && !xDateAxis.isDisposed()) {
			if (props.xAxisLabel) {
				xDateAxis.title.text = props.xAxisLabel;
			} else {
				xDateAxis.title.text = defaultXAxisName;
			}
			if (props.xAxisLabelAlignment) {
				xDateAxis.title.align = props.xAxisLabelAlignment;
			}
		}
	}, [props.xAxisLabel, xDateAxis, props.xAxisLabelAlignment, defaultXAxisName]);

	useEffect(() => {
		if (chart && !chart.isDisposed() && chart.yAxes.length !== props.dataInfo.length && props.multipleYAxis) {
			for (let i = 0; i < props.dataInfo.length; i++) {
				const yValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				yValueAxis.cursorTooltipEnabled = false;
				yValueAxis.renderer.grid.template.disabled = true;
				yValueAxis.renderer.baseGrid.disabled = true;
				yValueAxis.renderer.labels.template.disabled = !props.multipleYAxis;
				yValueAxis.includeRangesInMinMax = true;
				yValueAxis.extraMin = 0.01;
				yValueAxis.extraMax = 0.01;
				if (props.yAxisLabel) {
					yValueAxis.title.text = props.yAxisLabel;
					if (props.showUnitInYLabel) {
						yValueAxis.title.text += ` (${props.dataInfo[i].unit})`;
					}
					if (props.yAxisLabelAlignment) {
						yValueAxis.title.valign = props.yAxisLabelAlignment;
					}
				}
			}
		} else if (chart && !chart.isDisposed() && chart.yAxes.length === 0 && props.dataInfo.length > 0 && !props.multipleYAxis) {
			const yValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			yValueAxis.cursorTooltipEnabled = false;
			yValueAxis.renderer.grid.template.disabled = props.showGrid ? false : true;
			yValueAxis.renderer.baseGrid.disabled = props.showGrid ? false : true;
			yValueAxis.renderer.labels.template.disabled = props.showGrid ? false : !props.multipleYAxis;
			yValueAxis.includeRangesInMinMax = true;
			yValueAxis.extraMin = 0.01;
			yValueAxis.extraMax = 0.01;
			if (props.yAxisLabel) {
				yValueAxis.title.text = props.yAxisLabel;
				if (props.showUnitInYLabel) {
					yValueAxis.title.text += ` (${props.dataInfo[0].unit})`;
				}
				if (props.yAxisLabelAlignment) {
					yValueAxis.title.valign = props.yAxisLabelAlignment;
				}
			}
		}

		return () => {
			if (chart && !chart.isDisposed()) {
				while (chart.yAxes.length) {
					chart.yAxes.removeIndex(0);
				}
			}
		}
	}, [chart, props.multipleYAxis, props.dataInfo, props.showGrid, props.yAxisLabel, props.yAxisLabelAlignment, props.showUnitInYLabel]);

	useEffect(() => {
		if (!chart || chart.isDisposed() || !chart.yAxes.length || !props.dataInfo || (chart.yAxes.length !== props.dataInfo.length && props.multipleYAxis)) { return; }
		props.dataInfo.forEach((m, i) => {
			const yValueAxis = (chart.yAxes.getIndex(i) ?? chart.yAxes.getIndex(0))! as am4charts.ValueAxis;
			if (m.highAlarm !== undefined) {
				const range = yValueAxis.axisRanges.create();
				range.value = Number(m.highAlarm);
				if (m.highAlarmLabel) {
					range.label.text = m.highAlarmLabel;
				}
			}

			if (m.highWarn !== undefined) {
				const range = yValueAxis.axisRanges.create();
				range.value = Number(m.highWarn);
				if (m.highWarnLabel) {
					range.label.text = m.highWarnLabel;
				}
				range.grid.strokeDasharray = "3";
			}

			if (m.lowWarn !== undefined) {
				const range = yValueAxis.axisRanges.create();
				range.value = Number(m.lowWarn);
				if (m.lowWarnLabel) {
					range.label.text = m.lowWarnLabel;
				}
				range.grid.strokeDasharray = "3";
			}

			if (m.lowAlarm !== undefined) {
				const range = yValueAxis.axisRanges.create();
				range.value = Number(m.lowAlarm);
				if (m.lowAlarmLabel) {
					range.label.text = m.lowAlarmLabel;
				}
			}

			const series = props.seriesType === "step" 
				? chart.series.push(new am4charts.StepLineSeries()) 
				: chart.series.push(new am4charts.LineSeries());

			series.name = m.name;
			series.stroke = am4core.color(m.color);
			series.strokeWidth = 2;
			if (props.fillOnHover) {
				series.events.on('over', (_) => {
					series.fill = am4core.color(m.color);
					series.fillOpacity = 0.2;
				});
				series.events.on('out', (_) => {
					series.fillOpacity = 0;
				});
			}
			if (m.dashed) {
				series.strokeDasharray = "2";
			}
			series.dataFields.valueY = "value" + (i === 0 ? "" : (i + 1));
			series.dataFields.dateX = "date";
			series.tensionX = 0.9;
			series.tensionY = 1;
			series.dummyData = { seriesid: i };
			series.yAxis = yValueAxis;
			// if true the chart will connect the bullets with a line
			series.connect = m.connect ?? false;
			const bullets = new am4charts.CircleBullet();
			bullets.stroke = am4core.color(m.color);
			bullets.fill = am4core.color(m.color);
			bullets.circle.radius = 3;
			series.bullets.push(bullets);
			//interval tolerated between 2 adiacent value, if this interval is not respected adiacent chart point value should not be connected

			if (props.multipleYAxis) {
				yValueAxis.renderer.line.strokeWidth = 2;
				yValueAxis.renderer.line.strokeOpacity = 1;
				yValueAxis.renderer.opposite = i % 2 !== 0;
				yValueAxis.renderer.line.stroke = series.stroke;
				yValueAxis.renderer.labels.template.fill = series.stroke;
				//series.connect= false;
				//if some date has no value for this serie, the gap is shown

			}
			else {
				yValueAxis.renderer.line.strokeOpacity = 0.1;
			}

			//************************************************************ LEGEND: LineSeriesSegment STATE ************************************************************
			let segment: am4charts.LineSeriesSegment = series.segments.template;
			segment.interactionsEnabled = true;

			let hoverState: am4core.SpriteState<am4charts.ILineSeriesSegmentProperties, am4charts.ILineSeriesSegmentAdapters> = segment.states.create("hover");
			hoverState.properties.strokeWidth = 3;

			let dimmed: am4core.SpriteState<am4charts.ILineSeriesSegmentProperties, am4charts.ILineSeriesSegmentAdapters> = segment.states.create("dimmed");
			dimmed.properties.stroke = am4core.color("#dadada");

			//************************************************************ LEGEND: LineSeriesSegment STATE ************************************************************                 

			series.tooltipText = "xxx"; // viene sempre sovrascritto

			let axisTooltip = series.tooltip;
			if (axisTooltip) {
				axisTooltip.numberFormatter.numberFormat = "####.#####";
				axisTooltip.getFillFromObject = false;
				axisTooltip.autoTextColor = false;
				axisTooltip.background.fill = am4core.color("white");
				axisTooltip.label.fill = am4core.color((props.multipleYAxis ? m.color : "black"));

				axisTooltip.label.adapter.add("html", function (text, target) {
					return createSeriesLabel(text, target, m)
				});
			}
		});

		return () => {
			if (chart && !chart.isDisposed()) {
				chart.yAxes.each((axis) => {
					while (axis?.axisRanges?.length) {
						axis.axisRanges.removeIndex(0);
					}
				});
				while (chart.series.length) {
					chart.series.removeIndex(0);
				}
			}
		}
	}, [props.dataInfo, chart, chart?.yAxes, props.seriesType, props.multipleYAxis, props.fillOnHover]);


	useEffect(() => {
		if (chart && !chart.isDisposed() && !props.multipleYAxis && chart.yAxes.length > 0) {
			const maxRange = props.dataInfo.reduce((max, di) => {
				if (di.highAlarm && di.highAlarm > max) {
					return di.highAlarm;
				}
				if (di.highWarn && di.highWarn > max) {
					return di.highWarn;
				}
				if (di.lowWarn && di.lowWarn > max) {
					return di.lowWarn;
				}
				if (di.lowAlarm && di.lowAlarm > max) {
					return di.lowAlarm;
				}
				return max;
			}, 0);
			const minRange = props.dataInfo.reduce((min, di) => {
				if (di.lowAlarm && di.lowAlarm < min) {
					return di.lowAlarm;
				}
				if (di.lowWarn && di.lowWarn < min) {
					return di.lowWarn;
				}
				if (di.highWarn && di.highWarn < min) {
					return di.highWarn;
				}
				if (di.highAlarm && di.highAlarm < min) {
					return di.highAlarm;
				}
				return min;
			}, 0);
			const maxValue = props.data.reduce((max, el) => {
				return Object.entries(el)
					.filter(([k, value]) => k.startsWith('value') && value !== undefined)
					.reduce((newMax, [_k, value]) => {
						if (value > newMax) {
							return value;
						}
						return newMax;
					}, max);
			}, maxRange);
			const minValue = props.data.reduce((min, el) => {
				return Object.entries(el)
					.filter(([k, value]) => k.startsWith('value') && value !== undefined)
					.reduce((newMin, [_k, value]) => {
						if (value < newMin) {
							return value;
						}
						return newMin;
					}, min);
			}, minRange);
			chart.yAxes.each((a) => (a as am4charts.ValueAxis).max = maxValue);
			chart.yAxes.each((a) => (a as am4charts.ValueAxis).min = minValue);
		}
	}, [props.data, chart, chart?.yAxes, props.multipleYAxis, props.dataInfo]);
	
	useEffect(() => {
		//cleanup
		return () => {
			if (chart && !chart.isDisposed()) {
				chart.dispose();
				setChart(null);
			}
		};
	}, [chart]);

/* 	//need this feature in order to show or hide yaxis, based on data passed in props
	useEffect(() => {
		if (props.multipleYAxis) {
			let tmpChart = am4core.registry.baseSprites.find(c => c.htmlContainer?.id === props.chartDivId) as am4charts.XYChart;
			if (tmpChart) {
				//enable only y axis used for active series
				let maxSupportedYaxis = 5;//should be defined in props        
				let yaxes = tmpChart.yAxes.values;
				for (let index = 0; index < maxSupportedYaxis; index++) {
					if (yaxes.length >= index) {
						let disabled = index > props.dataInfo.length - 1;
						yaxes[index].disabled = disabled;
					}
				}
				//rewrite label function to use current serie's unit of measure
				let series = tmpChart.series.values;
				props.dataInfo.forEach((m, i) => {
					let tooltip = series[i].tooltip;
					if (tooltip) {
						tooltip.label.adapter.remove("html");
						tooltip.label.adapter.add("html", function (text, target) { return createSeriesLabel(text, target, m) });
					}
				})
			}
		}
	}, [props.dataInfo]); */

	useEffect(() => {
		if (chart) {
			chart.xAxes.each(a => ChartHelper.setDateFormat(scaleType, a as am4charts.DateAxis));
		}
	}, [chart, scaleType]);

	//  https://www.amcharts.com/demos/highlighting-line-chart-series-on-legend-hover/

	return (
		<Card title={props.title}
			progressBar={"bottom"}
			installed={props.installed}
			disconnected={props.disconnected}
			showProgressBar={props.fetching}
			topRightContent={props.cardTopRightContent}
			comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(12, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(6, props.desktopCols)}
			contentClassName={`d-flex m-0${props.legend === ChartLegendPosition.top ? ' flex-column' : ''}`}
			style={props.hideCard ? { visibility: "hidden" } : undefined}>

			{props.legend === ChartLegendPosition.right ?

				<div className="chart-right-legend row flex-grow-1">

					<div ref={containerRef} className={"appChart"} />

					<div className={"legend-container m-auto d-flex flex-row flex-wrap"}>
						{props.image && <img style={{ maxWidth: 70, maxHeight: 70 }} src={props.image} alt="" />}
						{props.dataInfo.map((m, i) =>
							<div className="legend-block" key={"legend" + m.name + i}>
								<div style={{ cursor: "pointer" }} onClick={() => showHideElement(i)} onMouseOver={() => processOver(i)} onMouseOut={() => processOutOver()}>
									<div className="Square" style={{ background: m.color, marginRight: "10px", display: "inline-block" }} />
									<span>{m.name}</span>
								</div>

								<p className="principal-text font-weight-bolder">
									{m.lastValue} {m.unit}
								</p>

								<span>
									<LastUpdate lastUpdate={m.lastUpdate} />
								</span>

								{/* {i !== props.dataInfo.length - 1 && <div className="row separator d-none d-xl-block" />} */}
							</div>
						)}
					</div>
				</div>
				: (props.legend === ChartLegendPosition.top ?
					<>
						<div className="d-flex flex-row justify-content-between" style={{ flexWrap: "wrap", marginRight: "15px", marginLeft: "15px", marginBottom: "15px", alignItems: "center" }}>
							<div className="d-flex legendToggle-margin justify-content-center" style={{ marginLeft: "-15px" }}>
								{props.dataInfo.map((m, i) =>
									<div key={"legend" + m.name + i} className="d-flex flex-col" style={{ cursor: "pointer" }} onClick={() => showHideElement(i)} onMouseOver={() => processOver(i)} onMouseOut={() => processOutOver()}>
										<div className="Square" style={{ background: m.color, marginRight: "10px", marginLeft: "15px" }} />{m.name}
									</div>
								)}
							</div>
							<div className="d-flex legendToggle-margin">
								{props.showFilter &&
									<ABB.ToggleButtonGroup className={"ToggleButton-xs-inside"} sizeClass={"small"} selected={[scaleType as number]} >
										<ABB.ToggleButton className={"ToggleButton-xs"} text={t("1H")} onClick={v => multiLineChartTimeScale$.next(ChartTimeRange.Hour)} />
										<ABB.ToggleButton className={"ToggleButton-xs"} text={t("1D")} onClick={v => multiLineChartTimeScale$.next(ChartTimeRange.Day)} />
										<ABB.ToggleButton className={"ToggleButton-xs"} text={t("1W")} onClick={v => multiLineChartTimeScale$.next(ChartTimeRange.Week)} />
										<ABB.ToggleButton className={"ToggleButton-xs"} text={t("1M")} onClick={v => multiLineChartTimeScale$.next(ChartTimeRange.Month)} />
									</ABB.ToggleButtonGroup>}

							</div>
						</div>
						<div className="row flex-grow-1">
							<div ref={containerRef} className="appChart" />
						</div>
					</>
					:
					<div ref={containerRef} className="appChart" />)
			}
		</Card>
	);
}
