import { ApiDataItem, ChartDataItem, ChartScaleType, ChartDateRange } from "../models";
import { ChartTimeRange } from '../enums'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";

export class ChartHelper {

    private static add(index: number, item: ApiDataItem, map: Map<number, ChartDataItem>) {
        let val = map.get(item.timestamp_us);

        if (!val) {
            val = {
                date: item.timestamp_us / 1000,
                date_us: item.timestamp_us
            }
            map.set(item.timestamp_us, val);
        }

        switch (index) {
            case 0:
                val.value = item.value as number;
                return;
            case 1:
                val.value2 = item.value as number;
                return;
            case 2:
                val.value3 = item.value as number;
                return;
            case 3:
                val.value4 = item.value as number;
                return;
            default:
                throw new Error("invalid index: " + index);
        }
    }

    public static mergeMultiData(items: ApiDataItem[][]): ChartDataItem[] {

        let res = new Map<number, ChartDataItem>();

        items.forEach((i, index) => i.forEach(item => ChartHelper.add(index, item, res)));

        let data = [...res.values()].sort((a, b) => a.date - b.date);

        return data;
    }

    public static mergeData(items: ApiDataItem[], items2?: ApiDataItem[], items3?: ApiDataItem[], items4?: ApiDataItem[]): ChartDataItem[] {

        let res = new Map<number, ChartDataItem>();

        items.forEach(item => ChartHelper.add(0, item, res));

        if (items2) {
            items2.forEach(item => ChartHelper.add(1, item, res));

        }
        if (items3) {
            items3.forEach(item => ChartHelper.add(2, item, res));
        }

        if (items4) {
            items4.forEach(item => ChartHelper.add(3, item, res));
        }

        let data = [...res.values()].sort((a, b) => a.date - b.date);

        return data;
    }
//try to set a smart tolerated gap between displayed values in chart
//If time interval between two consecutive values is greater than toleratedGap, the two point will be not connected
public static getGApFromScaleType(scaleType: ChartScaleType, sampleNumber : number) : number
{
    let toleratedGap : number = 1000;
    let totalTimeInMinutes : number = 1;
    //considering base time interval expressed in minutes
    if(sampleNumber > 0)
    {
    switch (scaleType) {
        case ChartTimeRange.Hour:
            {
                //with 60 samples --> one value every minute
                totalTimeInMinutes = 60;
                toleratedGap = totalTimeInMinutes / sampleNumber;
                toleratedGap =Math.round( toleratedGap <= 1 ?3 : toleratedGap);
                break;
            }
        case ChartTimeRange.Day:
            {   
                //with 60 samples --> one value every 24 minutes        
                totalTimeInMinutes = 24 * 60;
                toleratedGap =  totalTimeInMinutes / sampleNumber;
                toleratedGap =Math.round(toleratedGap);
                break;
            }
        case ChartTimeRange.Week:
            {
                //with 60 samples --> one value every 2 hours and 48 minutes
                totalTimeInMinutes = 7 * 24 * 60;
                toleratedGap = totalTimeInMinutes / sampleNumber;
                toleratedGap =Math.round(toleratedGap);
                break;
            }
        case ChartTimeRange.Month:
            {
                //with 60 samples --> one value every 12 hours
                //handle real day in month
                totalTimeInMinutes = 30 * 24 * 60;
                toleratedGap = totalTimeInMinutes / sampleNumber;
                toleratedGap =Math.round(toleratedGap);
                break;
            }
        }
    }
    return (toleratedGap * 6);    
}








/*
    public static fromChartScaleTypeToTimeUnit(scaleType: ChartScaleType): am4core.TimeUnit | undefined
    {
        let timeUnit: am4core.TimeUnit = "minute";
        let num:number = 1;
        switch (scaleType) {
            case ChartTimeRange.Hour:
                {
                    timeUnit = "minute";
                    break;
                }
            case ChartTimeRange.Day:
                {
                    timeUnit = "hour";
                    break;
                }
            case ChartTimeRange.Week:
                {
                    timeUnit = "day";
                    break;
                }
            case ChartTimeRange.Month:
                {
                    timeUnit = "day"
                    break;
                }
            default:
                {
                    return undefined;
                }
            }
        return timeUnit;    
    }*/

    public static setDateFormat(scaleType: ChartScaleType, dateAxis: am4charts.DateAxis) {
        let formats: am4core.TimeUnit[] = ["millisecond", "second", "minute", "hour", "day", "week", "month", "year"];
        let format: string;

        switch (scaleType) {
            case ChartTimeRange.Hour:
                {
                    format = "hh:mm a";
                    break;
                }
            case ChartTimeRange.Day:
                {
                    format = "hh:mm a\n EEE dd";
                    break;
                }
            case ChartTimeRange.Week:
                {
                    format = "hh:mm a \n MM/dd";
                    break;
                }
            case ChartTimeRange.Month:
                {
                    format = " dd \n MMM";
                    break;
                }
            case undefined:
                {
                    format = "";
                    break;
                }
            default:
                {
                    if (scaleType as ChartDateRange) {

                        format = " dd \n MMM";
                    }
                    else {

                        format = " dd \n MMM";
                    }
                }
        }
        if (format) {
            formats.forEach(f => {
                dateAxis.dateFormats.setKey(f, format)
                dateAxis.periodChangeDateFormats.setKey(f, format)
            });
        }
    }
    
    public static containsPositiveAndNegativeValue(data : ChartDataItem[])
    {
        let positive : boolean = false;
        let negative : boolean = false;
       
        for (let index = 0; index < data.length && (!positive || !negative); index++) {
            // const element = data[index];
            if(!positive)
                positive = this.HasPositiveValue(data[index])
            if(!negative)
                negative = this.HasNegativeValue(data[index])

        }
       
        return positive === negative;

    }

    private static HasPositiveValue(data : ChartDataItem)
    {
        if(data.value?data.value>0:false)
            return true
        if(data.value2?data.value2>0:false)
            return true
        if(data.value3?data.value3>0:false)
            return true
        if(data.value4?data.value4>0:false)
            return true
        if(data.value5?data.value5>0:false)
            return true

        return false
    }

    private static HasNegativeValue(data : ChartDataItem)
    {
        if(data.value?data.value<0:false)
            return true
        if(data.value2?data.value2<0:false)
            return true
        if(data.value3?data.value3<0:false)
            return true
        if(data.value4?data.value4<0:false)
            return true
        if(data.value5?data.value5<0:false)
            return true

        return false
    }

}
