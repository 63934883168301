import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { Card, TrimmedText } from '../../components';
import { HealthIndexStatus } from '../../enums';
import { WidgetProps } from '../../models';
import { useTranslation } from 'react-i18next';
import { SlideControl } from '../../components/SlideControl/SlideControl';
import "./style.scss";
import { SingleLineChart } from '..';
import { HealthIndexStatusHelper } from '../../helpers';
import { SingleLineChartProps } from '../SingleLineChart/SingleLineChart';

export interface CumulatedWorkloadProps extends WidgetProps {
	numberOfOperations: number;
	avgInterruptedCurrent: number;
	value: number;
	status: HealthIndexStatus;
	description: string;
	additionalStats: { title: string, value: number }[];
	avgInterruptedCurrentUnit: string,
	warningMessage: string,
	okMessage: string,
	alarmMessage: string,
	singleLineChartProps: SingleLineChartProps
}

export function CumulatedWorkload(props: CumulatedWorkloadProps): ReactElement {
	const { t } = useTranslation();
	const icon = HealthIndexStatusHelper.getIcon(props.status);
	const color = HealthIndexStatusHelper.getColor(props.status);
	const [currentPage, setCurrentPage] = useState(0);
	const [showNotification, setShowNotification] = useState(true);
	const [currentMessage, setCurrentMessage] =	useState(props.okMessage ?? '');
	const onPageChange = useCallback((newPageIndex) => {
		setCurrentPage(newPageIndex);
	}, []);
	const hideNotification = useCallback(() => {
		setShowNotification(false);
	}, []);
	useEffect(() => {
		setShowNotification(true);
	}, [props.status]);
	useEffect(() => {
		if (props.status === HealthIndexStatus.Ok) {
			setCurrentMessage(props.okMessage);
		} else if (props.status === HealthIndexStatus.Warning) {
			setCurrentMessage(props.warningMessage);
		} else if (props.status === HealthIndexStatus.Alarm) {
			setCurrentMessage(props.alarmMessage);
		}
	}, [props.status, props.okMessage, props.alarmMessage, props.warningMessage]);
	return <Card
		title={props.title}
		topRightContent={() => SlideControl({
			onPageChange,
			totalPages: 2,
			currentPage
		})}
		className="card-hidden-content"
		installed={props.installed}
		disconnected={props.disconnected}
		mobileCols={props.mobileCols ?? 12}
		tabletCols={props.tabletCols ?? 6}
		desktopCols={props.desktopCols ?? 6}>
		<div className={`slider-wrapper ${currentPage === 0 ? "page-left" : "page-right"}`}>
			<div className="row" style={{ width: "100%" }}>
				<div className="col-6 d-flex flex-column justify-content-between">
					<div className="d-flex justify-content-between">
						<div style={{ height: "100%" }} className="d-flex justify-content-center flex-column">
							<div className="cumulated-workload-label">
								{t("No. of operations")}
							</div>
							<div className="font-weight-bold">
								{props.numberOfOperations}
							</div>
							<div className="mt-2 cumulated-workload-label">
								{t("Avg. Interrupted current")}
							</div>
							<div className="font-weight-bold">
								{props.avgInterruptedCurrent} {props.avgInterruptedCurrentUnit}
							</div>
						</div>
						<div className="d-flex flex-column justify-items-center align-items-center">
							<div>
								<SingleLineChart { ...props.singleLineChartProps } />
							</div>
							<div className="font-weight-bold mt-2 d-flex align-items-center" style={{ fontSize: "1.3rem" }}>
								<div className="mr-2">{props.value}</div>
								<ABB.Icon name={icon} color={color} sizeClass="medium" />
							</div>
						</div>
					</div>
					{showNotification && <div className="mt-2 cumulated-workload-alert d-flex justify-content-between align-items-center">

						<ABB.Icon color={color} name={icon} sizeClass="large" />

						<TrimmedText tag="span" text={currentMessage} maxLength={40} />
						<button className="icon-button" onClick={() => hideNotification()}>
							<ABB.Icon className="align-middle" sizeClass="medium" name="abb/close" />
						</button>
					</div>}
				</div>
				<div className="col-6">
					<div className="mt-2 mb-3 text-muted">{props.description}</div>
					<div className="row">
						{props.additionalStats.map((stat, i) => <div key={i} className="col-6"><div className="cumulated-workload-label">{stat.title}</div><div>{stat.value}</div></div>)}
					</div>
				</div>
			</div>
		</div>
	</Card>
}
