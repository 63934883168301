import React, { FunctionComponent, ReactElement } from 'react'
import { BootstrapHelper } from "../../helpers"
import './style.scss'
import * as ABB from "@abb/abb-common-ux-react";
import { LastUpdate } from '../LastUpdate/LastUpdate';
import { LastUpdateType } from '../../models';
import { useTranslation } from 'react-i18next';


export interface CardProps {
    id?: string;
    className?: string;
    style?: React.CSSProperties;
    hideTitle?: boolean;
    hideTitleStraightBar?: boolean;
    comment?: string | {lastUpdate?: Date|null, lastUpdateType?: LastUpdateType};
		topRightContent?: () => ReactElement;
		topCenterContent?: () => ReactElement;
    contentClassName?: string;

    mobileCols: number;
    tabletCols: number;
    desktopCols: number;
    title?: string;
    progressBar?: "top" | "bottom";
    showProgressBar?: boolean;
		installed?: boolean;
		disconnected?: { role: string, position?: string }[],
}

export const Card: FunctionComponent<CardProps> = (props) => {
	const TopRightContent = props.topRightContent;
	const TopCenterContent = props.topCenterContent;
	const { t } = useTranslation();
    return (
        <div id={props.id} className={"card-wrapper" + BootstrapHelper.getClassName(props.mobileCols, props.tabletCols, props.desktopCols)}>
            {props.progressBar === "top" &&
                <div style={{ height: "5px", position: 'absolute', right: '5px', left: '5px', top: 0, zIndex: 1 }}>
                    {props.showProgressBar && <ABB.LoadingIndicator color="blue" sizeClass="small" determinate={false} />}
                </div>
            }
            <div className={"card " + (props.className ? props.className : '') + (props.installed === false ? ' widget-uninstalled' : '')} style={props.style} >
                {!props.hideTitle && (
                    <div className={"d-flex flew-row justify-content-between" + (!props.hideTitleStraightBar ? " card-title" : "")}>
                        <div className='d-flex flex-wrap'>
													<h4 className="text-uppercase mr-2">{props.title}</h4>
													{props.installed === false && <ABB.WithTooltip>
														<ABB.Icon name='abb/wrench' className='pb-2 mr-1'/>
														<ABB.Tooltip>{t("NoSensorsFound")} 
														<br/>{t("PleaseInstallThem")}</ABB.Tooltip>
														</ABB.WithTooltip>}
													{props.disconnected && props.disconnected.length > 0 && <ABB.WithTooltip>
														<ABB.Icon name='abb/drives-error' className='pb-2 mr-1' color='color-status-red' />
														<ABB.Tooltip>
															{t("TheseSensorsAreDisconnected")}:
															<br/>
															{props.disconnected.map((sensor, i) => <span key={i}>
																{sensor.role}
																<br/>
															</span>)}
														</ABB.Tooltip>
													</ABB.WithTooltip>}
													{props.disconnected && props.disconnected.length === 0 && <ABB.WithTooltip>
														<ABB.Icon name='abb/drive-connected' className='pb-2 mr-1' color="color-status-green" />
														<ABB.Tooltip>{t("AllSensorsConnected")}</ABB.Tooltip>
													</ABB.WithTooltip>}
													{TopCenterContent && <TopCenterContent />}
												</div>
												<div className='flex-shrink-0'>
													{TopRightContent && <TopRightContent />}
													{props.comment && <span>{typeof props.comment === "string" ? props.comment : <LastUpdate lastUpdate={props.comment.lastUpdate} lastUpdateType={props.comment.lastUpdateType}/>}</span>}
												</div>
                    </div>
                )}
                <div className="card-content-wrapper d-flex flex-grow-1">
                    <div className={"card-content " + (props.contentClassName ? props.contentClassName : '')}>
                        {props.children}
                    </div>
                </div>
            </div>
            {props.progressBar === "bottom" &&
                <div style={{ height: "5px", position: 'absolute', right: '5px', left: '5px', bottom: 0, zIndex: 1 }}>
                    {props.showProgressBar && <ABB.LoadingIndicator color="blue" sizeClass="small" determinate={false} />}
                </div>
            }
        </div>
    )
}
