import * as ABB from '@abb/abb-common-ux-react';
import { getLastGenericSample } from '.';
import { DateTimeFormatHelper } from '../../helpers';
import { GaugeItemData, LastUpdateType, WidgetProps } from '../../models';
import { NamedSource, PathSource, WidgetConfig } from '../../pages/DynamicPage';
import { GaugeInfoProps, GaugesProps } from '../../widgets';
import { areInstalled, findDisconnected } from '../sensor';

export async function loadGauge<T extends ABB.GaugeProps>(methodName: string, parentProp: WidgetProps, prop: T, api: GaugeItemData) {
	if (!api)
		throw new Error(`${methodName} | api gauge date required`);

	if (!api.value)
		throw new Error(`${methodName} | api data: value not found`);

	let valueSample = await getLastGenericSample('number', api.value, {
		max: prop.max,
		min: prop.min
	});
	if (valueSample) {
		prop.value = valueSample.value;
		parentProp.lastUpdate = DateTimeFormatHelper.toDate(valueSample.timestamp_us);
		parentProp.lastUpdateType = LastUpdateType.Sample;
	}
}

export async function loadGaugeInfoData(config: WidgetConfig, props: Partial<GaugeInfoProps>): Promise<GaugeInfoProps> {
	console.info("loadGaugeInfoData");
	const gaugeInfo = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as GaugeInfoPropsConfig;
		const gaugeProps = { ...props.gauge! };
		await loadGauge("loadGaugeInfoData", gaugeInfo, gaugeProps, {
			value: propsConfig.value.source,
		});
		gaugeInfo.gauge = gaugeProps;
		gaugeInfo.fetching = false;
	} catch (e) {
		console.error("loadGaugeInfoData", e);
		gaugeInfo.exception = e;
	}
	return gaugeInfo as GaugeInfoProps;
}

export async function loadGaugesData(config: WidgetConfig, props: Partial<GaugesProps>): Promise<GaugesProps> {
	console.info("loadGaugesData");
	const gauges = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as GaugesPropsConfig;
		const gaugeConfigs = propsConfig.data.map((gaugeConfig) => ({
			value: gaugeConfig.value.source,
		})); 
		if (propsConfig.installedDevices) {
			gauges.installed = areInstalled(propsConfig.installedDevices);
			if (gauges.installed) {
				gauges.disconnected = findDisconnected(propsConfig.installedDevices);
			} else {
				gauges.disconnected = undefined;
			}
		}
		await Promise.all(
			gauges.gauges!.map((p, i) => loadGauge("loadGaugesData", gauges, p, gaugeConfigs[i]))
		);
		gauges.fetching = false;
	} catch (e) {
		console.error("loadGaugesData", e);
		gauges.exception = e;
	} 
	return gauges as GaugesProps;
}

export interface GaugeItemPropsConfig {
	lowWarn?: {
		source: PathSource
	},
	lowAlarm?: {
		source: PathSource
	},
	max?: {
		source: PathSource
	},
	min?: {
		source: PathSource
	},
	highWarn?: {
		source: PathSource
	},
	highAlarm?: {
		source: PathSource
	},
	value: {
		source: NamedSource
	},
	unit?: {
		value: string,
	}
	label?: {
		i18n: string,
	},
	showAsPercentage?: {
		value: boolean,
	}
}

export interface GaugeInfoPropsConfig {
	title: {
		i18n: string
	},
	description?: {
		i18n: string
	},
	info?: {
		i18n: string
	},
	value: {
		source: NamedSource
	},
	lowWarn: {
		source: PathSource
	},
	lowAlarm: {
		source: PathSource
	},
	max: {
		source: PathSource
	},
	min: {
		source: PathSource
	},
	discoverMoreLink?: {
		value: string
	},
	size?: {
		value: 's'|'m'|'l',
	},
	orientation?: {
		value: 'row'|'column'
	},
	showAsPercentage?: {
		value: boolean,
	}
}

export interface GaugesPropsConfig {
	
	data: GaugesItemPropsConfig[],
	title: {
		i18n: string,
	},
	installedDevices?: { role: string, position: string }[],
}

export interface GaugesItemPropsConfig {
	value: {
		source: NamedSource,
	},
	min: {
		source: PathSource,
	},
	max: {
		source: PathSource,
	},
	lowWarn?: {
		source: PathSource,
	},
	lowAlarm?: {
		source: PathSource,
	},
	highWarn?: {
		source: PathSource,
	},
	highAlarm?: {
		source: PathSource,
	},
	label: {
		i18n: string,
	}
}
