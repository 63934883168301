import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ABB from '@abb/abb-common-ux-react';
import { useReadable } from '../../observables/hooks';
import { values$ } from '../../services/polling';
import { getAssetIdentificationData } from '../../services/startup-and-commissioning';

export interface AssetIdentificationData {
	substation: string,
	switchgear: string,
	bay: string,
	load: string,
	customLabel: string,
}
interface AssetIdentificationProps {
	onLock: () => unknown,
	onUnlock: () => unknown,
	setData: (data: AssetIdentificationData|null) => unknown,
}

export function AssetIdentification({ setData }: AssetIdentificationProps): ReactElement {
	const { t } = useTranslation();
	const values = useReadable(values$);
	const [substation, setSubstation] = useState('');
	const [switchgear, setSwitchgear] = useState('');
	const [bay, setBay] = useState('');
	const [load, setLoad] = useState('');
	const [customLabel, setCustomLabel] = useState('');
	const data = useMemo(() => {
		return getAssetIdentificationData(values.values);
	}, [values.values]);

	useEffect(() => {
		setData({
			bay,
			customLabel,
			load,
			substation,
			switchgear
		});
	}, [substation, switchgear, bay, load, customLabel, setData]);

	return <>
		<p>{t("AssetIdentificationInfo")}</p>
		<div className='row mt-4'>
			<div className='asset-identification-list-item asset-identification-list-item-bold col-sm-6 col-12'>
				<span>{t('SerialNumber')}</span>
				<span>{data.serialNumber}</span>
			</div>
			<div className='asset-identification-list-item asset-identification-list-item-bold col-sm-6 col-12'>
				<span>{t('OrderNumber')}</span>
				<span>{data.orderNumber}</span>
			</div>
		</div>
		<div className='row mt-3'>
			<div className='col-lg-5 col-md-6 col-12'>
				<ABB.Input
					dataType='text'
					value={substation}
					label={t("Substation")}
					onValueChange={(value) => setSubstation(value)}
					placeholder={t("AddSubstation")}
				/>
			</div>
			<div className='d-none d-lg-block col-lg-1' />
			<div className='col-lg-5 col-md-6 col-12 mt-3 mt-md-0'>
				<ABB.Input
					dataType='text'
					value={switchgear}
					label={t("Switchgear")}
					onValueChange={(value) => setSwitchgear(value)}
					placeholder={t("AddSwitchgear")}
				/>
			</div>
			<div className='d-none d-lg-block col-lg-1' />
		</div>
		<div className='row mt-3'>
			<div className='col-lg-5 col-md-6 col-12'>
				<ABB.Input
					dataType='text'
					value={bay}
					label={t("Bay")}
					onValueChange={(value) => setBay(value)}
					placeholder={t("AddBay")}
				/>
			</div>
			<div className='d-none d-lg-block col-lg-1' />
			<div className='col-lg-5 col-md-6 col-12 mt-3 mt-md-0'>
				<ABB.Input
					dataType='text'
					value={load}
					label={t("Load")}
					onValueChange={(value) => setLoad(value)}
					placeholder={t("AddLoad")}
				/>
			</div>
			<div className='d-none d-lg-block col-lg-1' />
		</div>
		<div className='row mt-3'>
			<div className='col-lg-5 col-md-6 col-12'>
				<ABB.Input
					dataType='text'
					value={customLabel}
					label={t("CustomLabel")}
					onValueChange={(value) => setCustomLabel(value)}
					placeholder={t("AddCustomLabel")}
				/>
			</div>
		</div>
	</>;
}
