import { ReactElement, ReactNode, useEffect } from "react";
import { WidgetProps } from "../../models";
import { useTranslation } from "react-i18next";
import "./style.scss";
import * as ABB from "@abb/abb-common-ux-react";
import { ConnectionHelper } from "../../helpers";
import { ConnectionStatus, Role } from "../../enums";
import { TrimmedText } from "../../components";
import {
	getSensorConnection,
	getSensorDescription,
	Sensor,
} from "../../services/sensor";
import {
	ContextMenu,
	ContextMenuVisibility,
} from "../../components/ContextMenu/ContextMenu";
import { useState } from "react";
import { useReadable } from "../../observables/hooks";
import { user$ } from "../../services/user";
import {
	calibrateSensor,
	configureSensor,
	installSensor,
	replaceSensor,
	trainSensor,
	uninstallSensor,
} from "../../services/sensor";
import {
	ConfigurationModalDefaultConfig,
	ConfigurationModalSelector,
} from "../../sensor-configuration-dialogs/ConfigurationDialogSelector";
import { eventsList$ } from "../../services/polling";
import { devicesMetadata$ } from "../../services/device";
import { fireErrorNotification } from '../../toasts';

export interface SettingsSensorProps extends WidgetProps {
	userRole: Role;
	sensor: Sensor;
	label: string;
	description: string;
	fireNotification: (data: any) => void;
	children?: ReactNode[] | ReactNode;
}

export function SettingsSensor({
	sensor,
	label,
	fireNotification,
	exception,
	description,
	children,
}: SettingsSensorProps): ReactElement {
	let { t } = useTranslation();
	const [connectionStatus, setConnectionStatus] = useState(
		sensor.installed ? sensor.connectionStatus : ConnectionStatus.Disconnected
	);
	const eventsList = useReadable(eventsList$);
	const devicesMetadata = useReadable(devicesMetadata$);

	const userRole = useReadable(user$)?.role ?? Role.None;
	const isAdmin = userRole === Role.ABB || userRole === Role.Admin;
	async function handleCalibrate() {
		try {
			await calibrateSensor(sensor.uid);
			fireNotification({
				type: "banner",
				severity: "success",
				text: `${label} ${t("SuccessfullyCalibrated").toLowerCase()}`,
				timeout: 3000,
			});
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		}
	}

	async function handleTrain() {
		try {
			await trainSensor(sensor.uid);
			fireNotification({
				type: "banner",
				severity: "success",
				text: `${label} ${t("SuccessfullyTrained").toLowerCase()}`,
				timeout: 3000,
			});
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		}
	}

	async function handleReplace() {
		try {
			await replaceSensor(sensor.uid);
			fireNotification({
				type: "banner",
				severity: "success",
				text: `${label} ${t("SuccessfullyReplaced").toLowerCase()}`,
				timeout: 3000,
			});
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		}
	}

	async function handleAdd() {
		try {
			await installSensor(sensor.uid);
			fireNotification({
				type: "banner",
				severity: "success",
				text: `${label} ${t("SuccessfullyAdded").toLowerCase()}`,
				timeout: 3000,
			});
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		}
	}

	async function handleRemove() {
		try {
			await uninstallSensor(sensor.uid);
			fireNotification({
				type: "banner",
				severity: "success",
				text: `${label} ${t("SuccessfullyRemoved").toLowerCase()}`,
				timeout: 3000,
			});
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		}
	}

	const [showConfigurationDialog, setShowConfigurationDialog] = useState(false);

	async function sendConfiguration(formData: Record<string, unknown>) {
		try {
			await configureSensor(sensor.uid, formData);
			setShowConfigurationDialog(false);
			fireNotification({
				type: "banner",
				severity: "success",
				text: `${label} ${t("SuccessfullyConfigured").toLowerCase()}`,
				timeout: 3000,
			});
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		}
	}

	async function handleConfigure() {
		setShowConfigurationDialog(true);
	}

	useEffect(() => {
		if (sensor.installed) {
			setConnectionStatus(
				getSensorConnection(devicesMetadata, sensor.id, sensor.model)
			);
		} else {
			setConnectionStatus(ConnectionStatus.Disconnected);
		}
	}, [eventsList, sensor.installed, sensor.model, sensor.id, devicesMetadata]);

	return (
		<div
			className={`settings-sensor d-flex flex-column justify-content-between p-2${
				!sensor.installed ? " add-box" : ""
			}`}
		>
			<div className="d-flex flex-row justify-content-between">
				<div
					className={`${
						sensor.installed &&
						(!sensor.calibratable || sensor.calibrated) &&
						(!sensor.trainable || sensor.trained) &&
						(!sensor.configurable || sensor.configured)
							? "icon icon-green icon-top icon-out"
							: ""
					}`}
				>
					<div className="settings-sensor-type">{description}</div>
					<div className="settings-sensor-name">
						{sensor.installed && (
							<ABB.Icon
								className="align-middle"
								sizeClass="medium"
								name={ConnectionHelper.getSensorIcon(connectionStatus, false)}
								color={ConnectionHelper.getColor(connectionStatus)}
							/>
						)}
						<TrimmedText
							className="align-middle d-inline-block"
							maxLength={20}
							tag="span"
							text={label}
						></TrimmedText>
					</div>
					{exception && (
						<div className="text-danger">
							<ABB.Icon name="abb/information-circle-2" sizeClass="small" />{" "}
							&nbsp;
							<TrimmedText
								maxLength={20}
								tag="span"
								text={exception.message}
							></TrimmedText>
						</div>
					)}
				</div>
				{isAdmin && (sensor.installed ? (
					<ContextMenu>
						{sensor.configurable && (
							<ContextMenuVisibility.Consumer>
								{({ setVisible }) => (
									<div className="mt-2">
										<ABB.Button
											className="w-100"
											text={t("Configure")}
											isLoading={sensor.local.configuring}
											disabled={
												!sensor.configurable ||
												sensor.local.busy ||
												!sensor.configuration ||
												Object.keys(sensor.configuration).length === 0
											}
											onClick={() => {
												handleConfigure();
												setVisible(false);
											}}
											type="normal"
											sizeClass="small"
										></ABB.Button>
									</div>
								)}
							</ContextMenuVisibility.Consumer>
						)}
						{sensor.calibratable && (
							<div className="mt-2">
								<ABB.Button
									className="w-100"
									text={t("Calibrate")}
									isLoading={sensor.local.calibrating}
									disabled={!sensor.calibratable || sensor.local.busy}
									onClick={() => handleCalibrate()}
									type="normal"
									sizeClass="small"
								></ABB.Button>
							</div>
						)}
						{sensor.trainable && (
							<div className="mt-2">
								<ABB.Button
									className="w-100"
									text={t("Train")}
									isLoading={sensor.local.training}
									disabled={!sensor.trainable || sensor.local.busy}
									onClick={() => handleTrain()}
									type="normal"
									sizeClass="small"
								></ABB.Button>
							</div>
						)}
						<div className="mt-2">
							<ABB.Button
								className="w-100"
								text={t("Remove")}
								isLoading={sensor.local.uninstalling}
								disabled={sensor.local.busy}
								onClick={() => handleRemove()}
								type="normal"
								sizeClass="small"
							></ABB.Button>
						</div>
					</ContextMenu>
				) : (
					<ContextMenu>
						<div className="mt-2">
							<ABB.Button
								className="w-100"
								text={t("Add")}
								isLoading={sensor.local.installing}
								disabled={sensor.local.busy}
								onClick={() => handleAdd()}
								type="normal"
								sizeClass="small"
							></ABB.Button>
						</div>
					</ContextMenu>
				))}
			</div>
			{children}
			<div className="settings-sensor-buttons">
				<div className="row">
					{(userRole === Role.Admin || userRole === Role.ABB) && (
						<>
							{!sensor.installed ? (
								<>
									<div className="col-6 mt-2">
										<ABB.Button
											text={t("Add")}
											isLoading={sensor.local.installing}
											disabled={sensor.local.busy}
											onClick={() => handleAdd()}
											type="primary-blue"
											sizeClass="small"
										></ABB.Button>
									</div>
								</>
							) : (
								<>
									{sensor.calibratable && (
										<div className="col-6 mt-2">
											<div
												className={`icon icon-${
													sensor.calibrated ? "green" : "red"
												}`}
											>
												<ABB.Button
													text={t("Calibrate")}
													isLoading={sensor.local.calibrating}
													disabled={!sensor.calibratable || sensor.local.busy}
													onClick={() => handleCalibrate()}
													type={sensor.calibrated ? "normal" : "primary-blue"}
													sizeClass="small"
												></ABB.Button>
											</div>
										</div>
									)}
									{sensor.configurable &&
										sensor.configuration &&
										Object.keys(sensor.configuration).length > 0 && (
											<div className="col-6 mt-2">
												<ABB.Button
													text={t("Configure")}
													isLoading={sensor.local.configuring}
													disabled={!sensor.configurable || sensor.local.busy}
													onClick={() => handleConfigure()}
													type="primary-blue"
													sizeClass="small"
												></ABB.Button>
											</div>
										)}
									{(!sensor.calibratable || sensor.calibrated) &&
										sensor.trainable &&
										!sensor.trained && (
											<div className="col-6 mt-2">
												<div
													className={`icon icon-${
														sensor.trained ? "green" : "red"
													}`}
												>
													<ABB.Button
														text={t("Train")}
														isLoading={sensor.local.training}
														disabled={!sensor.trainable || sensor.local.busy}
														onClick={() => handleTrain()}
														type={sensor.trained ? "normal" : "primary-blue"}
														sizeClass="small"
													></ABB.Button>
												</div>
											</div>
										)}
									{(!sensor.calibratable || sensor.calibrated) &&
										(!sensor.trainable || sensor.trained) &&
										sensor.replaceable && (
											<div className="col-6 mt-2">
												<div className="icon icon-green">
													<ABB.Button
														text={t("Replace")}
														isLoading={sensor.local.replacing}
														disabled={sensor.local.busy}
														onClick={() => handleReplace()}
														type="primary-blue"
														sizeClass="small"
													></ABB.Button>
												</div>
											</div>
										)}
								</>
							)}
						</>
					)}
				</div>
			</div>

			{sensor.installed && sensor.configurable && sensor.configurationData && (
				<>
					<ConfigurationModalSelector
						configuring={sensor.local.configuring}
						isOpen={showConfigurationDialog}
						title={
							getSensorDescription(sensor.model, sensor.roles) +
							" " +
							t("Configuration")
						}
						model={sensor.model}
						defaultConfig={
							sensor.configuration as unknown as
								| ConfigurationModalDefaultConfig
								| undefined
						}
						onClose={() => setShowConfigurationDialog(false)}
						onSubmit={(formData) => sendConfiguration(formData)}
					/>
				</>
			)}
		</div>
	);
}
