import React, { ReactElement, useCallback, useRef, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { isCommissioningPhase$, isStartupPhase$, startupWizardIsShown$ } from '../../App/layout-state';
import { ModalWithSteps, StepStatus, StepStatusIcon } from '../../components';
import { DocumentType } from '../../enums';
import { delay } from '../../helpers';
import { useReadable } from '../../observables/hooks';
import { apiService } from '../../services/api';
import { fireErrorNotification, fireNotification } from '../../toasts';
import { AdditionalSensors, AdditionalSensorsData } from './AdditionalSensors';
import { Documents, DocumentsData } from './Documents';
import { Firmware, FirmwareData } from './Firmware';
import { ProductInfo, ProductInfoData, ProductInfoEditButton, ProductInfoNameplate } from './ProductInfo';
import { RoutineTest } from './RoutineTest';
import './style.scss';
import { values$ } from '../../services/polling';

export function StartupWizard(): ReactElement {
	const { t } = useTranslation();
	const startupWizardIsShown = useReadable(startupWizardIsShown$);
	const [steps, setSteps] = useState([
		{ title: t('ProductInfo'), isUnlocked: true, topRightContent: () => ProductInfoEditButton({ onClick: (edit) => setEditProductInfo(edit) }) },
		{ title: t('AdditionalSensors'), isUnlocked: true },
		{ title: t('RoutineTest'), cardTitle: t('RoutineAcquisition'), isUnlocked: true },
		{ title: t('Documents'), isUnlocked: true },
		{ title: t('Firmware'), isUnlocked: true },
	]);
	const productInfo = useRef<ProductInfoData | null>(null);
	const firmware = useRef<FirmwareData | null>(null);
	const additionalSensors = useRef<AdditionalSensorsData | null>(null);
	const documents = useRef<DocumentsData | null>(null);
	const [showLoading, setShowLoading] = useState(false);
	const [productInfoStatus, setProductInfoStatus] = useState<StepStatus>('to-do');
	const [additionalSensorsStatus, setAdditionalSensorsStatus] = useState<StepStatus>('to-do');
	const [documentsStatus, setDocumentsStatus] = useState<StepStatus>('to-do');
	const [firmwareStatus, setFirmwareStatus] = useState<StepStatus>('to-do');
	const [showFirmwareRestartMessage, setShowFirmwareRestartMessage] = useState(false);
	const [editProductInfo, setEditProductInfo] = useState(false);

	const setProductInfo = useCallback((data: ProductInfoData | null) => {
		productInfo.current = data;
	}, [productInfo]);

	const setFirmware = useCallback((data: FirmwareData | null) => {
		firmware.current = data;
	}, [firmware]);

	const setAdditionalSensors = useCallback((data: AdditionalSensorsData | null) => {
		additionalSensors.current = data;
	}, [additionalSensors]);

	const setDocuments = useCallback((data: DocumentsData | null) => {
		documents.current = data;
	}, [documents]);

	const updateStep = useCallback((index, unlocked) => {
		if (steps[index].isUnlocked !== unlocked) {
			steps[index].isUnlocked = unlocked;
			setSteps([...steps]);
		}
	}, [steps]);

	const onProductInfoNext = useCallback(async () => {
		if (productInfo.current) {
			setProductInfoStatus('doing');
			await apiService.updateNameplate({
				orderNumber: productInfo.current.orderNumber,
				serialNumber: productInfo.current.serialNumber,
			});
			if (editProductInfo) {
				const toUpdate = Object.entries(productInfo.current).reduce((mappedObject, [key, value]) => {
					if (key !== 'serialNumber' && key !== 'orderNumber') {
						mappedObject[key as keyof ProductInfoNameplate] = value;
					}
					return mappedObject;
				}, {} as Partial<ProductInfoNameplate>);
				if (Object.keys(toUpdate).length > 0) {
					await apiService.updateABBValues(toUpdate);
				}
			}
			setProductInfoStatus('done');
		} else {
			setProductInfoStatus('skipped');
		}
	}, [productInfo, editProductInfo]);

	const onFirmwareNext = useCallback(async () => {
		if (firmware.current && firmware.current.files.length > 0) {
			setFirmwareStatus('doing');
			const currentFirmware = await apiService.getBoardFirmwareInfo();
			try {
				await apiService.uploadFirmware(firmware.current.files[0]);
			} catch (err) {
				setFirmwareStatus('error');
				throw err;
			}
			setFirmwareStatus('done');
			setShowFirmwareRestartMessage(true);
			let newRoot = currentFirmware.currentRoot;
			let max = 1000;
			do {
				newRoot = (await apiService.getBoardFirmwareInfo()).currentRoot;
				if (newRoot !== currentFirmware.currentRoot) {
					window.location.reload();
				} else {
					await delay(5);
					max -= 5;
				}
			} while (currentFirmware.currentRoot === newRoot && max > 0);
			if (max <= 0) {
				fireNotification({ type: "banner", severity: "warn", text: t("TimeoutExceeded") })
			} 
			setShowFirmwareRestartMessage(false);
		} else {
			setFirmwareStatus('skipped');
		}
	}, [firmware, t]);

	const onAdditionalSensorsNext = useCallback(async () => {
		if (additionalSensors.current) {
			setAdditionalSensorsStatus('doing');
			await delay(1);
			// TODO call API to save serial numbers
			console.debug(additionalSensors.current);
			setAdditionalSensorsStatus('done');
		} else {
			setAdditionalSensorsStatus('skipped');
		}
	}, [additionalSensors]);

	const onDocumentsNext = useCallback(async () => {
		if (documents.current && documents.current.files.length > 0) {
			setDocumentsStatus('doing');
			try {
				await Promise.all(documents.current!.files.map(
					(document) => apiService.uploadDocument(document, DocumentType.ABB, document.name)
				));
			} catch (err) {
				setDocumentsStatus('error');
				throw err;
			}
			setDocumentsStatus('done');
		} else {
			setDocumentsStatus('skipped');
		}
	}, [documents]);

	const onFinish = useCallback(async () => {
		setShowLoading(true);
		try {
			await onProductInfoNext();
			await onAdditionalSensorsNext();
			await onDocumentsNext();
			await onFirmwareNext();
		} finally {
			setShowLoading(false);
		}
	}, [onDocumentsNext, onFirmwareNext, onProductInfoNext, onAdditionalSensorsNext]);

	const onSuccess = useCallback(async () => {
		fireNotification({
			severity: 'success',
			text: t("StartupSuccessful"),
			type: 'banner',
		});
		isStartupPhase$.next(false);
		startupWizardIsShown$.next(false);
		const commissioningDoneValue = values$.getValue().values?.nameplate?.commissioningDone;
		if (commissioningDoneValue !== undefined) {
			const commissioningDone = typeof commissioningDoneValue !== 'boolean' ?
				Boolean(Number(commissioningDoneValue)) : commissioningDoneValue;
			if (!commissioningDone) {
				isCommissioningPhase$.next(true);
			}
		}
	}, [t]);

	const onError = useCallback((err) => {
		fireErrorNotification({
			severity: 'alarm',
			text: t("AnErrorOccurred"),
			type: 'banner',
		}, err, t);
	}, [t]);

	return <>
		<ModalWithSteps dialog={{
			isOpen: startupWizardIsShown,
		}} title={t("StartupWizard")}
			steps={steps}
			onClose={() => {
				startupWizardIsShown$.next(false);
			}}
			onFinish={onFinish}
			onSuccess={onSuccess}
			onError={onError}
			nextGuard={() => Promise.resolve()}
		>
			<ProductInfo
				editMode={editProductInfo}
				setData={setProductInfo}
				onLock={() => updateStep(0, false)}
				onUnlock={() => updateStep(0, true)} />
			<AdditionalSensors
				setData={setAdditionalSensors}
				onLock={() => updateStep(1, false)}
				onUnlock={() => updateStep(1, true)} />
			<RoutineTest />
			<Documents setData={setDocuments} />
			<Firmware
				setData={setFirmware}
				onLock={() => updateStep(4, false)}
				onUnlock={() => updateStep(4, true)} />
		</ModalWithSteps>

		<ABB.Dialog
			isOpen={showLoading}
			dimBackground={true}
			title={t('UploadStartupData')}
		>
			<div className='d-flex justify-content-between align-items center'>
				<div>{t('ProductInfo')}</div>
				<StepStatusIcon status={productInfoStatus} />
			</div>
			<div className='d-flex justify-content-between align-items center'>
				<div>{t('AdditionalSensors')}</div>
				<StepStatusIcon status={additionalSensorsStatus} />
			</div>
			<div className='d-flex justify-content-between align-items center'>
				<div>{t('Documents')}</div>
				<StepStatusIcon status={documentsStatus} />
			</div>
			<div className='d-flex justify-content-between align-items center'>
				<div>{t('Firmware')}</div>
				<StepStatusIcon status={firmwareStatus} />
			</div>
			{showFirmwareRestartMessage && <>

				<div>{t('UpdateFirmwareCompleted')}.</div>
				<div>{t('UpdateFirmwareWaitForRestart')}.</div>

				<ABB.LoadingIndicator
					className="my-3"
					type="bar"
					determinate={false}
					color="red"
					sizeClass="medium"
				/>
			</>}
		</ABB.Dialog>
	</>
}
