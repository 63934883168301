import React, { ReactElement } from "react";
import { HealthIndexStatus } from "../../enums";
import * as ABB from "@abb/abb-common-ux-react";
import { Card, InfoPopup } from "../../components";
import { HealthIndexStatusHelper, BootstrapHelper } from "../../helpers";
import { WidgetProps } from "../../models"
import './style.scss';

export interface SectionStatusProps extends WidgetProps {
	status: HealthIndexStatus;
	subtitle: string; //black description next to InfoPopup
	description: string; //description grey
	info?: string; //tooltipText
}

export function SectionStatus(props: SectionStatusProps): ReactElement {
	let icon = HealthIndexStatusHelper.getIcon(props.status);
	let color = HealthIndexStatusHelper.getColor(props.status);

	return (
		<Card title={props.title} comment={!props.disabled ? { lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType } : ""}
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(4, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}
			installed={props.installed}
			disconnected={props.disconnected}
			className={"section-card text-center section-border " + getCorrespondingClass(props.status) + BootstrapHelper.getElementByStatus(props.disabled)}
			contentClassName="d-flex flex-row flex-wrap justify-content-center section-status">
			<div className="status-icon m-auto" >
				<ABB.Icon name={icon} color={color} sizeClass={"large"} />
			</div>
			<div className="status-description-container my-auto text-left">
				<div>
					<span> <InfoPopup info={props.info} style={{ margin: "0px" }} />{props.subtitle}</span>
				</div>
				<span className="staticDescription" >{props.description}</span>

			</div>
		</Card>
	);
}


function getCorrespondingClass(status: HealthIndexStatus): string {

	let result: string = "";

	if (status === HealthIndexStatus.Ok)
		result = " border-ok";
	if (status === HealthIndexStatus.Warning)
		result = " border-warning";
	if (status === HealthIndexStatus.Alarm)
		result = " border-alert";

	return result;
}
