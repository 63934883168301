import i18next from 'i18next';
import { changePasswordDialogIsShown$, sessionAboutToExpire$ } from '../App/layout-state';
import { LocalizationHelper } from '../helpers';
import { fireErrorNotification } from '../toasts';
import { FetchError } from '../utils/fetch';
import { makePollingWritable, PollingWritable } from '../utils/polling-subject';
import { apiService } from './api';
import { boardDate$, loadPrivateConfig, startPolling$ } from './config';
import { isDemo } from './demo';
import { eventsList$, loadPollers$, motorPollers$ } from './polling';
import { getPollerDescriptors, resetData } from './polling/startup';
import { token$, user$ } from './user';

export enum LoginResult {
	LoggedIn,
	InvalidCredentials,
	TooManyRequests,
};

const userStorageKey = 'user';

export function removeCachedUser() {
	localStorage.removeItem(userStorageKey);
}

export function init() {
	try {
		const userToParse = localStorage.getItem(userStorageKey);
		if (userToParse) {
			user$.next(JSON.parse(userToParse));
		}
	} catch (err) {
		console.error(err);
		removeCachedUser();
	}

	user$.subscribe((user) => {
		if (user) {
			postLogin();
			if (user.forceChangePassword) {
				changePasswordDialogIsShown$.next(true);
			} else {
				changePasswordDialogIsShown$.next(false);
			}
		} else {
			// cleanup
		}
	});
	
	document.addEventListener("visibilitychange", () => onDocumentVisibilityChange());
}

let pausedPollers: PollingWritable<unknown>[] = [];

const sessionPoller$ = makePollingWritable<number|null>(null, {
	dataProvider: async () => {
		try {
			const validity = Number(await apiService.getSessionValidity());
			if (validity <= 0) {
				throw new Error('session expired');
			}
			if (validity < 2 * 60 * 1000 * 1000) { // 2 minutes, validity is in microseconds 
				sessionAboutToExpire$.next(true);
			} else {
				sessionAboutToExpire$.next(false);
			}
			return validity;
		} catch (err) {
			fireErrorNotification({
				severity: 'alarm',
				text: LocalizationHelper.getTranslation('SessionExpired'),
				type: 'banner',
			}, err, i18next.t)
			sessionAboutToExpire$.next(false); // session is already expired
			logout();
			return null;
		}
	},
	auto: false,
	interval: 60000,
});

export async function login(username: string, password: string, rememberMe: boolean): Promise<LoginResult> {
	try {
		const { role, token, forceChangePassword } = await apiService.login(username, password);
		const newUserValue = {
			role,
			token,
			user: username,
			forceChangePassword,
		};
		if (rememberMe) {
			localStorage.setItem(userStorageKey, JSON.stringify(newUserValue));
		}
		user$.next(newUserValue);
		return LoginResult.LoggedIn;
	} catch (err) {
		if (err instanceof FetchError && err.status === 429) {
			return LoginResult.TooManyRequests;
		} else {
			return LoginResult.InvalidCredentials;
		}
	}
}

async function postLogin(): Promise<void> {
	const user = user$.getValue();
	if (!user) {
		throw new Error('unable to run postLogin: user not present');
	}
	token$.next(user.token);
	sessionPoller$.startPolling();
	try {
		await loadPrivateConfig();
	} catch (err) {
		alert(err);
		console.error(err);
	}
}

export function logout() {
	stopPollers();
	startPolling$.next(false);
	boardDate$.stopPolling();
	eventsList$.stopPolling();
	loadPollers$.stopPolling();
	motorPollers$.stopPolling();
	sessionPoller$.stopPolling();
	if (isDemo) {
		boardDate$.next(null);
		eventsList$.next({ events: [] });
		loadPollers$.next([]);
		motorPollers$.next({});
		sessionPoller$.next(null);
	}
	removeCachedUser();
	user$.next(null);
	token$.next(null);
	// if user has been logged out when the session was about to expire, but not by the session
	// poller (because a different http request was made when the session had already expired and
	// before the session poller could check it), we want to make sure that the session about to
	// expire value gets reset anyway
	sessionAboutToExpire$.next(false);
	//window.location.reload();
}

function stopPollers(reset = true) {
	const pollersToStop = getPollerDescriptors()
		.filter((props$) => props$.polling$.getValue());
	pollersToStop.forEach((poller$) => poller$.stopPolling());
	if (isDemo && reset) {
		resetData();
	}
	return pollersToStop;
}

function onDocumentVisibilityChange() {
	if (document.visibilityState === 'visible') {
		pausedPollers.forEach((poller$) => poller$.startPolling());
		pausedPollers = [];
	} else if (document.visibilityState === 'hidden') {
		pausedPollers = stopPollers(false);
	}
}
