import React, { ReactElement } from "react";
import { HealthIndexStatus } from "../../enums";
import * as ABB from "@abb/abb-common-ux-react";
import { HealthIndexStatusHelper } from "../../helpers";
import { LastUpdate, TrimmedText } from "../../components";
import "./style.scss";
import { PopupData } from "../../App/AppNotificationsTab";
import { useTranslation } from "react-i18next";

interface NotificationItemProps {
	status: HealthIndexStatus;
	title: string;
	message?: string;
	date: Date | null;
	setPopup?: (popupData: PopupData) => void;
	popupText: string[];
}

export function NotificationItem(props: NotificationItemProps): ReactElement {
	let icon = HealthIndexStatusHelper.getIcon(props.status);
	let color = HealthIndexStatusHelper.getColor(props.status);

	const { t } = useTranslation();

	return (
		<div className="notification d-flex flex-column">
			<div className="notification-header d-flex justify-content-between">
				<div className="d-flex">
					<div className="notification-icon">
						<ABB.Icon
							className="m-auto"
							sizeClass="small"
							name={icon}
							color={color}
						/>
					</div>
					<TrimmedText
						className="font-weight-bold text-wrap"
						text={props.title}
						tag="span"
						maxLength={30}
					/>
				</div>

				<span className="notification-time">
					<LastUpdate lastUpdate={props.date} />
				</span>
			</div>
			<div className="notification-message d-flex">
				<p className="my-auto">{props.message}</p>
			</div>
			<div className="notification-actions text-right">
				{props.setPopup && (
					<ABB.Button
						text={t("More")}
						sizeClass="small"
						type="ghost"
						onClick={() =>
							props.setPopup?.({
								title: props.title,
								description: props.popupText,
								subtitle: props.message ?? "",
							})
						}
					></ABB.Button>
				)}
			</div>
		</div>
	);
}
