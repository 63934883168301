import React, { ReactElement, useEffect, useRef } from 'react'
import { Card } from '../../components'
import { WidgetProps } from "../../models"
import { BootstrapHelper } from '../../helpers'

export class TableColumn {
	dataDisplayField: string;
	text: string;
	sortable: boolean;
	dataSortField?: string;
	className?: string;

	constructor(dataDisplayField: string, text: string, sortable: boolean, className?: string, dataSortField?: string) {
		this.dataDisplayField = dataDisplayField;
		this.text = text;
		this.sortable = sortable;
		this.className = className;
		this.dataSortField = dataSortField;
	}
}

export class TableSort {
	columnIndex: number;
	order: string;

	constructor(columnIndex: number, order: string) {
		this.columnIndex = columnIndex;
		this.order = order;
	}
}

export interface DataTablePropsConfig {
	title: { i18n: string },
	columns: {
		displayField: { value: string },
		text: { i18n: string },
		sortable: { value: boolean },
		className?: { value: string },
		sortField?: { value: string },
	}[],
	defaultSortIndex: {
		value: number,
	},
	defaultSortOrder: {
		value: 'asc' | 'desc',
	},
}
export interface DataTableProps extends WidgetProps {
	columns: TableColumn[];
	rows: any[];
	defaultSort: TableSort[];
}

export function DataTable(props: DataTableProps): ReactElement {
	const tableRef = useRef<HTMLTableElement | null>(null);


	useEffect(() => {

		if (props.rows.length > 0 && tableRef.current) {

			if (($.fn as any).dataTable.isDataTable(tableRef.current)) {
				($(tableRef.current) as any).DataTable().clear().rows.add(props.rows).draw();
			}
			else {

				let columnDefs = props.columns.map((c, i) => {
					return {
						targets: i,
						data: null, // Use the full data source object for the renderer's source
						className: c.className,
						render: {
							_: c.dataSortField ? c.dataSortField : c.dataDisplayField, //default
							display: c.dataDisplayField
						}
					}
				});
				let order = props.defaultSort.map(s => [s.columnIndex, s.order]);

				($(tableRef.current) as any).DataTable({
					data: props.rows,
					deferRender: true,
					columnDefs: columnDefs,
					order: order
				});
			}
		}

		//With ABB.Table it's necessary to pass option throung the call method
		// ($('#' + tableId) as any).DataTable(
		//     { 
		//         searching: false,
		//         displayLength:10,
		//         lengthChange: false
		//     }
		// );

		return () => {
		};
	}, [props.rows, props.columns, props.defaultSort]);

	useEffect(() => {
		const currentTableRef = tableRef.current;
		return () => {
			if (!currentTableRef) {
				return;
			}
			if (($.fn as any).dataTable.isDataTable(currentTableRef)) {
				($(currentTableRef) as any).DataTable().destroy();
			}
		};
	}, [tableRef]);



	return (
		<Card mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}
			installed={props.installed}
			disconnected={props.disconnected}
			title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }} >

			<div className="table-responsive">
				<table ref={tableRef} className="table table-sm" data-searching="false" data-length-change="false" data-display-length="10">
					<thead>
						<tr>
							{props.columns.map((column, i) =>
								<th key={"header" + i} data-sortable={column.sortable} scope="col">{column.text}</th>
							)}
						</tr>
					</thead>
				</table>
			</div>

			{/* <ABB.Table id={tableId} sizeClass="medium" solidBackground={true} borderType={"normal"} zebra={false}>
                    <thead>
                        <tr>
                            {props.columns.map((element, h) =>
                                <th key={"header" + h} data-field={element.dataField} data-sortable={element.sortable} scope="col">{element.text}</th>
                            )}                            
                        </tr>
                    </thead>
                    <tbody >
                        {props.rows.map((row, r) =>
                            <tr key={"row-" + r} >
                                {row.map((td, c) => <td key={"row-cell-" + r + "-" + c}>{td}</td>)}
                            </tr>
                        )}
                    </tbody>
                </ABB.Table> */}
		</Card>

	)
}
