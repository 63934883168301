import React from 'react';
import { WidgetType } from '../enums';
import { ApiIdentityItemType, SectionProps } from '../models';
import { WidgetSelector } from '../widgets';

export type NamedSource = {
	type: ApiIdentityItemType,
	role: string,
	name: string,
};

export type PathSource = {
	type: ApiIdentityItemType,
	path: string[],
}


export type ValueProp = {
	value: string | boolean | number | unknown,
};


export type SimplePropConfig = ValueProp | {
	i18n: string,
} | {
	source: NamedSource,
} | {
	source: PathSource,
};

export type PropsConfig = {
	[key in string]?: SimplePropConfig | SimplePropConfig[] | PropsConfig;
};

export interface WidgetConfig {
	component: WidgetType,
	pollingInterval?: number,
	grid: {
		desktop: number,
		tablet: number,
		mobile: number
	},
	propsConfig?: PropsConfig,
	tag?: string,
}

export interface DynamicPageProps extends SectionProps {
}

export function DynamicPage({ widgetConfigs: widgets }: DynamicPageProps) {
	return (
		<div className="row justify-content-center">
			{widgets.map((widgetConfig, i) => (
				<WidgetSelector widgetConfig={widgetConfig} key={i}></WidgetSelector>
			))}
		</div>
	);
}
