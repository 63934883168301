import { getWaveforms } from '.';
import { ChartLastOperation, ChartTimeRange } from '../../enums';
import { DateTimeFormatHelper } from '../../helpers';
import { WaveformOperation } from '../../models';
import { WidgetConfig } from '../../pages/DynamicPage';
import { ActuationEventsProps } from '../../widgets';
import { chartFilters$ } from './chart';
import { loadWaveformChartData, WaveformChartPropsConfig } from './chart/waveform';

export async function loadActuationEvents(config: WidgetConfig, props: Partial<ActuationEventsProps>): Promise<ActuationEventsProps> {
	const actuationEvents = { ...props };
	console.log("LoadActuationEvents");
	if (props.installed === false) {
		return actuationEvents as ActuationEventsProps;
	}
	try {
		const propsConfig = config.propsConfig as unknown as ActuationEventsPropsConfig;
		const eventType: 'open'|'close' = actuationEvents.eventType$!.getValue();
		const chartFilters = chartFilters$.getValue();
		const filter = chartFilters[actuationEvents.filterRef!];
		let startDate;
		let endDate;
		let size;
		if (typeof filter === 'object' && 'startDate' in filter) {
			startDate = filter.startDate;
			endDate = filter.endDate;
		} else {
			switch (filter) {
				case ChartTimeRange.Hour:
				case ChartTimeRange.Day:
				case ChartTimeRange.Month:
				case ChartTimeRange.Week: {
					startDate = DateTimeFormatHelper.getTimeIntervalBeginningFromNow(filter as ChartTimeRange);
					endDate = new Date();
					break;
				}
				case ChartLastOperation.ALL:
				case ChartLastOperation.MIN:
				case ChartLastOperation.MID: {
					size = filter;
					break;
				}
			}
		}
		const events = await getWaveforms(
			{ ...(propsConfig.charts[0].data?.[0] ?? propsConfig.charts[0])!.series!.source, operation: eventType === 'open' ? WaveformOperation.opening : WaveformOperation.closing },
			true,
			size,
			startDate,
			endDate,
		);
		actuationEvents.events = events.map((e) => ({ timestamp: e.timestamp_us, groupId: e.group_id! }));
		const selectedEvent = actuationEvents.selectedEvent$!.getValue();
		if (!selectedEvent || !actuationEvents.events.some((e) => e.groupId === selectedEvent?.groupId && e.timestamp === selectedEvent?.timestamp)) {
			if (actuationEvents.events.length) {
				actuationEvents.selectedEvent$!.next(actuationEvents.events[0]);
			} else {
				actuationEvents.selectedEvent$!.next(null);
			}
		}
		if (actuationEvents.selectedEvent$!.getValue()) {
			actuationEvents.charts = await Promise.all(propsConfig.charts.map((chart, i) => {
				let series;
				let data;
				if (chart.series) {
					series = JSON.parse(JSON.stringify(chart.series));
					series.source.operation = eventType === "open" ? WaveformOperation.opening :  WaveformOperation.closing;
				} else if (chart.data && chart.data.length) {
					data = JSON.parse(JSON.stringify(chart.data));
					data.forEach((d: typeof chart.data[0]) => {
						d.series.source.operation = eventType === "open" ? WaveformOperation.opening :  WaveformOperation.closing;
					});
				}
				return loadWaveformChartData(
					{ propsConfig: { ...chart,
						data,
						series,
						showRange: { value: true },
						groupId: { value: actuationEvents.selectedEvent$!.getValue()?.groupId } }} as any, 
					actuationEvents.charts![i]
				); 
			}));
		} else {
			actuationEvents.charts = actuationEvents.charts!.map((c) => ({ ...c, data: [] }));
		}
	} catch (e) {
		console.error("loadActuationEvents", e);
		actuationEvents.exception = e;
	} finally {
		actuationEvents.fetching = false;
	}
	return actuationEvents as ActuationEventsProps;
}
export interface ActuationEventsPropsConfig {
	eventType: { 
		value: 'open'|'close',
	},
	charts: WaveformChartPropsConfig[],
	separators?: {
		i18n: string
	}[],
	filterRef: {
		value: string,
	},
	installedDevices?: { role: string, position: string }[],
}
