import React, { FunctionComponent, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import './style.scss';
import { fireErrorNotification } from '../../toasts';
import { useTranslation } from 'react-i18next';

export interface HeaderAndAttributeProps {
	title: string;
	titleClass?: string;
	iconTitle?: string;
	iconAttribute?: string;
	attribute: string;
	attributeClass?: string;
	container?: boolean;
	containerClass?: string;
	onEdit?: (attribute: string) => Promise<void>;
}

function ContentEditor(props: HeaderAndAttributeProps & { onEdit: (attribute: string) => Promise<void> }) {
	const [value, setValue] = useState(props.attribute);
	const [isLoading, setIsLoading] = useState(false);
	return <div className='d-flex align-items-end my-2'>
		<ABB.Input
			dataType='text'
			value={value}
			disabled={isLoading}
			onValueChange={(newVal) => setValue(newVal)} />
		<ABB.IconButton isClickable={!isLoading} onClick={async () => {
			setIsLoading(true);
			await props.onEdit(value);
			setIsLoading(false);
		}} className="ml-1" sizeClass='large'>
			{isLoading ? <ABB.LoadingIndicator color='blue' determinate={false} type='radial' sizeClass='small' /> :
			<ABB.Icon name='abb/save' color="color-status-blue" />}
		</ABB.IconButton>
	</div>
}

export const HeaderAndAttribute: FunctionComponent<HeaderAndAttributeProps> = (props: HeaderAndAttributeProps) => {
	const Container: React.FC = props.container ? ({ children }) => <div className={props.containerClass}>{children}</div> : ({ children }) => <>{children}</>;
	const [isEditing, setIsEditing] = useState(false);
	const { t } = useTranslation();
	return <Container>
		<div className={(props.titleClass ? (" " + props.titleClass) : "")} style={{ color: "grey" }}>
			{props.title}
		</div>
		<div className={`d-flex align-items-center ${props.attributeClass ?? ''}`}>
			{(!isEditing) &&
				<div>
					{props.attribute || '-'}
				</div>}
			{props.onEdit && !isEditing && <ABB.IconButton className='ml-1' onClick={() => setIsEditing(true)} sizeClass="large">
				<ABB.Icon name='abb/edit' color='color-status-blue' />
			</ABB.IconButton>}
			{props.onEdit && isEditing && <ContentEditor {...props} onEdit={async (value) => {
				try {
					await props.onEdit!(value);
					setIsEditing(false);
				} catch (err) {
					fireErrorNotification({ severity: 'alarm', text: t('CannotUpdateAssetLabel'), type: 'banner' }, err, t);
				}
			}} />}
		</div>
	</Container>;
}
