import React, { ReactElement, useRef, useState } from "react";
import { SectionProps } from "../../models";
import { Card, HeaderAndAttribute, LoadingOverlay } from "../../components";
import * as ABB from "@abb/abb-common-ux-react";
import { useTranslation } from "react-i18next";
import {
	Placeholder,
	SettingsSensor,
	SettingsSensorProps,
	SettingsUsers,
	ThermalConfiguration,
} from "../../widgets";
import { Role, UserDataType } from "../../enums";
import "./style.scss";
import { FileUploader } from "../../components/";
import { SettingsNetworkLabels } from "./SettingsNetworkLabels";
import {
	getSensorDescription,
	getSensorName,
	InstalledSensor,
	sensors$,
} from "../../services/sensor";
import { useReadable } from "../../observables/hooks";
import { useEffect } from "react";
import { user$ } from "../../services/user";
import { apiService } from "../../services/api";
import { calibrateSensors, trainSensors } from "../../services/sensor";
import {
	boardFirmware$,
	boardNetwork$,
	isDevMode$,
} from "../../services/polling";
import { boardDevice$, devicesAndMetadata$ } from "../../services/device";
import { BoardFirmwareInfo } from "../../models/api/BoardFirmwareInfo";
import { delay } from "../../helpers";
import { isCommissioningPhase$, isStartupPhase$ } from "../../App/layout-state";
import { startupAndCommissioningEnabled$ } from "../../services/config";
import { fireErrorNotification } from '../../toasts';

interface SettingsProps extends SectionProps {}

export function Settings(props: SettingsProps): ReactElement {
	let { t } = useTranslation();
	const userRole = useReadable(user$)?.role ?? Role.None;
	let isAdmin = userRole === Role.Admin || userRole === Role.ABB;

	const boardInfo = useReadable(boardFirmware$);
	const boardNetwork = useReadable(boardNetwork$);
	const devicesRefreshed = useRef(false);
	const [networkConfig, setNetworkConfig] = useState(boardNetwork);

	//Tab handling
	const [activeTab, setActiveTab] = useState<number>(0);

	const boardDevice = useReadable(boardDevice$);
	//Wireless Sensors Channel
	const channelValue = boardDevice?.configuration.IEEE802154Channel;
	const [autoTune, setAutoTune] = useState<boolean>(channelValue === "-1");
	const [channel, setChannel] = useState<string | null>(
		channelValue === "-1" ? null : channelValue ?? null
	);

	//Restore databases
	const [showResetDialog, setShowResetDialog] = useState<boolean>(false);
	const [resetDevice, setResetDevice] = useState<boolean>(false);

	const startupAndCommissioningEnabled = useReadable(
		startupAndCommissioningEnabled$
	);

	async function factoryReset() {
		let type: UserDataType = UserDataType.Logs;

		if (resetDevice) {
			//Call api reset device
			type = UserDataType.DeviceData;
		} else {
			//Call api reset configuration and calibration
			type = UserDataType.Config;
		}

		setShowResetDialog(false);
		setLoadingOverlayText(t("Factory reset"));
		setShowLoadingOverlay(true);

		try {
			await apiService.wipeUserData(type);

			setShowLoadingOverlay(false);
			setShowFactoryResetCompleted(true);
		} catch (error) {
			console.error(error);
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: error.message }),
				timeout: 5000,
			}, error, t);
			setShowLoadingOverlay(false);
		}
	}

	//Upload database
	const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
	const [uploadType, setUploadType] = useState<
		"device" | "configuration" | "clone"
	>("device");
	const [uploadFileObject, setUploadFileObject] = useState<File | null>(null);

	function getUploadDialogTitle(): string {
		let res = "";

		switch (uploadType) {
			case "clone":
				res = t("ClonePackage");
				break;
			case "configuration":
				res = t("ConfigurationAndCalibrationDatabase");
				break;
			case "device":
				res = t("DeviceDatabase");
				break;
		}

		return `${t("Upload")} ${res.toLowerCase()}`;
	}

	const isDevMode = useReadable(isDevMode$);
	function HandleDownloadDatabaseFile(
		props: SettingsProps,
		userData?: UserDataType
	): Promise<Blob> {
		let IsdeveloperMode: boolean = isDevMode;
		if (userData) {
			return apiService.getUserData(IsdeveloperMode, userData);
		} else {
			return apiService.getUserData(IsdeveloperMode);
		}
	}

	function downloadDataBaseFile(props: SettingsProps, userData?: UserDataType) {
		setLoadingOverlayText(t("DownloadingDatabase"));
		setShowLoadingOverlay(true);

		let filename: string = "";
		switch (userData) {
			case UserDataType.Config:
				filename = "Configuration_Calibration_Database";
				break;
			case UserDataType.DeviceData:
				filename = "Device_Database";
				break;
			case UserDataType.AuditLogs:
				setLoadingOverlayText(t("DownloadAuditLogsFile"));
				filename = "Audit_Logs";
				break;
			default:
				filename = "Board_Clone_Package";
				break;
		}

		HandleDownloadDatabaseFile(props, userData)
			.then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = filename + ".tar.gz";
				a.click();
				setShowLoadingOverlay(false);
			})
			.catch((err) => {
				console.log(err);
				setShowLoadingOverlay(false);
				fireErrorNotification({
					type: "banner",
					severity: "alarm",
					text: t("Error", { message: err.message }),
					timeout: 3000,
				}, err, t);
			});
	}

	function getUploadDialogAccept(): string {
		return ".tar.gz, application/x-gzip";
		// let res = '';

		// switch (uploadType) {
		//     case "clone":
		//         res = ".zip, application/zip"
		//         break;
		//     case "configuration":
		//         res = ".sqlite, application/vnd.sqlite3, application/x-sqlite3"
		//         break;
		//     case "device":
		//         res = ".json, application/json"
		//         break;
		// }

		// return res;
	}

	function upload() {
		let dataType: undefined | UserDataType = undefined;
		let inputError: boolean = false;
		switch (uploadType) {
			case "configuration":
				dataType = UserDataType.Config;
				break;
			case "device":
				dataType = UserDataType.DeviceData;
				break;
			case "clone":
				dataType = undefined;
				break;
			default:
				inputError = true;
		}
		if (uploadFileObject && !inputError) {
			apiService
				.setUserData(uploadFileObject, dataType)
				.then((res) => {
					props.fireNotification({
						type: "banner",
						severity: res ? "success" : "alarm",
						text: t(res ? "success" : "alarm"),
						timeout: 2000,
					});
				})
				.catch((err) => {
					console.log(err);
					fireErrorNotification({
						type: "banner",
						severity: "alarm",
						text: t("Error", { message: err.message }),
						timeout: 5000,
					}, err, t);
				});
		} else {
			props.fireNotification({
				type: "banner",
				severity: "alarm",
				text: t("GenericError"),
				timeout: 5000,
			});
		}
	}

	//Loading Overlay
	const [showLoadingOverlay, setShowLoadingOverlay] = useState<boolean>(false);
	const [loadingOverlayText, setLoadingOverlayText] = useState<string>("");

	//Upload firmware
	const [showFirmwareDialog, setShowFirmwareDialog] = useState<boolean>(false);
	const [firmwareFile, setFirmwareFile] = useState<File>();
	const [showFirmwareUpdated, setShowFirmwareUpdated] =
		useState<boolean>(false);
	const [showFactoryResetCompleted, setShowFactoryResetCompleted] =
		useState<boolean>(false);

	function onFirmwareFileSelected(file: File) {
		setFirmwareFile(file);
		setShowFirmwareDialog(true);
	}

	async function checkIfRestarted(currentFirmware: BoardFirmwareInfo) {
		let newRoot = currentFirmware.currentRoot;
		let max = 1000;
		do {
			newRoot = (await apiService.getBoardFirmwareInfo()).currentRoot;
			if (newRoot !== currentFirmware.currentRoot) {
				window.location.reload();
			} else {
				await delay(5);
				max -= 5;
			}
		} while (currentFirmware.currentRoot === newRoot && max > 0);
		if (max <= 0) {
			setShowFirmwareUpdated(false);
			props.fireNotification({
				type: "banner",
				severity: "warn",
				text: t("TimeoutExceeded"),
			});
		}
	}

	async function uploadFirmware() {
		//Show loading
		setShowFirmwareDialog(false);
		setLoadingOverlayText(t("UpdateFirmware"));
		setShowLoadingOverlay(true);

		try {
			const currentFirmware = await apiService.getBoardFirmwareInfo();
			await apiService.uploadFirmware(firmwareFile!);

			setShowLoadingOverlay(false);
			setShowFirmwareUpdated(true);

			checkIfRestarted(currentFirmware);
		} catch (error) {
			console.error(error);
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: error.message }),
				timeout: 5000,
			}, error, t);
			setShowLoadingOverlay(false);
		}
	}

	const [savingNetwork, setSavingNetwork] = useState(false);
	const [savingWirelessChannel, setSavingWirelessChannel] = useState(false);
	//Saving
	async function saveNetwork() {
		setSavingNetwork(true);
		try {
			await apiService.saveNetworkConfig(networkConfig!);
			await boardNetwork$.fetch();
			props.fireNotification({
				type: "banner",
				severity: "success",
				text: t("NetworkSaved"),
				timeout: 3000,
			});
		} catch (err) {
			console.error(err);
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		}
		setSavingNetwork(false);
	}

	async function saveWirelessChannel() {
		setSavingWirelessChannel(true);
		try {
			if (autoTune) {
				await apiService.setWirelessChannel("-1");
			} else if (channel !== null) {
				await apiService.setWirelessChannel(channel);
			} else {
				props.fireNotification({
					type: "banner",
					severity: "warn",
					text: t("Please select a channel"),
					timeout: 5000,
				});
				return;
			}
			props.fireNotification({
				type: "banner",
				severity: "success",
				text: t("Wireless channel saved"),
				timeout: 3000,
			});
			await devicesAndMetadata$.fetch();
		} catch (err) {
			console.error(err);
			fireErrorNotification({
				type: "banner",
				severity: "alarm",
				text: t("Error", { message: err.message }),
				timeout: 5000,
			}, err, t);
		} finally {
			setSavingWirelessChannel(false);
		}
	}

	//Calibrate

	const [calibratingAll, setCalibratingAll] = useState<boolean>(false);
	const [calibrateAllEnabled, setCalibrateAllEnabled] =
		useState<boolean>(false);
	const [calibrateAllVisible, setCalibrateAllVisible] =
		useState<boolean>(false);
	const [trainingAll, setTrainingAll] = useState<boolean>(false);
	const [trainAllEnabled, setTrainAllEnabled] = useState<boolean>(false);
	const [trainAllVisible, setTrainAllVisible] = useState<boolean>(false);

	const sensors = useReadable(sensors$);

	async function calibrateAll() {
		try {
			const resultsWithError = (await calibrateSensors()).filter(
				(result) => result.err
			) as { uid: string; err: Error }[];
			if (resultsWithError.length === 0) {
				props.fireNotification({
					type: "banner",
					severity: "success",
					text: t("SensorsCalibrated"),
					timeout: 3000,
				});
			} else {
				props.fireNotification({
					type: "banner",
					severity: "warn",
					text: t("SensorsCalibratedWithExceptions", {
						errorNumber: resultsWithError.length,
					}),
					timeout: 3000,
				});
			}
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "warn",
				text: t("GenericError"),
				timeout: 3000,
			}, err, t);
		}
	}

	async function trainAll() {
		try {
			await trainSensors();
			props.fireNotification({
				type: "banner",
				severity: "success",
				text: t("SensorsTrained"),
				timeout: 3000,
			});
		} catch (err) {
			fireErrorNotification({
				type: "banner",
				severity: "warn",
				text: t("GenericError"),
				timeout: 3000,
			}, err, t);
		}
	}

	const [loadingSensors] = useState(false);
	const [sensorProps, setSensorsProps] = useState<SettingsSensorProps[]>([]);

	useEffect(() => {
		if (!devicesRefreshed.current) {
			devicesAndMetadata$
				.fetch()
				.catch((err) => console.error(err))
				.finally(() => devicesRefreshed.current = true);
		}
	}, []);

	useEffect(() => {
		setSensorsProps(
			sensors
				.filter((s) => s.exported)
				.sort((s1, s2) => {
					if (s1.installed && !s2.installed) {
						return -1;
					} else if (!s1.installed && s2.installed) {
						return 1;
					} else {
						const sensor1Name = getSensorName(s1.model, s1.roles);
						const sensor2Name = getSensorName(s2.model, s2.roles);
						if (sensor1Name < sensor2Name) {
							return -1;
						} else if (sensor1Name > sensor2Name) {
							return 1;
						}
						const sensor1Description = getSensorDescription(s1.model, s1.roles);
						const sensor2Description = getSensorDescription(s2.model, s2.roles);
						if (sensor1Description < sensor2Description) {
							return -1;
						} else if (sensor1Description > sensor2Description) {
							return 1;
						}
					}
					return 0;
				})
				.map<SettingsSensorProps>((sensor, i) => ({
					userRole,
					key: `sensor-props-${i}`,
					sensor,
					label: getSensorName(sensor.model, sensor.roles),
					fireNotification: props.fireNotification,
					exception: sensor.local.lastException || undefined,
					description: getSensorDescription(sensor.model, sensor.roles),
				}))
		);

		// Calibration
		const calibratableSensors = sensors.filter(
			(s) => s.installed && s.calibratable
		) as InstalledSensor[];
		setCalibratingAll(
			calibratableSensors.length > 0 &&
				calibratableSensors.every((s) => s.local.calibrating)
		);
		setCalibrateAllEnabled(calibratableSensors.some((s) => !s.local.busy));
		setCalibrateAllVisible(calibratableSensors.length > 0);

		// Training
		const trainableSensors = sensors.filter(
			(s) => s.installed && s.trainable
		) as InstalledSensor[];
		setTrainingAll(
			trainableSensors.length > 0 &&
				trainableSensors.every((s) => s.local.training)
		);
		setTrainAllEnabled(trainableSensors.some((s) => !s.local.busy));
		setTrainAllVisible(trainableSensors.length > 0);
	}, [sensors, props.fireNotification, userRole]);

	if (!boardInfo || !boardNetwork) {
		return (
			<Placeholder {...{ mobileCols: 12, desktopCols: 12, tabletCols: 12 }} />
		);
	}

	return (
		<>
			<LoadingOverlay label={loadingOverlayText} visible={showLoadingOverlay} />
			<div className="settings-container row">
				<div className="col-12 col-md-4 col-lg-3">
					<Card
						title={t("Firmware")}
						mobileCols={12}
						tabletCols={12}
						desktopCols={12}
					>
						<h6>{t("Current board firmware version")}</h6>
						<HeaderAndAttribute
							container={true}
							containerClass="my-2"
							title={t("ReleaseNumber")}
							attribute={boardInfo.version}
						/>
						<HeaderAndAttribute
							container={true}
							containerClass="my-2"
							title={t("BuildRevision")}
							attribute={boardInfo.build.revision}
						/>
						<HeaderAndAttribute
							container={true}
							containerClass="my-2"
							title={t("BuildHash")}
							attribute={boardInfo.build.hashShort}
						/>
						<HeaderAndAttribute
							container={true}
							containerClass="my-2"
							title={t("LinuxVersion")}
							attribute={boardInfo.linuxVersion}
						/>
						<HeaderAndAttribute
							container={true}
							containerClass="my-2"
							title={t("BuildDateAndTime")}
							attribute={boardInfo.build.date.toLocaleDateString()}
						/>

						{isAdmin && (
							<div className="firmware-update">
								<span>{t("UpdateBoardFirmwareFromPackage")}</span>
								<div className="upload-area">
									<FileUploader
										accept=".tar.gz, application/x-gzip"
										onFileSelected={(files) => onFirmwareFileSelected(files[0])}
									/>
								</div>
							</div>
						)}
					</Card>
					{startupAndCommissioningEnabled && (
						<Card
							title={t("Wizards")}
							mobileCols={12}
							tabletCols={12}
							desktopCols={12}
						>
							{(userRole === Role.Admin || userRole === Role.ABB) && (
								<ABB.Button
									type="primary-blue"
									sizeClass="small"
									icon="abb/connect"
									className="w-100 mb-2"
									text={t("StartStartupWizard")}
									onClick={() => isStartupPhase$.next(true)}
								/>
							)}
							<ABB.Button
								type="primary-blue"
								sizeClass="small"
								icon="abb/certificate-document-1"
								className="w-100"
								text={t("StartCommissioningWizard")}
								onClick={() => isCommissioningPhase$.next(true)}
							/>
						</Card>
					)}
				</div>
				<div className="col-12 col-md-8 col-lg-9">
					<Card
						hideTitle={true}
						style={{ padding: 0 }}
						mobileCols={12}
						tabletCols={12}
						desktopCols={12}
					>
						<ABB.TabControl
							className="settings-tab-control"
							type="secondary"
							activeTab={activeTab}
							onTabChange={(o, n) => setActiveTab(n)}
						>
							<ABB.TabItem title={t("Sensors")}>
								<div className="row">
									<Card
										title={t("Sensors")}
										mobileCols={12}
										tabletCols={12}
										desktopCols={12}
									>
										{loadingSensors ? (
											<ABB.Spinner color="blue" sizeClass="small" />
										) : sensorProps.length > 0 ? (
											<>
												{isAdmin && (
													<div className="all-buttons px-0 col-12 d-flex text-right justify-content-center justify-content-md-end">
														{calibrateAllVisible && (
															<div className="mr-2">
																<ABB.Button
																	text={t("CalibrateAll")}
																	sizeClass="small"
																	type="normal"
																	disabled={!calibrateAllEnabled}
																	isLoading={calibratingAll}
																	onClick={() => calibrateAll()}
																></ABB.Button>
															</div>
														)}
														{trainAllVisible && (
															<div>
																<ABB.Button
																	text={t("TrainAll")}
																	sizeClass="small"
																	type="normal"
																	disabled={!trainAllEnabled}
																	isLoading={trainingAll}
																	onClick={() => trainAll()}
																></ABB.Button>
															</div>
														)}
													</div>
												)}
												{isAdmin &&
												<div className="channel-box my-2 d-flex flex-wrap">
													<ABB.Checkbox
														label={t("Auto-tune")}
														value={autoTune}
														className="my-auto"
														sizeClass="small"
														onChange={(val) => setAutoTune(!autoTune)}
														allowIndeterminate={false}
													/>
													<ABB.Dropdown
														clearable={true}
														label={t("CommunicationChannel")}
														placeholder={t("SelectChannel")}
														className="my-auto ml-4"
														disabled={autoTune}
														required={true}
														value={channel === null ? [] : [{ value: channel }]}
														onChange={(current) => {
															if (current[0]?.value) {
																setChannel(current[0].value);
															} else {
																setChannel(null);
															}
														}}
													>
														{new Array(64).fill(0).map((_, ch) => (
															<ABB.DropdownOption
																key={"option-" + ch}
																label={ch.toString()}
																value={ch.toString()}
															/>
														))}
													</ABB.Dropdown>
													{isAdmin && (
														<ABB.Button
															text={t("Save")}
															icon="abb/save"
															isLoading={savingWirelessChannel}
															disabled={savingWirelessChannel}
															className="my-auto ml-4"
															type="primary-blue"
															sizeClass="small"
															onClick={() => saveWirelessChannel()}
														/>
													)}
												</div>}
												<div className="row">
													{sensorProps.map((p) => (
														<div
															className="col-12 col-xl-6 my-2 d-flex"
															key={
																p.sensor.model +
																"-" +
																p.sensor.id +
																"-" +
																p.sensor.installed
															}
														>
															<SettingsSensor {...p} />
														</div>
													))}
												</div>
											</>
										) : (
											<span>{t("NoSensorsAvailable")}</span>
										)}
									</Card>
								</div>
							</ABB.TabItem>
							<ABB.TabItem title={t("Network")}>
								<div className="row">
									{Object.entries(networkConfig?.interfaces || {}).map(
										([nic, config]) => (
											<SettingsNetworkLabels
												interfaceName={nic}
												interfaceConfig={config!}
												onChange={(m) => {
													if (networkConfig) {
														networkConfig.interfaces[nic] = m;
														setNetworkConfig({ ...networkConfig });
													}
												}}
												key={nic}
											/>
										)
									)}

									<div className="col-12 text-center">
										<ABB.Button
											text={t("Save")}
											icon="abb/save"
											type="primary-blue"
											sizeClass="small"
											isLoading={savingNetwork}
											disabled={savingNetwork || !isAdmin}
											onClick={() => saveNetwork()}
										/>
									</div>
								</div>
								<div className="row">
									<Card
										title={t("NetworkConnection")}
										mobileCols={12}
										tabletCols={12}
										desktopCols={12}
									>
										<img
											alt="settingsNetworkConnection"
											src="./images/settingsNetworkConnection.png"
											style={{ width: "100%" }}
										></img>
									</Card>
								</div>
							</ABB.TabItem>
							<ABB.TabItem title={t("Database")} disabled={!isAdmin}>
								<div className="row">
									<Card
										title={t("ConfigurationAndCalibrationDatabase")}
										contentClassName="database-content"
										mobileCols={12}
										tabletCols={12}
										desktopCols={12}
									>
										<p>{t("DownloadConfigurationDatabaseLabel")}</p>
										<ABB.Button
											type="primary-blue"
											text={t("DownloadDatabase")}
											sizeClass="small"
											style={{ width: "210px" }}
											icon="abb/download"
											download={true}
											onClick={() => {
												downloadDataBaseFile(props, UserDataType.Config);
											}}
											/*href={props.dataService.ApiService.getUserDataUrl(UserDataType.Config)} */
										/>
										{isAdmin && (
											<>
												<p>{t("UploadConfigurationDatabase")}</p>
												<ABB.Button
													type="primary-blue"
													text={t("UploadDatabase")}
													sizeClass="small"
													style={{ width: "210px" }}
													icon="abb/upload"
													onClick={() => {
														setUploadType("configuration");
														setShowUploadDialog(true);
													}}
												/>
												<p>
													<ABB.Icon
														className="align-middle"
														sizeClass="small"
														name="abb/warning-circle-2"
														color="red"
													/>{" "}
													<span className="align-middle">
														{t("FactoryResetConfigurationDatabase")}
													</span>
												</p>
												<ABB.Button
													type="normal"
													text={t("FactoryResetDatabase")}
													sizeClass="small"
													onClick={() => {
														setResetDevice(false);
														setShowResetDialog(true);
													}}
													style={{ width: "210px" }}
													icon="abb/turn-counter-clockwise"
												/>
											</>
										)}
									</Card>
									<Card
										title={t("DeviceDatabase")}
										contentClassName="database-content"
										mobileCols={12}
										tabletCols={12}
										desktopCols={12}
									>
										<p>{t("DownloadDeviceDatabaseLabel")}</p>
										<ABB.Button
											type="primary-blue"
											text={t("DownloadDatabase")}
											sizeClass="small"
											style={{ width: "210px" }}
											icon="abb/download"
											download={true}
											onClick={() => {
												downloadDataBaseFile(props, UserDataType.DeviceData);
											}}
										/>
										{isAdmin && (
											<>
												<p>{t("UploadDeviceDatabase")}</p>
												<ABB.Button
													type="primary-blue"
													text={t("UploadDatabase")}
													sizeClass="small"
													style={{ width: "210px" }}
													icon="abb/upload"
													onClick={() => {
														setUploadType("device");
														setShowUploadDialog(true);
													}}
												/>
												<p>
													<ABB.Icon
														sizeClass="small"
														className="align-middle"
														name="abb/warning-circle-2"
														color="red"
													/>{" "}
													<span className="align-middle">
														{t("FactoryResetDeviceDatabase")}
													</span>
												</p>
												<ABB.Button
													type="normal"
													text={t("FactoryResetDatabase")}
													sizeClass="small"
													style={{ width: "210px" }}
													icon="abb/turn-counter-clockwise"
													onClick={() => {
														setResetDevice(true);
														setShowResetDialog(true);
													}}
												/>
											</>
										)}
									</Card>
									<Card
										title={t("AuditLogs")}
										mobileCols={12}
										tabletCols={12}
										desktopCols={12}
									>
										<p>{t("DownloadAuditLogsFile")}</p>
										<ABB.Button
											type="primary-blue"
											text={t("AuditLogs")}
											sizeClass="small"
											style={{ width: "210px" }}
											icon="abb/download"
											download={true}
											onClick={() => {
												downloadDataBaseFile(props, UserDataType.AuditLogs);
											}}
										/>
									</Card>
									<Card
										title={t("Clone")}
										contentClassName="database-content"
										mobileCols={12}
										tabletCols={12}
										desktopCols={12}
									>
										<p>{t("DownloadClonePackage")}</p>
										<ABB.Button
											type="primary-blue"
											text={t("Download")}
											sizeClass="small"
											style={{ width: "210px" }}
											icon="abb/download"
											download={true}
											onClick={() => {
												downloadDataBaseFile(props);
											}}
										/>
										{isAdmin && (
											<>
												<p>{t("UploadClonePackage")}</p>
												<ABB.Button
													type="primary-blue"
													text={t("Upload")}
													sizeClass="small"
													style={{ width: "210px" }}
													icon="abb/upload"
													onClick={() => {
														setUploadType("clone");
														setShowUploadDialog(true);
													}}
												/>
											</>
										)}
									</Card>
								</div>
							</ABB.TabItem>
							<ABB.TabItem disabled={true} title={t("Maintenance")}>
								{/* TODO <div className="row training-row">
                                    <Card title={t('PrepareForTraining')} mobileCols={12} tabletCols={12} desktopCols={12}>
                                        <p>{t('PrepareForTrainingInstruction')}</p>
                                        <ABB.Button text={t('Prepare')}
                                            type="primary-blue"
                                            onClick={prepareForTraining}
                                            sizeClass="small"></ABB.Button>
                                    </Card>
                                    <Card title={t('TrainingExecution')} mobileCols={12} tabletCols={12} desktopCols={12}>
                                        <p>{t('TrainingInstructions')}</p>
                                        <ABB.Button text={t('Start')}
                                            type="primary-blue"
                                            isLoading={training}
                                            onClick={startTraining}
                                            sizeClass="small"></ABB.Button>
                                        {trainingFeedback !== null &&
                                            <div className={"mt-2 training-feedback " + (trainingFeedback === true ? "success" : "failed")}>
                                                <p className="m-0">{trainingFeedbackMessage}</p>
                                            </div>
                                        }
                                    </Card>
                                </div> */}
							</ABB.TabItem>
							<ABB.TabItem title={t("Users")}>
								<SettingsUsers />
							</ABB.TabItem>
							<ABB.TabItem
								title={t("ThermalConfiguration")}
								disabled={!isAdmin}
							>
								{isAdmin && <ThermalConfiguration />}
							</ABB.TabItem>
						</ABB.TabControl>
					</Card>
				</div>
				<ABB.Dialog
					isOpen={showFirmwareUpdated}
					dimBackground={true}
					title={t("UpdateFirmware")}
				>
					<div>{t("UpdateFirmwareCompleted")}.</div>
					<div>{t("UpdateFirmwareWaitForRestart")}.</div>
					<ABB.LoadingIndicator
						className="my-3"
						type="bar"
						determinate={false}
						color="red"
						sizeClass="medium"
					/>
				</ABB.Dialog>
				<ABB.MessageDialog
					buttons="ok"
					isOpen={showFactoryResetCompleted}
					message={t("FactoryResetCompleted")}
					title={t("FactoryResetDatabase")}
					onOk={() => window.location.reload()}
				/>
				<ABB.MessageDialog
					buttons="confirmcancel"
					isOpen={showFirmwareDialog}
					message={t("ConfirmFirmwareUpdate")}
					title={t("Confirmation")}
					onCancel={() => {
						setShowFirmwareDialog(false);
						setFirmwareFile(undefined);
					}}
					onConfirm={() => uploadFirmware()}
				/>
				<ABB.MessageDialog
					buttons="confirmcancel"
					isOpen={showResetDialog}
					message={`${t("ConfirmFactoryResetFor")} ${
						resetDevice
							? t("DeviceDatabase")
							: t("ConfigurationAndCalibrationDatabase")
					}?`}
					title={t("Confirmation")}
					onCancel={() => {
						setShowResetDialog(false);
					}}
					onConfirm={() => factoryReset()}
				/>
				<ABB.Dialog
					title={t("Upload")}
					isOpen={showUploadDialog}
					dimBackground={true}
					showCloseButton={false}
					closeOnEscape={true}
					closeOnLostFocus={true}
					hideBackground={false}
					onClose={() => {
						setUploadFileObject(null);
						setShowUploadDialog(false);
					}}
					standardButtonsOnBottom={[
						{
							text: t("Cancel"),
							type: "discreet-blue",
							handler: (dlg) => {
								setUploadFileObject(null);
								setShowUploadDialog(false);
							},
						},
						{
							text: t("Upload"),
							type: "primary-blue",
							handler: (dlg) => {
								setShowUploadDialog(false);
								upload();
							},
						},
					]}
				>
					<div>
						<p>{getUploadDialogTitle()}</p>
					</div>
					<div style={{ height: "110px" }}>
						<FileUploader
							showFileName={true}
							accept={getUploadDialogAccept()}
							onFileSelected={(files) => setUploadFileObject(files[0])}
						></FileUploader>
					</div>
				</ABB.Dialog>
			</div>
		</>
	);
}
