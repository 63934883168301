import { getHealthIndexStatus, getLastEventValue, getMultipleSamples } from '.';
import { DateTimeFormatHelper } from '../../helpers';
import { LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { SyncStatusProps } from '../../widgets';

export async function loadSyncStatus(config: WidgetConfig, props: Partial<SyncStatusProps>): Promise<SyncStatusProps> {
	const syncStatus = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as SyncStatusPropsConfig;
		const [
			frequency,
			voltage,
		] = await getMultipleSamples('number', [
			propsConfig.frequency.source,
			propsConfig.voltage.source,
		]);
		const [
			syncEnabled,
			workingMode,
		] = await getMultipleSamples('boolean', [
			propsConfig.syncEnabled.source,
			propsConfig.workingMode.source,
		]);
		[frequency, voltage, syncEnabled, workingMode].forEach((sample) => {
			const previous = syncStatus.lastUpdate;
			syncStatus.lastUpdate = getGreaterDate(syncStatus.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined);
			if (previous !== syncStatus.lastUpdate) {
				syncStatus.lastUpdateType = LastUpdateType.Sample;
			}
		});
		let frequencyStatus;
		try {
			frequencyStatus = getLastEventValue(propsConfig.frequencyStatus.source);
			const previous = syncStatus.lastUpdate;
			syncStatus.lastUpdate = getGreaterDate(frequencyStatus.date, syncStatus.lastUpdate);
			if (previous !== syncStatus.lastUpdate) {
				syncStatus.lastUpdateType = LastUpdateType.Event;
			}
		} catch (err) {
			console.warn('loadSyncStatus', err);
			frequencyStatus = undefined;
		}
		let voltageStatus;
		try {
			voltageStatus = getLastEventValue(propsConfig.voltageStatus.source);
			const previous = syncStatus.lastUpdate;
			syncStatus.lastUpdate = getGreaterDate(voltageStatus.date, syncStatus.lastUpdate);
			if (previous !== syncStatus.lastUpdate) {
				syncStatus.lastUpdateType = LastUpdateType.Event;
			}
		} catch (err) {
			console.warn('loadSyncStatus', err);
			voltageStatus = undefined;
		}

		syncStatus.frequency = frequency.value!;
		syncStatus.voltage = voltage.value!;
		syncStatus.frequencyStatus = frequencyStatus ? getHealthIndexStatus(frequencyStatus) : undefined;
		syncStatus.syncEnabled = syncEnabled!.value ?? undefined;
		syncStatus.voltageStatus = voltageStatus ? getHealthIndexStatus(voltageStatus) : undefined;
		syncStatus.workingMode = workingMode!.value ?? undefined;
	} catch (e) {
		console.error("loadSyncStatus", e);
		syncStatus.exception = e;
	} finally {
		syncStatus.fetching = false;
	}
	return syncStatus as SyncStatusProps;
}
export interface SyncStatusPropsConfig {
	title: {
		i18n: string,
	},
	frequency: {
		source: NamedSource,
	},
	syncEnabled: {
		source: NamedSource,
	},
	workingMode: {
		source: NamedSource,
	},
	link: {
		value: string,
	},
	linkName: {
		i18n: string,
	},
	frequencyStatus: {
		source: NamedSource,
	}
	voltageStatus: {
		source: NamedSource,
	},
	voltage: {
		source: NamedSource,
	},
}
