import {
	AllSections,
	AppSections,
	DashboardSections,
	TemperatureSections,
	MechanicalSections,
	ElectricalSections,
} from "../enums";
import { createLocation, LocationState, History } from "history";
import { LocalizationHelper } from "./LocalizationHelper";

export class SectionsHelper {
	private static dashboard = "/dashboard";
	private static temperature = SectionsHelper.dashboard + "/temperature";
	private static mechanical = SectionsHelper.dashboard + "/mechanical";
	private static electrical = SectionsHelper.dashboard + "/electrical";

	static getPath(section: AllSections): string {
		switch (section) {
			case AppSections.Dashboard:
				return SectionsHelper.dashboard;

			case AppSections.Documents:
				return "/documents";

			case AppSections.Settings:
				return "/settings";

			case AppSections.AssetInformation:
				return "/asset";

			case AppSections.Trends:
				return "/trends";

			case DashboardSections.Temperature:
				return SectionsHelper.temperature;

			case DashboardSections.Mechanical:
				return SectionsHelper.mechanical;

			case DashboardSections.Electrical:
				return SectionsHelper.electrical;

			case TemperatureSections.SmartArm1:
				return SectionsHelper.temperature + "/smartarm1";

			case TemperatureSections.SmartArm2:
				return SectionsHelper.temperature + "/smartarm2";

			case TemperatureSections.SmartArm3:
				return SectionsHelper.temperature + "/smartarm3";

			case MechanicalSections.Coils:
				return SectionsHelper.mechanical + "/coils";

			case MechanicalSections.ContactForce:
				return SectionsHelper.mechanical + "/contactforce";

			case MechanicalSections.Motor:
				return SectionsHelper.mechanical + "/motor";

			case MechanicalSections.TravelCurve:
				return SectionsHelper.mechanical + "/travelcurve";

			case MechanicalSections.Vibration:
				return SectionsHelper.mechanical + "/vibration";

			case ElectricalSections.TrippingCurrent:
				return SectionsHelper.electrical + "/trippingcurrent";

			default:
				throw new RangeError();
		}
	}

	static getName(section: AllSections): string {
		switch (section) {
			case AppSections.Dashboard:
				return LocalizationHelper.getTranslation("Dashboard");

			case AppSections.Documents:
				return LocalizationHelper.getTranslation("Documents");

			case AppSections.Settings:
				return LocalizationHelper.getTranslation("Settings");

			case AppSections.AssetInformation:
				return LocalizationHelper.getTranslation("Asset information");

			case AppSections.Trends:
				return LocalizationHelper.getTranslation("Trends");

			case DashboardSections.Temperature:
				return LocalizationHelper.getTranslation("Temperature");

			case DashboardSections.Mechanical:
				return LocalizationHelper.getTranslation("Mechanical");

			case DashboardSections.Electrical:
				return LocalizationHelper.getTranslation("Electrical");

			case TemperatureSections.SmartArm1:
				return LocalizationHelper.getTranslation("Smart Arm 1");

			case TemperatureSections.SmartArm2:
				return LocalizationHelper.getTranslation("Smart Arm 2");

			case TemperatureSections.SmartArm3:
				return LocalizationHelper.getTranslation("Smart Arm 3");

			case MechanicalSections.Coils:
				return LocalizationHelper.getTranslation("Coils");

			case MechanicalSections.ContactForce:
				return LocalizationHelper.getTranslation("Contact Force");

			case MechanicalSections.Motor:
				return LocalizationHelper.getTranslation("Motor");

			case MechanicalSections.TravelCurve:
				return LocalizationHelper.getTranslation("Travel Curve");

			case MechanicalSections.Vibration:
				return LocalizationHelper.getTranslation("Vibration");

			case ElectricalSections.TrippingCurrent:
				return LocalizationHelper.getTranslation("Tripping Current");

			default:
				throw new RangeError();
		}
	}

	static getIcon(section: AppSections): string {
		switch (section) {
			case AppSections.Dashboard:
				return "abb/dashboard";

			case AppSections.Documents:
				return "abb/document";

			case AppSections.Settings:
				return "abb/settings";

			case AppSections.AssetInformation:
				return "abb/information-circle-2";

			case AppSections.Trends:
				return "abb/jog-planar";

			default:
				throw new RangeError();
		}
	}

	// private static createHrefByString(to: string, history: History<LocationState>, currentLocation: Location<LocationState>): string {
	//   const newLocation = createLocation(to, null, undefined, currentLocation);
	//   let href = history.createHref(newLocation);
	//   return href;
	// }
	//
	// static createHref(to: Sections | DashboardSections, history: History<LocationState>, currentLocation: Location<LocationState>): string {
	//   return SectionsHelper.createHrefByString(SectionsHelper.getPath(to), history, currentLocation);
	// }

	static createHrefByString(
		to: string,
		history: History<LocationState>
	): string {
		let newLocation = createLocation(to);
		let href = history.createHref(newLocation);
		return href;
	}

	static createHref(to: AllSections, history: History<LocationState>): string {
		return SectionsHelper.createHrefByString(
			SectionsHelper.getPath(to),
			history
		);
	}

	static getParentSection(section: AllSections): AllSections | undefined {
		switch (section) {
			case AppSections.Dashboard:
			case AppSections.Documents:
			case AppSections.Settings:
			case AppSections.AssetInformation:
			case AppSections.Trends:
				return undefined;

			case DashboardSections.Temperature:
			case DashboardSections.Mechanical:
			case DashboardSections.Electrical:
				return AppSections.Dashboard;

			case TemperatureSections.SmartArm1:
			case TemperatureSections.SmartArm2:
			case TemperatureSections.SmartArm3:
				return DashboardSections.Temperature;

			case MechanicalSections.Coils:
			case MechanicalSections.ContactForce:
			case MechanicalSections.Motor:
			case MechanicalSections.TravelCurve:
			case MechanicalSections.Vibration:
				return DashboardSections.Mechanical;

			case ElectricalSections.TrippingCurrent:
				return DashboardSections.Electrical;

			default:
				throw new RangeError();
		}
	}
}
