import * as Enums from "../../enums"
import * as Models from "../../models"

export interface IData { }

export interface IConfig {
    update?: number;
}

export interface SingleApiDataItem extends ApiIdentityItem, IData { }


export interface IWidgetConfig {
    type: Enums.WidgetType;
    layout?: WidgetLayoutConfig;
    data?: IData;
    configuration?: IConfig;
}

export interface WidgetLayoutConfig {
    desktop: number;
    tablet: number;
    mobile: number;
}

export enum ApiIdentityItemType {
    sample = "sample",
    event = "event",
    value = "value",
    waveform = "waveform"
}


export interface DeviceIdentity {
    model: string;
    id: string;
    checked?: boolean;
}
export interface ApiIdentityItem {
    device?: DeviceIdentity;
    states?: Models.EventMetadataStates;
    type: ApiIdentityItemType;
    role?: string;
    name?: string;
    path?: string[];
    operation?: Models.WaveformOperation;
		position?: string;
}
