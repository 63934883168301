import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploader } from '../../components';
import { DocumentsToUpload } from './DocumentsToUpload';

export interface DocumentsData {
	files: File[],
}

interface DocumentsProps {
	setData: (data: DocumentsData | null) => unknown,
}

export function Documents({ setData }: DocumentsProps): ReactElement {
	const { t } = useTranslation();
	const [files, setFiles] = useState<File[]>([]);
	
	const onFilesSelected = useCallback((selectedFiles) => {
		setFiles([...files, ...selectedFiles]);
	}, [files]);

	useEffect(() => {
		setData({ files });
	}, [files, setData]);

	return <>
		<p>{t("DocumentsIntro")}</p>
		<div className='documents-list-item mt-4'>
			<span>{t('UploadDocumentation')}</span>
			<div className='documents-file-uploader-wrapper mt-3'>
				<FileUploader accept=".txt, .pdf, image/png, image/jpeg, .xlsx, .docx" onFileSelected={(files) => onFilesSelected(files)} />
			</div>
		</div>
		<div>
			<DocumentsToUpload files={files} setFiles={setFiles} />
		</div>
	</>;
}
