import { BehaviorSubject } from 'rxjs';
import { logout } from './access';

export type Scenario = {
	name: string;
	description: string;
}

export type ScenariosResponse = {
	scenarios: Scenario[]
}

export const isDemo = Boolean(process.env.REACT_APP_DEMO);

export const selectedScenario$ = new BehaviorSubject<string|null>(null);

export const scenarios$ = new BehaviorSubject<Scenario[]|undefined>(undefined);

export function initDemoModule() {
	selectedScenario$.subscribe((selectedScenario) => {
		if (selectedScenario === null) {
			logout();
		}
	});
}
