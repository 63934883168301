import React, { ReactElement } from "react";
//import * as am4core from "@amcharts/amcharts4/core";
//import * as am4charts from "@amcharts/amcharts4/charts";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import { Card } from "../../components";
import { ChartDataItem, ChartWidgetProps } from "../../models"
//import { BootstrapHelper, ChartHelper } from "../../helpers"
import "./style.scss";

export interface BubbleOptimumValues {

    intervalValue: number;//ampiezza intervallo colorato | valore massimo secondo cui la proporzione di  max radius (heatRules) viene mantenuta
    name?: string;
    color?: string;
}

export interface BubbleChartItemProps {
    unit: string;
    name: string;
    color: string;
    hoverColor?: string;
}

//in order to preserve proportion in the graph : 
//value2=150                                                                                                (fixed but taken from props.data)
//Grey interval range value: max(200) - min(100) = 100                                                      fixed
//HeatRules max= 30                                                                                         fixed
//yValueAxis.min = 0;                                                                                       fixed
//yValueAxis.max = 300;                                                                                     fixed
//HeatRule maxValue = maximum legal value to maintain the bullet inside the grey range                      (free and configurable from props.optimumValue.intervalValue)

//proporzioni da mantenere nel grafico--> alcuni dati sono dipendenti tra loro:
/*MODIFICARE 
    1)yValueAxis.min e max --> comporta la modifica di value2 in TUTTI i dati ricevuti in ingresso dall'api, ------>>>  value2 = (yValueAxis.max - yValueAxis.min)/2

    2)per rimpicciolire la barra grigia ==>> ora è larga 100 -->  range.value = value2-50,   range.endValue = value2 + 50 
        -   diminuire in proporzione  value e end value, mantenendo centrata la barra grigia su value2
        -   modificare heatRule max di conseguenza--> considerando che ORA è intervallo grigio(100) : heatRule max (30) mantenere [più o meno] questa proporzione, 
            per verificare se la proporzione è corretta, impostare uno dei props.data.vaule =  heatRUles->maxValue, verificare se nel grafico il Circle corrispondente tocca i limiti della barra grigia

    3)heatRules--> max rappresenta il valore massimo che deve essere contenuto nella barra grigia
*/ 





export interface BubbleChartProps extends ChartWidgetProps {
    chartDivId: string;
    data: ChartDataItem[];//use only value: valore effettivo, value2: altezza fissa !important: value 2 = 150  , date: data asse x
    bubbleDataInfo: BubbleChartItemProps;
    optimumValue: BubbleOptimumValues;
    xAxisLabel?: string;
    showLegend?: boolean;
}


export function BubbleChart(props: BubbleChartProps): ReactElement {

    /* function setState(singleSerie: am4charts.XYSeries, state: string) {

        if (singleSerie.bullets.length > 0 && singleSerie.bullets.values[0].clones.length > 0 && singleSerie.bullets.values[0].clones.values[0]) {
            singleSerie.bullets.values[0].clones.each(function (bulletCircle) { bulletCircle.setState(state) });
        }
    }
 */
    //************************************************************ LEGEND FUNCTIONS ************************************************************
    /* function showHideElement(id: string, i: "bullet") {

        let tmpChart = am4core.registry.baseSprites.find(c => c.htmlContainer?.id === id) as am4charts.XYChart;
        if (tmpChart) 
            tmpChart.series.each(function (singleSerie) {
                if (singleSerie.bullets !== undefined && singleSerie.bullets.values[0].dummyData.seriesid === i)
                    if (singleSerie.isHidden)
                        singleSerie.show();
                    else
                        singleSerie.hide();
            });      
    }

    function processOutOver(id: string, i: "bullet") {

        let tmpChart = am4core.registry.baseSprites.find(c => c.htmlContainer?.id === id) as am4charts.XYChart;
        if (tmpChart)
            tmpChart.series.each(function (singleSerie) {
                if (singleSerie.bullets !== undefined && singleSerie.bullets.values[0].dummyData.seriesid === i) 
                    setState(singleSerie, "default")             
            });
    }

    function processOver(id: string, i: "bullet") {

        let tmpChart = am4core.registry.baseSprites.find(c => c.htmlContainer?.id === id) as am4charts.XYChart;
        if (tmpChart)
            tmpChart.series.each(function (singleSerie) {
                if (singleSerie.bullets !== undefined && singleSerie.bullets.values[0].dummyData.seriesid === i) 
                    setState(singleSerie, "selected")               
            });
    }
    //************************************************************ LEGEND FUNCTIONS ************************************************************

    useEffect(() => {
        let chart: am4charts.XYChart;
        let mainColor = am4core.color("red");

        // Create chart instance
        chart = am4core.create(props.chartDivId, am4charts.XYChart);
        chart.zoomOutButton.disabled = true;
        chart.dateFormatter.inputDateFormat = "x";

        // Create axes
        let xDateAxis = chart.xAxes.push(new am4charts.DateAxis());
        xDateAxis.cursorTooltipEnabled = false;
        xDateAxis.renderer.grid.template.disabled = true;
        xDateAxis.skipEmptyPeriods = true;

        //dateAxis title
        if (props.xAxisLabel)
        {
            xDateAxis.title.text = props.xAxisLabel;
            xDateAxis.title.align = "left";
            xDateAxis.title.fontWeight = "bold";
        }

        ///yValueAxis.axisRanges.values[0].axisFill.fill
        let yValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yValueAxis.cursorTooltipEnabled = false;
        yValueAxis.renderer.grid.template.disabled = true;
        yValueAxis.renderer.labels.template.disabled = true;
        yValueAxis.calculateTotals = true;
        yValueAxis.min = 0;     //fixed
        yValueAxis.max = 300;   //fixed
        yValueAxis.strictMinMax = true;

        //optim range
        let range = yValueAxis.axisRanges.create();
        range.value = 100;      //fixed
        range.endValue = 200;   //fixed
        range.axisFill.fill = am4core.color("#396478");
        range.axisFill.fillOpacity = 0.2;
        range.grid.strokeOpacity = 0;

        //main serie
        let series = chart.series.push(new am4charts.LineSeries());
        series.name = "Value";
        series.stroke = mainColor;
        series.strokeWidth = 0;
        series.dataFields.valueY = "value2";//fixed height where center of bullets are printed
        series.dataFields.dateX = "date";
        series.dataFields.value = "value";//effective bullet value 

        //bullet
        let bullet = series.bullets.push(new am4core.Circle());
        bullet.fill = am4core.color(props.bubbleDataInfo.color);
        bullet.strokeOpacity = 0;
        bullet.strokeWidth = 1;
        bullet.fillOpacity = 0.5;
        bullet.stroke = am4core.color(props.bubbleDataInfo.color);
        bullet.hiddenState.properties.opacity = 0;
        bullet.dummyData = { seriesid: "bullet" }
        bullet.hoverOnFocus = true;


        //bullet state
        let hoverBullet: am4core.SpriteState<am4core.ICircleProperties, am4core.ICircleAdapters> = bullet.states.create("hover")
        hoverBullet.properties.fill = am4core.color(props.bubbleDataInfo.hoverColor!==undefined? props.bubbleDataInfo.hoverColor :"red");
        
        let selectedBullets: am4core.SpriteState<am4core.ICircleProperties, am4core.ICircleAdapters> = bullet.states.create("selected")
        selectedBullets.properties.strokeOpacity = 0.8;
        selectedBullets.properties.stroke = am4core.color(props.bubbleDataInfo.color);

        //bullet rules
        series.heatRules.push(
            {
                target: series.bullets.values[0],

                maxValue: props.optimumValue.intervalValue,//max value where bullet is contained perfectly in optimum Energy Range            
                min: 5,
                max: 30,//radious max (proportionated on optimum Energy Range height)
                property: "radius",
                dataField: "value"
            });

        series.tooltipText = "{name}:  [bold]{value} " + props.bubbleDataInfo.unit + " [/]";
        let axisTooltip = series.tooltip;
        if (axisTooltip) {
            axisTooltip.pointerOrientation = "down";
            axisTooltip.getFillFromObject = false;
            axisTooltip.autoTextColor = false;
            axisTooltip.background.fill = am4core.color("white");
            axisTooltip.label.fill = am4core.color("black");
        }


        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "none";
        chart.cursor.lineY.disabled = true;
        chart.cursor.lineX.disabled = true;
        //cleanup
        return () => {
            if (chart) {
                chart.dispose();
            }
        };
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour


    useEffect(() => {
        let tmpChart = am4core.registry.baseSprites.find(c => c.htmlContainer?.id === props.chartDivId) as am4charts.XYChart;
        if (tmpChart) {
            tmpChart.data = props.data;
        }
    }, [props.data]);

    useEffect(() => {
        let tmpChart = am4core.registry.baseSprites.find(c => c.htmlContainer?.id === props.chartDivId) as am4charts.XYChart;
        if (tmpChart) {
            tmpChart.xAxes.each(a => ChartHelper.setDateFormat(props.scaleType, a as am4charts.DateAxis));
        }
    }, [props.scaleType]);//props che voglio che faccia scattare evento

    return (
        <Card title={props.title} comment={props.lastUpdate}
            mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
            tabletCols={BootstrapHelper.getColValue(12, props.tabletCols)}
            desktopCols={BootstrapHelper.getColValue(6, props.desktopCols)}>


            {props.showLegend &&
                <div className="d-flex flex-row justify-content-between" style={{ flexWrap: "wrap", marginRight: "15px", marginLeft: "15px", marginBottom: "15px", alignItems: "center" }}>
                    <div className="d-flex legendToggle-margin justify-content-start" style={{ marginLeft: "-15px", flexWrap: "wrap" }}>

                        <div key={"legend" + props.chartDivId + (props.optimumValue.name || "Optimum Energy Range")} className="d-flex flex-col">
                            <div className="bubble-square" style={{ background: (props.optimumValue.color || "#BABABA"), marginRight: "10px", marginLeft: "15px" }} />{props.optimumValue.name || "Optimum Energy Range"}
                        </div>

                        <div key={"legend" + props.chartDivId + props.bubbleDataInfo.name} className="d-flex flex-col" style={{ cursor: "pointer" }} onClick={() => showHideElement(props.chartDivId, "bullet")} onMouseOver={() => processOver(props.chartDivId, "bullet")} onMouseOut={() => processOutOver(props.chartDivId, "bullet")}>
                            <div className="bubble-circle" style={{ background: props.bubbleDataInfo.color, marginRight: "10px", marginLeft: "15px" }} />{props.bubbleDataInfo.name}
                        </div>
                    </div>
                </div>}
            <div id={props.chartDivId} className={"appChart"}></div>
        </Card>
    ); */
		return <></>;
}
