import React, { ReactElement, useEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import { Card } from "../../components";
import { WaveformChartDataItem, WidgetProps } from '../../models';
import { BootstrapHelper } from '../../helpers';


export interface WaveformChartItemProps {
	unit: string;
	name: string;
	color: string;
	dotted?: boolean;
	/* lastValue: number;
	lastUpdate: Date;

	dashed?: boolean;
	
	highAlarm?: number;
	highWarn?: number;
	lowWarn?: number;
	lowAlarm?: number;

	highAlarmLabel?: string;
	highWarnLabel?: string;
	lowWarnLabel?: string;
	lowAlarmLabel?: string;

	showSerieMissingDataGap?: boolean;//NEW PROP
	connect?: boolean; */
}
export interface WaveformChartProps extends WidgetProps {
	accentColor?: string;
	data: WaveformChartDataItem[];
	dataInfo: WaveformChartItemProps[];
	rangeData?: WaveformChartDataItem[]; 
	unit?: string;
	filterRef?: string;
	showRange: boolean;
	showHorizontalGrid: boolean;
	hideYAxis?: boolean;
	yAxisLabel?: string;
	xAxisLabel?: string;
	xAxisLabelAlignment?: 'center' | 'left' | 'right' | 'none';
	yAxisLabelAlignment?: 'top' | 'middle' | 'bottom' | 'none';
	showUnitInYLabel: boolean;
	timeUnit: string;
	topRightContent?: (() => ReactElement)|undefined; 
	topCenterContent?: (() => ReactElement)|undefined; 
}

export function WaveformChart(props: WaveformChartProps): ReactElement {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [chart, setChart] = useState<am4charts.XYChart | null>(null);
	const [series, setSeries] = useState<am4charts.LineSeries | null>(null);
	const [bottomRangeSeries, setBottomRangeSeries] = useState<am4charts.LineSeries | null>(null);
	const [topRangeSeries, setTopRangeSeries] = useState<am4charts.LineSeries | null>(null);
	const [xAxis, setXAxis] = useState<am4charts.DateAxis | null>(null);
	const [yAxis, setYAxis] = useState<am4charts.ValueAxis | null>(null);

	useEffect(() => {
		if (!containerRef.current) {
			return;
		}
		// Create chart instance
		const _chart = am4core.create(containerRef.current, am4charts.XYChart);
		_chart.zoomOutButton.disabled = true;
		_chart.dateFormatter.inputDateFormat = "x";
		_chart.cursor = new am4charts.XYCursor();
		_chart.cursor.behavior = "none";
		_chart.cursor.lineY.disabled = true;
		setChart(_chart);

		// Create axes
		const xDateAxis = _chart.xAxes.push(new am4charts.DateAxis());
		xDateAxis.cursorTooltipEnabled = false;
		xDateAxis.renderer.grid.template.disabled = true;
		xDateAxis.title.text = "Time";

		const formats: am4core.TimeUnit[] = ["millisecond", "second", "minute", "hour"];
		formats.forEach(f => {
			xDateAxis.dateFormats.setKey(f, "x");
			xDateAxis.periodChangeDateFormats.setKey(f, "x");
		});
		setXAxis(xDateAxis);

		const yValueAxis = _chart.yAxes.push(new am4charts.ValueAxis());
		yValueAxis.cursorTooltipEnabled = false;
		yValueAxis.renderer.grid.template.disabled = true;
		yValueAxis.renderer.labels.template.disabled = true;
		setYAxis(yValueAxis);

		if (props.dataInfo.length <= 1) {
			const _series = _chart.series.push(new am4charts.LineSeries());
			_series.name = "Value";
			_series.connect = true;
			_series.dataFields.valueY = "value";
			_series.dataFields.dateX = "t_us";
			_series.strokeWidth = 1.5;
			_series.tensionX = 0.9;
			_series.tensionY = 0.9;
			const axisTooltip = _series.tooltip;
			if (axisTooltip) {
				axisTooltip.numberFormatter.numberFormat = "####.#####";
				axisTooltip.getFillFromObject = false;
				axisTooltip.autoTextColor = false;
				axisTooltip.background.fill = am4core.color("white");
				axisTooltip.label.fill = am4core.color("black");
			}

			setSeries(_series);
			if (props.showRange) {
				const _topRangeSeries = _chart.series.push(new am4charts.LineSeries());
				_topRangeSeries.name = "Top";
				_topRangeSeries.connect = true;
				_topRangeSeries.dataFields.valueY = "value";
				_topRangeSeries.dataFields.dateX = "t_us";
				_topRangeSeries.strokeWidth = 1.5;
				_topRangeSeries.strokeDasharray = '2';
				_topRangeSeries.data = [];
				setTopRangeSeries(_topRangeSeries);
				const _bottomRangeSeries = _chart.series.push(new am4charts.LineSeries());
				_bottomRangeSeries.name = "Bottom";
				_bottomRangeSeries.connect = true;
				_bottomRangeSeries.dataFields.valueY = "value2";
				_bottomRangeSeries.dataFields.dateX = "t_us";
				_bottomRangeSeries.strokeWidth = 1.5;
				_bottomRangeSeries.strokeDasharray = '2';
				_bottomRangeSeries.data = [];
				setBottomRangeSeries(_bottomRangeSeries);
			}
		} else {
			_chart.legend = new am4charts.Legend();
			_chart.legend.position = 'right';
			_chart.legend.markers.template.width = 15;
			props.dataInfo.forEach((dataInfo, i) => {
				const _dataInfoSeries = _chart.series.push(new am4charts.LineSeries());
				const axisTooltip = _dataInfoSeries.tooltip;
				if (axisTooltip) {
					axisTooltip.numberFormatter.numberFormat = "####.#####";
					axisTooltip.getFillFromObject = false;
					axisTooltip.autoTextColor = false;
					axisTooltip.background.fill = am4core.color("white");
					axisTooltip.label.fill = am4core.color("black");
					_dataInfoSeries.tooltipText = "{name}: [bold]{valueY}" + dataInfo.unit + "[/]";
				}
				_dataInfoSeries.name = dataInfo.name;
				_dataInfoSeries.connect = true;
				_dataInfoSeries.dataFields.valueY = `value${i ? (i + 1) : ''}`;
				_dataInfoSeries.dataFields.dateX = "t_us";
				_dataInfoSeries.stroke = am4core.color(dataInfo.color);
				_dataInfoSeries.strokeWidth = 2;
				if (dataInfo.dotted) {
					_dataInfoSeries.strokeDasharray = '2';
				}
			});
		}
	}, [containerRef, props.showRange, props.dataInfo]);

	useEffect(() => {
		if (xAxis) {
			let divideBy = 1;
			switch (props.timeUnit) {
				case 'ms':
					divideBy = 1000;
					break;
				case 's':
					divideBy = 1000000;
					break;
			}
			xAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
				let value = 0;
				if (label !== undefined) {
					value = parseInt(label);
					if (!isNaN(value) && value !== 0)
						value = value / divideBy;
					else
						value = 0;
				}
				return value + props.timeUnit;
			});
		}
	}, [xAxis, props.timeUnit]);

	useEffect(() => {
		if (chart && !chart.isDisposed() && props.hideYAxis !== undefined) {
			chart.yAxes.each((axis) => {
				axis.renderer.labels.template.disabled = props.hideYAxis!;
				axis.renderer.grid.template.disabled = props.hideYAxis!;
				axis.renderer.baseGrid.disabled = !props.showHorizontalGrid;
				axis.renderer.grid.template.strokeOpacity = props.showHorizontalGrid ? 0.2 : 0;
			});
		}
	}, [chart, props.hideYAxis, props.showHorizontalGrid]);

	const firstRender = useRef(true);
	useEffect(() => {
		if (chart && !chart.isDisposed()) {
			if (!firstRender.current) {
				chart.series.each((series) => {
					series.interpolationDuration = 0;
				});
			}
			firstRender.current = false;
			chart.data = props.data;
		}
	}, [chart, props.data, firstRender]);

	useEffect(() => {
		if (bottomRangeSeries && !bottomRangeSeries.isDisposed() && topRangeSeries && !topRangeSeries.isDisposed()) {
			topRangeSeries.data = props.rangeData ?? [];
			bottomRangeSeries.data = props.rangeData ?? [];
		}
	}, [bottomRangeSeries, topRangeSeries, props.rangeData, firstRender]);

	useEffect(() => {
		if (series) {
			const mainColor = am4core.color(props.accentColor ? props.accentColor : "red");
			series.stroke = mainColor;
		}
	}, [props.accentColor, series]);

	useEffect(() => {
		if (series) {
			const unit = props.unit ?? "";
			series.tooltipText = "{name}: [bold]{valueY}" + unit + "[/]";
		}
	}, [series, props.unit]);

	useEffect(() => {
		if (props.xAxisLabel && xAxis && !xAxis.isDisposed()) {
			xAxis.title.text = props.xAxisLabel;
			if (props.xAxisLabelAlignment) {
				xAxis.title.align = props.xAxisLabelAlignment;
			}
		}
		if (props.yAxisLabel && yAxis && !yAxis.isDisposed()) {
			yAxis.title.text = props.yAxisLabel;
			if (props.showUnitInYLabel && props.unit) {
				yAxis.title.text += ` (${props.unit})`;
			}
			if (props.yAxisLabelAlignment) {
				yAxis.title.valign = props.yAxisLabelAlignment;
			}
		}
	}, [props.xAxisLabel, xAxis, props.xAxisLabelAlignment, yAxis, props.unit, props.yAxisLabel, props.showUnitInYLabel, props.yAxisLabelAlignment]);


	useEffect(() => {
		//cleanup
		return () => {
			if (chart && !chart.isDisposed()) {
				chart.dispose();
				setChart(null);
			}
		};
	}, [chart, props.showRange, props.dataInfo]);

	return (
		<Card
			title={props.title}
			topRightContent={props.topRightContent}
			topCenterContent={props.topCenterContent}
			comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
			showProgressBar={props.fetching}
			progressBar="top"
			installed={props.installed}
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(12, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(6, props.desktopCols)}>
			<div ref={containerRef} className={"appChart"}></div>
		</Card>
	);
}
