import { getLastEventValue } from '.';
import { BinaryStatus, HealthIndexStatus } from '../../enums';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { ImageStatusWithOverlaysProps } from '../../widgets';

export async function loadImageStatusWithOverlays(config: WidgetConfig, props: Partial<ImageStatusWithOverlaysProps>): Promise<ImageStatusWithOverlaysProps> {
	const imageStatusWithOverlays = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as ImageStatusWithOverlaysPropsConfig;
		const layers = imageStatusWithOverlays.layers!.map((_, i) => getLastEventValue(propsConfig.alerts[i].source));
		imageStatusWithOverlays.visibleLayerIndices = layers.flatMap((l, i) => l.mappedValue === BinaryStatus.Ok ? [] : [i]);
		imageStatusWithOverlays.status = imageStatusWithOverlays.visibleLayerIndices.length > 0 ? HealthIndexStatus.Alarm : HealthIndexStatus.Ok;
		if (imageStatusWithOverlays.status === HealthIndexStatus.Ok) {
			imageStatusWithOverlays.message = 'No issues found';
		} else {
			// TODO custom messages
			imageStatusWithOverlays.message = 'Failure';
		}
	} catch (e) {
		console.error('loadImageStatusWithOverlays', e);
		imageStatusWithOverlays.exception = e;
	} finally {
		imageStatusWithOverlays.fetching = false;
	}
	return imageStatusWithOverlays as ImageStatusWithOverlaysProps;
}
export interface ImageStatusWithOverlaysPropsConfig {
	alerts: { source: NamedSource }[],
	layers: { url: { value: string } }[],
	"layers-estraibile": { url: { value: string } }[],
	url: { value: string },
	"url-estraibile": { value: string },
}
