import React, { ReactElement, useCallback, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export interface ExportButtonProps {
	onExport: () => Promise<void>,
	disabled: boolean,
}

export function ExportButton({ onExport, disabled }: ExportButtonProps): ReactElement {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const exportFn = useCallback(async () => {
		setIsLoading(true);
		await onExport();
		setIsLoading(false);
	}, [onExport]);
	return <ABB.Button
		className="mx-1 export-button"
		text={t("Export")}
		icon="abb/download"
		sizeClass="small"
		type="primary-blue"
		isLoading={isLoading}
		disabled={disabled}
		onClick={() => exportFn()}
	/>
}
