import React, { ReactElement } from 'react';
import * as ABB from "@abb/abb-common-ux-react";

export interface SlideControlProps {
	onPageChange: (index: number) => void,
	totalPages: number,
	currentPage: number,
}

export function SlideControl({ onPageChange, currentPage, totalPages }: SlideControlProps): ReactElement {
	return <div className="mb-1">
		<button className="icon-button" disabled={currentPage <= 0} onClick={() => {
			onPageChange(currentPage - 1);
		}}><ABB.Icon sizeClass="medium" name="abb/caret-left" /></button>
		<button className="icon-button" disabled={currentPage >= totalPages - 1} onClick={() => {
			onPageChange(currentPage + 1);
		}}><ABB.Icon sizeClass="medium" name="abb/caret-right" /></button>
	</div>
}
