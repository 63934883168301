export function nor(...args: (boolean|null|undefined)[]): boolean|undefined {
	const someUndefined = args.some((value) => value === null || value === undefined);
	if (someUndefined) { return undefined; }
	return !args.reduce((value, current) => value || current, false);
}

export function and(...args: (boolean|null|undefined)[]): boolean|undefined {
	const someUndefined = args.some((value) => value === null || value === undefined);
	if (someUndefined) { return undefined; }
	return Boolean(args.reduce((value, current) => value && current, true));
}

export function not(arg: boolean|null|undefined): boolean|undefined {
	if (arg === null || arg === undefined) { return undefined; }
	return !arg;
}
