import React, { ReactElement } from 'react'
import { InfoPopup } from '../../components'
import { BaseSensorStatusProps } from '../SensorStatus/SensorStatus'
import { AppSections } from '../../enums'
import { BootstrapHelper, HealthIndexStatusHelper, SectionsHelper } from '../../helpers'
import * as ABB from '@abb/abb-common-ux-react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { useHistory } from 'react-router-dom'

export interface ConnectionSensorStatusProps extends BaseSensorStatusProps {
    localConnection: boolean;
    cloudConnection: boolean;
}

export function ConnectionSensorStatus(props: ConnectionSensorStatusProps): ReactElement {

    let { t } = useTranslation();
    let hist = useHistory();

    let color = HealthIndexStatusHelper.getColor(props.status);
    let href = SectionsHelper.createHref(AppSections.Settings, hist);

    let className = BootstrapHelper.getClassName(
        BootstrapHelper.getColValue(12, props.mobileCols),
        BootstrapHelper.getColValue(6, props.tabletCols),
        BootstrapHelper.getColValue(4, props.desktopCols));

    return (

        <div className={"card-wrapper connection-status-card " + className}>
            <div className="connection-status-content h-100 d-flex flex-row flex-wrap align-content-between">
                <div className="notification-container d-flex justify-content-center" >
                    {props.localConnection ? <ABB.Icon name="abb/connect" sizeClass="medium" color="color-status-green" /> : <ABB.Icon name="abb/disconnect" sizeClass="medium" color="grey" />}<span>{t('LocalConnection')}</span>
                    {props.cloudConnection ? <ABB.Icon name="abb/network_signal_strength-level_3" sizeClass="medium" color="color-status-green" /> : <ABB.Icon name="abb/wifi_network_disconnected" sizeClass="medium" color="grey" />}<span>{t('CloudConnection')}</span>
                </div>

                <div className={"status-container d-flex justify-content-between " + props.status}>
                    <div className="d-flex justify-content-start my-auto">
                        <ABB.Icon sizeClass="medium" className="mx-2" name="abb/broadcast" color={color} />
                        <span className="font-weight-bolder" style={{ fontSize: "16px" }}>{t('SensorStatus')}</span>
                        <InfoPopup info={props.info} />
                    </div>
                    <div className="d-flex justify-content-center my-auto">
                        <a href={href}>
                            <ABB.Icon sizeClass="medium" name="abb/right-arrow" color="color-status-blue" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}
