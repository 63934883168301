import React, { ReactElement, useEffect, useState } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { WidgetProps } from "../../models"
import { Card, UnknownIconWithTooltip } from "../../components"
import { useTranslation } from 'react-i18next';
import "./style.scss";
import { SectionLink } from '../../components/SectionLink/SectionLink';
import { getColor } from '../../helpers/css';
import { HealthIndexStatus } from '../../enums';
import { HealthIndexStatusHelper } from '../../helpers';

function OkOrFail({ isOk }: { isOk: boolean|undefined }): ReactElement {
	const { t } = useTranslation();
	return <div className="font-weight-bold text-uppercase" style={{ lineHeight: "16px" }}>
		{isOk && <><ABB.Icon sizeClass="small" className="align-middle" name="abb/check-mark-circle-2" color="color-status-green" /> <span className="align-middle d-inline-block">{t("Ok")}</span></>}
		{isOk === false && <><ABB.Icon sizeClass="small" className="align-middle" name="abb/error-circle-2" color="color-status-red" /> <span className="align-middle d-inline-block">{t("Fail")}</span></> }
		{isOk === undefined && <>
			<UnknownIconWithTooltip 
				size='small'
				tooltipText={t("OneOrMoreSamplesAreMissing")}
			/> <span className="align-middle d-inline-block">{t("Unknown")}</span>
		</> }
	</div>
}

export interface MotorUnitProps extends WidgetProps {
	angle: number,
	angleStatus: HealthIndexStatus|undefined,
	temperature: number,
	incrementalEncoder: boolean|undefined,
	encodersDifference: boolean|undefined,
	absoluteEncoder: boolean|undefined,
	motorWinding: boolean|undefined,
	highWarn: number,
	lowWarn: number,
	minTemperature: number,
	maxTemperature: number,
	minAngle: number,
	maxAngle: number,
	link: string,
	linkName: string,
	temperatureHighAlarm?: number,
	temperatureHighWarning?: number,
	unit: string,
	temperatureUnit: string,
}
export function MotorUnit(props: MotorUnitProps): ReactElement {
	const { t } = useTranslation();
	const status = props.angle <= props.lowWarn ? t("Open angle") : (props.angle >= props.highWarn ? t("Close angle") : t("Intermediate angle"));
	
	const [baseColor, setBaseColor] = useState(getColor('color-status-green'));

	useEffect(() => {
		// forcing red in case of alarm because the alarm status is, among other things,
		// used to identify an angle that is not within the boundaries, so the general
		// perceived status can't be "okay"
		if (props.angleStatus === HealthIndexStatus.Alarm) {
			setBaseColor(getColor('color-status-red'));
		} else {
			setBaseColor(getColor('color-status-green'));
		}
	}, [props.angleStatus]);
	return <Card
		desktopCols={props.desktopCols ?? 4}
		mobileCols={props.mobileCols ?? 12}
		tabletCols={props.tabletCols ?? 6}
		title={props.title}
		showProgressBar={props.fetching !== false}
		progressBar="top"
		installed={props.installed}
		disconnected={props.disconnected}
		comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
	>
		<div className="motor-unit-gauge-wrapper">
			{(props.angleStatus !== HealthIndexStatus.Ok && props.angleStatus !== undefined) && <ABB.WithTooltip><ABB.Icon 
				name={HealthIndexStatusHelper.getIcon(props.angleStatus)}
				sizeClass="medium"
				color={HealthIndexStatusHelper.getColor(props.angleStatus)}
				className="motor-unit-gauge-icon"
			/><ABB.Tooltip followCursor={true}>{props.angleStatus === HealthIndexStatus.Warning ? t("Angle in intermediate position") : t("Alarm")}</ABB.Tooltip></ABB.WithTooltip>}
			<ABB.Gauge
				className="motor-unit-radial-gauge"
				min={props.minAngle}
				max={props.maxAngle}
				value={props.angle}
				alarmColor={baseColor}
				color={getColor('color-status-red')}
				unit={`${status} [${props.unit}]`}
				type="radial"
				lowWarn={props.highWarn}
				lowAlarm={props.lowWarn}
				highAlarm={props.highWarn}
				limitStyle="parallel"
				decimals={0}
			/>
		</div>
		<div className="row mb-5">
			<div className="col-5 d-flex flex-column justify-content-between">
				<div>
					<div className="motor-unit-label mb-1">{t("Incremental Encoder")}</div>
					<OkOrFail isOk={props.incrementalEncoder} />
				</div>
				<div>
					<div className="motor-unit-label mb-1">{t("Absolute Encoder")}</div>
					<OkOrFail isOk={props.absoluteEncoder} />
				</div>
			</div>
			<div className="col-2">
				<svg viewBox="0 0 20 60" style={{ height: "100%" }}>
					<line x1="0" x2="10" y1="1" y2="1" stroke="gray" strokeWidth="1" />
					<line x1="10" x2="20" y1="16" y2="16" stroke="gray" strokeWidth="1" />
					<line x1="0" x2="10" y1="59" y2="59" stroke="gray" strokeWidth="1" />
					<line x1="10" x2="10" y1="1" y2="59" stroke="gray" strokeWidth="1" />
				</svg>
			</div>
			<div className="col-5 d-flex flex-column justify-content-between">
				<div>
					<div className="motor-unit-label mb-1">{t("Encoders difference")}</div>
					<OkOrFail isOk={props.encodersDifference} />
				</div>
				<div>
					<div className="motor-unit-label mb-1">{t("Driver Diagnostic")}</div>
					<OkOrFail isOk={props.motorWinding} />
				</div>
			</div>
		</div>
		<ABB.Gauge
			min={props.minTemperature}
			max={props.maxTemperature}
			value={props.temperature}
			unit={props.temperatureUnit}
			type="bar"
			highWarn={props.temperatureHighWarning}
			highAlarm={props.temperatureHighAlarm}
			label={t("Driver temperature")}
		/>
		<SectionLink className="mt-3" link={props.link} linkName={props.linkName} showIcon={true} showName={true} />
	</Card>
}
