import { getMultipleSamples } from '.';
import { DateTimeFormatHelper, LocalizationHelper } from '../../helpers';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { InputStatusProps } from '../../widgets';

export async function loadInputStatus(config: WidgetConfig, props: Partial<InputStatusProps>): Promise<InputStatusProps> {
	const inputStatus = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as InputStatusPropsConfig;
		const samples = await getMultipleSamples('boolean', propsConfig.inputs.flatMap((input) => [...input.active.map((e) => e.source), ...input.ready.map((e) => e.source)]));
		samples.forEach((s) => {
			inputStatus.lastUpdate = getGreaterDate(inputStatus.lastUpdate, DateTimeFormatHelper.toDate(s.timestamp_us));
		});
		let sampleIndex = 0;
		inputStatus.inputs = propsConfig.inputs.map((inputConfig) => {
			const active = samples.slice(sampleIndex, sampleIndex += inputConfig.active.length);
			const ready = samples.slice(sampleIndex, sampleIndex += inputConfig.ready.length);
			return {
				title: LocalizationHelper.getTranslation(inputConfig.title.i18n),
				active: active.reduce<boolean>((total, el, i) => (inputConfig.active[i].not?.value ? !el.value : Boolean(el.value)) && total, true),
				ready: ready.reduce<boolean>((total, el, i) => (inputConfig.ready[i].not?.value ? !el.value : Boolean(el.value)) && total, true),
			};
		});
	} catch (e) {
		inputStatus.exception = e;
	} finally {
		inputStatus.fetching = false;
	}
	return inputStatus as InputStatusProps;
}

export interface InputStatusItemPropsConfig {
	title: {
		i18n: string,
	},
	ready: {
		source: NamedSource,
		not?: {
			value: boolean
		}
	}[],
	active: {
		source: NamedSource,
		not?: {
			value: boolean
		}
	}[]
}
export interface InputStatusPropsConfig {
	title: {
		i18n: string,
	},
	link: {
		value: string,
	},
	linkName: {
		i18n: string,
	},
	inputs: InputStatusItemPropsConfig[],
}
