export enum ChartLegendPosition {
    top = "top",
    right = "right"
}
export interface ChartDataItem {
    //ms
    date: number;
    date_us: number;
    value?: number;
    value2?: number;
    value3?: number;
    value4?: number;
    value5?: number;
		value6?: number;
		value7?: number;
    color?: string;
		category?: string;
		icon?: any;
}

export type ChartValueProperty = 'value'|'value2'|'value3'|'value4'|'value5'|'value6'|'value7';

export interface ChartDataList {
    data: ChartDataItem[];
    lastValue: ChartDataItem;
}

export interface WaveformChartDataItem {
    t_us: number;
    value: number;
		value2?: number;
		value3?: number;
		value4?: number;
}
export interface WaveformChartDataList {
    data: WaveformChartDataItem[];
    date_us: number;
}
