
import React, { ReactElement } from 'react'
import * as Widgets from '../../widgets'
import { SectionProps } from '../../models'
import { Card } from '../../components'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useReadable } from '../../observables/hooks'
import { assetInfo$ } from '../../services/polling'



interface AssetInformationProps extends SectionProps {
}

function NumberBox(props: { header: string, number: string }) {
	return (
		<div className="number-box col-12 col-md-6">
			<span>{props.header}</span>
			<div className="number" >
				{props.number}
			</div>
		</div>

	);
}

function InfoBox(props: { header: string, info: string }) {
	return (
		<div className="info-box col-12 col-md-6">
			<span>{props.header}</span>
			<div className="info" >
				{props.info}
			</div>
		</div>
	);
}

export interface AssetInformationData {
	deviceType: string;
	modelAndRatings: string;
	serialNumber: string;
	orderNumber: string;
	modelAndRatingsInfo: string;
	standard: string;
	productionYear: string;
	classification: string;
	mass: string;
	shortCircuitBreakingCurrent: string;
	voltage: string;
	dCComponentOfRatedShortCircuit: string;
	lightningImpulseWithstandVoltage: string;
	cableChargingBreakingCurrent: string;
	powerFrequencyWithstandVoltage: string;
	operatingSequence: string;
	frequency: string;
	temperatureClass: string;
	normalCurrent: string;
	shuntReleaseOnVoltage: string;
	shortTimeWithstandCurrent: string;
	shuntReleaseOffVoltage: string;
	durationOfShortCircuit: string;
	chargingMotorVoltage: string;
	assetLabel: string,
	installationDate: Date|null,
	manufacturingDate: Date|null,
	pgApplication: string,
	warrantyStatus: string,
// only on VD4AF:
	auxiliaryVoltage?: string,
	commissioningDone?: string,
}

export function AssetInformation(props: AssetInformationProps): ReactElement {
	let { t } = useTranslation();
	const info = useReadable(assetInfo$);

	return (
		<>
			<div className="asset-information row">
				<div className="col-12 col-lg-2">
					<Widgets.ImageWidget src="./images/dashboardDeviceImage.png" mobileCols={12} desktopCols={12} tabletCols={12} />
				</div>
				<div className="col-12 col-lg-10" >
					<Card hideTitle={true} mobileCols={12} tabletCols={12} desktopCols={12}>
						<div className="title-container">
							<h2 className="ABB_CommonUX_Heading__root ABB_CommonUX_Heading__light ABB_CommonUX_Heading__withCursor">{info.deviceType}</h2>
							<p className="sub-title">{t('ModelAndRatings')}</p>
							<h4 className="font-weight-bold">{info.modelAndRatings}</h4>
						</div>
						<div className="info-container bordered d-flex flex-wrap">
							<NumberBox header={t('SerialNumber')} number={info.serialNumber} />
							<NumberBox header={t('OrderNumber')} number={info.orderNumber} />

							<InfoBox header={info.modelAndRatings} info={info.modelAndRatingsInfo} />
							<InfoBox header={t("Standard")} info={info.standard} />

							<InfoBox header={t('ProductionYear')} info={info.productionYear} />
							<InfoBox header={t('Classification')} info={info.classification} />
						</div>

						<div className="info-container d-flex flex-wrap" style={{ paddingTop: "10px" }}>
							<InfoBox header={t('Mass')} info={info.mass} />
							<InfoBox header={t('ShortCircuitBreakingCurrent')} info={info.shortCircuitBreakingCurrent} />

							<InfoBox header={t('Voltage')} info={info.voltage} />
							<InfoBox header={t('DCComponentOfRatedShortCircuit')} info={info.dCComponentOfRatedShortCircuit} />

							<InfoBox header={t('LightningImpulseWithstandVoltage')} info={info.lightningImpulseWithstandVoltage} />
							<InfoBox header={t('CableChargingBreakingCurrent')} info={info.cableChargingBreakingCurrent} />

							<InfoBox header={t('PowerFrequencyWithstandVoltage')} info={info.powerFrequencyWithstandVoltage} />
							<InfoBox header={t('OperatingSequence')} info={info.operatingSequence} />

							<InfoBox header={t('Frequency')} info={info.frequency} />
							<InfoBox header={t('TemperatureClass')} info={info.temperatureClass} />

							<InfoBox header={t('NormalCurrent')} info={info.normalCurrent} />
							<InfoBox header={t('ShuntReleaseOnVoltage')} info={info.shuntReleaseOnVoltage} />

							<InfoBox header={t('ShortTimeWithstandCurrent')} info={info.shortTimeWithstandCurrent} />
							<InfoBox header={t('ShuntReleaseOffVoltage')} info={info.shuntReleaseOffVoltage} />

							<InfoBox header={t('DurationOfShortCircuit')} info={info.durationOfShortCircuit} />
							<InfoBox header={t('ChargingMotorVoltage')} info={info.chargingMotorVoltage} />
						</div>
					</Card>
				</div>
			</div>

		</>
	)
}
