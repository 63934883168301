/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import * as Widgets from '../index'
import { useReadable } from '../../observables/hooks';
import { WidgetConfig } from '../../pages/DynamicPage';
import { WidgetProps } from '../../models/widget/WidgetProps';
import { Readable } from '../../observables/types';
import { WidgetType } from '../../enums';
import { getWidgetProps } from '../../services/widget';
import { getDynamicProps, isPoller } from '../../services/polling/startup';
import { PollingWritable } from '../../utils/polling-subject';
import { startPolling$ } from '../../services/config';
import { useComputed } from '../../react-extra/hooks';

export interface WidgetSelectorProps {
	widgetConfig: WidgetConfig,
}

export function ReadableToProps<T>(props: { readable: Readable<T> | undefined, defaultProps: T, children: (props: T) => React.ReactElement }) {
	if (!props.readable) {
		return (<>{props.children(props.defaultProps)}</>);
	}
	const currentValue = useReadable(props.readable);
	return (
		<>
			{props.children(currentValue)}
		</>
	);
}

export function PollerToProps<T>(props: { readable: PollingWritable<T> | undefined, defaultProps: T, children: (props: T) => React.ReactElement, debug?: string }) {
	if (!props.readable) {
		return (<>{props.children(props.defaultProps)}</>);
	}
	const currentValue = useReadable(props.readable);
	if (!currentValue) {
		props.readable.fetch();
	}
	if (!currentValue) {
		return (
			<Widgets.Placeholder { ...props.defaultProps } />
		);
	}
	return (
		<>
			{props.children(currentValue)}
		</>
	);
}

const componentByWidgetType: { [key in WidgetType]?: (props: any) => React.ReactElement } = {
	[WidgetType.Alerts]: Widgets.Alerts,
	[WidgetType.BarChart]: Widgets.BarChart,
	[WidgetType.BubbleChart]: Widgets.BubbleChart,
	[WidgetType.DonutChart]: Widgets.DonutChart,
	[WidgetType.ConnectionSensorStatus]: Widgets.ConnectionSensorStatus,
	[WidgetType.Status]: Widgets.FamilyStatus,
	[WidgetType.FamilyStatus]: Widgets.FamilyStatus,
	[WidgetType.GaugeInfo]: Widgets.GaugeInfo,
	[WidgetType.Gauges]: Widgets.Gauges,
	[WidgetType.Image]: Widgets.ImageWidget,
	[WidgetType.ImageStatus]: Widgets.ImageStatus,
	[WidgetType.SingleLineChart]: Widgets.SingleLineChart,
	[WidgetType.MultiLineChart]: Widgets.MultiLineChart,
	[WidgetType.PhaseSensor]: Widgets.PhaseSensor,
	[WidgetType.SectionStatus]: Widgets.SectionStatus,
	[WidgetType.SensorsOverview]: Widgets.SensorsOverview,
	[WidgetType.Slider]: Widgets.Slider,
	[WidgetType.SystemInfo]: Widgets.SystemInfo,
	[WidgetType.WaveformChart]: Widgets.WaveformChart,
	[WidgetType.MechanicalSensor]: Widgets.MechanicalSensor,
	[WidgetType.Filter]: Widgets.ExternalChartScaleSelector,
	[WidgetType.CBStatus]: Widgets.CBStatus,
	[WidgetType.CumulatedWorkload]: Widgets.CumulatedWorkload,
	[WidgetType.InputStatus]: Widgets.InputStatus,
	[WidgetType.HardwareIntegrity]: Widgets.HardwareIntegrity,
	[WidgetType.HealthIndexProgress]: Widgets.HealthIndexProgress,
	[WidgetType.SyncStatus]: Widgets.SyncStatus,
	[WidgetType.MotorUnit]: Widgets.MotorUnit,
	[WidgetType.CapacitorStatus]: Widgets.CapacitorStatus,
	[WidgetType.ImageStatusWithOverlays]: Widgets.ImageStatusWithOverlays,
	[WidgetType.ActuationEvents]: Widgets.ActuationEvents,
	[WidgetType.SectionTitle]: Widgets.SectionTitle,
	[WidgetType.BinaryInputDiagnostic]: Widgets.BinaryInputDiagnostic,
	[WidgetType.EventLogs]: Widgets.EventLogs,
	[WidgetType.StatusDetailsWithIcon]: Widgets.StatusDetailsWithIcon,
	[WidgetType.BarChartWithChartTooltip]: Widgets.BarChartWithChartTooltip,
};



export function WidgetSelector({ widgetConfig: config }: WidgetSelectorProps) {
	const commonProps: WidgetProps = {
		desktopCols: config.grid.desktop,
		tabletCols: config.grid.tablet,
		mobileCols: config.grid.mobile,
	}
	const specificProps = useComputed(() => getWidgetProps(config), [config]);
	const props = {
		...commonProps,
		...specificProps,
	};
	const startPolling = useReadable(startPolling$);
	const Component = componentByWidgetType[config.component];
	if (!Component) {
		props.title = config.component + " not implemented";
		return <Widgets.Placeholder {...props} />
	} else if (!startPolling) {
		return <Widgets.Placeholder { ...props } />
	} else {
		const props$ = getDynamicProps(config, props);
		if (isPoller(props$)) {
			return (
				<PollerToProps readable={props$} defaultProps={props} debug={config.component}>
					{(currentProps) => <Component {...currentProps}></Component>}
				</PollerToProps>
			);
		}
		return (
			<ReadableToProps readable={props$} defaultProps={props}>
				{(currentProps) => <Component {...currentProps}></Component>}
			</ReadableToProps>
		);
	}
}
