import { useEffect } from 'react'
import * as ABB from '@abb/abb-common-ux-react';
import { Card } from '../../components';
import { apiService } from '../../services/api';
import { scenarios$, selectedScenario$ } from '../../services/demo';
import { useTranslation } from 'react-i18next';
import { fireNotification } from '../../toasts';
import { useReadable } from '../../observables/hooks';

export const ScenarioSelection: React.FC = () => {
	const scenarios = useReadable(scenarios$);
	const { t } = useTranslation();
	useEffect(() => {
		apiService.getScenarios()
			.then((s) => scenarios$.next(s))
			.catch((err) => {
				console.error(err);
				scenarios$.next([]);
				fireNotification({
					severity: 'alarm',
					text: t('CannotLoadScenarios'),
					type: 'banner',
				});
			});
	}, [t]);

	useEffect(() => {
		if (scenarios && scenarios.length === 1) {
			selectedScenario$.next(scenarios[0].name);
		}
	}, [scenarios]);
	return <>
		<ABB.AbbBar productName={t("DemoLandingTopBarText", { product: process.env.REACT_APP_PRODUCT })} />
		<div className='mb-2' style={{ overflowY: 'auto' }}><div className='container'>
			<h2 className='mt-2 mb-3 text-center'>{t("ChooseAScenario")}</h2>
			{scenarios ? <>
				{(t("DemoLandingWelcomeMessage", { returnObjects: true }) as string[]).map((line, i) => <p key={i} className='mb-1'>{line}</p>)}
				<div className='row mt-3'>{scenarios.map((scenario, i) => <Card 
					key={i} 
					desktopCols={4} 
					tabletCols={6} 
					mobileCols={16} 
					title={scenario.name}
				>
					<p>{scenario.description}</p>
					<div className='text-center'>
						<ABB.Button text={t("StartScenario")} type='primary-blue' sizeClass='small' onClick={() => selectedScenario$.next(scenario.name)} />
					</div>
				</Card>)}</div>
			</> : <div className='text-center' style={{
				position: 'fixed',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)'
			}}><ABB.LoadingIndicator
					determinate={false}
					className='mx-auto'
					type="radial"
					color="blue"
				/><p>{t("LoadingScenarios")}...</p></div>}
		</div></div></>
}
