import { FetchError } from "../../utils/fetch";
import { logout, removeCachedUser } from "../access";
import { AuthenticatedUser, SaveUser, token$, User, user$ } from "../user";
import * as Models from "../../models";
import * as Enums from "../../enums";
import * as Helpers from "../../helpers";
import { BoardFirmwareInfo } from "../../models/api/BoardFirmwareInfo";
import {
	AccessPointNetworkSettings,
	BoardNetworkConfig,
	EthernetNetworkSettings,
	SaveEthernetNetworkSettings,
} from "../../models/api/BoardNetworkConfig";
import { HealthIndexStatus } from "../../enums";
import { roundNumber } from "../../utils/number";
import { devicesMetadata$ } from "../../services/device";
import { changePasswordDialogIsShown$ } from "../../App/layout-state";
import {
	SaveThermalConfiguration,
	ThermalConfiguration,
} from "../../models/api/ThermalConfiguration";
import { ProductInfoNameplate } from "../../wizards/StartupWizard/ProductInfo";
import { isDemo, Scenario } from '../demo';

async function fetchIfToken(
	url: string,
	config?: Record<string, any>
): Promise<Response> {
	if (!token$.getValue() && !url.includes("/views/")) {
		logout();
		throw new FetchError(
			new Response(null, {
				status: 401,
			})
		);
	}
	return fetch(url, config);
}

function getRandomInt(min: number, max: number): number {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //Il max è escluso e il min è incluso
}

function getRandomNearValue(proveValue: number, min: number, max: number) {
	return getRandomInt(
		Math.max(min, proveValue - 10),
		Math.min(proveValue + 10, max)
	);
}

function getBaseUrl(): string {
	return "./mockApi";
}

export async function getDocuments(): Promise<Models.Document[]> {
	let resp = await fetchIfToken(
		`${getBaseUrl()}/${process.env.REACT_APP_PRODUCT}/documents.json`
	);
	await Helpers.delay(1);
	let data: Models.DocumentsConfig = await resp.json();
	return data.documents.map(
		(d) =>
			new Models.Document(
				d.type,
				d.id,
				d.fileName,
				d.title ? d.title : "",
				d.date_us,
				d.url,
				d.description,
				d.size,
				d.fileType
			)
	);
}
//TO DO:
//HANDLE THIS FOR MOCK SERVICE
export async function downloadDocument(): Promise<Blob> {
	return new Blob();
}

export async function uploadDocument(
	file: File,
	doc_type: Enums.DocumentType,
	file_name: string,
	title: string,
	description: string
): Promise<Models.Document> {
	let doc = new Models.Document(
		Enums.DocumentType.Uploaded,
		"id",
		"filename",
		"title",
		12345,
		"url"
	);
	return doc;
}

export async function updateDocumentData(
	id: string,
	type: Enums.DocumentType,
	title: string,
	description?: string
): Promise<void> {
	console.debug(
		`updateDocumentData | ${id} | ${type} | ${title} | ${description}`
	);
	await Helpers.delay(2);
}

export async function deleteDocument(
	doc_type: Enums.DocumentType,
	id: string
): Promise<void> {
	await Helpers.delay(2);
}

export async function getDevices(): Promise<Models.DevicesResponse> {
	let resp = await fetchIfToken(
		`${getBaseUrl()}/${process.env.REACT_APP_PRODUCT}/devices.json`
	);
	let data: Models.DevicesResponse = await resp.json();
	return data;
}

export async function getDevicesMetadata(): Promise<Models.DevicesMetadataResponse> {
	let resp = await fetchIfToken(
		`${getBaseUrl()}/${process.env.REACT_APP_PRODUCT}/metadata.json`
	);
	let data: Models.DevicesMetadataResponse = await resp.json();
	return data;
}

//dateTo - start
// discendente (più recenti prima)
export async function getSample(
	deviceModel: string,
	deviceId: string,
	sample: string,
	size?: number,
	fromId?: number,
	dateFrom?: Date,
	dateTo?: Date
): Promise<Models.SampleResponse> {
	await Helpers.delay(1);

	if (!size) size = 1;

	let data: Models.ApiDataItem[] = [];

	let value = getRandomInt(0, 100);

	if (dateFrom && dateTo) {
		let tmpDate: Date = new Date(dateFrom.getTime());
		let TimeScale: Enums.ChartTimeRange = manageChartTimeScale(
			dateFrom,
			dateTo
		);
		size = manageSampleSizeForChart(dateFrom, dateTo, TimeScale);

		for (let index = 0; index < size; index++) {
			let tick = tmpDate.valueOf() * 1000;
			data.push({
				timestamp_us: tick,
				id: index,
				value: (value = getRandomNearValue(value, 0, 100)),
			});
			manageTimeIncrementForChart(tmpDate, TimeScale);
		}
	} else {
		for (let index = 0; index < size; index++) {
			let tick = (new Date().valueOf() + index * 1000) * 1000;
			data.push({
				timestamp_us: tick,
				id: index,
				value: (value = getRandomNearValue(value, 0, 100)),
			});
		}
	}

	let resp: Models.SampleResponse = {
		samples: data,
		total: size,
	};

	return resp;
}

export async function getBooleanSample(
	deviceModel: string,
	deviceId: string,
	sample: string,
	size: number = 1,
	start?: number,
	dateFrom?: Date,
	dateTo?: Date
): Promise<Models.GenericSampleResponse<boolean>> {
	await Helpers.delay(1);

	const data: Models.GenericApiDataItem<boolean>[] = [];

	if (dateFrom && dateTo) {
		const tmpDate: Date = new Date(dateFrom.getTime());
		const timeScale: Enums.ChartTimeRange = manageChartTimeScale(
			dateFrom,
			dateTo
		);
		size = manageSampleSizeForChart(dateFrom, dateTo, timeScale);

		for (let index = 0; index < size; index++) {
			let tick = tmpDate.valueOf() * 1000;
			data.push({
				timestamp_us: tick,
				id: index,
				value: Math.random() > 0.5 ? true : false,
			});
			manageTimeIncrementForChart(tmpDate, timeScale);
		}
	} else {
		for (let index = 0; index < size; index++) {
			let tick = (new Date().valueOf() + index * 1000) * 1000;
			data.push({
				timestamp_us: tick,
				id: index,
				value: Math.random() > 0.5 ? true : false,
			});
		}
	}

	const resp: Models.GenericSampleResponse<boolean> = {
		samples: data,
		total: size,
	};

	return resp;
}

export async function getNumberSample(
	deviceModel: string,
	deviceId: string,
	sample: string,
	size: number = 1,
	start?: number,
	dateFrom?: Date,
	dateTo?: Date,
	boundaries?: { min?: number; max?: number; step?: number }
): Promise<Models.GenericSampleResponse<number>> {
	await Helpers.delay(1);

	const data: Models.GenericApiDataItem<number>[] = [];

	const step = boundaries?.step ?? 1;
	let value =
		getRandomInt(
			(boundaries?.min ?? 0) / step,
			(boundaries?.max ?? 100) / step
		) * step;

	if (dateFrom && dateTo) {
		const tmpDate: Date = new Date(dateFrom.getTime());
		const TimeScale: Enums.ChartTimeRange = manageChartTimeScale(
			dateFrom,
			dateTo
		);
		size = manageSampleSizeForChart(dateFrom, dateTo, TimeScale);

		for (let index = 0; index < size; index++) {
			let tick = tmpDate.valueOf() * 1000;
			data.push({
				timestamp_us: tick,
				id: index,
				value: (value =
					getRandomNearValue(
						value / step,
						(boundaries?.min ?? 0) / step,
						(boundaries?.max ?? 100) / step
					) * step),
			});
			manageTimeIncrementForChart(tmpDate, TimeScale);
		}
	} else {
		for (let index = 0; index < size; index++) {
			const tick = (new Date().valueOf() + index * 1000) * 1000;
			data.push({
				timestamp_us: tick,
				id: index,
				value: (value =
					getRandomNearValue(
						value / step,
						(boundaries?.min ?? 0) / step,
						(boundaries?.max ?? 100) / step
					) * step),
			});
		}
	}

	const resp: Models.GenericSampleResponse<number> = {
		samples: data,
		total: size,
	};

	return resp;
}

export async function getHealthIndexSample(
	deviceModel: string,
	deviceId: string,
	sample: string,
	size: number = 1,
	start?: number,
	dateFrom?: Date,
	dateTo?: Date
): Promise<Models.GenericSampleResponse<HealthIndexStatus>> {
	await Helpers.delay(1);

	const data: Models.GenericApiDataItem<HealthIndexStatus>[] = [];

	if (dateFrom && dateTo) {
		const tmpDate: Date = new Date(dateFrom.getTime());
		const timeScale: Enums.ChartTimeRange = manageChartTimeScale(
			dateFrom,
			dateTo
		);
		size = manageSampleSizeForChart(dateFrom, dateTo, timeScale);

		for (let index = 0; index < size; index++) {
			let tick = tmpDate.valueOf() * 1000;
			const random = Math.random();
			let value = HealthIndexStatus.Ok;
			if (random > 1 / 3 && random < 2 / 3) {
				value = HealthIndexStatus.Warning;
			} else if (random >= 2 / 3) {
				value = HealthIndexStatus.Alarm;
			}
			data.push({
				timestamp_us: tick,
				id: index,
				value,
			});
			manageTimeIncrementForChart(tmpDate, timeScale);
		}
	} else {
		for (let index = 0; index < size; index++) {
			let tick = (new Date().valueOf() + index * 1000) * 1000;
			const random = Math.random();
			let value = HealthIndexStatus.Ok;
			if (random > 1 / 3 && random < 2 / 3) {
				value = HealthIndexStatus.Warning;
			} else if (random >= 2 / 3) {
				value = HealthIndexStatus.Alarm;
			}
			data.push({
				timestamp_us: tick,
				id: index,
				value,
			});
		}
	}

	const resp: Models.GenericSampleResponse<HealthIndexStatus> = {
		samples: data,
		total: size,
	};

	return resp;
}

export async function getLastWaveform(
	deviceModel: string,
	deviceId: string,
	name: string,
	type: Models.WaveformOperation,
	dateFrom?: number,
	groupId?: number
): Promise<Models.WaveformDataItem | undefined> {
	console.debug(
		`getLastWaveform | ${deviceModel} | ${deviceId} | ${name} | ${type} | ${dateFrom}`
	);
	await Helpers.delay(1);

	if (name === "angular") {
		let resp = await fetchIfToken(
			`${getBaseUrl()}/${process.env.REACT_APP_PRODUCT}/angular.json`
		);
		let data: Models.WaveformsResponse = await resp.json();

		if (data && data.waveforms && data.waveforms.length > 0)
			return data.waveforms[0];
		else return undefined;
	}

	let samples: string[] = [];

	let value = getRandomInt(0, 100);

	for (let index = 0; index < 2000; index++) {
		value = getRandomNearValue(value, 0, 100);
		samples.push(value.toString());
	}

	let data: Models.WaveformDataItem = {
		id: 0,
		operation:
			type !== Models.WaveformOperation.last
				? type
				: value % 2
				? Models.WaveformOperation.closing
				: Models.WaveformOperation.opening,
		samples: samples,
		sampling_rate_us: 4000,
		timestamp_us: new Date().valueOf() * 1000,
	};
	return data;
}

export async function getWaveforms(deviceModel: string, deviceId: string, name: string, type: Models.WaveformOperation, listOnly: true, size?: number, dateFrom?: number, dateTo?: number, groupId?: number): Promise<Models.WaveformDataListOnlyItem[]>;
export async function getWaveforms(deviceModel: string, deviceId: string, name: string, type: Models.WaveformOperation, listOnly?: false, size?: number, dateFrom?: number, dateTo?: number, groupId?: number): Promise<Models.WaveformDataItem[]>;
export async function getWaveforms(deviceModel: string, deviceId: string, name: string, type: Models.WaveformOperation, listOnly?: boolean, size?: number, dateFrom?: number, dateTo?: number, groupId?: number): Promise<Models.WaveformDataItem[] | Models.WaveformDataListOnlyItem[]> {
	console.debug(
		`getWaveforms | ${deviceModel} | ${deviceId} | ${name} | ${type} | ${dateFrom} | ${dateTo} | listOnly: ${listOnly}`
	);
	await Helpers.delay(1);
	let waveforms = size ?? Math.ceil(Math.random() * 30);
	const res = new Array(waveforms);
	for (let i = 0; i < waveforms; i++) {
		res[i] = {
			id: i + 1,
			operation: type !== Models.WaveformOperation.last ? type : (Math.random() > 0.5 ? Models.WaveformOperation.closing : Models.WaveformOperation.opening),
			sampling_rate_us: 4000,
			timestamp_us: (dateTo ?? (new Date().getTime() * 1000)) - (waveforms - i) * 10000000, 
		}
		if (!listOnly) {
			let value = getRandomInt(0, 100);
			const samples = new Array(100);
			for (let s = 0; s < 100; s++) {
				samples[s] = value;
				value = getRandomNearValue(value, 0, 100)
			}
			res[i].samples = samples;
		}
	}
	return res;
}

export async function getEvent(
	deviceModel: string,
	deviceId: string,
	event: string,
	size?: number,
	start?: number,
	dateFrom?: Date,
	dateTo?: Date
): Promise<Models.EventsListResponse> {
	await Helpers.delay(1);

	if (!size) size = 1;
	const metadata = devicesMetadata$.getValue();
	let data: Models.EventsBaseDataItem[] = [];
	if (dateFrom && dateTo) {
		let TimeScale: Enums.ChartTimeRange = manageChartTimeScale(
			dateFrom,
			dateTo
		);
		size = manageSampleSizeForChart(dateFrom, dateTo, TimeScale);
		if (event === "ALL") {
			const allEvents = Object.keys(metadata.models[deviceModel]!.events || {});
			allEvents.forEach((eventName) => {
				const states =
					metadata.models[deviceModel]!.events?.[eventName].states ?? {};
				for (let index = 0; index < size!; index++) {
					let tick = dateFrom.valueOf() * 1000;
					data.push({
						timestamp_us: tick,
						device_id: deviceId,
						model: deviceModel,
						name: eventName,
						state: Number(
							Object.keys(states)[getRandomInt(0, Object.keys(states).length)]
						),
					});
					manageTimeIncrementForChart(dateFrom, TimeScale);
				}
			});
		} else {
			for (let index = 0; index < size; index++) {
				const states =
					metadata.models[deviceModel]!.events?.[event].states ?? {};
				let tick = dateFrom.valueOf() * 1000;
				data.push({
					timestamp_us: tick,
					device_id: deviceId,
					model: deviceModel,
					name: event,
					state: Number(
						Object.keys(states)[getRandomInt(0, Object.keys(states).length)]
					),
				});
				manageTimeIncrementForChart(dateFrom, TimeScale);
			}
		}
	} else {
		if (event === "ALL") {
			const allEvents = Object.keys(
				devicesMetadata$.getValue().models[deviceModel]!.events || {}
			);
			allEvents.forEach((eventName) => {
				for (let index = 0; index < size!; index++) {
					const states =
						metadata.models[deviceModel]!.events?.[eventName].states ?? {};
					let tick = (new Date().valueOf() + index * 1000) * 1000;
					data.push({
						timestamp_us: tick,
						device_id: deviceId,
						model: deviceModel,
						name: eventName,
						state: Number(
							Object.keys(states)[getRandomInt(0, Object.keys(states).length)]
						),
					});
				}
			});
		} else {
			for (let index = 0; index < size; index++) {
				const states =
					metadata.models[deviceModel]!.events?.[event].states ?? {};
				let tick = (new Date().valueOf() + index * 1000) * 1000;
				data.push({
					timestamp_us: tick,
					device_id: deviceId,
					model: deviceModel,
					name: event,
					state: Number(
						Object.keys(states)[getRandomInt(0, Object.keys(states).length)]
					),
				});
			}
		}
	}
	let resp: Models.EventsListResponse = {
		events: data,
	};

	return resp;
}

export async function getEventsList(): Promise<Models.EventsListResponse> {
	let resp = await fetchIfToken(
		`${getBaseUrl()}/${process.env.REACT_APP_PRODUCT}/eventsList.json`
	);
	let data: Models.EventsListResponse = await resp.json();

	const now_us = new Date().valueOf() * 1000;
	const two_days_ago_us = now_us - 2 * 24 * 3600 * 1000 * 1000;

	for (const event of data.events) {
		event.timestamp_us = getRandomInt(two_days_ago_us, now_us);
	}

	return data;
}

export async function getEventsHistory(
	ids: number[],
	dateFrom?: Date
): Promise<Models.EventsHistoryResponse> {
	let url = `${getBaseUrl()}/${
		process.env.REACT_APP_PRODUCT
	}/eventsHistory.json`;

	let resp = await fetchIfToken(url);
	let data: Models.EventsHistoryResponse = await resp.json();

	//ToDo: è necessario popolare la lista con gli id richiesti?

	const now_us = new Date().valueOf() * 1000;
	const two_days_ago_us = now_us - 2 * 24 * 3600 * 1000 * 1000;

	let ret: Models.EventsHistoryResponse = {
		total: data.total,
		events: data.events.map((m) => {
			return {
				device_id: m.device_id,
				name: m.name,
				model: m.model,
				state: m.state,
				timestamp_us: getRandomInt(two_days_ago_us, now_us),
				related_events: m.related_events,
			};
		}),
	};

	return ret;
}

export async function getValues(): Promise<Models.ValuesResponse> {
	let resp = await fetchIfToken(
		`${getBaseUrl()}/${process.env.REACT_APP_PRODUCT}/values.json`
	);
	let data = await resp.json();
	let ret: Models.ValuesResponse = {
		values: data,
	};
	return ret;
}

function manageChartTimeScale(
	dateFrom: Date,
	dateTo: Date
): Enums.ChartTimeRange {
	let M_sample: number = 0;
	let D_sample: number = 0;
	let result: Date;
	let TimeScale: Enums.ChartTimeRange;

	result = new Date(dateTo.getTime() - dateFrom.getTime());
	M_sample = result.getUTCMonth();
	D_sample = result.getUTCDate() - 1;
	TimeScale =
		M_sample > 0
			? Enums.ChartTimeRange.Month
			: D_sample > 1
			? Enums.ChartTimeRange.Week
			: D_sample === 1
			? Enums.ChartTimeRange.Day
			: Enums.ChartTimeRange.Hour;
	return TimeScale;
}

function manageSampleSizeForChart(
	dateFrom: Date,
	dateTo: Date,
	TimeScale: Enums.ChartTimeRange
) {
	let tmpDate: Date = new Date(dateFrom.getTime());
	let size: number = 0;

	switch (TimeScale) {
		case Enums.ChartTimeRange.Week:
			size =
				Helpers.DateTimeFormatHelper.getDaysBetween(dateTo, dateFrom) * 2 + 1;
			break;
		case Enums.ChartTimeRange.Day:
			size = Helpers.DateTimeFormatHelper.getHoursBetween(dateTo, tmpDate) + 1;
			break;
		case Enums.ChartTimeRange.Hour:
			size = 10;
			break;
		default:
			size = Helpers.DateTimeFormatHelper.getDaysBetween(dateTo, tmpDate) + 1;
			break;
	}
	return size;
}

function manageTimeIncrementForChart(
	dateFrom: Date,
	TimeScale: Enums.ChartTimeRange
) {
	switch (TimeScale) {
		case Enums.ChartTimeRange.Month:
			dateFrom.setDate(dateFrom.getDate() + 1);
			break;
		case Enums.ChartTimeRange.Week:
			dateFrom.setHours(dateFrom.getHours() + 12);
			break;
		case Enums.ChartTimeRange.Day:
			dateFrom.setHours(dateFrom.getHours() + 1);
			break;
		case Enums.ChartTimeRange.Hour:
			dateFrom.setMinutes(dateFrom.getMinutes() + 6);
			break;
	}
}

export async function getMultipleBooleanSamples(
	devices: Record<string, Record<string, Models.AggregationDevice>>
): Promise<Models.GenericAggregationResponse<boolean | null>> {
	await Helpers.delay(1);
	let date_us = toEpoch_us(new Date());

	let response: Models.GenericAggregationResponse<boolean | null> = {
		devices: {},
	};

	for (const deviceModel of Object.keys(devices)) {
		let model = devices[deviceModel];

		let respModel = response.devices[deviceModel];
		if (!respModel) respModel = response.devices[deviceModel] = {};

		for (const deviceId of Object.keys(model)) {
			let device = model[deviceId];

			let respDevice = respModel[deviceId];
			if (!respDevice) {
				respDevice = respModel[deviceId] = {
					events: {},
					samples: {},
				};
			}
			for (const sampleName of device.samples) {
				let respSamples = respDevice.samples[sampleName];
				if (!respSamples) {
					respSamples = respDevice.samples[sampleName] = [];
				}
				respSamples.push({
					timestamp_us: date_us,
					id: 0,
					value: Math.random() > 0.5 ? true : false,
					//value: debugvar?getRandomInt(0, index%2>0? 1000 : -10) :  getRandomInt(0, index%2>0? 100 : -100)
				});
			}
		}
	}

	return response;
}

export async function getMultipleHealthIndexSamples(
	devices: Record<string, Record<string, Models.AggregationDevice>>
): Promise<Models.GenericAggregationResponse<HealthIndexStatus | null>> {
	await Helpers.delay(1);
	let date_us = toEpoch_us(new Date());

	let response: Models.GenericAggregationResponse<HealthIndexStatus | null> = {
		devices: {},
	};

	for (const deviceModel of Object.keys(devices)) {
		let model = devices[deviceModel];

		let respModel = response.devices[deviceModel];
		if (!respModel) respModel = response.devices[deviceModel] = {};

		for (const deviceId of Object.keys(model)) {
			let device = model[deviceId];

			let respDevice = respModel[deviceId];
			if (!respDevice) {
				respDevice = respModel[deviceId] = {
					events: {},
					samples: {},
				};
			}
			for (const sampleName of device.samples) {
				let respSamples = respDevice.samples[sampleName];
				if (!respSamples) {
					respSamples = respDevice.samples[sampleName] = [];
				}
				const random = Math.random();
				let value = HealthIndexStatus.Ok;
				if (random > 1 / 3 && random < 2 / 3) {
					value = HealthIndexStatus.Warning;
				} else if (random >= 2 / 3) {
					value = HealthIndexStatus.Alarm;
				}
				respSamples.push({
					timestamp_us: date_us,
					id: 0,
					value,
					//value: debugvar?getRandomInt(0, index%2>0? 1000 : -10) :  getRandomInt(0, index%2>0? 100 : -100)
				});
			}
		}
	}

	return response;
}

export async function getMultipleNumericSamples(
	devices: Record<string, Record<string, Models.AggregationDevice>>,
	boundaries?: Record<string, Record<string, Record<string, any>>> | undefined
): Promise<Models.GenericAggregationResponse<string | number | null>> {
	await Helpers.delay(1);
	let date_us = toEpoch_us(new Date());

	let response: Models.GenericAggregationResponse<string | number | null> = {
		devices: {},
	};

	for (const deviceModel of Object.keys(devices)) {
		let model = devices[deviceModel];

		let respModel = response.devices[deviceModel];
		if (!respModel) respModel = response.devices[deviceModel] = {};

		for (const deviceId of Object.keys(model)) {
			let device = model[deviceId];

			let respDevice = respModel[deviceId];
			if (!respDevice) {
				respDevice = respModel[deviceId] = {
					events: {},
					samples: {},
				};
			}
			for (const sampleName of device.samples) {
				let respSamples = respDevice.samples[sampleName];
				if (!respSamples) {
					respSamples = respDevice.samples[sampleName] = [];
				}
				let value;
				if (boundaries && boundaries[deviceModel]?.[deviceId]?.[sampleName]) {
					const boundary = boundaries[deviceModel]?.[deviceId]?.[sampleName];
					const random =
						Math.random() * ((boundary.max ?? 100) - (boundary.min ?? 0)) +
						(boundary.min ?? 0);
					value = roundNumber(random, boundary.step);
				} else {
					value = getRandomInt(0, 100);
				}
				respSamples.push({
					timestamp_us: date_us,
					id: 0,
					value,
					//value: debugvar?getRandomInt(0, index%2>0? 1000 : -10) :  getRandomInt(0, index%2>0? 100 : -100)
				});
			}
		}
	}

	return response;
}

export async function getDataAggregation(
	devices: Record<string, Record<string, Models.AggregationDevice>>,
	size?: number,
	dateFrom?: Date,
	dateTo?: Date,
	boundaries?: { min?: number; max?: number }
): Promise<Models.AggregationResponse> {
	await Helpers.delay(1);

	///ToDo gestire date

	let increment = 1000 * 1000 * 3600; // 1h

	if (!size && (!dateFrom || !dateTo)) {
		size = 1;
	} else if (!size) {
		size = Math.floor(
			(toEpoch_us(dateTo!) - toEpoch_us(dateFrom!)) / increment
		);
	}

	let timestamps: number[] = [];

	let date_us = dateTo ? toEpoch_us(dateTo) : toEpoch_us(new Date());

	for (let index = size - 1; index >= 0; index--) {
		timestamps.push(date_us - index * increment);
	}

	let response: Models.AggregationResponse = {
		devices: {},
	};

	for (const deviceModel of Object.keys(devices)) {
		let model = devices[deviceModel];

		let respModel = response.devices[deviceModel];
		if (!respModel) respModel = response.devices[deviceModel] = {};

		for (const deviceId of Object.keys(model)) {
			let device = model[deviceId];

			let respDevice = respModel[deviceId];
			if (!respDevice)
				respDevice = respModel[deviceId] = {
					events: {},
					samples: {},
				};

			for (const eventName of device.events) {
				let respEvents = respDevice.events[eventName];
				if (!respEvents) respEvents = respDevice.events[eventName] = [];

				for (let index = 0; index < timestamps.length; index++) {
					respEvents.push({
						timestamp_us: timestamps[index],
						id: index,
						state: getRandomInt(0, 3),
					});
				}
			}

			//let debugvar:boolean = ((new Date().getTime()) % 2 ==0);
			for (const sampleName of device.samples) {
				let respSamples = respDevice.samples[sampleName];
				if (!respSamples) respSamples = respDevice.samples[sampleName] = [];
				for (let index = 0; index < timestamps.length; index++) {
					let value;
					if (boundaries) {
						const random =
							Math.random() *
								((boundaries.max ?? 100) - (boundaries.min ?? 0)) +
							(boundaries.min ?? 0);
						value = Math.round(random * 100) / 100;
					} else {
						value = getRandomInt(0, 100);
					}
					respSamples.push({
						timestamp_us: timestamps[index],
						id: index,
						value,
						//value: debugvar?getRandomInt(0, index%2>0? 1000 : -10) :  getRandomInt(0, index%2>0? 100 : -100)
					});
				}
			}
		}
	}

	return response;
}

export async function getDataAggregationIntervals(
	devices: Record<string, Record<string, Models.AggregationDevice>>,
	dateFrom: Date,
	dateTo: Date,
	numberOfSamples: number,
	boundaries?: { min?: number; max?: number }
): Promise<Models.AggregationIntervalsResponse> {
	await Helpers.delay(1);

	/* console.log(devices);
	if (devices?.algorithms?.["0"].samples.includes("TCVE_closing_overshoot")) {
	 const random = Math.random();
		return {
		"devices" : {
		 "algorithms" : {
			"0" : {
			 "samples" : {
				"TCVE_closing_overshoot" : random > 0.5 ? [
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 null,
				 "0.60442187",
				 null,
				 null,
				 null,
				 null,
				 null,
				 null
				] : []
			 }
			}
		 }
		},
		"intervals" : random > 0.5 ? [
		 1642077957077999,
		 1642078137077999,
		 1642078317077999,
		 1642078497077999,
		 1642078677077999,
		 1642078857077999,
		 1642079037077999,
		 1642079217077999,
		 1642079397077999,
		 1642079577077999,
		 1642079757077999,
		 1642079937077999,
		 1642080117077999,
		 1642080297077999,
		 1642080477077999,
		 1642080657077999,
		 1642080837077999,
		 1642081017077999,
		 1642081197077999,
		 1642081377077999
		] : []
	 } as any;
	} */

	let ts_dateFrom = toEpoch_us(dateFrom);
	let ts_dateTo = toEpoch_us(dateTo);

	let range = ts_dateTo - ts_dateFrom;

	let interval = range / numberOfSamples;

	let timestamps: number[] = [];

	for (let index = 0; index < numberOfSamples; index++) {
		timestamps.push(ts_dateFrom + index * interval);
	}

	let response: Models.AggregationIntervalsResponse = {
		devices: {},
		intervals: timestamps,
	};

	for (const deviceModel of Object.keys(devices)) {
		let model = devices[deviceModel];

		let respModel = response.devices[deviceModel];
		if (!respModel) respModel = response.devices[deviceModel] = {};

		for (const deviceId of Object.keys(model)) {
			let device = model[deviceId];

			let respDevice = respModel[deviceId];
			if (!respDevice)
				respDevice = respModel[deviceId] = {
					events: {},
					samples: {},
				};

			for (const eventName of device.events) {
				let respEvents = respDevice.events[eventName];
				if (!respEvents) respEvents = respDevice.events[eventName] = [];

				for (let index = 0; index < timestamps.length; index++) {
					respEvents.push(getRandomInt(0, 3));
				}
			}

			for (const sampleName of device.samples) {
				let respSamples = respDevice.samples[sampleName];
				if (!respSamples) respSamples = respDevice.samples[sampleName] = [];
				for (let index = 0; index < timestamps.length; index++) {
					if (index === 57 /*  || Math.random() > 0.2 */) {
						respSamples.push(undefined);
					} else {
						if (boundaries) {
							const random =
								Math.random() *
									((boundaries.max ?? 100) - (boundaries.min ?? 0)) +
								(boundaries.min ?? 0);
							respSamples.push(Math.round(random * 100) / 100);
						} else {
							respSamples.push(getRandomInt(0, 100));
						}
						//respSamples.push(getRandomInt(0, 100));
					}
				}
			}
		}
	}

	return response;
}

function toEpoch_us(date: Date): number {
	return date.getTime() * 1000;
}

export function uploadFirmware(file: File): Promise<void> {
	return Helpers.delay(5);
}

export async function getDate(): Promise<Date> {
	await Helpers.delay(1);
	let date = new Date();

	//todo add minutes..

	return date;
}
export async function setDate(): Promise<void> {
	await Helpers.delay(1);
}

export async function getUserData(
	developerMode: boolean,
	...includes: Enums.UserDataType[]
): Promise<Blob> {
	await Helpers.delay(1);
	const params = new URLSearchParams();

	if (includes && includes.length > 0) {
		for (const includeDataType of includes) {
			params.append("include", includeDataType);
		}
	}
	if (developerMode) {
		params.append("unencrypted", "true");
	}
	console.log(includes, params.toString());
	return new Blob();
}
export function getUserDataUrl(...includes: Enums.UserDataType[]): string {
	let qs = "";

	if (includes && includes.length > 0) {
		qs = qs + "?include=" + includes.join("&include=");
	}

	return getBaseUrl() + "/board/userdata" + qs;
}

export async function setUserData(
	file: File,
	dataType?: Enums.UserDataType
): Promise<boolean> {
	await Helpers.delay(1);
	return true;
}

export async function wipeUserData(type: Enums.UserDataType): Promise<void> {
	await Helpers.delay(1);
	console.log(getBaseUrl() + "/board/userdata/" + type);
}

export async function runOperation(
	deviceModel: string,
	deviceId: string,
	opName: string
): Promise<void> {
	await Helpers.delay(3);
	if (Math.random() > 0.8) {
		throw new Error(`op: ${opName} - test error`);
	}
}

export async function configureSensor(
	deviceModel: string,
	deviceId: string,
	data: Record<string, any>
): Promise<void> {
	console.log(data);
	await Helpers.delay(3);
	if (Math.random() > 0.8) {
		throw new Error(`op: configure - test error`);
	}
}

export async function login(
	username: string,
	password: string
): Promise<Omit<AuthenticatedUser, "user">> {
	await Helpers.delay(1);
	token$.next("test");
	let role = Enums.Role.Admin;
	if (username === "user") {
		role = Enums.Role.User;
	} else if (username.toLowerCase() === "abb") {
		role = Enums.Role.ABB;
	}
	return {
		token: "test",
		role,
		forceChangePassword: false,
	};
}

export function isMock() {
	return true;
}

export async function saveNetworkConfig(
	networkConfig: BoardNetworkConfig
): Promise<void> {
	const interfaces = Object.keys(networkConfig?.interfaces ?? {}).filter(
		(key) =>
			"available" in ((networkConfig?.interfaces ?? {})[key] ?? {}) &&
			(networkConfig!.interfaces[key]! as EthernetNetworkSettings).available ===
				true
	);
	const data = {
		interfaces: interfaces.reduce((res, interf) => {
			const data = networkConfig!.interfaces[
				interf
			]! as EthernetNetworkSettings;
			if (!data.dhcp) {
				res[interf] = {
					dhcp: false,
					address: data.address,
					netmask: data.netmask,
					gateway: data.gateway || undefined,
				};
			} else {
				res[interf] = { dhcp: true };
			}
			return res;
		}, {} as { [i: string]: SaveEthernetNetworkSettings }),
	};
	console.log(data);
	await Helpers.delay(1);
	if (Math.random() > 0.8) {
		throw new Error(`saveNetworkSettings: test error`);
	}
}

export async function setWirelessChannel(data: unknown): Promise<void> {
	console.log(data);
	await Helpers.delay(1);
	if (Math.random() > 0.8) {
		throw new Error(`setWirelessChannel: test error`);
	}
}

export async function getBoardFirmwareInfo(): Promise<BoardFirmwareInfo> {
	return {
		build: {
			branch: "master",
			date: new Date(),
			hashShort: "aaaa",
			revision: "bbbb",
		},
		currentRoot: "root_a",
		linuxVersion: "aaaa",
		version: "10",
	};
}

export async function getBoardNetworkConfig(): Promise<BoardNetworkConfig> {
	return {
		userDNS: "1.1.1.1",
		interfaces: {
			ap: {
				SSID: "ssid-test",
				WPAPassphrase: "passphrase",
				channel: 1,
			} as AccessPointNetworkSettings,
			user: {
				address: "1.1.1.2",
				dhcp: true,
				gateway: "1.1.1.1",
				netmask: "255.0.0.0",
				available: true,
			} as EthernetNetworkSettings,
			service: {
				address: "1.1.1.3",
				dhcp: true,
				gateway: "1.1.1.1",
				netmask: "255.0.0.0",
				available: true,
			} as EthernetNetworkSettings,
		},
	};
}

export async function updateNameplate(
	data: Partial<{
		serialNumber: string;
		orderNumber: string;
		assetLabel: string;
		commissioningDone: string | number | boolean;
	}>
): Promise<void> {
	await Helpers.delay(4);
	console.log({ nameplate: data });
}

export async function createUser(data: SaveUser): Promise<void> {
	await Helpers.delay(4);
	console.log(data);
}

export async function deleteUser(user: string): Promise<void> {
	await Helpers.delay(4);
	console.log(`${user} deleted`);
}

export async function resetUserPassword(user: string): Promise<void> {
	await Helpers.delay(4);
	console.log(`${user}'s password was reset`);
}

export async function getUserList(): Promise<User[]> {
	await Helpers.delay(4);
	return [
		{ user: "admin", role: Enums.Role.Admin, forceChangePassword: false },
		{ user: "admin2", role: Enums.Role.Admin, forceChangePassword: false },
		{ user: "user", role: Enums.Role.User, forceChangePassword: false },
		{ user: "user2", role: Enums.Role.User, forceChangePassword: true },
	];
}

export async function changePassword(password: string): Promise<void> {
	const currentUserData = user$.getValue()!;
	await Helpers.delay(2);
	console.log({ user: currentUserData.user, password });
	if (changePasswordDialogIsShown$.getValue()) {
		changePasswordDialogIsShown$.next(false);
		removeCachedUser();
	}
}

export async function getThermalConfiguration(): Promise<ThermalConfiguration> {
	const response = await fetchIfToken(
		`${getBaseUrl()}/${process.env.REACT_APP_PRODUCT}/thermalConfiguration.json`
	);
	await Helpers.delay(1);
	return response.json();
}

export async function updateThermalConfiguration(
	thermal: SaveThermalConfiguration
): Promise<void> {
	await Helpers.delay(1);
	console.log({ thermal });
}

export async function resetThermalConfiguration(): Promise<void> {
	await Helpers.delay(1);
	console.log("thermal configuration was reset");
}

export async function getSessionValidity(): Promise<string> {
	await Helpers.delay(1);
	return "1191754770";
}
export async function refreshSession(): Promise<string> {
	await Helpers.delay(1);
	return "1200000000";
}

export async function updateABBValues(
	data: Partial<ProductInfoNameplate>
): Promise<void> {
	const keyValues = Object.entries(data);
	for (const [key, value] of keyValues) {
		await Helpers.delay(0.5);
		console.log(`set ${key} to ${value}`);
	}
}

export async function remoteLogout(): Promise<void> {
	await Helpers.delay(0.1);	
	console.debug('remote logout executed correctly');
}

export async function getScenarios(): Promise<Scenario[]> {
	if (!isDemo) {
		throw new Error('scenarios are only available in demo mode');
	}
	await Helpers.delay(0.5);
	const scenariosResponse = {
		scenarios: [
			{ name: "0-serie test", description: "Decription of scenario 0-serie test" },
			{ name: "Scenario One", description: "Decription of scenario one" },
			{ name: "Scenario Two", description: "Decription of scenario one" },
			{ name: "Scenario Three", description: "Decription of scenario three" }
		]
	};
	return scenariosResponse.scenarios;
}
