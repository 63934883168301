
import { Load } from "../enums"

export class LoadHelper {
    static getNameKey(ld: Load): string {

        if (ld === Load.High)
            return "High";

        if (ld === Load.Mid)
            return "Mid";

        if (ld === Load.Low)
            return "Low";

        throw new RangeError();
    }
}
