export function radToDeg(angle: string|number|undefined): number|undefined {
	if (angle === undefined) { return undefined; }
	return Math.round((Number(angle) / Math.PI) * 180);
}

export function degAsNegative(angle: number): number {
	return angle - 360;
}

export function angleRangeWithNegativeConversion(angle1: string|undefined, angle2: string|undefined): [number, number] {
	let from = undefined;
	let to = undefined;
	if (Number(angle1) > Number(angle2)) {
		from = radToDeg(angle1);
		to = radToDeg(angle2);
	} else {
		from = radToDeg(angle2);
		to = radToDeg(angle1);
	}
	return [from !== undefined ? degAsNegative(from) : 0, to ?? 360];
}

export function angleInRange(angle: string|number|undefined, min: number, max: number): number {
	const converted = radToDeg(angle);
	if (converted === undefined) { return min; }
	if (converted >= min && converted <= max) {
		return converted;
	}
	return degAsNegative(converted);
}
