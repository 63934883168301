import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploader } from '../../components';
import { useReadable } from '../../observables/hooks';
import { boardFirmware$ } from '../../services/polling';
import { DocumentsToUpload } from './DocumentsToUpload';

export interface FirmwareData {
	files: File[],
}

interface FirmwareProps {
	setData: (data: FirmwareData|null) => unknown,
	onLock: () => unknown,
	onUnlock: () => unknown,
}
const maxFiles = 1;
export function Firmware({ setData, onLock, onUnlock }: FirmwareProps): ReactElement {
	const { t } = useTranslation();
	const boardInfo = useReadable(boardFirmware$);
	const [files, setFiles] = useState<File[]>([]);
	
	const onFilesSelected = useCallback((selectedFiles) => {
		setFiles([...files, ...selectedFiles]);
	}, [files]);

	useEffect(() => {
		setData({ files });
	}, [files, setData]);

	useEffect(() => {
		if (files.length > maxFiles) {
			onLock();
		} else {
			onUnlock();
		}
	}, [files, onLock, onUnlock]);

	return <>
		<p>{t("FirmwareIntro")}</p>
		<div className='row mt-4'>
			<div className='col-sm-6 col-12'>
				<h6 className='font-weight-bold'>{t("Current board firmware version")}:</h6>
				<div className="d-flex flex-column">
					<div className='firmware-list-item'>
						<span>{t("ReleaseNumber")}</span>
						<span>{boardInfo?.version}</span>
					</div>
					<div className='firmware-list-item'>
						<span>{t("BuildRevision")}</span>
						<span>{boardInfo?.build.revision}</span>
					</div>
					<div className='firmware-list-item'>
						<span>{t("BuildHash")}</span>
						<span>{boardInfo?.build.hashShort}</span>
					</div>
					<div className='firmware-list-item'>
						<span>{t("LinuxVersion")}</span>
						<span>{boardInfo?.linuxVersion}</span>
					</div>
					<div className='firmware-list-item'>
						<span>{t("BuildDateAndTime")}</span>
						<span>{boardInfo?.build.date.toLocaleString(undefined, { hourCycle: 'h12' })}</span>
					</div>
				</div>
			</div>
			<div className="firmware-list-item col-md-6 col-12">
				<span>{t('UpdateBoardFirmwareFromPackage')}</span>
				<div className='firmware-file-uploader-wrapper mt-3'>
					<FileUploader accept=".tar.gz, application/x-gzip" onFileSelected={(files) => onFilesSelected(files)} />
				</div>
				<div>
					<DocumentsToUpload files={files} setFiles={setFiles} maxFiles={maxFiles} />
				</div>
			</div>
		</div>
	</>;
}
