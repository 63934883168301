import React, {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as ABB from "@abb/abb-common-ux-react";
import { Card, HeaderAndAttribute, LastUpdate } from "../../components";
import { BootstrapHelper } from "../../helpers";
import { WidgetProps } from "../../models";
import "./style.scss";
import { apiService } from "../../services/api";
import { values$ } from "../../services/polling";
import { useReadable } from "../../observables/hooks";
import { getNameplateData } from "../../services/polling/system-info";
import { GaugeProps } from "../Gauges/Gauges";

export interface SystemInfoProps extends WidgetProps {
	localConnection: Boolean;
	cloudConnection: Boolean;
	gauge: GaugeProps;
}

function getDateString(date: string | Date) {
	if (typeof date === "string") {
		return date;
	} else {
		return (date as Date)?.toLocaleDateString();
	}
}

export function SystemInfo(props: SystemInfoProps): ReactElement {
	const { t } = useTranslation();
	const constants = useReadable(values$);

	const getGauge = useCallback(
		(g) => ({
			...g,
			min: g.showAsPercentage ? 0 : g.min,
			max: g.showAsPercentage ? 100 : g.max,
			value: g.showAsPercentage ? Math.ceil((g.value / g.max) * 100) : g.value,
		}),
		[]
	);

	const [gaugeData, setGaugeData] = useState(getGauge(props.gauge));

	useEffect(() => {
		setGaugeData(getGauge(props.gauge));
	}, [props.gauge, getGauge]);

	const systemInfoData = useMemo(() => {
		return getNameplateData(constants.values);
	}, [constants.values]);

	let firstTitleClass = "col-lg-12 col-sm-12 align-items-end ";
	let firstAttributeClasss =
		"col-lg-12 col-sm-12 AdviceTBolder principalText lineHeightStrong";

	let secondTitleClass = "col-sm-12 grey-text";
	let secondAttributeClass = "col-sm-12 AdviceTBolder lineHeightStrong";

	let mobileCols = props.mobileCols !== undefined ? props.mobileCols : 8;
	let tabletCols = props.tabletCols !== undefined ? props.tabletCols : 8;
	let desktopCols = props.desktopCols !== undefined ? props.desktopCols : 8;

	let installationDate = getDateString(systemInfoData.installationDate);
	let warrantyEnd = getDateString(systemInfoData.warrantyEnd);
	let manufacturingDate = getDateString(systemInfoData.manufacturingDate);

	return (
		<Card
			hideTitleStraightBar={true}
			installed={props.installed}
			disconnected={props.disconnected}
			mobileCols={mobileCols}
			tabletCols={tabletCols}
			desktopCols={desktopCols}
			className={
				"system-info" + BootstrapHelper.getElementByStatus(props.disabled)
			}
		>
			<div id="system-into-title" className="d-flex justify-content-between">
				<h2 className="ABB_CommonUX_Heading__root ABB_CommonUX_Heading__light ABB_CommonUX_Heading__withCursor">
					{props.title}
				</h2>

				<span>
					<LastUpdate lastUpdate={props.lastUpdate} />
				</span>
			</div>

			<div className="row d-flex">
				<div className="col-12 col-md-8 col-lg-5 order-md-1">
					<ABB.Gauge
						{...gaugeData}
						limitStyle="none"
						style={{ height: "280px", marginTop: "-80px" }}
					/>
				</div>

				<div className="col-12 col-md-4 col-lg-7 order-md-0">
					<HeaderAndAttribute
						title={t("AssetLabel")}
						titleClass={firstTitleClass}
						attributeClass={firstAttributeClasss}
						attribute={systemInfoData.assetLabel}
						onEdit={async (assetLabel: string) => {
							await apiService.updateNameplate({ assetLabel });
							const updatedConstants = await apiService.getValues();
							values$.next(updatedConstants);
						}}
					/>
					<HeaderAndAttribute
						title={t("ModelAndRatings")}
						titleClass={firstTitleClass}
						attributeClass={firstAttributeClasss}
						attribute={systemInfoData.modelRating}
					/>
				</div>
			</div>

			<div className="row d-flex flex-row flex-wrap justify-content-between">
				<HeaderAndAttribute
					container={true}
					containerClass="col-12 col-lg-4 order-1"
					title={t("SerialNumber")}
					titleClass={secondTitleClass}
					attributeClass={secondAttributeClass}
					attribute={systemInfoData.serialNumber}
				/>

				<HeaderAndAttribute
					container={true}
					containerClass="col-12 col-md-4 order-6 order-lg-2"
					title={t("WarrantyStatus")}
					titleClass={secondTitleClass}
					attributeClass={secondAttributeClass}
					attribute={warrantyEnd}
				/>

				<div
					style={{ color: "grey" }}
					className="col-12 col-md-4 order-3 d-none d-lg-block"
				>
					{props.localConnection ? (
						<ABB.Icon
							sizeClass="small"
							name="abb/connect"
							className="align-middle"
							color="color-status-green"
						/>
					) : (
						<ABB.Icon
							sizeClass="small"
							name="abb/disconnect"
							color="grey"
							className="align-middle"
						/>
					)}
					&ensp;<span className="align-middle">{t("LocalConnection")}</span>
				</div>

				<HeaderAndAttribute
					container={true}
					containerClass="col-12 col-md-4 order-4"
					title={t("ManufacturingDate")}
					titleClass={secondTitleClass}
					attributeClass={secondAttributeClass}
					attribute={manufacturingDate}
				/>

				<HeaderAndAttribute
					container={true}
					containerClass="col-12 col-md-4 order-5"
					title={t("InstallationDate")}
					titleClass={secondTitleClass}
					attributeClass={secondAttributeClass}
					attribute={installationDate}
				/>

				<div
					style={{ color: "grey" }}
					className="col-12 col-md-4 order-2 order-lg-6 d-none d-lg-block"
				>
					{props.cloudConnection ? (
						<ABB.Icon
							sizeClass="small"
							className="align-middle"
							name="abb/network_signal_strength-level_3"
							color="color-status-green"
						/>
					) : (
						<ABB.Icon
							sizeClass="small"
							name="abb/wifi_network_disconnected"
							color="grey"
							className="align-middle"
						/>
					)}
					&ensp;<span className="align-middle">{t("CloudConnection")}</span>
				</div>
			</div>
		</Card>
	);
}
