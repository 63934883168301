import React, { ReactElement, useEffect, useRef, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import { Card, InfoPopup } from "../../components";
import { ChartDataItem, ChartWidgetProps } from '../../models';
import { ChartLastOperation, HealthIndexStatus } from '../../enums';
import { BootstrapHelper, HealthIndexStatusHelper, AbbColorHelper } from '../../helpers';
import { chartFilters$, ChartThresholds } from '../../services/polling/chart';
import { getColor } from '../../helpers/css';
import { useReadable } from '../../observables/hooks';

export type ChartLineStyle = "none" | "smooth" | "broken";
export type ChartDotStyle = "none" | "small" | "normal" | "large";

export interface SingleLineChartProps extends ChartWidgetProps, Partial<ChartThresholds<number>> {
	accentColor: string;

	lineStyle?: ChartLineStyle;
	dotStyle?: ChartDotStyle;

	unit?: string;

	highAlarmLabel?: string;
	highWarnLabel?: string;
	lowWarnLabel?: string;
	lowAlarmLabel?: string;

	data: ChartDataItem[];
	hideAxes?: boolean;
	hideCard?: boolean;
	xAxisType: 'date'|'value'|'category';
	disableYCursor: boolean;
	fill: boolean;
	showHorizontalGrid: boolean;
	xAxisLabel?: string;
	yAxisLabel?: string;
	showVariation: boolean;
	skipEmptyPeriods: boolean;
	showDateInTooltip: boolean;
	infoText?: string;
	showZero: boolean;
	hideXLabel: boolean;
	showAsPercentage: boolean;
	showUnitInTooltip: boolean;
	showThresholdValue: boolean;
}

export function SingleLineChart(props: SingleLineChartProps): ReactElement {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [chart, setChart] = useState<am4charts.XYChart | null>(null);
	const [highAlarmRange, setHighAlarmRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [highWarnRange, setHighWarnRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [lowAlarmRange, setLowAlarmRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [lowWarnRange, setLowWarnRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [zeroRange, setZeroRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [series, setSeries] = useState<am4charts.LineSeries | null>(null);
	const [circleBullet, setCircleBullet] = useState<am4charts.CircleBullet | null>(null);
	const [xAxis, setXAxis] = useState<am4charts.DateAxis|am4charts.ValueAxis|am4charts.CategoryAxis|null>(null);
	const [yAxis, setYAxis] = useState<am4charts.ValueAxis|null>(null);
	const { t } = useTranslation();
	const chartFilters = useReadable(chartFilters$);
	const scale = (props.filterRef && chartFilters[props.filterRef]) ?? ChartLastOperation.MIN;
	const [defaultXAxisName, setDefaultXAxisName] = useState('');
	const [showDataRemovedAlert, setShowDataRemovedAlert] = useState(false);
	useEffect(() => {
		if (Object.values(ChartLastOperation).includes(scale as any) || props.xAxisType === 'category') {
			setDefaultXAxisName(t("OperationExecuted"));
		} else {
			setDefaultXAxisName(t("Time"));
		}
	}, [scale, t, props.xAxisType]);
	useEffect(() => {
		if (!containerRef.current) {
			return;
		}
		const _chart = am4core.create(containerRef.current, am4charts.XYChart);
		_chart.zoomOutButton.disabled = true;
		_chart.dateFormatter.inputDateFormat = "x";
		_chart.cursor = new am4charts.XYCursor();
		_chart.cursor.behavior = "none";
		setChart(_chart);

		(window as any).chart = _chart;

		if (props.xAxisType === 'date') {
			const _xDateAxis = _chart.xAxes.push(new am4charts.DateAxis());
			_xDateAxis.groupData = false;
			_xDateAxis.cursorTooltipEnabled = false;
			_xDateAxis.renderer.grid.template.disabled = true;
			_xDateAxis.dateFormats.setKey('millisecond', 'hh:mm a');
			_xDateAxis.dateFormats.setKey('second', 'hh:mm a');
			_xDateAxis.periodChangeDateFormats.setKey('millisecond', 'hh:mm a');
			_xDateAxis.periodChangeDateFormats.setKey('second', 'hh:mm a');
			setXAxis(_xDateAxis);
		} else if (props.xAxisType === 'value') {
			const _xValueAxis = _chart.xAxes.push(new am4charts.ValueAxis());
			_xValueAxis.cursorTooltipEnabled = false;
			_xValueAxis.renderer.grid.template.disabled = true;
			setXAxis(_xValueAxis);
		} else {
			const _xCategoryAxis = _chart.xAxes.push(new am4charts.CategoryAxis());
			_xCategoryAxis.cursorTooltipEnabled = false;
			_xCategoryAxis.renderer.grid.template.disabled = true;
			_xCategoryAxis.dataFields.category = 'category';
			setXAxis(_xCategoryAxis);
		}
		const yValueAxis = _chart.yAxes.push(new am4charts.ValueAxis());
		yValueAxis.cursorTooltipEnabled = false;
		yValueAxis.renderer.grid.template.disabled = true;
		yValueAxis.renderer.labels.template.disabled = true;
		yValueAxis.includeRangesInMinMax = true;
		setYAxis(yValueAxis);

		setZeroRange(yValueAxis.axisRanges.create());
		setHighAlarmRange(yValueAxis.axisRanges.create());
		setHighWarnRange(yValueAxis.axisRanges.create());
		setLowAlarmRange(yValueAxis.axisRanges.create());
		setLowWarnRange(yValueAxis.axisRanges.create());
		
		const _series = new am4charts.LineSeries();
		_chart.series.push(_series);
		_series.name = "Value";
		_series.connect = true; // TODO: make parametric if some single line charts need this to be false
		_series.dataFields.valueY = "value";
		if (props.xAxisType === 'date') {
			_series.dataFields.dateX = "date";
		} else if (props.xAxisType === 'value') {
			_series.dataFields.valueX = "value2";
			// IMPORTANT: when using a ValueAxis for both axis,
			// tooltips won't work unless snapToSeries is used
			// https://www.amcharts.com/docs/v4/concepts/chart-cursor/#Relation_to_series
			_chart.cursor.snapToSeries = _series;
		} else {
			_series.dataFields.categoryX = "category";
		}
		_series.tooltipText = "[bold]{valueY}[/]";
		setSeries(_series);

		// TODO: if it's necessary to show a second axis on the right, uncomment this code and
		// add an option to show/hide it (otherwise it will be shown in all charts with showAxes = true)
		/* const _sampleValueAxis = _chart.yAxes.push(new am4charts.ValueAxis());
		_sampleValueAxis.renderer.opposite = true;
		_sampleValueAxis.cursorTooltipEnabled = false;
		_sampleValueAxis.renderer.grid.template.disabled = true;
		_sampleValueAxis.renderer.labels.template.disabled = true;
		_sampleValueAxis.renderer.minGridDistance = 20;
		_sampleValueAxis.renderer.labels.template.hideOversized = true;
		_sampleValueAxis.renderer.labels.template.disabled = true;

		const _lineSeries = _chart.series.push(new am4charts.LineSeries());
		_lineSeries.name = '';
		_lineSeries.stroke = am4core.color('white');;
		_lineSeries.strokeWidth = 0;
		_lineSeries.dataFields.valueY = "value";
		if (props.xAxisType === 'date') {
			_lineSeries.dataFields.dateX = "date";
		} else {
			_lineSeries.dataFields.valueX = "value2";
		}
		_lineSeries.yAxis = _sampleValueAxis; */
	}, [containerRef, props.xAxisType]);

	useEffect(() => {
		if (yAxis && (props.alarmHighTh || props.alarmLowTh) && !yAxis.isDisposed()) {
			const maxValue = props.data.reduce((max, el) => {
				if (el.value && el.value > max) {
					return el.value;
				}
				return max;
			}, props.alarmHighTh ?? 0);
			const minValue = props.data.reduce((min, el) => {
				if (el.value && el.value < min) {
					return el.value;
				}
				return min;
			}, props.alarmLowTh ?? 0);
			yAxis.max = maxValue + Math.abs(maxValue) * 0.05;
			yAxis.min = minValue - Math.abs(minValue) * 0.05;
		}
	}, [props.data, yAxis, props.alarmHighTh, props.alarmLowTh]);

	const firstRender = useRef(true);

	useEffect(() => {
		if (chart && !chart.isDisposed() && props.hideAxes !== undefined) {
			chart.xAxes.each((axis) => {
				axis.renderer.labels.template.disabled = props.hideAxes!;
				axis.renderer.grid.template.disabled = props.hideAxes!;
				axis.renderer.grid.template.strokeOpacity = 0;
				axis.renderer.baseGrid.disabled = true;
			});
			chart.yAxes.each((axis) => {
				axis.renderer.labels.template.disabled = props.hideAxes!;
				axis.renderer.grid.template.disabled = props.hideAxes!;
				axis.renderer.baseGrid.disabled = !props.showHorizontalGrid;
				axis.renderer.grid.template.strokeOpacity = props.showHorizontalGrid ? 0.2 : 0;
			});
		}
	}, [chart, props.hideAxes, props.showHorizontalGrid]);

	useEffect(() => {
		if (chart && !chart.isDisposed() && props.disableYCursor) {
			chart.cursor.lineY.disabled = true;
		} else if (chart && !chart.isDisposed() && !props.disableYCursor) {
			chart.cursor.lineY.disabled = false;
		}
	}, [chart, props.disableYCursor]);

	useEffect(() => {
		if (xAxis && !xAxis.isDisposed() && !props.hideXLabel) {
			if (props.xAxisLabel) {
				xAxis.title.text = props.xAxisLabel;
			} else {
				xAxis.title.text = defaultXAxisName;
			}
			if (props.xAxisLabelAlignment) {
				xAxis.title.align = props.xAxisLabelAlignment;
			}
		}
		if (props.yAxisLabel && yAxis && !yAxis.isDisposed()) {
			yAxis.title.text = props.yAxisLabel;
			if (props.showUnitInYLabel && props.unit) {
				yAxis.title.text += ` (${props.unit})`;
			} 
			if (props.yAxisLabelAlignment) {
				yAxis.title.valign = props.yAxisLabelAlignment;
			}
		}
	}, [props.xAxisLabel, xAxis, yAxis, props.yAxisLabel, props.xAxisLabelAlignment, props.yAxisLabelAlignment, props.showUnitInYLabel, props.unit, defaultXAxisName, props.hideXLabel]);

	useEffect(() => {
		if (!xAxis || xAxis.isDisposed() || props.xAxisType !== 'date') { return; }
		(xAxis as am4charts.DateAxis).skipEmptyPeriods = props.skipEmptyPeriods;
	}, [xAxis, props.skipEmptyPeriods, props.xAxisType]);
	useEffect(() => {
		if (chart && !chart.isDisposed()) {
			if (!firstRender.current) {
				chart.series.each((series) => {
					series.interpolationDuration = 0;
				});
			}
			firstRender.current = false;
			let setData = true;
			// Because of a problem with amcharts, if skipEmptyPeriods is set to true and
			// we are displaying data with huge gaps (e.g. 10 ms between d1 and d2, 1 day 
			// between d2 and d3), the chart crashes. 
			// To avoid this, we override the automatic interval setting in these scenarios,
			// losing some data but avoiding the crash.
			if (props.skipEmptyPeriods && props.data.length > 1) {
				const intervalUs = props.data[props.data.length - 1].date_us - props.data[0].date_us;
				const minIntervalUs = props.data.reduce((min, el, i) => {
					if (i > 1) {
						if (el.date_us - props.data[i - 1].date_us < min) {
							return el.date_us - props.data[i - 1].date_us;
						}
					} else if (i === 1) {
						return el.date_us - props.data[0].date_us;
					}
					return min;
				}, -1);
				const tolerance = 3000;
				// Dangerous scenario: all the points are as distant as the two closest
				// data points (multiplied by a reasonable tolerance factor), but they aren't
				// able to cover the whole distance from the first to the last data point.
				// This means that there are (at least) two points that are much closer in time
				// than the rest.
				if (minIntervalUs * props.data.length * tolerance < intervalUs) {
					// The precision level is set to the distance between the first and last point,
					// represented in milliseconds, divided by the number of data points and by
					// the tolerance factor
					/* if (intervalUs < (minIntervalUs * props.data.length * tolerance * collapseThreshold)) {
						(xAxis as am4charts.DateAxis).baseInterval = {
							timeUnit: "millisecond",
							count: Math.round((intervalUs / props.data.length) / 1000 / tolerance),
						}
					} */
					// The solution above has been commented because it caused the points to "collapse"
					// to the sides of the chart if the distance was much lower than the one allowed.
					// It's better to just remove the points causing trouble and show an alert to the user 
					const sorted = [...props.data].sort((a, b) => b.date_us - a.date_us);
					const filteredPoints = [sorted[0], sorted[1]];
					let index = 2;
					let stop = false;
					let minDistance = sorted[0].date_us - sorted[1].date_us;
					let maxDistance = minDistance;
					const removeTolerance = 100;
					while (index < sorted.length && !stop) {
						const distanceFromPreviousUs = sorted[index - 1].date_us - sorted[index].date_us;
						if (distanceFromPreviousUs * removeTolerance < minDistance || distanceFromPreviousUs / removeTolerance > maxDistance) {
							stop = true;
						} else {
							filteredPoints.push(sorted[index]);
						}
						index++;
					}
					(xAxis as am4charts.DateAxis).baseInterval = {
						timeUnit: "millisecond",
						count: Math.round((filteredPoints[0].date_us - filteredPoints[filteredPoints.length - 1].date_us) / filteredPoints.length / 1000),
					}
					chart.data = filteredPoints;
					setData = false;
				}
			}
			if (setData) {
				chart.data = props.data;
				setShowDataRemovedAlert(false);
			} else {
				setShowDataRemovedAlert(true);
			}
		}
	}, [chart, props.data, firstRender, props.skipEmptyPeriods, xAxis]);

	useEffect(() => {
		if (highAlarmRange && !highAlarmRange.isDisposed() && props.alarmHighTh !== undefined) {
			highAlarmRange.value = props.alarmHighTh;
			highAlarmRange.grid.stroke = am4core.color(getColor('color-status-red'));
			highAlarmRange.grid.strokeWidth = 2;
			highAlarmRange.grid.strokeOpacity = 1;
			highAlarmRange.label.text = props.showThresholdValue ? props.alarmHighTh.toFixed(2) : t("MAX");
			highAlarmRange.ignoreMinMax = false;
		} else if (highAlarmRange && !highAlarmRange.isDisposed()) {
			highAlarmRange.ignoreMinMax = true;
			highAlarmRange.grid.strokeOpacity = 0;
		}
	}, [props.alarmHighTh, highAlarmRange, props.showThresholdValue, t]);

	useEffect(() => {
		if (highWarnRange && !highWarnRange.isDisposed() && props.warningHighTh !== undefined) {
			highWarnRange.value = props.warningHighTh;
			highWarnRange.grid.stroke = am4core.color(getColor('color-status-yellow'));
			highWarnRange.grid.strokeWidth = 2;
			highWarnRange.grid.strokeOpacity = 1;
			highWarnRange.label.text = props.showThresholdValue ? props.warningHighTh.toFixed(2) : t('MAX');
			highWarnRange.ignoreMinMax = false;
			
		} else if (highWarnRange && !highWarnRange.isDisposed()) {
			highWarnRange.ignoreMinMax = true;
			highWarnRange.grid.strokeOpacity = 0;
		}
	}, [props.warningHighTh, highWarnRange, props.showThresholdValue, t]);

	useEffect(() => {
		if (lowWarnRange && !lowWarnRange.isDisposed() && props.warningLowTh !== undefined) {
			lowWarnRange.value = props.warningLowTh;
			lowWarnRange.grid.stroke = am4core.color(getColor('color-status-yellow'));
			lowWarnRange.grid.strokeWidth = 2;
			lowWarnRange.grid.strokeOpacity = 1;
			lowWarnRange.label.text = props.showThresholdValue ? props.warningLowTh.toFixed(2) : t("MIN");
			lowWarnRange.ignoreMinMax = false;
		} else if (lowWarnRange && !lowWarnRange.isDisposed()) {
			lowWarnRange.ignoreMinMax = true;
			lowWarnRange.grid.strokeOpacity = 0;
		}
	}, [lowWarnRange, props.warningLowTh, props.showThresholdValue, t]);

	useEffect(() => {
		if (lowAlarmRange && !lowAlarmRange.isDisposed() && props.alarmLowTh !== undefined) {
			lowAlarmRange.value = props.alarmLowTh;
			lowAlarmRange.grid.stroke = am4core.color(getColor('color-status-red'));
			lowAlarmRange.grid.strokeWidth = 2;
			lowAlarmRange.grid.strokeOpacity = 1;
			lowAlarmRange.label.text = props.showThresholdValue ? props.alarmLowTh.toFixed(2) : t("MIN");
			lowAlarmRange.ignoreMinMax = false;
		} else if (lowAlarmRange && !lowAlarmRange.isDisposed()) {
			lowAlarmRange.ignoreMinMax = true;
			lowAlarmRange.grid.strokeOpacity = 0;
		}
	}, [lowAlarmRange, props.alarmLowTh, props.showThresholdValue, t]);

	useEffect(() => {
		if (zeroRange && !zeroRange.isDisposed() && yAxis && !yAxis.isDisposed()) {
			zeroRange.value = 0;
			if (props.showZero) {
				zeroRange.label.text = '0';
				zeroRange.grid.strokeOpacity = 0;
				yAxis.renderer.baseGrid.disabled = false;
			} else if (props.showAsPercentage) { 
				zeroRange.value = 1;
				zeroRange.label.text = '1';
				zeroRange.grid.strokeOpacity = 0.2;
				yAxis.renderer.baseGrid.disabled = true;
			} else {
				zeroRange.label.text = '';
				zeroRange.grid.strokeOpacity = 0;
				yAxis.renderer.baseGrid.disabled = false;
			}
		}
	}, [zeroRange, props.showZero, props.showAsPercentage, yAxis]);

	useEffect(() => {
		if (series && !series.isDisposed()) {
			const mainColor = am4core.color(props.accentColor || "red");
			series.stroke = mainColor;
			if (props.fill) {
				series.fill = mainColor;
				series.fillOpacity = 0.3;
			}
			if (props.lineStyle === "none") {
				series.strokeWidth = 0;
			} else if (props.lineStyle === "smooth") {
				series.strokeWidth = 1.5;
				series.tensionX = 0.9;
				series.tensionY = 0.9;
			} else {
				//broken        
				series.strokeWidth = 1.5;
			}
			if (props.dotStyle !== "none") {
				const _circleBullet = circleBullet && !circleBullet.isDisposed() ? circleBullet : new am4charts.CircleBullet();
				_circleBullet.fillOpacity = 1;
				_circleBullet.fill = mainColor;
				_circleBullet.stroke = mainColor;
				_circleBullet.circle.radius = (!props.dotStyle || props.dotStyle === "normal") ? 3 : props.dotStyle === "small" ? 1.5 : 4;
				if (!circleBullet || circleBullet.isDisposed()) {
					series.bullets.push(_circleBullet);
					setCircleBullet(_circleBullet);
				}
			}
			const axisTooltip = series.tooltip;
			if (axisTooltip) {
				axisTooltip.numberFormatter.numberFormat = "####.#####";
				axisTooltip.getFillFromObject = false;
				axisTooltip.autoTextColor = false;
				axisTooltip.background.fill = am4core.color("white");
				axisTooltip.label.fill = am4core.color("black");
				axisTooltip.label.adapter.remove("html");
				axisTooltip.label.adapter.add("html", function (text, target) {
					let val = target.dataItem as am4charts.LineSeriesDataItem;
					let status = HealthIndexStatus.Ok;
					let variation = "";
					let date = "";
					if (val) {
						if (props.alarmHighTh !== undefined && val.valueY > props.alarmHighTh) {
							status = HealthIndexStatus.Alarm;
						}
						else if (props.warningHighTh !== undefined && val.valueY > props.warningHighTh) {
							status = HealthIndexStatus.Warning;
						}
						else if (props.alarmLowTh !== undefined && val.valueY < props.alarmLowTh) {
							status = HealthIndexStatus.Alarm;
						}
						else if (props.warningLowTh && val.valueY < props.warningLowTh) {
							status = HealthIndexStatus.Warning;
						}

						if (props.showVariation && val.index > 0) {
							const previous = props.data[val.index - 1].value!;
							const percVariation = (((val.valueY - previous) / previous) * 100);
							variation = `<div style="color:${props.accentColor};font-weight:bold;text-align:center;font-size:0.8em;">${percVariation > 0 ? '+' : ''}${percVariation.toFixed(1)}%</div>`
						}
						if (props.showDateInTooltip && val.dataContext && 'date' in val.dataContext && (val.dataContext as any).date !== undefined) {
							date = `<div>Date: ${new Date((val.dataContext as any).date).toLocaleString(undefined, {
								year: '2-digit',
								second: '2-digit',
								hourCycle: 'h12',
								day: 'numeric',
								month: 'numeric',
								minute: '2-digit',
								hour: '2-digit',
							})}</div>`;
						}
					}

					const unit = (props.unit && props.showUnitInTooltip) ? props.unit : "";

					if (status === HealthIndexStatus.Ok) {
						return "{name}: <b>{valueY}" + unit + '</b>' + variation + date;
					} else {
						const icon = HealthIndexStatusHelper.getIcon(status).replace('/', '_');
						const color = AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(status));

						//ToDo: trovare un modo di usare il componente Icon di ABB?
						return `<span style="color:${color}">{name}: <i class="ABB_CommonUX_Icon__root align-middle"><i class="mx-auto ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-${icon}_16" style="color:${color}"></i></i> <b>{valueY}${unit}</b></span>${variation}${date}`;
					}
				});
			}
		}
	}, [series, props.accentColor, props.lineStyle, props.dotStyle, circleBullet, props.unit, props.alarmHighTh, props.warningHighTh, props.alarmLowTh, props.warningLowTh, props.fill, props.showVariation, props.data, props.showDateInTooltip, props.showUnitInTooltip]);

	useEffect(() => {
		return () => {
			if (chart && !chart.isDisposed()) {
				chart.dispose();
				setChart(null);
			}
		}
	}, [chart, props.xAxisType]);

	return (
		<>{!props.hideCard &&
			<Card title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
				mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
				tabletCols={BootstrapHelper.getColValue(12, props.tabletCols)}
				desktopCols={BootstrapHelper.getColValue(6, props.desktopCols)}
				installed={props.installed}
				disconnected={props.disconnected}
				topCenterContent={props.infoText ? () => InfoPopup({
					info: props.infoText,
					style: { margin: '0.5rem 0.5rem 0.5rem 0', paddingBottom: '0.5rem' },
				}) : undefined}
			>
				{showDataRemovedAlert && <p>
					<ABB.WithTooltip>

						<ABB.Icon name='abb/warning-circle-1' color='color-status-warning' />
						<ABB.Tooltip followCursor={true}><div style={{ maxWidth: "250px"}}>{t("OldestDataRemovedWarning")}</div></ABB.Tooltip>
					</ABB.WithTooltip>
				</p>}
				<div ref={containerRef} className={"appChart"}></div>
			</Card>
			}
			{props.hideCard && <div ref={containerRef} style={{minHeight: "auto"}} className={"appChart"}></div>}
		</>
	);
}
