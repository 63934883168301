import { BehaviorSubject } from 'rxjs';

export const nowAt1Hz$ = new BehaviorSubject(new Date());

function scheduleNext() {
    setTimeout(update, 1000 - nowAt1Hz$.getValue().getMilliseconds());
}

function update() {
    nowAt1Hz$.next(new Date());
    scheduleNext();
}

scheduleNext();
