import { HealthIndexStatus } from '../enums'

export class HealthIndexStatusHelper {

  static getIcon(hi: HealthIndexStatus): string {

    if (hi === HealthIndexStatus.Ok)
      return "abb/check-mark-circle-1";

    if (hi === HealthIndexStatus.Warning)
      return "abb/warning-circle-1";

    if (hi === HealthIndexStatus.Alarm)
      return "abb/warning-triangle";

    throw new RangeError();
  }


  static getColor(hi: HealthIndexStatus) {

    if (hi === HealthIndexStatus.Ok)
      return "color-status-green";

    if (hi === HealthIndexStatus.Warning)
      return "color-status-orange";

    if (hi === HealthIndexStatus.Alarm)
      return "color-status-red";

    throw new RangeError();
  }
}