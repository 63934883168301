import { DateTimeFormatHelper } from '../../helpers';
import { ApiIdentityItem, ChartDataList, ChartScaleType, LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { PhaseSensorProps } from '../../widgets';
import { areInstalled, findDisconnected } from '../sensor';
import { chartFilters$, getChartDataList } from './chart';

export async function loadPhaseSensorData(config: WidgetConfig, props: Partial<PhaseSensorProps>): Promise<PhaseSensorProps> {
		const phaseSensor = { ...props };
    try {
				const propsConfig = config.propsConfig as unknown as PhaseSensorPropsConfig;
				const chartFilters = chartFilters$.getValue();
				const selectedFilter = propsConfig.filterRef ? chartFilters[propsConfig.filterRef.value] : null;
				const scaleType = selectedFilter ?? phaseSensor.scaleType;
				if (propsConfig.installedDevices) {
					phaseSensor.installed = areInstalled(propsConfig.installedDevices);
					if (phaseSensor.installed) {
						phaseSensor.disconnected = findDisconnected(propsConfig.installedDevices);
					} else {
						phaseSensor.disconnected = undefined;
					}
				}
        const data = await getPhaseSensorChartDataList(scaleType, propsConfig);
        phaseSensor.currentArmValues = data.lastValue;
        phaseSensor.lastUpdate = DateTimeFormatHelper.toDate(data.lastValue.date_us);
        phaseSensor.lastUpdateType = LastUpdateType.Sample;
        phaseSensor.data = data.data;

        phaseSensor.fetching = false;
        phaseSensor.externalFetching = false;
    } catch (e) {
        console.error("loadPhaseSensorData", e);
        phaseSensor.exception = e;
    } 
		return phaseSensor as PhaseSensorProps;
}

export function getPhaseSensorChartDataList(scaleType: ChartScaleType, propsConfig: PhaseSensorPropsConfig): Promise<ChartDataList> {
	const apis = [
		...propsConfig.temperatureSamples.map((ts) => ts.source),
		propsConfig.highWarn?.source,
		propsConfig.highAlarm?.source,
		propsConfig.lowWarn?.source,
		propsConfig.lowAlarm?.source,
		propsConfig.loadEvent.source,
	].filter((a) => a);
	return getChartDataList(scaleType,
		undefined,
		...apis as ApiIdentityItem[]);
}
export interface PhaseSensorPropsConfig {
	upperTemperatureSample: {
		source: NamedSource,
	},
	lowerTemperatureSample: {
		source: NamedSource,
	},
	temperatureSamples: { 
		source: NamedSource,
		name: {
			i18n: string,
		},
		color: {
			value: string,
		}
	}[],
	loadEvent: {
		source: NamedSource,
	},
	highAlarm?: {
		source: NamedSource,
	},
	highWarn?: {
		source: NamedSource,
	},
	lowAlarm?: {
		source: NamedSource,
	},
	lowWarn?: {
		source: NamedSource,
	},
	title: {
		i18n: string,
	},
	linkUrl?: {
		value: string,
	},
	filterRef?: {
		value: string,
	},
	hideYAxis?: {
		value: boolean,
	},
	showHorizontalGrid?: {
		value: boolean,
	},
	showUnitInYLabel?: {
		value: boolean,
	},
	installedDevices?: { role: string, position: string }[],
	yAxisLabel?: {
		i18n: string,
	}
}
