export enum WidgetType {
	Alerts = "Alerts",
	BarChart = "BarChart",
	BubbleChart = "BubbleChart",
	ConnectionSensorStatus = "ConnectionSensorStatus",
	DonutChart = "DonutChart",
	FamilyStatus = "FamilyStatus",
	Filter = "Filter",
	Status = "Status",
	GaugeInfo = "GaugeInfo",
	Gauges = "Gauges",
	Image = "Image",
	ImageStatus = "ImageStatus",
	SingleLineChart = "SingleLineChart",
	MechanicalSensor = "MechanicalSensor",
	MultiLineChart = "MultiLineChart",
	PhaseSensor = "PhaseSensor",
	SectionStatus = "SectionStatus",
	SensorsOverview = "SensorsOverview",
	Slider = "Slider",
	SystemInfo = "SystemInfo",
	WaveformChart = "WaveformChart",
	InputStatus = "InputStatus",
	CBStatus = "CBStatus",
	CumulatedWorkload = "CumulatedWorkload",
	HardwareIntegrity = "HardwareIntegrity",
	HealthIndexProgress = "HealthIndexProgress",
	SyncStatus = "SyncStatus",
	MotorUnit = "MotorUnit",
	CapacitorStatus = "CapacitorStatus",
	ImageStatusWithOverlays = "ImageStatusWithOverlays",
	ActuationEvents = "ActuationEvents",
	SectionTitle = "SectionTitle",
	BinaryInputDiagnostic = "BinaryInputDiagnostic",
	EventLogs = "EventLogs",
	StatusDetailsWithIcon = "StatusDetailsWithIcon",
	BarChartWithChartTooltip = "BarChartWithChartTooltip"
}
