import React, { ReactElement } from 'react'
import { SectionProps } from "../../models"
import { useTranslation } from 'react-i18next'

interface UnderDevProps extends SectionProps {
}

export function UnderDev(props: UnderDevProps): ReactElement {

    const { t } = useTranslation();

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {t("section under development")}
                    </div>
            </div>
        </div>
    )
}
