import React, { ReactElement } from 'react';
import * as ABB from "@abb/abb-common-ux-react";
import { useTranslation } from 'react-i18next';
import { Card, UnknownIconWithTooltip } from '../../components';
import { HealthIndexStatus } from '../../enums';
import { WidgetProps } from '../../models';
import { HealthIndexStatusHelper } from '../../helpers';
import './style.scss';
import { getColor } from '../../helpers/css';
import { SectionLink } from '../../components/SectionLink/SectionLink';

export interface SyncStatusProps extends WidgetProps {
	frequency: number,
	voltage: number,
	frequencyStatus: HealthIndexStatus | undefined,
	syncEnabled: boolean | undefined,
	workingMode: boolean | undefined,
	voltageStatus: HealthIndexStatus | undefined,
	link: string,
	linkName: string,
	frequencyUnit: string,
	voltageUnit: string,
}

export function SyncStatus(props: SyncStatusProps): ReactElement {
	const { t } = useTranslation();
	const voltageIcon = props.voltageStatus ? HealthIndexStatusHelper.getIcon(props.voltageStatus) : undefined;
	const voltageColor = props.voltageStatus ? HealthIndexStatusHelper.getColor(props.voltageStatus) : undefined;
	const red = getColor("color-status-red");
	const frequencyStatusIcon = props.frequencyStatus ? HealthIndexStatusHelper.getIcon(props.frequencyStatus) : undefined;
	const frequencyStatusColor = props.frequencyStatus ? HealthIndexStatusHelper.getColor(props.frequencyStatus) : undefined;
	return <Card
		desktopCols={props.desktopCols ?? 6}
		tabletCols={props.tabletCols ?? 12}
		mobileCols={props.mobileCols ?? 12}
		title={props.title}
		disconnected={props.disconnected}
		installed={props.installed}
		comment={{ lastUpdateType: props.lastUpdateType, lastUpdate: props.lastUpdate }}
	>
		<div className="row mt-2">
			<div className="col-6 d-flex align-items-center"><h6 className="font-weight-bold mb-0">{t("Frequency detected")}</h6></div>
			<div className="col-6">
				<div className="sync-status-frequency">
					<span className="align-middle">{props.frequency} {props.frequencyUnit}</span> <span>
					{frequencyStatusIcon ? <ABB.Icon className="align-middle" sizeClass="large" name={frequencyStatusIcon} color={frequencyStatusColor} />
						: <UnknownIconWithTooltip size='large' tooltipText={t("FrequencyStatusUnknown")} />
					}</span>
				</div>
			</div>
		</div>
		<div className="row mt-4">
			<div className="col-4">
				<div className="sync-status-label mb-1">{t("Sync feature")}</div>
				<div>
					{props.syncEnabled && <>
						<ABB.Icon className="align-middle" name="abb/check-mark-circle-1" sizeClass="medium" color="color-status-green" />
						<span className="ml-1 sync-status-description align-middle">{t("Enabled")}</span>
					</>}
					{props.syncEnabled === false && <>
						<ABB.Icon className="align-middle" name="abb/close" sizeClass="medium" color="color-status-red" />
						<span className="ml-1 sync-status-description align-middle">{t("Disabled")}</span>
					</>}
					{props.syncEnabled === undefined && <>
						<UnknownIconWithTooltip tooltipText={t("SyncUnknown")} size="medium" />
						<span className='ml-1 sync-status-description align-middle'>{t("Unknown")}</span>
					</>}
				</div>
			</div>
			<div className="col-4">
				<div className="sync-status-label mb-1">{t("Working mode")}</div>
				<div>
					{props.workingMode && <>
						<ABB.Icon className="align-middle" name="abb/synchronize" sizeClass="medium" color="color-status-green" />
						<span className="ml-1 sync-status-description align-middle">{t("Sync")}</span>
					</>}
					{props.workingMode === false && <>
						<span style={{ position: "relative" }}>
							<ABB.Icon className="align-middle" name="abb/synchronize" sizeClass="medium" color="color-status-red" />
							<svg viewBox="0 0 10 10" style={{ position: "absolute", left: "0" }}>
								<line x1="0" x2="10" y1="0" y2="10" stroke={red} />
							</svg>
						</span>
						<span className="ml-1 sync-status-description align-middle">{t("Not sync")}</span>
					</>}
					{props.workingMode === undefined && <>
						<UnknownIconWithTooltip tooltipText={t("WorkingModeUnknown")} size="medium" />
						<span className='ml-1 sync-status-description align-middle'>{t("Unknown")}</span>
					</>}
				</div>
			</div>
			<div className="col-4">
				<div className="sync-status-label mb-1">{t("Line voltage")}</div>
				{(voltageIcon && props.voltageStatus) ?
					<ABB.WithTooltip>
						<div>
							<ABB.Icon className="align-middle" name={voltageIcon} sizeClass="medium" color={voltageColor} />
							<span className="ml-1 sync-status-description align-middle">{t(props.voltageStatus)}</span>
						</div>
						<ABB.Tooltip>
							{props.voltage} {props.voltageUnit}
						</ABB.Tooltip>
					</ABB.WithTooltip>
					: <div>
						<UnknownIconWithTooltip tooltipText={`${props.voltage} ${props.voltageUnit}`} size="medium" />
						<span className='ml-1 sync-status-description align-middle'>{t("Unknown")}</span>
					</div>}
			</div>
		</div>
		<SectionLink
			showIcon={true}
			showName={true}
			link={props.link}
			linkName={props.linkName}
			className="mt-4"
		/>
	</Card>
}
