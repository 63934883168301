import { BehaviorSubject } from 'rxjs';
import * as Enums from "../enums";
export interface User {
	user: string,
	role: Enums.Role,
	forceChangePassword: boolean,
};

export interface AuthenticatedUser extends User {
	token: string,
}
export interface SaveUser {
	user: string,
	role: Enums.Role.Admin | Enums.Role.User,
}

export const user$ = new BehaviorSubject<AuthenticatedUser | null>(null);
export const token$ = new BehaviorSubject<string | null>(null);
