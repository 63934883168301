import React, { ReactElement } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { Card } from '../../components'
import { BootstrapHelper, HealthIndexStatusHelper } from '../../helpers';
import { ImageWidgetWithOverlays, ImageWidgetWithOverlaysProps } from '../ImageWidgetWithOverlays/ImageWidgetWithOverlays';
import './style.scss';
import { HealthIndexStatus } from '../../enums';

export interface ImageStatusWithOverlaysProps extends ImageWidgetWithOverlaysProps{
	status: HealthIndexStatus,
	message: string,
}

export function ImageStatusWithOverlays(props: ImageStatusWithOverlaysProps): ReactElement {
	const icon = HealthIndexStatusHelper.getIcon(props.status);
	const color = HealthIndexStatusHelper.getColor(props.status);
	return (
        <Card hideTitle={true}
						installed={props.installed}
						disconnected={props.disconnected}
            mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
            tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)}
            desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}>
            <ImageWidgetWithOverlays {...props} mobileCols={12} tabletCols={12} desktopCols={12} />
            <div className="status-alert mt-2 p-2" style={{ lineHeight: '32px' }}>
							<ABB.Icon name={icon} sizeClass="large" color={color} className="align-middle mr-3" />
							<span className="align-middle">{props.message}</span>
						</div>
        </Card>
    )
}
