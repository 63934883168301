import { BehaviorSubject, PartialObserver } from 'rxjs';
import { Readable, Writable } from '../observables/types';
import { measureExecutionTime } from './time';

export interface PollingWritable<T> extends Writable<T> {
	startPolling: () => Promise<void>,
	stopPolling: () => void,
	fetch: (force?: boolean) => Promise<void>,
	fetching$: Readable<boolean>,
	polling$: Readable<boolean>,
	lastError$: Readable<Error | null>,
	lastUpdate$: Readable<Date>,
	interval: number|null,
}

export interface PollingWritableConfig<T> {
	dataProvider: () => Promise<T>,
	interval?: number|null,
	auto?: boolean,
}

/**
 * 
 * @param defaultValue the value that this subject contains upon initialization, accessible using subject.getValue()
 * @param config.dataProvider an async function used as data source
 * @param config.interval (optional, defaults to null) polling interval in milliseconds
 * @param config.auto (optional, defaults to true) if true the polling will start and stop automatically (start when there is at least one subscriber, stop when there are no more subscribers),
 *                    otherwise you'll need to call subject.startPolling and subject.stopPolling manually
 * @returns 
 */
export function makePollingWritable<T>(
	defaultValue: T,
	{ dataProvider, interval = null, auto = true }: PollingWritableConfig<T>
): PollingWritable<T> {
	const content$ = new BehaviorSubject(defaultValue);
	const fetching$ = new BehaviorSubject(false);
	const polling$ = new BehaviorSubject(false);
	const lastError$ = new BehaviorSubject<Error | null>(null);
	const lastUpdate$ = new BehaviorSubject<Date>(new Date());

	const requestId = () => window.performance.now().toString();

	let queue: string[] = [];
	const fetch = async (force?: boolean) => {
		if (fetching$.getValue() && !force) {
			return;
		}
		if (force) {
			queue = [];
		}
		const id = requestId();
		queue.push(id);
		fetching$.next(true);
		try {
			const newContent = await dataProvider();
			if (queue.includes(id)) {
				content$.next(newContent);
				lastUpdate$.next(new Date());
			}
			lastError$.next(null);
		} catch (err) {
			lastError$.next(err);
		} finally {
			const indexOf = queue.indexOf(id);
			if (indexOf !== -1) {
				queue.splice(indexOf, 1);
			}
			fetching$.next(false);
		}
	};

	let pollingInterval: ReturnType<typeof setTimeout> | null = null;
	const pollingLoop = async () => {
		const elapsedTime = await measureExecutionTime(fetch);
		if (polling$.getValue() && interval !== null) {
			pollingInterval = setTimeout(pollingLoop, Math.max(0, Math.round(interval - elapsedTime)));
		}
	};

	const startPolling = () => {
		stopPolling();
		polling$.next(true);
		return pollingLoop();
	};
	const stopPolling = () => {
		queue = [];
		if (pollingInterval !== null) {
			clearTimeout(pollingInterval);
			pollingInterval = null;
		}
		fetching$.next(false);
		polling$.next(false);
	};

	let subscribers = 0;
	return {
		getValue: () => content$.getValue(),
		next: (v) => content$.next(v),
		subscribe: (subscriber) => {
			subscribers++;
			if (auto && subscribers === 1) {
				startPolling();
			}

			return content$.subscribe((subscriber || undefined) as PartialObserver<T>).add(() => {
				subscribers--;
				if (auto && subscribers === 0) {
					stopPolling();
				}
			});
		},
		fetch,
		startPolling,
		stopPolling,
		fetching$,
		polling$,
		lastError$,
		lastUpdate$,
		get interval() {
			return interval;
		},
		set interval(ms: number|null) {
			interval = ms;
		}
	};
}
