import { useTranslation } from "react-i18next";
import * as ABB from "@abb/abb-common-ux-react";
import { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { apiService } from "../../services/api";
import { fireErrorNotification, fireNotification } from "../../toasts";
import { handleLogout } from '../../App/login';
import { useMountedRef } from '../../utils/hooks';

const validNumber = /[0-9]/;
const validLowercase = /[a-z]/;
const validUppercase = /[A-Z]/;
const validSpecialSymbol = /[!"#$%&'()*+,-./:;<>=?@[\\\]{}|^_`~]/;

export const UserChangePassword: React.FC = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [valid, setValid] = useState(false);
	const [data, setData] = useState<{
		password: string;
		confirmPassword: string;
	}>({ password: "", confirmPassword: "" });
	const mountedRef = useMountedRef();
	const [passwordDirty, setPasswordDirty] = useState(false);

	const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);

	const iconOk = <ABB.Icon className='align-middle mr-2' name='abb/check-mark-circle-1' color='color-status-green' />
	const iconFail = <ABB.Icon className='align-middle mr-2' name='abb/warning-triangle' color='color-status-red' />

	const validators: {
		[key in keyof typeof data]: (v: string | null | undefined) => boolean
	} = useMemo(
		() => ({
			password: (v) => {
				return !(v === undefined || v === null || v === "" || v.length < 8 || !validUppercase.test(v) || !validNumber.test(v) || !validLowercase.test(v) || !validSpecialSymbol.test(v));
			},

			confirmPassword: (v) => {
				return (v === data.password);
			},
		}),
		[data]
	);

	useEffect(() => {
		setValid(
			!Object.entries(validators).some(
				([key, validator]) => !validator(data[key as keyof typeof data])
			)
		);
	}, [data, validators]);

	const handleChangePassword = useCallback(async () => {
		try {
			setIsLoading(true);
			await apiService.changePassword(data.password);
			fireNotification({
				text: t("UpdatePasswordSuccess"),
				severity: "success",
				type: "banner",
			});
			await handleLogout();
			if (mountedRef.current) {
				setData({ password: "", confirmPassword: "" });
				setPasswordDirty(false);
				setConfirmPasswordDirty(false);
			}
		} catch (err) {
			fireErrorNotification({
				text: t("UpdatePasswordError"),
				severity: "alarm",
				type: "banner",
			}, err, t);
		} finally {
			if (mountedRef.current) {
				setIsLoading(false);
			}
		}
	}, [data, t, mountedRef]);

	return (
		<>
			<div className="row">
				<div className="col-sm-6 col-12">
					<ABB.Input
						dataType="password"
						inputAttributes={{ autoComplete: "new-password" }}
						type="normal"
						label={t("Password")}
						placeholder={t("Password")}
						value={data.password}
						validator={validators.password}
						onValueChange={(v) => {
							setPasswordDirty(true);
							setData((oldData) => ({
								...oldData,
								password: v,
							}));
						}}
						showValidationBarWhenValid={false}
						showValidationIconWhenValid={false}
						showValidationBarWhenInvalid={passwordDirty}
						showValidationIconWhenInvalid={false}
					/>
				</div>
				<div className="col-sm-6 col-12">
					<ABB.Input
						dataType="password"
						type="normal"
						label={t("Confirm Password")}
						placeholder={t("Confirm Password")}
						value={data.confirmPassword}
						validator={validators.confirmPassword}
						onValueChange={(v) => {
							setConfirmPasswordDirty(true);
							setData((oldData) => ({
								...oldData,
								confirmPassword: v,
							}));
						}}
						showValidationBarWhenValid={false}
						showValidationIconWhenValid={false}
						showValidationBarWhenInvalid={confirmPasswordDirty}
						showValidationIconWhenInvalid={false}
					/>
				</div>
			</div>
			<div className='mt-2 px-2 pt-2 border'>
				<span>{t("YourPasswordMust")}:</span>
				<ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
					<li>
						{data.password.length >= 8 ? iconOk : iconFail}
						<span className='align-middle'>
							{t("PasswordLengthRequirement")}
						</span>
					</li>
					<li>
						{validLowercase.test(data.password) ? iconOk : iconFail}
						<span className='align-middle'>
							{t("PasswordLowercaseRequirement")}
						</span>
					</li>
					<li>
						{validUppercase.test(data.password) ? iconOk : iconFail}
						<span className='align-middle'>
							{t("PasswordUppercaseRequirement")}
						</span>
					</li>
					<li>
						{validNumber.test(data.password) ? iconOk : iconFail}
						<span className='align-middle'>
							{t("PasswordNumberRequirement")}
						</span>
					</li>
					<li>
						{validSpecialSymbol.test(data.password) ? iconOk : iconFail}
						<span className='align-middle'>
							{t("PasswordSymbolRequirement")}
						</span>
					</li>
					<li>
						{data.password === data.confirmPassword ? iconOk : iconFail}
						<span className='align-middle'>
							{t("PasswordMatchRequirement")}
						</span>
					</li>
				</ul></div>

			<div className="text-center">
				<ABB.Button
					className="my-2"
					type="primary-blue"
					sizeClass="small"
					text={t("Change Password")}
					isLoading={isLoading}
					disabled={!valid}
					onClick={() => handleChangePassword()}
				/>
			</div>
		</>
	);
};
