import { ReactElement, useCallback, useRef, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { commissioningWizardIsShown$, isCommissioningPhase$ } from '../../App/layout-state';
import { ModalWithSteps } from '../../components';
import { delay } from '../../helpers';
import { BoardNetworkConfig } from '../../models/api/BoardNetworkConfig';
import { useReadable } from '../../observables/hooks';
import { apiService } from '../../services/api';
import { boardNetwork$ } from '../../services/polling';
import { fireErrorNotification, fireNotification } from '../../toasts';
import { AssetIdentification, AssetIdentificationData } from './AssetIdentification';
import { Network } from './Network';
import { Sensors } from './Sensors';
import './style.scss';

export function CommissioningWizard(): ReactElement {
	const { t } = useTranslation();
	const [showSkipWarning, setShowSkipWarning] = useState(false);
	const guardPromiseResolutionFnRef = useRef<(value: boolean) => void>(() => {});
	const commissioningWizardIsShown = useReadable(commissioningWizardIsShown$);
	const [steps, setSteps] = useState([
		{ title: t('AssetIdentification'), isUnlocked: true },
		{ title: t('Network'), isUnlocked: true },
		{ title: t('Sensors'), isUnlocked: true },
	]);
	const assetIdentification = useRef<AssetIdentificationData | null>(null);
	const network = useRef<BoardNetworkConfig | null>(null);
	const [commissioningDone, setCommissioningDone] = useState(false);

	const setAssetIdentification = useCallback((data: AssetIdentificationData | null) => {
		assetIdentification.current = data;
	}, [assetIdentification]);

	const setNetwork = useCallback((data: BoardNetworkConfig | null) => {
		network.current = data;
	}, [network]);

	const updateStep = useCallback((index, unlocked) => {
		if (steps[index].isUnlocked !== unlocked) {
			steps[index].isUnlocked = unlocked;
			setSteps([...steps]);
		}
	}, [steps]);

	const onAssetIdentificationNext = useCallback(async () => {
		if (assetIdentification.current) {
			// TODO: call API to save data
			await delay(1);
			console.debug(assetIdentification.current);
		}
	}, [assetIdentification]);

	const onNetworkNext = useCallback(async () => {
		if (network.current) {
			await apiService.saveNetworkConfig(network.current);
			await boardNetwork$.fetch();
		}
	}, [network]);

	const onFinish = useCallback(async () => {
		await onAssetIdentificationNext();
		await onNetworkNext();
		if (commissioningDone) {
			await apiService.updateNameplate({
				commissioningDone: 1
			});
		}
	}, [onAssetIdentificationNext, onNetworkNext, commissioningDone]);

	const onError = useCallback((err) => {
		fireErrorNotification({
			severity: 'alarm',
			text: t("AnErrorOccurred"),
			type: 'banner',
		}, err, t);
	}, [t]);

	const onSuccess = useCallback(async () => {
		fireNotification({
			severity: 'success',
			text: t("CommissioningSuccessful"),
			type: 'banner',
		});
		isCommissioningPhase$.next(false);
		commissioningWizardIsShown$.next(false);
	}, [t]);

	const nextGuard = useCallback(async (currentStep) => {
		let shouldContinue = true;
		if (currentStep === 0) {
			if (!Object.values(assetIdentification.current || {}).some((v) => v)) {
				setShowSkipWarning(true);
				setCommissioningDone(false);
				shouldContinue = await new Promise<boolean>((resolve) => {
					guardPromiseResolutionFnRef.current = resolve;
				});
			} else {
				setCommissioningDone(true);
			}
		}
		if (!shouldContinue) { 
			throw new Error('user does not wish to skip this section');
		}
	}, []);

	const onSkipWarningCancel = useCallback(() => {
		guardPromiseResolutionFnRef.current(false);
		setShowSkipWarning(false);
	}, [guardPromiseResolutionFnRef]);

	const onSkipWarningConfirm = useCallback(() => {
		guardPromiseResolutionFnRef.current(true);
		setShowSkipWarning(false);
	}, [guardPromiseResolutionFnRef]);

	return <><ModalWithSteps
		dialog={{ isOpen: commissioningWizardIsShown, showCloseButton: false }}
		title={t("CommissioningWizard")}
		steps={steps}
		onClose={() => commissioningWizardIsShown$.next(false)}
		onError={onError}
		onSuccess={onSuccess}
		onFinish={onFinish}
		nextGuard={nextGuard}
	>
		<AssetIdentification
			setData={setAssetIdentification}
			onLock={() => updateStep(0, false)}
			onUnlock={() => updateStep(0, true)} />
		<Network
			setData={setNetwork}
			onLock={() => updateStep(1, false)}
			onUnlock={() => updateStep(1, true)} />
		<Sensors />
	</ModalWithSteps>
		<ABB.MessageDialog
			buttons='confirmcancel'
			message={t("SkipCommissioningMessage")}
			title={t("SkipCommissioningTitle")}
			isOpen={showSkipWarning}
			onCancel={onSkipWarningCancel}
			onConfirm={onSkipWarningConfirm}			
		/>
	</>
}
