import { BehaviorSubject } from 'rxjs';
import { ConnectionStatus } from '../enums';
import { LocalizationHelper } from '../helpers';
import { ApiIdentityItemType, DevicesMetadataResponse } from '../models';
import { combineReadables } from '../observables/utils';
import { apiService } from './api';
import { devices$, devicesAndMetadata$, devicesMetadata$, mergeDeviceData } from './device';
import { getLastEventValue, values$ } from './polling';

const _installedSensors$ = new BehaviorSubject<InstalledSensor[]>([]);
const _availableSensors$ = new BehaviorSubject<AvailableSensor[]>([]);

export function getSensorConnection(devicesMetadata: DevicesMetadataResponse, id: string, model: string): ConnectionStatus {
	let connectionStatus = ConnectionStatus.Connected;
	try {
		if ('connection' in (devicesMetadata.models[model]?.events || {})) {
			connectionStatus = getLastEventValue({
				name: 'connection',
				device: {
					id,
					model,
				},
				type: ApiIdentityItemType.event,
			}).mappedValue as ConnectionStatus;
		}
	} catch (err) {
		// device not present
		console.warn(err);
		connectionStatus = ConnectionStatus.Disconnected;
	}
	return connectionStatus;
}

export const installedSensors$ = combineReadables([_installedSensors$, devices$, devicesMetadata$], ([installedSensors, devices, devicesMetadata]) => {
    return mergeDeviceData(
        installedSensors,
        [
            ...[...devices.values()].flat().filter((sensor) => sensor.status && sensor.status.installed && !sensor.roles?.includes('board')).map<InstalledSensorOnline>((sensor) => {
							
							return {
                connectionStatus: getSensorConnection(devicesMetadata, sensor.device_id, sensor.model),
                installed: true,
                id: sensor.device_id,
                uid: sensor.model + sensor.device_id,
                model: sensor.model,
								configuration: sensor.configuration,
                calibratable: Boolean(sensor.status?.calibration.available),
                calibrated: (sensor.status?.calibration.count ?? 0) > 0,
                trainable: Boolean(sensor.status?.training.available),
                trained: (sensor.status?.training.count ?? 0) > 0,
                configurable: Boolean(devicesMetadata.models[sensor.model]?.configuration),
                configured: Boolean(sensor.status?.configuration),
                replaceable: Boolean(sensor.status?.replace.available),
                replaced: (sensor.status?.replace.count ?? 0) > 0,
                roles: sensor.roles || [],
								exported: Boolean(typeof sensor.exported === 'string' ? Number(sensor.exported) : sensor.exported),
                configurationData: devicesMetadata.models[sensor.model]?.configuration || null,
							};
            }),
        ],
        () => ({
            busy: false,
            calibrating: false,
            configuring: false,
            training: false,
            uninstalling: false,
        })
    );
});

export const availableSensors$ = combineReadables([_availableSensors$, devices$, devicesMetadata$], ([availableSensors, devices, devicesMetadata]) => {
    return mergeDeviceData(
        availableSensors,
        [
            ...[...devices.values()].flat().filter((sensor) => (!sensor.status || !sensor.status.installed) && !sensor.roles?.includes('board')).map<AvailableSensorOnline>((sensor) => ({
                id: sensor.device_id,
                uid: sensor.model + sensor.device_id,
                busy: false,
                installing: false,
                installed: false,
                model: sensor.model,
                roles: sensor.roles || [],
								exported: Boolean(typeof sensor.exported === 'string' ? Number(sensor.exported) : sensor.exported),
                configurationData: devicesMetadata.models[sensor.model]?.configuration || null,
            })),
        ],
        () => ({
            busy: false,
            installing: false,
        })
    );
});


export const sensors$ = combineReadables([installedSensors$, availableSensors$], ([installedSensors, availableSensors]) => {
    return [...installedSensors, ...availableSensors];
});


async function runOperationOnInstalledSensor(uid: string, operation: string | ((model: string, id: string) => Promise<void>), loadingPropertyName: keyof InstalledSensorLocalLoadingProps) {
    const sensors = installedSensors$.getValue();
    const sensor = sensors.find((s) => s.uid === uid)!;
    sensor.local[loadingPropertyName] = true;
    sensor.local.busy = true;
    _installedSensors$.next([...sensors]);

    try {
        if (typeof operation === "string") {
            await apiService.runOperation(sensor.model, sensor.id, operation);
        } else {
            await operation(sensor.model, sensor.id);
        }
        sensor.local.lastException = null;
    } catch (err) {
        sensor.local.lastException = err;
        throw err;
    } finally {
        sensor.local[loadingPropertyName] = false;
        sensor.local.busy = false;
        await devicesAndMetadata$.fetch();
        _installedSensors$.next([...sensors]);
    }
}

async function runOperationOnAvailableSensor(uid: string, opName: string, loadingPropertyName: keyof AvailableSensorLocalLoadingProps) {
    const sensors = availableSensors$.getValue();
    const sensor = sensors.find((s) => s.uid === uid)!;
    sensor.local[loadingPropertyName] = true;
    sensor.local.busy = true;
    _availableSensors$.next([...sensors]);

    try {
        await apiService.runOperation(sensor.model, sensor.id, opName);
        sensor.local.lastException = null;
    } catch (err) {
        sensor.local.lastException = err;
        throw err;
    } finally {
        sensor.local[loadingPropertyName] = false;
        sensor.local.busy = false;
        await devicesAndMetadata$.fetch();
        _availableSensors$.next([...sensors]);
    }
}

async function runOperationOnInstalledSensors(uids: string[], opName: string, loadingPropertyName: keyof InstalledSensorLocalLoadingProps) {
    const sensors = installedSensors$.getValue();
    const filteredSensors = sensors.filter((s) => uids.includes(s.uid));
    filteredSensors.forEach((sensor) => {
        sensor.local[loadingPropertyName] = true;
        sensor.local.busy = true;
    });
    _installedSensors$.next([...sensors]);

    try {
        return await Promise.all(
            filteredSensors.map((sensor) => apiService.runOperation(sensor.model, sensor.id, opName)
                .then(() => {
                    sensor.local.lastException = null;
                    return { uid: sensor.uid, err: null };
                })
                .catch((err) => {
                    sensor.local.lastException = err;
                    return { uid: sensor.uid, err };
                })
            ),
        );
    } finally {
        filteredSensors.forEach((sensor) => {
            sensor.local[loadingPropertyName] = false;
            sensor.local.busy = false;
        });
        await devicesAndMetadata$.fetch();
        _installedSensors$.next([...sensors]);
    }
}

export async function calibrateSensor(uid: string): Promise<void> {
	return runOperationOnInstalledSensor(uid, 'calibration', 'calibrating');
}

export async function calibrateSensors(): Promise<{ uid: string, err: Error | null }[]> {
    const sensors = installedSensors$.getValue();
    const calibratable = sensors.filter((s) => !s.local.calibrating && s.calibratable);
	return runOperationOnInstalledSensors(calibratable.map(({ uid }) => uid), 'calibration', 'calibrating');
}

export async function installSensor(uid: string): Promise<void> {
    return runOperationOnAvailableSensor(uid, 'install', 'installing');
}

export async function uninstallSensor(uid: string): Promise<void> {
    return runOperationOnInstalledSensor(uid, 'uninstall', 'uninstalling');
}

export async function configureSensor(uid: string, data: Record<string, any>): Promise<void> {
    return runOperationOnInstalledSensor(uid, async (model, id) => {
        await apiService.configureSensor(model, id, data);
    }, 'configuring');
}

export async function trainSensor(uid: string): Promise<void> {
    await runOperationOnInstalledSensor(uid, 'training', 'training')
		const updatedValues = await apiService.getValues();
		values$.next(updatedValues);
}

export async function replaceSensor(uid: string): Promise<void> {
    return runOperationOnInstalledSensor(uid, 'replace', 'replacing');
}

export async function trainSensors(): Promise<{ uid: string, err: Error | null }[]> {
    const sensors = installedSensors$.getValue();
    const calibratable = sensors.filter((s) => !s.local.training && s.trainable);
    const res = await runOperationOnInstalledSensors(calibratable.map(({ uid }) => uid), 'training', 'training');
		const updatedValues = await apiService.getValues();
		values$.next(updatedValues);
		return res;
}

export function getSensorName(sensorModel: string, roles: string[]) {
	if (roles.length > 0) {
		const withTranslation = roles.find((r) => LocalizationHelper.getTranslation(`deviceNames:${r}`) !== r);
		return withTranslation ? LocalizationHelper.getTranslation(`deviceNames:${withTranslation}`) : roles.join(', ');
	} else {
		return LocalizationHelper.getTranslation(`deviceNames:${sensorModel}`);		
	}
}

export function getSensorDescription(sensorModel: string, roles: string[]) {
	if (roles.length > 0) {
		const withTranslation = roles.find((r) => LocalizationHelper.getTranslation(`deviceDescriptions:${r}`) !== r);
		return withTranslation ? LocalizationHelper.getTranslation(`deviceDescriptions:${withTranslation}`) : sensorModel;
	} else {
		return LocalizationHelper.getTranslation(`deviceDescriptions:${sensorModel}`);
	}
}


export function isInstalled({ role, position = '0' }: { role: string, position?: string }): boolean {
	let device = devices$.getValue().get(role)?.find((d) => d.position === position);
	if (!device) {
		return false;
	}
	return device.status?.installed ?? false;
}

export function areInstalled(devices: { role: string, position?: string }[]): boolean {
	return devices.some((d) => isInstalled({ role: d.role, position: d.position ?? '0'}));
}

export function findDisconnected(devices: { role: string, position?: string }[]): { role: string, position?: string }[] {
	return devices
		.filter((d) => isInstalled({ role: d.role, position: d.position ?? '0' }))
		.filter((d) => {
			try {
				const lastEvent = getLastEventValue({
					name: 'connection',
					role: d.role,
					position: d.position,
					type: ApiIdentityItemType.event,
				}).mappedValue as ConnectionStatus;
				return lastEvent === ConnectionStatus.Disconnected;
			} catch (err) {
				return true;
			}
		});
}




export enum SensorModel {
	// TODO: collezionare/verificare nomi modelli da API
	modbus_ss_current = 'modbus_ss_current',
	modbus_ss_environmental = 'modbus_ss_environmental',
	smartarm = 'smartarm',
}

export interface SensorCommonsOnline {
    model: SensorModel|string,
    roles: string[],
		exported: boolean,
    id: string,
    uid: string,
    configurationData: Record<string, "string" | "boolean" | "number"> | null,
		configuration?: Record<string, any>,
}

export interface SensorCommonsLocal {
    lastException?: Error | null,
    busy: boolean,
}

export interface AvailableSensorOnline extends SensorCommonsOnline {
    installed: false,
}

export interface AvailableSensorLocalLoadingProps {
    installing: boolean,
}

export interface AvailableSensorLocal extends SensorCommonsLocal, AvailableSensorLocalLoadingProps {
}

export interface AvailableSensor extends AvailableSensorOnline {
    local: AvailableSensorLocal,
}

export interface InstalledSensorOnline extends SensorCommonsOnline {
    connectionStatus: ConnectionStatus,

    installed: true,
    replaceable: boolean,
    replaced: boolean,
    calibratable: boolean,
    calibrated: boolean,
    trainable: boolean,
    trained: boolean,
    configurable: boolean,
    configured: boolean,
}

export interface InstalledSensorLocalLoadingProps {
    calibrating: boolean,
    training: boolean,
    uninstalling: boolean,
    configuring: boolean,
    replacing: boolean,
}

export interface InstalledSensorLocal extends SensorCommonsLocal, InstalledSensorLocalLoadingProps {
}


export type InstalledSensor = InstalledSensorOnline & { local: InstalledSensorLocal };

export type Sensor = AvailableSensor | InstalledSensor;
