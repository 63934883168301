import { stringify } from 'csv-stringify/dist/esm/sync';

export function toCsv(filename: string, data: Record<string, any>[], columnKeys?: string[], columnNames?: string[]) {
	if (data.length === 0) {
		return;
	}
	const keysToSelect = columnKeys ?? Object.keys(data[0]);
	const firstRow = columnNames ?? keysToSelect;
	const csv = stringify(
		[
			firstRow,
			...data.map((d) => keysToSelect.map((k) => d[k])),
		]
	);
	const url = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv'}));
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = url;
	a.download = `${filename}.csv`;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
}
