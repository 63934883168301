import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsNetworkLabels } from '../../sections/Settings/SettingsNetworkLabels';
import { BoardNetworkConfig } from '../../models/api/BoardNetworkConfig';
import { boardNetwork$ } from '../../services/polling';
import { useReadable } from '../../observables/hooks';

interface NetworkProps {
	onLock: () => unknown,
	onUnlock: () => unknown,
	setData: (data: BoardNetworkConfig|null) => unknown,
}

export function Network({ setData }: NetworkProps): ReactElement {
	const { t } = useTranslation();
	const boardNetwork = useReadable(boardNetwork$);
	const [networkConfig, setNetworkConfig] = useState(boardNetwork);

	useEffect(() => {
		setData(networkConfig);
	}, [networkConfig, setData]);
	return <>
		<p>{t("NetworkInfo")}</p>
		<div className='row'>
			{Object.entries(boardNetwork?.interfaces || {}).map(([nic, config]) => <SettingsNetworkLabels interfaceName={nic} interfaceConfig={config!} onChange={(m) => {
				if (networkConfig) {
					networkConfig.interfaces[nic] = m;
					setNetworkConfig({ ...networkConfig });
				}
			}} key={nic} />)}
		</div>
	</>;
}
