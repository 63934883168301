import React, { ReactElement, useEffect, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { getColor } from '../../helpers/css';

interface UnknownIconWithTooltipProps {
	size: 'small'|'medium'|'large',
	tooltipText: string,
}
export function UnknownIconWithTooltip({ size, tooltipText }: UnknownIconWithTooltipProps): ReactElement {
	const [iconSizePx, setIconSizePx] = useState(16);
	const [fontSizePx, setFontSizePx] = useState(12);

	useEffect(() => {
		if (size === 'large') {
			setIconSizePx(32);
			setFontSizePx(24);
		} else if (size === 'medium') {
			setIconSizePx(24);
			setFontSizePx(16);
		} else if (size === 'small') {
			setIconSizePx(16);
			setFontSizePx(12);
		}
	}, [size]);
	return <ABB.WithTooltip>
		<span 
			className='m-auto'
			style={{ 
				height: `${iconSizePx}px`, 
				width: `${iconSizePx}px`, 
				fontSize: `${fontSizePx}px`, 
				borderRadius: '300px', 
				backgroundColor: getColor('color-status-orange'),
				textAlign: 'center',
				color: 'white',
				cursor: 'default',
				userSelect: 'none',
			}}>
				?
		</span>
		<ABB.Tooltip followCursor={true} style={{ fontWeight: 'normal', textTransform: 'none' }}>{tooltipText}</ABB.Tooltip>
</ABB.WithTooltip>
}
