import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigurationModalProps } from "./ConfigurationDialogSelector";
import * as ABB from "@abb/abb-common-ux-react";
import { useState } from "react";
import { Dropdown, DropdownOption, Input } from "@abb/abb-common-ux-react";
import { fireNotification } from "../toasts";

export function SmartArmConfigDialog(
	props: ConfigurationModalProps
): React.ReactElement {
	const { t } = useTranslation();
	type FormData = { sensorID: string; channel: number };
	const validators: {
		[key in keyof FormData]: (v: string | null | undefined) => {
			valid: boolean;
			text?: string;
		};
	} = useMemo(
		() => ({
			sensorID: (v) => {
				if (typeof v !== "string") {
					return {
						valid: false,
					};
				}
				if (v.length === 0) {
					return {
						valid: false,
						text: t("Mandatory field"),
					};
				}
				return {
					valid: true,
				};
			},
			channel: (value) => {
				if (typeof value !== "string") {
					return {
						valid: false,
						text: t("Invalid value"),
					};
				}
				const valueNumber = Number(value);
				if (Number.isNaN(valueNumber)) {
					return {
						valid: false,
						text: t("Channel must be a number"),
					};
				}
				if (valueNumber < -1 || valueNumber > 63) {
					return {
						valid: false,
						text: t("Channel must be between -1 (auto) and 63 included"),
					};
				}
				return {
					valid: true,
				};
			},
		}),
		[t]
	);

	const [formData, setFormData] = useState<FormData>({
		sensorID:
			props.defaultConfig && "sensorID" in props.defaultConfig
				? props.defaultConfig.sensorID
				: "",
		channel:
			props.defaultConfig && "channel" in props.defaultConfig
				? props.defaultConfig.channel
				: 0,
	});
	const [valid, setValid] = useState(false);

	const updateValidity = useCallback(() => {
		setValid(
			!Object.entries(validators).some(
				([key, validator]) =>
					!validator(
						typeof formData[key as keyof FormData] === "number"
							? String(formData[key as keyof FormData])
							: (formData[key as keyof FormData] as string | undefined | null)
					).valid
			)
		);
	}, [formData, setValid, validators]);

	useEffect(() => {
		updateValidity();
	}, [updateValidity]);

	return (
		<ABB.Dialog
			isOpen={props.isOpen}
			showCloseButton={false}
			closeOnEscape={true}
			closeOnLostFocus={false}
			dimBackground={true}
			title={props.title}
			standardButtonsOnBottom={[
				{
					text: t("Cancel"),
					type: "discreet-blue",
					handler: () => props.onClose(),
				},
				{
					text: t("Send"),
					type: "primary-blue",
					handler: () => {
						if (!valid) {
							fireNotification({
								text: t("Form data is not valid"),
								severity: "warn",
								type: "banner",
							});
							return;
						}
						if (!props.configuring) {
							props.onSubmit(formData);
						}
					},
				},
			]}
		>
			<div style={{ minHeight: 400, minWidth: 400 }}>
				<div className="mb-2">
					<Input
						type="discreet"
						dataType="number"
						label={t("Address")}
						required={true}
						onValueChange={(value) =>
							setFormData({
								...formData,
								sensorID: value,
							})
						}
						value={String(formData.sensorID ?? 0)}
						showValidationBarWhenValid={false}
						showValidationIconWhenValid={false}
						showValidationBarWhenInvalid={true}
						showValidationIconWhenInvalid={true}
					/>
				</div>

				<Dropdown
					label={t("Channel")}
					multiselect={false}
					allowAdd={false}
					onChange={([selection]) =>
						setFormData({
							...formData,
							channel: selection?.value || 0,
						})
					}
					required={true}
					value={[{ value: formData.channel }]}
				>
					<DropdownOption
						label={"0 (" + t("default") + ")"}
						value={0}
						key={0}
					></DropdownOption>
					<DropdownOption
						label={t("Auto-detect")}
						value={-1}
						key={-1}
					></DropdownOption>
					{new Array(63).fill(0).map((_, i) => (
						<DropdownOption
							label={(i + 1).toString()}
							value={i + 1}
							key={i + 1}
						></DropdownOption>
					))}
				</Dropdown>
			</div>
		</ABB.Dialog>
	);
}
