import React, { ReactElement } from 'react';
import { Card } from '../../components';
import { WidgetProps } from '../../models';
import * as ABB from "@abb/abb-common-ux-react";
import { useTranslation } from 'react-i18next';

export interface CapacitorStatusItemProps {
	min: number,
	max: number,
	value: number,
	lowAlarm: number|undefined,
	lowWarn: number|undefined,
	highAlarm: number|undefined,
	highWarn: number|undefined,
	unit: string,
}
export interface CapacitorStatusProps extends WidgetProps {
	voltage: CapacitorStatusItemProps,
	capacity: CapacitorStatusItemProps
}

export function CapacitorStatus(props: CapacitorStatusProps): ReactElement {
	const { t } = useTranslation();
	return <Card
		title={props.title}
		installed={props.installed}
		disconnected={props.disconnected}
		desktopCols={props.desktopCols ?? 12}
		tabletCols={props.tabletCols ?? 12}
		mobileCols={props.mobileCols ?? 12}
		comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
	>
		<div className="row">
			<div className="col-6">
				<ABB.Gauge 
					type="bar"
					max={props.voltage.max}
					min={props.voltage.min}
					value={props.voltage.value}
					unit={props.voltage.unit}
					lowAlarm={props.voltage.lowAlarm}
					lowWarn={props.voltage.lowWarn}
					highAlarm={props.voltage.highAlarm}
					highWarn={props.voltage.highWarn}
					label={t("Voltage")}
				/>
			</div>
			<div className="col-6">
				<ABB.Gauge 
					type="bar"
					max={props.capacity.max}
					min={props.capacity.min}
					value={props.capacity.value}
					unit={props.capacity.unit}
					lowAlarm={props.capacity.lowAlarm}
					lowWarn={props.capacity.lowWarn}
					highAlarm={props.capacity.highAlarm}
					highWarn={props.capacity.highWarn}
					label={t("Capacity")}
					decimals={4}
				/>
			</div>
		</div>
	</Card>
}
