import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigurationModalProps } from "./ConfigurationDialogSelector";
import * as ABB from "@abb/abb-common-ux-react";
import { useState } from "react";
import { Input, RadioGroup, RadioOption } from "@abb/abb-common-ux-react";
import { useEffect } from "react";
import { useCallback } from "react";
import { fireNotification } from "../toasts";

export function EnvironmentalConfigDialog(
	props: ConfigurationModalProps
): React.ReactElement {
	const { t } = useTranslation();
	type FormData = {
		connection: "ethernet" | "modbus";
		ipAddress: string | null;
	};
	const [formData, setFormData] = useState<FormData>({
		connection:
			props.defaultConfig &&
			"ipAddress" in props.defaultConfig &&
			props.defaultConfig.ipAddress
				? "ethernet"
				: "modbus",
		ipAddress:
			props.defaultConfig && "ipAddress" in props.defaultConfig
				? props.defaultConfig.ipAddress
				: null,
	});
	const validators: {
		[key in keyof FormData]: (v: string | null | undefined) => {
			valid: boolean;
			text?: string;
		};
	} = useMemo(
		() => ({
			connection: (v) => {
				if (typeof v !== "string") {
					return {
						valid: false,
					};
				}
				if (v.length === 0) {
					return {
						valid: false,
						text: t("Mandatory field"),
					};
				}
				return {
					valid: ["ethernet", "modbus"].includes(v),
				};
			},
			ipAddress: (v) => {
				if (formData.connection === "modbus") {
					return {
						valid: true,
					};
				}
				if (typeof v !== "string") {
					return {
						valid: false,
						text: t("Invalid value"),
					};
				}
				if (v.length === 0) {
					return {
						valid: false,
						text: t("Mandatory field"),
					};
				}
				const ipParts = v.split(".");
				if (ipParts.length !== 4) {
					return {
						valid: false,
						text: t("IP must have 4 numbers"),
					};
				}
				if (ipParts.some((ipPart) => !/^\d+$/.test(ipPart))) {
					return {
						valid: false,
						text: t("IP contains invalid digits"),
					};
				}
				if (ipParts.some((ipPart) => Number(ipPart) > 255)) {
					return {
						valid: false,
						text: t("Each number must be between 0 and 255 (included)"),
					};
				}
				return {
					valid: true,
				};
			},
		}),
		[t, formData]
	);

	const [valid, setValid] = useState(false);

	const updateValidity = useCallback(() => {
		setValid(
			!Object.entries(validators).some(
				([key, validator]) => !validator(formData[key as keyof FormData]).valid
			)
		);
	}, [formData, setValid, validators]);

	useEffect(() => {
		updateValidity();
	}, [updateValidity]);

	return (
		<ABB.Dialog
			isOpen={props.isOpen}
			showCloseButton={false}
			closeOnEscape={true}
			closeOnLostFocus={false}
			dimBackground={true}
			title={props.title}
			standardButtonsOnBottom={[
				{
					text: t("Cancel"),
					type: "discreet-blue",
					handler: () => props.onClose(),
				},
				{
					text: t("Send"),
					type: "primary-blue",
					handler: () => {
						if (!valid) {
							fireNotification({
								text: t("Form data is not valid"),
								severity: "warn",
								type: "banner",
							});
							return;
						}
						if (!props.configuring) {
							props.onSubmit(
								formData.connection === "modbus"
									? {
											// modbus
											ipAddress: "",
									  }
									: {
											// ethernet
											ipAddress: formData.ipAddress,
									  }
							);
						}
					},
				},
			]}
		>
			<div style={{ minHeight: 400, minWidth: 400 }}>
				<RadioGroup
					value={formData.connection}
					onChange={(connection) =>
						setFormData({
							...formData,
							connection: connection as "modbus" | "ethernet",
						})
					}
				>
					<RadioOption value={"modbus"} text={t("ModBus")} />
					<RadioOption value={"ethernet"} text={t("Ethernet")} />
				</RadioGroup>
				<Input
					dataType="text"
					label={t("IP")}
					validator={validators.ipAddress}
					required={formData.connection === "ethernet"}
					disabled={formData.connection !== "ethernet"}
					onValueChange={(value) =>
						setFormData({
							...formData,
							ipAddress: value || null,
						})
					}
					value={formData.ipAddress ?? ""}
					showValidationBarWhenValid={false}
					showValidationIconWhenValid={false}
					showValidationBarWhenInvalid={true}
					showValidationIconWhenInvalid={true}
				/>
			</div>
		</ABB.Dialog>
	);
}
