import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { getProductInfoData } from '../../services/startup-and-commissioning';
import { useReadable } from '../../observables/hooks';
import { values$ } from '../../services/polling';
import { user$ } from '../../services/user';
import { Role } from '../../enums';

export type ProductInfoData = Partial<ProductInfoNameplate> & { serialNumber: string, orderNumber: string };

export interface ProductInfoNameplate {
	serialNumber: string,
	orderNumber: string,
	manufacturingDateUs: string,
	modelAndRatings: string,
	classification: string,
	mass: string,
	shortCircuitBreakingCurrent: string,
	voltage: string,
	DCComponentOfRatedShortCircuitBreakingCurrent: string,
	lightningImpulseWithstandVoltage: string,
	powerFrequencyWithstandVoltage: string,
	operatingSequence: string,
	frequency: string,
	normalCurrent: string,
	shortTimeWithstandCurrent: string,
	shortCircuitDuration: string,
	auxiliaryVoltage: string,
	standard: string,
};

interface ProductInfoProps {
	onLock: () => unknown,
	onUnlock: () => unknown,
	setData: (data: Partial<ProductInfoData> & { serialNumber: string, orderNumber: string } | null) => unknown,
	editMode: boolean,
}

interface ProductInfoEditButtonProps {
	onClick: (edit: boolean) => void,
}

export function ProductInfoEditButton({ onClick }: ProductInfoEditButtonProps): ReactElement {
	const { t } = useTranslation();
	const [editing, setEditing] = useState(false);
	const user = useReadable(user$);
	const userRole = user?.role ?? Role.None;
	if (userRole !== Role.ABB) {
		return <></>
	}
	return <ABB.Button
		text={!editing ? t('EditProductData') : t('Cancel')}
		sizeClass="small"
		icon={!editing ? 'abb/edit' : undefined}
		onClick={() => {
			onClick(!editing);
			setEditing(!editing);
		}}
	/>
}

export function ProductInfo({ setData, editMode }: ProductInfoProps): ReactElement {
	const { t } = useTranslation();
	const values = useReadable(values$);
	const defaultNameplateData = useMemo<ProductInfoNameplate>(() => getProductInfoData(values.values), [values.values]);
	const [nameplateData, setNameplateData] = useState<ProductInfoData & ProductInfoNameplate>(getProductInfoData(values.values));
	const [isEditing, setIsEditing] = useState(editMode);

	useEffect(() => {
		if (editMode) {
			const dataToChange: ProductInfoData = {
				serialNumber: nameplateData.serialNumber,
				orderNumber: nameplateData.orderNumber,
			}
			Object.entries(nameplateData)
				.filter(([key]) => key !== 'serialNumber' && key !== 'orderNumber')
				.forEach(([key, value]) => {
					if (defaultNameplateData[key as keyof ProductInfoNameplate] !== value) {
						dataToChange[key as keyof ProductInfoNameplate] = value;
					}
				});
			setData(dataToChange);
		} else {
			setData({
				serialNumber: nameplateData.serialNumber,
				orderNumber: nameplateData.orderNumber,
			});
		}
	}, [nameplateData, setData, editMode, defaultNameplateData]);

	const updateNameplate = useCallback((change: Partial<ProductInfoData>) => {
		setNameplateData({ ...nameplateData, ...change });
	}, [nameplateData]);

	useEffect(() => {
		setIsEditing(editMode);
	}, [editMode]);

	return <>
		<p>{t("ProductInfoIntro")}</p>
		<div className='d-flex flex-wrap'>
			<ABB.Input
				label={t('SerialNumber')}
				dataType='text'
				className='mr-3'
				value={nameplateData.serialNumber}
				onValueChange={(newValue) => updateNameplate({ serialNumber: newValue })}
			/>
			<ABB.Input
				label={t('OrderNumber')}
				dataType='text'
				value={nameplateData.orderNumber}
				onValueChange={(newValue) => updateNameplate({ orderNumber: newValue })}
			/>
		</div>

		<div className='row mt-3'>
			<div className='product-list-item product-list-item-bold col-lg-3 col-sm-6 col-12'>
				{!isEditing ? <><span>{t('ManufacturingDate')}</span>
				<span>{defaultNameplateData.manufacturingDateUs ? new Date(Number(defaultNameplateData.manufacturingDateUs) / 1000).toLocaleDateString() : '-'}</span></> : <ABB.Input 
					dataType='date'
					label={t('ManufacturingDate')}
					value={(nameplateData.manufacturingDateUs ? new Date(Number(nameplateData.manufacturingDateUs) / 1000) : new Date()).toISOString().split('T')[0]}
					onValueChange={(manufacturingDate) => updateNameplate({ manufacturingDateUs: (new Date(manufacturingDate).getTime() * 1000).toString() })}
				/>}
			</div>
			<div className='product-list-item product-list-item-bold col-lg-3 col-sm-6 col-12'>
				{!isEditing ? <>
					<span>{t('ModelAndRatings')}</span>
					<span>{defaultNameplateData.modelAndRatings}</span>
				</> :
					<ABB.Input
						label={t('ModelAndRatings')}
						dataType="text"
						value={nameplateData.modelAndRatings}
						onValueChange={(newValue) => updateNameplate({ modelAndRatings: newValue })}
					/>}
			</div>
			<div className='product-list-item product-list-item-bold col-lg-3 col-sm-6 col-12'>
				{!isEditing ? <>
					<span>{t('Classification')}</span>
					<span>{defaultNameplateData.classification}</span>
				</>
					: <ABB.Input
						label={t('Classification')}
						dataType="text"
						value={nameplateData.classification}
						onValueChange={(classification) => updateNameplate({ classification })}
					/>}
			</div>
			<div className='product-list-item product-list-item-bold col-lg-3 col-sm-6 col-12'>
			{!isEditing ? <>
					<span>{t('Standard')}</span>
					<span>{defaultNameplateData.standard}</span>
				</>
					: <ABB.Input
						label={t('Standard')}
						dataType="text"
						value={nameplateData.standard}
						onValueChange={(standard) => updateNameplate({ standard })}
					/>}
			</div>
		</div>

		<hr />

		<div className='row mt-3'>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("Mass")}</span>
				<span>{defaultNameplateData.mass}</span></> : <ABB.Input
					dataType='text'
					label={t("Mass")}
					onValueChange={(mass) => updateNameplate({ mass })}
					value={nameplateData.mass}	
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <>
				<span>{t("ShortCircuitBreakingCurrent")}</span>
				<span>{defaultNameplateData.shortCircuitBreakingCurrent}</span>
				</> : <ABB.Input 
					dataType='text'
					label={t("ShortCircuitBreakingCurrent")}
					onValueChange={(shortCircuitBreakingCurrent) => updateNameplate({ shortCircuitBreakingCurrent })}
					value={nameplateData.shortCircuitBreakingCurrent}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("Voltage")}</span>
				<span>{defaultNameplateData.voltage}</span></> : <ABB.Input 
					dataType='text'
					label={t("Voltage")}
					onValueChange={(voltage) => updateNameplate({ voltage })}
					value={nameplateData.voltage}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("DCComponentOfRatedShortCircuit")}</span>
				<span>{defaultNameplateData.DCComponentOfRatedShortCircuitBreakingCurrent}</span></> : <ABB.Input 
					dataType='text'
					label={t("DCComponentOfRatedShortCircuit")}
					onValueChange={(DCComponentOfRatedShortCircuitBreakingCurrent) => updateNameplate({ DCComponentOfRatedShortCircuitBreakingCurrent })}
					value={nameplateData.DCComponentOfRatedShortCircuitBreakingCurrent}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("LightningImpulseWithstandVoltage")}</span>
				<span>{defaultNameplateData.lightningImpulseWithstandVoltage}</span></> : <ABB.Input 
					dataType='text'
					label={t("LightningImpulseWithstandVoltage")}
					onValueChange={(lightningImpulseWithstandVoltage) => updateNameplate({ lightningImpulseWithstandVoltage })}
					value={nameplateData.lightningImpulseWithstandVoltage}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("PowerFrequencyWithstandVoltage")}</span>
				<span>{defaultNameplateData.powerFrequencyWithstandVoltage}</span></> : <ABB.Input 
					dataType='text'
					label={t("PowerFrequencyWithstandVoltage")}
					onValueChange={(powerFrequencyWithstandVoltage) => updateNameplate({ powerFrequencyWithstandVoltage })}
					value={nameplateData.powerFrequencyWithstandVoltage}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("OperatingSequence")}</span>
				<span>{defaultNameplateData.operatingSequence}</span></> : <ABB.Input 
					dataType='text'
					label={t("OperatingSequence")}
					onValueChange={(operatingSequence) => updateNameplate({ operatingSequence })}
					value={nameplateData.operatingSequence}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("Frequency")}</span>
				<span>{defaultNameplateData.frequency}</span></> : <ABB.Input 
					dataType='text'
					label={t("Frequency")}
					onValueChange={(frequency) => updateNameplate({ frequency })}
					value={nameplateData.frequency}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("NormalCurrent")}</span>
				<span>{defaultNameplateData.normalCurrent}</span></> : <ABB.Input 
					dataType='text'
					label={t("NormalCurrent")}
					onValueChange={(normalCurrent) => updateNameplate({ normalCurrent })}
					value={nameplateData.normalCurrent}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("ShortTimeWithstandCurrent")}</span>
				<span>{defaultNameplateData.shortTimeWithstandCurrent}</span></> : <ABB.Input 
					dataType='text'
					label={t("ShortTimeWithstandCurrent")}
					onValueChange={(shortTimeWithstandCurrent) => updateNameplate({ shortTimeWithstandCurrent })}
					value={nameplateData.shortTimeWithstandCurrent}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("DurationOfShortCircuit")}</span>
				<span>{defaultNameplateData.shortCircuitDuration}</span></> : <ABB.Input 
					dataType='text'
					label={t("DurationOfShortCircuit")}
					onValueChange={(shortCircuitDuration) => updateNameplate({ shortCircuitDuration })}
					value={nameplateData.shortCircuitDuration}
				/>}
			</div>
			<div className='product-list-item col-sm-6 col-12'>
				{!isEditing ? <><span>{t("AuxiliaryVoltage")}</span>
				<span>{defaultNameplateData.auxiliaryVoltage}</span></> : <ABB.Input 
					dataType='text'
					label={t("AuxiliaryVoltage")}
					onValueChange={(auxiliaryVoltage) => updateNameplate({ auxiliaryVoltage })}
					value={nameplateData.auxiliaryVoltage}
				/>}
			</div>
		</div>
	</>;
}
