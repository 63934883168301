import React, { ReactElement } from 'react';
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from '../../components';
import { WidgetProps } from "../../models"
import { BootstrapHelper } from '../../helpers';

export interface DonutChartProps extends ABB.DonutChartProps, WidgetProps {

}

export function DonutChart(props: DonutChartProps): ReactElement {
	return (
		<Card title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}>
			<ABB.DonutChart data={props.data} key="donuts" allowValueHiding={true} showLegend={true} />
		</Card>
	)
}
