import { ChartLastOperation } from '../../../enums';
import { DateTimeFormatHelper } from '../../../helpers';
import { ApiIdentityItem, ChartDateRange, LastUpdateType } from '../../../models';
import { BarChartProps, BarChartWithChartTooltipProps } from '../../../widgets';
import { chartFilters$, ChartPropsConfig, getChartDataList, readThresholds } from '.';
import { NamedSource, PathSource, WidgetConfig } from '../../../pages/DynamicPage';
import { loadWaveformChartData, WaveformChartPropsConfig } from './waveform';
import { makePollingWritable } from '../../../utils/polling-subject';
import { getDataAggregation, getMappedEventState, getSamples } from '..';
import { SyncAndPositionStatus } from '../../../enums/SyncAndPositionStatus';
import { roundNumber } from '../../../utils/number';
import { SyncLabel } from '../../../components';

export async function loadBarChartData(config: WidgetConfig, props: Partial<BarChartProps>): Promise<BarChartProps> {
	console.info("loadBarChartData");
	const barChart = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as BarChartPropsConfig;
		const chartFilters = chartFilters$.getValue();
		const selectedFilter = propsConfig.filterRef ? chartFilters[propsConfig.filterRef.value] : null;
		const scaleType = selectedFilter ?? barChart.scaleType;
		if (scaleType === null || scaleType === undefined)
			barChart.scaleType = ChartLastOperation.MIN;

		const samples: ApiIdentityItem[] = [];

		if (propsConfig.data?.source) {
			samples.push(propsConfig.data.source);
		}


		if (propsConfig.line?.source) {
			samples.push(propsConfig.line.source);
		}

		readThresholds(barChart, propsConfig);

		let boundaries = undefined;
		if (barChart.alarmHigh !== undefined) {
			boundaries = { max: barChart.alarmHigh + (barChart.alarmHigh - (barChart.alarmLow ?? 0)) * 0.1 };
		}
		if (barChart.alarmLow !== undefined) {
			boundaries = { ...(boundaries ?? {}), min: barChart.alarmLow - ((barChart.alarmHigh ?? 100) - barChart.alarmLow) * 0.1 };
		}
		const data = await getChartDataList(scaleType ?? ChartLastOperation.MIN, boundaries, ...samples);
		if (barChart.alarmHighTh !== undefined && barChart.alarmHigh !== undefined) {
			const avgValue = barChart.alarmHigh - barChart.alarmHighTh;
			data.data = data.data.map((sample) => {
				return {
					...sample,
					...(Object.keys(sample) as (keyof typeof sample)[])
						.filter((k) => k.startsWith('value'))
						// eslint-disable-next-line eqeqeq
						.filter((k) => sample[k] != undefined)
						.reduce((mapped, k) => {
							mapped[k] = ((sample[k] as any) - avgValue) as any;
							return mapped;
						}, {} as Partial<typeof sample>)
					};
			});
		}
		if (barChart.xAxisType === 'category') {
			data.data = data.data.map((sample) => ({
				...sample,
				category: DateTimeFormatHelper.toDate(sample.date_us)!.toLocaleTimeString(undefined, {
					timeStyle: 'short',
					hourCycle: 'h12'
				}),
			}))
		}

		barChart.data = data.data;
		barChart.lastUpdate = DateTimeFormatHelper.toDate(data.lastValue.date_us);
		barChart.lastUpdateType = LastUpdateType.Sample;
		barChart.fetching = false;
	} catch (e) {
		console.error("loadBarChartData", e);
		barChart.exception = e;
	}
	return barChart as BarChartProps;
}

export async function loadBarChartWithChartTooltipData(config: WidgetConfig, props: Partial<BarChartWithChartTooltipProps>): Promise<BarChartWithChartTooltipProps> {
	console.info("loadBarChartWithChartTooltipData");
	const barChartWithChartTooltip = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as BarChartWithChartTooltipPropsConfig;
		const chartFilters = chartFilters$.getValue();
		let { startDate, endDate } = chartFilters[barChartWithChartTooltip.filterRef!] as ChartDateRange;
		if (endDate.getTime() > new Date().getTime()) {
			endDate = new Date();
		}
		const isSyncEvent = (event: SyncAndPositionStatus): 0|1 => {
			switch (event) {
				case SyncAndPositionStatus.OpenSync:
				case SyncAndPositionStatus.CloseSync: {
					return 1;
				}
				default:
					return 0;
			}
		}
		const eventsRaw = (await getDataAggregation(
			[propsConfig.event.source],
			undefined,
			startDate,
			endDate,
		)).devices.acu[1].events;
		const events = Object.keys(eventsRaw)
			.flatMap((k) => eventsRaw[k])
			.map((e) => ({ ...getMappedEventState(propsConfig.event.source.name, e.state, 'acu'), timestamp: e.timestamp_us }));
		const data = await getSamples(
			propsConfig.data!.source,
			events.length,
			DateTimeFormatHelper.toDate(events[0].timestamp)!, 
			DateTimeFormatHelper.toDate(events[events.length - 1].timestamp)!,
		);
		barChartWithChartTooltip.data = data!.map((sample, i) => ({
			date_us: sample.timestamp_us,
			date: sample.timestamp_us / 1000,
			category: DateTimeFormatHelper.toDate(sample.timestamp_us)!.toLocaleTimeString(undefined, {
				timeStyle: 'short',
				hourCycle: 'h12',
			}),
			icon: isSyncEvent(events[i].value as SyncAndPositionStatus),
			value: roundNumber(sample.value),
		}));
		barChartWithChartTooltip.lastUpdate = DateTimeFormatHelper.toDate(events[events.length - 1].timestamp);
		barChartWithChartTooltip.lastUpdateType = LastUpdateType.Event;
		if (barChartWithChartTooltip.tooltipChartType === 'WaveformChart' && !barChartWithChartTooltip.tooltipChartPoller$) {
			barChartWithChartTooltip.tooltipChartPoller$ = makePollingWritable(
				barChartWithChartTooltip!.tooltipChartProps$!.getValue(), 
				{
					dataProvider: () => loadWaveformChartData(
						{ propsConfig: { ...propsConfig.tooltipChartConfig, dateFrom: { value: barChartWithChartTooltip.selectedTimestamp$!.getValue() } } } as unknown as WidgetConfig,
						barChartWithChartTooltip.tooltipChartProps$!.getValue(),
					).then((res) => {
						barChartWithChartTooltip.tooltipChartProps$!.next({
							...res,
							topRightContent: () => SyncLabel({
								sync: Boolean(barChartWithChartTooltip.data!.find((d) => d.date_us === barChartWithChartTooltip.selectedTimestamp$!.getValue())?.icon),
							}),
						});
						return res;
					}).catch((err) => {
						console.error('loadWaveformChartData', err);
						return barChartWithChartTooltip.tooltipChartProps$!.getValue();
					})	
				}
			);
		}
	} catch (e) {
		console.error("loadBarChartWithChartTooltipData", e);
		barChartWithChartTooltip.exception = e;
	} finally {
		barChartWithChartTooltip.fetching = false;
	}
	return barChartWithChartTooltip as BarChartWithChartTooltipProps;
}

export interface BarChartPropsConfig extends ChartPropsConfig {
	title: {
		i18n: string,
	},
	showLegend: {
		value: boolean,
	},
	yAxisLabel: {
		i18n: string,
	},
	xAxisLabel: {
		i18n: string,
	},
	yRightAxisLabel: {
		i18n: string,
	},
	data?: {
		source: NamedSource,
	},
	line?: any,
	name: {
		i18n: string,
	},
	color: {
		value: string,
	},
	hoverColor: {
		value: string,
	},
	alarmHighTh?: {
		source: PathSource
	},
	alarmLowTh?: {
		source: PathSource
	},
	alarmHigh?: {
		source: PathSource
	},
	alarmLow?: {
		source: PathSource
	},
	warningHighTh?: {
		source: PathSource
	},
	warningLowTh?: {
		source: PathSource
	},
	warningHigh?: {
		source: PathSource
	},
	warningLow?: {
		source: PathSource
	},
	percentageWidth?: {
		value: number,
	},
	minWidth?: {
		value: number,
	},
	dateFormat?: {
		value: string,
	},
	icons?: {
		mappings: { value: string }[]|{ value: number }[]|{ value: boolean }[],
		sources: { value: string }[],
		field: { value: string|null },
		alignment: { value: 'top'|'bottom'|'middle' },
		width: { value: number },
		height: { value: number },
	},
	xAxisType?: {
		value: 'category'|'date',
	},
}

export interface BarChartWithChartTooltipPropsConfig extends BarChartPropsConfig {
	tooltipChartType: {
		value: 'WaveformChart' // TODO handle other chart options if needed
	},
	tooltipChartConfig: WaveformChartPropsConfig,
	event: {
		source: NamedSource
	}
}
