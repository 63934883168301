import * as Enums from '../enums/DocumentType';
import { Document } from '../models'


export class DocumentHelper {
    //REFACTOR AFTER API UPDATE
    static FromDocumentTypeEnumToApiDocumentType(doc_type: Enums.DocumentType): string {
        let documentTypeUrl = "user";
        switch (doc_type) {
            //case Enums.DocumentType.ABB:
            //documentTypeUrl = "abb";//"firmware";
            //break;
            case Enums.DocumentType.Reports:
                documentTypeUrl = "report";//admin
                break;
            case Enums.DocumentType.Uploaded:
                documentTypeUrl = "user";
                break;
        }
        return documentTypeUrl;
    }
    //REFACTOR AFTER API UPDATE
    static FromApiDocumentTypeToDocumentTypeEnum(type: string): Enums.DocumentType {
        let resDocType: Enums.DocumentType = Enums.DocumentType.Uploaded;
        switch (type) {
            case "user":
                resDocType = Enums.DocumentType.Uploaded;
                break;
            case "report"://"admin":
            case "Reports":
                resDocType = Enums.DocumentType.Reports;
                break;
            case "abb"://firmware
            case "ABB":
                resDocType = Enums.DocumentType.ABB;
                break;
        }
        return resDocType;
    }

    static GetDocumentExtension(doc: Document): string {
        let extension: string = "";
        try {
            if (doc.file_name) {
                let splittedFilename = doc.file_name.split('.');
                if (splittedFilename.length > 0)
                    extension = '.' + splittedFilename[splittedFilename.length - 1]
            }

            if (extension === "") {
                let splittedFilename = doc.url.split('.');
                if (splittedFilename.length > 0)
                    extension = '.' + splittedFilename[splittedFilename.length - 1]
            }
        }
        catch
        {
            extension = "";
        }
        return extension;
    }

    static GetDocumentSizeFormatted(doc: Document): string {
        let result: string = "";
        let suffixes: string[] = ["B", "KB", "MB", "GB"]

        if (doc.size) {
            var integerSize = parseInt(doc.size, 10);
            if (!isNaN(integerSize)) {

                let flag: boolean = false;
                let iterator: number = 0;
                while (integerSize > 0 && !flag) {
                    if (integerSize >= 1024 && iterator < (suffixes.length - 1)) {
                        integerSize = Math.round(integerSize/1024);
                        iterator++;
                    }
                    else {
                        result = integerSize + " " + suffixes[iterator];
                        flag = true;
                    }
                }
            }
        }
        return result;
    }
}

