import React, { ReactElement, useMemo } from 'react'
import * as ABB from "@abb/abb-common-ux-react"
import { useTranslation } from 'react-i18next';
import { isDemo, scenarios$ } from '../../services/demo';
import { useReadable } from '../../observables/hooks';

interface AdminItemProps {
	hasMenu: boolean;
	userName: string;
	menuOpen?: boolean;
	mobileVisble?: boolean;
	tabletVisible?: boolean;
	desktopVisible?: boolean;
	onToggleUserMenu: (toggle?: boolean, item?: any) => void;
}

export default function AdminItem(props: AdminItemProps): ReactElement {
	const { t } = useTranslation();
	const scenarios = useReadable(scenarios$);
	let classes = ""

	if (props) {
		classes += props.mobileVisble ? "d-block " : "d-none ";
		classes += props.tabletVisible ? "d-md-block " : "d-md-none ";
		classes += props.desktopVisible ? "d-lg-block" : "d-lg-none";
	}

	const menuChildren = useMemo(() => {
		if (isDemo && scenarios && scenarios.length > 1) {
			return [<ABB.MenuItem text={t('About')} itemId="about" key="about" />,<ABB.MenuItem text={t('Change scenario')} itemId="scenario" key="scenario" />, <ABB.MenuItem text={t("Logout")} itemId="logout" key="logout" />];
		}
		return [<ABB.MenuItem text={t('About')} itemId="about" key="about" />, <ABB.MenuItem text={t("Logout")} itemId="logout" key="logout" />];
	}, [t, scenarios]);
	return (
		<ABB.AppTopNaviItem
			icon="abb/user-in-circle"
			className={classes}
			text={props.userName}
			onClick={() => props.onToggleUserMenu()}
			hasMenu={props.hasMenu}
		>
			{props.hasMenu && props.menuOpen && (
				<ABB.Menu style={{ minWidth: "120px", zIndex: 10000 }} onLostFocus={() => props.onToggleUserMenu(false)} onSelect={(item: any) => props.onToggleUserMenu(false, item)} alignToParent="right">
					{/* <ABB.MenuItem text={t('Help')} /> */}
					{/* <ABB.MenuItem text={t("Keyboard shortcuts")} disabled={true} /> */}
					{/* <ABB.MenuItem text={t("Toggle Theme")} itemId="toggleTheme" /> */}
					{/* <ABB.MenuItem text={t('Preferences')} itemId="preferences" /> */}
					{menuChildren}
				</ABB.Menu>
			)}
		</ABB.AppTopNaviItem>
	)
}
