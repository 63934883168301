import { getHealthIndexStatus, getLastEventValue, getMultipleSamples } from '.';
import { ChartLastOperation } from '../../enums';
import { DateTimeFormatHelper, HealthIndexStatusHelper } from '../../helpers';
import { getColor } from '../../helpers/css';
import { LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { CumulatedWorkloadProps } from '../../widgets';
import { apiService } from '../api';
import { getChartDataList } from './chart';

export async function loadCumulatedWorkload(config: WidgetConfig, props: Partial<CumulatedWorkloadProps>): Promise<CumulatedWorkloadProps> {
	const cumulatedWorkload = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as CumulatedWorkloadPropsConfig;

		
		const [numberOfOperations, value, avgInterruptedCurrent, ...additionalStats] = await getMultipleSamples('number', [
			propsConfig.numberOfOperations.source,
			propsConfig.value.source,
			propsConfig.avgInterruptedCurrent.source,
			...propsConfig.additionalStats.map((as) => as.value.source),
		]).then((res) => {
			res.forEach((sample) => {
				const previous = cumulatedWorkload.lastUpdate;
				cumulatedWorkload.lastUpdate = getGreaterDate(cumulatedWorkload.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined);
				if (previous !== cumulatedWorkload.lastUpdate) {
					cumulatedWorkload.lastUpdateType = LastUpdateType.Sample;
				}
			});
			return res;
		});
		cumulatedWorkload.numberOfOperations = numberOfOperations!.value ?? undefined;
		cumulatedWorkload.value = value!.value ?? undefined;
		const workloadStatus = getLastEventValue(propsConfig.workloadStatus.source);
		const previousUpd = cumulatedWorkload.lastUpdate;
		cumulatedWorkload.lastUpdate = getGreaterDate(workloadStatus.date, cumulatedWorkload.lastUpdate);
		if (previousUpd !== cumulatedWorkload.lastUpdate) {
			cumulatedWorkload.lastUpdateType = LastUpdateType.Event;
		}
		cumulatedWorkload.status = getHealthIndexStatus(workloadStatus);
		cumulatedWorkload.avgInterruptedCurrent = avgInterruptedCurrent!.value ?? undefined;
		cumulatedWorkload.additionalStats = propsConfig.additionalStats.map((stat, i) => ({ title: stat.title.i18n, value: additionalStats[i].value! }));
		const chartData = await getChartDataList(ChartLastOperation.ALL, { min: 0, max: 100 }, propsConfig.numberOfOperations.source, propsConfig.value.source);
		if (apiService.isMock()) {
			// since date is not used in the chart, if the values aren't ordered (which they are with real data)
			// the chart looks messy
			chartData.data.sort((a, b) => a.value2! - b.value2!)
		}
		cumulatedWorkload.singleLineChartProps = {
			...cumulatedWorkload.singleLineChartProps!,
			data: chartData.data,
			accentColor: getColor(HealthIndexStatusHelper.getColor(cumulatedWorkload.status)),
		};
	} catch (e) {
		console.error('loadCumulatedWorkload', e);
		cumulatedWorkload.exception = e;
	} finally {
		cumulatedWorkload.fetching = false;
	}
	return cumulatedWorkload as CumulatedWorkloadProps;
}

export interface CumulatedWorkloadPropsConfig {
	title: {
		i18n: string,
	},
	numberOfOperations: {
		source: NamedSource,
	},
	avgInterruptedCurrent: {
		source: NamedSource,
	},
	value: {
		source: NamedSource,
	},
	workloadStatus: {
		source: NamedSource,
	},
	description: {
		i18n: string,
	},
	okMessage: {
		i18n: string,
	},
	warningMessage: {
		i18n: string,
	},
	alarmMessage: {
		i18n: string,
	},
	additionalStats: {
		title: {
			i18n: string,
		},
		value: {
			source: NamedSource,
		}
	}[]
}
