import { ReactElement } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { getColor } from '../../helpers/css';

export interface SyncLabelProps {
	sync: boolean
}

export function SyncLabel({ sync }: SyncLabelProps): ReactElement {
	const { t } = useTranslation();
	const red = getColor("color-status-red");
	return <span className='px-2'>
		{sync ? <>
			<ABB.Icon className="align-middle" name="abb/synchronize" sizeClass="small" color="color-status-green" />
			<span className="ml-1 sync-label align-middle">{t("Sync")}</span>
		</> : <>
			<span style={{ position: "relative" }}>
				<ABB.Icon className="align-middle" name="abb/synchronize" sizeClass="small" color="color-status-red" />
				<svg viewBox="0 0 16 18" style={{ position: "absolute", left: "0" }}>
					<line x1="0" x2="16" y1="2" y2="18" stroke={red} strokeWidth={2} />
				</svg>
			</span>
			<span className="ml-1 sync-label align-middle">{t("Not sync")}</span>
		</>}
	</span>
}
