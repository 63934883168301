import React, { useState, useEffect } from 'react'
import * as ABB from "@abb/abb-common-ux-react"
import { useTranslation } from 'react-i18next'
import { DocumentType, Role } from "../../enums"
import { DocumentHelper } from "../../helpers/DocumentHelper"
import { Document } from "../../models"
import './style.scss'
import { FileUploader, Card } from '../../components'
import { DocumentBox } from './DocumentBox'
import { useReadable } from '../../observables/hooks'
import { user$ } from '../../services/user'
import { apiService } from '../../services/api'
import { useCallback } from 'react'
import { PageProps } from '../../services/page'
import { DocumentTable } from './DocumentTable'
import { fireErrorNotification } from '../../toasts'
import { useMountedRef } from '../../utils/hooks'

export interface DocumentsProps extends PageProps {
	displayMode: 'table' | 'list',
	acceptedFileExtensions: string[],
}

export const Documents = ({ displayMode, fireNotification, acceptedFileExtensions }: DocumentsProps) => {

	const { t } = useTranslation();
	const userRole = useReadable(user$)?.role ?? Role.None;
	const mountedRef = useMountedRef();

	function GetDocumentBox(key: string, document: Document) {
		return (
			<DocumentBox key={key} document={document}
				userRole={userRole}
				onDeleteRequired={deleteDocumentRequired}
				fireNotification={fireNotification}
				downloadDoc={downloadSelectedDocument}
				copyLinkCb={copyLinkCb}
			/>
		)
	}


	const [isLoading, setIsLoading] = useState<boolean>(false);

	//Tab handling
	const [activeTab, setActiveTab] = useState<number>(0);


	//Document search
	const [searchText, setSearchText] = useState<string>();
	const [docs, setDocs] = useState<Document[]>([]);
	const [filtered, setFiltered] = useState<Document[]>([]);

	const onTextChanged = useCallback((text: string) => {
		setSearchText(text);

		let cleanText = text.trim();

		if (cleanText.length === 0) {
			setFiltered(docs);
		}
		else if (cleanText.length > 1) {
			let filtered = docs.filter(d => d.title !== undefined && d.title.toLowerCase().includes(cleanText.toLowerCase()));//was title not file_name
			setFiltered(filtered);
		}
	}, [docs]);

	useEffect(() => {
		if (docs.length === 0)
			return;

		if (searchText)
			onTextChanged(searchText);
		else
			setFiltered(docs);

		setIsLoading(false);

	}, [docs, onTextChanged, searchText]);


	const loadDocuments = useCallback(async () => {
		setIsLoading(true);
		const documents = await apiService.getDocuments()
		if (mountedRef.current) {
			if (documents && documents.length > 0) {
				setDocs(documents)
			} else {
				setIsLoading(false)
			}
		}
	}, [mountedRef]);

	useEffect(() => {
		if (docs.length === 0) {
			loadDocuments();
		}
	}, [docs, loadDocuments]);

	//Document Upload

	const default_uploadType_value: DocumentType = ((userRole === Role.Admin || userRole === Role.ABB) ? DocumentType.Reports : DocumentType.Uploaded);
	const default_uploadType_label: string = ((userRole === Role.Admin || userRole === Role.ABB) ? DocumentHelper.FromDocumentTypeEnumToApiDocumentType(DocumentType.Reports) : DocumentHelper.FromDocumentTypeEnumToApiDocumentType(DocumentType.Uploaded));

	const [uploadType, setUploadType] = useState<{
		value: DocumentType;
		label: string;
		isNew: boolean;
	}[]>([{
		value: default_uploadType_value,
		label: default_uploadType_label,
		isNew: false
	}]);
	const [showUploadDialog, setShowUploadDialog] = useState(false);
	const [uploadTitle, setUploadTitle] = useState("");
	const [uploadDescription, setUploadDescription] = useState("");
	const [uploadFileObject, setuploadFileObject] = useState<File | null>(null);


	async function upload() {
		if (controlMandatoryFields() && uploadFileObject !== null && uploadType !== null && uploadType[0] !== null) {
			setIsLoading(true);
			try {
				await apiService.uploadDocument(uploadFileObject, uploadType[0].value, uploadFileObject.name, uploadTitle, uploadDescription);
				await loadDocuments();
				fireNotification({ type: "banner", severity: 'success', text: t('DocumentSuccesfullyUploaded'), timeout: 3000 })
			} catch (error) {
				fireErrorNotification({ type: "banner", severity: 'alarm', text: t('DocumentUploadError'), timeout: 5000 }, error, t);
			} finally {
				if (mountedRef.current) {
					setIsLoading(false);
					setShowUploadDialog(false);
					cleanUploadInput();
				}
			}
		}
		else {
			fireNotification({ type: "banner", severity: 'warn', text: t('SomeFieldsAreMissing'), timeout: 5000 });
		}
	}

	function controlMandatoryFields(): boolean {
		let result: boolean = false;

		if (uploadTitle && uploadType && uploadFileObject) result = true;

		return result;
	}

	function cancel() {
		setShowUploadDialog(false);
		cleanUploadInput();
	}

	function cleanUploadInput() {
		setUploadTitle("");
		setUploadType([{ value: default_uploadType_value, label: default_uploadType_label, isNew: false }]);
		setUploadDescription("");
		setuploadFileObject(null);
	}

	const downloadSelectedDocument: (d: Document) => Promise<void> = useCallback(async (d: Document) => {
		const extension = DocumentHelper.GetDocumentExtension(d);
		try {
			const blob = await apiService.downloadDocument(d.id);
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = (d.title || d.id) + extension;
			a.click(); 
		} catch (err) {
			console.log(err);
			fireErrorNotification({ type: "banner", severity: "alarm", text: t("Document Download Error"), timeout: 3000 }, err, t);
		}
	}, [t]);

	//Delete document
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [documentToDelete, setDocumentToDelete] = useState<Document>();

	function deleteDocumentRequired(doc: Document) {
		setDocumentToDelete(doc);
		setShowDeleteDialog(true);
	}

	async function deleteDocument(doc_type: DocumentType, documentId: string) {

		setShowDeleteDialog(false);
		try {
			await apiService.deleteDocument(doc_type, documentId);
			await loadDocuments();
			fireNotification({ type: "banner", severity: "success", text: t("DocumentSuccesfullyDeleted"), timeout: 3000 });
		} catch (err) {
			console.log(err);
			fireErrorNotification({ type: "banner", severity: "alarm", text: t("FailedDocumentDeletion"), timeout: 3000 }, err, t);
		} finally {
			if (mountedRef.current) {
				setUploadType([{ value: default_uploadType_value, label: default_uploadType_label, isNew: false }]);
			}
		}

	}

	const copyLinkCb = useCallback((d) => {
		const a = document.createElement('a');
		a.href = d.url;
		if (navigator.clipboard && navigator.clipboard.writeText) { // only available when using https or in localhost	
			navigator.clipboard.writeText(a.href);
		} else { // deprecated, but it seems the only option that can be used over http
			const tempInput = document.createElement("input");
			tempInput.type = "text";
			tempInput.style.opacity = "0";
			tempInput.style.position = "absolute";
			tempInput.value = a.href;
			document.body.appendChild(tempInput);
			tempInput.select();
			tempInput.setSelectionRange(0, a.href.length);
			document.execCommand("copy");
			document.body.removeChild(tempInput);
		}
		fireNotification({ type: "banner", severity: 'info', text: t('LinkCopiedInClipboard'), timeout: 2000 });
	}, [fireNotification, t]);

	async function renameCb(d: Document, title: string, description?: string): Promise<boolean> {
		try {
			await apiService.updateDocumentData(d.id, d.type, title, description);
			loadDocuments();
			fireNotification({ type: "banner", severity: "success", text: t("DocumentSuccesfullyRenamed"), timeout: 3000 });
			return true;
		} catch (err) {
			console.warn(err);
			fireErrorNotification({ type: "banner", severity: "alarm", text: t("FailedDocumentRenaming"), timeout: 3000 }, err, t);
			return false;
		}
	}

	return (

		<>
			<Card className="documents-card" contentClassName="d-flex flex-column flex-grow-1 m-0" title={t("DocumentsAndReports")}
				mobileCols={12} tabletCols={12} desktopCols={12}
				showProgressBar={isLoading}
				progressBar="top">

				<div className="mb-4 d-flex justify-content-between flex-wrap">
					<ABB.Input type="discreet"
						style={{ width: "100%", maxWidth: "500px" }}
						dataType="text"
						value={searchText}
						onValueChange={text => onTextChanged(text)}
						placeholder={t("Search for files uploaded and manuals")}
						showClearIcon={true}
						icon="abb/search"
					/>

					<ABB.Button className="my-1" sizeClass="small" type="primary-blue" shape="rounded" text={t('UploadDocument')} onClick={() => (setShowUploadDialog(true))}></ABB.Button>

				</div>
				<ABB.TabControl className="documents-tab-control"
					type="secondary"
					activeTab={activeTab}
					onTabChange={(o, n) => setActiveTab(n)}
				>
					<ABB.TabItem title={t('All')}>
						{displayMode === 'table'
							? <DocumentTable documents={filtered} copyLinkCb={copyLinkCb} downloadCb={downloadSelectedDocument} deleteCb={deleteDocumentRequired} renameCb={renameCb} />
							: filtered.map((doc, i) => GetDocumentBox(`doc-${i}`, doc))}
					</ABB.TabItem>
					<ABB.TabItem title={t("ABB")}>
						{displayMode === 'table'
							? <DocumentTable documents={filtered} type={DocumentType.ABB} copyLinkCb={copyLinkCb} downloadCb={downloadSelectedDocument} renameCb={renameCb} deleteCb={deleteDocumentRequired} />
							: filtered.filter(d => d.type === DocumentType.ABB).map((doc, i) => GetDocumentBox(`doc-abb-${i}`, doc))}
					</ABB.TabItem>
					<ABB.TabItem title={t('Reports')}>
						{displayMode === 'table'
							? <DocumentTable documents={filtered} type={DocumentType.Reports} copyLinkCb={copyLinkCb} downloadCb={downloadSelectedDocument} renameCb={renameCb} deleteCb={deleteDocumentRequired} />
							: filtered.filter(d => d.type === DocumentType.Reports).map((doc, i) => GetDocumentBox(`doc-report-${i}`, doc))}
					</ABB.TabItem>
					<ABB.TabItem title={t('Uploaded')}>
						{displayMode === 'table'
							? <DocumentTable documents={filtered} type={DocumentType.Uploaded} copyLinkCb={copyLinkCb} downloadCb={downloadSelectedDocument} renameCb={renameCb} deleteCb={deleteDocumentRequired} />
							: filtered.filter(d => d.type === DocumentType.Uploaded).map((doc, i) => GetDocumentBox(`doc-uploaded-${i}`, doc))}
					</ABB.TabItem>
				</ABB.TabControl>
			</Card>

			{/*UPLOAD DIALOG*/}
			<ABB.Dialog
				isOpen={showUploadDialog}
				onClose={() => setShowUploadDialog(false)}
				showCloseButton={false}
				closeOnEscape={false}
				closeOnLostFocus={false}
				dimBackground={true}
				disableDefaultStyle={false}
				className="upload-doc-dialog"
				title={t('UploadDocument')}
			>
				{/*Document Type*/}
				{(userRole === Role.Admin || userRole === Role.ABB) &&
					<div>
						<ABB.Dropdown clearable={false}
							label={t('Type')}
							required={false}
							value={uploadType}
							clearOnEscape={false}
							onChange={val => setUploadType(val)} >
							{/*<ABB.DropdownOption label={t("ABB")} value={DocumentType.ABB} />*/}
							<ABB.DropdownOption label={t('Reports')} value={DocumentType.Reports} />
							<ABB.DropdownOption label={t('Uploaded')} value={DocumentType.Uploaded} />
						</ABB.Dropdown>
					</div>
				}
				{/*Document title*/}
				<div>
					<ABB.Input
						type="discreet"
						dataType="text"
						label={t('Title')}
						required={false}
						maxLength={25}
						value={uploadTitle}
						onValueChange={v => setUploadTitle(v)}
					/>
				</div>
				{/*Document description*/}
				<div>
					<ABB.Input
						type="discreet"
						dataType="text"
						label={t('Description')}
						required={false}
						maxLength={50}
						value={uploadDescription}
						onValueChange={v => setUploadDescription(v)}
					/>
				</div>

				<div className="upload-area">
					<FileUploader showFileName={true}
						fileNameLabel={t("File")}
						accept={acceptedFileExtensions.join(', ')}
						onFileSelected={(files) => setuploadFileObject(files[0])} />
				</div>

				<div className="upload-doc-dialog__buttons">
					<ABB.Button className="my-1" sizeClass="small" type="normal" shape="rounded" text={t('Cancel')} onClick={cancel}></ABB.Button>
					<ABB.Button className="my-1" sizeClass="small" type="primary-blue" shape="rounded" text={t('Upload')} onClick={upload}></ABB.Button>
				</div>
			</ABB.Dialog>

			<ABB.MessageDialog buttons="confirmcancel"
				isOpen={showDeleteDialog}
				message={`${t('ConfirmDeleteOf')} ${documentToDelete ? documentToDelete.title : ""}?`}
				title={t('Confirmation')}
				onCancel={() => {
					setShowDeleteDialog(false);
					setDocumentToDelete(undefined);
				}}
				onConfirm={() => deleteDocument(documentToDelete ? DocumentHelper.FromApiDocumentTypeToDocumentTypeEnum(documentToDelete.type) : DocumentHelper.FromApiDocumentTypeToDocumentTypeEnum(DocumentType.Uploaded),
					documentToDelete ? documentToDelete.id : "")}
			/>
		</>
	)
}

