import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card } from '../../components';
import { AspectRatio } from '../../components/AspectRatio/AspectRatio';
import { Scrollable } from '../../components/Scrollable/Scrollable';
import { HealthIndexStatus } from '../../enums';
import { BootstrapHelper } from '../../helpers';
import { WidgetProps } from '../../models';
import { useReadable } from '../../observables/hooks';
import { makeAsyncWritable } from '../../utils/async-writable';
import { Placeholder } from '../Placeholder/Placeholder';
import './style.scss';

const ioStatusSvg$ = makeAsyncWritable(null, {
	dataProvider: () => fetch('./images/io-status.svg').then((response) => response.text()),
});

const healthIndexStatusToClassName = {
	[HealthIndexStatus.Ok]: 'ok',
	[HealthIndexStatus.Warning]: 'warning',
	[HealthIndexStatus.Alarm]: 'alarm',
}

export interface UnitHealth {
	positionControl: {
		endOfOperation?: HealthIndexStatus
	},
	operationControl: {
		position?: HealthIndexStatus
		speed?: HealthIndexStatus
		torque?: HealthIndexStatus
	},
	loadDiagnostic: {
		motor?: HealthIndexStatus
	}
}

export interface HardwareIntegrityProps extends WidgetProps {
	states: {
		unit1: UnitHealth,
		unit2: UnitHealth,
		unit3: UnitHealth,
		hardwareMonitor: {
			electronicIntegrity?: HealthIndexStatus,
			capacitorDiagnostic?: HealthIndexStatus,
		},
		unitsConnection: {
			rs422?: HealthIndexStatus,
			canSupervisor?: HealthIndexStatus,
		}
	}
}

function getSubtree<T>(object: object, path: string[]): object | T | undefined {
	let subtree = object as object | T | undefined;
	for (let i = 0; i < path.length; i++) {
		if (subtree && path[i] in subtree) {
			subtree = (subtree as Record<string, object | T>)[path[i]];
		} else {
			return undefined;
		}
	}

	return subtree;
}

export function HardwareIntegrity({ title, states, mobileCols, desktopCols, tabletCols, lastUpdate, lastUpdateType, installed, disconnected }: HardwareIntegrityProps): React.ReactElement {
	const ioStatusSvg = useReadable(ioStatusSvg$);
	const [svgContainerRef, setSvgContainerRef] = useState<HTMLElement | null>(null);
	const [svgElement, setSvgElement] = useState<SVGSVGElement | null>(null);
	const [aspectRatio, setAspectRatio] = useState(1);
	const svgContainerRefCallback = useCallback((node) => {
		setSvgContainerRef(node);
	}, [setSvgContainerRef]);
	useEffect(() => {
		if (ioStatusSvg && svgContainerRef) {
			svgContainerRef.innerHTML = ioStatusSvg;
			const svgElement = svgContainerRef.firstElementChild as SVGSVGElement;
			svgElement.style.width = '100%';
			svgElement.style.height = 'auto';
			setSvgElement(svgElement);
		}
	}, [ioStatusSvg, svgContainerRef]);

	useEffect(() => {
		if (!svgElement) {
			return;
		}
		setAspectRatio(svgElement.viewBox.baseVal.width / svgElement.viewBox.baseVal.height);

		// determine which icons to show based on passed states
		[...svgElement.querySelectorAll('.status-indicator-group')].forEach((statusIndicatorGroup) => {
			const state = getSubtree(states, statusIndicatorGroup.getAttribute('data-path')?.split('.') ?? []) as HealthIndexStatus;
			[...statusIndicatorGroup.children].forEach((statusIndicatorIcon) => {
				if (!state) {
					(statusIndicatorIcon as SVGGElement).style.display = (statusIndicatorIcon.getAttribute('class') === 'unknown') ? '' : 'none';
				} else {
					(statusIndicatorIcon as SVGGElement).style.display = (statusIndicatorIcon.getAttribute('class') === healthIndexStatusToClassName[state]) ? '' : 'none';
				}
			});
		});
	}, [states, svgElement]);

	if (!ioStatusSvg) {
		return <Placeholder mobileCols={12} desktopCols={12} tabletCols={12}></Placeholder>
	}
	return (
		<Card title={title}
			installed={installed}
			disconnected={disconnected}
			mobileCols={BootstrapHelper.getColValue(12, mobileCols)}
			tabletCols={BootstrapHelper.getColValue(6, tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, desktopCols)}
			comment={{ lastUpdate, lastUpdateType }}
		>
			<div className="p-2">
				<div style={{
					minHeight: (svgElement?.viewBox.baseVal.height || 1) + 20 // +20px to take into account the horizontal scrollbar height
				}}>
					<Scrollable>
						<AspectRatio ratio={aspectRatio} minWidth={svgElement?.viewBox.baseVal.width || 1}>
							<div ref={svgContainerRefCallback}></div>
						</AspectRatio>
					</Scrollable>
				</div>
			</div>
		</Card>
	);
}
