import { getLastEventValue, getMultipleSamples } from '.';
import { UnitOperationalStatus } from '../../enums';
import { DateTimeFormatHelper, LocalizationHelper } from '../../helpers';
import { LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { CBStatusProps } from '../../widgets';

export async function loadCBStatus(config: WidgetConfig, props: Partial<CBStatusProps>): Promise<CBStatusProps> {
	const cbStatus = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as CBStatusPropsConfig;
		const [voltage, current] = await getMultipleSamples('number', [
			propsConfig.voltage.source,
			propsConfig.current.source	
		]);
		const [notReady, open, closed, ...units] = await getMultipleSamples('boolean', [
			propsConfig.notReady.source,
			propsConfig.open.source,
			propsConfig.closed.source,
			...propsConfig.units.flatMap((unit) => [
				unit.open.source,
				unit.closed.source,
				unit.ready.source,
				unit.alarm.source,
			])
		]);
		[notReady, open, closed, voltage, current, ...units.flatMap((u) => u)].forEach((sample) => {
			const greaterDate = getGreaterDate(cbStatus.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined);
			if (greaterDate !== cbStatus.lastUpdate) {
				cbStatus.lastUpdate = greaterDate;
				cbStatus.lastUpdateType = LastUpdateType.Sample;
			}
		});
		cbStatus.voltage = voltage.value ?? null;
		cbStatus.current = current.value ?? null;
		cbStatus.ready = !notReady?.value;
		cbStatus.closed = closed.value ?? undefined;
		cbStatus.open = open.value ?? undefined;
		cbStatus.units = [];
		for (let i = 0; i < propsConfig.units.length; i++) {
			let connection;
			try {
				connection = getLastEventValue(propsConfig.units[i].connection.source);
				const greaterDate = getGreaterDate(cbStatus.lastUpdate, connection.date);
				if (greaterDate !== cbStatus.lastUpdate) {
					cbStatus.lastUpdate = greaterDate;
					cbStatus.lastUpdateType = LastUpdateType.Event;
				}
			} catch (err) {
				console.warn('loadCBStatus', err);
			}
			cbStatus.units.push({
				title: LocalizationHelper.getTranslation(propsConfig.units[i].title.i18n),
				alarm: units[3 + i * 4]!.value!,
				ready: units[2 + i * 4]!.value!,
				closed: units[1 + i * 4]!.value!,
				open: units[0 + i * 4]!.value!,
				connected: connection?.mappedValue === UnitOperationalStatus.Operational,
			})
		}
	} catch (e) {
		console.error('loadCBStatus', e);
		cbStatus.exception = e;
	} finally {
		cbStatus.fetching = false;
	}
	return cbStatus as CBStatusProps;
}

export interface CBStatusItemPropsConfig {
	title: {
		i18n: string,
	},
	ready: {
		source: NamedSource,
	},
	closed: {
		source: NamedSource,
	},
	open: {
		source: NamedSource,
	},
	alarm: {
		source: NamedSource,
	},
	connection: {
		source: NamedSource,
	},
}

export interface CBStatusPropsConfig {
	title: {
		i18n: string;
	},
	closed: {
		source: NamedSource,
	},
	open: {
		source: NamedSource,
	},
	notReady: {
		source: NamedSource,
	},
	current: {
		source: NamedSource,
	},
	voltage: {
		source: NamedSource,
	},
	units: CBStatusItemPropsConfig[]
}
