import React, { ReactElement, useCallback } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { FileTypeHelper } from '../../helpers';
import { FileType } from '../../enums';

interface DocumentsToUploadProps {
	files: File[],
	setFiles: (files: File[]) => void,
	maxFiles?: number,
	minFiles?: number,
}

export function DocumentsToUpload({ files, setFiles, maxFiles, minFiles }: DocumentsToUploadProps): ReactElement {
	const { t } = useTranslation();

	const getFileIcon = useCallback((file: File) => {
		const fileType = FileTypeHelper.FromMimeTypeToFileType(file.type);
		switch (fileType) {
			case FileType.Excel:
				return 'abb/document-excel';
			case FileType.Image:
				return 'abb/image';
			case FileType.Pdf:
				return 'abb/document-pdf';
			case FileType.Word:
				return 'abb/document-word';
			default:
				return 'abb/template';
		}
	}, []);

	const getIconColor = useCallback((file: File) => {
		const fileType = FileTypeHelper.FromMimeTypeToFileType(file.type);
    switch (fileType) {
      case FileType.Image:
        return "#49C9A7";
      case FileType.Pdf:
        return "color-status-red";
      case FileType.Excel:
        return "color-status-green";
      case FileType.Word:
        return "color-status-blue";
			default:
				return "gray";
    }
  }, []);

	return <>{
		files.map((f, i) => <div key={i} className="d-flex justify-content-between my-2 align-items-center">
			<span className='d-flex align-items-center'>
				<ABB.Icon
					name={getFileIcon(f)}
					color={getIconColor(f)}
					className="mr-2" />
				{f.name}
			</span>
			<div>
				<ABB.WithTooltip>
					<ABB.IconButton onClick={() => setFiles(files.filter((file) => file !== f))}>
						<ABB.Icon name='abb/trash' />
					</ABB.IconButton>
					<ABB.Tooltip>
						{t("RemoveFromUploadList")}
					</ABB.Tooltip>
				</ABB.WithTooltip>
			</div>
		</div>)}
		{((minFiles !== undefined && files.length < minFiles) || (maxFiles !== undefined && files.length > maxFiles)) && <div className='mt-2 d-flex align-items-center'>
		<ABB.Icon name='abb/warning-triangle' color='color-status-red' sizeClass='small' className='mr-2'/>
			{maxFiles !== undefined && maxFiles !== minFiles && files.length > maxFiles && <span>
				{t("OverMaxFileLimit", { count: maxFiles })}	
			</span>}
			{minFiles !== undefined && maxFiles !== minFiles && files.length < minFiles && <span>
				{t("BelowMinFileLimit", { count: minFiles })}	
			</span>}
			{minFiles === maxFiles && minFiles !== undefined && files.length !== minFiles && <span>
				{t("WrongNumberOfFiles", { count: minFiles })}	
			</span>}
		</div>}
	</>
}
