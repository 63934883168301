import React,{ ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeFormatHelper } from '../../helpers';
import { LastUpdateType } from '../../models';
import { useReadable } from '../../observables/hooks';
import { nowAt1Hz$ } from '../../observables/time';

export interface LastUpdateProps {
	lastUpdate?: Date|null,
	lastUpdateType?: LastUpdateType,
}
export function LastUpdate({ lastUpdate, lastUpdateType }: LastUpdateProps): ReactElement {
	const { t, i18n } = useTranslation();
	const now = useReadable(nowAt1Hz$);
	if (lastUpdate === undefined) {
		return <></>;
	}
	if (lastUpdate === null) {
		return <>{t("N/A")}</>
	}
	return (
		<>
			{lastUpdateType === LastUpdateType.Event
				? t('for {{diff}}', { diff: DateTimeFormatHelper.humanDiff(now, lastUpdate, i18n.language) })
				: DateTimeFormatHelper.humanDiff(now, lastUpdate, i18n.language)}
		</>
	);
}
