import { getLastEventValue, getMappedEventState, getHealthIndexStatus } from '.';
import { HealthIndexStatus } from '../../enums';
import { LocalizationHelper } from '../../helpers';
import { LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { FamilyStatusProps } from '../../widgets';
import { apiService } from '../api';


export async function loadFamilyStatus(config: WidgetConfig, props: Partial<FamilyStatusProps>): Promise<FamilyStatusProps> {
	console.info("loadFamilyStatus");
	const familyStatus: Partial<FamilyStatusProps> = { ...props };
	try {
		if (!config.propsConfig) {
			throw new Error(`loadFamilyStatus | api identity not found`);
		}
		const propsConfig = config.propsConfig as unknown as FamilyStatusPropsConfig;
		const eventSource = (propsConfig.value?.source || propsConfig.data?.source)!;
		const eventValue = getLastEventValue(eventSource);

		const healthIndex = getHealthIndexStatus(eventValue);
		familyStatus.lastUpdate = eventValue.date;
		familyStatus.lastUpdateType = LastUpdateType.Event;
		familyStatus.status = healthIndex;

		if (healthIndex === HealthIndexStatus.Ok) {
			familyStatus.error = undefined;
		}
		else {
			let messageKey: string | undefined = undefined;

			if (eventValue.relatedEvents && eventValue.relatedEvents.length > 0) {
				const history = await apiService.getEventsHistory(eventValue.relatedEvents);
				if (history && history.events) {
					for (const event of history.events) {
						const mappedEventState = getMappedEventState(event.name, event.state, event.model);
						if (mappedEventState.messageKey) {
							const healthIndexStatus = mappedEventState.value || event.state.toString();
							if (healthIndexStatus === HealthIndexStatus.Alarm) {
								messageKey = mappedEventState.messageKey;
								break;
							}
							else if (!messageKey && healthIndexStatus === HealthIndexStatus.Warning) {
								messageKey = mappedEventState.messageKey;
							}
						}
					}
				}
			}

			if (!messageKey) {
				if (healthIndex === HealthIndexStatus.Alarm) {
					messageKey = "GenericAlarmMessage";
				} else {
					messageKey = "GenericWarningMessage";
				}
			}

			familyStatus.error = LocalizationHelper.getTranslation("notificationDescriptions:" + messageKey);
		}
	} catch (e) {
		familyStatus.exception = e;
	} finally {
		familyStatus.fetching = false;
	}
	return familyStatus as FamilyStatusProps;
}

export interface FamilyStatusPropsConfig {
	description: {
		i18n: string
	},
	title: {
		i18n: string
	},
	info?: {
		i18n: string
	},
	linkLabel: {
		i18n: string
	},
	linkUrl: {
		value: string
	},
	disabledLinkUrl: {
		value: string
	},
	disabledLinkLabelKey: {
		i18n: string
	},
	disabledText: {
		i18n: string
	},
	enable: {
		value: boolean
	},
	value?: {
		source: NamedSource
	},
	data?: {
		source: NamedSource
	}
}
