import React, { ReactElement } from 'react'
import * as ABB from "@abb/abb-common-ux-react"

interface TrimmedTextProps {
    text: string,
    maxLength: number,
    tag: 'span' | 'p' | 'div' | 'h1' | 'h2' | 'h3',
    id?: string,
    className?: string
}

export function TrimmedText(props: TrimmedTextProps): ReactElement {

    let needTooltip = props.text.length > props.maxLength;

    function getContent(text: string) {
        
        switch (props.tag) {
            case "div":
                return <div id={props.id} className={props.className}>{text}</div>
            case "h1":
                return <h1 id={props.id} className={props.className}>{text}</h1>
            case "h2":
                return <h2 id={props.id} className={props.className}>{text}</h2>
            case "h3":
                return <h3 id={props.id} className={props.className}>{text}</h3>
            case "p":
                return <p id={props.id} className={props.className}>{text}</p>
            case "span":
                return <span id={props.id} className={props.className}>{text}</span>
        }        
    }

    return (
        <>
            {needTooltip &&
                <ABB.WithTooltip>
                    {/* {`<${props.tag}>${props.text.substring(0, props.maxLength - 3)}...</${props.tag}>`} */}

                    {getContent(`${props.text.substring(0, props.maxLength - 3)}...`)}

                    <ABB.Tooltip followCursor={true} >
                        <p>{props.text}</p>
                    </ABB.Tooltip>
                </ABB.WithTooltip>
            }
            {!needTooltip &&
                <>
                    {getContent(props.text)}
                </>
            }


        </>
    )
}
