export * from './AbbColorHelper';
export * from './BootstrapHelper';
export * from './ChartHelper';
export * from './ConnectionStatusHelper';
export * from './DateTimeFormatHelper';
export * from './DocumentHelper';
export * from './EnvHelper';
export * from './FileTypeHelper';
export * from './HealthIndexStatusHelper';
export * from './LoadHelper';
export * from './LocalizationHelper';
export * from './SectionsHelper';


export function delay(seconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}