import React, { ReactElement } from 'react'
import { ImageWidget, ImageWidgetProps } from '../ImageWidget/ImageWidget'
import { SensorStatus, SensorStatusProps } from '../SensorStatus/SensorStatus'
import { Card } from '../../components'
import { BootstrapHelper } from '../../helpers';

export interface ImageStatusProps extends ImageWidgetProps, SensorStatusProps {
}

export function ImageStatus(props: ImageStatusProps): ReactElement {

    return (
        <Card hideTitle={true}
						installed={props.installed} disconnected={props.disconnected}
            mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
            tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)}
            desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}>
            <ImageWidget {...props} mobileCols={12} tabletCols={12} desktopCols={12} />
            <SensorStatus {...props} mobileCols={12} tabletCols={12} desktopCols={12} embedded={true} />
        </Card>
    )
}
