import './App.scss';
import React from 'react';
import { useReadable } from '../observables/hooks';
import { closeToast, toasts$ } from '../toasts';
import * as ABB from '@abb/abb-common-ux-react';

export function AppToasts(): React.ReactElement {
	const toasts = useReadable(toasts$);
	return (
		<>
			<ABB.NotificationContainer
				notifications={toasts}
				actionHandler={{
					remove: (id: string) => {
						closeToast(id);
					}
				}}
				className="notification-container"
			/>
		</>
	);
}
