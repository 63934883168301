import * as Models from "../models";
import { DeviceApiItem } from '../models';
import { combineReadables } from '../observables/utils';
import { makePollingWritable } from '../utils/polling-subject';
import { apiService } from './api';

export const devicesAndMetadata$ = makePollingWritable({ devices: [], metadata: { models: {} } }, {
	dataProvider: async () => {
		const [devices, metadata] = await Promise.all([apiService.getDevices(), apiService.getDevicesMetadata()]);
		return {
			devices: devices.devices, metadata
		}
	},
	auto: false,
});

export const rawDevices$ = combineReadables([devicesAndMetadata$], ([devicesAndMetadata]) => devicesAndMetadata.devices);
export const devicesMetadata$ = combineReadables([devicesAndMetadata$], ([devicesAndMetadata]) => devicesAndMetadata.metadata);


export const devices$ = combineReadables([rawDevices$], ([rawDevices]) => {
	return (rawDevices || []).reduce((devicesMap, device) => {
		(device.roles || []).forEach((role) => {
			const roleExists = devicesMap.get(role);
			if (roleExists) {
				roleExists.push(device);
			} else {
				devicesMap.set(role, [device]);
			}
		});
		return devicesMap;
	}, new Map<string, Models.DeviceApiItem[]>());
});

export const boardDevice$ = combineReadables([devices$], ([devices]) => {
	const boardDevices = devices.get('board');
	if (!boardDevices) {
		return null;
	}
	const boardDevice = boardDevices[0];
	if (!boardDevice) {
		return null;
	}
	return boardDevice as DeviceApiItem & {
		configuration: {
			IEEE802154Channel: string,
		},
	};
});


export function mergeDeviceData<
	TOnline extends { uid: string },
	TComplete extends TOnline & { local: TLocalDefault },
	TLocalDefault
>(
	oldArray: TComplete[],
	newOnlineArray: TOnline[],
	localDefaultProvider: (v: TOnline) => TLocalDefault,
) {
	const oldCompleteByUid = oldArray.reduce<{ [uid: string]: TComplete }>((acc, cur) => {
		acc[cur.uid] = cur;
		return acc;
	}, {});
	const newOnlineByUid = newOnlineArray.reduce<{ [uid: string]: TOnline }>((acc, cur) => {
		acc[cur.uid] = cur;
		return acc;
	}, {});

	const newCompleteByUid: { [uid: string]: TComplete } = {};
	for (const uid of Object.keys(newOnlineByUid)) {
		const previous = oldCompleteByUid[uid];
		const next = newOnlineByUid[uid];
		if (!previous) {
			newCompleteByUid[uid] = {
				...next,
				local: localDefaultProvider(next),
			} as TComplete;
		} else {
			newCompleteByUid[uid] = {
				...next,
				local: previous.local,
			} as TComplete;
		}
	}
	return Object.values(newCompleteByUid).sort((a, b) => {
		if (a.uid < b.uid) return -1;
		if (a.uid > b.uid) return 1;
		return 0;
	});
}
