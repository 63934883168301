import { getLastGenericSample } from '.';
import { HealthIndexStatus } from '../../enums';
import { DateTimeFormatHelper } from '../../helpers';
import { LastUpdateType } from '../../models';
import { NamedSource, PathSource, WidgetConfig } from '../../pages/DynamicPage';
import { StatusDetailsWithIconProps } from '../../widgets';

export async function loadStatusDetailsWithIcon(config: WidgetConfig, props: Partial<StatusDetailsWithIconProps>): Promise<StatusDetailsWithIconProps> {
	const statusDetailsWithIcon = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as StatusDetailsWithIconPropsConfig;
		const value = await getLastGenericSample('number', propsConfig.value.source);
		// TODO: va mostrato?
		const lastErrorDurationMs = 0; //getLastEventErrorDuration(propsConfig.value.source);
		let healthIndex = statusDetailsWithIcon.status;
		if (statusDetailsWithIcon.lowAlarm !== undefined && value!.value < statusDetailsWithIcon.lowAlarm) {
			healthIndex = HealthIndexStatus.Alarm;
		} else if (statusDetailsWithIcon.lowWarn !== undefined && value!.value < statusDetailsWithIcon.lowWarn) {
			healthIndex = HealthIndexStatus.Warning;
		} else if (value?.value !== undefined) {
			healthIndex = HealthIndexStatus.Ok;
		}
		statusDetailsWithIcon.lastUpdate = DateTimeFormatHelper.toDate(value!.timestamp_us);
		statusDetailsWithIcon.lastUpdateType = LastUpdateType.Sample;
		statusDetailsWithIcon.status = healthIndex;
		statusDetailsWithIcon.lastErrorDuration = lastErrorDurationMs;
	} catch (e) {
		statusDetailsWithIcon.exception = e;
		console.error('loadStatusDetailsWithIcon', e);
	} finally {
		statusDetailsWithIcon.fetching = false;
	}
	return statusDetailsWithIcon as StatusDetailsWithIconProps;
}

export interface StatusDetailsWithIconPropsConfig {
	title: {
		i18n: string
	},
	icon: {
		value: string
	},
	description: {
		i18n: string
	},
	value: {
		source: NamedSource
	},
	lowWarn: {
		source: PathSource
	},
	lowAlarm: {
		source: PathSource
	}
}
