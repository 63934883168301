import { BehaviorSubject, PartialObserver } from 'rxjs';
import { Readable, Writable } from '../observables/types';

export interface AsyncWritable<T> extends Writable<T> {
	fetch: (force?: boolean) => Promise<void>,
	fetching$: Readable<boolean>,
	error$: Readable<Error | null>,
	fetchedAt$: Readable<Date|null>,
}

export interface AsyncWritableConfig<T> {
	dataProvider: () => Promise<T>,
	auto?: boolean,
}

/**
 * 
 * @param defaultValue the value that this subject contains upon initialization, accessible using subject.getValue()
 * @param config.dataProvider an async function used as data source
 * @param config.auto (optional, defaults to true) if true the polling will start fetching the resource from the data provider once there is at least one subscriber
 *                    otherwise you'll need to call subject.fetch manually
 * @returns 
 */
export function makeAsyncWritable<T>(
	defaultValue: T,
	{ dataProvider, auto = true }: AsyncWritableConfig<T>
): AsyncWritable<T> {
	const content$ = new BehaviorSubject(defaultValue);
	const fetching$ = new BehaviorSubject(false);
	const error$ = new BehaviorSubject<Error | null>(null);
	const fetchedAt$ = new BehaviorSubject<Date|null>(null);

	const requestId = () => window.performance.now().toString();

	let queue: string[] = [];
	const fetch = async (force?: boolean) => {
		if (fetching$.getValue() && !force) {
			return;
		}
		if (force) {
			queue = [];
		}
		const id = requestId();
		queue.push(id);
		fetching$.next(true);
		try {
			const newContent = await dataProvider();
			if (queue.includes(id)) {
				content$.next(newContent);
				fetchedAt$.next(new Date());
			}
			error$.next(null);
		} catch (err) {
			error$.next(err);
		} finally {
			const indexOf = queue.indexOf(id);
			if (indexOf !== -1) {
				queue.splice(indexOf, 1);
			}
			fetching$.next(false);
		}
	};

	let subscribers = 0;
	return {
		getValue: () => content$.getValue(),
		next: (v) => content$.next(v),
		subscribe: (subscriber) => {
			subscribers++;
			if (auto && subscribers === 1 && fetchedAt$.getValue() === null) {
				fetch().catch(console.error);
			}

			return content$.subscribe((subscriber || undefined) as PartialObserver<T>);
		},
		fetch,
		fetchedAt$,
		fetching$,
		error$,
	};
}
