import { getHealthIndexStatus, getLastByDataAggregation, getLastGenericSample, getLastValueFromAggregration } from '.';
import { HealthIndexStatus } from '../../enums';
import { DateTimeFormatHelper } from '../../helpers';
import { LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { AlertsProps } from '../../widgets';

export async function loadAlertsData(config: WidgetConfig, props: Partial<AlertsProps>): Promise<AlertsProps> {
	console.info("loadAlertsData");
	const alerts = { ...props };
	try {
		if (!config.propsConfig) {
			throw new Error("loadAlertsData | api data not found");
		}
		const propsConfig = config.propsConfig as unknown as AlertsPropsConfig;
		const apis: NamedSource[] = [];
		for (const apiItem of propsConfig.data) {
			if (!apiItem)
				throw new Error("loadAlertsData | api data not found");
			// Facoltativo
			if (apiItem.status)
				apis.push(apiItem.status.source);

			if (!apiItem.value)
				throw new Error("loadAlertsData | api data: value not found");

			apis.push(apiItem.value.source);
		}
		const getWarnings = () => {
			return Promise.all(propsConfig.data.map((data) => {
				if (data.warning) {
					return getLastGenericSample('boolean', data.warning.source);
				}
				return Promise.resolve(undefined);
			}));
		};
		const [data, warnings] = await Promise.all([
			getLastByDataAggregation(...apis),
			getWarnings(),
		]);

		alerts.data?.forEach((propItem, index) => {
			const apiItem = propsConfig.data[index];
			// Facoltativo
			if (apiItem.status) {
				try {
					let statusEventValue = getLastValueFromAggregration(data, apiItem.status.source);

					if (statusEventValue) {
						propItem.status = getHealthIndexStatus(statusEventValue);
						alerts.lastUpdate = getGreaterDate(statusEventValue.date, alerts.lastUpdate);
						if (alerts.lastUpdate === statusEventValue.date) {
							alerts.lastUpdateType = LastUpdateType.Event;
						}
					}
				} catch (err) {
					console.warn('loadAlertsData', err);
					propItem.status = undefined;
				}
			} else if (warnings[index]) {
				propItem.status = warnings[index]!.value ? HealthIndexStatus.Warning : HealthIndexStatus.Ok;
				alerts.lastUpdate = getGreaterDate(DateTimeFormatHelper.toDate(warnings[index]!.timestamp_us), alerts.lastUpdate);
				alerts.lastUpdateType = LastUpdateType.Sample;
			}
			const sampleValue = getLastValueFromAggregration(data, apiItem.value.source);
			if (sampleValue) {
				propItem.value = sampleValue.rawValue ?? null;
				alerts.lastUpdate = getGreaterDate(sampleValue.date, alerts.lastUpdate);
				alerts.lastUpdateType = LastUpdateType.Sample;
			}
		});
		alerts.fetching = false;
	} catch (e) {
		console.error("loadAlertsData", e);
		alerts.exception = e;
	}
	return alerts as AlertsProps;
}

export interface AlertsPropsConfig {
	title: {
		i18n: string
	},
	data: AlertsItemPropsConfig[],
}

export interface AlertsItemPropsConfig {
	label: {
		i18n: string
	},
	value: { source: NamedSource },
	status?: { source: NamedSource },
	warning?: { source: NamedSource },
}
