import React, { useState } from "react";
import * as Components from "../../components";
import { Role } from "../../enums";
import { SectionProps } from "../../models";
import { delay } from "../../helpers";
import * as ABB from "@abb/abb-common-ux-react";
import { useTranslation } from 'react-i18next';
import { useReadable } from '../../observables/hooks';
import { user$ } from '../../services/user';
import { commissioningCompleted$, productionCompleted$ } from '../../services/config';

interface LandingProps extends SectionProps {
}

export const Landing = (props: LandingProps) => {

    const { t } = useTranslation();
    const productionCompleted = useReadable(productionCompleted$);
    const commissioningCompleted = useReadable(commissioningCompleted$);
    async function productionClick() {

        setIsLoadingProducition(true);

        await delay(2);

        productionCompleted$.next(true);

        setIsLoadingProducition(false);
    }

    async function commissioningClick() {

        setIsLoadingCommissioning(true);

        await delay(2);

        commissioningCompleted$.next(true);
    }


    const userRole = useReadable(user$)?.role ?? Role.None;
    const [isLoadingProducition, setIsLoadingProducition] = useState(false);
    const [isLoadingCommissioning, setIsLoadingCommissioning] = useState(false);

    return (
        <div className="row">

            {(userRole === Role.Admin || userRole === Role.ABB) ?
                <>
                    <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("Production Setup")}>
                        <ABB.Button type="primary-blue" text={t("Next")}
                            onClick={productionClick}
                            disabled={productionCompleted}
                            isLoading={isLoadingProducition} />
                    </Components.Card>

                    <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("Commissioning Setup")}>
                        <ABB.Button type="primary-blue" text={t("Next")}
                            onClick={commissioningClick}
                            disabled={!productionCompleted}
                            isLoading={isLoadingCommissioning} />
                    </Components.Card>
                </>
                : !productionCompleted ?
                    <>
                        <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("Production Setup")}>
                            <span>{t("Production configuration required, please login as administrator")}</span>
                        </Components.Card>
                    </>
                    : !commissioningCompleted ?
                        <>
                            <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("Commissioning Setup")}>
                                <span>{t("Commissioning configuration required, please login as administrator")}</span>
                            </Components.Card>
                        </>
                        :
                        <>
                            <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("LANDING PAGE")}>
                                <span>ToDo</span>
                            </Components.Card>
                            <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("LANDING PAGE")}>
                                <span>ToDo</span>
                            </Components.Card>
                            <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("LANDING PAGE")}>
                                <span>ToDo</span>
                            </Components.Card>
                            <Components.Card mobileCols={12} tabletCols={6} desktopCols={6} title={t("LANDING PAGE")}>
                                <span>ToDo</span>
                            </Components.Card>
                        </>
            }
        </div>
    );
};
