import { LocalizationHelper } from '../helpers';
import { login, LoginResult, logout } from '../services/access';
import { apiService } from '../services/api';
import { fireNotification } from '../toasts';
import { loginDialogIsShown$ } from './layout-state';

export async function handleLogin(username: string | undefined, password: string | undefined, rememberMe: boolean) {

	const loginResult = await login(username || '', password || '', rememberMe);
	if (loginResult === LoginResult.InvalidCredentials) {
		fireNotification({ type: "banner", severity: "alarm", text: LocalizationHelper.getTranslation('InvalidCredentials') })
	} else if (loginResult === LoginResult.TooManyRequests) {
		fireNotification({ type: "banner", severity: "alarm", text: LocalizationHelper.getTranslation('LoginTooManyRequests') })
	} else if (loginResult === LoginResult.LoggedIn) {
		loginDialogIsShown$.next(false);
	}
}

export async function handleLogout() {
	try {
		await apiService.remoteLogout();
	} catch (err) {
		console.error('logout error', err);
	}
	return logout();
}
