import { getLastEventValue, getHealthIndexStatus } from '.';
import { LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { SectionStatusProps } from '../../widgets';

export async function loadSectionStatusData(config: WidgetConfig, props: Partial<SectionStatusProps>): Promise<SectionStatusProps> {
	console.info("loadSectionStatusData");
	const sectionStatus: Partial<SectionStatusProps> = { ...props };
	try {
		if (!config.propsConfig) {
			throw new Error(`loadSectionStatusData | api identity not found`);
		}
		const propsConfig = config.propsConfig as unknown as SectionStatusPropsConfig;
		const apiData = getLastEventValue(propsConfig.data.source);
		if (apiData) {
			sectionStatus.status = getHealthIndexStatus(apiData);
			sectionStatus.lastUpdate = apiData.date;
			sectionStatus.lastUpdateType = LastUpdateType.Event;
			sectionStatus.fetching = false;
		}
	} catch (e) {
		sectionStatus.exception = e;
	} 
	return sectionStatus as SectionStatusProps;
}

export interface SectionStatusPropsConfig {
	data: {
		source: NamedSource,
	},
	title: {
		i18n: string,
	},
	subtitle: {
		i18n: string,
	},
	description: {
		i18n: string,
	},
	info: {
		i18n: string,
	}
}
