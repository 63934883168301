import { getMultipleSamples } from '.';
import { DateTimeFormatHelper, LocalizationHelper } from '../../helpers';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { BinaryInputDiagnosticProps } from '../../widgets';

export async function loadBinaryInputDiagnostic(config: WidgetConfig, props: Partial<BinaryInputDiagnosticProps>): Promise<BinaryInputDiagnosticProps> {
	const binaryInputDiagnostic = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as BinaryInputDiagnosticPropsConfig;
		const samples = await getMultipleSamples('boolean', propsConfig.inputs.map((i) => i.status.source));
		binaryInputDiagnostic.inputs = propsConfig.inputs.map((input, index) => {
			const sample = samples[index];
			binaryInputDiagnostic.lastUpdate = getGreaterDate(binaryInputDiagnostic.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined);
			return {
				name: LocalizationHelper.getTranslation(input.name.i18n),
				status: sample.value !== null ? !sample.value : null,
			}
		});
	} catch (e) {
		console.error("loadBinaryInputDiagnostic", e);
		binaryInputDiagnostic.exception = e;
	} finally {
		binaryInputDiagnostic.fetching = false;
	}
	return binaryInputDiagnostic as BinaryInputDiagnosticProps;
}

export interface BinaryInputDiagnosticPropsConfig {
	title: {
		i18n: string,
	},
	inputs: {
		name: {
			i18n: string,
		},
		status: { source: NamedSource }
	}[]
}
