import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from '../../components';
import { WidgetProps } from '../../models';

export interface BinaryInputDiagnosticProps extends WidgetProps {
	inputs: {
		name: string,
		status: boolean | null,
	}[]
}

function getIcon(status: boolean|null) {
	if (status) {
		return "abb/check-mark-circle-2";
	}
	if (status === false) {
		return "abb/error-circle-2";
	}
	return "abb/minus";
}

function getIconColor(status: boolean|null) {
	if (status) {
		return "color-status-green";
	}
	if (status === false) {
		return "color-status-red";
	}
	return "color-grey-40";
}

function getStatusText(status: boolean|null) {
	if (status) {
		return "Ok";
	}
	if (status === false) {
		return "Fail";
	}
	return "N/a";
}

export function BinaryInputDiagnostic(props: BinaryInputDiagnosticProps): ReactElement {
	const { t } = useTranslation();
	return <Card
		desktopCols={props.desktopCols ?? 4}
		tabletCols={props.tabletCols ?? 4}
		mobileCols={props.mobileCols ?? 12}
		title={props.title}
		installed={props.installed}
		disconnected={props.disconnected}
		comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
	>
		{props.inputs.map((input, i) =>
			<div className="row" key={i}>
				<div className="col-8 font-weight-bold my-3">{t(input.name)}</div>
				<div className="col-4 my-3" style={{ lineHeight: "24px" }}>
					<ABB.Icon className="align-middle" name={getIcon(input.status)} color={getIconColor(input.status)} sizeClass="medium"/> <span className="font-weight-bold align-middle d-inline-block">{t(getStatusText(input.status))}</span>
				</div>
			</div>
		)}
	</Card>
}
