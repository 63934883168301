import React, { ReactElement } from "react";
import * as ABB from "@abb/abb-common-ux-react";

export interface InfoPopupProps {
    info?: string;
    style?:React.CSSProperties;
}

export function InfoPopup(props: InfoPopupProps): ReactElement {

    let showInfo = props.info && props.info.length > 0;

    let additionalClass :string = "mx-2";

    if (props.style)
    {
        additionalClass="";
    }
    
    return (
        <>
            {showInfo &&
                <>
                    {/* &ensp; */}
                    <ABB.WithTooltip>
                        <ABB.Icon  name="abb/information-circle-2" className={"my-auto align-middle " + additionalClass}  sizeClass="small" color="color-grey-60" style={props.style}/>
                        <ABB.Tooltip style={{ maxWidth: "250px" }} children={props.info} />
                    </ABB.WithTooltip>
                </>}

        </>
    );
}
