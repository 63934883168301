export enum UnitOperationalStatus {
	Operational = 'operational',
	NotOperational = 'not_operational',
}

export enum SupervisorStatus {
	Both = 'both',
	ProcessKo = 'process_ko',
	ServiceKo = 'service_ko'
}
