import { getMultipleSamples } from '.';
import { DateTimeFormatHelper } from '../../helpers';
import { NamedSource, PathSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { CapacitorStatusItemProps, CapacitorStatusProps } from '../../widgets';

export async function loadCapacitorStatus(config: WidgetConfig, props: Partial<CapacitorStatusProps>): Promise<CapacitorStatusProps> {
	const capacitorStatus = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as CapacitorStatusPropsConfig;
		const samples = await getMultipleSamples('number', [
			propsConfig.voltage.value.source,
			propsConfig.capacity.value.source,
		], [
			{ min: capacitorStatus.voltage!.min, max: capacitorStatus.voltage!.max, step: 0.0001 },
			{ min: capacitorStatus.capacity!.min, max: capacitorStatus.capacity!.max, step: 0.0001 },
		]).then((s) => {
			s.forEach((sample) => {
				capacitorStatus.lastUpdate = getGreaterDate(capacitorStatus.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined)
			})
			return s;
		});
		capacitorStatus.voltage = { 
			...capacitorStatus.voltage,
			value: Number(samples[0]?.value),
		} as CapacitorStatusItemProps;
		capacitorStatus.capacity = { 
			...capacitorStatus.capacity, 
			value: Number(samples[1]?.value),
		} as CapacitorStatusItemProps;
	} catch (e) {
		console.error("loadCapacitorStatus", e);
		capacitorStatus.exception = e;
	} finally {
		capacitorStatus.fetching = false;
	}
	return capacitorStatus as CapacitorStatusProps;
}

export interface CapacitorStatusItemPropsConfig {
	min: { source: PathSource },
	max: { source: PathSource },
	value: { source: NamedSource },
	lowAlarm?: { source: PathSource },
	lowWarn?: { source: PathSource },
	highAlarm?: { source: PathSource },
	highWarn?: { source: PathSource },
}

export interface CapacitorStatusPropsConfig {
	title: {
		i18n: string,
	},
	voltage: CapacitorStatusItemPropsConfig,
	capacity: CapacitorStatusItemPropsConfig,
}
