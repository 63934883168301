
import React, { ReactElement, useState } from 'react'
import { Card } from '../../components'
import * as ABB from '@abb/abb-common-ux-react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { AccessPointNetworkSettings, EthernetNetworkSettings } from '../../models/api/BoardNetworkConfig'
import { Role } from '../../enums'
import { useReadable } from '../../observables/hooks'
import { user$ } from '../../services/user'

export type SettingsNetworkLabelsProps<T extends EthernetNetworkSettings | AccessPointNetworkSettings> = {
	interfaceName: string,
	interfaceConfig: T,
	onChange: (updatedModel: T) => void,
};


export function SettingsNetworkLabels<T extends EthernetNetworkSettings | AccessPointNetworkSettings>(props: SettingsNetworkLabelsProps<T>): ReactElement {
	if ('SSID' in props.interfaceConfig) {
		return <WifiNetworkLabels {...(props as unknown as SettingsNetworkLabelsProps<AccessPointNetworkSettings>)} />
	} else {
		return <NetworkLabels {...(props as unknown as SettingsNetworkLabelsProps<EthernetNetworkSettings>)} />;
	}
}

function makeHandleChange<T>(setState: (state: T) => void, onChange?: (value: T) => void) {
	return function handleChange<K extends keyof T>(state: T, key: K, value: T[K]) {
		const newState = {
			...state,
			[key]: value,
		};
		setState(newState);
		onChange?.(newState);
	}
}


function NetworkLabels(props: SettingsNetworkLabelsProps<EthernetNetworkSettings>): ReactElement {
	const { t } = useTranslation();
	const [config, setConfig] = useState<EthernetNetworkSettings>(props.interfaceConfig);
	const userRole = useReadable(user$)?.role ?? Role.None;
	let isAdmin = userRole === Role.Admin || userRole === Role.ABB;
	const pattern = "(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])";

	const handleChange = makeHandleChange(setConfig, props.onChange);

	return (
		<Card title={t('TcpIpNetworkParameters') + ": " + props.interfaceName} mobileCols={12} tabletCols={12} desktopCols={6}>
			<ABB.RadioGroup orientation="horizontal"
				value={config.dhcp ? "1" : "0"}
				onChange={value => handleChange(config, 'dhcp', value === "1")}
				disabled={!isAdmin || !config.available}
				sizeClass="small">
				<ABB.RadioOption text={t("DHCP")} value="1" />
				<ABB.RadioOption text={t('Static')} value="0" />
			</ABB.RadioGroup>
			<ABB.Input type="normal"
				required={!config.dhcp}
				disabled={config.dhcp || !isAdmin || !config.available}
				dataType="text"
				label={t('IpAddress')}
				inputAttributes={{ pattern }}
				value={config.address}
				onValueChange={(v) => handleChange(config, 'address', v)}
				showValidationBarWhenValid={false}
				showValidationIconWhenValid={false}
				showValidationBarWhenInvalid={true}
				showValidationIconWhenInvalid={true}
			/>
			<ABB.Input type="normal"
				required={!config.dhcp}
				disabled={config.dhcp || !isAdmin || !config.available}
				dataType="text"
				label={t('SubnetMask')}
				inputAttributes={{ pattern }}
				value={config.netmask}
				onValueChange={(v) => handleChange(config, 'netmask', v)}
				showValidationBarWhenValid={false}
				showValidationIconWhenValid={false}
				showValidationBarWhenInvalid={true}
				showValidationIconWhenInvalid={true}
			/>
			<ABB.Input type="normal"
				required={false}
				disabled={config.dhcp || !isAdmin || !config.available}
				dataType="text"
				label={t('Gateway')}
				inputAttributes={{ pattern }}
				value={config.gateway}
				onValueChange={(v) => handleChange(config, 'gateway', v)}
				showValidationBarWhenValid={false}
				showValidationIconWhenValid={false}
				showValidationBarWhenInvalid={true}
				showValidationIconWhenInvalid={true}
			/>
		</Card>)
}

function WifiNetworkLabels(props: SettingsNetworkLabelsProps<AccessPointNetworkSettings>): ReactElement {

	const { t } = useTranslation();
	const [model, setModel] = useState<AccessPointNetworkSettings>(props.interfaceConfig);

	const handleChange = makeHandleChange(setModel, props.onChange);
	return (
		<Card title={t('WiFiNetworkParameters') + ": " + props.interfaceName} contentClassName="my-0" mobileCols={12} tabletCols={12} desktopCols={6}>
			<ABB.Input type="normal"
				required={false}
				dataType="text"
				label={t('WirelessNetworkNameSsid')}
				inputAttributes={{ pattern: "[A-Za-z0-9\\-]{1,25}" }}
				value={model.SSID}
				onValueChange={(v) => handleChange(model, 'SSID', v)}
				showValidationBarWhenValid={false}
				showValidationIconWhenValid={false}
				showValidationBarWhenInvalid={true}
				showValidationIconWhenInvalid={true}
				disabled={true}
			/>
			<ABB.Input type="normal"
				required={false}
				dataType="text"
				label={t('WPAPassphrase')}
				value={model.WPAPassphrase}
				onValueChange={(v) => handleChange(model, 'WPAPassphrase', v)}
				showValidationBarWhenValid={false}
				showValidationIconWhenValid={false}
				showValidationBarWhenInvalid={true}
				showValidationIconWhenInvalid={true}
				disabled={true}
			/>
			<ABB.Input type="normal"
				required={false}
				dataType="number"
				label={t('Channel')}
				inputAttributes={{ max: "9999999999", step: "1" }}
				value={String(model.channel)}
				onValueChange={(v) => handleChange(model, 'channel', Number(v || '0'))}
				showValidationBarWhenValid={false}
				showValidationIconWhenValid={false}
				showValidationBarWhenInvalid={true}
				showValidationIconWhenInvalid={true}
				disabled={true}
			/>
		</Card>)
}
