import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export interface InputStatusItemProps {
	title: string;
	ready: boolean;
	active: boolean;
}

export function CircleIcon({ fill, stroke }: { fill: string, stroke: string }): ReactElement {
	return <svg viewBox="0 0 20 20" className="circle-icon">
		<circle r="9" cx="10" cy="10" fill={fill} stroke={stroke} strokeWidth="2"/>
	</svg>
}

export function InputStatusItem(props: InputStatusItemProps): ReactElement {
	const { t } = useTranslation();
	return <div className="col-lg-6 col-12">
		<div className="input-status-item-wrapper d-flex align-items-center">
			<div className="d-flex flex-column mr-3">
				<span className="input-status-item-label mb-1 d-flex align-items-center">
					<CircleIcon fill={props.ready ? "blue" : "none"} stroke={props.ready ? "blue" : "gray"} /><span className="ml-1">{t("Ready")}</span></span>
				<span className="input-status-item-label d-flex align-items-center">
					<CircleIcon fill={props.active ? "blue" : "none"} stroke={props.active ? "blue" : "gray"} /><span className="ml-1">{t("Active")}</span></span>
			</div>
			<h6 className="font-weight-bold mb-0">{props.title}</h6>
		</div>
	</div>
}
