import { ChartPropsConfig, getChartDataList } from '.';
import { checkWaveform, eventsList$, getLastEventValue, getLastGenericSample, getValue } from '..';
import { Duration, SyncLabel } from '../../../components';
import { SyncAndPositionStatus } from '../../../enums/SyncAndPositionStatus';
import { DateTimeFormatHelper } from '../../../helpers';
import { ApiIdentityItem, LastUpdateType, WaveformChartDataItem, WaveformChartDataList, WaveformOperation } from '../../../models';
import { NamedSource, PathSource, WidgetConfig } from '../../../pages/DynamicPage';
import { WaveformChartProps } from '../../../widgets';
import { apiService } from '../../api';
import { devicesMetadata$ } from '../../device';
import { getConstantChartData, setConstantChartData } from '../startup';

export async function loadWaveformChartData(config: WidgetConfig, props: Partial<WaveformChartProps>): Promise<WaveformChartProps> {
	console.info("loadWaveformChartData");
	const waveformChart = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as WaveformChartPropsConfig;
		if (propsConfig.series) {
			const data = await getWaveformChartDataList(propsConfig.series.source, propsConfig.usePositionEvent?.value ?? false, propsConfig.dateFrom?.value, propsConfig.groupId?.value);
			waveformChart.data = data.data;
			waveformChart.lastUpdate = DateTimeFormatHelper.toDate(data.date_us);
			if (propsConfig.duration?.source) {
				const duration = await getLastGenericSample('number', propsConfig.duration.source, { min: 1000, max: 10000 });
				waveformChart.topCenterContent = () => Duration({
					durationMs: duration?.value ?? null,
					displayUnit: waveformChart.timeUnit ?? 'ms',
					text: propsConfig.durationLabel?.i18n ?? '',
				});			
			}
			if (propsConfig.showRange?.value) {
				if (propsConfig.upperRange?.source && propsConfig.lowerRange?.source) {
					if (data.data.length) {
						const rangeData = await getChartDataList({
							startDate: DateTimeFormatHelper.toDate(data.date_us)!,
							endDate: DateTimeFormatHelper.toDate(data.date_us + data.data[data.data.length - 1].t_us)!,
						}, undefined, propsConfig.upperRange.source, propsConfig.lowerRange.source);
						waveformChart.rangeData = rangeData.data.map((d) => ({
							value: d.value!,
							value2: d.value2,
							t_us: d.date_us - data.date_us,
						}));
					} else {
						waveformChart.rangeData = [];
					}
				} else if (propsConfig.range?.source && propsConfig.rangeToleranceClose?.source && propsConfig.rangeToleranceOpen?.source) {
					// the constant data may still depend on the operation type, so we add the current operation to the api
					let rangeData = getConstantChartData({ ...propsConfig.range.source, operation: propsConfig.series.source.operation });
					if (!rangeData) {
						rangeData = await getWaveformChartDataList({ ...propsConfig.range.source, operation: propsConfig.series.source.operation }, propsConfig.usePositionEvent?.value ?? false, propsConfig.dateFrom?.value);
						setConstantChartData({ ...propsConfig.range.source, operation: propsConfig.series.source.operation }, rangeData);
					}
					const tolerance = Number(propsConfig.series.source.operation === WaveformOperation.closing ? getValue(propsConfig.rangeToleranceClose.source) : getValue(propsConfig.rangeToleranceOpen.source));
					waveformChart.rangeData = rangeData.data.map((d) => ({
						value: d.value! + tolerance,
						value2: d.value! - tolerance,
						t_us: (d as WaveformChartDataItem).t_us!,
					}));
				}
			}
		} else {
			const allWaveforms = await Promise.all(propsConfig.data!
					.filter((d) => d.series.source.type === 'waveform')
					.map(async (d) => {
						if (!d.constant?.value) {
							return getWaveformChartDataList(d.series.source, propsConfig.usePositionEvent?.value ?? false, propsConfig.dateFrom?.value, propsConfig.groupId?.value)
						} 
						const constantProfile = getConstantChartData(d.series.source);
						if (constantProfile) { return Promise.resolve(constantProfile as WaveformChartDataList); }
						const res = await getWaveformChartDataList(d.series.source, propsConfig.usePositionEvent?.value ?? false, propsConfig.dateFrom?.value);
						setConstantChartData(d.series.source, res);
						return res;
					}));
			const data: WaveformChartDataItem[] = [];
			// TODO: API per richiedere più waveform?
			allWaveforms.forEach((waveform, waveformIndex) => {
				waveform.data.forEach((value, i) => {
					if (!data[i]) {
						data[i] = value;
					} else {
						data[i][`value${waveformIndex + 1}` as 'value2'|'value3'|'value4'] = value.value;
					}
				})
			});
			waveformChart.lastUpdate = DateTimeFormatHelper.toDate(allWaveforms.reduce((lastUpdate, waveform) => {
				return waveform.date_us > lastUpdate ? waveform.date_us : lastUpdate;
			}, 0));
			waveformChart.data = data;

			if (propsConfig.lastSyncEvent?.source) {
				const event = getLastEventValue(propsConfig.lastSyncEvent.source);
				const isSyncEvent = (event: SyncAndPositionStatus): boolean => {
					switch (event) {
						case SyncAndPositionStatus.OpenSync:
						case SyncAndPositionStatus.CloseSync: {
							return true;
						}
						default:
							return false;
					}
				}
				waveformChart.topRightContent = () => SyncLabel({
					sync: isSyncEvent(event.mappedValue as SyncAndPositionStatus),
				});
			}
		}
		waveformChart.lastUpdateType = LastUpdateType.Sample;
	} catch (e) {
		console.error("loadWaveformChartData", e);
		waveformChart.exception = e;
	} finally {
		waveformChart.fetching = false;
	}
	return waveformChart as WaveformChartProps;
}

async function getWaveformChartDataList(apiWave: ApiIdentityItem, usePositionEvent: boolean, startDate?: number, groupId?: number): Promise<WaveformChartDataList> {
	checkWaveform("getWaveformChartDataList", apiWave);
	let operation = apiWave.operation!;
	let dateFrom: number | undefined = startDate;
	if (usePositionEvent) {
		const positionEvent = eventsList$.getValue().events.find(e => e.model === "breaker" && e.name === "position");
		if (!positionEvent) {
			throw new Error("getChartDataList | breaker position event not found");
		}

		const state = devicesMetadata$.getValue().models["breaker"]!.events!["position"].states![positionEvent.state];
		const currentPosition = state === "close" || state === "closing" ? WaveformOperation.closing : WaveformOperation.opening;

		if (operation === WaveformOperation.last || operation === currentPosition) {
			dateFrom = positionEvent.timestamp_us - 1000000;
			operation = currentPosition;
		}
	}

	const waveform = await apiService.getLastWaveform(apiWave.device!.model, apiWave.device!.id, apiWave.name!, operation, dateFrom, groupId);
	const data: WaveformChartDataItem[] = [];

	if (waveform && waveform.samples && waveform.samples.length > 0) {
		let ts = 0;
		let step = waveform.sampling_rate_us;
		for (const sample of waveform.samples) {
			data.push({
				value: parseFloat(sample),
				t_us: ts
			});

			ts += step;
		}
		return {
			data,
			date_us: waveform.timestamp_us
		};
	}

	return {
		data: [],
		date_us: 0
	};
}

interface NamedSourceWithOperation extends NamedSource {
	operation: WaveformOperation,
}

export interface WaveformChartPropsConfig extends ChartPropsConfig {
	series?: {
		source: NamedSourceWithOperation,
	},
	xAxisLabel?: {
		i18n: string,
	},
	yAxisLabel?: {
		i18n: string,
	},
	data?: {
		series: {
			source: NamedSourceWithOperation,
		},
		color: {
			value: string,
		},
		lineStyle?: {
			value: string,
		},
		name: {
			i18n: string,
		},
		dotted?: {
			value: boolean,
		},
		constant?: {
			value: boolean,
		}
	}[],
	title: {
		i18n: string,
	},
	color?: {
		value: string,
	},
	lineStyle?: {
		value: string,
	},
	dotStyle?: {
		value: string,
	},
	showRange?: {
		value: boolean,
	},
	showHorizontalGrid?: {
		value: boolean,
	},
	hideYAxis?: {
		value: boolean,
	},
	usePositionEvent?: {
		value: boolean,
	},
	dateFrom?: {
		value: number,
	},
	timeUnit: {
		value: string,
	},
	duration?: {
		source: NamedSource,
	},
	durationLabel?: {
		i18n: string,
	},
	lastSyncEvent?: {
		source: NamedSource,
	},
	upperRange?: {
		source: NamedSource,
	},
	lowerRange?: {
		source: NamedSource,
	},
	range?: {
		source: NamedSource,
	},
	rangeToleranceOpen?: {
		source: PathSource,
	},
	rangeToleranceClose?: {
		source: PathSource,
	},
	groupId?: {
		value: number,
	}
}
