import React, { ReactElement, ReactNode, useMemo, useRef, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react'
import ReactDOM from 'react-dom';
import { useLayoutEffect } from 'react';
import { createContext } from 'react';

export const ContextMenuVisibility = createContext({
    setVisible: (value: boolean) => {},
});

export interface ContextMenuProps {
	children: ReactNode[]|ReactNode,
	buttonType?: 'icon'|'ghost',
	buttonIcon?: string,
}
export const ContextMenu = ({ children, buttonType, buttonIcon = 'abb/more' }: ContextMenuProps): ReactElement => {
    const [visible, setVisible] = useState(false);
    const contextMenuWrapperRef = useRef<HTMLDivElement | null>(null);
    const floatingContentRef = useRef<HTMLDivElement | null>(null);

    const [offsetWidth, setOffsetWidth] = useState(0);
    const [boundingClientRect, setBoundingClientRect] = useState<null | DOMRect>(null);

    useLayoutEffect(() => {
        if (contextMenuWrapperRef.current) {
            const candidate = contextMenuWrapperRef.current.closest('.ABB_CommonUX_AppMainContent__root') as HTMLElement | null;

            if (candidate) {
                const scrollableAncestor = candidate;

                const update = () => {
                    setVisible(false);
                };

                scrollableAncestor.addEventListener('scroll', update);

                update();

                return () => scrollableAncestor.removeEventListener('scroll', update);
            }
        }
    }, [contextMenuWrapperRef]);

    const floatingTopLeft = useMemo(() => ({
        top: boundingClientRect?.top || 0,
        left: (boundingClientRect?.right || 0) - offsetWidth,
    }), [offsetWidth, boundingClientRect]);

    useLayoutEffect(() => {
        if (floatingContentRef.current && contextMenuWrapperRef.current && visible) {
            const floatingContentNode = floatingContentRef.current;
            const contextMenuWrapperNode = contextMenuWrapperRef.current;

            const resizeObserver = new ResizeObserver(() => {
                setOffsetWidth(floatingContentNode.offsetWidth || 0);
                setBoundingClientRect(contextMenuWrapperNode.getBoundingClientRect() || null);
            });
            resizeObserver.observe(floatingContentRef.current);
            resizeObserver.observe(contextMenuWrapperRef.current);

            let innerClick = true;
            const onBodyClick = () => {
                if (!innerClick) {
                    setVisible(false);
                }
                innerClick = false;
            };
            const onFloatingClick = () => {
                innerClick = true;
            };

            document.body.addEventListener('click', onBodyClick);
            floatingContentRef.current.addEventListener('click', onFloatingClick);

            return () => {
                resizeObserver.disconnect();
                floatingContentNode.removeEventListener('click', onFloatingClick);
                document.body.removeEventListener('click', onBodyClick);
            };
        }
    }, [floatingContentRef, contextMenuWrapperRef, visible]);

    return (<div className="context-menu" ref={contextMenuWrapperRef}>
				{buttonType === 'icon' ? 
				<ABB.IconButton sizeClass='large' onClick={() => setVisible(true)}>
					<ABB.Icon name={buttonIcon} sizeClass='small' />
				</ABB.IconButton> :
				<ABB.Button type="ghost" sizeClass="small" icon={buttonIcon} onClick={() => setVisible(true)}></ABB.Button>}
        {ReactDOM.createPortal(
            (
                <div style={{
                    display: "block",
                    zIndex: 10000,
                    position: "absolute",
                    ...floatingTopLeft,
                    ...(visible ? {} : {
                        pointerEvents: "none",
                        opacity: "0",
                        visibility: "hidden",
                    }),
                }} ref={floatingContentRef}>
                    <div className="bg-white p-2 border">
                        <ContextMenuVisibility.Provider value={{ setVisible }}>
                            {children}
                        </ContextMenuVisibility.Provider>
                    </div>
                </div>
            ), document.body
        )
        }
    </div>);
}
