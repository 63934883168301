import React, { useCallback, useState } from "react";
import { useHistory, matchPath, useLocation } from "react-router-dom";
import * as ABB from "@abb/abb-common-ux-react";
import { Role } from "../../enums";
import { SectionsHelper } from "../../helpers";
import "./style.scss";
import AlertBell from "./AlertBell";
import AdminItem from "./AdminItem";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useReadable } from "../../observables/hooks";
import { appConfig$ } from "../../services/config";
import { topBarDropdownsOpen$ } from "../../App/layout-state";
import { user$ } from "../../services/user";
import { isDemo, scenarios$, selectedScenario$ } from '../../services/demo';

interface TopBarProps {
	userRole: Role;
	userMenuOpen: boolean;
	alertBellToggled: boolean;
	onAlertBellClick: () => void;
	onToggleUserMenuChange: (toggle?: boolean, item?: any) => void;
}

export const TopBar = (props: TopBarProps) => {
	const { t } = useTranslation();
	const userRole = props.userRole;
	const userName = useReadable(user$)?.user ?? "";
	const appConfig = useReadable(appConfig$);
	const scenarios = useReadable(scenarios$);
	const dropdownsOpen = useReadable(topBarDropdownsOpen$);
	const [sidebarStyle, setSidebarStyle] = useState<React.CSSProperties>({
		display: 'none',
	});
	let history = useHistory();
	const location = useLocation();

	const toggleSidebar = useCallback(() => {
		setSidebarStyle((previousStyle) => ({
			...previousStyle,
			display: previousStyle.display === 'none' ? 'block' : 'none',
		}));
	}, []);
	return (
		/* Previous was ABB.AppHeader, not div */
		<div className="navbar navbar-expand-lg navbar-light bg-light ">
			<button
				className="navbar-toggler order-0"
				type="button"
				aria-label={t("Toggle navigation")}
				onClick={() => {
					toggleSidebar();
					if (props.alertBellToggled) {
						props.onAlertBellClick();
					}
				}}
			>
				<span className="navbar-toggler-icon"></span>
			</button>

			<ABB.AppTopNavi className="order-1 order-lg-2 justify-child-content-end bg-white">
				{userRole !== Role.None && (
					<AlertBell
						mobileVisble={true}
						desktopVisible={true}
						tabletVisible={true}
						toggled={props.alertBellToggled}
						onClick={() => {
							props.onAlertBellClick();
						}}
					/>
				)}

				<ABB.AppTopNaviDivider />

				{userRole === Role.None ? (
					<ABB.AppTopNaviItem
						className="nav-item d-none d-lg-block d-md-block"
						icon="abb/log-in"
						text={t("Login")}
						onClick={() => props.onToggleUserMenuChange(false, { id: "login" })}
					/>
				) : (
					<AdminItem
						mobileVisble={false}
						desktopVisible={true}
						tabletVisible={true}
						userName={userName}
						menuOpen={props.userMenuOpen}
						hasMenu={true}
						onToggleUserMenu={props.onToggleUserMenuChange}
					/>
				)}
			</ABB.AppTopNavi>

			{/* ABB.AppTopNavi */}
			<div
				className="collapse navbar-collapse order-2 order-lg-1"
				style={sidebarStyle}
			>
				<div className="menu-container">
					<Breadcrumbs mobile={true} />

					{(appConfig?.topBarLinks ?? []).map((topBarLink, i) => {
						const disabled = topBarLink.disabled;
						const isDropdown = Boolean(topBarLink.dropDown);

						return (
							<ABB.AppTopNaviItem
								key={"topNaviItemSection" + i}
								href={
									disabled || !topBarLink.href
										? undefined
										: SectionsHelper.createHrefByString(
												topBarLink.href!,
												history
										  )
								}
								// onClick={() => {
								//   history.push(SectionsHelper.createHref(s, history));
								// }}
								className="nav-item"
								disabled={disabled}
								icon={topBarLink.icon}
								text={topBarLink.i18n}
								active={
									topBarLink.href
										? matchPath(location.pathname, topBarLink.href!) !== null
										: topBarLink.dropDown!.some(({ href }) =>
												matchPath(location.pathname, href!)
										  )
								}
								hasMenu={isDropdown}
								onClick={
									isDropdown
										? () =>
												topBarDropdownsOpen$.next({
													...dropdownsOpen,
													[topBarLink.i18n]: !dropdownsOpen[topBarLink.i18n],
												})
										: toggleSidebar
								}
							>
								{isDropdown && (
									<ABB.Menu
										trigger="click"
										onSelect={(selected) => {
											history.push(selected.id);
											topBarDropdownsOpen$.next({
												...dropdownsOpen,
												[topBarLink.i18n]: false,
											});
										}}
										onLostFocus={() =>
											topBarDropdownsOpen$.next({
												...dropdownsOpen,
												[topBarLink.i18n]: false,
											})
										}
										isOpen={!!dropdownsOpen[topBarLink.i18n]}
									>
										{topBarLink.dropDown!.map((dropdownEl, i) => (
											<ABB.MenuItem
												text={dropdownEl.i18n}
												itemId={dropdownEl.href}
												disabled={dropdownEl.disabled}
												key={i}
												onSelect={toggleSidebar}
											/>
										))}
									</ABB.Menu>
								)}
							</ABB.AppTopNaviItem>
						);
					})}

					<ABB.AppTopNaviDivider
						style={{
							width: "95%",
							height: "1px",
							marginLeft: "5px",
							background: "#4B4848",
						}}
						className="d-block d-md-none"
					/>

					{userRole === Role.None ? (
						<ABB.AppTopNaviItem
							className="nav-item d-block d-md-none d-lg-none"
							icon="abb/log-in"
							text={t("Login")}
							onClick={() =>
								props.onToggleUserMenuChange(false, { id: "login" })
							}
						/>
					) : (
						<>
							<AdminItem
								mobileVisble={true}
								desktopVisible={false}
								tabletVisible={false}
								userName={userName}
								menuOpen={props.userMenuOpen}
								hasMenu={false}
								onToggleUserMenu={props.onToggleUserMenuChange}
							></AdminItem>
							{isDemo && scenarios && scenarios.length > 1 && <ABB.AppTopNaviItem 
								className='nav-item d-block d-md-none'
								icon="abb/shuffle"
								text={t("Change scenario")}
								onClick={() => selectedScenario$.next(null)}
							/>}
							<ABB.AppTopNaviItem
								className="nav-item d-block d-md-none"
								icon="abb/log-out"
								text={t("Logout")}
								onClick={() =>
									props.onToggleUserMenuChange(false, { id: "logout" })
								}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
