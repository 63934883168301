import React, { ReactElement, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Card } from "../../components";
import { ChartDataItem, ChartWidgetProps } from "../../models"
import { BootstrapHelper, ChartHelper } from "../../helpers";
import "./style.scss";
import { chartFilters$, ChartThresholds } from '../../services/polling/chart';
import { useReadable } from '../../observables/hooks';
import { useRef } from 'react';
import { getColor } from '../../helpers/css';
import { SpritePointerTypeEvent } from '@amcharts/amcharts4/.internal/core/SpriteEvents';

export interface BarChartItemProps {
	unit: string;
	name: string;
	color: string;
	hoverColor: string;
}

export interface BarChartProps extends ChartWidgetProps, Partial<ChartThresholds<number>> {
	data: ChartDataItem[];
	barDataInfo: BarChartItemProps;
	lineDataInfo?: BarChartItemProps;
	showLegend?: boolean;
	dateFormat?: string;
	percentageWidth: number;
	minWidth?: number;
	referenceValue?: number;
	xAxisLabel?: string;
	yAxisLabel?: string;
	yRightAxisLabel?: string;
	xAxisType: 'category' | 'date';
	icons?: {
		sources: string[],
		mappings: (string|number|boolean)[],
		field: string | null,
		alignment: 'top' | 'bottom' | 'middle',
		width: number,
		height: number,
	};
	onBarClick?: (dataItem: am4core.Optional<am4core.DataItem>, ev: {
		type: "hit";
		target: am4charts.Column;
	} & SpritePointerTypeEvent & am4core.SpritePointEvent & am4core.SpriteMouseTouchEvent) => void,
}



export function BarChart(props: BarChartProps): ReactElement {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const chartFilters = useReadable(chartFilters$);
	const selectedFilter = props.filterRef ? chartFilters[props.filterRef] : null;
	const scaleType = selectedFilter ?? props.scaleType;

	const [chart, setChart] = useState<am4charts.XYChart | null>(null);
	const [valueAxisRight, setValueAxisRight] = useState<am4charts.ValueAxis | null>(null);
	const [valSeriesRight, setValSeriesRight] = useState<am4charts.LineSeries | null>(null);
	const [series, setSeries] = useState<am4charts.ColumnSeries | null>(null);
	const [highAlarm, setHighAlarm] = useState<am4charts.DateAxisDataItem | null>(null);
	const [highWarn, setHighWarn] = useState<am4charts.DateAxisDataItem | null>(null);
	const [lowAlarm, setLowAlarm] = useState<am4charts.DateAxisDataItem | null>(null);
	const [lowWarn, setLowWarn] = useState<am4charts.DateAxisDataItem | null>(null);
	const [xAxis, setXAxis] = useState<am4charts.DateAxis | am4charts.CategoryAxis | null>(null);
	const [yValueAxis, setYValueAxis] = useState<am4charts.ValueAxis | null>(null);

	useEffect(() => {
		if (!containerRef.current) {
			return;
		}
		const _chart = am4core.create(containerRef.current, am4charts.XYChart);
		_chart.zoomOutButton.disabled = true;
		_chart.dateFormatter.inputDateFormat = "x";

		_chart.cursor = new am4charts.XYCursor();
		_chart.cursor.behavior = "none";
		_chart.cursor.lineY.disabled = true;

		setChart(_chart);
		// Create axes
		if (props.xAxisType === 'date') {
			const _xDateAxis = _chart.xAxes.push(new am4charts.DateAxis());
			_xDateAxis.cursorTooltipEnabled = false;
			_xDateAxis.renderer.grid.template.disabled = true;
			_xDateAxis.skipEmptyPeriods = true;
			_xDateAxis.renderer.labels.template.hideOversized = true;
			_xDateAxis.renderer.minGridDistance = 40;
			_xDateAxis.renderer.line.strokeOpacity = 0;
			setXAxis(_xDateAxis);
		} else {
			const _xCategoryAxis = _chart.xAxes.push(new am4charts.CategoryAxis());
			_xCategoryAxis.dataFields.category = 'category';
			_xCategoryAxis.cursorTooltipEnabled = false;
			_xCategoryAxis.renderer.grid.template.disabled = true;
			_xCategoryAxis.renderer.labels.template.hideOversized = true;
			_xCategoryAxis.renderer.minGridDistance = 40;
			_xCategoryAxis.renderer.line.strokeOpacity = 0;
			setXAxis(_xCategoryAxis);
		}
		const _yValueAxis = _chart.yAxes.push(new am4charts.ValueAxis());
		_yValueAxis.cursorTooltipEnabled = false;
		_yValueAxis.renderer.grid.template.disabled = false;
		_yValueAxis.renderer.minGridDistance = 20;
		_yValueAxis.renderer.labels.template.hideOversized = true;
		_yValueAxis.renderer.labels.template.disabled = true;
		_yValueAxis.includeRangesInMinMax = true;

		
		setYValueAxis(_yValueAxis);

		const _series = _chart.series.push(new am4charts.ColumnSeries());
		_series.columns.template.states.create("hover");
		_series.columns.template.states.create("selected");
		_series.columns.template.states.create("dimmed");
		_series.interactionsEnabled = true;
		_series.columns.template.width = am4core.percent(40);
		_series.cursorHoverEnabled = true;
		if (props.xAxisType === 'date') {
			_series.dataFields.dateX = "date";
		} else {
			_series.dataFields.categoryX = "category";
		}
		_series.dataFields.valueY = "value";
		_series.dummyData = { seriesid: "column" };

		if (_series.tooltip) {
			_series.tooltip.getFillFromObject = false;
			_series.tooltip.background.fill = am4core.color("white");
			_series.tooltip.label.fill = am4core.color("black");
		}
		setSeries(_series);

		const _valueAxisRight = _chart.yAxes.push(new am4charts.ValueAxis());
		_valueAxisRight.cursorTooltipEnabled = false;
		_valueAxisRight.renderer.opposite = true;
		_valueAxisRight.renderer.grid.template.disabled = true;
		setValueAxisRight(_valueAxisRight);

		const _valSeriesRight = _chart.series.push(new am4charts.LineSeries());
		_valSeriesRight.cursorHoverEnabled = false;
		if (props.xAxisType === 'date') {
			_valSeriesRight.dataFields.dateX = "date";
		} else {
			_valSeriesRight.dataFields.categoryX = "category";
		}
		_valSeriesRight.dataFields.valueY = "value2";
		
		_valSeriesRight.yAxis = _valueAxisRight;
		_valSeriesRight.dummyData = { seriesid: "line" };
		_valSeriesRight.interactionsEnabled = true;
		const lineHoverState = _valSeriesRight.states.create("selected");
		lineHoverState.properties.strokeWidth = 3;
		const lineDimmed = _valSeriesRight.states.create("dimmed");
		lineDimmed.properties.stroke = am4core.color("#dadada");
		setValSeriesRight(_valSeriesRight);

		const _sampleValueAxis = _chart.yAxes.push(new am4charts.ValueAxis());
		_sampleValueAxis.renderer.opposite = true;
		_sampleValueAxis.cursorTooltipEnabled = false;
		_sampleValueAxis.renderer.grid.template.disabled = true;
		_sampleValueAxis.renderer.labels.template.disabled = true;

		_sampleValueAxis.cursorTooltipEnabled = false;
		_sampleValueAxis.renderer.grid.template.disabled = false;
		_sampleValueAxis.renderer.minGridDistance = 20;
		_sampleValueAxis.renderer.labels.template.hideOversized = true;
		_sampleValueAxis.renderer.labels.template.disabled = true;

		const _lineSeries = _chart.series.push(new am4charts.LineSeries());
		_lineSeries.name = '';
		_lineSeries.stroke = am4core.color('white');;
		_lineSeries.strokeWidth = 0;
		_lineSeries.dataFields.valueY = "value";
		if (props.xAxisType === 'date') {
			_lineSeries.dataFields.dateX = "date";
		} else {
			_lineSeries.dataFields.categoryX = "category";
		}
		_lineSeries.yAxis = _sampleValueAxis;

		setHighAlarm(_yValueAxis.axisRanges.create() as am4charts.DateAxisDataItem);
		setHighWarn(_yValueAxis.axisRanges.create() as am4charts.DateAxisDataItem);
		setLowAlarm(_yValueAxis.axisRanges.create() as am4charts.DateAxisDataItem);
		setLowWarn(_yValueAxis.axisRanges.create() as am4charts.DateAxisDataItem);
	}, [containerRef, props.xAxisType]);

	//************************************************************ LEGEND FUNCTIONS ************************************************************
	function showHideElement(i: "line" | "column") {
		if (chart && !chart.isDisposed()) {
			chart.series.each(function (singleSerie) {
				if (singleSerie.dummyData.seriesid === i)
					if (singleSerie.isHidden)
						singleSerie.show();
					else
						singleSerie.hide();
			})
		}
	}

	function setState(singleSerie: am4charts.XYSeries, state: string) {

		singleSerie.setState(state);

		if ((singleSerie as am4charts.ColumnSeries).columns)
			(singleSerie as am4charts.ColumnSeries).columns.each(function (col) { col.setState(state) });
	}

	function processOutOver(i: "line" | "column") {
		if (chart && !chart.isDisposed()) {
			chart.series.each(singleSerie => setState(singleSerie, "default"));
		}
	}

	function processOver(i: "line" | "column") {
		if (chart && !chart.isDisposed()) {
			chart.series.each(singleSerie => setState(singleSerie, singleSerie.dummyData?.seriesid === i ? "selected" : "dimmed"));
		}
	}
	//************************************************************ LEGEND FUNCTIONS ************************************************************

	useEffect(() => {
		if (yValueAxis && props.icons && !yValueAxis.isDisposed()) {
			// if icons are used, we need to make sure that they won't be cut out
			const maxValue = props.data.reduce((max, el) => {
				if (el.value && el.value > max) {
					return el.value;
				}
				return max;
			}, 0);
			yValueAxis.max = maxValue + props.icons.height * 2;
		} else if (yValueAxis && (props.alarmHighTh || props.alarmLowTh)) {
			const maxValue = props.data.reduce((max, el) => {
				if (el.value && el.value > max) {
					return el.value;
				}
				return max;
			}, props.alarmHighTh ?? 0);
			const minValue = props.data.reduce((min, el) => {
				if (el.value && el.value < min) {
					return el.value;
				}
				return min;
			}, props.alarmLowTh ?? 0);
			yValueAxis.max = maxValue;
			yValueAxis.min = minValue;
		}
	}, [props.data, yValueAxis, props.icons, props.alarmHighTh, props.alarmLowTh]);


	const firstRender = useRef(true);
	useEffect(() => {
		if (chart && !chart.isDisposed()) {
			if (!firstRender.current) {
				chart.series.each((series) => {
					series.interpolationDuration = 0;
				});
			}
			firstRender.current = false;
			chart.data = props.data;
		}
	}, [chart, props.data, firstRender]);

	useEffect(() => {
		if (chart && !chart.isDisposed() && props.xAxisLabel && xAxis) {
			xAxis.title.text = props.xAxisLabel;
			if (props.xAxisLabelAlignment) {
				xAxis.title.align = props.xAxisLabelAlignment;
			}
		}
	}, [chart, props.xAxisLabel, xAxis, props.xAxisLabelAlignment]);

	useEffect(() => {
		if (chart && !chart.isDisposed() && props.yAxisLabel && yValueAxis) {
			yValueAxis.title.text = props.yAxisLabel;
			if (props.showUnitInYLabel && props.barDataInfo.unit) {
				yValueAxis.title.text += ` (${props.barDataInfo.unit})`;
			}
			if (props.yAxisLabelAlignment) {
				yValueAxis.title.valign = props.yAxisLabelAlignment;
			}
		}
	}, [chart, props.yAxisLabel, yValueAxis, props.yAxisLabelAlignment, props.showUnitInYLabel, props.barDataInfo.unit]);

	useEffect(() => {
		if (series && props.onBarClick && !series.isDisposed()) {
			const hitHandler = (ev: any) => {
				ev.event.stopPropagation();
				props.onBarClick!(ev.target.dataItem, ev);
			}
			series.columns.template.events.on('hit', hitHandler);
			return () => {
				series.columns.template.events.off('hit', hitHandler);
			}
		}
	}, [props.onBarClick, series]);

	useEffect(() => {
		if (yValueAxis) {
			if (chart && !chart.isDisposed() && props.warningHighTh !== undefined && highWarn) {
				highWarn.value = props.warningHighTh;
				highWarn.grid.stroke = am4core.color(getColor('color-status-yellow'));
				highWarn.grid.strokeWidth = 2;
				highWarn.grid.strokeOpacity = 1;
				highWarn.label.text = props.warningHighTh.toFixed(2);
				highWarn.ignoreMinMax = false;
				yValueAxis.invalidate();
			} else if (highWarn && chart && !chart.isDisposed()) {
				highWarn.ignoreMinMax = true;
				yValueAxis.invalidate();
			}
		}
	}, [chart, props.warningHighTh, highWarn, yValueAxis]);

	useEffect(() => {
		if (yValueAxis) {
			if (chart && !chart.isDisposed() && props.alarmHighTh !== undefined && highAlarm) {
				highAlarm.value = props.alarmHighTh;
				highAlarm.grid.stroke = am4core.color(getColor('color-status-red'));
				highAlarm.grid.strokeWidth = 2;
				highAlarm.grid.strokeOpacity = 1;
				highAlarm.label.text = props.alarmHighTh.toFixed(2);
				highAlarm.ignoreMinMax = false;
				yValueAxis.invalidate();
			} else if (chart && !chart.isDisposed() && highAlarm) {
				highAlarm.ignoreMinMax = true;
				yValueAxis.invalidate();
			}
		}
	}, [chart, props.alarmHighTh, highAlarm, yValueAxis]);

	useEffect(() => {
		if (yValueAxis) {
			if (chart && !chart.isDisposed() && props.warningLowTh !== undefined && lowWarn) {
				lowWarn.value = props.warningLowTh;
				lowWarn.grid.stroke = am4core.color(getColor('color-status-yellow'));
				lowWarn.grid.strokeWidth = 2;
				lowWarn.grid.strokeOpacity = 1;
				lowWarn.label.text = props.warningLowTh.toFixed(2);
				lowWarn.ignoreMinMax = false;
				yValueAxis.invalidate();
			} else if (chart && !chart.isDisposed() && lowWarn) {
				lowWarn.ignoreMinMax = true;
				yValueAxis.invalidate();
			}
		}
	}, [chart, props.warningLowTh, lowWarn, yValueAxis]);

	useEffect(() => {
		if (yValueAxis) {
			if (chart && !chart.isDisposed() && props.alarmLowTh !== undefined && lowAlarm) {
				lowAlarm.value = props.alarmLowTh;
				lowAlarm.grid.stroke = am4core.color(getColor('color-status-red'));
				lowAlarm.grid.strokeWidth = 2;
				lowAlarm.grid.strokeOpacity = 1;
				lowAlarm.label.text = props.alarmLowTh.toFixed(2);
				lowAlarm.ignoreMinMax = false;
				yValueAxis.invalidate();
			} else if (chart && !chart.isDisposed() && lowAlarm) {
				lowAlarm.ignoreMinMax = true;
				yValueAxis.invalidate();
			}
		}
	}, [chart, props.alarmLowTh, lowAlarm, yValueAxis]);

	useEffect(() => {
		if (chart && !chart.isDisposed() && props.barDataInfo && series) {
			const mainColor = am4core.color(props.barDataInfo.color);
			const hoverState = series.columns.template.states.getKey("hover")!;
			hoverState.properties.fill = am4core.color(props.barDataInfo.hoverColor);
			hoverState.properties.stroke = hoverState.properties.fill;
			hoverState.properties.fillOpacity = 1;
			const selectedState = series.columns.template.states.getKey("selected")!;
			selectedState.properties.stroke = am4core.color(props.barDataInfo.hoverColor);
			selectedState.properties.fillOpacity = 1;
			selectedState.properties.strokeWidth = 3;
			const dimmed = series.columns.template.states.getKey("dimmed")!;
			dimmed.properties.fill = am4core.color("#dadada");
			dimmed.properties.stroke = dimmed.properties.fill;
			dimmed.properties.fillOpacity = 0.8;
			series.name = props.barDataInfo.name;
			series.stroke = mainColor;
			series.fill = mainColor;
			series.dateFormatter.dateFormat = 'yyyy-MM-dd hh:mm:ss a';
			if (props.xAxisType === 'date') {
				series.tooltipText = "{dateX} [bold]{valueY}" + props.barDataInfo.unit + "[/]";
			} else {
				series.tooltipText = "[bold]{valueY}" + props.barDataInfo.unit + "[/] ({categoryX})";
			}
		}
	}, [chart, props.barDataInfo, series, props.xAxisType]);

	useEffect(() => {
		if (chart && !chart.isDisposed() && props.lineDataInfo && valueAxisRight && valSeriesRight) {
			const mainColor = am4core.color(props.lineDataInfo.color);
			valSeriesRight.strokeWidth = 2;
			valSeriesRight.stroke = mainColor;
			valSeriesRight.fill = mainColor;
			if (props.yRightAxisLabel) {
				valueAxisRight.title.text = props.yRightAxisLabel;
				valueAxisRight.title.rotation = -90;
			}
		}
	}, [chart, props.lineDataInfo, props.yRightAxisLabel, valueAxisRight, valSeriesRight]);

	useEffect(() => {
		if (series && props.icons && !series.isDisposed()) {
			if (series.dataFields.customValue !== props.icons.field && props.icons.field !== null) {
				series.dataFields.customValue = props.icons.field;
			}
			const icon = series.columns.template.createChild(am4core.Image);
			icon.align = "center";
			icon.valign = props.icons.alignment;
			icon.width = props.icons.width;
			icon.height = props.icons.height;
			icon.dy = props.icons.height * -1.2;
			icon.fill = am4core.color("#fff");
			icon.fillOpacity = 0.5;
			icon.adapter.add("href", function (_href, target) {
				if (!series.dataFields.customValue || !target.dataItem) {
					return props.icons!.sources[0];
				}
				const currentValue = target.dataItem.values.customValue.value;
				const mappingIndex = props.icons!.mappings.findIndex((mapping) => mapping === currentValue);
				return props.icons!.sources[mappingIndex];
			});
		}
	}, [series, props.icons]);

	useEffect(() => {
		//cleanup
		return () => {
			if (chart && !chart.isDisposed()) {
				chart.dispose();
				setChart(null);
			}
		};
	}, [chart, props.xAxisType]);

	useEffect(() => {
		if (props.xAxisType === 'date') {
			const formats: am4core.TimeUnit[] = ["millisecond", "second", "minute", "hour", "day", "week", "month", "year"];
			if (chart && !chart.isDisposed() && !props.dateFormat) {
				chart.xAxes.each(a => ChartHelper.setDateFormat(scaleType, a as am4charts.DateAxis));
			} else if (props.dateFormat && !chart?.isDisposed()) {
				chart?.xAxes.each(a => formats.forEach((f) => (a as am4charts.DateAxis).dateFormats.setKey(f, props.dateFormat!)));
			}
		}
	}, [scaleType, chart, props.dateFormat, props.xAxisType]);

	return (
		<Card title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
			installed={props.installed}
			disconnected={props.disconnected}
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(12, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(6, props.desktopCols)}>

			{props.showLegend &&
				<div className="d-flex flex-row justify-content-between" style={{ flexWrap: "wrap", marginRight: "15px", marginLeft: "15px", marginBottom: "15px", alignItems: "center" }}>
					<div className="d-flex legendToggle-margin justify-content-center" style={{ marginLeft: "-15px" }}>

						<div className="d-flex flex-col" style={{ cursor: "pointer" }} onClick={() => showHideElement("column")} onMouseOver={() => processOver("column")} onMouseOut={() => processOutOver("column")}>
							<div className="istogram-legend-square" style={{ background: props.barDataInfo.color, marginRight: "10px", marginLeft: "15px" }} />{props.barDataInfo.name}
						</div>
						{props.lineDataInfo &&
							<div className="d-flex flex-col" style={{ cursor: "pointer" }} onClick={() => showHideElement("line")} onMouseOver={() => processOver("line")} onMouseOut={() => processOutOver("line")}>
								<div className="istogram-legend-line" style={{ background: props.lineDataInfo.color, marginRight: "10px", marginLeft: "15px" }} />{props.lineDataInfo.name}
							</div>
						}
					</div>

				</div>}

			<div style={props.percentageWidth > 100 ? { overflowX: 'auto' } : undefined}>
				<div style={{ width: `${props.percentageWidth}%`, minWidth: props.minWidth ? `${props.minWidth}px` : undefined }} ref={containerRef} className={"appChart"}></div>
			</div>
		</Card>
	);
}
