import { useTranslation } from "react-i18next";
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from "../../components";
import "./style.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SaveUser, User, user$ } from "../../services/user";
import { apiService } from "../../services/api";
import { fireErrorNotification, fireNotification } from "../../toasts";
import { useReadable } from "../../observables/hooks";
import { Role } from "../../enums";
import { useMountedRef } from '../../utils/hooks';

interface AddUserDialogParams {
	onClose: (refresh?: boolean) => void;
	isOpen: boolean;
}
const AddUserDialog: React.FC<AddUserDialogParams> = ({ onClose, isOpen }) => {
	const { t } = useTranslation();
	const [newUser, setNewUser] = useState<SaveUser>({
		user: "",
		role: Role.User,
	});

	const [isCreating, setCreating] = useState(false);

	//create user

	const [userType, setUserType] = useState<
		{
			value: Role.User | Role.Admin;
			label: string;
			isNew?: boolean;
		}[]
	>([
		{
			value: Role.User,
			label: t("User"),
		},
	]);

	const handleSaveUser = useCallback(async () => {
		setCreating(true);
		try {
			await apiService.createUser(newUser);
			fireNotification({
				severity: "success",
				text: t("UserCreatedSuccessfully", { user: newUser.user }),
				type: "banner",
			});
			setNewUser({ user: "", role: Role.User });
			setUserType([{ value: Role.User, label: t("User") }]);
			onClose(true);
		} catch (err) {
			fireErrorNotification({
				severity: "alarm",
				text: t("CannotCreateUser", { user: newUser.user }),
				type: "banner",
			}, err, t);
		} finally {
			setCreating(false);
		}
	}, [newUser, t, onClose]);

	useEffect(() => {
		if (userType.length) {
			setNewUser((previousValue) => ({
				...previousValue,
				role: userType[0].value,
			}));
		}
	}, [userType]);

	return (
		<ABB.Dialog
			style={{ overflowY: "visible" }}
			title={t("CreateUser")}
			isOpen={isOpen}
			onClose={isCreating ? undefined : () => onClose(false)}
			dimBackground
			showCloseButton
		>
			<ABB.Input
				dataType="text"
				required={true}
				type="normal"
				label={t("Username")}
				placeholder={t("Username")}
				value={newUser.user}
				onValueChange={(v) => {
					setNewUser((oldData) => ({
						...oldData,
						user: v,
					}));
				}}
			></ABB.Input>

			<div>
				<ABB.Dropdown
					clearable={false}
					label={t("Role")}
					required={true}
					placeholder={t("Role")}
					clearOnEscape={false}
					value={userType}
					onChange={(role) => setUserType(role)}
				>
					<ABB.DropdownOption label={t("User")} value={Role.User} />
					<ABB.DropdownOption label={t("Admin")} value={Role.Admin} />
				</ABB.Dropdown>
			</div>
			<div className="d-flex justify-content-end">
				<ABB.Button
					disabled={!newUser.role || !newUser.user}
					className="my-2"
					type="primary-blue"
					sizeClass="small"
					icon="abb/save"
					text={t("CreateUser")}
					isLoading={isCreating}
					onClick={() => handleSaveUser()}
				></ABB.Button>
			</div>
		</ABB.Dialog>
	);
};

interface ConfirmDeleteDialogProps {
	isOpen: boolean;
	onClose: (refresh?: boolean) => void;
	userToDelete: User | null;
}
const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
	isOpen,
	onClose,
	userToDelete,
}) => {
	const { t } = useTranslation();
	const [isDeleting, setIsDeleting] = useState(false);
	const mountedRef = useMountedRef();
	const handleDelete = useCallback(async () => {
		if (!userToDelete) {
			return;
		}
		setIsDeleting(true);
		try {
			await apiService.deleteUser(userToDelete.user);
			fireNotification({
				severity: "success",
				text: t("UserDeleted", { user: userToDelete.user }),
				type: "banner",
			});
			onClose(true);
		} catch (err) {
			fireErrorNotification({
				severity: "alarm",
				text: t("CannotDeleteUser", { user: userToDelete.user }),
				type: "banner",
			}, err, t);
		} finally {
			if (mountedRef.current) {
				setIsDeleting(false);
			}
		}
	}, [userToDelete, t, onClose, mountedRef]);

	return (
		<ABB.Dialog
			title={t("ConfirmDeleteUserTitle", { user: userToDelete?.user ?? "" })}
			onClose={isDeleting ? undefined : () => onClose(false)}
			isOpen={isOpen}
			dimBackground
			showCloseButton
		>
			<p>{t("ConfirmDeleteUserMessage")}</p>
			<div className="d-flex justify-content-end">
				<ABB.Button
					className="mr-2"
					onClick={() => onClose(false)}
					text={t("Cancel")}
					disabled={isDeleting}
					sizeClass="small"
				/>
				<ABB.Button
					onClick={handleDelete}
					text={t("Confirm")}
					isLoading={isDeleting}
					type="primary-blue"
					sizeClass="small"
				/>
			</div>
		</ABB.Dialog>
	);
};

interface ConfirmResetPasswordDialogProps {
	isOpen: boolean;
	onClose: (refresh?: boolean) => void;
	userResetPassword: User | null;
}

const ConfirmResetPasswordDialog: React.FC<ConfirmResetPasswordDialogProps> = ({
	isOpen,
	onClose,
	userResetPassword,
}) => {
	const { t } = useTranslation();
	const [isResettingPassword, setIsResettingPassword] = useState(false);
	const mountedRef = useMountedRef();
	const handleResetPassword = useCallback(async () => {
		if (!userResetPassword) {
			return;
		}
		setIsResettingPassword(true);
		try {
			await apiService.resetUserPassword(userResetPassword.user);
			fireNotification({
				severity: "success",
				text: t("UserPasswordReset", { user: userResetPassword.user }),
				type: "banner",
			});
			onClose(true);
		} catch (err) {
			fireErrorNotification({
				severity: "alarm",
				text: t("CannotResetUserPassword", { user: userResetPassword.user }),
				type: "banner",
			}, err, t);
		} finally {
			if (mountedRef.current) {
				setIsResettingPassword(false);
			}
		}
	}, [userResetPassword, t, onClose, mountedRef]);
	return (
		<ABB.Dialog
			title={t("ConfirmResetUserPasswordTitle", {
				user: userResetPassword?.user ?? "",
			})}
			onClose={isResettingPassword ? undefined : () => onClose(false)}
			isOpen={isOpen}
			dimBackground
			showCloseButton
		>
			<p>{t("ConfirmResetUserPasswordMessage")}</p>
			<div className="d-flex justify-content-end">
				<ABB.Button
					className="mr-2"
					onClick={() => onClose(false)}
					disabled={isResettingPassword}
					text={t("Cancel")}
					sizeClass="small"
				/>
				<ABB.Button
					onClick={handleResetPassword}
					text={t("Confirm")}
					isLoading={isResettingPassword}
					type="primary-blue"
					sizeClass="small"
				/>
			</div>
		</ABB.Dialog>
	);
};

export const UserList: React.FC = () => {
	const { t } = useTranslation();
	const self = useReadable(user$);
	const [showAddUserDialog, setShowAddUserDialog] = useState(false);
	const [userList, setUserList] = useState<User[]>([]);
	const [rows, setRows] = useState<ABB.DatagridRow[]>([]);
	const [loading, setLoading] = useState(false);
	const [sortOrder, setSortOrder] = useState<
		{ field: string; desc: boolean } | undefined
	>(undefined);
	const [userToDelete, setUserToDelete] = useState<User | null>(null);
	const [userResetPassword, setUserResetPassword] = useState<User | null>(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
	const mountedRef = useMountedRef();
	const columns = useMemo<ABB.DatagridColumnDef[]>(() => {
		return [
			{
				title: t("Name"),
				fieldKey: "user",
			},
			{
				title: t("Role"),
				fieldKey: "role",
			},
			{
				title: t("Password"),
				fieldKey: "passwordStatus",
				width: 110,
			},
			{
				fieldKey: "resetPassword",
				width: 175,
				sortable: false,
			},
			{
				fieldKey: "delete",
				width: 110,
				sortable: false,
			},
		];
	}, [t]);

	const getUserList = useCallback(async () => {
		setLoading(true);
		try {
			const users = await apiService.getUserList();
			if (mountedRef.current) {
				setUserList(users);
			}
		} finally {
			if (mountedRef.current) {
				setLoading(false);
			}
		}
	}, [mountedRef]);

	const hideDeleteDialog = useCallback(
		(refresh = false) => {
			setShowDeleteDialog(false);
			setUserToDelete(null);
			if (refresh) {
				getUserList();
			}
		},
		[getUserList]
	);

	const hideResetPasswordDialog = useCallback(
		(refresh = false) => {
			setShowResetPasswordDialog(false);
			setUserResetPassword(null);
			if (refresh) {
				getUserList();
			}
		},
		[getUserList]
	);

	const hideAddUserDialog = useCallback(
		(refresh = false) => {
			setShowAddUserDialog(false);
			if (refresh) {
				getUserList();
			}
		},
		[getUserList]
	);

	useEffect(() => {
		getUserList();
	}, [getUserList]);

	useEffect(() => {
		setRows(
			userList.map((u) => ({
				fields: {
					user: u.user,
					role: u.role,
					passwordStatus: (
						<div className="text-center">
							{u.forceChangePassword ? (
								<ABB.WithTooltip>
									<ABB.Icon
										sizeClass="medium"
										name="abb/warning-triangle"
										color="color-status-red"
									/>
									<ABB.Tooltip>{t("PasswordNotUpdated")}</ABB.Tooltip>
								</ABB.WithTooltip>
							) : (
								<ABB.WithTooltip>
									<ABB.Icon
										sizeClass="medium"
										name="abb/check-mark-circle-1"
										color="color-status-green"
									/>
									<ABB.Tooltip>{t("PasswordUpdated")}</ABB.Tooltip>
								</ABB.WithTooltip>
							)}
						</div>
					),
					resetPassword: (
						<ABB.Button
							sizeClass="small"
							text={t("ResetPassword")}
							onClick={() => {
								setUserResetPassword(u);
								setShowResetPasswordDialog(true);
							}}
						/>
					),
					delete: (
						<ABB.Button
							sizeClass="small"
							disabled={u.user === self?.user}
							text={t("Delete")}
							onClick={() => {
								setUserToDelete(u);
								setShowDeleteDialog(true);
							}}
						/>
					),
				},
			}))
		);
	}, [t, userList, self]);

	return (
		<>
			<Card
				tabletCols={12}
				className="user-list-card"
				mobileCols={12}
				desktopCols={12}
				title={t("UserList")}
				topRightContent={() => (
					<ABB.Button
						text={t("AddUser")}
						className="add-user-button my-2"
						sizeClass="small"
						type="primary-blue"
						icon="abb/add-user"
						onClick={() => setShowAddUserDialog(true)}
					/>
				)}
			>
				{loading ? (
					<div className="text-center">
						<ABB.Spinner color="blue" sizeClass="large" />
					</div>
				) : (
					<div style={{ overflowX: "auto" }}>
						<ABB.Datagrid
							style={{ minWidth: 600 }}
							columns={columns}
							data={rows}
							enableSorting={true}
							sortOrder={sortOrder}
							onSort={(newSort) => {
								setSortOrder(
									newSort as { field: string; desc: boolean } | undefined
								);
							}}
						/>
					</div>
				)}
			</Card>
			<AddUserDialog isOpen={showAddUserDialog} onClose={hideAddUserDialog} />
			<ConfirmDeleteDialog
				isOpen={showDeleteDialog}
				onClose={hideDeleteDialog}
				userToDelete={userToDelete}
			/>
			<ConfirmResetPasswordDialog
				isOpen={showResetPasswordDialog}
				onClose={hideResetPasswordDialog}
				userResetPassword={userResetPassword}
			/>
		</>
	);
};
