import { getHealthIndexStatus, getLastEventValue } from '.';
import { LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { MechanicalSensorProps } from '../../widgets';

export async function loadMechanicalSensorData(config: WidgetConfig, props: Partial<MechanicalSensorProps>): Promise<MechanicalSensorProps> {
	console.info("loadMechanicalSensorData");
	const mechanicalSensor = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as MechanicalSensorPropsConfig;
		const value = getLastEventValue(propsConfig.data.source);
		if (value) {
			mechanicalSensor.status = getHealthIndexStatus(value);
			mechanicalSensor.lastUpdate = value.date;
			mechanicalSensor.lastUpdateType = LastUpdateType.Event;
		}
		mechanicalSensor.fetching = false;
	} catch (e) {
		console.error("loadMechanicalSensorData", e);
		mechanicalSensor.exception = e;
	}
	return mechanicalSensor as MechanicalSensorProps;
}

export interface MechanicalSensorPropsConfig {
	data: {
		source: NamedSource,
	},
	title: {
		i18n: string,
	},
	info: {
		i18n: string,
	},
	subPageDisabled: {
		value: boolean,
	},
	linkUrl?: {
		value: string,
	}
}
