import React from "react";
import { SensorModel } from "../services/sensor";
import * as ABB from "@abb/abb-common-ux-react";
import { useTranslation } from "react-i18next";
import { LoadingOverlay } from "../components";
import { EnvironmentalConfigDialog } from "./EnvironmentalConfigDialog";
import { SmartArmConfigDialog } from "./SmartArmConfigDialog";
import { CurrentConfigDialog } from "./CurrentConfigDialog";

export type ConfigurationModalDefaultConfig =
	| { sensorID: string; channel: number }
	| { ipAddress: string | null }
	| { transformationRatio: string | null };

export interface ConfigurationModalProps {
	model: SensorModel;
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (configurationData: Record<string, unknown>) => void;
	configuring: boolean;
	defaultConfig?: ConfigurationModalDefaultConfig | null;
	title: string;
}

export interface ConfigurationModalSelectorProps
	extends Omit<ConfigurationModalProps, "model"> {
	model: SensorModel | string;
}

const sensorModelToDialog: {
	[key in SensorModel | string]?: (
		props: ConfigurationModalProps
	) => React.ReactElement;
} = {
	[SensorModel.modbus_ss_environmental]: EnvironmentalConfigDialog,
	[SensorModel.smartarm]: SmartArmConfigDialog,
	[SensorModel.modbus_ss_current]: CurrentConfigDialog,
};

export function ConfigurationModalSelector(
	props: ConfigurationModalSelectorProps
): React.ReactElement {
	const { t } = useTranslation();
	const Dialog = sensorModelToDialog[props.model];
	if (!Dialog) {
		return (
			<ABB.Dialog
				isOpen={props.isOpen}
				showCloseButton={false}
				closeOnEscape={true}
				closeOnLostFocus={false}
				dimBackground={true}
				title={t("ConfigureDialogTitle")}
				standardButtonsOnBottom={[
					{
						text: t("Cancel"),
						type: "discreet-blue",
						handler: () => props.onClose(),
					},
				]}
			>
				<div className="text-center">{t("ConfigureDialogNotAvailable")}</div>
			</ABB.Dialog>
		);
	}
	const childProps = props as unknown as ConfigurationModalProps;
	return (
		<>
			<LoadingOverlay
				visible={props.configuring}
				label={t("SendingConfiguration")}
			/>
			<Dialog {...childProps} />
		</>
	);
}
