import { DocumentType, FileType } from "../../enums";

export class Document {
  id : string;
  file_name ?: string;
  title?: string;
  description?: string;
  size?: string;
  creation_date_us: number;
  type: DocumentType;
  mime_type?: FileType;
  url: string;
  constructor(type: DocumentType, id : string, fileName:string, title: string, date: number, url: string, description?: string, size?: string, fileType: FileType = FileType.NA) {
    this.id = id;
    this.file_name = fileName;
    this.title = title;
    this.description = description;
    this.size = size;
    this.creation_date_us = date;
    this.type = type;
    this.mime_type = fileType;
    this.url = url;
  }
 

  public getIcon(): string {
    let res = "abb/error-circle-1";
    switch (this.mime_type) {
      case FileType.Image:
        res = "abb/image";
        break;
      case FileType.Pdf:
        res = "abb/document-pdf";
        break;
      case FileType.Excel:
        res = "abb/document-excel";
        break;
      case FileType.Word:
        res = "abb/error-circle-1";
        break;
    }
    return res;
  }

  public getIconColor(): string {
    let res = "gray";
    switch (this.mime_type) {
      case FileType.Image:
        res = "#49C9A7";
        break;
      case FileType.Pdf:
        res = "color-status-red";
        break;
      case FileType.Excel:
        res = "color-status-green";
        break;
      case FileType.Word:
        res = "color-status-blue";
        break;
    }
    return res;
  }
}
