import { ReactElement, useMemo } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { commissioningWizardIsShown$, isCommissioningPhase$, isStartupPhase$, startupWizardIsShown$ } from '../../App/layout-state';
import { useReadable } from '../../observables/hooks';
import { CommissioningWizard, StartupWizard } from '../../wizards';
import { useTranslation } from 'react-i18next';
import { values$ } from '../../services/polling';
import { getCommissioningData, getStartupData } from '../../services/startup-and-commissioning';
import './style.scss';
import { user$ } from '../../services/user';
import { Role } from '../../enums';

export function StartupAndCommissioningPage(): ReactElement {
	const { t } = useTranslation();
	const isStartupPhase = useReadable(isStartupPhase$);
	const userRole = useReadable(user$)?.role ?? Role.None;
	const isCommissioningPhase = useReadable(isCommissioningPhase$);
	const values = useReadable(values$);
	const isCommissioningDone = useMemo(() => {
		if (values.values?.nameplate?.commissioningDone !== undefined) {
			const commissioningDone = typeof values.values.nameplate.commissioningDone === 'boolean' 
				? values.values.nameplate.commissioningDone 
				: Boolean(Number(values.values.nameplate.commissioningDone));
			return commissioningDone;
		}
		return true;
	}, [values]);
	const imgUrl = './images/image-status/af/base.png'; // TODO: valutare come parametrizzarlo
	const startupData = useMemo(() => {
		return getStartupData(values.values);
	}, [values.values]);
	const commissioningData = useMemo(() => {
		return getCommissioningData(values.values);
	}, [values.values]);
	return <>
		<div className='mt-4 d-flex align-items-center flex-wrap justify-content-center justify-content-md-start'>
			<ABB.Heading
				showCursor={false}
				text={t("WelcomeABBRole", { role: (userRole === Role.Admin || userRole === Role.ABB) ? 'admin' : 'user' })}
				level={3}
				className="font-weight-bold mr-4 mb-0"
			/>
			{isStartupPhase && <ABB.Button
				type='primary-blue'
				sizeClass='small'
				icon='abb/connect'
				className='mr-4'
				text={t("StartStartupWizard")}
				onClick={() => startupWizardIsShown$.next(true)}
			/>}
			{isCommissioningPhase && <ABB.Button
				type='primary-blue'
				sizeClass='small'
				className='mr-4'
				icon='abb/certificate-document-1'
				text={t("StartCommissioningWizard")}
				onClick={() => commissioningWizardIsShown$.next(true)}
			/>}
			{
				<ABB.Button 
					type='normal'
					sizeClass='small'
					className='mr-4'
					text={t("BackToDashboard")}
					disabled={isCommissioningPhase && !isCommissioningDone}
					onClick={() => {
						if (isStartupPhase) {
							isStartupPhase$.next(false);
						} else {
							isCommissioningPhase$.next(false);
						}
					}}
				/>
			}
			<span className='text-muted mt-2 mt-md-0'>
				<ABB.Icon name='abb/information-circle-2' className='mr-2 align-middle' />
				{t("ChangesApplyOnlyToConnectedDevice")}
			</span>
		</div>
		<div className='mt-4 d-flex flex-wrap flex-md-nowrap justify-content-center'>
			<div className='startup-and-commissioning-image-card mb-2'>
				<img src={imgUrl} style={{ width: "100%", maxWidth: "300px" }} alt={imgUrl} />
			</div>
			<div className='startup-and-commissioning-device-card justify-content-between'>
				{isStartupPhase &&
					<>
						<div>
							<ABB.Heading
								text={startupData.deviceType}
								level={2}
								className="font-weight-bold"
							/>
							<div className='startup-and-commissioning-list-item startup-and-commissioning-list-item-bold'>
								<span>{t('ModelAndRatings')}</span>
								<span style={{ fontSize: '1.2rem' }}>{startupData.modelAndRatings}</span>
							</div>
						</div>
						<div className='text-muted'>
							{t("InformationUpdatedAfterStartup")}
						</div>
					</>
				}
				{
					isCommissioningPhase &&
					<div>
						<ABB.Heading
							text={commissioningData.deviceType}
							level={2}
							className="font-weight-bold"
						/>
						<div className='row'>
							<div className='startup-and-commissioning-list-item startup-and-commissioning-list-item-bold col-sm-6 col-12'>
								<span>{t('AssetLabel')}</span>
								<span>{commissioningData.assetLabel}</span>
							</div>
							<div className='startup-and-commissioning-list-item startup-and-commissioning-list-item-bold col-sm-6 col-12'>
								<span>{t('ModelAndRatings')}</span>
								<span>{commissioningData.modelAndRatings}</span>
							</div>
							<div className='startup-and-commissioning-list-item startup-and-commissioning-list-item-bold col-sm-6 col-12'>
								<span>{t('ManufacturingDate')}</span>
								<span>{commissioningData.manufacturingDate.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' })}</span>
							</div>
							<div className='startup-and-commissioning-list-item startup-and-commissioning-list-item-bold col-sm-6 col-12'>
								<span>{t('InstallationDate')}</span>
								<span>{commissioningData.installationDate.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' })}</span>
							</div>
							<div className='startup-and-commissioning-list-item startup-and-commissioning-list-item-bold col-sm-6 col-12'>
								<span>{t('SerialNumber')}</span>
								<span>{commissioningData.serialNumber}</span>
							</div>
							<div className='startup-and-commissioning-list-item startup-and-commissioning-list-item-bold col-sm-6 col-12'>
								<span>{t('ContractType')}</span>
								<span>{commissioningData.contractType}</span>
							</div>
						</div>
						<hr />

						<div className='row mt-3'>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("Mass")}</span>
								<span>{commissioningData.mass}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("ShortCircuitBreakingCurrent")}</span>
								<span>{commissioningData.shortCircuitBreakingCurrent}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("Voltage")}</span>
								<span>{commissioningData.voltage}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("DCComponentOfRatedShortCircuit")}</span>
								<span>{commissioningData.DCComponentOfTheRatedShortCircuitBC}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("LightningImpulseWithstandVoltage")}</span>
								<span>{commissioningData.lightningImpulseWithstandVoltage}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("CableChargingBreakingCurrent")}</span>
								<span>{commissioningData.cableChargingBreakingCurrent}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("PowerFrequencyWithstandVoltage")}</span>
								<span>{commissioningData.powerFrequencyWithstandVoltage}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("OperatingSequence")}</span>
								<span>{commissioningData.operatingSequence}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("Frequency")}</span>
								<span>{commissioningData.frequency}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("TemperatureClass")}</span>
								<span>{commissioningData.temperatureClass}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("NormalCurrent")}</span>
								<span>{commissioningData.normalCurrent}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("ShuntReleaseOnVoltage")}</span>
								<span>{commissioningData.shuntReleaseOnVoltage}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("ShortTimeWithstandCurrent")}</span>
								<span>{commissioningData.shortTimeWithstandCurrent}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("ShuntReleaseOffVoltage")}</span>
								<span>{commissioningData.shuntReleaseOffVoltage}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("DurationOfShortCircuit")}</span>
								<span>{commissioningData.durationOfShortCircuit}</span>
							</div>
							<div className='startup-and-commissioning-list-item col-sm-6 col-12'>
								<span>{t("ChargingMotorVoltage")}</span>
								<span>{commissioningData.chargingMotorVoltage}</span>
							</div>
						</div>

					</div>
				}
			</div>
		</div>
		<StartupWizard />
		<CommissioningWizard />
	</>
}
