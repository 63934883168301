export class FetchError extends Error {
    response: Response;
    status: number;

    constructor(response: Response, msg?: string) {
        super(msg || `fetch returned http status [${response.status}] ${response.statusText}`);
        this.response = response;
        this.status = response.status;
    }
}

export function fetchOrThrow(url: string, options?: Record<string, any>): Promise<Response> {
    return fetch(url, options)
        .then(response => response.ok ? Promise.resolve(response) : Promise.reject(new FetchError(response)));
}
