import React, { ReactElement } from 'react'
import { Link, matchPath, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useReadable } from '../../observables/hooks';
import { appConfig$ } from '../../services/config';


interface BreadcrumbsProps {
    mobile: boolean;
}

export const Breadcrumbs = (props: BreadcrumbsProps): ReactElement => {

    let { t } = useTranslation();
    const location = useLocation();
    const path = location.pathname.split('/');
		const appConfig = useReadable(appConfig$);

		if (!appConfig) {
			return (
				<>
					<p>-</p>
				</>
			);
		}

    let display = props.mobile? "d-flex d-lg-none" : "d-none d-lg-flex";
		const breadcrumbs = [];

		const appPaths = Object.keys(appConfig.pages)
			.sort((a, b) => b.length - a.length);
		while (path.length > 0) {
			const currentPath = path.join('/');
			const appPath = appPaths.find((parametricPath) => matchPath(currentPath, parametricPath) !== null);
			if (appPath) {
				const title = appConfig.pages[appPath]!.title;
				breadcrumbs.unshift({ title, path: currentPath });
			}
			path.splice(path.length - 1, 1);
		}

    return (
        <>
					<div className={`breadcrumbs-wrapper ${display}`}>
							{breadcrumbs.length > 0 && breadcrumbs.map((c, i) =>
									<span key={"crumb" + i} className="font-weight-bold">
											<Link to={c.path}>
													{t(c.title.i18n)}
											</Link>
											{i < breadcrumbs.length - 1 && <div className="circle" />}
									</span>
							)}
					</div>
            
        </>);
}
