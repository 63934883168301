import React, { ReactElement } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';

export type StepStatus = 'to-do'|'done'|'doing'|'error'|'skipped';

export function StepStatusIcon({ status }: { status: StepStatus }): ReactElement {
	let component;
	let text;
	const { t } = useTranslation();
	if (status === 'done') {
		component = <ABB.Icon name='abb/check-mark-circle-1' color='color-status-green' />;
		text = t('Done');
	}
	if (status === 'skipped') {
		component = <ABB.Icon name='abb/forward' color='grey' />;
		text = t('Skipped');
	}
	if (status === 'doing') {
		component = <ABB.LoadingIndicator style={{ display: 'inline-block', transform: 'scale(0.5)', transformOrigin: 'bottom right', marginTop: '-16px'}} determinate={false} color="grey" type='radial' sizeClass='small' />;
		text = t('UploadingData');
	}
	if (status === 'error') {
		component = <ABB.Icon name='abb/warning-triangle' color='color-status-red' />;
		text = t('GenericError');
	}
	if (component) {
		return <ABB.WithTooltip>
			{component}
			<ABB.Tooltip followCursor>{text}</ABB.Tooltip>
		</ABB.WithTooltip>;
	}
	return <></>
}
