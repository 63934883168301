import { getHealthIndexStatus, getLastEventValue, getMultipleSamples } from '.';
import { DateTimeFormatHelper } from '../../helpers';
import { NamedSource, PathSource, WidgetConfig } from '../../pages/DynamicPage';
import { angleInRange } from '../../utils/angle';
import { getGreaterDate } from '../../utils/date';
import { nor, not } from '../../utils/logic';
import { MotorUnitProps } from '../../widgets';

export async function loadMotorUnit(config: WidgetConfig, props: Partial<MotorUnitProps>): Promise<MotorUnitProps> {
	const motorUnit = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as MotorUnitPropsConfig;
		const [
			encoderLineDiag, 
			motorIndexFail,
			angleErrorFail,
			driverDiagnosticFail,
			absEncoderNotGood,
			redundantServoFail,
		] = await getMultipleSamples('boolean', [
			propsConfig.encoderLineDiag.source,
			propsConfig.motorIndexFail.source,
			propsConfig.angleErrorFail.source,
			propsConfig.driverDiagnosticFail.source,
			propsConfig.absEncoderNotGood.source,
			propsConfig.redundantServoFail.source,
		]).then((res) => {
			res.forEach((sample) => {
				motorUnit.lastUpdate = getGreaterDate(motorUnit.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined);
			})
			return res;
		});
		const [
			ipmTempFilt,
			motorAngle,
		] = await getMultipleSamples('number', 
			[propsConfig.ipmTempFilt.source, propsConfig.motorAngle.source], 
			[
				{ min: motorUnit.minTemperature, max: motorUnit.maxTemperature },
				{ min: (motorUnit.minAngle ?? 0) / 180 * Math.PI, max: (motorUnit.maxAngle ?? 360) / 180 * Math.PI, step: 0.0001 }
			]).then((res) => {
				res.forEach((sample) => {
					motorUnit.lastUpdate = getGreaterDate(motorUnit.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined);
				});
				return res;
			});
		try {
			const angleStatus = getLastEventValue(propsConfig.angleStatus.source);
			motorUnit.angleStatus = getHealthIndexStatus(angleStatus);
		} catch (err) {
			console.warn('loadMotorUnit', err);
			motorUnit.angleStatus = undefined;
		}
		motorUnit.incrementalEncoder = nor(encoderLineDiag!.value, motorIndexFail!.value);
		motorUnit.encodersDifference = not(angleErrorFail!.value) ?? undefined;
		motorUnit.angle = angleInRange(motorAngle!.value ?? undefined, motorUnit.minAngle!, motorUnit.maxAngle!);
		motorUnit.absoluteEncoder = nor(absEncoderNotGood!.value, redundantServoFail!.value);
		motorUnit.temperature = Number(ipmTempFilt!.value);
		motorUnit.motorWinding = not(driverDiagnosticFail!.value) ?? undefined;
	} catch (e) {
		console.error("loadMotorUnit", e);
		motorUnit.exception = e;
	} finally {
		motorUnit.fetching = false;
	}
	return motorUnit as MotorUnitProps;
}

export interface MotorUnitPropsConfig {
	title: {
		i18n: string,
	},
	highWarn: {
		source: PathSource
	},
	lowWarn: {
		source: PathSource
	},
	minAngle: {
		source: PathSource,
	},
	maxAngle: {
		source: PathSource
	},
	link: {
		value: string,
	},
	linkName: {
		i18n: string,
	},
	encoderLineDiag: {
		source: NamedSource,
	},
	motorIndexFail: {
		source: NamedSource
	},
	angleErrorFail: {
		source: NamedSource
	},
	driverDiagnosticFail: {
		source: NamedSource
	},
	absEncoderNotGood: {
		source: NamedSource
	},
	redundantServoFail: {
		source: NamedSource
	},
	ipmTempFilt: {
		source: NamedSource
	},
	motorAngle: {
		source: NamedSource
	},
	tempHighWarning: {
		source: PathSource,
	},
	tempHighAlarm: {
		source: PathSource,
	},
	tempMin: {
		source: PathSource,
	},
	tempMax: {
		source: PathSource,
	},
	angleStatus: {
		source: NamedSource,
	}
}
