import "./App.scss";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { HealthIndexStatus } from "../enums";
import { useReadable } from "../observables/hooks";
import { alertBellToggled$ } from "./layout-state";
import * as ABB from "@abb/abb-common-ux-react";
import { notifications$ } from "../services/polling";
import { NotificationItem } from "../widgets";

export interface AppNotificationsProps {
	states?: HealthIndexStatus[];
	filter?: string;
	setPopup: (popupData: PopupData) => void;
}

export interface PopupData {
	title: string;
	description: string[];
	subtitle: string;
}

export function AppNotifications({
	states,
	filter,
	setPopup,
}: AppNotificationsProps) {
	const globalNotifications = useReadable(notifications$);
	return (
		<>
			{globalNotifications.all
				.filter((value) => {
					if (!states) {
						return true;
					}
					if (states.length > 0) {
						return value.status === states[0];
					} else {
						return (
							value.status === HealthIndexStatus.Alarm ||
							value.status === HealthIndexStatus.Warning
						);
					}
				})
				.filter((value) => {
					if (filter) {
						return value.title.toLowerCase().includes(filter.toLowerCase());
					}
					return true;
				})
				.map((not) => {
					return (
						<NotificationItem
							key={"ntff" + not.id}
							status={not.status}
							title={not.title}
							message={not.text}
							date={not.date}
							setPopup={
								not.status !== HealthIndexStatus.Ok ? setPopup : undefined
							}
							popupText={not.popupText}
						/>
					);
				})}
		</>
	);
}

export function AppNotificationsTabs() {
	const { t } = useTranslation();
	const alertBellToggled = useReadable(alertBellToggled$);
	const [activeTab, setActiveTab] = useState(0);
	const [showDialog, setshowDialog] = useState<boolean>(false);
	const [popupData, setpopupData] = useState<PopupData>({
		title: "",
		description: [],
		subtitle: "",
	});
	const [eventNameFilter, setEventNameFilter] = useState("");
	const [alertPanelFilter1, setAlertPanelFilter1] = useState<
		{
			value: any;
			label: string;
			isNew: boolean;
		}[]
	>([]);

	const handleValueChange = useCallback((newValue) => {
		setEventNameFilter(newValue);
	}, []);

	const handleSetPopup = useCallback((data: PopupData) => {
		setshowDialog(true);
		setpopupData(data);
	}, []);

	const handleClosePopup = useCallback(() => {
		setshowDialog(false);
		setpopupData({ title: "", description: [], subtitle: "" });
	}, []);

	return (
		<>
			<ABB.TabControl
				type="primary"
				className={
					"alertPanel tabHeaderAnimation aling-items-stretch align-items-md-end align-items-lg-stretch " +
					(alertBellToggled ? "d-flex" : "d-none")
				}
				activeTab={activeTab}
				onTabChange={(_oldIndex, newIndex) => setActiveTab(newIndex)}
			>
				<ABB.TabItem title={t("AlarmsAndWarnings")}>
					<ABB.Dropdown
						clearable={true}
						placeholder={t("Select filters")}
						value={alertPanelFilter1}
						onChange={(val) => setAlertPanelFilter1(val)}
					>
						<ABB.DropdownOption
							label={t("Alarm")}
							value={HealthIndexStatus.Alarm}
						/>
						<ABB.DropdownOption
							label={t("Warning")}
							value={HealthIndexStatus.Warning}
						/>
					</ABB.Dropdown>

					<AppNotifications
						states={alertPanelFilter1.map(({ value }) => value)}
						setPopup={handleSetPopup}
					/>
				</ABB.TabItem>
				<ABB.TabItem title={t("All")}>
					<ABB.Input
						dataType="search"
						placeholder={t("Event name")}
						value={eventNameFilter}
						onValueChange={(props) => handleValueChange(props)}
					/>
					<AppNotifications
						filter={eventNameFilter.toLowerCase()}
						setPopup={handleSetPopup}
					/>
				</ABB.TabItem>
			</ABB.TabControl>

			<ABB.Dialog
				title={popupData.title}
				isOpen={showDialog}
				dimBackground={true}
				onClose={handleClosePopup}
				showCloseButton={true}
			>
				<p>{popupData.subtitle}</p>
				{popupData.description.length > 0 && <p>{t("Recommended actions")}:</p>}

				<ol>
					{popupData.description.map((line, index) => (
						<li key={index} dangerouslySetInnerHTML={{ __html: line }}></li>
					))}
				</ol>
			</ABB.Dialog>
		</>
	);
}
