import * as Models from "../../models";
import * as Enums from "../../enums";
import * as Helpers from "../../helpers";
import { logout, removeCachedUser } from '../access';
import { AuthenticatedUser, SaveUser, token$, User, user$ } from '../user';
import { FetchError, fetchOrThrow } from '../../utils/fetch';
import { BoardNetworkConfig, EthernetNetworkSettings, SaveEthernetNetworkSettings } from '../../models/api/BoardNetworkConfig';
import { BoardFirmwareInfo } from '../../models/api/BoardFirmwareInfo';
import { HealthIndexStatus, Role } from '../../enums';
import { changePasswordDialogIsShown$ } from '../../App/layout-state';
import { SaveThermalConfiguration, ThermalConfiguration } from '../../models/api/ThermalConfiguration';
import { ProductInfoNameplate } from '../../wizards/StartupWizard/ProductInfo';
import { isDemo, Scenario, ScenariosResponse, selectedScenario$ } from '../demo';

let baseUrl = process.env.REACT_APP_API_URL;

function prepareHttpHeaders(): Record<string, any> {
	if (token$.getValue()) {
		return {
			'Authorization': `Bearer ${token$.getValue()}`,
			'Role': user$.getValue()?.role,
			...(isDemo && selectedScenario$.getValue() !== null ? { scenario: selectedScenario$.getValue() } : {}),
		};
	} else {
		return {
			...(isDemo && selectedScenario$.getValue() !== null ? { scenario: selectedScenario$.getValue() } : {}),
		};
	}
}

async function httpRequest(
	method: string,
	url: string,
	headers?: Record<string, any>,
	middleware?: (fetchConfig: Record<string, any>) => void
): Promise<Response> {
	const fetchConfig: Record<string, any> = { method, headers: prepareHttpHeaders() };
	if (middleware) {
		middleware(fetchConfig);
	}
	// enable headers overwrite
	fetchConfig.headers = { ...fetchConfig.headers, ...(headers || {}) };
	try {
		const response = await fetchOrThrow(url, fetchConfig);
		return response;
	} catch (err) {
		if (token$.getValue() && err instanceof FetchError && err.status === 401) {
			logout();
		}
		throw err;
	}
}

async function httpGet(url: string, headers?: Record<string, any>): Promise<Response> {
	return httpRequest('GET', url, headers);
}

async function httpPost(url: string, body?: any, headers?: Record<string, any>): Promise<Response> {
	return httpRequest('POST', url, headers, (fetchConfig) => {
		if (body) {
			if (typeof body === 'object') {
				if (
					body instanceof Blob
					|| body instanceof FormData
					|| body instanceof URLSearchParams
					|| body instanceof ReadableStream
				) {
					fetchConfig.body = body;
				} else {
					fetchConfig.body = JSON.stringify(body);
					fetchConfig.headers['Content-Type'] = 'application/json';
					fetchConfig.headers['Accept'] = 'application/json';
				}
			} else {
				fetchConfig.body = body;
			}
		}
	});
}

async function httpDelete(url: string, headers?: Record<string, any>): Promise<Response> {
	return httpRequest('DELETE', url, headers);
}

async function getAsync(url: string, ignoreResponse: true): Promise<void>;
async function getAsync<T>(url: string, ignoreResponse?: false): Promise<T>
async function getAsync<T>(url: string, ignoreResponse?: boolean): Promise<T|void> {

	try {
		let resp = await httpGet(url);
		if (!ignoreResponse) {
			let data: T = await resp.json();
			return data;
		}
		return undefined;
	}
	catch (e) {
		console.error("getAsync", url, e);
		throw e;
	}
}

async function postAsync(url: string, json: any, ignoreResponse: true): Promise<void>;
async function postAsync<T>(url: string, json: any, ignoreResponse?: false | undefined): Promise<T>;

async function postAsync<T>(url: string, json: any, ignoreResponse?: boolean): Promise<T | void> {

	let postContent: string = "";

	try {
		let resp = await httpPost(url, json);
		if (!ignoreResponse) {
			let data: T = await resp.json();
			return data;
		} else {
			return undefined;
		}
	}
	catch (e) {
		console.error("postAsync", url, postContent, e);
		throw e;
	}
}

function toEpoch_us(date: Date): number {
	return date.getTime() * 1000;
}

function getBaseUrl(): string {

	if (!baseUrl) {
		baseUrl = "./api/v1";
	}
	return baseUrl;
}

export function getDevices(): Promise<Models.DevicesResponse> {
	return getAsync<Models.DevicesResponse>(getBaseUrl() + "/devices");
}

export function getDevicesMetadata(): Promise<Models.DevicesMetadataResponse> {
	return getAsync<Models.DevicesMetadataResponse>(getBaseUrl() + "/devices/metadata");
}

// dateTo - start
// discendente (più recenti prima)
export function getSample(deviceModel: string, deviceId: string, sample: string, size?: number, fromId?: number, dateFrom?: Date, dateTo?: Date): Promise<Models.SampleResponse> {

	let params = new URLSearchParams();
	if (size)
		params.append("size", size.toString());

	if (fromId)
		params.append("fromID", fromId.toString());

	if (dateFrom)
		params.append("dateFrom", toEpoch_us(dateFrom).toString());

	if (dateTo)
		params.append("dateTo", toEpoch_us(dateTo).toString());

	let url = `${getBaseUrl()}/device/${deviceModel}/${deviceId}/samples/${sample}?${params}`;

	return getAsync<Models.SampleResponse>(url);
}

export function getBooleanSample(deviceModel: string, deviceId: string, sample: string, size?: number, fromId?: number, dateFrom?: Date, dateTo?: Date): Promise<Models.GenericSampleResponse<boolean | undefined>> {
	return getSample(deviceModel, deviceId, sample, size, fromId, dateFrom, dateTo)
		.then((res) => ({
			...res,
			samples: res.samples.map((s) => {
				let value = s.value;
				if (typeof value === 'string') {
					value = parseInt(value);
				}
				return { ...s, value: s.value != null ? Boolean(value) : undefined };
			}),
		}));
}

export function getNumberSample(deviceModel: string, deviceId: string, sample: string, size?: number, fromId?: number, dateFrom?: Date, dateTo?: Date): Promise<Models.GenericSampleResponse<number | undefined>> {
	return getSample(deviceModel, deviceId, sample, size, fromId, dateFrom, dateTo)
		.then((res) => ({
			...res,
			samples: res.samples.map((s) => ({ ...s, value: s.value != null ? Number(s.value) : undefined })),
		}));
}

export function getHealthIndexSample(deviceModel: string, deviceId: string, sample: string, size?: number, fromId?: number, dateFrom?: Date, dateTo?: Date): Promise<Models.GenericSampleResponse<HealthIndexStatus | undefined>> {
	return getSample(deviceModel, deviceId, sample, size, fromId, dateFrom, dateTo)
		.then((res) => ({
			...res,
			samples: res.samples.map((s) => ({ ...s, value: s.value != null && Object.values(HealthIndexStatus).includes(s.value as any) ? s.value as HealthIndexStatus : undefined })),
		}));
}

export async function getLastWaveform(deviceModel: string, deviceId: string, name: string, type: Models.WaveformOperation, dateFrom?: number, groupId?: number): Promise<Models.WaveformDataItem | undefined> {
	const url = `${getBaseUrl()}/device/${deviceModel}/${deviceId}/waveforms/${name}`;

	const params = new URLSearchParams();

	params.append('size', '1');
	if (type !== Models.WaveformOperation.last) {
		params.append('operation', type);
	}
	if (dateFrom) {
		params.append('dateFrom', dateFrom.toString());
	}
	if (groupId !== undefined) {
		params.append('groupID', groupId.toString());
	}

	const res = await getAsync<Models.WaveformsResponse>(`${url}?${params}`);

	if (res && res.waveforms && res.waveforms.length > 0) {
		return res.waveforms[0];
	}

	return undefined;
}

export async function getWaveforms(deviceModel: string, deviceId: string, name: string, type: Models.WaveformOperation, listOnly: true, size?: number, dateFrom?: number, dateTo?: number, groupId?: number): Promise<Models.WaveformDataListOnlyItem[]>;
export async function getWaveforms(deviceModel: string, deviceId: string, name: string, type: Models.WaveformOperation, listOnly?: false, size?: number, dateFrom?: number, dateTo?: number, groupId?: number): Promise<Models.WaveformDataItem[]>;
export async function getWaveforms(deviceModel: string, deviceId: string, name: string, type: Models.WaveformOperation, listOnly?: boolean, size?: number, dateFrom?: number, dateTo?: number, groupId?: number): Promise<Models.WaveformDataItem[] | Models.WaveformDataListOnlyItem[]> {
	const url = `${getBaseUrl()}/device/${deviceModel}/${deviceId}/waveforms/${name}`;

	const params = new URLSearchParams();

	if (type !== Models.WaveformOperation.last) {
		params.append('operation', type);
	}
	if (size) {
		params.append('size', size.toString());
	}
	if (dateFrom) {
		params.append('dateFrom', dateFrom.toString());
	}
	if (dateTo) {
		params.append('dateTo', dateTo.toString());
	}
	if (groupId !== undefined) {
		params.append('groupID', groupId.toString());
	}
	if (listOnly !== undefined) {
		params.append('listOnly', String(listOnly));
	}

	const res = await getAsync<Models.WaveformsResponse>(`${url}?${params}`);

	return res.waveforms;
}


export function getEventsList(): Promise<Models.EventsListResponse> {
	let url = getBaseUrl() + "/events/list";
	return getAsync<Models.EventsListResponse>(url);
}

export async function getEventsHistory(ids: number[], dateFrom?: Date): Promise<Models.EventsHistoryResponse> {
	let url = getBaseUrl() + "/events/history";

	if (ids.length > 0) {
		url = url + "?id=" + ids.join("&id=");
	} else if (dateFrom) {
		url += `?dateFrom=${toEpoch_us(dateFrom).toString()}`;
	}

	let data = await getAsync<Models.EventsHistoryResponse>(url);
	return data;
}


export async function getValues(): Promise<Models.ValuesResponse> {

	let url = `${getBaseUrl()}/constants`;

	let data = await getAsync<any>(url);

	let ret: Models.ValuesResponse = {
		values: data
	};
	return ret;
}

export function getEvent(deviceModel: string, deviceId: string, event: string, size?: number, start?: number, dateFrom?: Date, dateTo?: Date): Promise<Models.EventsListResponse> {
	let params = new URLSearchParams();
	if (size)
		params.append("size", size.toString());

	if (start)
		params.append("start", start.toString());

	if (dateFrom)
		params.append("dateFrom", toEpoch_us(dateFrom).toString());

	if (dateTo)
		params.append("dateTo", toEpoch_us(dateTo).toString());

	let url = `${getBaseUrl()}/device/${deviceModel}/${deviceId}/events/${event}?${params}`;

	return getAsync<Models.EventsListResponse>(url);
}
//alert
export function getDataAggregation(devices: Record<string, Record<string, Models.AggregationDevice>>, size?: number, dateFrom?: Date, dateTo?: Date): Promise<Models.AggregationResponse> {
	let url = getBaseUrl() + "/data/aggregation";
	return aggregationPost<Models.AggregationResponse>(url, devices, size, dateFrom, dateTo, undefined);
}
export function getMultipleBooleanSamples(devices: Record<string, Record<string, Models.AggregationDevice>>): Promise<Models.GenericAggregationResponse<boolean | null>> {
	let url = getBaseUrl() + "/data/aggregation";
	return postAsync(url, {
		aggregation: { devices, size: 1 },
	});
}
export function getMultipleNumericSamples(devices: Record<string, Record<string, Models.AggregationDevice>>): Promise<Models.GenericAggregationResponse<string | number | null>> {
	let url = getBaseUrl() + "/data/aggregation";
	return postAsync(url, {
		aggregation: { devices, size: 1 },
	});
}
export function getMultipleHealthIndexSamples(devices: Record<string, Record<string, Models.AggregationDevice>>): Promise<Models.GenericAggregationResponse<HealthIndexStatus | null>> {
	let url = getBaseUrl() + "/data/aggregation";
	return postAsync(url, {
		aggregation: { devices, size: 1 },
	});
}
//multiline chart
export function getDataAggregationIntervals(devices: Record<string, Record<string, Models.AggregationDevice>>, dateFrom: Date, dateTo: Date, numberOfSamples: number, _boundaries?: { min?: number, max?: number }): Promise<Models.AggregationIntervalsResponse> {
	let url = getBaseUrl() + "/data/aggregation/intervals";
	return aggregationPost<Models.AggregationIntervalsResponse>(url, devices, undefined, dateFrom, dateTo, numberOfSamples);
}

function aggregationPost<T>(url: string, devices: Record<string, Record<string, Models.AggregationDevice>>, size?: number, dateFrom?: Date, dateTo?: Date, numberOfSamples?: number): Promise<T> {

	let json: any = {
		aggregation: {
			devices: devices
		}
	};

	if (size)
		json.aggregation.size = size;

	if (dateFrom)
		json.aggregation.dateFromUs = toEpoch_us(dateFrom);

	if (dateTo)
		json.aggregation.dateToUs = toEpoch_us(dateTo) - 1;

	if (numberOfSamples) {
		let intervalUs: number = (json.aggregation.dateToUs - json.aggregation.dateFromUs) / numberOfSamples;
		json.aggregation.intervalUs = Math.round(intervalUs);
	}

	return postAsync<T>(url, json);
}

export async function getDocuments(): Promise<Models.Document[]> {
	let url = getBaseUrl() + "/documents";
	let emptyResult: Models.Document[] = [];
	try {
		let response = await getAsync<Models.DocumentResponse>(url);
		for (const iterator of Object.keys(response)) {
			let currentElement = response[iterator];
			let type: Enums.DocumentType = Enums.DocumentType.Uploaded;
			let fileType: Enums.FileType = Enums.FileType.NA;

			type = Helpers.DocumentHelper.FromApiDocumentTypeToDocumentTypeEnum(currentElement.type);

			fileType = Helpers.FileTypeHelper.FromMimeTypeToFileType(currentElement.mime_type);

			emptyResult.push((new Models.Document(type, currentElement.id, currentElement.file_name!, currentElement.title!, currentElement.creation_date_us, (getBaseUrl() + "/document/" + currentElement.id) /*currentElement.url*/, currentElement.description, currentElement.size ? currentElement.size.toString() : "", fileType)))
		}

		return emptyResult;
	}
	catch
	{
		console.log("error during get documents");
		return Promise.resolve(emptyResult);
	}
}




export async function downloadDocument(id: string): Promise<Blob> {

	if (id) {
		let url = getBaseUrl() + "/document/" + id;
		/* let response = await getAsync<File>(url);
		 return response;*/
		try {
			let response = await httpGet(url);
			let res = await response.blob();
			if (res) {
				return res;
			}
			else {
				throw new Error("empty blob response");
			}
		} catch (err) {
			if (err instanceof FetchError && err.status === 400) {
				throw new Error("Invalid parameters");
			} else {
				throw err;
			}
		}
	}
	else {
		throw new Error("Invalid parameters");
	}
}
//TO DO:
export async function deleteDocument(doc_type: Enums.DocumentType, id: string): Promise<void> {
	let documentTypeUrl = Helpers.DocumentHelper.FromDocumentTypeEnumToApiDocumentType(doc_type);

	let url = getBaseUrl() + "/document/" + documentTypeUrl + "/" + id;

	try {
		await httpDelete(url);
	} catch (err) {
		if (err instanceof FetchError && err.status === 400) {
			throw new Error("Invalid parameters");
		} else {
			throw err;
		}
	}
}





//TO DO: possibile mettere i parametri in query string
export async function uploadDocument(file_to_upload: File, doc_type: Enums.DocumentType, file_name_and_extension: string, doc_title?: string, doc_description?: string): Promise<Models.Document> {
	//TODO when apis are updated 
	let documentTypeUrl = Helpers.DocumentHelper.FromDocumentTypeEnumToApiDocumentType(doc_type);
	const params = new URLSearchParams();
	params.set('file_name', file_name_and_extension);
	if (doc_title) {
		params.set('title', doc_title.trim());
	}
	if (doc_description) {
		params.set('description', doc_description?.trim());
	}

	let url = getBaseUrl() + "/document/" + documentTypeUrl + "?" + params.toString();
	try {
		let response = await httpPost(url, file_to_upload);
		return response.json();
	} catch (err) {
		if (err instanceof FetchError && err.status === 400) {
			throw new Error("Invalid parameters");
		} else {
			throw err;
		}
	}
}

export async function updateDocumentData(id: string, type: Enums.DocumentType, title: string, description?: string): Promise<void> {
	const typeUrl = Helpers.DocumentHelper.FromDocumentTypeEnumToApiDocumentType(type);
	const params = new URLSearchParams();
	params.set('title', title);
	if (description) {
		params.set('description', description);
	}
	const url = `${getBaseUrl()}/document/${typeUrl}/${id}?${params.toString()}`;
	try {
		await httpPost(url);
	} catch (err) {
		if (err instanceof FetchError && err.status === 400) {
			throw new Error("Invalid parameters");
		} else {
			throw err;
		}
	}
}


async function uploadPackage(url: string, file: File, label: string): Promise<void> {

	let rootPartitionResponse = await httpGet(getBaseUrl() + "/board/firmware").then(v => v.json());
	let rootPartition = rootPartitionResponse.currentRoot;

	try {
		await httpPost(url, file);
		let max = 120; // seconds
		const step = 3; // seconds

		while (true) {
			await Helpers.delay(step);

			try {
				let json = await httpGet(getBaseUrl() + "/board/firmware").then(v => v.json());
				if (json && json.currentRoot && json.currentRoot.length > 0) {
					if (json.currentRoot === rootPartition)
						throw new Error(label + " error. currentRoot is not changed.");
					else
						return;// ok, rootPartition is changed
				}
			} catch (e) {
				console.log(label + "- rebooting - polling... ");
			}

			max -= step;

			if (max <= 0)
				throw new Error(label + " timeout. Status unknown.");
		}
	} catch (err) {
		if (err instanceof FetchError && err.status === 400) {
			throw new Error("The provided package is invalid");
		} else {
			throw err;
		}
	}
}


export async function uploadFirmware(file: File): Promise<void> {
	try {
		await httpPost(getBaseUrl() + '/board/firmware', file);
	} catch (err) {
		if (err instanceof FetchError && err.status === 400) {
			throw new Error("The provided package is invalid");
		} else {
			throw err;
		}
	}
}


export async function saveNetworkConfig(networkConfig: BoardNetworkConfig): Promise<void> {
	const interfaces = Object.keys(networkConfig?.interfaces ?? {})
		.filter((key) =>
			('available' in ((networkConfig?.interfaces ?? {})[key] ?? {}))
			&& (networkConfig!.interfaces[key]! as EthernetNetworkSettings).available === true,
		);
	const data = {
		interfaces: interfaces.reduce((res, interf) => {
			const data = (networkConfig!.interfaces[interf]! as EthernetNetworkSettings);
			if (!data.dhcp) {
				res[interf] = {
					dhcp: false,
					address: data.address,
					netmask: data.netmask,
					gateway: data.gateway || undefined,
				};
			} else {
				res[interf] = { dhcp: true };
			}
			return res;
		}, {} as { [i: string]: SaveEthernetNetworkSettings }),
	}
	await postAsync(getBaseUrl() + '/board/network', data, true);
}

export async function setWirelessChannel(channel: string): Promise<void> {
	await httpPost(getBaseUrl() + '/device/board/0/configuration', {
		IEEE802154Channel: channel,
	});
}

export async function getBoardFirmwareInfo(): Promise<BoardFirmwareInfo> {
	return getAsync<Record<string, unknown>>(getBaseUrl() + '/board/firmware')
		.then((raw) => ({
			...raw,
			build: {
				...(raw.build as Record<string, string>),
				date: new Date((raw.build as Record<string, string>).date)
			}
		} as unknown as BoardFirmwareInfo));
}

export async function getBoardNetworkConfig(): Promise<BoardNetworkConfig> {
	return getAsync(getBaseUrl() + '/board/network');
}

export async function getDate(): Promise<Date> {

	let json = await httpGet(getBaseUrl() + "/board/date").then(v => v.json());
	let date = Helpers.DateTimeFormatHelper.toDate(json.dateUs);
	return date!;
}

export async function setDate(): Promise<void> {

	let obj = {
		dateUs: toEpoch_us(new Date())
	};

	await httpPost(getBaseUrl() + '/board/date', obj);
}



export async function setUserDataOld(file: File): Promise<void> {
	await uploadPackage(getBaseUrl() + '/board/userdata', file, 'UserData');
}


export async function setUserData(file_to_upload: File, dataType?: Enums.UserDataType): Promise<boolean> {

	let TypeDocumentSelector: string = "";

	if (dataType) {
		if (dataType === Enums.UserDataType.DeviceData || dataType === Enums.UserDataType.Config) {
			TypeDocumentSelector = "/" + dataType;
		}
		else {
			throw new Error("Invalid dataType Requested");
		}
	}

	let url = getBaseUrl() + "/board/userdata" + TypeDocumentSelector;
	try {
		await httpPost(url, file_to_upload);
		return true;
	} catch (err) {
		if (err instanceof FetchError && err.status === 400) {
			throw new Error("Invalid parameters");
		} else {
			throw err;
		}
	}
}

export function getUserDataUrl(...includes: Enums.UserDataType[]): string {

	let qs = "";

	if (includes && includes.length > 0) {
		qs = qs + "?include=" + includes.join("&include=");
	}

	return getBaseUrl() + "/board/userdata" + qs;
}


export async function getUserData(developerMode: boolean, ...includes: Enums.UserDataType[]): Promise<Blob> {

	const params = new URLSearchParams();

	if (includes && includes.length > 0) {
		for (const includeDataType of includes) {
			params.append('include', includeDataType);
		}
	}
	if (developerMode) {
		params.append('unencrypted', 'true');
	}

	let url = `${getBaseUrl()}/board/userdata?${params}`;

	try {
		let response = await httpGet(url);
		let res = await response.blob();
		if (res) {
			return res;
		}
		else {
			throw new Error("unknown error");
		}
	} catch (err) {
		if (err instanceof FetchError && err.status === 400) {
			throw new Error("Invalid parameters");
		} else {
			throw err;
		}
	}
}

export async function wipeUserData(type: Enums.UserDataType): Promise<void> {

	let url = getBaseUrl() + "/board/userdata/" + type;

	await httpPost(url);
	await waitForCompletion("Restart check...", "Wipe User Data timeout. Status unknown.");

}
async function waitForCompletion(polligLog: string, error: string) {

	let max = 120; // seconds
	const step = 3; // seconds

	while (true) {
		await Helpers.delay(step);

		try {
			let json = await httpGet(getBaseUrl() + "/board/firmware").then(v => v.json());
			if (json && json.currentRoot && json.currentRoot.length > 0) {
				return;
			}
		} catch (e) {
			console.log(polligLog);
		}

		max -= step;

		if (max <= 0)
			throw error;
	}
}

export async function runOperation(deviceModel: string, deviceId: string, opName: string): Promise<void> {
	await httpPost(`${getBaseUrl()}/device/${deviceModel}/${deviceId}/operation/${opName}`);
}

export async function configureSensor(deviceModel: string, deviceId: string, data: Record<string, any>): Promise<void> {
	await httpPost(`${getBaseUrl()}/device/${deviceModel}/${deviceId}/configuration`, data);
}

export async function login(username: string, password: string): Promise<Omit<AuthenticatedUser, 'user'>> {
	const { token, role, forceChangePassword } = await httpPost(getBaseUrl() + "/user/authentication", { user: username, password })
		.then(v => v.json());
	token$.next(token);
	return { token, role, forceChangePassword: typeof forceChangePassword !== 'boolean' ? Boolean(Number(forceChangePassword)) : forceChangePassword };
}

export function isMock() {
	return false;
}

export async function updateNameplate(data: Partial<{ serialNumber: string, orderNumber: string, assetLabel: string, commissioningDone: string|number|boolean }>): Promise<void> {
	await postAsync(getBaseUrl() + "/constants/nameplate", {
		nameplate: data
	}, true);
}

export async function createUser(data: SaveUser): Promise<void> {
	await postAsync(getBaseUrl() + "/user/create", data, true);
}

export async function deleteUser(user: string): Promise<void> {
	await postAsync(getBaseUrl() + "/user/delete", { user }, true);
}

export async function resetUserPassword(user: string): Promise<void> {
	await postAsync(getBaseUrl() + "/user/reset", { user }, true);
}

export async function getUserList(): Promise<User[]> {
	const response = await getAsync<{ users: {
		user: string,
		forceChangePassword: string,
		role: Role
	}[] }>(getBaseUrl() + "/user/list");
	return response.users.map((u) => ({ user: u.user, role: u.role, forceChangePassword: Boolean(Number(u.forceChangePassword)) }));
}

export async function changePassword(password: string): Promise<void> {
	const currentUserData = user$.getValue()!;
	await postAsync(getBaseUrl() + "/user/configuration", { password, user: currentUserData.user }, true);
	if (changePasswordDialogIsShown$.getValue()) {
		changePasswordDialogIsShown$.next(false);
		removeCachedUser();
	}
}

export async function getThermalConfiguration(): Promise<ThermalConfiguration> {
	return getAsync(`${getBaseUrl()}/thermal`);
}

export async function updateThermalConfiguration(thermal: SaveThermalConfiguration): Promise<void> {
	await postAsync(`${getBaseUrl()}/thermal`, { thermal }, true);
}

export async function resetThermalConfiguration(): Promise<void> {
	await getAsync(`${getBaseUrl()}/thermal/reset`, true);
}

export async function getSessionValidity(): Promise<string> {
	const { sessionValidityUs } = await getAsync<{ sessionValidityUs: string }>(`${getBaseUrl()}/session/validity`);
	return sessionValidityUs;
}

export async function refreshSession(): Promise<string> {
	const { sessionValidityUs } = await getAsync<{ sessionValidityUs: string }>(`${getBaseUrl()}/session/refresh`);
	return sessionValidityUs;
}

export async function updateABBValues(data: Partial<ProductInfoNameplate>): Promise<void> {
	const keyValues = Object.entries(data);
	for (const [key, value] of keyValues) {
		await postAsync(`${getBaseUrl()}/value/${key}?value=${value}`, undefined, true);
	}
}

export async function remoteLogout(): Promise<void> {
	if (token$.getValue() !== null) {
		await getAsync(`${getBaseUrl()}/user/logout`, true);
	}
}


export async function getScenarios(): Promise<Scenario[]> {
	if (!isDemo) {
		throw new Error('scenarios are only available in demo mode');
	}
	const { scenarios } = await getAsync<ScenariosResponse>(`${getBaseUrl()}/scenarios`);
	return scenarios;
}
