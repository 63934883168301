import uuidv4 from "uuid/v4";
import { BehaviorSubject } from "rxjs";
import * as Models from "../models";
import * as Enums from "../enums";
import * as Helpers from "../helpers";
import * as Widgets from "../widgets";
import { DataElement } from "./config";
import { WidgetConfig } from "../pages/DynamicPage";
import {
	SensorsOverviewItemPropsConfig,
	SensorsOverviewPropsConfig,
} from "./polling/sensors-overview";
import { AlertsItemPropsConfig, AlertsPropsConfig } from "./polling/alerts";
import {
	GaugeInfoPropsConfig,
	GaugeItemPropsConfig,
	GaugesPropsConfig,
} from "./polling/gauges";
import { FamilyStatusPropsConfig } from "./polling/family-status";
import { SectionStatusPropsConfig } from "./polling/section-status";
import {
	BarChartPropsConfig,
	BarChartWithChartTooltipPropsConfig,
} from "./polling/chart/bar";
import { WaveformChartPropsConfig } from "./polling/chart/waveform";
import { SingleLineChartPropsConfig } from "./polling/chart/single-line";
import { MultiLineChartPropsConfig } from "./polling/chart/multiline";
import { PhaseSensorPropsConfig } from "./polling/phase-sensor";
import { MechanicalSensorPropsConfig } from "./polling/mechanical-sensor";
import { ChartFilterPropsConfig } from "./polling/chart";
import {
	CapacitorStatusProps,
	CBStatusProps,
	CumulatedWorkloadProps,
	ActuationEventsProps,
	InputStatusProps,
	MotorUnitProps,
	SyncStatusProps,
	BinaryInputDiagnosticProps,
	EventLogsProps,
	StatusDetailsWithIconProps,
} from "../widgets";
import { CBStatusPropsConfig } from "./polling/cb-status";
import { InputStatusPropsConfig } from "./polling/input-status";
import { CumulatedWorkloadPropsConfig } from "./polling/cumulated-workload";
import { HealthIndexStatus } from "../enums";
import { HardwareIntegrityProps } from "../widgets/IOStatus/HardwareIntegrity";
import { HardwareIntegrityPropsConfig } from "./polling/hardware-integrity";
import { HealthIndexProgressPropsConfig } from "./polling/health-index-progress";
import { HealthIndexProgressProps } from "../widgets/HealthIndexProgress/HealthIndexProgress";
import { SyncStatusPropsConfig } from "./polling/sync-status";
import { MotorUnitPropsConfig } from "./polling/motor-unit";
import { CapacitorStatusPropsConfig } from "./polling/capacitor-status";
import { ActuationEventsPropsConfig } from "./polling/actuation-events";
import { ChartValueProperty, LastUpdateType, WidgetProps } from "../models";
import { BinaryInputDiagnosticPropsConfig } from "./polling/binary-input-diagnostic";
import { EventLogsPropsConfig } from "./polling/event-logs";
import {
	ConnectionSensorStatusPropsConfig,
	ImageStatusPropsConfig,
	ImageStatusWidgetWithOverlaysPropsConfig,
} from "./polling/sensor-status";
import { getColor } from "../helpers/css";
import { getUnit, getValue } from "./polling";
import { getWidgetDescriptorId } from "./polling/startup";
import { StatusDetailsWithIconPropsConfig } from "./polling/status-details-with-icon";
import { SystemInfoPropsConfig } from "./polling/system-info";
import { angleInRange, angleRangeWithNegativeConversion } from "../utils/angle";
import { areInstalled } from "./sensor";
export const phaseChartTimeInterval$ = new BehaviorSubject(
	Enums.ChartTimeRange.Hour
);

function getTranslation(key?: string) {
	return Helpers.LocalizationHelper.getTranslation(key);
}

function createSensorsOverviewItemProps(
	d: SensorsOverviewItemPropsConfig
): Widgets.SensorsOverviewItemProps {
	let soip: Widgets.SensorsOverviewItemProps = {
		group: d.group?.value,
		title: getTranslation(d.title.i18n),
		label: getTranslation(d.label.i18n),
		connected: true,
		loadStatus: Enums.Load.Low,
		value: null,
		unit: getUnit(d.tempSample.source),
	};
	return soip;
}

function createAlertsDataItemProps(
	d: AlertsItemPropsConfig
): Widgets.AlertsDataItemProps {
	let adip: Widgets.AlertsDataItemProps = {
		label: getTranslation(d.label.i18n),
		value: null,
		unit: getUnit(d.value.source),
		status: Enums.HealthIndexStatus.Ok,
	};
	return adip;
}

function createGaugeProps(
	configuration: GaugeItemPropsConfig | GaugeInfoPropsConfig
): Widgets.GaugeProps {
	const showAsPercentage = configuration.showAsPercentage?.value ?? false;
	const min: number =
		(configuration.min && getValue(configuration.min.source)) || 0;
	const max: number =
		(configuration.max && getValue(configuration.max.source)) || 100;
	const lowAlarm: number | undefined =
		(configuration.lowAlarm && getValue(configuration.lowAlarm.source)) ||
		undefined;
	const highAlarm: number | undefined =
		("highAlarm" in configuration &&
			configuration.highAlarm &&
			getValue(configuration.highAlarm.source)) ||
		undefined;
	const lowWarn: number | undefined =
		(configuration.lowWarn && getValue(configuration.lowWarn.source)) ||
		undefined;
	const highWarn: number | undefined =
		("highWarn" in configuration &&
			configuration.highWarn &&
			getValue(configuration.highWarn.source)) ||
		undefined;
	let prop: Widgets.GaugeProps = {
		showAsPercentage,
		min,
		max,
		highWarn:
			highWarn !== undefined && showAsPercentage
				? (highWarn / max) * 100
				: highWarn,
		lowWarn:
			lowWarn !== undefined && showAsPercentage
				? (lowWarn / max) * 100
				: lowWarn,
		highAlarm:
			highAlarm !== undefined && showAsPercentage
				? (highAlarm / max) * 100
				: highAlarm,
		lowAlarm:
			lowAlarm !== undefined && showAsPercentage
				? (lowAlarm / max) * 100
				: lowAlarm,
		key: "gauge" + uuidv4(),
		minLabel: showAsPercentage ? "0" : min.toString(),
		maxLabel: showAsPercentage ? "100" : max.toString(),
		type: "radial",
		value: 0,
		unit: showAsPercentage ? "%" : getUnit(configuration.value.source),
		limitStyle: "parallel",
		decimals: 0,
		color: Helpers.AbbColorHelper.getRgbHexColor(
			Helpers.HealthIndexStatusHelper.getColor(Enums.HealthIndexStatus.Ok)
		),
		warnColor: Helpers.AbbColorHelper.getRgbHexColor(
			Helpers.HealthIndexStatusHelper.getColor(Enums.HealthIndexStatus.Warning)
		),
		alarmColor: Helpers.AbbColorHelper.getRgbHexColor(
			Helpers.HealthIndexStatusHelper.getColor(Enums.HealthIndexStatus.Alarm)
		),
	};

	if (configuration && "label" in configuration) {
		prop.label = configuration.label?.i18n;
	}

	return prop;
}

export function getWidgetProps(w: WidgetConfig): Models.WidgetProps {
	console.info("loading " + w.component + " props");

	switch (w.component) {
		case Enums.WidgetType.SensorsOverview: {
			const data = w.propsConfig as unknown as SensorsOverviewPropsConfig;

			return {
				disabled: Boolean(data.disabled?.value),
				sensors: data.sensors.map((d) => createSensorsOverviewItemProps(d)),
				title: getTranslation(data.title.i18n),
				fetching: !Boolean(data.disabled?.value),
			} as Widgets.SensorsOverviewProps;
		}
		case Enums.WidgetType.Alerts: {
			const data = w.propsConfig as unknown as AlertsPropsConfig;
			let ap: Widgets.AlertsProps = {
				data: data.data.map((d) => createAlertsDataItemProps(d)),
				title: getTranslation(data.title.i18n),
				fetching: true,
			};
			return ap;
		}
		case Enums.WidgetType.GaugeInfo: {
			const data = w.propsConfig as unknown as GaugeInfoPropsConfig;

			return {
				description: data.description?.i18n
					? getTranslation(data.description.i18n)
					: "",
				info: data.info?.i18n ? getTranslation(data.info.i18n) : "",
				gauge: createGaugeProps(data),
				title: getTranslation(data.title.i18n),
				fetching: true,
				discoverMoreLink: data.discoverMoreLink?.value,
				size: data.size?.value ?? "s",
				orientation: data.orientation?.value ?? "row",
			} as Widgets.GaugeInfoProps;
		}
		case Enums.WidgetType.Gauges: {
			let data = w.propsConfig as unknown as GaugesPropsConfig;

			let gp: Widgets.GaugesProps = {
				gauges: data.data.map((d) => createGaugeProps(d)),
				title: getTranslation(data.title.i18n),
				fetching: true,
			};
			return gp;
		}
		case Enums.WidgetType.Image: {
			let data = w.propsConfig as unknown as { url: { value: string } };

			let ip: Widgets.ImageWidgetProps = {
				src: data.url.value,
			};

			return ip;
		}
		case Enums.WidgetType.ImageStatus: {
			let data = w.propsConfig as unknown as ImageStatusPropsConfig;

			let isp: Widgets.ImageStatusProps = {
				src: data.url.value,
				info: getTranslation(data.info.i18n),
				status: Enums.HealthIndexStatus.Ok,
			};

			return isp;
		}
		case Enums.WidgetType.ConnectionSensorStatus: {
			let data = w.propsConfig as unknown as ConnectionSensorStatusPropsConfig;

			let isp: Widgets.ConnectionSensorStatusProps = {
				info: getTranslation(data.info.i18n),
				status: Enums.HealthIndexStatus.Ok,
				localConnection: true,
				cloudConnection: false,
			};

			return isp;
		}
		case Enums.WidgetType.SystemInfo: {
			const config = w.propsConfig as unknown as SystemInfoPropsConfig;
			let systemInfoProps: Widgets.SystemInfoProps = {
				fetching: true,
				title: getValue<string>(config.deviceName.source),
				localConnection: true,
				cloudConnection: false,
				gauge: createGaugeProps({
					...config,
					value: config.gauge,
				}),
			};
			return systemInfoProps;
		}
		case Enums.WidgetType.Status: {
			const data = w.propsConfig as unknown as FamilyStatusPropsConfig;

			let sp: Widgets.FamilyStatusProps = {
				info: getTranslation(data.info?.i18n),
				title: getTranslation(data.title.i18n),
				description: getTranslation(data.description.i18n),
				status: Enums.HealthIndexStatus.Ok,
				fetching: true,
			};
			return sp;
		}
		case Enums.WidgetType.FamilyStatus: {
			const data = w.propsConfig as unknown as FamilyStatusPropsConfig;

			let fp: Widgets.FamilyStatusProps = {
				info: getTranslation(data.info?.i18n),
				title: getTranslation(data.title.i18n),
				description: getTranslation(data.description.i18n),
				status: Enums.HealthIndexStatus.Ok,
				key: data.title.i18n + "Widget",
				linkLabel: getTranslation(data.linkLabel.i18n),
				disableText: getTranslation(data.disabledText.i18n),
				linkUrl: data.linkUrl.value,
				disabled: !data.enable.value,
				fetching: data.enable.value,
			};

			return fp;
		}
		case Enums.WidgetType.SectionStatus: {
			let configuration = w.propsConfig as unknown as SectionStatusPropsConfig;

			let ssp: Widgets.SectionStatusProps = {
				status: Enums.HealthIndexStatus.Ok,
				title: getTranslation(configuration.title.i18n),
				subtitle: getTranslation(configuration.subtitle.i18n),
				description: getTranslation(configuration.description.i18n),
				info: getTranslation(configuration.info.i18n),
				fetching: true,
			};

			return ssp;
		}
		case Enums.WidgetType.BarChart:
		case Enums.WidgetType.BarChartWithChartTooltip: {
			let data = w.propsConfig as unknown as BarChartPropsConfig;
			let barProps: Widgets.BarChartItemProps = {
				color: data.color.value,
				hoverColor: data.hoverColor.value,
				name: getTranslation(data.name.i18n),
				unit: (data.data && getUnit(data.data.source)) || "",
			};

			let lineProps: Widgets.BarChartItemProps | undefined = undefined;

			// TODO: appiattire in lineColor, lineHoverColor, lineName o rimuovere se non utilizzata
			if (data.line) {
				lineProps = {
					color: data.line.configuration.color,
					hoverColor: data.line.configuration.hoverColor,
					name: getTranslation(data.line.configuration.nameKey),
					unit: (data.line && getUnit(data.line.source)) || "",
				};
			}

			let cp: Widgets.BarChartProps = {
				title: getTranslation(data.title.i18n),
				data: [],
				barDataInfo: barProps,
				lineDataInfo: lineProps,
				showLegend: data.showLegend?.value,
				xAxisLabel: getTranslation(data.xAxisLabel?.i18n),
				yAxisLabel: getTranslation(data.yAxisLabel?.i18n),
				yRightAxisLabel: getTranslation(data.yRightAxisLabel?.i18n),
				fetching: true,
				filterRef: data.filterRef?.value,
				percentageWidth: data.percentageWidth?.value ?? 100,
				dateFormat: data.dateFormat?.value,
				icons: data.icons
					? {
							alignment: data.icons.alignment.value,
							sources: data.icons.sources.map((s) => s.value),
							mappings: data.icons.mappings.map((m) => m.value),
							field: data.icons.field.value,
							width: data.icons.width.value,
							height: data.icons.height.value,
					  }
					: undefined,
				yAxisLabelAlignment: data.yAxisLabelAlignment?.value,
				xAxisLabelAlignment: data.xAxisLabelAlignment?.value,
				showUnitInYLabel: data.showUnitInYLabel?.value ?? false,
				xAxisType: data.xAxisType?.value ?? "date",
				minWidth: data.minWidth?.value,
			};

			if (w.component === Enums.WidgetType.BarChartWithChartTooltip) {
				let tooltipConfig =
					w.propsConfig as unknown as BarChartWithChartTooltipPropsConfig;
				const cpWithTooltip = cp as Widgets.BarChartWithChartTooltipProps;
				cpWithTooltip.tooltipChartType = tooltipConfig.tooltipChartType.value;
				let dataInfo: Widgets.WaveformChartItemProps[] =
					tooltipConfig.tooltipChartConfig.data!.map((d) => {
						let c: Widgets.WaveformChartItemProps = {
							unit: "",
							name: getTranslation(d.name.i18n),
							color: d.color.value,
						};
						return c;
					});
				cpWithTooltip.selectedTimestamp$ = new BehaviorSubject(0);
				cpWithTooltip.tooltipChartProps$ = new BehaviorSubject({
					data: [],
					dataInfo,
					title: getTranslation(tooltipConfig.tooltipChartConfig.title.i18n),
					//showFilter: tooltipConfig.tooltipChartConfig.showFilter?.value,
					xAxisLabel:
						tooltipConfig.tooltipChartConfig.xAxisLabel &&
						getTranslation(tooltipConfig.tooltipChartConfig.xAxisLabel?.i18n),
					yAxisLabel:
						tooltipConfig.tooltipChartConfig.yAxisLabel &&
						getTranslation(tooltipConfig.tooltipChartConfig.yAxisLabel?.i18n),
					fetching: true,
					filterRef: tooltipConfig.tooltipChartConfig.filterRef?.value,
					showHorizontalGrid:
						tooltipConfig.tooltipChartConfig.showHorizontalGrid?.value ?? false,
					yAxisLabelAlignment:
						tooltipConfig.tooltipChartConfig.yAxisLabelAlignment?.value,
					xAxisLabelAlignment:
						tooltipConfig.tooltipChartConfig.xAxisLabelAlignment?.value,
					showUnitInYLabel:
						tooltipConfig.tooltipChartConfig.showUnitInYLabel?.value ?? false,
					hideYAxis: tooltipConfig.tooltipChartConfig.hideYAxis?.value ?? true,
					showRange: tooltipConfig.tooltipChartConfig.showRange?.value ?? false,
					timeUnit: tooltipConfig.tooltipChartConfig.timeUnit.value,
				} as Widgets.WaveformChartProps);
			}

			return cp;
		}
		/* case Enums.WidgetType.BubbleChart:
				{
						let data = w.data as Models.BubbleChartData;

						let bubbleProps: Widgets.BubbleChartItemProps = {
								color: data.bubbles.configuration.color,
								hoverColor: data.bubbles.configuration.hoverColor,
								name: getTranslation(data.bubbles.configuration.nameKey),
								unit: ""
						};

						let optimumProps: Widgets.BubbleOptimumValues = {
								intervalValue: 0,
								color: data.optimum.configuration.color,
								name: getTranslation(data.optimum.configuration.nameKey),
						};

						let config = w.configuration as Models.BubbleChartConfig;

						let cp: Widgets.BubbleChartProps = {
								chartDivId: uuidv4(),
								title: getTranslation(config.titleKey),
								data: [],
								xAxisLabel: getTranslation(config.xAxisLabelKey),
								bubbleDataInfo: bubbleProps,
								optimumValue: optimumProps,
								showLegend: config.showLegend,
								fetching: true
						};

						return cp;
				} */
		case Enums.WidgetType.WaveformChart: {
			// let data = w.data as Models.SingleLineChartData;
			let config = w.propsConfig as unknown as WaveformChartPropsConfig;

			let cp: Widgets.WaveformChartProps = {
				title: getTranslation(config.title.i18n),
				data: [],
				fetching: true,
				accentColor: config.color?.value,
				filterRef: config.filterRef?.value,
				showRange: config.showRange?.value ?? false,
				hideYAxis: config.hideYAxis?.value,
				showHorizontalGrid: config.showHorizontalGrid?.value ?? false,
				unit: config.series?.source && (getUnit(config.series.source) ?? ""),
				dataInfo: config.data
					? config.data.map((d) => ({
							color: d.color.value,
							name: getTranslation(d.name.i18n),
							unit: getUnit(d.series.source) ?? "",
							dotted: d.dotted?.value ?? false,
					  }))
					: [],
				yAxisLabel: config.yAxisLabel
					? getTranslation(config.yAxisLabel.i18n)
					: undefined,
				xAxisLabel: config.xAxisLabel
					? getTranslation(config.xAxisLabel.i18n)
					: undefined,
				yAxisLabelAlignment: config.yAxisLabelAlignment?.value,
				xAxisLabelAlignment: config.xAxisLabelAlignment?.value,
				showUnitInYLabel: config.showUnitInYLabel?.value ?? false,
				timeUnit: config.timeUnit.value,
			};
			return cp;
		}
		case Enums.WidgetType.SingleLineChart: {
			//let data = w.data as Models.SingleLineChartData;
			let config = w.propsConfig as unknown as SingleLineChartPropsConfig;
			let cp: Widgets.SingleLineChartProps = {
				title: getTranslation(config.title.i18n),
				data: [],
				fetching: true,
				accentColor: config.color.value,
				highAlarmLabel:
					config.highAlarmLabel && getTranslation(config.highAlarmLabel.i18n),
				highWarnLabel:
					config.highWarnLabel && getTranslation(config.highWarnLabel.i18n),
				lowWarnLabel: "",
				lowAlarmLabel: "",
				unit: config.unit?.value ?? getUnit(config.data.source) ?? "",
				lineStyle: config.lineStyle.value,
				dotStyle: config.dotStyle.value,
				filterRef: config.filterRef?.value,
				xAxisType: config.xAxisType?.value ?? "date",
				disableYCursor: config.disableYCursor?.value ?? true,
				fill: config.fill?.value ?? false,
				hideAxes: config.hideAxes?.value,
				showHorizontalGrid: config.showHorizontalGrid?.value ?? false,
				yAxisLabel: config.yAxisLabel && getTranslation(config.yAxisLabel.i18n),
				xAxisLabel: config.xAxisLabel && getTranslation(config.xAxisLabel.i18n),
				yAxisLabelAlignment: config.yAxisLabelAlignment?.value,
				xAxisLabelAlignment: config.xAxisLabelAlignment?.value,
				showUnitInYLabel: config.showUnitInYLabel?.value ?? false,
				showVariation: config.showVariation?.value ?? false,
				skipEmptyPeriods: false,
				showDateInTooltip: config.showDateInTooltip?.value ?? false,
				infoText: config.infoText?.i18n
					? getTranslation(config.infoText.i18n)
					: undefined,
				showZero: config.showZero?.value ?? false,
				hideXLabel: config.hideXLabel?.value ?? false,
				showAsPercentage: config.showAsPercentage?.value ?? false,
				showUnitInTooltip: config.showUnitInTooltip?.value ?? true,
				showThresholdValue: config.showThresholdValue?.value ?? true,
			};

			//  cp.onTimeScaleChange = (v) => this.onMultilineTimeScaleChange(section, cp, v);

			return cp;
		}
		case Enums.WidgetType.MultiLineChart: {
			let data = w.propsConfig as unknown as MultiLineChartPropsConfig;

			let dataInfo: Widgets.MultiLineChartItemProps[] = data.data.map((d) => {
				const highAlarm =
					(d.highAlarm && getValue<number>(d.highAlarm.source)) ?? undefined;
				const lowAlarm =
					(d.lowAlarm && getValue<number>(d.lowAlarm.source)) ?? undefined;
				const highWarn =
					(d.highWarn && getValue<number>(d.highWarn.source)) ?? undefined;
				const lowWarn =
					(d.lowWarn && getValue<number>(d.lowWarn.source)) ?? undefined;
				const unit = getUnit(d.series.source) ?? "";
				const showThresholdValue = data.showThresholdValue?.value ?? true;
				let c: Widgets.MultiLineChartItemProps = {
					unit,
					name: getTranslation(d.name.i18n),
					color: d.color.value,
					lastValue: 0,
					lastUpdate: new Date(),
					highAlarm,
					highWarn,
					lowWarn,
					lowAlarm,
					highAlarmLabel:
						highAlarm && showThresholdValue
							? `${highAlarm}${unit}`
							: getTranslation("MAX"),
					highWarnLabel:
						highWarn && showThresholdValue
							? `${highWarn}${unit}`
							: getTranslation("MAX"),
					lowWarnLabel:
						lowWarn && showThresholdValue
							? `${lowWarn}${unit}`
							: getTranslation("MIN"),
					lowAlarmLabel:
						lowAlarm && showThresholdValue
							? `${lowAlarm}${unit}`
							: getTranslation("MIN"),
					connect: d.connect?.value,
				};
				return c;
			});
			let cp: Widgets.MultiLineChartProps = {
				title: getTranslation(data.title.i18n),
				dataInfo: dataInfo,
				data: [],
				showFilter: data.showFilter.value,
				legend: data.legend.value,
				xAxisLabel: data.xAxisLabel && getTranslation(data.xAxisLabel?.i18n),
				yAxisLabel: data.yAxisLabel && getTranslation(data.yAxisLabel?.i18n),
				seriesType: data.seriesType?.value,
				fetching: true,
				filterRef: data.filterRef?.value,
				image: data.image?.value,
				showGrid: data.showGrid?.value,
				fillOnHover: data.fillOnHover?.value ?? false,
				yAxisLabelAlignment: data.yAxisLabelAlignment?.value,
				xAxisLabelAlignment: data.xAxisLabelAlignment?.value,
				showUnitInYLabel: data.showUnitInYLabel?.value ?? false,
			};
			return cp;
		}
		case Enums.WidgetType.HardwareIntegrity: {
			const config = w.propsConfig as unknown as HardwareIntegrityPropsConfig;
			const props: HardwareIntegrityProps = {
				lastUpdateType: LastUpdateType.Sample,
				states: {
					hardwareMonitor: {},
					unit1: {
						loadDiagnostic: {},
						operationControl: {},
						positionControl: {},
					},
					unit2: {
						loadDiagnostic: {},
						operationControl: {},
						positionControl: {},
					},
					unit3: {
						loadDiagnostic: {},
						operationControl: {},
						positionControl: {},
					},
					unitsConnection: {},
				},
				title: getTranslation(config.title.i18n),
			};
			return props;
		}
		case Enums.WidgetType.PhaseSensor: {
			let config = w.propsConfig as unknown as PhaseSensorPropsConfig;
			const armMappings = config.temperatureSamples.map((ts, i) => ({
				color: ts.color.value,
				key: `value${i ? i + 1 : ""}` as ChartValueProperty,
				name: getTranslation(ts.name.i18n),
			}));
			let mappingIndex = armMappings!.length + 1;
			const highWarnKey: ChartValueProperty | undefined = config.highWarn
				?.source
				? (`value${mappingIndex++}` as ChartValueProperty)
				: undefined;
			const highAlarmKey: ChartValueProperty | undefined = config.highAlarm
				?.source
				? (`value${mappingIndex++}` as ChartValueProperty)
				: undefined;
			const lowWarnKey: ChartValueProperty | undefined = config.lowWarn?.source
				? (`value${mappingIndex++}` as ChartValueProperty)
				: undefined;
			const lowAlarmKey: ChartValueProperty | undefined = config.lowAlarm
				?.source
				? (`value${mappingIndex++}` as ChartValueProperty)
				: undefined;
			const loadKey: ChartValueProperty =
				`value${mappingIndex}` as ChartValueProperty;
			let psp: Widgets.PhaseSensorProps = {
				scaleType: phaseChartTimeInterval$.getValue(),
				data: [],
				armMappings,
				highAlarmKey,
				highWarnKey,
				lowWarnKey,
				lowAlarmKey,
				loadKey,
				title: getTranslation(config.title.i18n),
				linkUrl: config.linkUrl?.value,
				fetching: true,
				browsable: true,
				filterRef: config.filterRef?.value,
				hideYAxis: config.hideYAxis?.value ?? true,
				showHorizontalGrid: config.showHorizontalGrid?.value ?? false,
				showUnitInYLabel: config.showUnitInYLabel?.value ?? false,
				unit: getUnit(config.temperatureSamples[0].source) ?? "",
				yAxisLabel: config.yAxisLabel
					? getTranslation(config.yAxisLabel.i18n)
					: undefined,
			};

			return psp;
		}
		case Enums.WidgetType.MechanicalSensor: {
			let config = w.propsConfig as unknown as MechanicalSensorPropsConfig;

			let msp: Widgets.MechanicalSensorProps = {
				title: getTranslation(config.title.i18n),
				message: getTranslation(config.info.i18n),
				status: Enums.HealthIndexStatus.Ok,
				fetching: true,
				subPageDisabled: config.subPageDisabled?.value,
				linkUrl: config.linkUrl?.value,
			};
			return msp;
		}
		case Enums.WidgetType.Filter: {
			const config = w.propsConfig as unknown as ChartFilterPropsConfig;
			let wp: Models.ChartFilterWidgetProps = {
				filterRef: config.filterRef.value,
				defaultValue: config.default?.value,
				enableLastOperations: config.enableLastOperations?.value,
				defaultTimeRange: config.defaultTimeRange?.value,
				enableDate: config.enableDate?.value ?? false,
				enableDateRange: config.enableDateRange?.value ?? true,
				enableTimeRange: config.enableTimeRange?.value ?? true,
				installed: config.installedDevices
					? areInstalled(config.installedDevices)
					: undefined,
			};
			if (
				wp.enableDate &&
				!wp.enableDateRange &&
				!wp.enableTimeRange &&
				!wp.enableLastOperations
			) {
				const today = new Date();
				const startDate = new Date(today);
				startDate.setHours(0, 0, 0, 0);
				wp.defaultValue = {
					startDate,
					endDate: today,
				};
			}
			return wp;
		}
		case Enums.WidgetType.CBStatus: {
			const config = w.propsConfig as unknown as CBStatusPropsConfig;
			const props: CBStatusProps = {
				title: getTranslation(config.title.i18n),
				units: [],
				voltage: null,
				current: null,
				voltageUnit: getUnit(config.voltage.source),
				currentUnit: getUnit(config.current.source),
				ready: false,
				closed: false,
				open: false,
				lastUpdateType: LastUpdateType.Sample,
			};
			return props;
		}
		case Enums.WidgetType.CumulatedWorkload: {
			const config = w.propsConfig as unknown as CumulatedWorkloadPropsConfig;
			const props: CumulatedWorkloadProps = {
				title: getTranslation(config.title.i18n),
				additionalStats: [],
				description: getTranslation(config.description.i18n),
				numberOfOperations: 0,
				status: HealthIndexStatus.Ok,
				value: 0,
				avgInterruptedCurrent: 0,
				avgInterruptedCurrentUnit: getUnit(config.avgInterruptedCurrent.source),
				alarmMessage: getTranslation(config.alarmMessage.i18n),
				warningMessage: getTranslation(config.warningMessage.i18n),
				okMessage: getTranslation(config.okMessage.i18n),
				lastUpdateType: LastUpdateType.Sample,
				singleLineChartProps: {
					accentColor: getColor("color-status-green"),
					data: [],
					hideCard: true,
					hideAxes: true,
					dotStyle: "small",
					xAxisType: "value",
					disableYCursor: false,
					fill: false,
					showHorizontalGrid: false,
					showUnitInYLabel: false,
					showVariation: false,
					skipEmptyPeriods: false,
					showDateInTooltip: false,
					infoText: undefined,
					showZero: false,
					hideXLabel: true,
					showAsPercentage: false,
					showUnitInTooltip: true,
					showThresholdValue: false,
				},
			};
			return props;
		}
		case Enums.WidgetType.InputStatus: {
			const config = w.propsConfig as unknown as InputStatusPropsConfig;
			const props: InputStatusProps = {
				inputs: [],
				lastUpdateType: LastUpdateType.Sample,
				link: config.link.value,
				linkName: getTranslation(config.linkName.i18n),
				title: getTranslation(config.title.i18n),
			};
			return props;
		}
		case Enums.WidgetType.HealthIndexProgress: {
			const config = w.propsConfig as unknown as HealthIndexProgressPropsConfig;
			const props: HealthIndexProgressProps = {
				title: getTranslation(config.title.i18n),
				value: 0,
				discoverMoreLink: config.discoverMoreLink.value,
				unit: getUnit(config.value.source),
				lastUpdateType: LastUpdateType.Sample,
				warningMessage: getTranslation(config.warningMessage.i18n),
				alarmMessage: getTranslation(config.alarmMessage.i18n),
				lowAlarm: Number(getValue(config.lowAlarm.source)),
				lowWarn: Number(getValue(config.lowWarn.source)),
				min: Number(getValue(config.min.source) ?? 0),
				max: Number(getValue(config.max.source) ?? 100),
			};
			return props;
		}
		case Enums.WidgetType.SyncStatus: {
			const config = w.propsConfig as unknown as SyncStatusPropsConfig;
			const props: SyncStatusProps = {
				lastUpdateType: LastUpdateType.Sample,
				frequency: 0,
				voltage: 0,
				syncEnabled: undefined,
				link: config.link.value,
				frequencyStatus: undefined,
				voltageStatus: undefined,
				workingMode: undefined,
				title: getTranslation(config.title.i18n),
				linkName: getTranslation(config.linkName.i18n),
				frequencyUnit: getUnit(config.frequency.source),
				voltageUnit: getUnit(config.voltage.source),
			};
			return props;
		}
		case Enums.WidgetType.MotorUnit: {
			const config = w.propsConfig as unknown as MotorUnitPropsConfig;
			const [minAngle, maxAngle] = angleRangeWithNegativeConversion(
				getValue(config.minAngle.source),
				getValue(config.maxAngle.source)
			);
			const highWarn = angleInRange(
				getValue(config.highWarn.source),
				minAngle,
				maxAngle
			);
			const lowWarn = angleInRange(
				getValue(config.lowWarn.source),
				minAngle,
				maxAngle
			);
			const props: MotorUnitProps = {
				title: getTranslation(config.title.i18n),
				absoluteEncoder: undefined,
				angle: 0,
				angleStatus: HealthIndexStatus.Ok,
				encodersDifference: undefined,
				highWarn,
				lowWarn,
				incrementalEncoder: undefined,
				maxAngle,
				maxTemperature: Number(getValue(config.tempMax.source)),
				minAngle,
				minTemperature: Number(getValue(config.tempMin.source)),
				temperatureHighAlarm: Number(getValue(config.tempHighAlarm.source)),
				temperatureHighWarning: Number(getValue(config.tempHighWarning.source)),
				motorWinding: undefined,
				temperature: 0,
				temperatureUnit: getUnit(config.ipmTempFilt.source),
				link: config.link.value,
				linkName: getTranslation(config.linkName.i18n),
				lastUpdateType: LastUpdateType.Sample,
				unit: getUnit(config.motorAngle.source),
			};
			return props;
		}
		case Enums.WidgetType.CapacitorStatus: {
			const config = w.propsConfig as unknown as CapacitorStatusPropsConfig;
			const props: CapacitorStatusProps = {
				capacity: {
					min: Number(getValue(config.capacity.min.source)),
					max: Number(getValue(config.capacity.max.source)),
					lowAlarm: config.capacity.lowAlarm
						? Number(getValue(config.capacity.lowAlarm.source))
						: undefined,
					lowWarn: config.capacity.lowWarn
						? Number(getValue(config.capacity.lowWarn.source))
						: undefined,
					highAlarm: config.capacity.highAlarm
						? Number(getValue(config.capacity.highAlarm.source))
						: undefined,
					highWarn: config.capacity.highWarn
						? Number(getValue(config.capacity.highWarn.source))
						: undefined,
					value: 0,
					unit: getUnit(config.capacity.value.source),
				},
				voltage: {
					min: Number(getValue(config.voltage.min.source)),
					max: Number(getValue(config.voltage.max.source)),
					lowAlarm: config.voltage.lowAlarm
						? Number(getValue(config.voltage.lowAlarm.source))
						: undefined,
					lowWarn: config.voltage.lowWarn
						? Number(getValue(config.voltage.lowWarn.source))
						: undefined,
					highAlarm: config.voltage.highAlarm
						? Number(getValue(config.voltage.highAlarm.source))
						: undefined,
					highWarn: config.voltage.highWarn
						? Number(getValue(config.voltage.highWarn.source))
						: undefined,
					value: 0,
					unit: getUnit(config.voltage.value.source),
				},
				title: getTranslation(config.title.i18n),
				lastUpdateType: LastUpdateType.Sample,
			};
			return props;
		}
		case Enums.WidgetType.ImageStatusWithOverlays: {
			let tmpPropsConfig = { ...w.propsConfig };

			if (process.env.REACT_APP_PRODUCT_VARIANT) {
				tmpPropsConfig.url =
					tmpPropsConfig["url-" + process.env.REACT_APP_PRODUCT_VARIANT];
				tmpPropsConfig.layers =
					tmpPropsConfig["layers-" + process.env.REACT_APP_PRODUCT_VARIANT];
			}

			let data =
				tmpPropsConfig as unknown as ImageStatusWidgetWithOverlaysPropsConfig;

			let isp: Widgets.ImageStatusWithOverlaysProps = {
				src: data.url.value,
				message: "",
				status: Enums.HealthIndexStatus.Ok,
				layers: data.layers.map((l) => l.url.value),
				visibleLayerIndices: [],
			};

			return isp;
		}
		case Enums.WidgetType.ActuationEvents: {
			const config = w.propsConfig as unknown as ActuationEventsPropsConfig;
			const props: ActuationEventsProps = {
				eventType$: new BehaviorSubject(config.eventType.value),
				selectedEvent$: new BehaviorSubject<{
					groupId: number;
					timestamp: number;
				} | null>(null),
				defaultDate: new Date(),
				events: [],
				separators: config.separators?.map((s) => getTranslation(s.i18n)) ?? [],
				installed: config.installedDevices
					? areInstalled(config.installedDevices)
					: undefined,
				charts: config.charts.map((chart) => ({
					accentColor: chart.color?.value,
					data: [],
					desktopCols: w.grid.desktop,
					mobileCols: w.grid.mobile,
					tabletCols: w.grid.tablet,
					title: getTranslation(chart.title.i18n),
					filterRef: chart.filterRef?.value,
					showRange: chart.showRange?.value ?? false,
					hideYAxis: chart.hideYAxis?.value ?? true,
					showHorizontalGrid: chart.showHorizontalGrid?.value ?? false,
					unit: chart.series?.source && (getUnit(chart.series.source) ?? ""),
					dataInfo: chart.data
						? chart.data.map((d) => ({
								color: d.color.value,
								name: getTranslation(d.name.i18n),
								unit: getUnit(d.series.source) ?? "",
								dotted: d.dotted?.value ?? false,
						  }))
						: [],
					yAxisLabel: chart.yAxisLabel
						? getTranslation(chart.yAxisLabel.i18n)
						: undefined,
					xAxisLabel: chart.xAxisLabel
						? getTranslation(chart.xAxisLabel.i18n)
						: undefined,
					yAxisLabelAlignment: chart.yAxisLabelAlignment?.value,
					xAxisLabelAlignment: chart.xAxisLabelAlignment?.value,
					showUnitInYLabel: chart.showUnitInYLabel?.value ?? false,
					timeUnit: chart.timeUnit.value,
				})),
				pollerId: getWidgetDescriptorId(w),
				filterRef: config.filterRef.value,
			};
			return props;
		}
		case Enums.WidgetType.SectionTitle: {
			const props: WidgetProps = {
				title: getTranslation((w.propsConfig!.title as { i18n: string }).i18n),
				installed: w.propsConfig?.installedDevices
					? areInstalled(
							w.propsConfig!.installedDevices as unknown as {
								role: string;
								position: string;
							}[]
					  )
					: undefined,
			};
			return props;
		}
		case Enums.WidgetType.BinaryInputDiagnostic: {
			const config =
				w.propsConfig as unknown as BinaryInputDiagnosticPropsConfig;
			const props: BinaryInputDiagnosticProps = {
				title: getTranslation(config.title.i18n),
				inputs: [],
				lastUpdateType: LastUpdateType.Sample,
			};
			return props;
		}
		case Enums.WidgetType.EventLogs: {
			const config = w.propsConfig as unknown as EventLogsPropsConfig;
			const props: EventLogsProps = {
				title: getTranslation(config.title.i18n),
				logs: [],
				units: config.devices.map((d) => d.id.value),
			};
			return props;
		}
		case Enums.WidgetType.StatusDetailsWithIcon: {
			const config =
				w.propsConfig as unknown as StatusDetailsWithIconPropsConfig;
			const props: StatusDetailsWithIconProps = {
				title: getTranslation(config.title.i18n),
				description: getTranslation(config.description.i18n),
				lastErrorDuration: 0,
				icon: config.icon.value,
				status: null,
				lowAlarm: Number(getValue(config.lowAlarm.source)),
				lowWarn: Number(getValue(config.lowWarn.source)),
			};
			return props;
		}
		default: {
			console.info("creating." + w.component + ".notImplemented");

			let wp: Models.WidgetProps = {
				title: w.component + " " + getTranslation("NotImplemented"),
				fetching: true,
			};
			return wp;
		}
	}
}

/* export function onPhaseTimeScaleChange(chartTimeIntervalState: Enums.ChartTimeRange) {
		console.log("DataService.onPhaseTimeScaleChange." + Object.values(Enums.ChartTimeRange)[chartTimeIntervalState]);

		phaseChartTimeInterval$.next(chartTimeIntervalState);

		temperatureSensors$.getValue().forEach(s => {
				let prop = s.prop$.getValue() as Widgets.PhaseSensorProps;
				prop.scaleType = chartTimeIntervalState;
				s.prop$.next({...prop});
				forceRefresh(s);
		});
}
 */

export function updateCharts(
	d: DataElement,
	scaleType: Models.ChartScaleType
): Promise<void> {
	/*  let prop = d.prop$.getValue() as Widgets.PhaseSensorProps;
	 if (prop as Models.ChartWidgetProps) {
			 (prop as Models.ChartWidgetProps).scaleType = scaleType;
			 d.prop$.next({...prop});
			 return forceRefresh(d);
	 } */
	return Promise.resolve();
}
