import React, { ReactElement, useEffect, useState } from 'react'
import * as ABB from '@abb/abb-common-ux-react';
import { useHistory } from "react-router-dom";
import { Card } from "../../components"
import { useTranslation } from 'react-i18next'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import { ChartDataItem, ChartValueProperty, ChartWidgetProps } from "../../models";
import { ChartLastOperation, HealthIndexStatus } from '../../enums'
import { SectionsHelper, BootstrapHelper, HealthIndexStatusHelper, AbbColorHelper, ChartHelper } from '../../helpers'
import './style.scss';
import { useRef } from 'react';
import { useReadable } from '../../observables/hooks';
import { chartFilters$ } from '../../services/polling/chart';



export interface PhaseSensorProps extends ChartWidgetProps {
	data: ChartDataItem[];
	topRightContent?: () => ReactElement;
	currentUpperArmTemp?: number;
	currentLowerArmTemp?: number;
	currentArmValues?: ChartDataItem;
	armMappings: {
		name: string, 
		color: string,
		key: ChartValueProperty,
	}[];
	linkUrl?: string,
	loadKey: ChartValueProperty,
	highWarnKey?: ChartValueProperty,
	lowWarnKey?: ChartValueProperty,
	highAlarmKey?: ChartValueProperty,
	lowAlarmKey?: ChartValueProperty,
	browsable: boolean;
	hideYAxis: boolean;
	showHorizontalGrid: boolean;
	unit: string;
	yAxisLabel?: string;
}

function ArmBox(props: {
	armName: string,
	armClass: string,
	armValue: number|null,
	warnUpperLimit?: number,
	alarmUpperLimit?: number,
	warnLowerLimit?: number,
	alarmLowerLimit?: number,
	unit: string,
	color?: string,
}) {
	const { t } = useTranslation();


	return (
		<div className={"d-flex arm justify-content-between " + props.armClass}>
			<div className="d-flex flex-row">
				<div className="square my-auto mr-2" style={{backgroundColor: props.color}}></div>
				<div className="arm-name">{props.armName}</div>
			</div>
			<div className="d-flex flex-row verticalMarginAuto">
				<div className="text-uppercase mr-2" style={{ whiteSpace: "nowrap" }}>{t('CurrentTemp')}</div>
				<div className="text-right d-flex">
					<span>{ArmBoxIcon(props.armValue, props.alarmUpperLimit, props.warnUpperLimit, props.alarmLowerLimit, props.warnLowerLimit)}</span>
					<span className="font-weight-bold align-middle" style={{ display: 'inline-block', paddingLeft: "2px" }}>
						{props.armValue !== null ? props.armValue : t("N/A")}{props.unit}
					</span>
				</div>
			</div>
		</div>
	)
}

function PhaseCharts(props: PhaseSensorProps) {
	const { t } = useTranslation();
	const chartFilters = useReadable(chartFilters$);
	const selectedFilter = props.filterRef ? chartFilters[props.filterRef] : null;
	const scaleType = selectedFilter ?? props.scaleType;
	//Phase chart 
	const [phaseChart, setPhaseChart] = useState<am4charts.XYChart | null>(null);
	const phaseContainerRef = useRef<HTMLDivElement | null>(null);
	const [highWarningRange, setHighWarningRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [highAlarmRange, setHighAlarmRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [lowWarningRange, setLowWarningRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	const [lowAlarmRange, setLowAlarmRange] = useState<am4charts.ValueAxisDataItem | null>(null);
	//Load chart
	const [loadChart, setLoadChart] = useState<am4charts.XYChart | null>(null);
	const loadContainerRef = useRef<HTMLDivElement | null>(null);
	const [xAxis, setXAxis] = useState<am4charts.DateAxis|null>(null);
	const [yAxis, setYAxis] = useState<am4charts.ValueAxis|null>(null);

	const [defaultXAxisName, setDefaultXAxisName] = useState('');

	useEffect(() => {
		if (Object.values(ChartLastOperation).includes(scaleType as any)) {
			setDefaultXAxisName(t("OperationExecuted"));
		} else {
			setDefaultXAxisName(t("Time"));
		}
	}, [scaleType, t]);
	useEffect(() => {
		if (!phaseContainerRef.current) {
			return;
		}
		const _phaseChart = am4core.create(phaseContainerRef.current, am4charts.XYChart);
		_phaseChart.zoomOutButton.disabled = true;
		_phaseChart.dateFormatter.inputDateFormat = "x";
		_phaseChart.cursor = new am4charts.XYCursor();
		_phaseChart.cursor.behavior = "none";
		_phaseChart.cursor.lineY.disabled = true;
		setPhaseChart(_phaseChart);

		const dateAxis = _phaseChart.xAxes.push(new am4charts.DateAxis());
		dateAxis.cursorTooltipEnabled = false;
		dateAxis.renderer.grid.template.disabled = true;
		dateAxis.skipEmptyPeriods = true;
		setXAxis(dateAxis);
		const valueAxis = _phaseChart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.cursorTooltipEnabled = false;
		valueAxis.renderer.grid.template.disabled = true;
		valueAxis.renderer.labels.template.disabled = true;
		setYAxis(valueAxis);
		const seriesList: am4charts.LineSeries[] = [];
		props.armMappings.forEach((arm) => {
			const armColor = am4core.color(arm.color);
			const _series = _phaseChart.series.push(new am4charts.LineSeries());
			_series.strokeWidth = 2;
			_series.fillOpacity = 0.2;
			_series.dataFields.valueY = arm.key;
			_series.dataFields.dateX = "date";
			_series.tensionX = 0.8;
			_series.tensionY = 0.8;
			_series.tooltipText = `${arm.name}: [bold]{valueY}[/]`;
			_series.connect = false;
			_series.stroke = armColor;
			_series.fill = armColor;
			_series.autoGapCount = 2;
			_series.name = arm.name;
			seriesList.push(_series);
	
			const bullet1 = new am4charts.CircleBullet();
			bullet1.circle.radius = 3;
			bullet1.stroke = armColor;
			bullet1.fill = armColor;
			_series.bullets.push(bullet1);
	
			const axis1Tooltip = _series.tooltip;
			if (axis1Tooltip) {
				axis1Tooltip.getFillFromObject = false;
				axis1Tooltip.autoTextColor = false;
				axis1Tooltip.background.fill = am4core.color("white");
				axis1Tooltip.label.fill = am4core.color("black");
			}
	
			if (axis1Tooltip) {
				axis1Tooltip.label.adapter.add("html", function (text, target) {
					try {
						const val = target.dataItem as am4charts.LineSeriesDataItem;
						const d = val.dataContext as ChartDataItem | undefined;
						let status = HealthIndexStatus.Ok;
						const armValue = d?.[arm.key as keyof ChartDataItem];
						const highAlarm = props.highAlarmKey ? d?.[props.highAlarmKey as keyof ChartDataItem] : undefined;
						const highWarn = props.highWarnKey ? d?.[props.highWarnKey as keyof ChartDataItem] : undefined;
						const lowAlarm = props.lowAlarmKey ? d?.[props.lowAlarmKey as keyof ChartDataItem] : undefined;
						const lowWarn = props.lowWarnKey ? d?.[props.lowWarnKey as keyof ChartDataItem] : undefined;
						if (armValue !== undefined) {
							if (highAlarm !== undefined && armValue > highAlarm) {
								status = HealthIndexStatus.Alarm;
							} else if (highWarn !== undefined && armValue > highWarn) {
								status = HealthIndexStatus.Warning;
							} else if (lowAlarm !== undefined && armValue < lowAlarm) {
								status = HealthIndexStatus.Alarm;
							} else if (lowWarn !== undefined && armValue < lowWarn) {
								status = HealthIndexStatus.Warning;
							} 
							if (status === HealthIndexStatus.Ok) {
								return `${arm.name}: <b>{valueY}${props.unit}</b>`;
							}
							else {
								const icon = HealthIndexStatusHelper.getIcon(status).replace('/', '_');
								const color = AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(status));
								return `${arm.name}: <i class="ABB_CommonUX_Icon__root align-middle"><i class="mx-auto ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-${icon}_16" style="color:${color}"></i></i> <b>{valueY}${props.unit}</b>`;
							}
						}
					} catch (err) {
						console.warn(err);
					}
					return `${arm.name}: <b>{valueY}${props.unit}</b>`;
				})
			}
		});
		
		if (props.highWarnKey) {
			const _lineSeries1 = _phaseChart.series.push(new am4charts.StepLineSeries());
			_lineSeries1.dataFields.dateX = "date";
			_lineSeries1.dataFields.valueY = props.highWarnKey;
			_lineSeries1.fillOpacity = 0;
			_lineSeries1.strokeWidth = 1;
			_lineSeries1.strokeDasharray = "3";
			_lineSeries1.connect = true;
			_lineSeries1.name = "High warn";
			_lineSeries1.tooltipHTML = `<span style="color:${AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(HealthIndexStatus.Warning))}">Warning: <b>{valueY}${props.unit}</b></span>`;
			const highWarningTooltip = _lineSeries1.tooltip;
			if (highWarningTooltip) {
				highWarningTooltip.getFillFromObject = false;
				highWarningTooltip.autoTextColor = false;
				highWarningTooltip.background.fill = am4core.color("white");
				highWarningTooltip.label.fill = am4core.color("black");
			}
			setHighWarningRange(valueAxis.axisRanges.create());
		}

		if (props.highAlarmKey) {
			const _lineSeries2 = _phaseChart.series.push(new am4charts.StepLineSeries());
			_lineSeries2.dataFields.dateX = "date";
			_lineSeries2.dataFields.valueY = props.highAlarmKey;
			_lineSeries2.fillOpacity = 0;
			_lineSeries2.strokeWidth = 1;
			_lineSeries2.connect = true;
			_lineSeries2.name = "High alarm";
			_lineSeries2.tooltipHTML = `<span style="color:${AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(HealthIndexStatus.Alarm))}">Alarm: <b>{valueY}${props.unit}</b></span>`;

			const highAlarmTooltip = _lineSeries2.tooltip;
			if (highAlarmTooltip) {
				highAlarmTooltip.getFillFromObject = false;
				highAlarmTooltip.autoTextColor = false;
				highAlarmTooltip.background.fill = am4core.color("white");
				highAlarmTooltip.label.fill = am4core.color("black");
			}

			setHighAlarmRange(valueAxis.axisRanges.create());
		}

		if (props.lowWarnKey) {
			const _lineSeries3 = _phaseChart.series.push(new am4charts.StepLineSeries());
			_lineSeries3.dataFields.dateX = "date";
			_lineSeries3.dataFields.valueY = props.lowWarnKey;
			_lineSeries3.fillOpacity = 0;
			_lineSeries3.strokeWidth = 1;
			_lineSeries3.strokeDasharray = "3";
			_lineSeries3.connect = true;
			_lineSeries3.name = "Low warn";
			_lineSeries3.tooltipHTML = `<span style="color:${AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(HealthIndexStatus.Warning))}">Warning: <b>{valueY}${props.unit}</b></span>`;

			const lowWarningTooltip = _lineSeries3.tooltip;
			if (lowWarningTooltip) {
				lowWarningTooltip.getFillFromObject = false;
				lowWarningTooltip.autoTextColor = false;
				lowWarningTooltip.background.fill = am4core.color("white");
				lowWarningTooltip.label.fill = am4core.color("black");
			}
			setLowWarningRange(valueAxis.axisRanges.create());
		}

		if (props.lowAlarmKey) {
			const _lineSeries4 = _phaseChart.series.push(new am4charts.StepLineSeries());
			_lineSeries4.dataFields.dateX = "date";
			_lineSeries4.dataFields.valueY = props.lowAlarmKey;
			_lineSeries4.fillOpacity = 0;
			_lineSeries4.strokeWidth = 1;
			_lineSeries4.connect = true;
			_lineSeries4.name = "Low alarm"
			_lineSeries4.tooltipHTML = `<span style="color:${AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(HealthIndexStatus.Alarm))}">Alarm: <b>{valueY}${props.unit}</b></span>`;

			const lowAlarmTooltip = _lineSeries4.tooltip;
			if (lowAlarmTooltip) {
				lowAlarmTooltip.getFillFromObject = false;
				lowAlarmTooltip.autoTextColor = false;
				lowAlarmTooltip.background.fill = am4core.color("white");
				lowAlarmTooltip.label.fill = am4core.color("black");
			}
			setLowAlarmRange(valueAxis.axisRanges.create());
		}
	}, [phaseContainerRef, props.unit, props.armMappings, props.lowAlarmKey, props.highAlarmKey, props.highWarnKey, props.lowWarnKey]);

	useEffect(() => {
		if (!loadContainerRef.current || !props.loadKey) {
			return;
		}
		const _loadChart = am4core.create(loadContainerRef.current, am4charts.XYChart);
		_loadChart.zoomOutButton.disabled = true;
		_loadChart.dateFormatter.inputDateFormat = "x";
		setLoadChart(_loadChart);

		const dateAxis = _loadChart.xAxes.push(new am4charts.DateAxis());
		dateAxis.cursorTooltipEnabled = false;
		dateAxis.renderer.grid.template.location = 0;
		dateAxis.renderer.ticks.template.disabled = true;
		dateAxis.renderer.line.opacity = 0;
		dateAxis.renderer.grid.template.disabled = true;
		dateAxis.renderer.labels.template.disabled = true;

		const valueAxis = _loadChart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.line.opacity = 0;
		valueAxis.renderer.ticks.template.disabled = true;
		valueAxis.renderer.grid.template.disabled = true;
		valueAxis.renderer.labels.template.disabled = true;
		// Sometimes the chart would not render properly and would show all load levels having
		// same height. Fixing min and max fixes this problem.
		valueAxis.min = 0;
		valueAxis.max = 1.5;

		const lineSeries = _loadChart.series.push(new am4charts.StepLineSeries());
		lineSeries.dataFields.dateX = "date";
		lineSeries.dataFields.valueY = props.loadKey;
		lineSeries.fillOpacity = 1;
		lineSeries.strokeWidth = 0;
		lineSeries.propertyFields.fill = "color";
		lineSeries.connect = true;
		lineSeries.name = "Load";
	}, [loadContainerRef, props.loadKey]);

	useEffect(() => {
		return () => {
			if (loadChart && !loadChart.isDisposed()) {
				loadChart.dispose();
				setLoadChart(null);
			}
		}
	}, [loadChart, props.loadKey]);

	useEffect(() => {
		return () => {
			if (phaseChart && !phaseChart.isDisposed()) {
				phaseChart.dispose();
				setPhaseChart(null);
			}
		}
	}, [phaseChart, props.unit, props.armMappings, props.lowAlarmKey, props.highAlarmKey, props.highWarnKey, props.lowWarnKey]);

	useEffect(() => {
		if (phaseChart && !phaseChart.isDisposed()) {
			phaseChart.yAxes.each((axis) => {
				axis.renderer.labels.template.disabled = props.hideYAxis!;
				if (!props.hideYAxis) {
					axis.renderer.inside = true;
					axis.renderer.maxLabelPosition = 0.99;
					axis.renderer.labels.template.dy = -7;
				}
				axis.renderer.grid.template.disabled = props.hideYAxis!;
				axis.renderer.baseGrid.disabled = !props.showHorizontalGrid;
				axis.renderer.grid.template.strokeOpacity = props.showHorizontalGrid ? 0.2 : 0;
			});
		}
	}, [phaseChart, props.hideYAxis, props.showHorizontalGrid]);

	const loadChartFirstRender = useRef(true);
	useEffect(() => {
		if (loadChart && !loadChart.isDisposed()) {
			if (!loadChartFirstRender.current) {
				loadChart.series.each((series) => {
					series.interpolationDuration = 0;
				});
			}
			loadChartFirstRender.current = false;
			// This patch is required because otherwise if the last load is different from
			// the second to last load, the colour of the last load gets ignored and the
			// previous colour is used instead. Duplicating the last load fixes the problem.
			const data = props.data.length ? [...props.data, props.data[props.data.length - 1]] : props.data;
			loadChart.data = data;
		}
	}, [loadChart, props.data, loadChartFirstRender, props.loadKey]);

	const phaseChartFirstRender = useRef(true);
	useEffect(() => {
		if (phaseChart && !phaseChart.isDisposed()) {
			if (!phaseChartFirstRender.current) {
				phaseChart.series.each((series) => {
					series.interpolationDuration = 0;
				});
			}
			phaseChartFirstRender.current = false;
			phaseChart.data = props.data;
		}
	}, [phaseChart, props.data, phaseChartFirstRender]);

	useEffect(() => {
		if (phaseChart && !phaseChart.isDisposed()) {
			phaseChart.xAxes.each(a => ChartHelper.setDateFormat(scaleType, a as am4charts.DateAxis));
		}
	}, [phaseChart, scaleType]);

	useEffect(() => {
		if (xAxis && !xAxis.isDisposed()) {
			xAxis.title.text = defaultXAxisName;
			if (props.xAxisLabelAlignment) {
				xAxis.title.align = props.xAxisLabelAlignment;
			}
		}
		if (props.yAxisLabel && yAxis && !yAxis.isDisposed()) {
			yAxis.title.text = props.yAxisLabel;
			if (props.showUnitInYLabel && props.unit) {
				yAxis.title.text += ` (${props.unit})`;
			} 
			if (props.yAxisLabelAlignment) {
				yAxis.title.valign = props.yAxisLabelAlignment;
			}
		}
	}, [xAxis, yAxis, props.yAxisLabel, props.xAxisLabelAlignment, props.yAxisLabelAlignment, props.showUnitInYLabel, props.unit, defaultXAxisName]);


	useEffect(() => {
		if (phaseChart && !phaseChart.isDisposed() && props.currentArmValues) {
			if (highAlarmRange) {
				const highAlarm = props.highAlarmKey ? props.currentArmValues[props.highAlarmKey as keyof ChartDataItem] : undefined;
				if (highAlarm !== undefined) {
					highAlarmRange.value = highAlarm;
					highAlarmRange.label.text = highAlarmRange.value.toFixed(0);
				}
			}
			if (highWarningRange) {
				const highWarn = props.highWarnKey ? props.currentArmValues[props.highWarnKey as keyof ChartDataItem] : undefined;
				if (highWarn !== undefined) {
					highWarningRange.value = highWarn;
					highWarningRange.label.text = highWarningRange.value.toFixed(0);
				}
			}
			if (lowWarningRange) {
				const lowWarn = props.lowWarnKey ? props.currentArmValues[props.lowWarnKey as keyof ChartDataItem] : undefined;
				if (lowWarn !== undefined) {
					lowWarningRange.value = lowWarn;
					lowWarningRange.label.text = lowWarningRange.value.toFixed(0);
				}
			}
			if (lowAlarmRange) {
				const lowAlarm = props.lowAlarmKey ? props.currentArmValues[props.lowAlarmKey as keyof ChartDataItem] : undefined;
				if (lowAlarm) {
					lowAlarmRange.value = lowAlarm;
					lowAlarmRange.label.text = lowAlarmRange.value.toFixed(0);
				}
			}
		}
	}, [highAlarmRange, highWarningRange, props.currentArmValues, phaseChart, lowWarningRange, lowAlarmRange, props.lowAlarmKey, props.lowWarnKey, props.highAlarmKey, props.highWarnKey]);

	return (
		<>
			<div className="arms d-flex flex-column">
				{props.armMappings && props.currentArmValues && props.armMappings.map((arm, i) => <ArmBox 
					armClass=''
					armName={arm.name}
					armValue={(props.currentArmValues!)[arm.key as keyof ChartDataItem] ?? null}
					unit={props.unit} 
					alarmUpperLimit={props.highAlarmKey ? (props.currentArmValues!)[props.highAlarmKey as keyof ChartDataItem] : undefined} 
					warnUpperLimit={props.highWarnKey ? (props.currentArmValues!)[props.highWarnKey as keyof ChartDataItem] : undefined}
					alarmLowerLimit={props.lowAlarmKey ? (props.currentArmValues!)[props.lowAlarmKey as keyof ChartDataItem] : undefined}
					warnLowerLimit={props.lowWarnKey ? (props.currentArmValues!)[props.lowWarnKey as keyof ChartDataItem] : undefined}
					color={arm.color}
					key={i}
				/>)}
			</div>
			<div id="phase-chart">
				<div ref={phaseContainerRef} className={"appChart"}></div>
			</div>
			<div id="load-chart">
				<div ref={loadContainerRef} style={{ maxHeight: "80px" }}></div>
			</div>
			<div className="load d-flex flex-row">
				<span className="font-weight-bold text-uppercase">{t('Load')}:</span>
				<div className="square low"></div>
				<span>{t('Low')}</span>
				<div className="square mid"></div>
				<span>{t('Mid')}</span>
				<div className="square high"></div>
				<span>{t('High')}</span>
			</div>

		</>
	)
}

export function PhaseSensor(props: PhaseSensorProps): ReactElement {
	let hist = useHistory();

	return (
		<Card installed={props.installed} title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
			className={"phase-sensor-card " + (props.browsable ? "browsable" : "")}
			contentClassName="phase-sensor"
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(12, props.tabletCols)}
			desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}
			progressBar="top"
			disconnected={props.disconnected}
			topRightContent={props.topRightContent}
			showProgressBar={props.externalFetching}>

			{props.browsable && props.linkUrl &&
				<a href={SectionsHelper.createHrefByString(props.linkUrl, hist)}>
					<PhaseCharts {...props} />
				</a>
			}
			{(!props.browsable || !props.linkUrl) && <PhaseCharts {...props} />}
		</Card>
	)
}

function ArmBoxIcon(armValue: number|null, upperAlarmLimit: number | undefined, upperWarnLimit: number | undefined, lowerAlarmLimit: number|undefined, lowerWarnLimit: number|undefined): ReactElement {

	let status = HealthIndexStatus.Ok;
	//let visibleStatus: string = "visible";
	if (armValue !== null) {
		if (upperAlarmLimit !== undefined && armValue > upperAlarmLimit) {
			status = HealthIndexStatus.Alarm;
		} else if (upperWarnLimit !== undefined && armValue > upperWarnLimit) {
			status = HealthIndexStatus.Warning;
		} else if (lowerAlarmLimit !== undefined && armValue < lowerAlarmLimit) {
			status = HealthIndexStatus.Alarm;
		} else if (lowerWarnLimit !== undefined && armValue < lowerWarnLimit) {
			status = HealthIndexStatus.Warning;
		}
	}

	let icon = HealthIndexStatusHelper.getIcon(status);//replace('/', '_');
	let color = AbbColorHelper.getRgbHexColor(HealthIndexStatusHelper.getColor(status));
	if (status === HealthIndexStatus.Ok) {
		return <></>
	}
	return <ABB.Icon
		name={icon}
		color={color}
		className="mr-1 align-middle"
		sizeClass='small'
	/>;

}
