import React, { ReactElement } from 'react'
import * as ABB from "@abb/abb-common-ux-react";

interface AlertBellProps {
    mobileVisble?: boolean;
    tabletVisible?: boolean;
    desktopVisible?:boolean;    
    toggled: boolean;
    onClick: () => void;
}

export default function AlertBell(props: AlertBellProps): ReactElement {
    
    let classes = "alert-bell "

    if (props) {
        classes += props.mobileVisble ? "d-block " : "d-none "; 
        classes += props.tabletVisible ? "d-md-block " : "d-md-none ";
        classes += props.desktopVisible ? "d-lg-block " : "d-lg-none ";
    }    

    return (
        <ABB.AppTopNaviItem
              text={""}
              className={classes + (props.toggled? "toggled" : "")}
              icon="abb/alarm-bell"
              onClick={props.onClick}
            />
    )
}
