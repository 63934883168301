import { BehaviorSubject } from "rxjs";
import * as Models from "../models";
import * as Enums from "../enums";
import * as Helpers from "../helpers";
import { apiService } from "./api";
import { devicesAndMetadata$ } from "./device";
import {
	eventsList$,
	eventValueMapping$,
	loadPollers$,
	motorPollers$,
	values$,
} from "./polling";
import { isCommissioningPhase$, isStartupPhase$ } from "../App/layout-state";
import { combineReadables } from "../observables/utils";
import { makePollingWritable } from '../utils/polling-subject';

export const isPublicConfigAvailable$ = new BehaviorSubject(false);
export const isPrivateConfigAvailable$ = new BehaviorSubject(false);

export const productionCompleted$ = new BehaviorSubject(true);
export const commissioningCompleted$ = new BehaviorSubject(true);

export const appConfig$ = new BehaviorSubject<Models.AppConfig | null>(null);
export const startPolling$ = new BehaviorSubject(false);

export const boardDate$ = makePollingWritable(null, { 
	dataProvider: () => apiService.getDate(),
	interval: 10000,
	auto: false,
});

function getProductName(): string {
	if (
		process.env.REACT_APP_PRODUCT === "vd4-evo" ||
		process.env.REACT_APP_PRODUCT === "vd4-af"
	) {
		return process.env.REACT_APP_PRODUCT;
	}
	throw new Error(`Unknown Product Name: ${process.env.REACT_APP_PRODUCT}`);
}

export const productName = getProductName();

export const startupAndCommissioningEnabled$ = combineReadables(
	[appConfig$],
	([appConfig]) => {
		return appConfig?.startupAndCommissioning ?? false;
	}
);

export async function loadPublicConfig(): Promise<void> {
	//costanti
	const [appConfig, , mappingResult] = await Promise.all([
		fetch(`./views/${process.env.REACT_APP_PRODUCT}/app.json`).then(
			(response) => response.json()
		),
		Helpers.LocalizationHelper.initAsync("./i18n/locale.{{ns}}.{{lng}}.json"),
		fetch(
			`./views/${process.env.REACT_APP_PRODUCT}/eventValueMapping.json`
		).then((response) => response.json()),
	]);

	eventValueMapping$.next(mappingResult);
	appConfig$.next(appConfig);
	isPublicConfigAvailable$.next(true);
}

export async function loadPrivateConfig() {
	if (!eventValueMapping$.getValue()) {
		throw new Error("missing eventMapping");
	}

	if (!appConfig$.getValue()) {
		throw new Error("missing appConfig");
	}

	const [valuesResponse] = await Promise.all([
		apiService.getValues(),
		devicesAndMetadata$.fetch(),
	]);
	values$.next(valuesResponse);
	if (valuesResponse.values?.nameplate?.commissioningDone !== undefined) {
		const commissioningDone =
			typeof valuesResponse.values?.nameplate?.commissioningDone !== "boolean"
				? Boolean(Number(valuesResponse.values.nameplate.commissioningDone))
				: valuesResponse.values.nameplate.commissioningDone;
		if (!commissioningDone) {
			isStartupPhase$.next(false);
			isCommissioningPhase$.next(true);
		} else {
			isStartupPhase$.next(false);
			isCommissioningPhase$.next(false);
		}
	}
	Promise.all([eventsList$.startPolling(), loadPollers$.startPolling(), motorPollers$.startPolling()]).then(
		() => {
			startPolling$.next(true);
		}
	);
	boardDate$.startPolling();
	isPrivateConfigAvailable$.next(true);
}

export interface CancellationToken {
	id: string;
	isCanceled: boolean;
}

export interface DataElement {
	section: Enums.AllSections;
	widgetType: Enums.WidgetType;
	prop$: BehaviorSubject<Models.WidgetProps>;
	apiData?: Models.IData;
	cancellationToken?: CancellationToken;
	update?: number;
	currentTimeout?: ReturnType<typeof setTimeout>;
}

export interface SectionDataElement {
	section: Enums.AllSections;
	token?: CancellationToken;
	dataElements: DataElement[];
}

export interface NotificationDataElement {
	id: string;
	title: string;
	status: Enums.HealthIndexStatus;
	text: string;
	date: Date | null;
	popupText: string[];
}

export interface NotificationDataElements {
	all: NotificationDataElement[];
}
