import * as ABB from "@abb/abb-common-ux-react";
import "@abb/abb-common-ux-react/styles.css";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { productName } from "../../services/config";

export interface LoginProps {
	onLogin?: (
		username: string | undefined,
		password: string | undefined,
		rememberMe: boolean
	) => void;
}

export function Login(props: LoginProps): ReactElement {
	const { t } = useTranslation();

	return (
		<ABB.LoginScreen
			productName={t(productName)}
			id="appLoginScreen"
			onLogin={props.onLogin}
		>
			<div>
				{t(
					"This is a private system. Do not attempt to logon unless you are an authorized user. Any authorized or unauthorized access and use may be monitored and can result in criminal or civil prosecution under applicable law. By logging in, you agree to our"
				)}
				<ABB.Link target="_blank" href="https://www.abb.com">
					{" "}
					{t("privacy policy")}
				</ABB.Link>{" "}
				{t("and")}{" "}
				<ABB.Link target="_blank" href="https://www.abb.com">
					{t("terms of service")}
				</ABB.Link>
				.
			</div>
		</ABB.LoginScreen>
	);
}
