import { BehaviorSubject } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { startPolling$ } from '../config';
import * as Enums from "../../enums";
import { loadFamilyStatus } from './family-status';
import { makePollingWritable, PollingWritable } from '../../utils/polling-subject';
import { loadSectionStatusData } from './section-status';
import { loadSensorsOverviewData } from './sensors-overview';
import { loadAlertsData } from './alerts';
import { loadSystemInfoData } from './system-info';
import { loadGaugeInfoData, loadGaugesData } from './gauges';
import { loadWaveformChartData } from './chart/waveform';
import { loadSingleLineChartData } from './chart/single-line';
import { loadMultiLineChartData, multiLineChartTimeScale$ } from './chart/multiline';
import { loadBarChartData, loadBarChartWithChartTooltipData } from './chart/bar';
import { getPhaseSensorChartDataList, loadPhaseSensorData, PhaseSensorPropsConfig } from './phase-sensor';
import { loadMechanicalSensorData } from './mechanical-sensor';
import { ValueProp, WidgetConfig } from '../../pages/DynamicPage';
import { ApiIdentityItem, ChartDataItem, ChartDataList, ChartFilterWidgetProps, ChartScaleType, LastUpdateType, WaveformChartDataList, WidgetProps } from '../../models';
import { Load, WidgetType } from '../../enums';
import { ChartFilterPropsConfig, chartFilters$, ChartPropsConfig } from './chart';
import { Readable } from '../../observables/types';
import { combineReadables, updateBehaviorSubject } from '../../observables/utils';
import { boardFirmware$, boardNetwork$, connectionStatus$ } from '.';
import { user$ } from '../user';
import { PageComponents, PageConfig } from '../../pages/PageComponentSelector';
import { PageProps, PhaseSensorSectionProps, PhaseSensorSectionPropsConfig } from '../page';
import { DateTimeFormatHelper, LoadHelper, LocalizationHelper } from '../../helpers';
import { MultiLineChartProps } from '../../widgets';
import { loadCBStatus } from './cb-status';
import { loadInputStatus } from './input-status';
import { loadCumulatedWorkload } from './cumulated-workload';
import { loadHardwareIntegrity } from './hardware-integrity';
import { loadHealthIndexProgress } from './health-index-progress';
import { loadSyncStatus } from './sync-status';
import { loadMotorUnit } from './motor-unit';
import { loadCapacitorStatus } from './capacitor-status';
import { loadActuationEvents } from './actuation-events';
import { loadBinaryInputDiagnostic } from './binary-input-diagnostic';
import { loadEventLogs } from './event-logs';
import { loadImageStatusWithOverlays } from './image-status-with-overlays';
import { loadStatusDetailsWithIcon } from './status-details-with-icon';
import { areInstalled, findDisconnected } from '../sensor';

export const widgetDescriptorsById$ = new BehaviorSubject<{
	[id: string]: {
		props$: PollingWritable<unknown>|Readable<unknown>,
		component: WidgetType,
		filterRef?: string,
	}|undefined
}>({});

export const filterDescriptorByFilterRef$ = new BehaviorSubject<{
	[ref: string]: {
		props$: BehaviorSubject<ChartFilterPropsConfig>,
	}|undefined
}>({});

export const pageDescriptorsById$ = new BehaviorSubject<{
	[id: string]: {
		props$: PollingWritable<unknown>|Readable<unknown>,
		filterRef?: string,
		component: PageComponents,
	}
}>({});

export const constantChartData$ = new BehaviorSubject<{
	sample: { [name: string]: ChartDataList },
	waveform: { [name: string]: WaveformChartDataList },
}>({ sample: {}, waveform: {} });

export function getConstantChartData(api: ApiIdentityItem): ChartDataList|WaveformChartDataList|null {
	const value = constantChartData$.getValue()[api.type as 'sample'|'waveform']?.[(api.role ?? '') + (api.position ?? 0) + (api.name ?? '') + (api.operation ?? '')];
	if (value) {
		return value;
	}
	return null;
}

export function setConstantChartData(api: ApiIdentityItem, data: ChartDataList|WaveformChartDataList): void {
	const current = constantChartData$.getValue();
	current[api.type as 'sample'|'waveform'][(api.role ?? '') + (api.position ?? 0) + (api.name ?? '') + (api.operation ?? '')] = data;
	constantChartData$.next(current);		
}

export function getWidgetDescriptorId(config: WidgetConfig): string {
	return `${config.component}_${config.pollingInterval || 'interval'}_${config.tag || 'tag'}_${JSON.stringify(config.propsConfig)}`;
}

export function getPageDescriptorId(config: PageConfig): string {
	return `${config.component}_${JSON.stringify(config.propsConfig)}`;
}

export function load(config: WidgetConfig, props: WidgetProps): void {
	if (!startPolling$.getValue()) {
		return;
	}

	if ((config.propsConfig?.disabled as ValueProp)?.value) {
		return;
	}

	try {
		const descriptorId = getWidgetDescriptorId(config);
		const widgetDescriptorsById = widgetDescriptorsById$.getValue();
		if (widgetDescriptorsById[descriptorId]) {
			return;
		}
		let props$: PollingWritable<any> | Readable<any> | undefined;
		let filterRef;
		switch (config.component) {

			case Enums.WidgetType.Status:
			case Enums.WidgetType.FamilyStatus:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadFamilyStatus(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.SectionStatus:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadSectionStatusData(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.SensorsOverview:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadSensorsOverviewData(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.Alerts:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadAlertsData(config, props),
						interval: config.pollingInterval,
					})
					break;
				}
			case Enums.WidgetType.SystemInfo:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadSystemInfoData(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.GaugeInfo:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadGaugeInfoData(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.Gauges:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadGaugesData(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.WaveformChart:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadWaveformChartData(config, props),
						interval: config.pollingInterval,
					});
					filterRef = (config.propsConfig as unknown as ChartPropsConfig).filterRef?.value;
					break;
				}
			case Enums.WidgetType.SingleLineChart:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadSingleLineChartData(config, props),
						interval: config.pollingInterval,
					});
					filterRef = (config.propsConfig as unknown as ChartPropsConfig).filterRef?.value;
					break;
				}
			case Enums.WidgetType.MultiLineChart:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadMultiLineChartData(config, props),
						interval: config.pollingInterval ?? null,
					});
					filterRef = (config.propsConfig as unknown as ChartPropsConfig).filterRef?.value;
					break;
				}
			case Enums.WidgetType.BarChart:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadBarChartData(config, props),
						interval: config.pollingInterval,
					});
					filterRef = (config.propsConfig as unknown as ChartPropsConfig).filterRef?.value;
					break;
				}
			case Enums.WidgetType.BarChartWithChartTooltip:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadBarChartWithChartTooltipData(config, props),
						interval: config.pollingInterval,
					});
					filterRef = (config.propsConfig as unknown as ChartPropsConfig).filterRef?.value;
					break;
				}
			/* case Enums.WidgetType.BubbleChart:
				{
					newPoller = makePollingWritable(props, {
						dataProvider: () => loadBubbleChartData(config, props),
						interval: config.pollingInterval,
					});
					break;
				} */
			case Enums.WidgetType.PhaseSensor:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadPhaseSensorData(config, props),
						interval: config.pollingInterval,
					});
					filterRef = (config.propsConfig as unknown as ChartPropsConfig).filterRef?.value;
					break;
				}
			case Enums.WidgetType.MechanicalSensor:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadMechanicalSensorData(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.ConnectionSensorStatus:
				{
					props$ = combineReadables([connectionStatus$], ([connectionStatus]) => ({
						...props,
						status: connectionStatus,
					}));
					break;
				}
			case Enums.WidgetType.ImageStatus:
				{
					props$ = combineReadables([connectionStatus$], ([connectionStatus]) => ({
						...props,
						status: connectionStatus,
					}));
					break;
				}
			case Enums.WidgetType.Image:
				{
					props$ = new BehaviorSubject(props);
					break;
				}
			case Enums.WidgetType.Filter:
				{
					const filterProps = props as unknown as ChartFilterWidgetProps;
					props$ = new BehaviorSubject(filterProps);
					if (filterProps.filterRef) {
						updateBehaviorSubject(filterDescriptorByFilterRef$, (current) => ({
							...current,
							[filterProps.filterRef!]: {
								props$: props$! as unknown as BehaviorSubject<ChartFilterPropsConfig>,
							},
						}));
					}
					break;
				}
			case Enums.WidgetType.CBStatus:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadCBStatus(config, props),
						interval: config.pollingInterval
					});
					break;
				}
			case Enums.WidgetType.InputStatus:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadInputStatus(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.CumulatedWorkload:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadCumulatedWorkload(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.HardwareIntegrity:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadHardwareIntegrity(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.HealthIndexProgress:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadHealthIndexProgress(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.SyncStatus:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadSyncStatus(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.MotorUnit:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadMotorUnit(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.CapacitorStatus:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadCapacitorStatus(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.ImageStatusWithOverlays:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadImageStatusWithOverlays(config, props),
						interval: config.pollingInterval,
					})
					break;
				}
			case Enums.WidgetType.ActuationEvents:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadActuationEvents(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.SectionTitle:
				{
					props$ = new BehaviorSubject(props);
					break;
				}
			case Enums.WidgetType.BinaryInputDiagnostic:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadBinaryInputDiagnostic(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.EventLogs:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadEventLogs(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			case Enums.WidgetType.StatusDetailsWithIcon:
				{
					props$ = makePollingWritable(props, {
						dataProvider: () => loadStatusDetailsWithIcon(config, props),
						interval: config.pollingInterval,
					});
					break;
				}
			default:
				console.warn(`unknown component: ${config.component}`);
				break;
		}
		if (props$) {
			widgetDescriptorsById$.next({ ...widgetDescriptorsById, [descriptorId]: { 
				props$, 
				component: config.component,
				filterRef,
			} });
		}
	} catch (ex) {
		console.error(config.component, ex);
	}
}

function loadPage(config: PageConfig, props: PageProps): void {
	if (!startPolling$.getValue()) {
		return;
	}

	if ((config.propsConfig?.disabled as ValueProp)?.value) {
		return;
	}

	const descriptorId = getPageDescriptorId(config);
	const pageDescriptorsById = pageDescriptorsById$.getValue();
	if (pageDescriptorsById[descriptorId]) {
		return;
	}

	let newDescriptor: PollingWritable<any> | Readable<any> | undefined;
	let filterRef;
	switch (config.component) {
		case PageComponents.PhaseSensorSection: 
		{
			filterRef = (config.propsConfig as unknown as PhaseSensorSectionPropsConfig).filterRef?.value;
			newDescriptor = makePollingWritable(props, {
				dataProvider: async () => {
					const phaseSensorSectionProps = props as PhaseSensorSectionProps;
					const propsConfig = config.propsConfig as unknown as PhaseSensorSectionPropsConfig;
					const scaleType = chartFilters$.getValue()[propsConfig.filterRef.value] ?? propsConfig.defaultFilter.value;
					const data = await getPhaseSensorChartDataList(scaleType, propsConfig as unknown as PhaseSensorPropsConfig);
					const phaseSensor = {
						...phaseSensorSectionProps.phaseSensor
					};
					if (propsConfig.installedDevices) {
						phaseSensor.installed = areInstalled(propsConfig.installedDevices);
						if (phaseSensor.installed) {
							phaseSensor.disconnected = findDisconnected(propsConfig.installedDevices);
						} else {
							phaseSensor.disconnected = undefined;
						}
					}
					phaseSensor.currentArmValues = data.lastValue;
					phaseSensor.lastUpdate = DateTimeFormatHelper.toDate(data.lastValue.date_us);
					phaseSensor.lastUpdateType = LastUpdateType.Sample;
					phaseSensor.data = data.data;
					phaseSensor.fetching = false;
					phaseSensor.externalFetching = false;
					const dataTable = {
						...phaseSensorSectionProps.dataTable
					};
					const now = new Date();
					function getLoad(n?: number) {
            if (n === 0.5)
                return LoadHelper.getNameKey(Load.Low);
            if (n === 1)
                return LoadHelper.getNameKey(Load.Mid);
            if (n === 1.5)
                return LoadHelper.getNameKey(Load.High);
            return "-";
        	}
					function getRow(d: ChartDataItem, label: string, value?: number) {
						if (value !== undefined) {
							let level = undefined;
							if (
								phaseSensor.highAlarmKey 
								&& d[phaseSensor.highAlarmKey] !== undefined 
								&& value > d[phaseSensor.highAlarmKey]!
							) { // high alarm
								level = 'Alarm';
							} else if (
								phaseSensor.highWarnKey
								&& d[phaseSensor.highWarnKey] !== undefined
								&& value > d[phaseSensor.highWarnKey]!
							) { // high warning
								level = 'Warning';
							} else if (
								phaseSensor.lowAlarmKey
								&& d[phaseSensor.lowAlarmKey] !== undefined
								&& value < d[phaseSensor.lowAlarmKey]!
							) { // low alarm
								level = 'Alarm';
							} else if (
								phaseSensor.lowWarnKey
								&& d[phaseSensor.lowWarnKey] !== undefined
								&& value < d[phaseSensor.lowWarnKey]!
							) { // low warning
								level = 'Warning';
							}
							if (level === undefined) {
								return undefined;
							}
							return {
								arm: label,
								level,
								time: DateTimeFormatHelper.humanDiff(now, DateTimeFormatHelper.toDate(d.date_us)!, LocalizationHelper.getLanguage()),
								temperature: value + phaseSensor.unit,
								load: getLoad(d[phaseSensor.loadKey as keyof ChartDataItem]),
								timestamp: d.date,
								tempvalue: value
							}
						}
						return undefined;
					}
					dataTable.rows = data.data
						.flatMap((d) => phaseSensor.armMappings.map((a) => getRow(d, a.name, d[a.key as keyof ChartDataItem]))
						.filter((r) => r !== undefined));
					return {
						...phaseSensorSectionProps, phaseSensor, dataTable
					}
				}
			});
			break;
		}
		case PageComponents.Trends:
		case PageComponents.Documents:
			break;
		default:
			console.warn(`unknown page component: ${config.component}`);
			break;
	}
	if (newDescriptor) {
		pageDescriptorsById$.next({
			...pageDescriptorsById,
			[descriptorId]: { props$: newDescriptor, filterRef, component: config.component }
		})
	}
}

export function getDynamicProps(config: WidgetConfig, props: WidgetProps): PollingWritable<unknown> | Readable<unknown> | undefined {
	load(config, props);
	return widgetDescriptorsById$.getValue()[getWidgetDescriptorId(config)]?.props$;
}

export function getDynamicPageProps(config: PageConfig, props: PageProps): PollingWritable<unknown> | Readable<unknown> | undefined {
	loadPage(config, props);
	return pageDescriptorsById$.getValue()[getPageDescriptorId(config)]?.props$;
}

function getWidgetDescriptorsByComponent<T>(component: string): T[] {
	return Object.values(widgetDescriptorsById$.getValue())
		.filter((widgetDescriptor) => widgetDescriptor!.component === component)
		.map((widgetDescriptor) => widgetDescriptor!.props$) as unknown as T[];
}

function getDescriptorsByFilterRef<T = PollingWritable<unknown>|Readable<unknown>>(filterRef: string): T[] {
	return [...Object.values(widgetDescriptorsById$.getValue()), ...Object.values(pageDescriptorsById$.getValue())]
		.filter((descriptor) => descriptor!.filterRef === filterRef)
		.map((descriptor) => descriptor!.props$) as unknown as T[];
}

export function isPoller(object: unknown): object is PollingWritable<unknown> {
	return Boolean(object) && 'fetch' in (object as Partial<Pick<PollingWritable<unknown>, 'fetch'>>);
}

export function getPollerDescriptors(): PollingWritable<unknown>[] {
	return [...Object.values(widgetDescriptorsById$.getValue()), ...Object.values(pageDescriptorsById$.getValue())]
		.map((descriptor) => descriptor!.props$)
		.filter((props$) => isPoller(props$)) as unknown as PollingWritable<unknown>[];
}

export function resetData(): void {
	widgetDescriptorsById$.next({});
	pageDescriptorsById$.next({});
	filterDescriptorByFilterRef$.next({});
	constantChartData$.next({ sample: {}, waveform: {} });
}

export function init() {
	multiLineChartTimeScale$.subscribe((multiLineChartTimeScale) => {
		getWidgetDescriptorsByComponent<PollingWritable<MultiLineChartProps>>(Enums.WidgetType.MultiLineChart).forEach((props$) => {
			props$.next({
				...props$.getValue(),
				fetching: true,
			});
			props$.fetch(true).then(() => {
				props$.next({
					...props$.getValue(),
					fetching: false,
				});
			}, console.error);
		});
	});

	chartFilters$.pipe(startWith({} as {[key: string]: ChartScaleType}), pairwise()).subscribe(([previous, current]) => {
		Object.keys(current).filter((filterRef) => filterRef).forEach((filterRef) => {
			if (JSON.stringify(previous[filterRef]) !== JSON.stringify(current[filterRef])) {
				const filterDescriptor = filterDescriptorByFilterRef$.getValue()[filterRef];
				if (filterDescriptor) {
					updateBehaviorSubject(filterDescriptor.props$, (current) => ({
						...current,
						fetching: true,
					}))
				}
				Promise.all(getDescriptorsByFilterRef<PollingWritable<unknown>>(filterRef).map((props$) => {
					if (isPoller(props$)) {
						return props$.fetch(true);
					}
					return Promise.resolve();
				})).then(() => {
					if (filterDescriptor) {
						updateBehaviorSubject(filterDescriptor.props$, (current) => ({
							...current,
							fetching: false,
						}))
					}
				}, console.error);
			}
		});
	});

	user$.subscribe((user) => {
		if (user) {
			boardFirmware$.fetch();
			boardNetwork$.fetch();
		}
	});
}

export function refreshPollerById(pollerId: string) {
	const widget = widgetDescriptorsById$.getValue()[pollerId];
	if (!widget) {
		console.warn(`widget ${widget} not found`);
		return;
	}
	if (!isPoller(widget.props$)) {
		console.warn(`widget ${widget} is not a polling subject`);
		return;
	}
	return widget.props$.fetch(true);
}

export function getPollerFetchingStatus(pollerId: string) {
	const widget = widgetDescriptorsById$.getValue()[pollerId];
	if (!widget) {
		console.warn(`widget ${pollerId} not found`);
		throw new Error(`getPollerFetchingStatus | widget ${pollerId} not found`);
	}
	if (!isPoller(widget.props$)) {
		console.warn(`widget ${widget.component} is not a polling subject`);
		throw new Error(`getPollerFetchingStatus | widget ${widget.component} is not a polling subject`)
	}
	return widget.props$.fetching$;
}

export function getChartFilterValue(filterRef: string) {
	return chartFilters$.getValue()[filterRef];
}
