import * as Enums from '../enums/FileType';

export class FileTypeHelper{

    static FromMimeTypeToFileType(mime_type?: string) : Enums.FileType{
        switch (mime_type) {
            case "image/jpeg":
            case "image/bmp":
            case "image/gif":
            case "image/tiff":
            case "image/png":
                return Enums.FileType.Image;
            case ("application/msword"):
            case ("application/vnd.openxmlformats-officedocument.wordprocessingml.document"):
                return Enums.FileType.Word;
            case "application/pdf":
                return Enums.FileType.Pdf;
            case "application/vnd.ms-excel":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return Enums.FileType.Excel;
            default:
                return Enums.FileType.NA;
        }
    }
}