import React, { useCallback } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { useTranslation } from "react-i18next";
import { EnvHelper } from "../helpers";
import { useReadable } from "../observables/hooks";
import {
	aboutDialogIsShown$,
	alertBellToggled$,
	loginDialogIsShown$,
	userMenuOpen$,
} from "./layout-state";
import { user$ } from "../services/user";
import { TopBar } from "../components";
import { closeToast, fireNotification } from "../toasts";
import { handleLogout } from "./login";
import { updateBehaviorSubject } from "../observables/utils";
import { Role } from "../enums";
import { productName } from "../services/config";
import { selectedScenario$ } from '../services/demo';

export function AppHead(): React.ReactElement {
	const { t } = useTranslation();
	const userMenuOpen = useReadable(userMenuOpen$);
	const alertBellToggled = useReadable(alertBellToggled$);
	const userRole = useReadable(user$)?.role ?? Role.None;
	const toggleUserMenu = useCallback(
		(shouldOpen?: boolean, item?: any) => {
			if (item) {
				if (item.id === "about") {
					aboutDialogIsShown$.next(true);
				} else if (item.id === "preferences") {
					fireNotification({
						type: "banner",
						severity: "info",
						text: t("NoPreferencesAvailable"),
					});
				} else if (item.id === "logout") {
					fireNotification({
						type: "confirmation",
						severity: "warn",
						text: t("DoYouReallyWantToLogout"),
						buttons: [
							{
								text: "Yes",
								handler: (notificationId?: string) => {
									if (typeof notificationId === "string") {
										closeToast(notificationId);
									}
									handleLogout();
								},
							},
							{ text: "No", handler: closeToast },
						],
					});
				} else if (item.id === "login") {
					loginDialogIsShown$.next(true);
				} else if (item.id === "scenario") {
					selectedScenario$.next(null);
				}
			}
			updateBehaviorSubject(
				userMenuOpen$,
				(userMenuOpen) => shouldOpen ?? !userMenuOpen
			);
		},
		[t]
	);

	return (
		<>
			{EnvHelper.isDev && (
				<small style={{ position: "absolute", right: "10px" }}>
					{t("Application mode")}: <b>{process.env.NODE_ENV}</b>
				</small>
			)}
			<ABB.AbbBar productName={t(productName)} />

			<TopBar
				userMenuOpen={userMenuOpen}
				userRole={userRole}
				alertBellToggled={alertBellToggled}
				onAlertBellClick={() => {
					alertBellToggled$.next(!alertBellToggled);
				}}
				//Use simply {this.toggleUserMenu} will ride to an error
				onToggleUserMenuChange={(so?: boolean, i?: any) =>
					toggleUserMenu(so, i)
				}
			/>
		</>
	);
}
