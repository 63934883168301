import { useEffect, useState } from 'react';
import { Subscribable } from 'rxjs';
import { Readable } from './types';

export function useSubscribable<T>(observable: Subscribable<T>, defaultValue: T): T {
	const [value, setValue] = useState(defaultValue);
	useEffect(() => {
		let subscribed = true;
		const subscription = observable.subscribe((newValue) => {
			if (subscribed) { 
				setValue(newValue);
			}
		});
		return () => {
			subscribed = false;
			subscription.unsubscribe();
		};
	}, [observable, setValue]);

	return value;
}

export function useReadable<T>(readable: Readable<T>): T {
	return useSubscribable(readable, readable.getValue());
}
