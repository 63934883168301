import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigurationModalProps } from "./ConfigurationDialogSelector";
import * as ABB from "@abb/abb-common-ux-react";
import { useState } from "react";
import { Input } from "@abb/abb-common-ux-react";
import { useEffect } from "react";
import { useCallback } from "react";
import { fireNotification } from "../toasts";

export function CurrentConfigDialog(
	props: ConfigurationModalProps
): React.ReactElement {
	const { t } = useTranslation();
	type FormData = {
		transformationNumerator: string | null;
		transformationDivisor: string | null;
	};

	const [formData, setFormData] = useState<FormData>({
		transformationNumerator:
			props.defaultConfig &&
			"transformationRatio" in props.defaultConfig &&
			props.defaultConfig.transformationRatio
				? props.defaultConfig.transformationRatio
				: null,

		transformationDivisor: "1",
	});

	const [transformationNumeratorDirty, setTransformationNumeratorDirty] =
		useState(false);

	const [transformationDivisorDirty, setTransformationDivisorDirty] =
		useState(false);

	const validators: {
		[key in keyof FormData]: (v: string | null | undefined) => {
			valid: boolean;
			text?: string;
		};
	} = useMemo(
		() => ({
			transformationNumerator: (v) => {
				if (v === "" || v == null) {
					return {
						valid: false,
						text: t("Numerator is required"),
					};
				}
				if (Number.isNaN(Number(v))) {
					return {
						valid: false,
						text: t("Numerator must be an number"),
					};
				}

				return {
					valid: true,
				};
			},
			transformationDivisor: (v) => {
				if (v === "" || v == null) {
					return {
						valid: false,
						text: t("Divisor is required"),
					};
				}
				if (Number.isNaN(Number(v))) {
					return {
						valid: false,
						text: t("Divisor must be an number"),
					};
				}
				if (Number(v) === 0) {
					return {
						valid: false,
						text: t("Divisor must not be Zero"),
					};
				}
				return {
					valid: true,
				};
			},
		}),
		[t]
	);

	const [valid, setValid] = useState(false);

	const updateValidity = useCallback(() => {
		setValid(
			!Object.entries(validators).some(
				([key, validator]) => !validator(formData[key as keyof FormData]).valid
			)
		);
	}, [formData, setValid, validators]);

	useEffect(() => {
		updateValidity();
	}, [updateValidity]);

	return (
		<ABB.Dialog
			isOpen={props.isOpen}
			showCloseButton={false}
			closeOnEscape={true}
			closeOnLostFocus={false}
			dimBackground={true}
			title={props.title}
			standardButtonsOnBottom={[
				{
					text: t("Cancel"),
					type: "discreet-blue",
					handler: () => props.onClose(),
				},
				{
					text: t("Send"),
					type: "primary-blue",
					handler: () => {
						if (!valid) {
							fireNotification({
								text: t("Form data is not valid"),
								severity: "warn",
								type: "banner",
							});
							return;
						}
						if (!props.configuring) {
							props.onSubmit({
								transformationRatio:
									Number(formData.transformationNumerator) /
									Number(formData.transformationDivisor),
							});
						}
					},
				},
			]}
		>
			<div style={{ minHeight: 400, minWidth: 400 }}>
				<Input
					dataType="number"
					label={t("TransformationNumerator")}
					onValueChange={(value) => {
						setTransformationNumeratorDirty(true);
						setFormData({
							...formData,
							transformationNumerator: value,
						});
					}}
					value={formData.transformationNumerator ?? ""}
					validator={validators.transformationNumerator}
					showValidationBarWhenValid={false}
					showValidationIconWhenValid={false}
					showValidationBarWhenInvalid={transformationNumeratorDirty}
					showValidationIconWhenInvalid={transformationNumeratorDirty}
				/>
				<Input
					dataType="number"
					label={t("TransformationDivisor")}
					onValueChange={(value) => {
						setTransformationDivisorDirty(true);
						setFormData({
							...formData,
							transformationDivisor: value,
						});
					}}
					value={formData.transformationDivisor ?? ""}
					validator={validators.transformationDivisor}
					showValidationBarWhenValid={false}
					showValidationIconWhenValid={false}
					showValidationBarWhenInvalid={transformationDivisorDirty}
					showValidationIconWhenInvalid={transformationDivisorDirty}
				/>
			</div>
		</ABB.Dialog>
	);
}
