import { DependencyList, useEffect, useRef, useState } from 'react';

export function useComputed<T>(fn: () => T, deps: DependencyList): T {
	const [value, setValue] = useState<T>(fn());
	const isFirstRun = useRef(true);
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		setValue(fn());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFirstRun, setValue, ...deps]);

	return value;
}

export function useComputedWithCleanup<T>(fn: () => { value: T, cleanup: () => void }, deps: DependencyList): { value: T } | null {
	const [value, setValue] = useState<{ value: T } | null>(null);
	const isFirstRun = useRef(true);
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		const { value, cleanup } = fn();
		setValue({ value });

		return cleanup;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFirstRun, setValue, ...deps]);

	return value;
}
