import { BootstrapHelper } from "../../helpers";
import * as Enums from "../../enums";
import { SensorsOverviewItemProps, SensorsOverviewItem } from "./SensorsOverviewItem";
import { Card, InfoPopup } from "../../components";
import React, { ReactElement, useState } from "react";
import { WidgetProps } from "../../models"
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

export interface SensorsOverviewProps extends WidgetProps {
	sensors: SensorsOverviewItemProps[];
}

export function SensorsOverview(props: SensorsOverviewProps): ReactElement {

	const { t } = useTranslation();

	let itemDesktopCols: Enums.WidgetColumnSize = props.sensors.length <= 2 && props.sensors.length > 0 ? Enums.WidgetColumnSize.Large : Enums.WidgetColumnSize.Medium;

	//comment={props.enabled ? props.lastUpdate : ""}
	// let sensorOverviewChildren: JSX.Element[] = props.sensors.map(f => SensorsOverviewItem(f));

	const [grouped, setGrouped] = useState<SensorsOverviewItemProps[][]>([]);
	const [ungrouped, setUngrouped] = useState<SensorsOverviewItemProps[]>([]);
	useEffect(() => {
		const groupsByKey: Record<string, SensorsOverviewItemProps[]> = {};
		const ungrouped: SensorsOverviewItemProps[] = [];
		props.sensors.forEach((itemProps) => {
			if (itemProps.group !== undefined) {
				const key = itemProps.group || '-';
				if (!groupsByKey[key]) {
					groupsByKey[key] = [];
				}
				groupsByKey[key].push(itemProps);
			} else {
				ungrouped.push(itemProps);
			}
		});
		setGrouped(Object.values(groupsByKey));
		setUngrouped(ungrouped);
	}, [props.sensors, setGrouped, setUngrouped]);

	let mobileCols = BootstrapHelper.getColValue(12, props.mobileCols);
	let tabletCols = BootstrapHelper.getColValue(12, props.tabletCols);
	let desktopCols = BootstrapHelper.getColValue(12, props.desktopCols);

	if (!props.disabled) {
		return (
			<Card title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
				mobileCols={mobileCols} tabletCols={tabletCols} desktopCols={desktopCols} installed={props.installed}
				style={{ minHeight: "150px" }} disconnected={props.disconnected}
				className={"d-flex flex-column justify-content-between text-center"}>
				{props.sensors.length > 0 &&
					<div className="row" style={{ margin: "1px" }} >
						{grouped.map((group, groupIndex) => (
							<div key={"group-" + groupIndex} className={BootstrapHelper.getClassName(6, 6, itemDesktopCols)}>
								<div className="row">
								{group.map((f, i) => (
									<div  key={"SensorsOverviewItem" + groupIndex + '-' + i} className="col-12">
										<SensorsOverviewItem {...f} />
									</div>
								))}
								</div>
							</div>
						))}
						{ungrouped.map((f, i) => (
							<div key={"SensorsOverviewItem ungrouped-" + i} className={BootstrapHelper.getClassName(6, 6, itemDesktopCols)}>
								<SensorsOverviewItem {...f} />
							</div>
						))}
					</div>
				}
			</Card>);
	} else {

		return (
			<Card hideTitle={true} mobileCols={mobileCols} tabletCols={tabletCols} desktopCols={desktopCols}
				className={BootstrapHelper.getElementByStatus(props.disabled)} installed={props.installed} disconnected={props.disconnected}
				contentClassName="disabled-sensor-overview d-flex flex-row justify-content-start">
				<h6 className={'font-weight-bolder my-auto'}> {props.title}</h6>
				<InfoPopup info={t('SmartArmDisabled')} />
			</Card>
		);
	}
}
