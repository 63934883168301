import React, { ReactElement } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { Card } from '../../components';
import { WidgetProps } from '../../models';
import { useTranslation } from 'react-i18next';
import { HealthIndexStatusHelper } from '../../helpers';
import { HealthIndexStatus } from '../../enums';
import "./style.scss";
import { SectionLink } from '../../components/SectionLink/SectionLink';

export interface HealthIndexProgressProps extends WidgetProps {
	lowWarn?: number,
	lowAlarm?: number,
	min: number,
	max: number,
	value: number,
	discoverMoreLink: string,
	unit: string,
	warningMessage: string,
	alarmMessage: string,
}

export function HealthIndexProgress(props: HealthIndexProgressProps): ReactElement {
	const { t } = useTranslation();
	const status = (props.lowAlarm !== undefined && props.value < props.lowAlarm) ? 
		HealthIndexStatus.Alarm : (props.lowWarn !== undefined && props.value < props.lowWarn ?
		HealthIndexStatus.Warning :
		HealthIndexStatus.Ok);
	const abbColor = HealthIndexStatusHelper.getColor(status);
	return <Card 
		title={props.title} 
		comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
		installed={props.installed}
		disconnected={props.disconnected}
		mobileCols={props.mobileCols ?? 12} 
		desktopCols={props.desktopCols ?? 4} 
		tabletCols={props.tabletCols ?? 6}>
		<ABB.Gauge
			max={props.max}
			min={props.min}
			value={props.value}
			unit={props.unit}
			type="bar"
			lowAlarm={props.lowAlarm}
			lowWarn={props.lowWarn}
			label={t("Health Index")}
		/>
		{status === HealthIndexStatus.Ok &&
			<SectionLink 
				link={props.discoverMoreLink}
				linkName={t("Discover more")}
				showName={true}
				showIcon={true}
				className="mt-2"
			/>}
		{status !== HealthIndexStatus.Ok && <div className="mt-3 health-index-progress-alert d-flex justify-content-between">
			<div className="d-flex align-items-center">
				<ABB.Icon name={HealthIndexStatusHelper.getIcon(status)} color={abbColor} sizeClass="small" />
				<div className="ml-1">{status === HealthIndexStatus.Warning ? props.warningMessage : props.alarmMessage}</div>
			</div>
			<SectionLink
				link={props.discoverMoreLink}
				showName={false}
				showIcon={true}
			/>
		</div>}
	</Card>
}
