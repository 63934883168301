import React from 'react'
import { PhaseSensor, DataTable, WidgetSelector } from '../../widgets'
import { useTranslation } from 'react-i18next'
import { ChartTimeRange, Load, WidgetType } from '../../enums'
import { useLocation } from 'react-router-dom';
import { appConfig$ } from '../../services/config'
import { PhaseSensorSectionProps } from '../../services/page'
import { LastUpdateType } from '../../models';
import { ExportButton } from '../../components';
import { toCsv } from '../../utils/csv-converter';
import { LoadHelper } from '../../helpers';

export const PhaseSensorSection = (props: PhaseSensorSectionProps) => {
    const { t } = useTranslation();
		const location = useLocation();
		let defaultScaleType = ChartTimeRange.Day;

		const title = appConfig$.getValue()?.pages[location.pathname]?.title.i18n;

    //TEMP DEFAULT VALUE OF timeScale
    return (
        <>
            <div className="card-wrapper">
                <h3 className="ABB_CommonUX_Heading__root ABB_CommonUX_Heading__light ABB_CommonUX_Heading__withCursor">{title || ''}</h3>
            </div>

						<WidgetSelector
							widgetConfig={{
								component: WidgetType.Filter,
								propsConfig: {
									filterRef: { value: props.filter.filterRef },
									'default': { value: props.filter.defaultValue },
									enableLastOperations: { value: false },
									defaultTimeRange: { value: defaultScaleType }
								},
								grid: {
									desktop: 12,
									mobile: 12,
									tablet: 12,
								},
							}}
							/>

            <PhaseSensor title={title}
                mobileCols={12} tabletCols={12} desktopCols={12}
                lastUpdate={props.phaseSensor.lastUpdate}
								lastUpdateType={LastUpdateType.Event}
                filterRef={props.filter.filterRef}
                data={props.phaseSensor.data}
                currentLowerArmTemp={props.phaseSensor.currentLowerArmTemp}
                currentUpperArmTemp={props.phaseSensor.currentUpperArmTemp}
                browsable={props.phaseSensor.browsable}
								hideYAxis={props.phaseSensor.hideYAxis}
								showHorizontalGrid={props.phaseSensor.showHorizontalGrid}
								showUnitInYLabel={props.phaseSensor.showUnitInYLabel}
								unit={props.phaseSensor.unit}
								armMappings={props.phaseSensor.armMappings}
								currentArmValues={props.phaseSensor.currentArmValues}
								highAlarmKey={props.phaseSensor.highAlarmKey}
								highWarnKey={props.phaseSensor.highWarnKey}
								lowAlarmKey={props.phaseSensor.lowAlarmKey}
								lowWarnKey={props.phaseSensor.lowWarnKey}
								loadKey={props.phaseSensor.loadKey}
								disconnected={props.phaseSensor.disconnected}
								installed={props.phaseSensor.installed}
								yAxisLabel={props.phaseSensor.yAxisLabel}
								topRightContent={props.enableExport ? () => ExportButton({ onExport: async () => {
									const colNames = [
										'Timestamp', 
										...props.phaseSensor.armMappings.map((am) => am.name), 
										'High warn', 
										'High alarm', 
										'Low warn',
										'Low alarm',
										'Load'
									];
									const getLoad = (n?: number) => {
										if (n === 0.5) { return LoadHelper.getNameKey(Load.Low); }
										if (n === 1) { return LoadHelper.getNameKey(Load.Mid); }
										if (n === 1.5) { return LoadHelper.getNameKey(Load.High); }
										return "-";
									};
									const data = props.phaseSensor.loadKey 
										? props.phaseSensor.data.map((d) => ({ ...d, [props.phaseSensor.loadKey]: getLoad(d[props.phaseSensor.loadKey as keyof typeof d]) })) 
										: props.phaseSensor.data;
									const pathname = location.pathname.split('/');
									toCsv(
										`${new Date().getTime()}_${pathname[pathname.length - 1]}`,
										data,
										['date', 'value', 'value2', 'value3', 'value4', 'value5', 'value6', 'value7'].slice(0, colNames.length),
										colNames,
									);
								}, disabled: props.phaseSensor.data.length === 0 }) : undefined}
						/>

            <DataTable mobileCols={12} tabletCols={12} desktopCols={12}
                title={t('WarningAndAlerts')}
								installed={props.phaseSensor.installed}
								disconnected={props.phaseSensor.disconnected}
                lastUpdate={props.phaseSensor.lastUpdate}
								lastUpdateType={LastUpdateType.Event}
                columns={props.dataTable.columns}
                defaultSort={props.dataTable.defaultSort}
                rows={props.dataTable.rows} />
        </>
    )
}
