import { ReactElement } from 'react';
import { WidgetProps } from '../../models';

export function SectionTitle(props: WidgetProps): ReactElement {
	// TODO: add another parameter if it's necessary to show the widget as disabled when installed is false
	if (props.installed === false) {
		return <></>;
	}
	return <div className={`col-${props.mobileCols ?? 12} col-md-${props.tabletCols ?? 12} col-lg-${props.desktopCols ?? 12}`}>
		<h3 className="mt-3 mb-3">{props.title}</h3>
	</div>
}
