import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { Card, LastUpdate } from "../../components";
import { useTranslation } from "react-i18next";
import { HealthIndexStatus } from "../../enums";
import {
	SectionsHelper,
	HealthIndexStatusHelper,
	BootstrapHelper,
} from "../../helpers";
import "./style.scss";
import { WidgetProps } from "../../models";
import * as ABB from "@abb/abb-common-ux-react";

export interface MechanicalSensorProps extends WidgetProps {
	status: HealthIndexStatus;
	message: string;
	linkUrl?: string;
	subPageDisabled?: boolean;
}

export function MechanicalSensor(props: MechanicalSensorProps): ReactElement {
	const { t } = useTranslation();
	let hist = useHistory();

	let icon = HealthIndexStatusHelper.getIcon(props.status);
	let color = HealthIndexStatusHelper.getColor(props.status);

	return (
		<Card
			mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
			tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)}
			installed={props.installed}
			disconnected={props.disconnected}
			desktopCols={BootstrapHelper.getColValue(12, props.desktopCols)}
			hideTitle={true}
			className={"mechanical-sensor " + props.status}
			contentClassName="row d-flex mechanical-sensor-content"
		>
			<div className="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-start">
				<ABB.Icon
					sizeClass="medium"
					className="mx-2"
					name={icon}
					color={color}
				/>
				<span className="font-weight-bold">{props.title}</span>
			</div>
			<div className="col-12 col-lg-7 d-flex justify-content-center justify-content-lg-start">
				{/* <ABB.Icon className="mx-2" name="abb/information-circle-2" sizeClass="small"
                    color="gray" /> */}
				{/* <InfoPopup info="Lorem ipsum" /> */}
				{/* <span>{props.message}</span> */}
			</div>
			<div className="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-between">
				<span>
					{t("LastUpdate")}:{" "}
					{props.lastUpdate ? (
						typeof props.lastUpdate === "string" ? (
							props.lastUpdate
						) : (
							<LastUpdate lastUpdate={props.lastUpdate} />
						)
					) : (
						"N.A."
					)}
				</span>

				{!props.subPageDisabled && props.linkUrl && (
					<a href={SectionsHelper.createHrefByString(props.linkUrl, hist)}>
						<ABB.Icon
							className="mt-2"
							sizeClass="medium"
							name="abb/right-arrow"
							color="color-status-blue"
						/>
					</a>
				)}
			</div>
		</Card>
	);
}
