import * as Enums from '../../enums';
import React, { useState, ReactElement, useCallback } from 'react'
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from "../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import { useTranslation } from 'react-i18next';
import { ChartDateRange, ChartFilterWidgetProps } from '../../models';
import { chartFilters$ } from '../../services/polling/chart';
import { updateBehaviorSubject } from '../../observables/utils';
import { useEffect } from 'react';
import { ChartLastOperation, ChartScaleOptions, ChartTimeRange } from '../../enums';
import { useReadable } from '../../observables/hooks';

export function ExternalChartScaleSelector(props: ChartFilterWidgetProps): ReactElement {

    const { t } = useTranslation();
		const defaultValue = props.enableLastOperations 
		? Enums.ChartScaleOptions.LastOperation 
		: (props.enableDate ? Enums.ChartScaleOptions.Date : Enums.ChartScaleOptions.TimeRange);
    //enabledRadio
		const previouslySelected = useReadable(chartFilters$)[props.filterRef!] as ChartDateRange|ChartTimeRange|ChartLastOperation|undefined;
		let previouslySelectedType: ChartScaleOptions|undefined;
		if (previouslySelected && typeof previouslySelected === 'object') {
			if (props.enableDateRange) {
				previouslySelectedType = ChartScaleOptions.DateRange;
			} else if (props.enableDate) {
				previouslySelectedType = ChartScaleOptions.Date;
			}
		} else if (previouslySelected !== undefined) {
			if (Object.values(Enums.ChartLastOperation).includes(previouslySelected as any)) {
				previouslySelectedType = ChartScaleOptions.LastOperation;
			} else if (Object.values(Enums.ChartTimeRange).includes(previouslySelected as any)) {
				previouslySelectedType = ChartScaleOptions.TimeRange;
			}
		} else if (props.defaultValue !== undefined && typeof props.defaultValue === 'object') {
			if (props.enableDateRange) {
				previouslySelectedType = ChartScaleOptions.DateRange;
			} else if (props.enableDate) {
				previouslySelectedType = ChartScaleOptions.Date;
			}
		} else if (props.defaultValue !== undefined) {
			if (Object.values(Enums.ChartLastOperation).includes(props.defaultValue as any)) {
				previouslySelectedType = ChartScaleOptions.LastOperation;
			} else if (Object.values(Enums.ChartTimeRange).includes(props.defaultValue as any)) {
				previouslySelectedType = ChartScaleOptions.TimeRange;
			}
		}
		const defaultOpt = previouslySelectedType ?? defaultValue;
		
    const [enabledOpt, setEnabledOpt] = useState<Enums.ChartScaleOptions>(defaultOpt);
		const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(((defaultOpt === Enums.ChartScaleOptions.DateRange || defaultOpt === Enums.ChartScaleOptions.Date) && ((previouslySelected ?? props.defaultValue) as ChartDateRange|undefined)?.startDate) || null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(((defaultOpt === Enums.ChartScaleOptions.DateRange || defaultOpt === Enums.ChartScaleOptions.Date) && ((previouslySelected ?? props.defaultValue) as ChartDateRange|undefined)?.endDate) || null);
		const [selectedSingleDate, setSelectedSingleDate] = useState<Date | null>(defaultOpt === Enums.ChartScaleOptions.Date ? ((previouslySelected ?? props.defaultValue) as ChartDateRange|undefined)!.endDate : null);
		const [timeRange, setTimeRange] = useState<Enums.ChartTimeRange>(defaultOpt === Enums.ChartScaleOptions.TimeRange ? ((previouslySelected ?? props.defaultTimeRange ?? props.defaultValue ?? Enums.ChartTimeRange.Hour) as ChartTimeRange) : Enums.ChartTimeRange.Hour);
		const [operationNumber, setOperationNumber] = useState<Enums.ChartLastOperation>(defaultOpt === Enums.ChartScaleOptions.LastOperation ? ((previouslySelected ?? props.defaultValue) as ChartLastOperation) : Enums.ChartLastOperation.MIN);

    let desktop:number = 12;
    let tablet:number = 12;
    let mobile:number = 12;

		useEffect(() => {
			if (props.filterRef && props.defaultValue !== undefined && chartFilters$.getValue()[props.filterRef] === undefined) {
				updateBehaviorSubject(chartFilters$, (current) => ({ ...current, [props.filterRef!]: props.defaultValue }));
			}
		}, [props.filterRef, props.defaultValue]);

		const resetValue = useCallback((value) => {
			if (value === Enums.ChartScaleOptions.LastOperation) {
				if (props.onScaleTypeChange) {
					props.onScaleTypeChange(operationNumber);
				} else {
					updateBehaviorSubject(chartFilters$, (current) => ({ ...current, [props.filterRef!]: operationNumber }));
				}
			} else if (value === Enums.ChartScaleOptions.TimeRange) {
				if (props.onScaleTypeChange) {
					props.onScaleTypeChange(timeRange);
				} else {
					updateBehaviorSubject(chartFilters$, (current) => ({ ...current, [props.filterRef!]: timeRange }));
				}
			} else if (value === Enums.ChartScaleOptions.DateRange) {
				if (selectedStartDate && selectedEndDate) {
					if (props.onScaleTypeChange) {
						props.onScaleTypeChange({
							startDate: selectedStartDate,
							endDate: selectedEndDate,
						});
					} else {
						updateBehaviorSubject(chartFilters$, (current) => ({ ...current, [props.filterRef!]: {
							startDate: selectedStartDate, endDate: selectedEndDate
						} }));
					}
				}
			} else if (value === Enums.ChartScaleOptions.Date) {
				if (selectedSingleDate) {
					const startDate = new Date(selectedSingleDate);
					startDate.setHours(0, 0, 0, 0);
					const endDate = new Date(selectedSingleDate);
					endDate.setHours(23, 59, 59, 999);
					const selection = {
						startDate,
						endDate,
					};
					if (props.onScaleTypeChange) {
						props.onScaleTypeChange(selection)
					}
					updateBehaviorSubject(chartFilters$, (current) => {
						return { ...current, [props.filterRef!]: selection };
					});
				}
			}
		}, [props, selectedStartDate, selectedEndDate, operationNumber, timeRange, selectedSingleDate]);


		desktop = props.layout?.desktop ?? props.desktopCols ?? 12;
		tablet = props.layout?.tablet ?? props.tabletCols ?? 12;
		mobile = props.layout?.mobile ?? props.mobileCols ?? 12;
		// TODO: add another parameter if it's necessary to show the widget as disabled when installed is false
		if (props.installed === false) {
			return <></>;
		}
    return (
        <Card mobileCols={mobile} tabletCols={tablet} desktopCols={desktop} hideTitleStraightBar={true} hideTitle={true}
            progressBar={"bottom"}
						installed={props.installed}
						disconnected={props.disconnected}
            showProgressBar={props.fetching}>

            <div className="d-flex flex-row justify-content-start ExternalTimeScaleContainerMargins">

                {/*SECTION Last Operations*/}

                {props.enableLastOperations &&
                    <div className="ExternalTimeScaleMargins" >
                        <ABB.RadioGroup
                            orientation="horizontal"
                            sizeClass="small"
                            value={enabledOpt}
                            onChange={value => {
                                setEnabledOpt(value as Enums.ChartScaleOptions);
																resetValue(value);
                            }}>
                            <ABB.RadioOption text={t("Last Operations") + ':'} value={Enums.ChartScaleOptions.LastOperation} />
                        </ABB.RadioGroup>

                        <ABB.ToggleButtonGroup id="ChartLastOperations" className={"m-sm-auto m-md-0 noPadding"}
                            sizeClass={"small"}
                            multiselect={false}
                            selected={[lastOpToIndex(operationNumber)]}
                            onChange={value => {
                                if (value && value.length > 0) {
																	setOperationNumber(indexToLastOp(value[0]));
																	if (props.onScaleTypeChange) {
																		props.onScaleTypeChange(indexToLastOp(value[0]));
																	} else {
																		updateBehaviorSubject(chartFilters$, (current) => {
																			return { ...current, [props.filterRef!]: indexToLastOp(value[0]) };
																		});
																	}                                  
                                }
                            }}>
                            <ABB.ToggleButton style={{ minWidth: "65px" }} text={Enums.ChartLastOperation.MIN.toString()} disabled={enabledOpt !== Enums.ChartScaleOptions.LastOperation} />
                            <ABB.ToggleButton style={{ minWidth: "65px" }} text={Enums.ChartLastOperation.MID.toString()} disabled={enabledOpt !== Enums.ChartScaleOptions.LastOperation} />
                            <ABB.ToggleButton style={{ minWidth: "65px" }} text={Enums.ChartLastOperation.ALL.toString()} disabled={enabledOpt !== Enums.ChartScaleOptions.LastOperation} />
                        </ABB.ToggleButtonGroup>
                    </div>
                }

                {/*SECTION Last Time Range:*/}

								{props.enableTimeRange &&
                <div className="ExternalTimeScaleMargins">
                    <ABB.RadioGroup
                        orientation="horizontal"
                        sizeClass="small"
                        value={enabledOpt}
                        onChange={value => {
                            setEnabledOpt(value as Enums.ChartScaleOptions);
														resetValue(value);
                        }}>
                        <ABB.RadioOption text={t("Last Time Range") + ':'} value={Enums.ChartScaleOptions.TimeRange} />
                    </ABB.RadioGroup>


                    <ABB.ToggleButtonGroup id="ChartTimeInterval" className={"m-sm-auto m-md-0 noPadding"}
                        sizeClass={"small"}
                        multiselect={false}
                        selected={[timeRange]}
                        onChange={value => {
													if (value && value.length > 0) {
														setTimeRange(value[0]);
														if (props.onScaleTypeChange) {
															props.onScaleTypeChange(value[0]);
														} else {
															updateBehaviorSubject(chartFilters$, (current) => {
																return { ...current, [props.filterRef!]: value[0] };
															});
														}
													}
                        }}>
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1H")} disabled={enabledOpt !== Enums.ChartScaleOptions.TimeRange} />
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1D")} disabled={enabledOpt !== Enums.ChartScaleOptions.TimeRange} />
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1W")} disabled={enabledOpt !== Enums.ChartScaleOptions.TimeRange} />
                        <ABB.ToggleButton style={{ minWidth: "65px" }} text={t("1M")} disabled={enabledOpt !== Enums.ChartScaleOptions.TimeRange} />
                    </ABB.ToggleButtonGroup>
                </div>}

                {/*SECTION DatePicker*/}

                {props.enableDateRange && <div className="DatePickerContainer" >
                    <ABB.RadioGroup
                        orientation="horizontal"
                        sizeClass="small"
                        value={enabledOpt}
                        onChange={value => {
                            setEnabledOpt(value as Enums.ChartScaleOptions);
														resetValue(value);
                        }}>
                        <ABB.RadioOption text={t("Date Range") + ":"} value={Enums.ChartScaleOptions.DateRange} />
                    </ABB.RadioGroup>

                    <div className="d-flex flex-row" style={{ flexWrap: "wrap" }}>
                        <div className="ExternalTimeScaleMargins">
                            <DatePicker
                                selected={selectedStartDate}
                                onChange={date => {
																	setSelectedStartDate(date);
																	const endDate = legalInterval(date, selectedEndDate, false);
																	setSelectedEndDate(endDate);
																	if (date && endDate) {
																		const selection = {
																			startDate: date,
																			endDate,
																		};
																		if (props.onScaleTypeChange) {
																			props.onScaleTypeChange(selection)
																		}
																		updateBehaviorSubject(chartFilters$, (current) => {
																			return { ...current, [props.filterRef!]: selection };
																		});
																	}
                                }}
                                selectsStart
                                startDate={selectedStartDate}
                                dateFormat="MM/dd/yyyy"
                                disabled={enabledOpt !== Enums.ChartScaleOptions.DateRange}
                                className={"m-sm-auto m-md-0"}
                                placeholderText={t("From Date") + ":"}
                                maxDate={new Date()}


                            />
                        </div>
                        <div className="ExternalTimeScaleMargins">
                            <DatePicker
                                selected={selectedEndDate}
                                onChange={
                                    date => {
																			let startDate: Date | null = null;
																			if (date) {
																				date.setHours(23, 59, 59);
																				startDate = legalInterval(selectedStartDate, date, true);
																				setSelectedStartDate(startDate);
																			}
																			setSelectedEndDate(date);
																			if (date && startDate) {
																				const selected = {
																					startDate,
																					endDate: date,
																				}
																				if (props.onScaleTypeChange) {
																					props.onScaleTypeChange(selected);
																				} else {
																					updateBehaviorSubject(chartFilters$, (current) => {
																						return { ...current, [props.filterRef!]: selected };
																					});
																				}
																			}
                                    }
                                }
                                selectsStart
                                startDate={selectedEndDate}
                                dateFormat="MM/dd/yyyy"
                                disabled={enabledOpt !== Enums.ChartScaleOptions.DateRange}
                                className={"m-sm-auto m-md-0"}
                                placeholderText={t("To Date") + ":"}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                </div>}

								{props.enableDate && <div className="d-flex flex-row align-items-center">
									<ABB.RadioGroup
                        orientation="horizontal"
                        sizeClass="small"
                        value={enabledOpt}
												className="label-without-margin"
                        onChange={value => {
                            setEnabledOpt(value as Enums.ChartScaleOptions);
														resetValue(value);
                        }}>
                        <ABB.RadioOption text={t("Date") + ":"} value={Enums.ChartScaleOptions.Date} />
                    </ABB.RadioGroup>
										<div className="ml-1 d-flex flex-row" style={{ flexWrap: "wrap" }}>
                        <div className="ExternalTimeScaleMargins">
                            <DatePicker
															selected={selectedSingleDate}
															onChange={date => {
																setSelectedSingleDate(date);
																if (date) {
																	const startDate = new Date(date);
																	startDate.setHours(0, 0, 0, 0);
																	const endDate = new Date(date);
																	endDate.setHours(23, 59, 59, 999);
																	const selection = {
																		startDate,
																		endDate,
																	};
																	if (props.onScaleTypeChange) {
																		props.onScaleTypeChange(selection)
																	}
																	updateBehaviorSubject(chartFilters$, (current) => {
																		return { ...current, [props.filterRef!]: selection };
																	});
																}
															}}
															dateFormat="MM/dd/yyyy"
															disabled={enabledOpt !== Enums.ChartScaleOptions.Date}
															className={"m-sm-auto m-md-0"}
															placeholderText={t("Date") + ":"}
															maxDate={new Date()}
                            />
                        </div>
                    </div>
								</div>}
            </div>
        </Card>)
}

export function legalInterval(start: Date | null, end: Date | null, returnStart: boolean) {
    let result: Date | null;

    if (start !== null && end !== null) {
        if (start < end) {
            returnStart ? result = start : result = end;
        }
        else {
            result = null;
        }
    }
    else {
        returnStart ? result = start : result = end;
    }

    return result;
}


function lastOpToIndex(value: Enums.ChartLastOperation): number {
    return value === Enums.ChartLastOperation.MIN ? 0 : (value === Enums.ChartLastOperation.MID ? 1 : 2);
}

function indexToLastOp(value: number): Enums.ChartLastOperation {
    return value === 0 ? Enums.ChartLastOperation.MIN : (value === 1 ? Enums.ChartLastOperation.MID : Enums.ChartLastOperation.ALL);
}
