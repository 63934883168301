import { BehaviorSubject } from 'rxjs';
import { chartFilters$, ChartPropsConfig, getChartDataList } from '.';
import { ChartTimeRange } from '../../../enums';
import { DateTimeFormatHelper } from '../../../helpers';
import { ApiIdentityItem, ChartLegendPosition, ChartScaleType, LastUpdateType } from '../../../models';
import { NamedSource, PathSource, WidgetConfig } from '../../../pages/DynamicPage';
import { MultiLineChartProps, SeriesType } from '../../../widgets';

export const multiLineChartTimeScale$ = new BehaviorSubject<ChartScaleType>(ChartTimeRange.Hour);

export async function loadMultiLineChartData(config: WidgetConfig, props: Partial<MultiLineChartProps>): Promise<MultiLineChartProps> {
	console.info("loadMultiLineChartData");
	const multiLineChart = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as MultiLineChartPropsConfig;
		const apis: ApiIdentityItem[] = propsConfig.data.map((apiItem) => apiItem.series.source);
		const chartFilters = chartFilters$.getValue();
		const selectedFilter = props.filterRef ? chartFilters[props.filterRef] : null;
		const scaleType = selectedFilter ?? props.scaleType ?? multiLineChartTimeScale$.getValue();
		const data = await getChartDataList(scaleType, undefined, ...apis);

		for (let index = 0; index < multiLineChart.dataInfo!.length; index++) {
			const dataItem = multiLineChart.dataInfo![index];
			if (data && data.lastValue) {
				let last = index === 0 ? data.lastValue.value
					: index === 1 ? data.lastValue.value2
						: data.lastValue.value3;
				if (last !== undefined) {
					dataItem.lastValue = last;
					dataItem.lastUpdate = new Date(data.lastValue.date);
				}
			}
		}
		multiLineChart.dataInfo = [...multiLineChart.dataInfo!];
		multiLineChart.data = data.data;
		multiLineChart.lastUpdate = DateTimeFormatHelper.toDate(data.lastValue.date_us);
		multiLineChart.lastUpdateType = LastUpdateType.Sample;

	} catch (e) {
		console.error("loadMultiLineChartData", e);
		multiLineChart.exception = e;
	} finally {
		multiLineChart.fetching = false;
	}
	return multiLineChart as MultiLineChartProps;
}

export interface MultiLineChartItemPropsConfig {

	series: {
		source: NamedSource,
	},
	lowWarn?: {
		source: PathSource,
	},
	lowAlarm?: {
		source: PathSource,
	},
	highWarn?: {
		source: PathSource,
	},
	highAlarm?: {
		source: PathSource,
	},
	name: {
		i18n: string,
	},
	color: {
		value: string,
	},
	connect?: {
		value: boolean
	}
}
export interface MultiLineChartPropsConfig extends ChartPropsConfig {
	data: MultiLineChartItemPropsConfig[],
	title: {
		i18n: string,
	},
	showFilter: {
		value: boolean,
	},
	legend: {
		value: ChartLegendPosition,
	},
	xAxisLabel?: {
		i18n: string,
	},
	yAxisLabel?: {
		i18n: string,
	},
	seriesType?: {
		value: SeriesType
	},
	image?: {
		value: string,
	},
	showGrid?: {
		value: boolean,
	},
	fillOnHover?: {
		value: boolean,
	},
	showThresholdValue?: {
		value: boolean,
	}
}
