import { ChartTimeRange } from "../../enums"
import * as Widgets from "../../widgets"
import { ChartLegendPosition, ChartDataItem, ChartScaleType } from "../../models"
import * as Helpers from '../../helpers'
import * as Models from '../../models'
import { MultiLineChartItemProps, ExternalChartScaleSelector } from '../../widgets'
import { dropdownElement, ExportButton } from '../../components'
import React, { useState, useEffect, useRef } from 'react'
import { apiService } from '../../services/api'
import { useReadable } from '../../observables/hooks'
import { devicesMetadata$, rawDevices$ } from '../../services/device'
import { useCallback } from 'react'
import { roundNumber } from '../../utils/number'
import { PageProps } from '../../services/page'
import './style.scss';
import { TableSelector } from './TableSelector';
import { DropdownSelector } from './DropdownSelector';
import { toCsv } from '../../utils/csv-converter'
import { BehaviorSubject } from 'rxjs'
import { useMountedRef } from '../../utils/hooks'


export interface TrendsProps extends PageProps {
	tableSelector: boolean
}



function alignForMultiLinePropsArray(input?: Widgets.MultiLineChartProps) {
	let result: Widgets.MultiLineChartProps[] = []
	if (input && input.data && input.data.length && input.dataInfo && input.dataInfo.length > 0) {

		for (let i = 0; i < 5; i++) {
			result.push(takeSingleMultiLineChartSerie(i < input.dataInfo.length ? input : undefined, i));
		}
	}
	else {
		for (let i = 0; i < 5; i++) {
			result.push(takeSingleMultiLineChartSerie(undefined, i));
		}
	}
	return result;
}

function takeSingleMultiLineChartSerie(input: Widgets.MultiLineChartProps | undefined, index: number) {
	let result: Widgets.MultiLineChartProps = initChartData();

	result.title = "MULTI ENABLED" + index;
	result.multipleYAxis = false;
	result.desktopCols = 12;
	result.tabletCols = 12;
	result.mobileCols = 12

	if (input && input.data && input.dataInfo && index <= input.dataInfo.length && input.dataInfo[index].name) {
		result.hideCard = false;
		result.scaleType = input.scaleType;

		let selector: "value" | "value2" | "value3" | "value4" | "value5";
		switch (index) {
			case 0:
				selector = "value";
				break;
			case 1:
				selector = "value2";
				break;
			case 2:
				selector = "value3";
				break;
			case 3:
				selector = "value4";
				break;
			default:
				selector = "value5";
				break;

		}
		//put correct series data on first value in the ChartDataItem series
		let tempDataArray: ChartDataItem[] = input.data.map((t) => {
			let res: ChartDataItem =
			{
				value: t[selector],
				value2: undefined,
				value3: undefined,
				value4: undefined,
				value5: undefined,
				date: t.date,
				date_us: t.date_us
			}
			return res;

		});
		result.data = tempDataArray;
		result.dataInfo = [];
		result.dataInfo.push(input.dataInfo[index]);
		result.title = result.dataInfo[0].name;
	}
	else {
		result.hideCard = true;
		var tmp = result.dataInfo[index];
		result.dataInfo = [];
		result.dataInfo.push(tmp);
	}
	return result;
}


const colors: string[] = ['#004480', "#704480", "#fcba03", "#de1717", "#99ff33"];
function initChartData(chartTitle?: string): Widgets.MultiLineChartProps {

	let result: Widgets.MultiLineChartProps =
	{
		title: chartTitle ? chartTitle : "MULTI ENABLED",
		scaleType: ChartTimeRange.Hour,
		legend: ChartLegendPosition.top,
		multipleYAxis: true,
		data: [],
		desktopCols: 12,
		tabletCols: 12,
		mobileCols: 12,
		dataInfo: [],
		fillOnHover: false,
		showUnitInYLabel: false,
	};
	let dataInfoMock: MultiLineChartItemProps[] = []
	colors.forEach((color, i) => {
		let mock: MultiLineChartItemProps = {
			unit: "",
			name: "",
			//showSerieMissingDataGap:i%2==0,
			color: color,
			lastValue: 0,
			lastUpdate: new Date()
		}
		dataInfoMock.push(mock)
	});
	result.dataInfo = dataInfoMock;
	return result;
}


/*
function createLoadData(data: ChartDataItem[]) {

	let map: any[] = [
		{ color: "#D7F7F3", value: 0.5 },
		{ color: "#7FD6C7", value: 1.0 },
		{ color: "#3CA191", value: 1.5 }];

	for (const d of data) {
		let index = Math.floor(Math.random() * 3);
		d.value5 = map[index].value;
		d.color = map[index].color;
	}

}
let data = Helpers.ChartHelper.mergeData(lowerArmData, upperArmData);
createLoadData(data);

let data1 = Helpers.ChartHelper.mergeData(lowerArmData, []);
createLoadData(data1);

let data2 = Helpers.ChartHelper.mergeData([], upperArmData);
createLoadData(data2);*/

//BubbleChart

interface modelIdsSamples {
	model: string,
	idsAndSamples: detailedAggregationDevice[]
}

interface detailedAggregationDevice {
	id: string,
	aggregation: Models.AggregationDevice
}



interface metaDataInfo {
	model: string,
	samples: string,
	description: string,
	unit?: string
}


export interface ReferenceMetaDataInfo {
	id: string,
	model: string,
	sample: string,
	description: string,
	index: number,
	unit?: string,
}

interface dateAndSampleRange {
	dateFrom: Date,
	dateTo: Date,
	numberOfSamples: number,
	useDate: boolean
}


interface partialChartData {
	chartDataList: Models.ChartDataList,
	multiLineChartItemProps: MultiLineChartItemProps[]
}

const baseMultichartProps = initChartData();

function getReferenceMetaDataInfo(metaData: Models.DevicesMetadataResponse, devices: Models.DeviceApiItem[]) {
	const modelSampleMapping: metaDataInfo[] = [];
	//mappo metadata con modello, tipo sample
	Object.entries(metaData.models).forEach(([key, value], i) => {
		if (value!.samples && value!.samples) {
			let k = key;
			Object.entries(value!.samples).forEach(([key, value]) => {
				//modelSampleMapping
				if (!value.overwrite) {
					modelSampleMapping.push({ model: k, samples: key, description: value.description, unit: value.unit });
				}
			})
		}
	});
	const res: ReferenceMetaDataInfo[] = [];
	devices.forEach((dev) => {
		let id = dev.device_id;
		let model = dev.model;
		modelSampleMapping.forEach((data, i) => {
			if (data.model === model) {
				let sample = data.samples;
				let description = data.description;
				let index = res.length + 1;//i;
				const unit = data.unit;
				res.push({ id, model, sample, description, index, unit })
			}
		});
	});
	return res;
}

const trendsStatus$ = new BehaviorSubject<{
	selectedItems: dropdownElement[],
	timeScale: ChartScaleType | undefined,
	devicesInfo: ReferenceMetaDataInfo[],
}>({
	timeScale: ChartTimeRange.Hour,
	selectedItems: [],
	devicesInfo: [],
});

export const Trends = (props: TrendsProps) => {
	//************************************ State Declarations ************************************/
	const trendsStatus = useReadable(trendsStatus$);
	//ExternalChartScaleSelector
	const [timeScale, setTimeScale] = React.useState<ChartScaleType | undefined>(trendsStatus.timeScale);
	const [fetchingDates, setFetchingDates] = useState(false);
	//DropDownMultiselect
	const [fetchingSelections, setFetchingSelections] = useState(false);
	const isFetching = useRef(false);
	const mountedRef = useMountedRef();
	const devices = useReadable(rawDevices$);

	const metaData = useReadable(devicesMetadata$);
	
	const [devicesInfo, setDevicesInfo] = useState<ReferenceMetaDataInfo[]>(trendsStatus.devicesInfo);
	//Elementi selezionati dal dropdown
	const [selectedItems, setSelectedItems] = useState<dropdownElement[]>(trendsStatus.selectedItems);
	const [maxElementsReached, setMaxElementsReached] = useState<boolean>(trendsStatus.selectedItems.length === 5);
	//response to api call
	// const [apiResponseAggregate, setapiResponseAggregate] = useState<Models.AggregationResponse>();
	//Props of multiline Chart
	const [multichartProp, setmultichartProp] = useState<Widgets.MultiLineChartProps>(baseMultichartProps)//(placeholderProps)
	//max chart series allowed
	const maxAllowedSelectedItems: number = 5;
	const [previousReferenceMetadata, setPreviousReferenceMetadata] = useState<ReferenceMetaDataInfo[]>([]);
	//state for draw multiple chart, one for every selectedItem
	const [, setMultiSingleMultiLineChart] = useState<Widgets.MultiLineChartProps[]>(alignForMultiLinePropsArray(multichartProp))
	
	//************************************ Functions Declarations ************************************/
	function getValueIndex(dev: ReferenceMetaDataInfo) {
		return dev.index;
		//return parseInt(''+dev.id+dev.index, 10)//v1
	}
	

	

	const getDateAndSampleRange = useCallback((): dateAndSampleRange => {
		let result: dateAndSampleRange =
		{
			dateTo: new Date(),
			dateFrom: new Date(),
			numberOfSamples: 60,
			useDate: true
		}
		if ((timeScale as Models.ChartDateRange).startDate) {
			const dateRange = timeScale as Models.ChartDateRange;
			result.dateFrom = dateRange.startDate;
			result.dateTo = dateRange.endDate;
		} else {
			switch (timeScale) {
				case ChartTimeRange.Hour:
				case ChartTimeRange.Day:
				case ChartTimeRange.Month:
				case ChartTimeRange.Week:
					result.dateFrom = Helpers.DateTimeFormatHelper.getTimeIntervalBeginningFromNow(timeScale as ChartTimeRange);
					break;
				//non gestito
				/* case ChartLastOperation.ALL:
				 case ChartLastOperation.MIN:
				 case ChartLastOperation.MID:
					 {
						 result.useDate = false;
					 }*/
				default:
					throw new Error("unhandled ChartScaleType:" + timeScale);
			}

		}
		return result;
	}, [timeScale]);

	const loadChartDataV2 = useCallback(() => {
		if (devicesInfo && metaData) {
			//let arrayOfPartialResult: partialResult[] = []
			//lista devices selezionati
			var selectedDevices: ReferenceMetaDataInfo[] = []

			selectedItems.forEach((val, i, selectedItem) => {
				var tmpObj = devicesInfo.find((dev, i, devicesInfo) => getValueIndex(dev) === val.value)
				if (tmpObj) {
					// let aggrDevices: Models.AggregationDevice = { events: [], samples: [tmpObj!.sample] }
					//let returnObj: partialResult = {id:tmpObj.id, model: tmpObj.model, sample: aggrDevices }
					//arrayOfPartialResult.push(returnObj);
					selectedDevices.push(tmpObj);
				}
			})

			const uniqueDevType = [...new Set(selectedDevices.map(item => item.model))];
			const selectedDevicesid = [...new Set(selectedDevices.map(item => item.id))];

			var partialRec = new Map<string, Record<string, Models.AggregationDevice>>();


			let el: modelIdsSamples[] = []

			uniqueDevType.forEach((curmodel) => el.push({ model: curmodel, idsAndSamples: [] }));


			el.forEach((element) => {
				//inserimento id + samples
				selectedDevicesid.forEach((currentId) => {

					var sameIdSelectedDev = selectedDevices.filter((pres) => pres.id === currentId)
					if (sameIdSelectedDev) {
						var sameIdsameModel = sameIdSelectedDev.filter((sisd) => sisd.model === element.model)
						if (sameIdsameModel && sameIdsameModel.length > 0) {
							let supportAggregationvar: Models.AggregationDevice = { events: [], samples: [] }
							sameIdsameModel.forEach((aggrData) => supportAggregationvar.samples.push(aggrData.sample))
							element.idsAndSamples.push({ id: currentId, aggregation: supportAggregationvar })
						}
					}
				})
			})


			el.forEach((entry) => {

				var initialRec = new Map<string, Models.AggregationDevice>();
				entry.idsAndSamples.forEach((idAndSamp) => {
					initialRec.set(idAndSamp.id, idAndSamp.aggregation);
				})

				partialRec.set(entry.model, Object.fromEntries(initialRec))
			});

			let finalResult: Record<string, Record<string, Models.AggregationDevice>> = Object.fromEntries(partialRec);



			let dataInfo: dateAndSampleRange = getDateAndSampleRange();

			if (dataInfo.useDate)
				return apiService.getDataAggregationIntervals(finalResult, dataInfo.dateFrom, dataInfo.dateTo, dataInfo.numberOfSamples);				
		}
		return Promise.resolve();
	}, [devicesInfo, getDateAndSampleRange, metaData, selectedItems]);

	const generateAggregateApiDevicesInput = useCallback((apiResponseAggregateInterval: Models.AggregationIntervalsResponse): partialChartData|undefined => {
		let res: Models.ChartDataItem[] = []
		let lastValue: Models.ChartDataItem = { date: 0, date_us: 0 };
		let chartPropArr: MultiLineChartItemProps[] = [];

		let colors: string[] = ['#004480', "#704480", "#fcba03", "#de1717", "#99ff33"]


		if (apiResponseAggregateInterval && metaData) {

			var selectedDevices: ReferenceMetaDataInfo[] = []
			selectedItems.forEach((val, i, selectedItem) => {
				var tobj = devicesInfo!.find((dev) => getValueIndex(dev) === val.value) //dev.index
				if (tobj)
					selectedDevices.push(tobj);
			})

			let funcs = [(i: Models.ChartDataItem, v: number) => { i.value = v; lastValue.value = v; lastValue.date_us = i.date_us; },
			(i: Models.ChartDataItem, v: number) => { i.value2 = v; lastValue.value2 = v; lastValue.date_us = i.date_us; },
			(i: Models.ChartDataItem, v: number) => { i.value3 = v; lastValue.value3 = v; lastValue.date_us = i.date_us; },
			(i: Models.ChartDataItem, v: number) => { i.value4 = v; lastValue.value4 = v; lastValue.date_us = i.date_us; },
			(i: Models.ChartDataItem, v: number) => { i.value5 = v; lastValue.value5 = v; lastValue.date_us = i.date_us; },
			(i: Models.ChartDataItem, v: number) => { throw new Error("getDataAggregationIntervals invalid apis length") }];

			var namedSamples = new Map<string, (number | undefined)[]>();

			let iterableDevices = Object.entries(apiResponseAggregateInterval.devices);
			// let devLength: number = iterableDevices.length;
			iterableDevices.forEach((itdev) => {
				Object.entries(itdev[1]).forEach((el) => {
					Object.entries(el[1].samples).forEach((sampleEl) => {
						namedSamples.set(`${sampleEl[0]} [${itdev[0]}-${el[0]}]`, sampleEl[1])
					})
				})
			})

			let iterator: number = 0;
			//if (namedSamples.size !== selectedDevices.length) { return; }
			namedSamples.forEach((element, key) => {
				let currentDeviceInfo = selectedDevices.find((sd) => `${sd.sample} [${sd.model}-${sd.id}]` === key)
				let UnitOfMeasure = metaData.models[currentDeviceInfo!.model]!.samples ? metaData.models[currentDeviceInfo!.model]!.samples![currentDeviceInfo!.sample].unit : undefined;

				let funcSetValue = funcs[iterator < funcs.length ? iterator : funcs.length - 1];
				let sample = element;
				for (let intervalIndex = 0; intervalIndex < apiResponseAggregateInterval.intervals.length; intervalIndex++) {
					const interval = apiResponseAggregateInterval.intervals[intervalIndex];
					const sampleValue = sample[intervalIndex];

					let item = res[intervalIndex];
					if (!item)
						item = res[intervalIndex] = { date: interval / 1000, date_us: interval };

					if (sampleValue !== undefined && sampleValue !== null) {
						funcSetValue(item, roundNumber(sampleValue));
					}
				}

				let mcip: MultiLineChartItemProps = { unit: UnitOfMeasure ? UnitOfMeasure : "", name: key, color: colors[iterator], lastValue: 100, lastUpdate: new Date() }
				chartPropArr.push(mcip);
				iterator = iterator + 1;
			});
		}

		let returnValue: partialChartData =
		{
			chartDataList: {
				data: res,
				lastValue: lastValue
			},
			multiLineChartItemProps: chartPropArr
		}

		return returnValue;
	}, [devicesInfo, metaData, selectedItems]);

	//************************************ UseEffect Declarations ************************************/
	
	useEffect(() => {
		if (devices && metaData && previousReferenceMetadata.length === 0) {
			const res = getReferenceMetaDataInfo(metaData, devices);
			setPreviousReferenceMetadata(res);
			setDevicesInfo(res);
		}
	}, [devices, metaData, previousReferenceMetadata]);

	useEffect(() => {
		if (isFetching.current) {
			return;
		}
		if (selectedItems && selectedItems.length > 0) {
			isFetching.current = true;
			setFetchingSelections(true);
			setFetchingDates(true);
			loadChartDataV2().then((apiResponseAggregateInterval) => {
				if (apiResponseAggregateInterval && mountedRef.current) {
					const result = generateAggregateApiDevicesInput(apiResponseAggregateInterval);
					if (!result) { return; }
					const newChartProp = { 
						...baseMultichartProps, 
						data: result.chartDataList.data, 
						dataInfo: result.multiLineChartItemProps,
						scaleType: timeScale,
					}
					setmultichartProp(newChartProp);
					setMultiSingleMultiLineChart(alignForMultiLinePropsArray(newChartProp));
				}
			})
			.finally(() => {
				if (mountedRef.current) {
					setFetchingSelections(false);
					setFetchingDates(false);
				}
				isFetching.current = false;
			});
		}
		else {
			//clean chart if no items are selected in the dropdown menù
			setmultichartProp({ ...baseMultichartProps, data: [], dataInfo: [] });
			setMultiSingleMultiLineChart(alignForMultiLinePropsArray())
		}

		if (selectedItems) {
			if (selectedItems.length === maxAllowedSelectedItems)
				setMaxElementsReached(true)
			else
				setMaxElementsReached(false)
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItems, loadChartDataV2, generateAggregateApiDevicesInput, timeScale]);

	useEffect(() => {
		trendsStatus$.next({
			selectedItems,
			timeScale,
			devicesInfo,
		})
	}, [selectedItems, timeScale, devicesInfo]);
	return (
		<>
			{props.tableSelector ?
				<div className="row"><ExternalChartScaleSelector
					key={"ExternalChartScaleSelector"}
					onScaleTypeChange={(scaleType) => setTimeScale(scaleType)}
					defaultValue={timeScale}
					enableLastOperations={false}
					fetching={fetchingDates}
					enableDate={false}
					enableDateRange={true}
					enableTimeRange={true}
					layout={{
						desktop: 12,
						tablet: 12,
						mobile: 12
					}} /></div> :
				<DropdownSelector
					defaultScaleType={timeScale}
					maxAllowedSelectedItems={maxAllowedSelectedItems}
					maxElementsReached={maxElementsReached}
					referenceMetadata={devicesInfo}
					selectedItems={selectedItems}
					fetchingDates={fetchingDates}
					fetchingSelections={fetchingSelections}
					onScaleTypeChange={setTimeScale}
					onSelectionChange={setSelectedItems} />
			}
			<div className="row">
				<Widgets.MultiLineChart {
					...{
						...multichartProp, 
						fetching: multichartProp.fetching || fetchingSelections || fetchingDates, 
						cardTopRightContent: props.tableSelector ? () => ExportButton({ onExport: async () => {
							const colNames = ['timestamp', ...selectedItems.map((s) => s.label)];
							toCsv(
								`${new Date().getTime()}_trends`,
								multichartProp.data,
								['date', 'value', 'value2', 'value3', 'value4', 'value5', 'value6', 'value7'].slice(0, colNames.length),
								colNames,
							);
						}, disabled: selectedItems.length === 0 }) : undefined
					}
				} />
			</div>
			{props.tableSelector && <TableSelector 
				colors={colors}
				onSelectionChange={setSelectedItems}
				referenceMetadata={devicesInfo}
				selectedItems={selectedItems}
				maxElementsReached={maxElementsReached}
			/>}
		</>
	)
}



