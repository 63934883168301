import React, { ReactElement } from "react";
import { WidgetProps } from "../../models"
import * as ABB from "@abb/abb-common-ux-react";
import { Card } from "../../components";
import { BootstrapHelper } from "../../helpers";

export function Placeholder(props: WidgetProps): ReactElement {

    return (
        <Card title={props.title}
            mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)}
            tabletCols={BootstrapHelper.getColValue(6, props.tabletCols)}
            desktopCols={BootstrapHelper.getColValue(4, props.desktopCols)}
            style={{ minHeight: "190px" }} hideTitleStraightBar={!props.title}
            contentClassName="d-flex flex-column align-items-center">

            {props.exception ?
                <ABB.WithTooltip>
                    <ABB.Icon sizeClass="large" color="color-status-red" name="abb/error-circle-1" />
                    <ABB.Tooltip style={{ maxWidth: "250px" }} children={props.exception.toString()} />
                </ABB.WithTooltip>
                :
                <ABB.Spinner color="blue" sizeClass="medium" />
            }
        </Card>
    );
}