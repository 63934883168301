import React, { ReactElement } from 'react'
import * as ABB from '@abb/abb-common-ux-react'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface LoadingOverlayProps {
    label: string,
    readyLabel?: string,
    visible: boolean
}

export function LoadingOverlay(props: LoadingOverlayProps): ReactElement {
    let { t } = useTranslation();

    let textReady = props.readyLabel ? props.readyLabel : `${t('Done')}!`

    return (
        <div className={`loading-overlay ${props.visible ? 'd-flex' : 'd-none'}`}>
            <div className="loading-container m-auto d-flex">
                <ABB.LoadingIndicator
                    className="m-auto"
                    type="bar"
                    determinate={false}
                    text={props.label}
                    textWhenReady={textReady}
                    color="red"
                    sizeClass="medium"
                />
            </div>
        </div>
    )
}
