import { useReadable } from '../observables/hooks';
import { useComputed } from '../react-extra/hooks';
import { AssetInformation, Documents, Landing, PhaseSensorSection, Settings, Temperature, Trends, UnderDev } from '../sections';
import { startPolling$ } from '../services/config';
import { getPageProps } from '../services/page';
import { getDynamicPageProps, isPoller } from '../services/polling/startup';
import { FireNotificationParams } from '../toasts';
import { Placeholder, PollerToProps, ReadableToProps } from '../widgets';
import { DynamicPage, PropsConfig, WidgetConfig } from './DynamicPage';

export enum PageComponents {
	AssetInformation = "AssetInformation",
	Documents = "Documents",
	Landing = "Landing",
	PhaseSensorSection = "PhaseSensorSection",
	Settings = "Settings",
	Temperature = "Temperature",
	Trends = "Trends",
	UnderDev = "UnderDev",
}

export interface PageConfig {
	component: PageComponents,
	widgetConfigs: WidgetConfig[],
	fireNotification: (data: FireNotificationParams) => void,
	propsConfig?: PropsConfig
}

const componentByPageType = {
	[PageComponents.AssetInformation]: AssetInformation,
	[PageComponents.Documents]: Documents,
	[PageComponents.Landing]: Landing,
	[PageComponents.PhaseSensorSection]: PhaseSensorSection,
	[PageComponents.Settings]: Settings,
	[PageComponents.Temperature]: Temperature,
	[PageComponents.Trends]: Trends,
	[PageComponents.UnderDev]: UnderDev,
}

export function PageComponentSelector(pageConfig: PageConfig) {
	const Page = componentByPageType[pageConfig.component] || DynamicPage;
	const startPolling = useReadable(startPolling$);
	const props = useComputed(() => startPolling ? getPageProps(pageConfig) : null, [pageConfig, startPolling]);
	if (!startPolling || !props) {
		return <Placeholder {...{ mobileCols: 12, desktopCols: 12, tabletCols: 12 }} />
	}
	if (!pageConfig.propsConfig) {
		return <Page {...(props as any)} />
	}
	const props$ = getDynamicPageProps(pageConfig, props);
	if (isPoller(props$)) {
		return (
			<PollerToProps readable={props$} defaultProps={props}>
				{(currentProps) => <Page {...(currentProps as any)}></Page>}
			</PollerToProps>
		);
	}
	return (
		<ReadableToProps readable={props$} defaultProps={props}>
			{(currentProps) => <Page {...(currentProps as any)}></Page>}
		</ReadableToProps>
	);
}
