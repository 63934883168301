import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { Document } from '../../models';
import { DateTimeFormatHelper } from '../../helpers';
import { DocumentType, Role } from '../../enums';
import { ContextMenu, ContextMenuVisibility } from '../../components/ContextMenu/ContextMenu';
import { useReadable } from '../../observables/hooks';
import { user$ } from '../../services/user';

interface RenameDialogProps {
	isOpen: boolean,
	onClose: () => void,
	document: Document,
	cb: (d: Document, title: string, description?: string) => Promise<boolean>,
}

export function RenameDialog({ isOpen, onClose, document, cb }: RenameDialogProps): ReactElement {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [isRenaming, setIsRenaming] = useState(false);
	const { t } = useTranslation();
	useEffect(() => {
		if (document.title) {
			setTitle(document.title);
		} else {
			setTitle("");
		}
		if (document.description) {
			setDescription(document.description);
		} else {
			setDescription("");
		}
	}, [document]);

	const rename = useCallback(async () => {
		setIsRenaming(true);
		const wasSuccessful = await cb(document, title, description);
		setIsRenaming(false);
		if (wasSuccessful) {
			onClose();
		}
	}, [title, onClose, document, description, cb]);
	
	return <ABB.Dialog
		isOpen={isOpen}
		onClose={onClose}
		title={t("RenameDocument")}
		dimBackground={true}
		showCloseButton={true}		
	>
		<ABB.Input 
			dataType='text'
			value={title}
			onValueChange={(newTitle) => setTitle(newTitle)}
			label={t("Title")}
			required={true}
		/>
		<ABB.Input
			dataType='text'
			value={description}
			onValueChange={(newDesc) => setDescription(newDesc)}
			label={t("Description")}
		/>
		<div className='text-center w-100 mt-4'>
			<ABB.Button disabled={!title} type="primary-blue" sizeClass='small' text={t("Save")} onClick={() => rename()} isLoading={isRenaming} />
		</div>
	</ABB.Dialog>
}
export interface DocumentTableProps {
	documents: Document[],
	type?: DocumentType,
	copyLinkCb: (d: Document) => void,
	deleteCb: (d: Document) => void,
	downloadCb: (d: Document) => void,
	renameCb: (d: Document, title: string, description?: string) => Promise<boolean>,
}

const colSizes: (number|'*')[] = ['*', 150, 200, 80, 100];

export function DocumentTable({ documents, type, copyLinkCb, deleteCb, downloadCb, renameCb }: DocumentTableProps): ReactElement {
	const { t } = useTranslation();
	const [sortOrder, setSortOrder] = useState<{ field: string, desc: boolean } | undefined>(undefined);
	const [renameDialogOpen, setRenameDialogOpen] = useState(false);
	const [currentDoc, setCurrentDoc] = useState<Document|null>(null);
	const userRole = useReadable(user$)?.role ?? Role.None;
	const columns = useMemo((): ABB.DatagridColumnDef[] => {
		return [
			{
				fieldKey: 'title',
				title: t("Title"),
				sortable: true,
				width: colSizes[0],
			},
			{
				fieldKey: 'lastModified',
				title: t("LastModified"),
				sortable: true,
				width: colSizes[1],
			},
			{
				fieldKey: 'folder',
				title: t("Folder"),
				sortable: true,
				width: colSizes[2],
			},
			{
				fieldKey: 'size',
				title: t("Size"),
				sortable: true,
				width: colSizes[3],
			},
			{
				fieldKey: 'actions',
				sortable: false,
				width: colSizes[4],
			}
		];
	}, [t]);
	const [data, setData] = useState<ABB.DatagridRow[]>([]);

	useEffect(() => {
		// TODO ask for last modified field to be added to documents
		setData(documents.filter((d) => !type || d.type === type).map((d, i) => ({
			fields: {
				title: <span style={{ display: 'inline-flex' }}>{d.title}{d.type === DocumentType.ABB && <ABB.WithTooltip>
					<span className='logo-wrapper'><ABB.Logo color='red' /></span>
					<ABB.Tooltip><span>{t("OfficialDocument")}</span></ABB.Tooltip>
				</ABB.WithTooltip>}</span>,
				lastModified: DateTimeFormatHelper.toDate(d.creation_date_us)?.toLocaleDateString(),
				folder: d.type,
				size: d.size,
				actions: <div className='d-flex'>
					<ABB.IconButton sizeClass='large'>
						<ABB.Icon name="abb/download" sizeClass='small' onClick={() => downloadCb(d)} />
					</ABB.IconButton>
					<ContextMenu buttonType="icon" buttonIcon='abb/menu-narrow'>
						<ContextMenuVisibility.Consumer>
							{({ setVisible }) => <div style={{ maxWidth: '150px' }}>
								{userRole !== Role.None && d.type === DocumentType.Uploaded && <ABB.Button className="w-100" text={t('Rename')}
									onClick={() => {
										setCurrentDoc(d);
										setRenameDialogOpen(true);
										setVisible(false)
									}}
									type="discreet-black"
									sizeClass="small" />}
								{(((userRole === Role.Admin || userRole === Role.ABB) && d.type !== DocumentType.ABB) 
									|| (userRole === Role.User && d.type === DocumentType.Uploaded)) 
									&& <ABB.Button className="w-100" text={t('Delete')}
										onClick={() => {
											deleteCb(d);
											setVisible(false);
										}}
										type="discreet-black"
										sizeClass="small" 
									/>
								}
								<ABB.Button className="w-100" text={t('Copy link')}
									onClick={() => {
										copyLinkCb(d);
										setVisible(false);
									}}
									type="discreet-black"
									sizeClass="small" />
							</div>}</ContextMenuVisibility.Consumer>
					</ContextMenu>
				</div>
			},
		})));
	}, [documents, type, t, copyLinkCb, deleteCb, downloadCb, userRole]);
	return <>
		<div style={{ width: '100%', overflowX: 'auto' }}>
			<div style={{ minWidth: '800px' }}>
				<ABB.Datagrid 
					columns={columns}
					data={data}
					enableSorting={true}
					sortOrder={sortOrder}
					onSort={(nso) => setSortOrder(nso)}
				/>
			</div>
		</div>
		{currentDoc && <RenameDialog 
			isOpen={renameDialogOpen}
			cb={renameCb} 
			onClose={() => setRenameDialogOpen(false)}
			document={currentDoc}
		/>}
	</>
}
