import { useTranslation } from 'react-i18next';
import { Card } from '../../components';
import { Role } from '../../enums';
import { useReadable } from '../../observables/hooks';
import { user$ } from '../../services/user';
import { UserChangePassword } from '../UserChangePassword/UserChangePassword';
import { UserList } from './UserList';

export const SettingsUsers: React.FC = () => {
	const { t } = useTranslation();
	const userRole = useReadable(user$)?.role ?? Role.None;
	let isAdmin = userRole === Role.Admin || userRole === Role.ABB;
	return <div className='row'>
		{isAdmin && <>
			<UserList />
		</>}
		<Card title={t("UserChangePassword")} desktopCols={12} mobileCols={12} tabletCols={12}>
			<UserChangePassword />
		</Card>
		</div>
}
