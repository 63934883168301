import React, { ReactElement } from 'react'
import { Card, UnknownIconWithTooltip } from '../../components'
import * as ABB from "@abb/abb-common-ux-react"
import * as Enums from "../../enums"
import * as Helpers from "../../helpers"
import { WidgetProps } from "../../models"
import "./style.scss"
import { BootstrapHelper } from '../../helpers'
import { useTranslation } from 'react-i18next'

export interface AlertsDataItemProps {
    label: string;
    value: number|null;
    unit: string;
    status: Enums.HealthIndexStatus|undefined;
}

export interface AlertsProps extends WidgetProps {
    data: AlertsDataItemProps[];
}

export function Alerts(props: AlertsProps): ReactElement {
	const { t } = useTranslation();
    return (
        <Card title={props.title} comment={{ lastUpdate: props.lastUpdate, lastUpdateType: props.lastUpdateType }}
            mobileCols={BootstrapHelper.getColValue(12, props.mobileCols)} 
            tabletCols={BootstrapHelper.getColValue(8, props.tabletCols)} 
            desktopCols={BootstrapHelper.getColValue(6, props.desktopCols)}
						installed={props.installed}
						disconnected={props.disconnected}
            contentClassName="d-flex flew-column justify-content-around alerts-widget">
            {props.data.map((d, i) =>
                <div key={"alert" + i} className="alert-value-box d-flex flex-row">
                    <div className="alert-value-content">
                        <div className="label">{d.label}</div>
                        <div className="value">{d.value ?? t("N/A")} {d.unit}</div>
                    </div>
                    {d.status && d.status !== Enums.HealthIndexStatus.Ok &&
                        <div className="alert-value-icon d-flex">
                            <ABB.Icon className="m-auto"
																sizeClass="medium"
                                name={Helpers.HealthIndexStatusHelper.getIcon(d.status)}
                                color={Helpers.HealthIndexStatusHelper.getColor(d.status)} />
                        </div>
                    }
										{!d.status && <div className='alert-value-icon d-flex'>
											<UnknownIconWithTooltip 
												size='medium'
												tooltipText={t('StatusEventNotFound')}
											/>
										</div>}
                </div>

            )}
        </Card>
    );
}
