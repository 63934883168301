//Uses React Dropzone
//https://react-dropzone.js.org/

import React, { ReactElement } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next';
import { Input } from '@abb/abb-common-ux-react';
import { useDropzone } from 'react-dropzone'

interface FileUploaderProps {
    multipleSelection?: boolean,
    accept?: string,//file_extension|audio/*|video/*|image/*|media_type
    className?: string,
    showFileName?: boolean,
    fileNameLabel?: string,
    onFileSelected?: (value: File[]) => void
}

export function FileUploader(props: FileUploaderProps): ReactElement {

    let { t } = useTranslation();

    /* const [file, setFile] = useState<File[] | null>(null); */

    /* const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        if (acceptedFiles as FileList && acceptedFiles[0]) {
            setFile(acceptedFiles[0]);
            if (props.onFileSelected) props.onFileSelected(acceptedFiles[0]);
        }
    }, []) */

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        accept: props.accept,
        multiple: props.multipleSelection,
        onDropAccepted: (files) => { if (props.onFileSelected) props.onFileSelected(files) },
    })


    let uploaderClasses = "file-uploader d-flex";
    if (isDragActive) {
        uploaderClasses += " file-uploader--dragging";
    }

    //NOTE: isDragReject is not working correctly so disable this part
    // if (isDragReject) {
    //     uploaderClasses += " file-uploader--rejecting";
    // }

    const fileName = acceptedFiles.length > 0 ? acceptedFiles.map(file => (
        file.name
    )).toString() : t('NoFileSelected');


    return (
        <div className="file-uploader-container d-flex flex-column">
            {props.showFileName &&
                <div className="file-name">
                    <Input
                        type="discreet"
                        dataType="text"
                        disabled={true}
                        label={props.fileNameLabel}
                        required={false}
                        value={fileName}
                    />
                </div>
            }

            <div className={uploaderClasses} {...getRootProps()}>
                <input {...getInputProps()} />
                {


                    isDragActive ? <p className="m-auto">{t('DropFileHere')}</p> :
                        <p className="m-auto" >{t('DragAndDropOrClickToUpload')}</p>
                }
            </div>
        </div>
    )
}
