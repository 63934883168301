//Implementation based on these sources
//https://reacttraining.com/react-router/web/guides/scroll-restoration
//https://stackoverflow.com/questions/1174863/javascript-scrollto-method-does-nothing/18573599#18573599

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// export function ScrollToTop() {
//     const { pathname } = useLocation();
//     const history = useHistory();

//     useEffect(() => {

//         //Trying to use this but not working, probably becaus of link handling
//         //https://github.com/ReactTraining/react-router/issues/2144#issuecomment-150939358
//         let action = history.action;

//         if (action === 'POP') {
//             return;
//         }
        
//         $('#appcontent').animate({ scrollTop: 0 }, 0);
//     }, [pathname]);

//     return null;
// }

export function ScrollToTop() {
    const { pathname } = useLocation();
    // const history = useHistory();    

    useEffect(() => {

        //Trying to use this but not working, because of HashRouter and links
        //https://github.com/ReactTraining/react-router/issues/2144#issuecomment-150939358
        // let action = history.action;

        // if (action === 'POP') {
        //     return;
        // }
        
        $('#appcontent').animate({ scrollTop: 0 }, 0);
    }, [pathname]);

    return null;
}

