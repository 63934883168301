import React from 'react';
import './style.scss';

export interface ScrollableProps {
	children: React.ReactElement|React.ReactElement[],
}

export function Scrollable({children}: ScrollableProps): React.ReactElement {
	return (
		<div className="scrollable">
			{children}
		</div>
	)
}
