import { BehaviorSubject, PartialObserver, Unsubscribable } from 'rxjs';
import { Readable, ValuesOfReadable } from './types';

export function updateBehaviorSubject<T>(behaviorSubject: BehaviorSubject<T>, fn: (currentValue: T) => T): void {
	const currentValue = behaviorSubject.getValue();
	behaviorSubject.next(fn(currentValue));
}

export async function updateBehaviorSubjectAsync<T>(behaviorSubject: BehaviorSubject<T>, fn: (currentValue: T) => Promise<T>): Promise<void> {
	const currentValue = behaviorSubject.getValue();
	behaviorSubject.next(await fn(currentValue));
}

export function combineReadables<B extends [Readable<any>, ...Readable<any>[]], TOut>(readables: B, combinator: (values: ValuesOfReadable<B>) => TOut): Readable<TOut> {
	const combineValues = () => combinator(readables.map((b) => b.getValue()) as ValuesOfReadable<B>);

	const derived$ = new BehaviorSubject(combineValues());
	const updateDerived = () => {
		const newValue = combineValues();
		if (newValue !== derived$.getValue()) {
			derived$.next(newValue);
		}
	};

	let subscriptions: Unsubscribable[] = [];
	const subscribeToReadables = () => {
		subscriptions = readables.map((r) => r.subscribe(updateDerived));
	};
	const unsubscribeFromReadables = () => {
		subscriptions.forEach((s) => s.unsubscribe());
	};

	let subscribers = 0;
	return {
		getValue: () => {
			if (subscribers === 0) {
				updateDerived();
			}
			return derived$.getValue();
		},
		subscribe: (subscriber) => {
			if (!subscriber) {
				return {
					unsubscribe: () => { },
				};
			} else {
				subscribers++;
				if (subscribers === 1) {
					subscribeToReadables();
				}

				const subscription = derived$.subscribe(subscriber as PartialObserver<TOut>);
				return {
					unsubscribe: () => {
						subscription.unsubscribe();
						subscribers--;
						if (subscribers === 0) {
							unsubscribeFromReadables();
						}
					}
				}
			}
		},
	};
}
