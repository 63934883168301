import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ABB from '@abb/abb-common-ux-react'
import { Document } from '../../models'
import { Role, DocumentType } from '../../enums';
import { DateTimeFormatHelper } from "../../../src/helpers/DateTimeFormatHelper"
import { DocumentHelper } from "../../helpers/DocumentHelper"
import { useReadable } from '../../observables/hooks';
import { user$ } from '../../services/user';
interface DocumentBoxProps {
    userRole: Role,
    document: Document,
    onDeleteRequired?: (doc: Document) => void;
    fireNotification: (data: any) => void;
    downloadDoc: (d: Document) => void;
		copyLinkCb: (d: Document) => void,
}

export function DocumentBox(props: DocumentBoxProps): ReactElement {

    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    // const [canDelete] = useState<boolean>(props.userRole === Role.Admin || (props.userRole === Role.User && props.document.type === DocumentType.Uploaded));
    const userRole = useReadable(user$)?.role ?? Role.None;
    let canDelete = ((userRole === Role.Admin || userRole === Role.ABB) && props.document.type !== DocumentType.ABB) || (userRole === Role.User && props.document.type === DocumentType.Uploaded);

    return (
        <div className="document-box d-flex flex-wrap">
            <div className="document-box-info">
                <p className="doc-title">{props.document.title}</p>
                <p>{props.document.description}</p>
                <p>{DocumentHelper.GetDocumentSizeFormatted(props.document)}</p>
            </div>
            <div className="document-box-date d-flex">
                <p className="my-auto">{DateTimeFormatHelper.toDate(props.document.creation_date_us)?.toLocaleDateString()}</p>
            </div>
            <div className="document-box-buttons d-flex justify-content-center">
                <ABB.Icon className="my-auto mx-2" sizeClass="small" name={props.document.getIcon()} color={props.document.getIconColor()} />

                {/* NOTE: This was the coolest solution, but Button cannot have a children. So cannot contain a Menu */}
                {/* <Button sizeClass="small" type="discreet-black"
                shape={"rounded"} icon="abb/download" href={document.url} download={true} /> */}

                <ABB.AppTopNaviItem className="small-navi-item my-auto" icon="abb/download" onClick={() => props.downloadDoc(props.document)} />

                <ABB.AppTopNaviItem className="small-navi-item my-auto" icon="abb/more" onClick={() => setMenuOpen(true)} >
                    {menuOpen && (
                        <ABB.Menu style={{ minWidth: "110px" }} alignToParent="left" onLostFocus={() => setMenuOpen(false)}>
                            <ABB.MenuItem text={t("Download")} itemId="download" onSelect={()=> props.downloadDoc(props.document)} />
                            {/* NOTE: cannot use conditional rendering inside of ABB.Menu */}
                            <ABB.MenuItem text={t('Delete')} disabled={!canDelete} itemId="delete" onSelect={(i) => {
                                if (props.onDeleteRequired) {
                                    props.onDeleteRequired(props.document);
                                }
                            }} />
                            <ABB.MenuItem text={t('Copy link')} itemId="copylink"
                                onSelect={(i) => {
                                    //TODO: check if url composition needed when API are ready
																		props.copyLinkCb(props.document);
                                    setMenuOpen(false);
                                }} />
                        </ABB.Menu>

                    )}
                </ABB.AppTopNaviItem>

            </div>
        </div>
    )

}
