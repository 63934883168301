import { BehaviorSubject } from "rxjs";
import {
	AvailabilityStatus,
	BinaryStatus,
	ConnectionStatus,
	HealthIndexStatus,
	Load,
	OpeningStatus,
} from "../../enums";
import { SyncAndPositionStatus } from "../../enums/SyncAndPositionStatus";
import { DateTimeFormatHelper, LocalizationHelper } from "../../helpers";
import * as Models from "../../models";
import { ApiIdentityItemType } from "../../models";
import { combineReadables } from "../../observables/utils";
import { AssetInformationData } from "../../sections";
import { getRandomInt, roundNumber } from "../../utils/number";
import { makePollingWritable } from "../../utils/polling-subject";
import { apiService } from "../api";
import {
	appConfig$,
	NotificationDataElement,
	NotificationDataElements,
} from "../config";
import { devices$, devicesAndMetadata$, devicesMetadata$ } from "../device";
import { token$ } from "../user";

export const eventsList$ = makePollingWritable(
	{ events: [] },
	{
		dataProvider: () =>
			token$.getValue()
				? apiService.getEventsList()
				: Promise.resolve({ events: [] }),
		interval: 59000,
		auto: false,
	}
);

export const loadPollers$ = makePollingWritable<TemperatureSamplesData[]>([], {
	dataProvider: async () => {
		const appConfig = appConfig$.getValue();
		if (!appConfig?.loadPollers || !appConfig.loadPollers.length) {
			return [];
		}
		const data = await getMultipleSamples(
			"number",
			appConfig.loadPollers.flatMap((p) => [
				...p.temperatureSamples.map((ts) => ({
					...ts,
					type: ApiIdentityItemType.sample,
				})),
				{ ...p.highAlarmSample, type: ApiIdentityItemType.sample },
				{ ...p.highWarnSample, type: ApiIdentityItemType.sample },
			])
		);
		let dataIndex = 0;
		let result = new Array(appConfig.loadPollers.length);
		for (
			let pollerIndex = 0;
			pollerIndex < appConfig.loadPollers.length;
			pollerIndex++
		) {
			const poller = appConfig.loadPollers[pollerIndex];
			const currentPollerData = {
				samples: [] as number[],
				highAlarm: null as number | null,
				highWarn: null as number | null,
				loadEvent: poller.loadEvent,
				timestampUs: 0,
			};
			currentPollerData.samples.push(
				...data
					.slice(dataIndex, (dataIndex += poller.temperatureSamples.length))
					.filter(({ value }) => value !== null)
					.map(({ value, timestamp_us }) => {
						if (timestamp_us > currentPollerData.timestampUs) {
							currentPollerData.timestampUs = timestamp_us;
						}
						return value!;
					})
			);
			if (poller.highAlarmSample) {
				currentPollerData.highAlarm = data[dataIndex++].value;
			}
			if (poller.highWarnSample) {
				currentPollerData.highWarn = data[dataIndex++].value;
			}
			result[pollerIndex] = currentPollerData;
		}
		return result;
	},
	interval: 59000,
	auto: false,
});

export const motorPollers$ = makePollingWritable<Record<string, number|null>>({}, {
	dataProvider: async () => {
		const appConfig = appConfig$.getValue();
		if (!appConfig?.motorPollers || !Object.keys(appConfig.motorPollers).length) {
			return {};
		}
		const data = await getMultipleSamples('number', Object.values(appConfig.motorPollers).map((pollerConfig) => ({
			...pollerConfig,
			type: ApiIdentityItemType.sample,
		})));
		return Object.keys(appConfig.motorPollers).reduce((result, pollerKey, i) => {
			result[pollerKey] = data[i].value;
			return result;
		}, {} as Record<string, number|null>);
	},
	interval: 59000,
	auto: false,
});

export const sampleLastUpdates$ = makePollingWritable(
	{} as Models.AggregationResponse,
	{
		dataProvider: () => {
			const devicesAndMetadata = devicesAndMetadata$.getValue();
			const metadata = devicesAndMetadata.metadata.models;
			const devices = devicesAndMetadata.devices;
			const mappedDevices: Record<
				string,
				Record<string, Models.AggregationDevice>
			> = {};
			Object.keys(metadata).forEach((name) => {
				const samples = Object.keys(metadata[name]?.samples ?? {}).map(
					(sample) => sample
				);
				mappedDevices[name] = devices
					.filter((d) => d.model === name)
					.reduce((mapped, device) => {
						mapped[device.device_id] = { samples, events: [] };
						return mapped;
					}, {} as Record<string, Models.AggregationDevice>);
			});
			return apiService.getDataAggregation(mappedDevices, 1);
		},
		interval: 51000,
		auto: false,
	}
);

const ignoredEvents: string[] = [];

const previousNotifications: NotificationDataElements = { all: [] };

export const connectionStatus$ = combineReadables(
	[eventsList$, devicesAndMetadata$],
	([_eventsList, { devices, metadata }]) => {
		const exportedDevices = devices
			.filter((d) =>
				Boolean(
					typeof d.exported === "string" ? Number(d.exported) : d.exported
				)
			)
			.filter((d) => d.status?.installed);
		const anyDisconnected = exportedDevices.some((d) => {
			if ("connection" in (metadata.models[d.model]?.events || {})) {
				try {
					const connectionEvent = getLastEventValue({
						name: "connection",
						device: {
							id: d.device_id,
							model: d.model,
						},
						type: ApiIdentityItemType.event,
					});
					return connectionEvent.mappedValue === ConnectionStatus.Disconnected;
				} catch (err) {
					return true;
				}
			}
			return false;
		});
		return anyDisconnected ? HealthIndexStatus.Alarm : HealthIndexStatus.Ok;
	}
);

export const notifications$ = combineReadables(
	[eventsList$, loadPollers$, motorPollers$],
	([eventsList, loadPollers, motorPollers]) => {
		const notifications: NotificationDataElement[] = [
			...eventsList.events
				.filter((event) => event.model !== "acu") // gli eventi di acu non vanno mostrati nel pannello delle notifiche
				.filter(
					(event) =>
						!ignoredEvents.some((iid) => iid === event.model + "_" + event.name)
				),
			...loadPollers,
		].reduce(
			(all, e) => {
				let event: Models.EventsBaseDataItem;
				if ("loadEvent" in e) {
					event = eventsList.events.find(
						(load) => load.name === e.loadEvent.name
					) ?? {
						device_id: e.loadEvent.id,
						name: e.loadEvent.name,
						model: e.loadEvent.model,
						state: -1,
						timestamp_us: e.timestampUs,
					};
					if (event.timestamp_us < e.timestampUs) {
						event.timestamp_us = e.timestampUs;
					}
				} else {
					event = e;
				}
				//Get status
				const id = event.model + "_" + event.name;
				let status: HealthIndexStatus = HealthIndexStatus.Ok;
				let descriptionKey = "";
				const getLoadStatus = (e: TemperatureSamplesData) => {
					if (e.highAlarm !== null && e.samples.some((s) => s > e.highAlarm!)) {
						return HealthIndexStatus.Alarm;
					}
					if (e.highWarn !== null && e.samples.some((s) => s > e.highWarn!)) {
						return HealthIndexStatus.Warning;
					}
					return HealthIndexStatus.Ok;
				};
				if (event.state > -1) {
					try {
						const mappedItem = getMappedEventState(
							event.name,
							event.state,
							event.model
						);

						descriptionKey =
							"notificationDescriptions:" + mappedItem.messageKey;

						//TODO: this switch is placed here to handle all statuses
						switch (mappedItem.value) {
							case Load.Low:
							case Load.Mid:
							case Load.High: {
								if ("loadEvent" in e) {
									status = getLoadStatus(e);
								} else {
									// Load Event is ignored
									return all;
								}
								break;
							}
							case HealthIndexStatus.Ok:
							case ConnectionStatus.Connected:
							case BinaryStatus.Ok:
							case AvailabilityStatus.Available:
							case OpeningStatus.Close:
							case SyncAndPositionStatus.OpenSync:
							case SyncAndPositionStatus.CloseSync:
								status = HealthIndexStatus.Ok;
								break;

							case HealthIndexStatus.Warning:
							case AvailabilityStatus.NotAvailable:
							case OpeningStatus.Open:
							case SyncAndPositionStatus.Trip:
								status = HealthIndexStatus.Warning;
								break;

							case HealthIndexStatus.Alarm:
							case BinaryStatus.Error:
							case SyncAndPositionStatus.OpenNotSync:
							case SyncAndPositionStatus.CloseNotSync:
								status = HealthIndexStatus.Alarm;
								break;

							case ConnectionStatus.Disconnected:
								/* if (wirelessSensors$.getValue().find(w => w.model === event.model && w.device_id === event.device_id) ||
								wiredSensors$.getValue().find(w => w.model === event.model && w.device_id === event.device_id))
								anyDisconnected = true; */
								status = HealthIndexStatus.Alarm;
								break;
							default:
								throw new Error("unhandled status." + mappedItem.value);
						}
					} catch (e) {
						ignoredEvents.push(id);
						console.warn("updateEventsList", e);
						return all;
					}
				} else {
					// Temperature sample without matching load event
					descriptionKey = "notificationDescriptions:UnknownLoad";
					status = getLoadStatus(e as TemperatureSamplesData);
				}
				let optionalEventDetail = "";

				if (event.name === "TSD_status") {
					try {
						const eventValue = getLastEventValue({
							type: ApiIdentityItemType.event,
							name: "position",
							role: "breaker",
						});

						if (eventValue.mappedValue === OpeningStatus.Close) {
							optionalEventDetail = "closing";
						} else {
							optionalEventDetail = "opening";
						}
					} catch (error) {
						console.warn("Breaker event not found", error);
					}
				} else if (event.name === "MCE_motor_charging_spring_time_status") {
					if ('motorChargingSpringTime' in motorPollers && motorPollers.motorChargingSpringTime != null) {
						const warningHigh = getValue({
							type: ApiIdentityItemType.value,
							path: [
								"ranges",
								"breaker",
								"MCE_motor_charging_spring_time",
								"warning",
								"high"
							]
						});
						const warningLow = getValue({
							type: ApiIdentityItemType.value,
							path: [
								"ranges",
								"breaker",
								"MCE_motor_charging_spring_time",
								"warning",
								"low"
							]
						});
						if (motorPollers.motorChargingSpringTime >= Number(warningHigh)) {
							optionalEventDetail = 'higher';
						} else if (motorPollers.motorChargingSpringTime <= Number(warningLow)) {
							optionalEventDetail = 'lower';
						}
					}
				}

				const eventKey = `${event.model}_${event.name}_${
					optionalEventDetail === "" ? "" : `${optionalEventDetail}_`
				}${status}`;

				descriptionKey = LocalizationHelper.keyExists(
					`notificationDescriptions:${eventKey}`
				)
					? `notificationDescriptions:${eventKey}`
					: descriptionKey;

				//Get description
				const description =
					LocalizationHelper.getTranslation(descriptionKey) ||
					devicesMetadata$.getValue().models[event.model]!.events![event.name]
						.description;

				const popupText = LocalizationHelper.keyExists(
					`notificationPopup:${eventKey}`
				)
					? (LocalizationHelper.getTranslation(
							`notificationPopup:${eventKey}`,
							true
					  ) as string[])
					: [];

				//Get date
				const date = DateTimeFormatHelper.toDate(event.timestamp_us || 1);
				//

				//Check if item exists
				let item = all.find((n) => n.id === id);
				if (!item) {
					//Get title
					const key =
						"notificationTitles:" +
						event.model +
						"_" +
						event.name +
						(optionalEventDetail === "" ? "" : `_${optionalEventDetail}`);
					const title = LocalizationHelper.getTranslation(key);
					const newItem: NotificationDataElement = {
						id,
						title,
						status,
						text: description,
						date,
						popupText,
					};
					return [...all, newItem];
				} else {
					//Update existing item
					item.status = status;
					item.text = description;
					item.date = date;
					item.popupText = popupText;
					return all;
				}
			},
			[...previousNotifications.all]
		);

		notifications.sort((e1, e2) => {
			if (e2.date && e1.date) {
				return e2.date.getTime() - e1.date.getTime();
			} else if (e2.date) {
				return 1;
			} else if (e1.date) {
				return -1;
			}
			return 0;
		});
		previousNotifications.all = notifications;
		return { all: notifications };
	}
);

// new BehaviorSubject<Models.EventsListResponse>({ events: [] });
export const eventValueMapping$ =
	new BehaviorSubject<Models.EventValueMappingConfig>({
		entities: {},
		mapping: {},
	});
const eventValueMappingMapPatch$ = new BehaviorSubject<
	Map<string, Map<string, string>>
>(new Map<string, Map<string, string>>());
export const eventValueMappingMap$ = combineReadables(
	[eventValueMapping$, eventValueMappingMapPatch$],
	([eventValueMapping, eventValueMappingMapPatch]) => {
		const baseMap = Object.keys(eventValueMapping.mapping).reduce(
			(eventValueMappingMap, key) => {
				const mappingItem = eventValueMapping.mapping[key];
				const map = Object.keys(mappingItem).reduce(
					(mapTmp, mappingItemKey) =>
						mapTmp.set(mappingItemKey, mappingItem[mappingItemKey]),
					new Map<string, string>()
				);
				return eventValueMappingMap.set(key, map);
			},
			new Map<string, Map<string, string>>()
		);
		eventValueMappingMapPatch.forEach((value, key) => {
			const mappingItem = baseMap.get(key) ?? new Map<string, string>();
			value.forEach((innerValue, innerKey) => {
				mappingItem.set(innerKey, innerValue);
			});
			baseMap.set(key, mappingItem);
		});
		return baseMap;
	}
);

export const eventValueMappingEntities$ = combineReadables(
	[eventValueMapping$],
	([eventValueMapping]) => {
		return eventValueMapping.entities;
	}
);

export const values$ = new BehaviorSubject<Models.ValuesResponse>({
	values: null,
});

export const isDevMode$ = combineReadables([values$], ([values]) => {
	return String(values.values?.developmentMode) === "1";
});

export const assetInfo$ = combineReadables([values$], ([values]) => {
	const nameplate = values.values?.nameplate;
	return {
		deviceType: nameplate?.deviceType,
		modelAndRatings: nameplate?.modelAndRatings,
		serialNumber: nameplate?.serialNumber,
		orderNumber: nameplate?.orderNumber,
		modelAndRatingsInfo: "Vacuum circuit-breaker",
		standard: nameplate?.standard,
		productionYear: nameplate?.productionYear,
		classification: nameplate?.classification,
		mass: nameplate?.mass,
		shortCircuitBreakingCurrent: nameplate?.shortCircuitBreakingCurrent,
		voltage: nameplate?.voltage,
		dCComponentOfRatedShortCircuit:
			nameplate?.DCComponentOfRatedShortCircuitBreakingCurrent,
		lightningImpulseWithstandVoltage:
			nameplate?.lightningImpulseWithstandVoltage,
		cableChargingBreakingCurrent: nameplate?.cableChargingBreakingCurrent,
		powerFrequencyWithstandVoltage: nameplate?.powerFrequencyWithstandVoltage,
		operatingSequence: nameplate?.operatingSequence,
		frequency: nameplate?.frequency,
		temperatureClass: nameplate?.temperatureClass,
		normalCurrent: nameplate?.normalCurrent,
		shuntReleaseOnVoltage: nameplate?.shuntReleaseOnVoltage,
		shortTimeWithstandCurrent: nameplate?.shortTimeWithstandCurrent,
		shuntReleaseOffVoltage: nameplate?.shuntReleaseOffVoltage,
		durationOfShortCircuit: nameplate?.shortCircuitDuration,
		chargingMotorVoltage: nameplate?.chargingMotorVoltage,
		installationDate: nameplate?.installationDateUs ? new Date(nameplate.installationDateUs / 1000) : null,
		manufacturingDate: nameplate?.manufacturingDateUs ? new Date(nameplate.manufacturingDateUs / 1000) : null,
		pgApplication: nameplate?.PGApplication,
		warrantyStatus: nameplate?.warrantyStatus,
		auxiliaryVoltage: nameplate?.auxiliaryVoltage,
		commissioningDone: nameplate?.commissioningDone,
	} as AssetInformationData;
});

export const boardFirmware$ = makePollingWritable(null, {
	dataProvider: () => apiService.getBoardFirmwareInfo(),
	auto: false,
	interval: null,
});

export const boardNetwork$ = makePollingWritable(null, {
	dataProvider: () => apiService.getBoardNetworkConfig(),
	auto: false,
	interval: null,
});

export function checkSample(caller: string, api: Models.ApiIdentityItem) {
	checkApiType(caller, api, Models.ApiIdentityItemType.sample);
	if (
		!api.device!.checked &&
		!devicesMetadata$.getValue().models[api.device!.model]?.samples?.[api.name!]
	) {
		throw new Error(
			`checkApiType | ${caller} | sample not found | ${api.role} | ${
				api.device!.model
			} | ${api.name}`
		);
	}
	api.device!.checked = true;
}

export function checkEvent(caller: string, api: Models.ApiIdentityItem) {
	checkApiType(caller, api, Models.ApiIdentityItemType.event);
	const event =
		devicesMetadata$.getValue().models[api.device!.model]?.events?.[api.name!];
	if (!event && api.name !== "ALL") {
		throw new Error(
			`checkApiType | ${caller} | events not found | ${api.device!.model} | ${
				api.name
			}`
		);
	}
	if (event && !api.states && api.name !== "ALL") {
		if (!event.states || Object.keys(event.states).length === 0)
			throw new Error(
				`checkApiType | ${caller} | event states not found (METADATA) | ${
					api.device!.model
				} | ${api.name}`
			);

		api.states = event.states;
	}
	api.device!.checked = true;
}

export function checkWaveform(caller: string, api: Models.ApiIdentityItem) {
	checkApiType(caller, api, Models.ApiIdentityItemType.waveform);
	if (!api.device!.checked) {
		if (!api.operation) {
			throw new Error(
				`checkApiType | ${caller} | operation is required for waveforms | ${
					api.role
				} | ${api.device!.model} | ${api.name}`
			);
		}
		if (
			!devicesMetadata$.getValue().models[api.device!.model]?.waveforms?.[
				api.name!
			]
		) {
			throw new Error(
				`checkApiType | ${caller} | waveform not found | ${api.role} | ${
					api.device!.model
				} | ${api.name}`
			);
		}
	}
	api.device!.checked = true;
}

function getDeviceIdentityFromRoleAndPosition(
	role: string,
	position = "0"
): Models.DeviceIdentity | undefined {
	let devices = devices$
		.getValue()
		.get(role)
		?.filter((d) => d.position === position);

	if (!devices || devices.length === 0) {
		return;
	}

	if (devices.length > 1) {
		throw new Error(
			`getDeviceIdentityFromRoleAndPosition | ${devices.length} devices found for role ${role} and position ${position} | ${devices}`
		);
	}

	return {
		id: devices[0].device_id,
		model: devices[0].model,
	};
}

function checkApiType(
	caller: string,
	api: Models.ApiIdentityItem,
	expectedType: Models.ApiIdentityItemType
) {
	if (api.type !== expectedType) {
		throw new Error(
			`checkApiType | ${caller} | expected: ${expectedType} | invalid api type: ${api.type}`
		);
	}

	if (!api.device && api.role) {
		api.device = getDeviceIdentityFromRoleAndPosition(api.role, api.position);
	}

	if (!api.device) {
		throw new Error(`checkApiType | ${caller} | device not found: ${api.role}`);
	}

	if (
		!api.device.checked &&
		!devicesMetadata$.getValue().models[api.device.model]
	) {
		throw new Error(
			`checkApiType | ${caller} | model not found | ${api.device.model}`
		);
	}
}

export function getMappedEventState(
	name: string,
	state: number,
	model: string,
	states?: Models.EventMetadataStates
): Models.EventValueMappingConfigItem {
	if (!states) {
		states = devicesMetadata$.getValue().models[model]!.events![name]?.states;
		if (!states || !Object.keys(states).length) {
			throw new Error(
				`getMappedEventState | event states not found (METADATA) | ${model} | ${name} | ${state}${
					states ? " | states.length === 0" : ""
				}`
			);
		}
	}

	if (apiService.isMock()) {
		state = Number(
			Object.keys(states)[getRandomInt(0, Object.keys(states).length)]
		);
	}

	let stringState: string = state.toString();

	if (!(stringState in states)) {
		throw new Error(
			`getMappedEventState | event state invalid range | ${model} | ${name} | ${state}`
		);
	}

	const eventValueMappingEntities = eventValueMappingEntities$.getValue();
	const eventValueMappingMap = eventValueMappingMap$.getValue();
	try {
		const mappingItemModel = eventValueMappingMap.get(model);
		if (!mappingItemModel) throw new Error("mappingItemModel not found");
		const mappingItemName = mappingItemModel.get(name);
		if (!mappingItemName) throw new Error("mappingItemName not found");

		const mappingItem = eventValueMappingEntities[mappingItemName][stringState];
		if (!mappingItem) throw new Error("mappingItem not found");

		return mappingItem;
	} catch (ex) {
		// auto mapping
		const sortedStates = Object.keys(states).sort().join(",");
		for (const entityKey of Object.keys(eventValueMappingEntities)) {
			const entity = eventValueMappingEntities[entityKey];
			const keys = Object.keys(entity);
			const values = Object.values(entity).map((e) => e.value);
			if (
				keys.length === Object.keys(states).length &&
				keys.find((k) => k === stringState) &&
				keys.sort().join(",") === sortedStates &&
				values.find((v) => v === states![state])
			) {
				const item =
					eventValueMappingMap.get(model) ?? new Map<string, string>();
				item.set(name, entityKey);
				eventValueMappingMapPatch$.next(
					eventValueMappingMapPatch$.getValue().set(model, item)
				);
				return entity[stringState];
			}
		}
		throw new Error(
			`getMappedEventState | event value not found (MAPPING) | ${model} | ${name} | ${stringState} | ${ex}`
		);
	}
}

export function getLastEventValue(api: Models.ApiIdentityItem): GenericValue {
	checkEvent("getLastEventValue", api);

	const events = eventsList$.getValue().events;
	const device = api.device!;

	let event = events.find(
		(e) =>
			e.model === device.model &&
			e.device_id === device.id &&
			e.name === api.name
	);

	if (!event) {
		if (apiService.isMock()) {
			event = {
				state: 0,
				timestamp_us: new Date().getTime() * 1000,
				device_id: "",
				name: "",
				model: "",
			};
		} else
			throw new Error(
				`getLastEventValue | event not found | ${api.role} | ${api.name}`
			);
	}

	const result = getMappedEventState(
		api.name!,
		event.state,
		api.device!.model,
		api.states
	);

	return {
		date: DateTimeFormatHelper.toDate(event.timestamp_us)!,
		rawValue: event.state,
		mappedValue: result.value || event.state.toString(),
		relatedEvents: event.related_events,
	};
}

export function getLastEventErrorDuration(api: Models.ApiIdentityItem): number {
	checkEvent("getLastEventErrorDuration", api);

	if (apiService.isMock()) {
		return getRandomInt(0, 1000 * 60 * 60 * 24);
	}
	const events = eventsList$.getValue().events;
	const device = api.device!;
	const states =
		devicesMetadata$.getValue().models[device.model!]!.events![api.name!]
			?.states ?? [];
	const errorEvent = events.find(
		(e) =>
			e.model === device.model &&
			e.device_id === device.id &&
			e.name === api.name &&
			states[e.state] === HealthIndexStatus.Alarm
	);
	if (errorEvent) {
		const nonErrorEvent = events.find(
			(e) =>
				e.model === device.model &&
				e.device_id === device.id &&
				e.name === api.name &&
				states[e.state] !== HealthIndexStatus.Alarm &&
				e.timestamp_us > errorEvent!.timestamp_us
		);
		if (nonErrorEvent) {
			return (nonErrorEvent.timestamp_us - errorEvent.timestamp_us) / 1000;
		}
		return new Date().getTime() - errorEvent.timestamp_us / 1000;
	}
	return 0;
}

export async function getSamples(
	api: Models.ApiIdentityItem,
	size: number,
	dateFrom?: Date,
	dateTo?: Date
): Promise<Models.ApiDataItem[] | undefined> {
	checkSample("getSamples", api);

	if (dateFrom && dateTo) {
		console.info(
			api.name +
				" - Date range: " +
				dateFrom.toLocaleString() +
				" - " +
				dateTo.toLocaleString()
		);
	}

	const resp = await apiService.getSample(
		api.device!.model,
		api.device!.id,
		api.name!,
		size,
		undefined,
		dateFrom,
		dateTo
	);

	return (
		resp?.samples?.map((sample) => ({
			...sample,
			value: sample.value != null ? roundNumber(sample.value) : 0,
		})) ?? undefined
	);
}

export async function getEvents(
	api: Models.ApiIdentityItem,
	size?: number,
	dateFrom?: Date,
	dateTo?: Date
): Promise<Models.EventsBaseDataItem[]> {
	checkEvent("getEvents", api);

	if (dateFrom && dateTo) {
		console.info(
			api.name +
				" - Date range: " +
				dateFrom.toLocaleString() +
				" - " +
				dateTo.toLocaleString()
		);
	}

	const resp = await apiService.getEvent(
		api.device!.model,
		api.device!.id,
		api.name!,
		size,
		undefined,
		dateFrom,
		dateTo
	);

	return resp?.events ?? [];
}

type TypeMap = {
	number: number;
	string: string;
	boolean: boolean;
	HealthIndexStatus: HealthIndexStatus;
};

export async function getGenericSamples<K extends keyof TypeMap>(
	type: K,
	api: Models.ApiIdentityItem,
	size: number,
	dateFrom?: Date,
	dateTo?: Date,
	boundaries?: { min?: number; max?: number }
): Promise<Models.GenericApiDataItem<TypeMap[K]>[] | undefined> {
	checkSample("getSamples", api);

	if (dateFrom && dateTo) {
		console.info(
			api.name +
				" - Date range: " +
				dateFrom.toLocaleString() +
				" - " +
				dateTo.toLocaleString()
		);
	}

	let apiFunction;
	switch (type) {
		case "boolean":
			apiFunction = apiService.getBooleanSample;
			break;
		case "number":
			apiFunction = apiService.getNumberSample;
			break;
		case "HealthIndexStatus":
			apiFunction = apiService.getHealthIndexSample;
			break;
		default:
			throw new Error(`getGenericSample | unsupported type ${type}`);
	}
	const resp = await apiFunction(
		api.device!.model,
		api.device!.id,
		api.name!,
		size,
		undefined,
		dateFrom,
		dateTo,
		boundaries
	);

	return resp.samples as Models.GenericApiDataItem<TypeMap[K]>[];
}

export async function getLastSample(
	api: Models.ApiIdentityItem
): Promise<Models.ApiDataItem | undefined> {
	let res = await getSamples(api, 1);
	return res?.[0] ?? undefined;
}

export async function getWaveforms(
	api: Models.ApiIdentityItem,
	listOnly: true,
	size?: number,
	dateFrom?: Date,
	dateTo?: Date,
	groupId?: number,
): Promise<Models.WaveformDataListOnlyItem[]>;
export async function getWaveforms(
	api: Models.ApiIdentityItem,
	listOnly?: false,
	size?: number,
	dateFrom?: Date,
	dateTo?: Date,
	groupId?: number,
): Promise<Models.WaveformDataItem[]>;
export async function getWaveforms(
	api: Models.ApiIdentityItem,
	listOnly?: boolean,
	size?: number,
	dateFrom?: Date,
	dateTo?: Date,
	groupId?: number,
): Promise<Models.WaveformDataItem[] | Models.WaveformDataListOnlyItem[]> {
	checkWaveform("getWaveforms", api);

	if (dateFrom && dateTo) {
		console.info(
			api.name +
				" - Date range: " +
				dateFrom.toLocaleString() +
				" - " +
				dateTo.toLocaleString()
		);
	}

	const resp = await apiService.getWaveforms(
		api.device!.model,
		api.device!.id,
		api.name!,
		api.operation ?? Models.WaveformOperation.last,
		listOnly,
		size,
		dateFrom ? dateFrom.getTime() * 1000 : undefined,
		dateTo ? dateTo.getTime() * 1000 : undefined,
		groupId,
	);

	return resp;
}

export async function getLastGenericSample<K extends keyof TypeMap>(
	type: K,
	api: Models.ApiIdentityItem,
	boundaries?: { min?: number; max?: number; step?: number }
): Promise<Models.GenericApiDataItem<TypeMap[K]> | undefined> {
	const res = await getGenericSamples(
		type,
		api,
		1,
		undefined,
		undefined,
		boundaries
	);
	return res?.[0] ?? undefined;
}

export function apiItemsToAggregationRequestDevices(
	apis: Models.ApiIdentityItem[]
): Record<string, Record<string, Models.AggregationDevice>> {
	return apis
		.filter((api) => api)
		.reduce((devices, api) => {
			checkApiType("apiItemsToAggregationRequestDevices", api, api.type);
			const model = (devices[api.device!.model] =
				devices[api.device!.model] || {});
			const id = (model[api.device!.id] = model[api.device!.id] || {
				events: [],
				samples: [],
			});
			if (api.type === Models.ApiIdentityItemType.event) {
				id.events.push(api.name!);
			} else if (api.type === Models.ApiIdentityItemType.sample) {
				id.samples.push(api.name!);
			} else {
				throw new Error(
					`apiItemsToAggregationRequestDevices | invalid api type | ${api.type}`
				);
			}
			return devices;
		}, {} as Record<string, Record<string, Models.AggregationDevice>>);
}

export async function getDataAggregation(
	apis: Models.ApiIdentityItem[],
	size?: number,
	dateFrom?: Date,
	dateTo?: Date,
	boundaries?: { min?: number; max?: number }
): Promise<Models.AggregationResponse> {
	let devices = apiItemsToAggregationRequestDevices(apis);
	const data = await apiService.getDataAggregation(
		devices,
		size,
		dateFrom,
		dateTo,
		boundaries
	);

	for (const models of Object.values(data.devices))
		for (const ids of Object.values(models))
			for (const samples of Object.values(ids.samples || {}))
				for (const sample of samples) sample.value = roundNumber(sample.value);

	return data;
}

export async function getMultipleSamples<K extends keyof TypeMap>(
	type: K,
	apis: Models.ApiIdentityItem[],
	boundaries?: ({ min?: number; max?: number; step?: number } | undefined)[]
): Promise<Models.GenericApiDataItem<TypeMap[K] | null>[]> {
	let devices = apiItemsToAggregationRequestDevices(apis);
	let mappedBoundaries;
	if (boundaries) {
		mappedBoundaries = apis
			.filter((api) => api)
			.reduce((devices, api, i) => {
				const model = (devices[api.device!.model] =
					devices[api.device!.model] || {});
				const id = (model[api.device!.id] =
					model[api.device!.id] || ({} as Record<string, any>));
				if (api.type === Models.ApiIdentityItemType.sample) {
					id[api.name!] = boundaries[i];
				}
				return devices;
			}, {} as Record<string, Record<string, Record<string, any>>>);
	}
	let data: Models.GenericAggregationResponse<
		boolean | number | string | HealthIndexStatus | null
	>;
	if (type === "number" || type === "string") {
		data = await apiService.getMultipleNumericSamples(
			devices,
			mappedBoundaries
		);
	} else if (type === "boolean") {
		data = await apiService.getMultipleBooleanSamples(devices);
	} else if (type === "HealthIndexStatus") {
		data = await apiService.getMultipleHealthIndexSamples(devices);
	} else {
		throw new Error(`getMultipleSamples | unsupported type ${type}`);
	}
	return apis.map((api, i) => {
		const device = data.devices[api.device!.model];
		const samples = device[api.device!.id].samples;
		const sample = samples[api.name!][0];
		switch (type) {
			case "number":
			case "string":
				return {
					...sample,
					value: (sample != null
						? roundNumber(
								sample.value as string | number,
								boundaries?.[i]?.step
						  )
						: null) as TypeMap[K],
				};
			case "boolean": {
				let value = sample?.value;
				if (typeof value === "string") {
					value = parseInt(value);
				}
				return {
					...sample,
					value: (sample != null ? Boolean(value) : null) as TypeMap[K],
				};
			}
			case "HealthIndexStatus":
				return {
					...sample,
					value: (sample.value != null &&
					Object.values(HealthIndexStatus).includes(sample.value as any)
						? (sample.value as HealthIndexStatus)
						: null) as TypeMap[K],
				};
			default:
				throw new Error(`getMultipleSamples | unsupported type ${type}`);
		}
	});
}

export function getLastByDataAggregation(
	...apis: Models.ApiIdentityItem[]
): Promise<Models.AggregationResponse> {
	return getDataAggregation(apis, 1);
}

export function getLastValueFromAggregration(
	data: Models.AggregationResponse,
	api: Models.ApiIdentityItem
): GenericValue {
	let item = data.devices[api.device!.model][api.device!.id];

	if (api.type === Models.ApiIdentityItemType.event) {
		let event = item.events[api.name!];
		if (event && event.length > 0) {
			let last = event[event.length - 1];
			let mappedState = getMappedEventState(
				api.name!,
				last.state,
				api.device!.model,
				api.states
			);
			return {
				date: DateTimeFormatHelper.toDate(last.timestamp_us)!,
				rawValue: last.state,
				mappedValue: mappedState.value,
				messageKey: mappedState.messageKey,
			};
		}
		throw new Error(
			`getLastValueFromAggregration | no data for event | ${api.role} | ${api.name}`
		);
	} else if (api.type === Models.ApiIdentityItemType.sample) {
		let sample = item.samples[api.name!];
		if (sample && sample.length > 0) {
			let last = sample[sample.length - 1];
			return {
				date: DateTimeFormatHelper.toDate(last.timestamp_us)!,
				rawValue: last.value as number,
				mappedValue: last.value.toString(),
			};
		}
		throw new Error(
			`getLastValueFromAggregration | no data for sample | ${api.role} | ${api.name}`
		);
	}
	throw new Error(
		`getLastValueFromAggregration | invalid api type | ${api.type}`
	);
}

export function getUnit(api: Models.ApiIdentityItem): string {
	try {
		if (!api.name) {
			throw new Error("missing api name");
		}
		if (api.type === Models.ApiIdentityItemType.sample) {
			checkSample("getSampleUnit", api);
			return devicesMetadata$.getValue().models[api.device!.model]!.samples![
				api.name!
			].unit!;
		}
		if (api.type === Models.ApiIdentityItemType.waveform) {
			checkWaveform("getSampleUnit", api);
			return devicesMetadata$.getValue().models[api.device!.model]!.waveforms![
				api.name!
			].unit!;
		}
		throw new Error("unsupported api type: " + api.type);
	} catch (ex) {
		console.warn("getSampleUnit", api, ex);
		return "";
	}
}

export function getValue<T>(api?: Models.ApiIdentityItem): T | undefined {
	if (!api) {
		throw new Error("getValue | api identity required");
	}

	if (api.type !== Models.ApiIdentityItemType.value) {
		throw new Error(`getValue | invalid api type | ${api.type}`);
	}

	if (!api.path || api.path.length === 0)
		throw new Error("getValue | path is required for api value type");

	let values = values$.getValue().values;

	for (const path of api.path) {
		if (values) values = values[path];
		else return;
	}

	return (values as T) || undefined;
}

export function getHealthIndexStatus(
	genericValue: GenericValue
): HealthIndexStatus {
	if (
		!Object.values(HealthIndexStatus).includes(genericValue.mappedValue as any)
	) {
		throw new Error(
			`Invalid Enums.HealthIndexStatus | ${genericValue.mappedValue}`
		);
	}
	return genericValue.mappedValue as HealthIndexStatus;
}

export function getConnection(genericValue: GenericValue): ConnectionStatus {
	if (
		!Object.values(ConnectionStatus).includes(genericValue.mappedValue as any)
	) {
		throw new Error(
			`Invalid Enums.ConnectionStatus | ${genericValue.mappedValue}`
		);
	}
	return genericValue.mappedValue as ConnectionStatus;
}

export function getLoad(genericValue: GenericValue): Load {
	if (!Object.values(Load).includes(genericValue.mappedValue as any)) {
		throw new Error(`Invalid Enums.Load | ${genericValue.mappedValue}`);
	}
	return genericValue.mappedValue as Load;
}

interface GenericValue {
	rawValue: number;
	mappedValue: string;
	date: Date;
	messageKey?: string;
	relatedEvents?: number[];
}

interface TemperatureSamplesData {
	loadEvent: { name: string; model: string; id: string };
	samples: number[];
	timestampUs: number;
	highAlarm: number | null;
	highWarn: number | null;
}
