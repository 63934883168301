import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ABB from '@abb/abb-common-ux-react';
import { Card } from '../../components';
import { WidgetProps } from '../../models';
import { CBStatusItem, CBStatusItemProps } from './CBStatusItem';
import "./style.scss"
import { Placeholder } from '../Placeholder/Placeholder';
import { useReadable } from '../../observables/hooks';
import { makeAsyncWritable } from '../../utils/async-writable';
import { AspectRatio } from '../../components/AspectRatio/AspectRatio';

const cbStatusSvg$ = makeAsyncWritable(null, {
	dataProvider: () => fetch('./images/cb-status.svg').then((response) => response.text()),
});

export interface CBStatusProps extends WidgetProps {
	closed: boolean;
	open: boolean;
	ready: boolean;
	current: number|null;
	voltage: number|null;
	voltageUnit: string;
	currentUnit: string;
	units: CBStatusItemProps[];
}

export function CBStatus({
	open,
	closed,
	ready,
	current,
	voltage,
	units,
	mobileCols,
	desktopCols,
	tabletCols,
	title,
	lastUpdateType,
	lastUpdate,
	currentUnit,
	voltageUnit,
	installed,
	disconnected,
}: CBStatusProps): ReactElement {
	const { t } = useTranslation();
	const cvStatusSvg = useReadable(cbStatusSvg$);
	const [svgContainerRef, setSvgContainerRef] = useState<HTMLElement | null>(null);
	const [svgElement, setSvgElement] = useState<SVGSVGElement | null>(null);
	const [aspectRatio, setAspectRatio] = useState(1);
	const svgContainerRefCallback = useCallback((node) => {
		setSvgContainerRef(node);
	}, [setSvgContainerRef]);
	useEffect(() => {
		if (cvStatusSvg && svgContainerRef) {
			svgContainerRef.innerHTML = cvStatusSvg;
			const svgElement = svgContainerRef.firstElementChild as SVGSVGElement;
			svgElement.style.width = '100%';
			svgElement.style.height = 'auto';
			setSvgElement(svgElement);
		}
	}, [cvStatusSvg, svgContainerRef]);

	useEffect(() => {
		if (!svgElement) {
			return;
		}
		setAspectRatio(svgElement.viewBox.baseVal.width / svgElement.viewBox.baseVal.height);
	}, [svgElement]);

	useEffect(() => {
		if (!svgElement) {
			return;
		}
		
		svgElement.setAttribute('data-status', `open:${open ? 1 : 0};closed:${closed ? 1 : 0}`);
	}, [open, closed, svgElement])

	useEffect(() => {
		if (!svgElement) {
			return;
		}
		svgElement.querySelector('.current-indicator')!.innerHTML = current !== null ? `${current.toLocaleString()} ${currentUnit}` : t("N/A");
		svgElement.querySelector('.voltage-indicator')!.innerHTML = voltage !== null ? `${voltage.toLocaleString()} ${voltageUnit}` : t("N/A");
	}, [voltage, current, svgElement, currentUnit, voltageUnit, t])

	return <Card
		title={title}
		mobileCols={mobileCols ?? 12}
		desktopCols={desktopCols ?? 12}
		tabletCols={tabletCols ?? 12}
		comment={{ lastUpdate, lastUpdateType }}
		installed={installed}
		disconnected={disconnected}
	>
		<div className="row" >
			<div className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex flex-column justify-content-center">
				<h3 className="font-weight-bold mb-4 mt-0">{closed && !open &&
					<><ABB.Icon
						name="abb/minus-in-circle"
						color="color-status-red"
						sizeClass="large"
						className="align-middle"
						style={{ transform: "rotate(90deg)" }}
					/> <span className="align-middle">{t("Closed")}</span>
					</>}
					{!closed && open && <><ABB.Icon
						name="abb/minus-in-circle"
						color="color-status-green"
						sizeClass="large" 
						className="align-middle"
					/> <span className="align-middle">{t("Open")}</span></>}
					{((!closed && !open) || (closed && open)) && <><ABB.Icon
						name="abb/close"
						color="color-status-red"
						sizeClass="large" 
						className="align-middle"
					/> <span className="align-middle">{t("Unknown")}</span></>}
				</h3>
				<h3 className="font-weight-bold">{ready ?
					<><ABB.Icon
						sizeClass="large"
						name="abb/check-mark-circle-2"
						className="align-middle"
						color="color-status-green" /> <span className="align-middle">{t("Ready")}</span></>
					: <><ABB.Icon
						sizeClass="large"
						name="abb/warning-triangle"
						className="align-middle"
						color="color-status-red" /> <span className="align-middle">{t("Not ready")}</span></>}
				</h3>
			</div>
			<div className="col-12 col-sm-6 col-md-3 col-lg-4">
				{!cvStatusSvg
					? <Placeholder mobileCols={12} desktopCols={12} tabletCols={12}></Placeholder>
					: (
						<div style={{ maxWidth: svgElement?.viewBox.baseVal.width || 1, margin: '0 auto' }} className="p-2">
							<AspectRatio ratio={aspectRatio}>
								<div ref={svgContainerRefCallback}></div>
							</AspectRatio>
						</div>
					)
				}
			</div>
			<div className="col-12 col-md-5 col-lg-5 d-flex flex-column justify-content-center">
				{units.map((itemProps, i) => <CBStatusItem {...itemProps} key={i} />)}
			</div>
		</div>
	</Card>
}
