import { getLastEventValue, getMultipleSamples } from '.';
import { HealthIndexStatus, SupervisorStatus } from '../../enums';
import { DateTimeFormatHelper } from '../../helpers';
import { GenericApiDataItem, LastUpdateType } from '../../models';
import { NamedSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { and, nor, not } from '../../utils/logic';
import { HardwareIntegrityProps } from '../../widgets';

export async function loadHardwareIntegrity(config: WidgetConfig, props: Partial<HardwareIntegrityProps>): Promise<HardwareIntegrityProps> {
	const hardwareIntegrityProps = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as HardwareIntegrityPropsConfig;
		const statusFromBoolean = (boolOrUndefined: boolean|null|undefined): HealthIndexStatus|undefined => {
			if (boolOrUndefined === null || boolOrUndefined === undefined) { return undefined; }
			if (boolOrUndefined === false) { return HealthIndexStatus.Ok; }
			return HealthIndexStatus.Alarm;
		}
		const getStateFromSample = ([motorFail, positionFail, speedFail, torqueFail, endOfOperationFail]: (GenericApiDataItem<boolean|null>)[]) => {
			return {
				loadDiagnostic: {
					motor: statusFromBoolean(motorFail?.value),
				},
				operationControl: {
					position: statusFromBoolean(positionFail?.value),
					speed: statusFromBoolean(speedFail?.value),
					torque: statusFromBoolean(torqueFail?.value),
				},
				positionControl:{
					endOfOperation: statusFromBoolean(endOfOperationFail?.value),
				}
			};
		}
		const samples = await getMultipleSamples('boolean', [
			...([propsConfig.unit1, propsConfig.unit2, propsConfig.unit3].flatMap((unit) => [
				unit.loadDiagnostic.motor.source,
				unit.operationControl.position.source,
				unit.operationControl.speed.source,
				unit.operationControl.torque.source,
				unit.positionControl.endOfOperation.source,
			])),
			...([propsConfig.hardwareMonitor.electronicIntegrity.unit1, propsConfig.hardwareMonitor.electronicIntegrity.unit2, propsConfig.hardwareMonitor.electronicIntegrity.unit3]
					.flatMap((unit) =>
						unit.map((u) => u.source)
					)
			),
			propsConfig.hardwareMonitor.capacitorDiagnostic.source,
			propsConfig.unitsConnection.rs422.source
		]);
		const unit1 = samples.slice(0, 5);
		const unit2 = samples.slice(5, 10);
		const unit3 = samples.slice(10, 15);
		const electronicUnit1Length = propsConfig.hardwareMonitor.electronicIntegrity.unit1.length;
		const electronicUnit2Length = propsConfig.hardwareMonitor.electronicIntegrity.unit2.length;
		const electronicUnit3Length = propsConfig.hardwareMonitor.electronicIntegrity.unit3.length;
		let idx = 15;
		const unit1El = samples.slice(idx, idx += electronicUnit1Length);
		const unit2El = samples.slice(idx, idx += electronicUnit2Length);
		const unit3El = samples.slice(idx, idx += electronicUnit3Length);
		const capacitorDiagnostic = samples[idx++];
		const rs422 = samples[idx];
		let canSupervisor; 
		try {
			canSupervisor = getLastEventValue(propsConfig.unitsConnection.canSupervisor.source);
			const greaterDate = getGreaterDate(hardwareIntegrityProps.lastUpdate, canSupervisor.date);
			if (greaterDate !== hardwareIntegrityProps.lastUpdate) {
				hardwareIntegrityProps.lastUpdate = greaterDate;
				hardwareIntegrityProps.lastUpdateType = LastUpdateType.Event;
			}
		} catch (err) {
			console.warn('loadHardwareIntegrity', err);
		}			

		samples.forEach((sample) => {
			const greaterDate = getGreaterDate(hardwareIntegrityProps.lastUpdate, sample?.timestamp_us ? DateTimeFormatHelper.toDate(sample.timestamp_us) : undefined);
			if (greaterDate !== hardwareIntegrityProps.lastUpdate) {
				hardwareIntegrityProps.lastUpdate = greaterDate;
				hardwareIntegrityProps.lastUpdateType = LastUpdateType.Sample;
			}
		});
		
		const electronicIntegrity = and(
			nor(...unit1El.map((el) => el!.value)),
			nor(...unit2El.map((el) => el!.value)),
			nor(...unit3El.map((el) => el!.value))
		);
		hardwareIntegrityProps.states = {
			unit1: getStateFromSample(unit1),
			unit2: getStateFromSample(unit2),
			unit3: getStateFromSample(unit3),
			hardwareMonitor: {
				capacitorDiagnostic: statusFromBoolean(capacitorDiagnostic?.value),
				electronicIntegrity: statusFromBoolean(not(electronicIntegrity)),
			},
			unitsConnection: {
				canSupervisor: canSupervisor?.mappedValue !== undefined ? statusFromBoolean(canSupervisor?.mappedValue !== SupervisorStatus.Both) : undefined,
				rs422: statusFromBoolean(rs422?.value),
			}
		};
	} catch (e) {
		hardwareIntegrityProps.exception = e;
	} finally {
		hardwareIntegrityProps.fetching = false;
	}
	return hardwareIntegrityProps as HardwareIntegrityProps;
}

export interface HardwareIntegrityUnitPropsConfig {
	positionControl: {
		endOfOperation: { source: NamedSource }
	},
	operationControl: {
		position: { source: NamedSource }
		speed: { source: NamedSource }
		torque: { source: NamedSource }
	},
	loadDiagnostic: {
		motor: { source: NamedSource }
	}
}

export interface HardwareIntegrityPropsConfig {
	title: { i18n: string },
	unit1: HardwareIntegrityUnitPropsConfig,
	unit2: HardwareIntegrityUnitPropsConfig,
	unit3: HardwareIntegrityUnitPropsConfig,
	hardwareMonitor: {
		electronicIntegrity: { 
			unit1: { source: NamedSource }[],
			unit2: { source: NamedSource }[],
			unit3: { source: NamedSource }[],
		},
		capacitorDiagnostic: { source: NamedSource },
	},
	unitsConnection: {
		rs422: { source: NamedSource },
		canSupervisor: { source: NamedSource },
	}
}
