import { getLastGenericSample } from '.';
import { DateTimeFormatHelper } from '../../helpers';
import { NamedSource, PathSource, WidgetConfig } from '../../pages/DynamicPage';
import { getGreaterDate } from '../../utils/date';
import { HealthIndexProgressProps } from '../../widgets/HealthIndexProgress/HealthIndexProgress';

export async function loadHealthIndexProgress(config: WidgetConfig, props: Partial<HealthIndexProgressProps>): Promise<HealthIndexProgressProps> {
	const healthIndexProgress = { ...props };
	try {
		const propsConfig = config.propsConfig as unknown as HealthIndexProgressPropsConfig;
		const value = await getLastGenericSample('number', propsConfig.value.source);
		healthIndexProgress.value = value!.value;
		healthIndexProgress.lastUpdate = getGreaterDate(healthIndexProgress.lastUpdate, value?.timestamp_us ? DateTimeFormatHelper.toDate(value!.timestamp_us) : undefined);
	} catch (e) {
		healthIndexProgress.exception = e;
	} finally {
		healthIndexProgress.fetching = false;
	}
	return healthIndexProgress as HealthIndexProgressProps;
}

export interface HealthIndexProgressPropsConfig {
	title: {
		i18n: string,
	},
	value: {
		source: NamedSource,
	},
	lowAlarm: {
		source: PathSource,
	},
	lowWarn: {
		source: PathSource,
	},
	min: {
		source: PathSource,
	},
	max: {
		source: PathSource,
	},
	discoverMoreLink: {
		value: string,
	},
	alarmMessage: {
		i18n: string,
	},
	warningMessage: {
		i18n: string
	}
}
